import * as React from "react";
import { Alert, Button, FormControl, FormGroup, ControlLabel, Col, HelpBlock } from "react-bootstrap";

interface Props {
  hasErrored: boolean;
  onLogin(username: string, password: string): void;
}

interface State {
  username?: string;
  password?: string;
}

export class Login extends React.Component<Props, State> {
  state: State = {
    username: "",
    password: ""
  };

  onFormSubmit = (event: any): void => {
    event.preventDefault();
    this.props.onLogin(this.state.username, this.state.password);
  }

  renderError = (): JSX.Element => {
    if (this.props.hasErrored) {
      return (
        <Alert bsStyle="danger">
          The username or password was not valid
        </Alert>
      );
    }
    return null;
  }

  render() {
    return (
      <Col xs={12} sm={6}>
        {this.renderError()}

        <form onSubmit={ this.onFormSubmit }>
          <FormGroup controlId="username">
            <ControlLabel>Username</ControlLabel>
            <FormControl
              type="text"
              placeholder="v.archer, c.beyer, a.tentorio"
              value={this.state.username}
              onChange={event => this.setState({username: (event.target as HTMLInputElement).value}) }
            />
          </FormGroup>

          <FormGroup controlId="password">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              type="password"
              value={this.state.password}
              onChange={event => this.setState({password: (event.target as HTMLInputElement).value}) }
            />
            <HelpBlock>The same password used on JumpCloud</HelpBlock>
          </FormGroup>

          <Button type="submit">Login</Button>
        </form>
      </Col>
    );
  }
}
