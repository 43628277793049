import * as Immutable from "immutable";
import * as types from "./types";

const initialSessionState = Immutable.Map({
  error: null,
  isLoggingIn: false,
  username: null,
});

export interface Action {
  type: string;
  payload: any;
}

const session = (state = initialSessionState, action: Action) => {
  switch (action.type) {
  case types.SESSION_LOGIN_ATTEMPT:
    return initialSessionState.set("isLoggingIn", true);
  case types.SESSION_LOGIN_FAIL:
    return initialSessionState.set("error", action.payload);
  case types.SESSION_LOGIN_SUCCESS:
    return state
    .set("isLoggingIn", false)
    .set("username", action.payload.username)
    .set("error", null);
  case types.SESSION_LOG_OUT:
    return initialSessionState;
  default:
    return state;
  }
};

export default session;
