type SET_AUTHORISED_TYPE = "trello.SET_AUTHORISED";
export const SET_AUTHORISED: SET_AUTHORISED_TYPE = "trello.SET_AUTHORISED";

type FETCH_ATTEMPT_TYPE = "trello.FETCH_ATTEMPT";
export const FETCH_ATTEMPT: FETCH_ATTEMPT_TYPE = "trello.FETCH_ATTEMPT";

type FETCH_SUCCESS_TYPE = "trello.FETCH_SUCCESS";
export const FETCH_SUCCESS: FETCH_SUCCESS_TYPE = "trello.FETCH_SUCCESS";

type FETCH_FAILED_TYPE = "trello.FETCH_FAILED";
export const FETCH_FAILED: FETCH_FAILED_TYPE = "trello.FETCH_FAILED";
