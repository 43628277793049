import * as React from "react";
import styled from "styled-components";

const HeaderText2 = styled.div`
    float: left;
`;

interface Props {
  text: string;
  width: string;
}

export const DataTableDataSubHeaderText: React.StatelessComponent<Props> = ({
  text,
  width,
}) => {

  return (
    <HeaderText2 style={{ width }}>
      {text}
    </HeaderText2>
  );
};
