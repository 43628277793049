import * as React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import * as Spinner from "react-spinkit";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { TherapistSubMenu } from "../../components/sub/sub-menu";
import { RootState } from "../../reducers";
import { ApiTherapist } from "../../api/main/therapist";
import { fetchTherapist } from "../../model/therapist";
import { searchTherapist } from "../../reducers/bookings/actions";
import { TherapistAvailabilityBulkForm } from "../../components/therapist/therapist-availability-bulk-form";
import { setBulkAvailability, setBulkAvailabilityRef } from "../../model/availability";
import { statusSelectors } from "../../reducers/api-status/selectors";
import { Button } from "../../components/atoms/button";
import { ApiTimeRanges } from "../../api/main/availability";

interface ReduxProps {
  therapist: ApiTherapist;
  isBusy: boolean;
}

type OwnProps = RouteComponentProps<{ urn: string }>;
type Props = ReduxProps & OwnProps & { dispatch: Dispatch<{}> };

class Component extends React.Component<Props, {}> {
  componentDidMount() {
    if (!this.props.therapist) {
      this.props.dispatch(fetchTherapist(this.props.match.params.urn));
    }
  }

  handleClickBookings = (): void => {
    this.props.dispatch(searchTherapist(this.props.therapist.urn));
  }

  handleClickBack = (): void => {
    this.props.history.push(`/therapists/${this.props.therapist.urn}/availability`);
  }

  handleSubmit = async (timeRange: ApiTimeRanges): Promise<boolean> => {
    return this.props.dispatch(setBulkAvailability(this.props.therapist.urn, timeRange));
  }

  render() {
    const { therapist } = this.props;

    return (
      <div className="therapist-container">
        {therapist &&
          <div className="main-section">
            <div className="therapist-availability">
              <h1>{therapist.name}</h1>

              <p>
                <Button
                  onClick={this.handleClickBack}
                  label="Availability"
                  icon="fa-arrow-left"
                  style="default"
                />
              </p>

              <TherapistAvailabilityBulkForm therapistUrn={this.props.therapist.urn} onSubmit={this.handleSubmit} isBusy={this.props.isBusy} />
            </div>
          </div>
        }
        {!therapist &&
          <div className="spinner-container main-section">
            <Spinner name="pulse" fadeIn="none" />
          </div>
        }

        <div className="side-nav">
          <TherapistSubMenu urn={this.props.match.params.urn} active="availability" onClickBookings={this.handleClickBookings} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): ReduxProps => {
  const therapistUrn = ownProps.match.params.urn;
  const therapist = state.therapistsState.therapists[therapistUrn];

  return {
    therapist: (therapist) ? therapist : null,
    isBusy: statusSelectors.getIsPending(state, setBulkAvailabilityRef),
  };
};

export const TherapistAvailabilityBulkContainer = withRouter(connect<ReduxProps, {}, OwnProps>(mapStateToProps)(Component));
