import * as moment from "moment-timezone";
import { ActionType, getType } from "typesafe-actions";
import * as iassign from "immutable-assign";

import { actionCreators, SearchParams } from "./actions";
import { ApiBooking } from "../../api/main/booking";

type BookingAction = ActionType<typeof actionCreators>;

interface BookingsMap {
  [index: string]: ApiBooking;
}

interface TherapistBookingsState {
  [day: string]: string[];
}

export type BookingsState = {
  error: string;
  isBusy: boolean;
  searchParams: SearchParams;
  bookings: BookingsMap;
  sortedBookings: string[];
  therapistBookings: {
    [therapistUrn: string]: TherapistBookingsState;
  };
  customerAddresses: any[];
  deleting: {
    promo: boolean;
    refund: boolean;
  };
  submittingPromos: boolean;
};

const defaultDeleting = { promo: false, refund: false };

const defaultSearchParams: SearchParams = {
  start: moment().startOf("day").valueOf(),
  end: moment().endOf("day").add(14, "days").valueOf(),
  dateType: "BOOKING",
  query: "",
  status: "ALL",
  payment: "ALL",
  partners: null,
};

const INITIAL_STATE: BookingsState = {
  error: null,
  isBusy: false,
  searchParams: defaultSearchParams,
  bookings: {},
  sortedBookings: [],
  therapistBookings: {},
  customerAddresses: [],
  deleting: defaultDeleting,
  submittingPromos: false,
};

export function bookingsReducer(
  state: BookingsState = INITIAL_STATE,
  action: BookingAction,
): BookingsState {
  switch (action.type) {
    case getType(actionCreators.resetSearch): {
      return iassign(
        state,
        (s) => s.searchParams,
        () => {
          return defaultSearchParams;
        },
      );
    }
    case getType(actionCreators.updateSearchParameters): {
      return iassign(
        state,
        (s) => s.searchParams,
        (s) => {
          return { ...s, ...action.payload };
        },
      );
    }

    case getType(actionCreators.fetchBookingsFail): {
      return iassign(state, (s) => {
        s.isBusy = false;
        s.error = action.payload;
        s.sortedBookings = [];

        return s;
      });
    }

    case getType(actionCreators.updateBookingsMap): {
      return iassign(
        state,
        (s) => s.bookings,
        (bs) => {
          // store bookings in lookup, keyed by URN
          action.payload.forEach((b) => (bs[b.urn] = b));

          return bs;
        },
      );
    }

    case getType(actionCreators.setBookingsList): {
      return iassign(state, (s) => {
        s.isBusy = false;
        s.error = undefined;
        s.sortedBookings = action.payload;

        return s;
      });
    }

    case getType(actionCreators.setTherapistBookingsForDay): {
      const stateWithCleanedFlags = iassign(state, (s) => {
        s.isBusy = false;
        s.error = undefined;

        return s;
      });

      const stateWithEmptyTherapistBookingsContainer = iassign(
        stateWithCleanedFlags,
        (s) => {
          return s.therapistBookings;
        },
        (tb) => {
          tb[action.payload.therapistUrn] = {};

          return tb;
        },
      );

      return iassign(
        stateWithEmptyTherapistBookingsContainer,
        (s, ctx) => {
          return s.therapistBookings[ctx.payload.therapistUrn];
        },
        (therapistDayBookings) => {
          therapistDayBookings[action.payload.day] =
            action.payload.bookings.map((b) => b.urn);
          return therapistDayBookings;
        },
        { payload: action.payload },
      );
    }

    case getType(actionCreators.fetchBookingAttempt): {
      return iassign(state, (s) => {
        s.isBusy = true;
        s.error = undefined;

        return s;
      });
    }

    case getType(actionCreators.fetchBookingFail): {
      return iassign(state, (s) => {
        s.isBusy = false;
        s.error = action.payload;
        s.deleting = defaultDeleting;

        return s;
      });
    }

    case getType(actionCreators.updateBookingAttempt): {
      return iassign(state, (s) => {
        s.isBusy = true;

        return s;
      });
    }

    case getType(actionCreators.updateBookingAttempt): {
      return iassign(
        state,
        (s) => s.deleting,
        (d) => {
          d.promo = true;

          return d;
        },
      );
    }

    case getType(actionCreators.updateBookingTreatmentPriceSuccess): {
      return iassign(
        state,
        (s, ctx) =>
          s.bookings[ctx.payload.bookingUrn].bookingTreatments[
            ctx.payload.index
          ],
        (bt) => {
          bt.price = action.payload.value;

          return bt;
        },
        { payload: action.payload },
      );
    }

    case getType(actionCreators.createTransactionSuccess): {
      return iassign(
        state,
        (s, ctx) => s.bookings[ctx.payload.bookingUrn].transactions,
        (t) => {
          t.push({
            type: action.payload.type,
            amount: action.payload.amount,
            urn: action.payload.transactionUrn,
            bookingUrn: "",
            transactionId: "",
          });

          return t;
        },
        { payload: action.payload },
      );
    }

    case getType(actionCreators.fetchTransactionsSuccess): {
      return iassign(
        state,
        (s, ctx) => s.bookings[ctx.payload.bookingUrn],
        (b) => {
          b.transactions = action.payload.transactions;

          return b;
        },
        { payload: action.payload },
      );
    }

    case getType(actionCreators.deleteTransactionSuccess): {
      return iassign(
        state,
        (s, ctx) => s.bookings[ctx.payload.bookingUrn].transactions,
        (t) => {
          return t.filter((_t, i) => i !== action.payload.transactionIndex);
        },
        { payload: action.payload },
      );
    }

    case getType(actionCreators.deleteAdjustmentSuccess): {
      return iassign(
        state,
        (s, ctx) => s.bookings[ctx.payload.bookingUrn].transactions,
        (t) => {
          return t.filter((t) => t.urn !== action.payload.adjustmentUrn);
        },
        { payload: action.payload },
      );
    }

    case getType(actionCreators.submitPromosAttempt): {
      return iassign(state, (s) => {
        s.submittingPromos = true;

        return s;
      });
    }

    case getType(actionCreators.submitPromosSuccess): {
      return iassign(state, (s) => {
        s.submittingPromos = false;

        return s;
      });
    }

    case getType(actionCreators.removeRefundAttempt): {
      return iassign(
        state,
        (s) => s.deleting,
        (d) => {
          d.refund = true;

          return d;
        },
      );
    }

    case getType(actionCreators.deleteBookingTreatment): {
      return iassign(
        state,
        (s, ctx) => s.bookings[ctx.payload.bookingUrn].bookingTreatments,
        (t) => {
          return t.filter((t) => t.urn !== action.payload.bookingTreatmentUrn);
        },
        { payload: action.payload },
      );
    }

    default: {
      return state;
    }
  }
}
