import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { RootState } from "../reducers";
import { Header } from "../components/header";
import { logOut } from "../reducers/operator/actions";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface ReduxProps {
  isLoggedIn: boolean;
  permissions: any;
}

type OwnProps = RouteComponentProps<{}>;
type Props = ReduxProps & OwnProps & { dispatch: Dispatch<{}> };

class Component extends React.Component<Props, {}> {

  getNavigationTabs() {
    const perms = this.props.permissions;
    let navigationTabs = [];

    if (perms.all) {
      navigationTabs.push(...[
        { label: "Home", url: "/home" },
        { label: "Bookings", url: "/bookings" },
        { label: "Therapists", url: "/therapists" },
        { label: "Treatments", url: "/treatments" },
        { label: "Customers", url: "/customers" },
        { label: "Partners", url: "/partners" },
        { label: "Jobs", url: "/jobs" },
        { label: "Platform", url: "/platform" },
        { label: "Promotions", url: "/promotions" },
        { label: "Regions", url: "/regions"},
        { label: "Fraud", url: "/fraud" },
      ]);
    }
    else {
      if (perms.bookings) {
        navigationTabs.push({ label: "Bookings", url: "/bookings" });
      }
      if (perms.customers) {
        navigationTabs.push({ label: "Clients", url: "/customers" });
      }
      if (perms.therapists) {
        navigationTabs.push({ label: "Therapists", url: "/therapists" });
      }
      if (perms.treatments) {
        navigationTabs.push({ label: "Treatments", url: "/treatments" });
      }
    }

    return navigationTabs;
  }

  render() {
    return (<Header
      isLoggedIn={this.props.isLoggedIn}
      onLogOut={() => this.props.dispatch(logOut(this.props.history))}
      navigationTabs={this.getNavigationTabs()}
    />);
  }
}

const mapStateToProps = (state: RootState): ReduxProps => {
  return {
    isLoggedIn: state.operator.urn !== null,
    permissions: state.operator.permissions,
  };
};

export const HeaderContainer = withRouter(connect<ReduxProps, {}, OwnProps>(mapStateToProps, null, null,
  { pure: false } // needed to add the active class in the header : https://github.com/react-bootstrap/react-router-bootstrap/issues/152
)(Component));
