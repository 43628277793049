import styled from "styled-components";

import { editButtonCss } from "../../atoms/editable-field-icon";

export const Title = styled.h1`
font-family: "nunito_sansbold";
color: #565656;

  ${editButtonCss}
`;
