import * as React from "react";
import styled from "styled-components";

interface Props {
  title: string;
}

const DashboardComponentWrapper = styled.div`
  margin: 5px 0 0 0;
  box-shadow: 1px 2px 4px rgba(0,0,0,.2);
`;

const DashboardComponentBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-height: 500px;
  overflow: auto;
  background: white;
`;

const DashboardComponentTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  background-color: #396174;
  padding: 10px; 
  color: #ffffff;
`;

export const DashboardComponent: React.StatelessComponent<Props> = (props) => {
  return (
    <DashboardComponentWrapper>
      <DashboardComponentTitle>{props.title}</DashboardComponentTitle>
      <DashboardComponentBlock>{props.children}</DashboardComponentBlock>
    </DashboardComponentWrapper>
  );
};
