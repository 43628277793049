import * as React from "react";
import { connect } from "react-redux";
import { ApiPartner } from "../../api/main/partners";
import { RootState } from "../../reducers/index";

interface ReduxProps {
  partner: ApiPartner;
}

class Component extends React.Component<ReduxProps, {}> {

  render(): JSX.Element {
    if (this.props.partner && this.props.partner.logoURL) {
      return (
        <img width={100} src={this.props.partner.logoURL} />
      );
    }

    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="65 60.3 330.5 80.7">
        <g id="Y">
          <path d="M386.5,60.5c-4.1,0-8.8-0.1-11.2-0.2V62c3.9,0.7,5.1,3.1,5.1,5.8c0,3-1.7,7.4-3.7,11.6l-13.2,28l-15.2-32.1c-2.2-4.6-3.1-7.2-3.1-9.2c0-2.6,1.7-3.5,5.1-4.2v-1.6c-2.4,0.1-8.9,0.2-13.6,0.2c-4,0-11.4-0.1-13.7-0.2v1.6c2.7,0.1,5,3.9,9.7,13.1l19.9,38.9v9.6c0,12.3-0.4,14.6-5.5,15.5v1.6c2.5-0.1,8.8-0.2,12.9-0.2c4,0,10.2,0.1,12.7,0.2v-1.7c-5.1-0.9-5.5-3.2-5.5-15.5v-13.6l13.4-28C386,70.5,389,64,395.2,61.9v-1.6C392.9,60.4,390.2,60.5,386.5,60.5z" />
        </g>
        <g id="B">
          <path d="M306.9,96.4c10.7-2.9,15.3-10,15.3-18.7c0-9.6-5.7-17.4-18.4-17.4c-5.6,0-10,0.2-19.7,0.2c-3.8,0-9.7-0.1-12-0.2V62c4.7,0.9,5.1,3.2,5.1,15.5v46c0,12.3-0.4,14.6-5.1,15.5h0v1.7c2.2-0.1,8.2-0.2,12-0.2c7.9,0,13.7,0.3,18.3,0.3c20.8,0,25.6-12.6,25.6-23.4C328,105.1,320.3,98.3,306.9,96.4z M291.2,64.5c1.6-0.7,3.4-1,4.8-1c7,0,11.5,4,11.5,14.1c0,12.9-3.9,17.7-13.1,17.7h-3.2V64.5z M298.4,136.6c-2.4,0-5.4-0.2-7.3-1V98.8h3.6c12,0,18.5,5.4,18.5,18.5C313.2,131.8,306,136.6,298.4,136.6z" />
        </g>
        <g id="U2">
          <path d="M265.3,60.3c-2.3,0.1-4.9,0.2-8.6,0.2c-4,0-7.7-0.1-10.1-0.2v1.6c6.4,1.7,8.2,3.7,8.2,26.2h0v20.2c0,19.8-5.3,28.5-19.7,28.5c-14.1-1-16.9-7-16.9-27.1V77.3c0-12.2,0.4-14.5,5.4-15.4v-1.6c-2.4,0.1-8.7,0.2-12.7,0.2s-10.3-0.1-12.7-0.2v1.6c5,0.9,5.4,3.2,5.4,15.4v32.8c0,10.5,1.6,16.6,5.4,21.5c5.2,6.7,13.7,9.3,25.6,9.3c19.7,0,24.7-13.5,24.7-30.9V88.2c0-22.5,0.1-24.6,6-26.3V60.3z" />
        </g>
        <g id="U">
          <path d="M174.7,60.3v1.6c6.4,1.7,8.2,3.7,8.2,26.2v20.2c0,19.8-5.3,28.5-19.7,28.5c-14.1-1-16.9-7-16.9-27.1V77.3c0-12.2,0.4-14.5,5.4-15.4v-1.6c-2.4,0.1-8.7,0.2-12.7,0.2s-10.3-0.1-12.7-0.2v1.6c5,0.9,5.4,3.2,5.4,15.4v32.8c0,10.5,1.6,16.6,5.4,21.5c5.2,6.7,13.7,9.3,25.6,9.3c19.7,0,24.7-13.5,24.7-30.9V88.2c0-22.5,0.1-24.6,6-26.3v-1.6c-2.3,0.1-4.9,0.2-8.6,0.2C180.8,60.5,177.1,60.4,174.7,60.3z" />
        </g>
        <g id="R">
          <path d="M129.9,138.8c-6.8-1.6-22.3-23.4-26.6-35.4c12.1-4.2,17.5-11.6,17.5-21.1c0-13.1-9.5-22-29-22c-3,0-7.9,0.2-13.8,0.2c-4.2,0-10.6-0.1-13-0.2v1.6c5.1,0.9,5.5,3.2,5.5,15.6v46c0,12.3-0.4,14.6-5.5,15.5v1.7c2.4-0.1,8.8-0.2,12.9-0.2c4.1,0,10.5,0.1,12.9,0.2V139c-5.1-0.9-5.5-3.2-5.5-15.5v-18.6h3.6c10.8,19.8,14.4,28,19.4,32.1c4.4,3.7,9.6,3.9,12.2,3.9c5.7,0,9.6-1.2,9.6-1.2h-0.2V138.8z M90.2,102h-5l0.2-36.1c1.1-0.6,2.9-1,5.4-1c9.8,0,14.2,5,14.2,18.4C105,96.1,100.9,102,90.2,102z" />
        </g>
      </svg>
    );
  }
}

const mapStateToProps = (state: RootState): ReduxProps => ({
  partner: state.operator.partner
});

export const AdminLogo = connect<ReduxProps, {}, {}>(mapStateToProps)(Component);
