import * as React from "react";
import styled from "styled-components";
import _ from "lodash";
import { DataTableCell } from "../../atoms/data-table-cell";
import { DataTableDataItem } from "..";

const RowNoHover = styled.div`
    padding: 10px; 
    border-bottom: 1px solid #dedede;
    float: left; 
    width: 100%;
`;

interface Props {
  data: DataTableDataItem[];
}

export const DataTableFooter: React.StatelessComponent<Props> = props => {
  return (
    <>
      <RowNoHover style={{ fontWeight: "bold" }}>

        <DataTableCell
          width={"40%"}
          text="Total"
        />

        <DataTableCell
          width={"30%"}
          text={`£${(_.sumBy(props.data, item => Number(item.value))).toFixed(2)}`}
        />

        <DataTableCell
          width={"30%"}
          text="&nbsp;" />

      </RowNoHover>
    </>
  )
}
