import * as React from "react";
import { Table } from "react-bootstrap";
import styled from "styled-components";

import { TreatmentPresenter } from "../../../presenters/treatment";
import { TherapistTreatmentsTableRow } from "../../molecules/therapist-treatments-table-row";
import { TherapistRegion, TherapistTier } from "../../../presenters/therapist";

const TableContainer = styled.div`
  box-shadow: 1px 2px 4px rgba(0,0,0,.2);
  margin-top: 10px; 
`;

const TableHeader = styled.div`
  width: 100%;
  background-color: #396174;
  padding: 10px;
  color: #ffffff;
  float: left;
`;

interface Props {
  treatments: TreatmentPresenter[];
  therapistTier: TherapistTier;
  therapistRegions: TherapistRegion[];
  onDelete(urn: string): void;
}

export const TherapistTreatmentsTable: React.StatelessComponent<Props> = props => {
  if (props.treatments.length === 0) {
    return (
      <p>
        No treatments set
      </p>
    );
  }

  const treatmentRows = props.treatments.map(
    (t, i) => <TherapistTreatmentsTableRow
      key={i}
      treatment={t}
      therapistTier={props.therapistTier}
      therapistRegions={props.therapistRegions}
      onDelete={() => props.onDelete(t.urn)}
    />
  );

  return (
    <>
      <TableContainer>
        <TableHeader>
          <div style={{ float: "left" }}>
            Treatments ({props.treatments.length})
          </div>
          <div style={{ float: "right" }}>
            {props.therapistRegions[0]} prices shown
          </div>
        </TableHeader>
        <div style={{ clear: "both" }}></div>

        <Table className="app-table">
          <colgroup style={{ backgroundColor: "#dedede" }}>
            <col width="20%" />
            <col width="30%" />
            <col width="20%" />
            <col width="20%" />
            <col width="10%" />
          </colgroup>

          <thead>
            <tr style={{ backgroundColor: "#efefef" }}>
              <th>Category</th>
              <th>Name</th>
              <th>Duration</th>
              <th>Prices</th>
              <th>&nbsp;</th>
            </tr>
          </thead>

          <tbody>
            {treatmentRows}
          </tbody>
        </Table>
      </TableContainer>
    </>
  );
};
