import styled from "styled-components";

interface Props {
  sm?: string;
  md?: string;
  lg?: string;
  autoWidth?: boolean;
  textAlign?: "left" | "center" | "right" | "justify";
  alignSelf?: "center" | "flex-end" | "flex-start";
  alignItems?: "center" | "flex-end" | "flex-start";
  justifyContent?:
    | "center"
    | "flex-end"
    | "flex-start"
    | "space-around"
    | "space-between"
    | "space-evenly";
}

const columns = 12;
const total = 100;

/**
 * Count dynamic width of column
 * @param span number from 1 to 12
 * @returns css width of column
 */
function getWidth(span: string): string {
  if (!span) {
    return undefined;
  }

  let spanColumn = parseInt(span, 10);

  if (spanColumn > columns) {
    spanColumn = columns;
  }

  const width = (spanColumn / columns) * total;

  return `
    -ms-flex: 0 0 ${width}%;
    flex: 0 0 ${width}%;
    max-width: ${width}%;
  `;
}

/**
 * Dynamic auto width of column in relation to other columns
 * @returns css style for auto width of column
 */
function autoWidth(): string {
  return `
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  `;
}

export const Column = styled.div`
  position: relative;
  min-height: 1px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;

  ${(props: Props) =>
    props.sm
      ? getWidth(props.sm)
      : `
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  `};

  /**
   * Addtitioanl properties for column:
   * auto widh; text alignment; self-alignment
   */
  ${(props: Props) => (props.autoWidth ? autoWidth() : undefined)};
  ${(props: Props) =>
    props.textAlign ? `text-align: ${props.textAlign}` : undefined};
  ${(props: Props) =>
    props.alignSelf ? `align-self: ${props.alignSelf}` : undefined};
  ${(props: Props) =>
    props.alignItems ? `align-items: ${props.alignItems}` : undefined};
  ${(props: Props) =>
    props.justifyContent
      ? `justify-content: ${props.justifyContent}`
      : undefined};

  @media (min-width: 768px) {
    ${(props: Props) => {
      if (props.sm) {
        return getWidth(props.sm);
      }
    }};
  }

  @media (min-width: 992px) {
    ${(props: Props) => {
      if (props.md) {
        return getWidth(props.md);
      }
    }};
  }

  @media (min-width: 1200px) {
    ${(props: Props) => {
      if (props.lg) {
        return getWidth(props.lg);
      }
    }};
  }
`;
