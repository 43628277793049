import * as React from "react";
import * as Select from "react-select";
import { ApiAddress } from "../../../../api/main/booking";

interface BookingAddressProps {
  customer: any;
  addresses: ApiAddress[];
  address: any;
  setBookingAddress: (address: any) => void;
}

export default class BookingAddress extends React.Component<BookingAddressProps, {}> {
  handleAddressChange(option: any) {
    const address = this.props.addresses.find((addr: any) => option.value === addr.urn);
    this.props.setBookingAddress(address);
  }

  render() {
    const { address } = this.props;
    let options: any[] = [];
    if (this.props.addresses) {
      options = this.props.addresses.map((address: any) => {
        const label = `${address.address1}, ${address.address2}, ${address.postcode}`;
        return ({
          value: address.urn,
          label
        });
      });
    }
    const value = (address) ? {
      value: address.urn,
      label: `${address.address1}, ${address.address2}, ${address.postcode}`
    } : {};

    return (
      <div className="customer-edit booking-address">
        <div className="customer-details">
          <div className="detail-heading">
            Select Customer Address
          </div>
        </div>
        <div className="field-edit">
          <div className="select-box">
            <Select
                name="address"
                value={value}
                clearable={false}
                options={options}
                onChange={this.handleAddressChange.bind(this)} />
          </div>
        </div>
      </div>
    );
  }
}
