import * as React from "react";
import { nameStamp, timeStamp } from "../../../utils";
import { Note } from "../../../api/main/notes";

interface Props {
  note: Note;
  onUpdate(urn: string, field: string, value: string | boolean): void;
}

export const BookingNote: React.SFC<Props> = ({note, onUpdate}) => {
  const handleUpdateRequiresAction = (e: React.FormEvent<HTMLInputElement>) => {
    onUpdate(note.urn, "requiresAction", e.currentTarget.checked);
  };

  return (
    <div className="note">
      <p className="header"><span className="name">{nameStamp(note.creatorUrn)}</span> added a comment - {timeStamp(note.timeCreated)}</p>
      <p className="comment">{note.comment}</p>
      <div className="requires-action-container">
        <input
          type="checkbox"
          checked={note.requiresAction}
          onChange={handleUpdateRequiresAction}
        />
        Requires action
      </div>
    </div>
  );
};
