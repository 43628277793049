import { ActionType, getType } from "typesafe-actions";
import { actions } from "../actions";
import { ApiRegion } from "../../../api/main/region";
import * as iassign from "immutable-assign";

type RegionAction = ActionType<typeof actions>;

export interface RegionState {
  regions: ApiRegion[];
  isBusy: boolean;
}

const INITIAL_STATE: RegionState = {
  regions: [],
  isBusy: false
};

export function regionReducer(state: RegionState = INITIAL_STATE, action: RegionAction): RegionState {
  switch (action.type) {
    case getType(actions.requestGetAllRegionsInit): {
      return {
        ...state,
        isBusy: true
      }
    }
    case getType(actions.requestGetAllRegionsSuccess): {
      return iassign(
        state,
        s => {
          s.regions = action.payload.regions;
          s.isBusy = false;
          return s;
        }
      );
    }
    case getType(actions.requestCreateRegionInit): {
      return state;
    }
    case getType(actions.requestCreateRegionSuccess): {
      return {
        ...state,
        regions: [...state.regions, action.payload]
      };
    }
    case getType(actions.requestEditRegionSuccess): {
      return {
        ...state,
        regions: state.regions.map((region) => {
          if (region.id === action.payload.id) {
            return action.payload;
          }
          return region;
        })
      };
    }
    default: return state;
  }
}