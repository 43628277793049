import * as React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { uniq, without } from "lodash";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { TherapistSubMenu } from "../../components/sub/sub-menu";
import { RootState } from "../../reducers";
import { initiateViewTherapistTreatments, updateTherapistField } from "../../model/therapist";
import { TherapistPresenter, inflateTherapist } from "../../presenters/therapist";
import { inflateArrayFromMap } from "../../presenters/utils";
import { TreatmentPresenter, inflateTreatment } from "../../presenters/treatment";
import { TherapistTreatments } from "../../components/organisms/therapist-treatments";
import { initiateSearch } from "../../reducers/treatments/actions";
import { TherapistUpdateField } from "../../api/main/therapist";
import { searchTherapist } from "../../reducers/bookings/actions";

interface ReduxProps {
  therapist: TherapistPresenter;
  therapistTreatments: TreatmentPresenter[];
  searchResults: TreatmentPresenter[];
  isSearching: boolean;
}

type OwnProps = RouteComponentProps<{ urn: string }>;
type Props = OwnProps & ReduxProps & { dispatch: Dispatch<{}> };

class Component extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(initiateViewTherapistTreatments(this.props.match.params.urn));
  }

  handleDeleteTreatment = (urn: string): void => {
    const updatedUrns = without(this.props.therapist.treatmentUrns, urn);

    this.props.dispatch(updateTherapistField(this.props.therapist.urn, TherapistUpdateField.TREATMENTS, updatedUrns));
  }

  handleAddTreatments = async (urns: string[]): Promise<void> => {
    const updatedUrns = uniq(this.props.therapist.treatmentUrns.concat(urns));

    await this.props.dispatch(updateTherapistField(this.props.therapist.urn, TherapistUpdateField.TREATMENTS, updatedUrns));
  }

  handleSearch = (query: string): void => {
    this.props.dispatch(initiateSearch(query));
  }

  handleClickBookings = (): void => {
    this.props.dispatch(searchTherapist(this.props.therapist.urn));
  }

  render() {
    const { therapist } = this.props;

    return (
      <div className="therapist-container">
        <div className="main-section">
          <TherapistTreatments
            therapist={therapist}
            therapistTreatments={this.props.therapistTreatments}
            searchResults={this.props.searchResults}
            onDelete={this.handleDeleteTreatment}
            onSearch={this.handleSearch}
            onAdd={this.handleAddTreatments}
            isSearching={this.props.isSearching}
          />
        </div>

        <div className="side-nav">
          <TherapistSubMenu urn={this.props.match.params.urn} active="treatments" onClickBookings={this.handleClickBookings} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): ReduxProps => {
  const therapist = inflateTherapist(state.therapistsState.therapists[ownProps.match.params.urn]);

  // get inflated list of treatments if therapist loaded
  const therapistTreatments = typeof therapist !== "undefined"
    ? inflateArrayFromMap(state.treatments.treatments, therapist.treatmentUrns, inflateTreatment)
    : [];

  return {
    therapist,
    therapistTreatments,
    searchResults: inflateArrayFromMap(state.treatments.treatments, state.treatments.searchResultUrns, inflateTreatment),
    isSearching: state.treatments.isLoading,
  };
};

export const TherapistTreatmentsContainer = withRouter(connect<ReduxProps, {}, OwnProps>(mapStateToProps)(Component));
