import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

import {RootState} from "../../reducers";
import { actions as treatmentActions, initiateCreateTreatment, createTreatment } from "../../reducers/treatments/actions";
import { CreateState } from "../../reducers/treatments/reducer";
import { CategoryPresenter, inflateCategory } from "../../presenters/category";
import { inflateMapFromMap, inflateArrayFromMap } from "../../presenters/utils";
import { ApiCategory } from "../../api/main/category";
import { TreatmentCreate } from "../../components/organisms/treatment-create";
import { TreatmentUpdateField, TreatmentUpdateValue } from "../../api/main/treatment";

interface ReduxProps {
  categoriesList: CategoryPresenter[];
  categories: {
    [id: string]: CategoryPresenter;
  };
  createState: CreateState;
  isCreating: boolean;
}

type OwnProps = RouteComponentProps<{}>
type Props = ReduxProps & OwnProps & {dispatch: Dispatch<{}>};

class Component extends React.Component<Props> {
  componentWillMount(): void {
    this.props.dispatch(initiateCreateTreatment());
  }

  handleUpdateField = (field: TreatmentUpdateField, value: TreatmentUpdateValue): void => {
    this.props.dispatch(treatmentActions.updateCreateField({field, value}));
  }

  handleSubmit = (): void => {
    this.props.dispatch(createTreatment(this.props.history));
  }

  render(): JSX.Element {
    return (
      <TreatmentCreate
        categories={this.props.categories}
        categoriesList={this.props.categoriesList}
        createState={this.props.createState}
        onUpdateField={this.handleUpdateField}
        onSubmit={this.handleSubmit}
        isCreating={this.props.isCreating}
      />
    );
  }
}

const mapStateToProps = (state: RootState): ReduxProps => {
  return {
    createState: state.treatments.createState,
    categories: inflateMapFromMap<ApiCategory, CategoryPresenter>(state.categories.categories, inflateCategory),
    categoriesList: inflateArrayFromMap<ApiCategory, CategoryPresenter>(
      state.categories.categories,
      state.categories.searchResultUrns,
      inflateCategory,
    ),
    isCreating: state.treatments.isCreating,
  };
};

export const TreatmentCreateContainer = withRouter(connect<ReduxProps, {}, OwnProps>(mapStateToProps)(Component));
