import * as React from "react";
import General from "./sub/general";
import GeneralPartner from "./sub/general-partner";
import Addresses from "./sub/addresses";
import { Credit } from "./sub/credit";
import Referrals from "./sub/referrals";
import EditField from "../../components/sub/edit-field";
import { CustomerSubmenu, CustomerPartnerSubmenu } from "../sub/sub-menu";
import { CustomerUpdateField, CustomerUpdateValue } from "../../reducers/customers/actions";
import { ApiBooking } from "../../api/main/booking";
import { ApiCustomer } from "../../api/main/customer";
import { Badge } from "../atoms/customer-badge";

interface Props {
  isPartner: boolean;
  isBusy: boolean;
  customer: ApiCustomer;
  bookings: ApiBooking[];
  page: string;
  braintreeToken: string;
  transactionBusy: boolean;
  isModalOpen: boolean;

  updateCustomer: (key: CustomerUpdateField, value: CustomerUpdateValue) => void;
  fetchAddresses: () => void;
  updateCustomerAddress: (addressUrn: string, key: string, value: string) => void;
  deleteCustomerAddress: (addressUrn: string, index: number) => void;
  addCustomerAddress: (address: any) => void;
  fetchBraintreeToken: () => void;
  getReferralBookings: () => void;
  transactionAttempt: () => void;
  setTransactionError: (error: string) => void;
  transactionError: string;
  deleteCustomerPaymentMethod: (token: string, index: number) => void;
  displayNotification: (type: string, headline: string, message: string) => void;
  onClickBookings(): void;
  busy: {
    card: boolean;
  };
}

interface State {
  editingFirstName?: boolean;
  editingPhone?: boolean;
  editingEmail?: boolean;
  editingLastName?: boolean;
}

export default class Customer extends React.Component<Props, State> {
  state: State = {
    editingFirstName: false,
    editingLastName: false,
    editingPhone: false,
    editingEmail: false,
  };

  renderDetails = () => {
    switch (this.props.page) {
      case "addresses":
        return <Addresses
          customer={this.props.customer}
          fetchAddresses={this.props.fetchAddresses}
          updateCustomerAddress={this.props.updateCustomerAddress}
          deleteCustomerAddress={this.props.deleteCustomerAddress}
          displayNotification={this.props.displayNotification}
          addCustomerAddress={this.props.addCustomerAddress} />;

      case "credit":
        return <Credit
          customer={this.props.customer} />;

      case "referrals":
        return <Referrals
          customer={this.props.customer}
          getReferralBookings={this.props.getReferralBookings} />;

      default:
        if (this.props.isPartner) {
          return (
            <GeneralPartner
              customer={this.props.customer}
              updateCustomer={this.props.updateCustomer} />
          );
        }
        else {
          return (
            <General
              customer={this.props.customer}
              updateCustomer={this.props.updateCustomer} />
          )
        }
    }
  }

  handleSave = (key: CustomerUpdateField, value: CustomerUpdateValue) => {
    this.props.updateCustomer(key, value);
  }

  render() {
    const { customer } = this.props;

    return (
      <div id="customer">
        <div className="customer-name-wrapper">
          <h1>
            <EditField
              readOnly={this.props.isPartner}
              dbKey={CustomerUpdateField.FIRST_NAME}
              type="text"
              value={customer.firstName}
              saveHandler={this.handleSave}
            />
          </h1>
          <h1>
            <EditField
              readOnly={this.props.isPartner}
              dbKey={CustomerUpdateField.LAST_NAME}
              type="text"
              value={customer.lastName}
              saveHandler={this.handleSave}
            />
          </h1>
          {customer.badges.map((type) => <Badge type={type} />)}
        </div>

        <div className="app-details-row">
          {this.renderDetails()}

          <div className="admin-side-menu">
            {this.props.isPartner ?
              <CustomerPartnerSubmenu urn={customer.urn} active={this.props.page as any} onClickBookings={this.props.onClickBookings} />
              :
              <CustomerSubmenu urn={customer.urn} active={this.props.page as any} onClickBookings={this.props.onClickBookings} />
            }
          </div>
        </div>
      </div>
    );
  }
}
