import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ApiTherapist } from "../../api/main/therapist";
import { ApiTreatment } from "../../api/main/treatment";
import { CategoryPresenter } from "../../presenters/category";

interface TherapistsTreeProps {
  categories: CategoryPresenter[];
  treatments: {
    [urn: string]: ApiTreatment;
  };
  therapists: ApiTherapist[];
  selectedTherapists: string[];
  allTherapistChecked: boolean;
  onMultipleTherapistsToggle(therapists: Set<ApiTherapist>, state: boolean): void;
  onTherapistSelect(therapist: string): void;
  onTherapistShiftSelect(therapist: string, index: number): void;
  onAllSelect(): void;
}

interface TherapistsTreeState {
  categoryStateMap: {
    [urn: string]: boolean;
  };
  categorySelectedMap: {
    [urn: string]: boolean;
  };
  treatmentSelectedMap: {
    [urn: string]: boolean;
  };
}

interface Treatment {
  name: string;
  urn: string;
  therapists?: ApiTherapist[];
}

interface Category {
  name: string;
  urn: string;
  categories?: Category[];
  treatments?: Treatment[];
  therapists?: Set<ApiTherapist>;
  allTreatments?: Set<Treatment>;
  allCategories?: Set<Category>;
}

const CategoryTitle = styled.h2`
  margin: 10px 0;
  font-size: 0.8rem;
  color: #555;
  cursor: pointer;
  font-family: "nunito_sansregular" !important;
`;

const EmptyCategoryTitle = styled.h2`
  margin: 10px 0;
  font-size: 0.8rem;
  color: #555;
`;

const TreatmentTitle = styled.h3`
  margin: 10px 0;
  font-size: 0.8rem;
`;

const TherapistTitle = styled.h4`
font-weight: bold;
`;

const Input = styled.input`
  width: 20px;
  height: 20px;
  margin-top: 0 !important;
  margin-right: 5px !important;
  vertical-align: middle;
`;


export default class TherapistsTree extends React.Component<TherapistsTreeProps, TherapistsTreeState> {
  constructor(props: TherapistsTreeProps) {
    super(props);

    this.state = {
      categoryStateMap: {},
      categorySelectedMap: {},
      treatmentSelectedMap: {},
    };
  }

  toggleCategory(categoryUrn: string) {
    const stateMap = { ...this.state.categoryStateMap };

    stateMap[categoryUrn] = !stateMap[categoryUrn];

    this.setState({
      categoryStateMap: stateMap
    });
  }

  toggleCategoryCheckbox(category: Category, event: any) {
    const checked = event.target.checked;
    const categoryStateMap = { ...this.state.categoryStateMap };
    const categorySelectedMap = { ...this.state.categorySelectedMap };
    const treatmentSelectedMap = { ...this.state.treatmentSelectedMap };

    this.props.onMultipleTherapistsToggle(category.therapists, checked);

    categoryStateMap[category.urn] = checked;
    categorySelectedMap[category.urn] = checked;

    for (const node of category.allCategories) {
      categoryStateMap[node.urn] = checked;
      categorySelectedMap[node.urn] = checked;
    }

    for (const node of category.allTreatments) {
      treatmentSelectedMap[node.urn] = checked;
    }

    this.setState({
      categoryStateMap,
      categorySelectedMap,
      treatmentSelectedMap
    });
  }

  toggleTreatmentCheckbox(treatment: Treatment, event: any) {
    const checked = event.target.checked;
    const treatmentSelectedMap = { ...this.state.treatmentSelectedMap };

    treatmentSelectedMap[treatment.urn] = checked;

    this.props.onMultipleTherapistsToggle(new Set([...treatment.therapists]), checked);

    this.setState({
      treatmentSelectedMap
    });
  }

  toggleTherapistCheckbox(therapist: ApiTherapist) {
    this.props.onTherapistSelect(therapist.urn);
  }

  getCategoriesTree(): Category[] {
    const tree: Category[] = [];
    const categoryMap: Map<string, Category> = new Map();
    const treatmentMap: Map<string, Treatment> = new Map();

    for (let category of this.props.categories) {
      categoryMap.set(category.urn, {
        name: category.name,
        urn: category.urn,
        categories: [],
        treatments: [],
      });
    }

    for (let category of this.props.categories) {
      const currCategory = categoryMap.get(category.urn);
      if (category.isTopLevel) {
        tree.push(currCategory);
      }
      else {
        const parent = categoryMap.get(category.parentUrn);

        parent.categories.push(currCategory);
      }
    }

    for (let entry of Object.entries(this.props.treatments)) {
      const [urn, t] = entry;
      const treatment = {
        name: t.name,
        urn: t.urn,
        therapists: [] as ApiTherapist[],
      };

      treatmentMap.set(urn, treatment);

      if (Array.isArray(t.categoryUrns)) {
        for (let categoryUrn of t.categoryUrns) {
          categoryMap.get(categoryUrn).treatments.push(treatment);
        }
      }
    }

    for (let therapist of Object.values(this.props.therapists)) {
      for (let treatmentUrn of therapist.treatmentUrns) {
        const treatment = treatmentMap.get(treatmentUrn);

        if (treatment) {
          treatment.therapists.push(therapist);
        }
      }
    }

    return this.setTreeSets(tree);
  }

  setTreeSets(tree: Category[]): Category[] {
    const recWalker = (category: Category) => {
      let therapists: Set<ApiTherapist> = new Set();
      let allTreatments: Set<Treatment> = new Set([...category.treatments]);
      let allCategories: Set<Category> = new Set([...category.categories]);

      for (const treatment of category.treatments) {
        therapists = new Set([...therapists, ...treatment.therapists]);
      }

      for (const subCategory of category.categories) {
        recWalker(subCategory);
        therapists = new Set([...therapists, ...subCategory.therapists]);
        allTreatments = new Set([...allTreatments, ...subCategory.allTreatments]);
        allCategories = new Set([...allCategories, ...subCategory.allCategories]);
      }

      category.therapists = therapists;
      category.allTreatments = allTreatments;
      category.allCategories = allCategories;
    };

    for (const category of tree) {
      recWalker(category);
    }

    return tree;
  }

  renderTreatments(category: Category) {
    return category.treatments.map((treatment) => {
      return (
        <li key={`${category.urn}-${treatment.urn}`}>
          <TreatmentTitle>
            {
              treatment.therapists.length
                ? <Input
                  type="checkbox"
                  onChange={this.toggleTreatmentCheckbox.bind(this, treatment)}
                  checked={this.props.allTherapistChecked || this.state.treatmentSelectedMap[treatment.urn]}
                />
                : null
            }
            <Link to={`/treatments/${treatment.urn}`} style={{ color: "#555555", textDecoration: "none", fontWeight: "normal" }}>
              {`${treatment.name} (${treatment.therapists.length})`}
            </Link>
          </TreatmentTitle>
          <ul key={`${category.urn}-${treatment.urn}-therapists`} style={{ listStyleType: "none", fontSize: "0.8rem", fontWeight: "normal" }}>
            {treatment.therapists.map((therapist) => {
              return (
                <li key={`${category.urn}-${treatment.urn}-${therapist.urn}`}>
                  <TherapistTitle>
                    <Input
                      type="checkbox"
                      onChange={this.toggleTherapistCheckbox.bind(this, therapist)}
                      checked={this.props.allTherapistChecked || this.props.selectedTherapists.includes(therapist.urn)}
                    />
                    <Link to={`/therapists/${therapist.urn}`} style={{ color: "#555555", textDecoration: "none", fontSize: "0.8rem", fontWeight: "normal" }}>
                      {therapist.name}
                    </Link>
                  </TherapistTitle>
                </li>
              );
            })}
          </ul>
        </li>
      );
    })
  }

  renderCategory(category: Category) {
    return (
      <ul key={category.urn} style={{ listStyleType: "none" }}>
        <li>
          {category.categories.length || category.treatments.length
            ? <CategoryTitle onClick={() => this.toggleCategory(category.urn)}>
              <Input
                type="checkbox"
                onChange={this.toggleCategoryCheckbox.bind(this, category)}
                onClick={(event) => event.stopPropagation()}
                checked={this.props.allTherapistChecked || this.state.categorySelectedMap[category.urn]}
              />
              {`${category.name} - (${category.therapists.size})`}
              {/* {this.state.categoryStateMap[category.urn] ? ' \u00AB' : ' \u00BB'} */}
            </CategoryTitle>
            : <EmptyCategoryTitle>
              {category.name}
            </EmptyCategoryTitle>
          }

          {this.state.categoryStateMap[category.urn]
            ? <>
              <ul style={{ listStyleType: "none" }}>
                {this.renderTreatments(category)}
              </ul>
              {category.categories.map(this.renderCategory.bind(this))}
            </>
            : null
          }
        </li>
      </ul>
    );
  }

  renderTree() {
    const tree = this.getCategoriesTree();

    return tree.map(this.renderCategory.bind(this));
  }

  render() {
    return (
      <>
        {this.renderTree()}
      </>
    );
  }
}