export type FETCH_CUSTOMERS_ATTEMPT = "customer.FETCH_CUSTOMERS_ATTEMPT";
export const FETCH_CUSTOMERS_ATTEMPT: FETCH_CUSTOMERS_ATTEMPT = "customer.FETCH_CUSTOMERS_ATTEMPT";
export type FETCH_CUSTOMERS_SUCCESS = "customer.FETCH_CUSTOMERS_SUCCESS";
export const FETCH_CUSTOMERS_SUCCESS: FETCH_CUSTOMERS_SUCCESS = "customer.FETCH_CUSTOMERS_SUCCESS";
export type UPDATE_CUSTOMER_SUCCESS = "customer.UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_SUCCESS: UPDATE_CUSTOMER_SUCCESS = "customer.UPDATE_CUSTOMER_SUCCESS";
export type FETCH_ADDRESSES_SUCCESS = "customer.FETCH_ADDRESSES_SUCCESS";
export const FETCH_ADDRESSES_SUCCESS: FETCH_ADDRESSES_SUCCESS = "customer.FETCH_ADDRESSES_SUCCESS";
export type UPDATE_CUSTOMER_ADDRESS_SUCCESS = "customer.UPDATE_CUSTOMER_ADDRESS_SUCCESS";
export const UPDATE_CUSTOMER_ADDRESS_SUCCESS: UPDATE_CUSTOMER_ADDRESS_SUCCESS = "customer.UPDATE_CUSTOMER_ADDRESS_SUCCESS";
export type DELETE_CUSTOMER_ADDRESS_SUCCESS = "customer.DELETE_CUSTOMER_ADDRESS_SUCCESS";
export const DELETE_CUSTOMER_ADDRESS_SUCCESS: DELETE_CUSTOMER_ADDRESS_SUCCESS = "customer.DELETE_CUSTOMER_ADDRESS_SUCCESS";
export type ADD_CUSTOMER_ADDRESS_SUCCESS = "customer.ADD_CUSTOMER_ADDRESS_SUCCESS";
export const ADD_CUSTOMER_ADDRESS_SUCCESS: ADD_CUSTOMER_ADDRESS_SUCCESS = "customer.ADD_CUSTOMER_ADDRESS_SUCCESS";
export type DELETE_CUSTOMER_PAYMENT_METHOD_ATTEMPT = "customer.DELETE_CUSTOMER_PAYMENT_METHOD_ATTEMPT";
export const DELETE_CUSTOMER_PAYMENT_METHOD_ATTEMPT: DELETE_CUSTOMER_PAYMENT_METHOD_ATTEMPT = "customer.DELETE_CUSTOMER_PAYMENT_METHOD_ATTEMPT";
export type DELETE_CUSTOMER_PAYMENT_METHOD_SUCCESS = "customer.DELETE_CUSTOMER_PAYMENT_METHOD_SUCCESS";
export const DELETE_CUSTOMER_PAYMENT_METHOD_SUCCESS: DELETE_CUSTOMER_PAYMENT_METHOD_SUCCESS = "customer.DELETE_CUSTOMER_PAYMENT_METHOD_SUCCESS";
export type FETCH_REFERRAL_BOOKINGS_SUCCESS = "customer.FETCH_REFERRAL_BOOKINGS_SUCCESS";
export const FETCH_REFERRAL_BOOKINGS_SUCCESS: FETCH_REFERRAL_BOOKINGS_SUCCESS = "customer.FETCH_REFERRAL_BOOKINGS_SUCCESS";

export type SET_CUSTOMER_BOOKINGS = "customer.SET_CUSTOMER_BOOKINGS";
export const SET_CUSTOMER_BOOKINGS: SET_CUSTOMER_BOOKINGS = "customer.SET_CUSTOMER_BOOKINGS";
export type SET_CUSTOMER_CARDS = "customer.SET_CUSTOMER_CARDS";
export const SET_CUSTOMER_CARDS: SET_CUSTOMER_CARDS = "customer.SET_CUSTOMER_CARDS";
