import * as React from "react";
import { Button as BootstrapButton } from "react-bootstrap";
import { SpinnerLoader } from "../../molecules/spinner";

interface Props {
  label: string;
  icon?: string;
  className?: string;
  disabled?: boolean;
  isBusy?: boolean;
  style?: string;
  onClick?(): void;
}

export const Button: React.StatelessComponent<Props> = ({ isBusy, label, style = "primary", icon, className, onClick, disabled }) => {
  const iconElement =
    typeof icon !== "undefined" ? (
      <i className={`fa ${icon}`} />
    ) : (
      ""
    );

  const content = isBusy ? <SpinnerLoader /> : <>{iconElement} {label}</>;

  return (
    <BootstrapButton
      bsStyle={style}
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </BootstrapButton>
  );
};
