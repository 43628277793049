import styled from "styled-components";
import { CustomerPaymentMethod } from "../../../../../../api/types/graphql";

const Card = styled.span`
  text-transform: uppercase;
  width: 110px;
  display: inline-grid;
  padding-right: 10px;
`;

interface StripeCardProps {
  paymentMethod: CustomerPaymentMethod;
}

export const StripeCard = ({ paymentMethod } : StripeCardProps) : JSX.Element => (
  <>
    <Card>{paymentMethod.brand}</Card>**** **** **** {paymentMethod.lastFour}
  </>
);
