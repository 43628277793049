import * as api from "../../api/main/promotions";
import { Dispatch } from "redux";
import * as types from "./types";
import { addNotification } from "../notifications/actions";
import { NOTIFICATION_TYPES } from "../notifications/types";

export const actions = {
  fetchPromosAttempt: () => ({
    type: types.FETCH_SEARCH_PROMO_ATTEMPT
  }),
  fetchPromosSuccess: (promos: api.Promotion[]) => ({
    type: types.FETCH_SEARCH_PROMO_SUCCESS,
    payload: { promos }
  })
};

export function fetch(code: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.fetchPromosAttempt());

    try {
      const promos = await api.searchPromotions(code);

      dispatch(actions.fetchPromosSuccess(promos));
    }
    catch (error) {
      console.error("ERROR FETCHING PROMOS", error);
      dispatch(addNotification(NOTIFICATION_TYPES.danger, error.message, "Error in fetching promos. Try refreshing the browser if problem persists"));
    }
  };
}
