export type TRANSACTION_ATTEMPT = "transaction.TRANSACTION_ATTEMPT";
export const TRANSACTION_ATTEMPT: TRANSACTION_ATTEMPT = "transaction.TRANSACTION_ATTEMPT";
export type TRANSACTION_SUCCESS = "transaction.TRANSACTION_SUCCESS";
export const TRANSACTION_SUCCESS: TRANSACTION_SUCCESS = "transaction.TRANSACTION_SUCCESS";
export type VOID_TRANSACTION = "VOID_TRANSACTION";
export const VOID_TRANSACTION: VOID_TRANSACTION = "VOID_TRANSACTION";
export type TRANSACTION_ERROR = "transaction.TRANSACTION_ERROR";
export const TRANSACTION_ERROR: TRANSACTION_ERROR = "transaction.TRANSACTION_ERROR";

export type OPEN_TRANSACTION_MODAL = "transaction.OPEN_TRANSACTION_MODAL";
export const OPEN_TRANSACTION_MODAL: OPEN_TRANSACTION_MODAL = "transaction.OPEN_TRANSACTION_MODAL";
export type CLOSE_TRANSACTION_MODAL = "transaction.CLOSE_TRANSACTION_MODAL";
export const CLOSE_TRANSACTION_MODAL: CLOSE_TRANSACTION_MODAL = "transaction.CLOSE_TRANSACTION_MODAL";

export type FETCH_BRAINTREE_TOKEN_SUCCESS = "transaction.FETCH_BRAINTREE_TOKEN_SUCCESS";
export const FETCH_BRAINTREE_TOKEN_SUCCESS: FETCH_BRAINTREE_TOKEN_SUCCESS = "transaction.FETCH_BRAINTREE_TOKEN_SUCCESS";

export type OPEN_NEW_CARD_MODAL = "transaction.OPEN_NEW_CARD_MODAL";
export const OPEN_NEW_CARD_MODAL: OPEN_NEW_CARD_MODAL = "transaction.OPEN_NEW_CARD_MODAL";
export type CLOSE_NEW_CARD_MODAL = "transaction.CLOSE_NEW_CARD_MODAL";
export const CLOSE_NEW_CARD_MODAL: CLOSE_NEW_CARD_MODAL = "transaction.CLOSE_NEW_CARD_MODAL";

export type SUBMIT_NEW_CARD_SUCCESS = "transaction.SUBMIT_NEW_CARD_SUCCESS";
export const SUBMIT_NEW_CARD_SUCCESS: SUBMIT_NEW_CARD_SUCCESS = "transaction.SUBMIT_NEW_CARD_SUCCESS";
