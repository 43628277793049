import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ApiRegion } from "../../api/main/region";
import { fetchAllRegions, createRegion, editRegion } from "../../reducers/regions/actions";
import { RootState } from "../../reducers/index";
import styled from "styled-components";
import * as Modal from "react-modal";
import { Button } from "react-bootstrap";
import RegionTabs from "../../components/region/RegionTabs";
import Table from "../../components/table";
import { Row } from "../../components/grid";
import { PartnerListing } from "../../components/organisms/treatment-explorer-table-row/partner-index";
import { RegionListing } from "../../components/organisms/treatment";
import Spinner from "react-spinkit";


const modalStyling: Modal.Styles = {
  content: {
    height: "fit-content",
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",
    border: "0px",
    backgroundColor: "#efefef"
  },
  overlay: {
    zIndex: 3
  }
};

const PageContainer = styled.div`
    padding: 0 20px 20px 20px;
    float: left; 
    width: 100%; 
`;

const RegionsContainer = styled.div`
    background-color: #ffffff; 
    width: 100%; 
    float: left; 
    box-shadow: 1px 2px 4px rgba(0, 0, 0, .2); 
`;

const Cell = styled.div`
    float: left; 
`;

const ModalContainer = styled.div`
    background-color: #efefef;
`;

const ModalHeading = styled.div`
    background-color: #396174;
    color: #ffffff;
    margin: 0px;
    padding: 10px; 
`;

const ModalBody = styled.div`
    margin: 10px 0 0 0;
    padding: 0 20px 20px 20px;
`;

interface ReduxProps {
  regions: ApiRegion[];
  isBusy: boolean;
}

type Props = ReduxProps & { dispatch: Dispatch<{}> };
interface IState {
  isCreateModalVisible: boolean;
  isEditModalVisible: boolean;
  name: string;
  classicCommission: number;
  eliteCommission: number;
  rodSurcharge: number;
  b2bCommission: number;
  isActive: boolean;
  id: string;
}

class Component extends React.Component<Props, IState> {
  state: IState;

  constructor(props: Props) {
    super(props);
    this.state = {
      isCreateModalVisible: false,
      isEditModalVisible: false,
      name: "",
      classicCommission: null,
      eliteCommission: null,
      rodSurcharge: null,
      b2bCommission: null,
      isActive: false,
      id: "",
    };
  }

  async componentDidMount() {
    await this.props.dispatch(fetchAllRegions());
  }

  toggleCreateModal = (resetFields: boolean) => {
    if (resetFields) {
      this.setState({
        name: "",
        classicCommission: null,
        eliteCommission: null,
        rodSurcharge: null,
        b2bCommission: null,
        isActive: true,
      });
    }

    this.setState({ isCreateModalVisible: !this.state.isCreateModalVisible });
  }

  toggleEditModal = () => {
    this.setState({ isEditModalVisible: !this.state.isEditModalVisible });
  }

  toggleEditModalAndSet = (id: string) => {
    const region = this.props.regions.find(r => r.id == id);

    this.setState({
      id: region.id,
      name: region.name,
      classicCommission: region.classicCommission,
      eliteCommission: region.eliteCommission,
      rodSurcharge: region.rodSurcharge,
      b2bCommission: region.b2bCommission,
      isActive: region.isActive,
    });

    this.toggleEditModal();
  }

  handleInputChange(key: string, e: any) {
    let obj = {};
    obj[key] = e.target.value;
    this.setState(obj);
  }

  handleInputNumberChange(key: string, e: any) {
    let obj = {};
    obj[key] = Number(e.target.value);
    this.setState(obj);
  }

  handleCheckboxChange = (): void => {
    this.setState({ isActive: !this.state.isActive });
  }

  submitCreateRegion = () => {
    const region = {
      name: this.state.name,
      classicCommission: this.state.classicCommission,
      eliteCommission: this.state.eliteCommission,
      rodSurcharge: this.state.rodSurcharge,
      b2bCommission: this.state.b2bCommission,
      isActive: this.state.isActive,
    };

    this.props.dispatch(createRegion(region));
    this.toggleCreateModal(true);
  }

  submitEditRegion = () => {
    const region = {
      name: this.state.name,
      classicCommission: this.state.classicCommission,
      eliteCommission: this.state.eliteCommission,
      rodSurcharge: this.state.rodSurcharge,
      b2bCommission: this.state.b2bCommission,
      isActive: this.state.isActive,
    };

    this.props.dispatch(editRegion(this.state.id, region));
    this.toggleEditModal();
  }

  mapDataset(regions: ApiRegion[]) {
    return regions.map((region: ApiRegion) => {
      const obj = {};
      obj["_id"] = region.id;
      Object.entries(region).map(([key, value]) => {
        switch (key) {

          case "eliteCommission":
          case "classicCommission": {
            const component = (
              <Cell key={key} style={{ width: "15%", textTransform: "capitalize" }}>
                <a style={{ color: "#000000", textDecoration: "none" }}>
                  {value}% &nbsp;
                </a>
              </Cell>
            );
            obj[key] = component;
            break;
          }

          case "rodSurcharge": {
            const component = (
              <Cell key={key} style={{ width: "15%", textTransform: "capitalize" }}>
                <a style={{ color: "#000000", textDecoration: "none" }}>
                  £{value} &nbsp;
                </a>
              </Cell>
            );
            obj[key] = component;
            break;
          }
          case "b2bCommission": {
             const component = (
              <Cell key={key} style={{ width: "10%", textTransform: "capitalize" }}>
                <a style={{ color: "#000000", textDecoration: "none" }}>
                  {value ? `${value} %` : "-"} &nbsp;
                </a>
              </Cell>
            );
            obj[key] = component;
            break;           
          }

          case "isActive": {
             const component = (
              <Cell key={key} style={{ width: "10%", textTransform: "capitalize" }}>
                <a style={{ color: "#000000", textDecoration: "none" }}>
                  {value ? "Yes" : "No"} &nbsp;
                </a>
              </Cell>
            );
            obj[key] = component;
            break;           
          }

          default: {
            const component = (
              <Cell key={key} style={{ width: "15%", textTransform: "capitalize" }}>
                <a style={{ color: "#000000", textDecoration: "none" }}>
                  {value} &nbsp;
                </a>
              </Cell>
            );
            obj[key] = component;
            break;
          }
        }
      });
      return obj;
    });
  }

  renderRegions = () => {
    if (this.props.isBusy) {
      return (
        <PartnerListing>
          <div className="spinner-container">
            <Spinner name="pulse" fadeIn="none" />
          </div>
        </PartnerListing>
      );
    }
    if (this.props.regions.length == 0) {
      return <RegionListing><Row><Cell>No regions to display</Cell></Row></RegionListing>
    }
  }

  renderTable() {
    const columns = [
      { title: "Name", key: "name", width: "15%" },
      { title: "Classic Commission", key: "classicCommission", width: "15%" },
      { title: "Elite Commission", key: "eliteCommission", width: "15%" },
      { title: "ROD surcharge", key: "rodSurcharge", width: "15%" },
      { title: "B2B Commission", key: "b2bCommission", width: "10%" },
      { title: "Active", key: "isActive", width: "10%" },
      { title: "Date Created", key: "dateCreated", width: "15%" },
    ]

    const rowActions = [
      {
        name: "Edit",
        onClick: (id: string) => this.toggleEditModalAndSet(id),
      }
    ]

    const dataset = this.mapDataset(this.props.regions)

    return (
      <Table
        tableName={"Region"}
        dataset={dataset}
        columns={columns}
        createEntryAction={() => this.toggleCreateModal(false)}
        rowActions={rowActions}
        emptyMessage={"No Regions to show"}
        isLoading={this.props.isBusy} />
    )
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.isCreateModalVisible}
          style={modalStyling}>

          <ModalContainer>

            <ModalHeading>
              Create new region
            </ModalHeading>

            <ModalBody>
              <form>
                <RegionTabs
                  tabsId="region-create-tab"
                  name={this.state.name}
                  isActive={this.state.isActive}
                  classicCommission={this.state.classicCommission}
                  eliteCommission={this.state.eliteCommission}
                  rodSurcharge={this.state.rodSurcharge}
                  b2bCommission={this.state.b2bCommission}
                  inputTextChange={this.handleInputChange.bind(this)}
                  inputNumberChange={this.handleInputNumberChange.bind(this)}
                  checkboxChange={this.handleCheckboxChange.bind(this)}
                />
                <div className="main-region-details-row" style={{ float: "left" }}>
                  <Button onClick={() => this.submitCreateRegion()} className="btn-create-job">Save</Button>
                  <Button onClick={() => this.toggleCreateModal(true)} className="btn-cancel-job">Cancel</Button>
                </div>
              </form>
            </ModalBody>

          </ModalContainer>

        </Modal>

        <Modal
          isOpen={this.state.isEditModalVisible}
          style={modalStyling}>

          <ModalContainer>

            <ModalHeading>
              Edit region: {this.state.name}
            </ModalHeading>

            <ModalBody>
              <form>
                <RegionTabs
                  tabsId="region-edit-tab"
                  name={this.state.name}
                  isActive={this.state.isActive}
                  classicCommission={this.state.classicCommission}
                  eliteCommission={this.state.eliteCommission}
                  rodSurcharge={this.state.rodSurcharge}
                  b2bCommission={this.state.b2bCommission}
                  inputTextChange={this.handleInputChange.bind(this)}
                  inputNumberChange={this.handleInputNumberChange.bind(this)}
                  checkboxChange={this.handleCheckboxChange.bind(this)}
                />
                <div className="main-region-details-row" style={{ float: "left" }}>
                  <Button onClick={() => this.submitEditRegion()} className="btn-create-job">Save</Button>
                  <Button onClick={() => this.toggleEditModal()} className="btn-cancel-job">Cancel</Button>
                </div>
              </form>

            </ModalBody>

          </ModalContainer>

        </Modal>

        <div>
          <div className="region-creation-row">

            <PageContainer>
              <RegionsContainer>
                {this.renderTable()}
              </RegionsContainer>
            </PageContainer>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): ReduxProps => {
  return {
    regions: state.regions.regions,
    isBusy: state.regions.isBusy
  };
};

export const RegionContainer = connect<ReduxProps, {}, {}>(mapStateToProps)(Component);
