import * as React from "react";
import * as Spinner from "react-spinkit";

import { Log } from "./log";
import { ActivityLog } from "../../api/main/audit";

interface Props {
  logs: ActivityLog[];
  isLoading: boolean;
}

export const BookingActivity: React.SFC<Props> = ({isLoading, logs}) => (
  <div>
    <h1>Booking</h1>

    {isLoading && <Spinner name="pulse" fadeIn="none" />}

    <ul className="log-wrapper">
      {logs.map((log, i: number) => <Log key={i} log={log} />)}
    </ul>
  </div>
);
