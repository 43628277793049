import * as React from "react";
import styled from "styled-components";

const Button = styled.button`
  color: #fff;
  background-color: #586b72;
  border: 0;
  border-radius: 50%;
  font-family: 'nunito_sanssemibold';
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  onClick?(): void;
}

export const PlusButton: React.SFC<Props> = props => (
  <Button onClick={props.onClick}><i className="fa fa-plus"></i></Button>
);