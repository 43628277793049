import * as React from "react";
import * as Select from "react-select";
import styled from "styled-components";

import { NewFieldLabel } from "../../atoms/field-label";

const SelectStyled = styled(Select)`
  flex-grow: 1;
`;

const PaymentStatusSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

const PaymentStatusSelectWrapper = styled.div`
  width: 200px;
  margin-right: 45px;
`;

interface Props {
  selected: string;
  onChange(option: string): void;
}

const OPTIONS = ["ALL", "UNPAID"].map((s) => ({
  label: s,
  value: s,
}));

export const PaymentStatusSelect: React.StatelessComponent<Props> = (props) => {
  return (
    <PaymentStatusSelectContainer>
      <PaymentStatusSelectWrapper>
        <NewFieldLabel>Payment Status</NewFieldLabel>
        <SelectStyled
          value={props.selected}
          clearable={false}
          options={OPTIONS}
          onChange={(option: Select.Option) =>
            props.onChange(option.value as string)
          }
        />
      </PaymentStatusSelectWrapper>
    </PaymentStatusSelectContainer>
  );
};
