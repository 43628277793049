import * as React from "react";

import {
  DataTableDataItem,
  DataTableTwoColumnData,
  DataTableThreeColumnData,
  DataTableHeading,
  DataTableSubHeading,
  DataTableNoData,
  DataTableFooter,
} from "../../molecules/";

interface DataTableHeading {
  caption: string;
  event: {
    text: string,
    onClick(): void,
  };
}

interface DataTableHeadingItem {
  name: string;
  width: string;
}

interface DataTableNoData {
  text: string;
  event: {
    text: string,
    onClick(): void,
  };
}

export enum DataTableTemplateEnum {
  TWO_COLUMNS = "TWO_COLUMNS",
  THREE_COLUMNS = "THREE_COLUMNS",
}

interface Props {
  heading: DataTableHeading;
  subHeading: DataTableHeadingItem[];
  tableData: DataTableDataItem[];
  noData: DataTableNoData;
  dataTableTemplate: DataTableTemplateEnum;
  includeFooter?: boolean;
  toggleCreate(): void;
  toggleEdit(urn: string): void;
  toggleDelete(urn: string): void;
}

export const DataTable: React.StatelessComponent<Props> = ({
  heading,
  subHeading,
  tableData,
  noData,
  dataTableTemplate,
  includeFooter,
  toggleCreate,
  toggleEdit,
  toggleDelete,
}) => {

  return (
    <>
      <DataTableHeading
        caption={heading.caption}
        event={{
          onClick: heading.event.onClick,
          text: heading.event.text,
        }} />

      <DataTableSubHeading headings={subHeading} />

      {dataTableTemplate === DataTableTemplateEnum.THREE_COLUMNS &&
        <DataTableThreeColumnData
          data={tableData}
          editHandler={toggleEdit}
          deleteHandler={toggleDelete} />
      }

      {dataTableTemplate === DataTableTemplateEnum.TWO_COLUMNS &&
        <DataTableTwoColumnData
          data={tableData}
          editHandler={toggleEdit}
          deleteHandler={toggleDelete} />
      }

      {tableData.length === 0 &&
        <DataTableNoData
          text={noData.text}
          event={{
            text: noData.event.text,
            onClick: toggleCreate,
          }} />
      }

      {includeFooter && tableData.length !== 0 &&
        <DataTableFooter data={tableData} />
      }
    </>
  );
};
