import axios, {AxiosRequestConfig} from "axios";
import * as Cookie from "js-cookie";
import { Dispatch } from "redux";

import { actions } from "../../reducers/operator/actions";

export function get<T = {}>(url: string, dispatch?: Dispatch<{}>) {
  const config = {
    headers: { "authorization": Cookie.get("token") }
  };
  try {
    return axios.get<T>(url, config);
  }
  catch {
    dispatch(actions.logOut());
  }
}

export function post<T = {}>(url: string, data: object) {
  const config = {
    headers: { "authorization": Cookie.get("token") }
  };
  return axios.post<T>(url, data, config).then(res => res);
}

export function put (url: string, data: object) {
  const config: AxiosRequestConfig = {
    headers: { "authorization": Cookie.get("token") }
  };
  return axios.put(url, data, config).then(res => res);
}

export function patch (url: string, data: object) {
  const config: AxiosRequestConfig = {
    headers: { "authorization": Cookie.get("token") }
  };
  return axios.patch(url, data, config).then(res => res);
};

export function deletion (url: string) {
  const config: AxiosRequestConfig = {
    headers: { "authorization": Cookie.get("token") }
  };
  return axios.delete(url, config).then(res => res);
}
