import { Dispatch } from "redux";
import * as Cookie from "js-cookie";
import axios from "axios";
import { History } from "history";

import * as types from "./types";
import * as api from "../../api/main/customer";
import { config as configuration } from "../../config";
import { addNotification } from "../notifications/actions";
import { NOTIFICATION_TYPES } from "../notifications/types";
import { MappedCustomers } from "./reducer";
import { ApiAddress } from "../../api/main/booking";
import { ActionDispatch } from "..";

export enum CustomerUpdateField {
  PASSWORD = "PASSWORD",
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  MAILING_LIST_ID = "MAILING_LIST_ID",
  DEVICE_TOKEN = "DEVICE_TOKEN",
  TIME_INDEXED = "TIME_INDEXED",
  NOTES_THERAPIST = "NOTES_THERAPIST",
  NOTES_RUUBY = "NOTES_RUUBY",
  BLACK_LABEL = "BLACK_LABEL",
  B2B = "B2B",
  IS_BLOCKED = "IS_BLOCKED",
  IS_TRUSTED_CUSTOMER = "IS_TRUSTED_CUSTOMER",
  VIP = "VIP"
}

export type CustomerUpdateValue = string | number | boolean;

function mapCustomerToObjectAndArray(data: any[]): [MappedCustomers, string[]] {
  const mappedCustomers = data.reduce<MappedCustomers>((customersMap, customer: any) => {
    customersMap[customer.urn] = { customer, bookingUrns: [] };
    return customersMap;
  }, {});
  const sortedCustomers = data.map((customer: any) => customer.urn);

  return [mappedCustomers, sortedCustomers];
}

export const actionCreators = {
  fetchCustomersAttempt: () => ({
    type: types.FETCH_CUSTOMERS_ATTEMPT,
    payload: { }
  }),
  fetchCustomersSuccess: (customers: MappedCustomers, sortedCustomers: string[]) => ({
    type: types.FETCH_CUSTOMERS_SUCCESS,
    payload: { customers, sortedCustomers }
  }),
  updateCustomerSuccess: (urn: string, key: string, value: string | number | boolean) => ({
    type: types.UPDATE_CUSTOMER_SUCCESS,
    payload: { urn, key, value }
  }),
  fetchAddressesSuccess: (urn: string, addresses: ApiAddress[]) => ({
    type: types.FETCH_ADDRESSES_SUCCESS,
    payload: { urn, addresses }
  }),
  fetchReferralBookingsSuccess: (customerUrn: string, bookings: ApiReferralBooking[]) => ({
    type: types.FETCH_REFERRAL_BOOKINGS_SUCCESS,
    payload: { customerUrn, bookings }
  }),
  addCustomerAddressSuccess: (customerUrn: string, address: ApiAddress) => ({
    type: types.ADD_CUSTOMER_ADDRESS_SUCCESS,
    payload: { customerUrn, address }
  }),
  updateCustomerAddressSuccess: (customerUrn: string, address: ApiAddress) => ({
    type: types.UPDATE_CUSTOMER_ADDRESS_SUCCESS,
    payload: { address, customerUrn }
  }),
  deleteCustomerAddressSuccess: (customerUrn: string, addressUrn: string) => ({
    type: types.DELETE_CUSTOMER_ADDRESS_SUCCESS,
    payload: { addressUrn, customerUrn }
  }),
  deleteCustomerPaymentMethodAttempt: () => ({
    type: types.DELETE_CUSTOMER_PAYMENT_METHOD_ATTEMPT
  }),
  deleteCustomerPaymentMethodSuccess: (urn: string, token: string, index: number) => ({
    type: types.DELETE_CUSTOMER_PAYMENT_METHOD_SUCCESS,
    payload: { urn, token, index }
  }),

  setCustomerBookings: (customerUrn: string, bookingUrns: string[]) => ({
    type: types.SET_CUSTOMER_BOOKINGS,
    payload: { customerUrn, bookingUrns }
  }),

  setCustomerCards: (customerUrn: string, cards: any[]) => ({
    type: types.SET_CUSTOMER_CARDS,
    payload: { customerUrn, cards },
  })
};

export function fetchCustomers() {
  return (dispatch: Dispatch<any>) => {
    const token = Cookie.get("token");
    const config = {
      headers: { "authorization": token, "Content-Type": "application/json" },
    };
    const url = `${configuration.adminPanelApiUrl}/customers`;

    dispatch(actionCreators.fetchCustomersAttempt());
    axios.get(url, config)
      .then((response: any) => {
        let mappings = mapCustomerToObjectAndArray(response.data);

        dispatch(actionCreators.fetchCustomersSuccess(mappings[0], mappings[1]));
      })
      .catch((err: any) => {
        console.error("ERROR FETCHING CUSTOMERS", err);
        dispatch(addNotification(NOTIFICATION_TYPES.danger, err.message, "Error fetching customers. Refresh the booking data and try again."));
      });
  };
}

export function fetchCustomer(urn: string) {
  return (dispatch: Dispatch<any>) => {
    const token = Cookie.get("token");
    const config = {
      headers: { "authorization": token, "Content-Type": "application/json" },
    };
    const url = `${configuration.adminPanelApiUrl}/customers/${urn}`;

    dispatch(actionCreators.fetchCustomersAttempt());
    return axios.get(url, config)
      .then((response: any) => {
        let mappings: any[] = mapCustomerToObjectAndArray([response.data]);

        dispatch(actionCreators.fetchCustomersSuccess(mappings[0], mappings[1]));
      })
      .catch((err: any) => {
        console.error("ERROR FETCHING CUSTOMERS", err);
        dispatch(addNotification(NOTIFICATION_TYPES.danger, err.message, "Error fetching customers. Refresh the booking data and try again."));
      });
  };
}


export function fetchCustomerCards(urn: string): ActionDispatch {
  return async dispatch => {
    try {
      const token = Cookie.get("token");
      const config = {
        headers: { "authorization": token, "Content-Type": "application/json" },
      };
      const url = `${configuration.adminPanelApiUrl}/customers/${urn}`;

      const response = await axios.get<api.ApiCustomer>(url, config);

      dispatch(actionCreators.setCustomerCards(urn, response.data.cards));
    }
    catch (err) {
      console.error("ERROR FETCHING CUSTOMER CARDS", err);
      dispatch(addNotification(NOTIFICATION_TYPES.danger, err.message, "Error fetching customers cards. Refresh the booking data and try again."));
    }
  };
}

export function searchCustomers(query: string) {
  return (dispatch: Dispatch<any>) => {
    const token = Cookie.get("token");
    const config = {
      headers: { "authorization": token, "Content-Type": "application/json" },
    };
    const url = (query.length === 0) ? `${configuration.adminPanelApiUrl}/customers` : `${configuration.adminPanelApiUrl}/customers?q=${query}`;

    dispatch(actionCreators.fetchCustomersAttempt());
    axios.get(url, config)
      .then((response: any) => {
        let mappings: any[] = mapCustomerToObjectAndArray(response.data);

        dispatch(actionCreators.fetchCustomersSuccess(mappings[0], mappings[1]));
      })
      .catch((err: any) => {
        console.error("ERROR FETCHING CUSTOMERS", err);
        dispatch(addNotification(NOTIFICATION_TYPES.danger, err.message, `Error fetching customers with query ${query}. Refresh the booking data and try again.`));
      });
  };
}

export function handleCustomerSubmit(data: any, history: History) {
  return (dispatch: Dispatch<any>) => {
    const token = Cookie.get("token");
    const config = {
      headers: { "authorization": token, "Content-Type": "application/json" },
    };
    const url = `${configuration.adminPanelApiUrl}/customers`;
    delete data.showForm;

    axios.post(url, data, config)
      .then((response: any) => {
        history.push(`/customers/${response.data.customerUrn}/general`);
      })
      .catch((err: any) => {
        handleError(err, dispatch);
      });
  };
}

export function updateCustomer(urn: string, key: CustomerUpdateField, value: CustomerUpdateValue): ActionDispatch {
  return async dispatch => {
    const token = Cookie.get("token");
    const config = {
      headers: {
        "authorization": token,
        "Content-Type": "application/json"
      }
    };
    const url = `${configuration.adminPanelApiUrl}/customers/${urn}`;
    const data = { key, value };

    try {
      await axios.patch(url, data, config);
      await dispatch(fetchCustomer(urn));

      dispatch(actionCreators.updateCustomerSuccess(urn, key, value));
      dispatch(addNotification(NOTIFICATION_TYPES.success, "Success", "Customer details succesfully updated"));
    }
    catch (err) {
      handleError(err, dispatch);
    }
  };
}

export function fetchAddresses(urn: string) {
  return (dispatch: Dispatch<any>) => {
    const token = Cookie.get("token");
    const config = {
      headers: {
        "authorization": token,
        "Content-Type": "application/json"
      }
    };
    const url = `${configuration.adminPanelApiUrl}/customers/${urn}/addresses`;

    axios.get(url, config)
      .then((response: any) => {
        dispatch(actionCreators.fetchAddressesSuccess(urn, response.data));
      })
      .catch((err: any) => {
        console.error("ERROR FETCHING ADDRESSES", err);
        dispatch(addNotification(NOTIFICATION_TYPES.danger, err.message, "Error fetching customer addresses"));
      });
  };
}

export function addCustomerAddress(customerUrn: string, address: any) {
  return (dispatch: Dispatch<any>) => {
    const token = Cookie.get("token");
    const config = {
      headers: {
        "authorization": token,
        "Content-Type": "application/json"
      }
    };
    const url = `${configuration.adminPanelApiUrl}/customers/${customerUrn}/addresses`;

    axios.post(url, { address }, config)
      .then((response: any) => {
        address.urn = response.data.addressUrn;
        dispatch(actionCreators.addCustomerAddressSuccess(customerUrn, address));
      })
      .catch((err: any) => {
        console.error("ERROR ADDING ADDRESS", err);
        dispatch(addNotification(NOTIFICATION_TYPES.danger, err.response.data.message, "Error adding customer address"));
      });
  };
}

export function updateCustomerAddress(customerUrn: string, addressUrn: string, key: string, value: string) {
  return async (dispatch: Dispatch<any>, getState: any) => {
    let address = getState().customersState.customers[customerUrn].addresses.find((addr: any) => addr.urn === addressUrn);
    address = address.set(key, value);

    try {
      await api.updateCustomerAddress(customerUrn, address);
      dispatch(actionCreators.updateCustomerAddressSuccess(customerUrn, address));
      dispatch(addNotification(NOTIFICATION_TYPES.success, "Success", "Customer address updated"));
    } catch (err) {
      console.error("ERROR UPDATING ADDRESS", err);
      dispatch(addNotification(NOTIFICATION_TYPES.danger, err.message, "Error updating customer addresses"));
    }
  };
}

export function deleteCustomerAddress(customerUrn: string, addressUrn: string, _index: number) {
  return (dispatch: Dispatch<any>) => {
    const token = Cookie.get("token");
    const config = {
      headers: { "authorization": token, "Content-Type": "application/json" }
    };
    const url = `${configuration.adminPanelApiUrl}/customers/${customerUrn}/addresses/${addressUrn}`;

    axios.delete(url, config)
      .then(() => {
        dispatch(actionCreators.deleteCustomerAddressSuccess(customerUrn, addressUrn));
        dispatch(addNotification(NOTIFICATION_TYPES.success, "Success", "Customer address deleted!"));
      })
      .catch((err: any) => {
        console.error("ERROR DELETING ADDRESS", err);
        dispatch(addNotification(NOTIFICATION_TYPES.danger, err.message, "Error deleting customer address"));
      });
  };
}

export function deleteCustomerPaymentMethod(customerUrn: string, token: string, index: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actionCreators.deleteCustomerPaymentMethodAttempt());

    try {
      await api.deletePaymentMethod(customerUrn, token);
      dispatch(actionCreators.deleteCustomerPaymentMethodSuccess(customerUrn, token, index));
    } catch (error) {
      console.error("ERROR DELETING PAYMENT METHOD", error);
      dispatch(addNotification(NOTIFICATION_TYPES.danger, error.message, "Error deleting payment method"));
    }
  };
}

export interface ApiReferralBooking {
  urn: string;
  customerUrn: string;
  date: string;
}

interface ApiReferralBookingsResponse {
  data: ApiReferralBooking[];
}

export function getReferralBookings(customerUrn: string): ActionDispatch {
  return (dispatch: Dispatch<any>) => {
    const token = Cookie.get("token");
    const config = {
      headers: {
        "authorization": token,
        "Content-Type": "application/json"
      }
    };
    const url = `${configuration.adminPanelApiUrl}/customers/${customerUrn}/referral-bookings`

    axios.get(url, config)
      .then((response: ApiReferralBookingsResponse) => {
        dispatch(actionCreators.fetchReferralBookingsSuccess(customerUrn, response.data));
      })
      .catch((err: any) => {
        console.error("ERROR FETCHING REFERRAL BOOKINGS", err);
        dispatch(addNotification(NOTIFICATION_TYPES.danger, err.message, "Error fetching customer referral bookings"));
      });
  };
}

function handleError(err: any, dispatch: Dispatch<any>) {
  let message;

  if (err.response) {
    message = err.response.data.message;
  }
  else {
    message = "Unknown error";
  }
  dispatch(addNotification(NOTIFICATION_TYPES.danger, message, ""));
}
