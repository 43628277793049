import * as moment from "moment-timezone";
import {extendMoment} from "moment-range";

import { config } from "../../config";

const rangeMoment = extendMoment(moment);

export function calculateSurchargeForStartTime(tier: string, startTime: moment.Moment, endTime: moment.Moment): number {
  let surcharge = 0;

  if (
    typeof startTime === "undefined" ||
    typeof endTime === "undefined"
  ) {
    return surcharge;
  }

  const compoundPercentage = (o: number, n: number): number => o + n + (o * n);
  const startDate = startTime.format("YYYY-MM-DD");

  const dateRule = config.surchargeRules.dates[startDate];
  if (typeof dateRule !== "undefined") {
    surcharge = compoundPercentage(surcharge, dateRule.surcharge);
  }

  // time rules only apply to black label account
  if (tier === "BLACK_LABEL") {
    const timeRules = config.surchargeRules.times.filter(r => {
      // A range object matching the specified rule
      const ruleRange = rangeMoment.range(
        moment.tz(`${startDate}T${r.start}`, config.timezone),
        moment.tz(`${startDate}T${r.end}`, config.timezone),
      );
      // Excluding the end time from matching means we won't need to do xx:59 for end times
      // We don't want excludeStart because for example a rule starting at 00:00 needs to match
      const exclusionOptions = { excludeEnd: true };
      // Only start time matters when determining the surcharge rule
      return ruleRange.contains(startTime, exclusionOptions);
    });

    if (timeRules.length > 0) {
      surcharge = compoundPercentage(surcharge, timeRules[0].surcharge);
    }
  }

  return surcharge;
}
