import { ActionType, getType } from "typesafe-actions";
import * as iassign from "immutable-assign";

import { actions } from "../actions";
import { ApiTreatment, TreatmentUpdateField, TreatmentUpdateValue } from "../../../api/main/treatment";
type TreatmentAction = ActionType<typeof actions>;

export interface UpdateState {
  [field: string]: {
    isEditing: boolean;
    isUpdating: boolean;
  };
}

export interface CreateState {
  [TreatmentUpdateField.NAME]: TreatmentUpdateValue;
  [TreatmentUpdateField.DESCRIPTION]: TreatmentUpdateValue;
  [TreatmentUpdateField.CATEGORIES]: TreatmentUpdateValue;
  [TreatmentUpdateField.LONDON_PRICE_CLASSIC]: TreatmentUpdateValue;
  [TreatmentUpdateField.LONDON_PRICE_ELITE]: TreatmentUpdateValue;
  [TreatmentUpdateField.LONDON_PRICE_BLACK_LABEL]: TreatmentUpdateValue;
  [TreatmentUpdateField.LONDON_PRICE_HOSPITALITY]: TreatmentUpdateValue;
  [TreatmentUpdateField.DURATION]: TreatmentUpdateValue;
  [TreatmentUpdateField.IS_ADDON]: TreatmentUpdateValue;
}

export interface TreatmentState {
  treatments: {
    [urn: string]: ApiTreatment;
  };
  searchResultUrns: string[];
  isLoading: boolean;
  createState: CreateState;
  isCreating: boolean;
}

const CREATE_STATE: CreateState = {
  [TreatmentUpdateField.NAME]: "",
  [TreatmentUpdateField.DESCRIPTION]: "",
  [TreatmentUpdateField.CATEGORIES]: [],
  [TreatmentUpdateField.LONDON_PRICE_CLASSIC]: 0,
  [TreatmentUpdateField.LONDON_PRICE_ELITE]: 0,
  [TreatmentUpdateField.LONDON_PRICE_BLACK_LABEL]: 0,
  [TreatmentUpdateField.LONDON_PRICE_HOSPITALITY]: 0,
  [TreatmentUpdateField.DURATION]: 0,
  [TreatmentUpdateField.IS_ADDON]: false,
};

const INITIAL_STATE: TreatmentState = {
  treatments: {},
  searchResultUrns: [],
  isLoading: false,
  createState: CREATE_STATE,
  isCreating: false,
};

export function treatmentReducer(state: TreatmentState = INITIAL_STATE, action: TreatmentAction): TreatmentState {
  switch (action.type) {
    case getType(actions.requestInit): {
      return iassign(
        state,
        s => {
          s.isLoading = true;

          return s;
        }
      );
    }

    case getType(actions.updateSearchResults): {
      return iassign(
        state,
        s => {
          s.searchResultUrns = action.payload.treatments.map(t => t.urn);

          return s;
        }
      );
    }

    case getType(actions.requestSuccess): {
      return iassign(
        iassign(
          state,
          s => {
            s.isLoading = false;

            return s;
          }
        ),

        s => s.treatments,
        treatmentsState => {
          // set each treatment in the map, keyed by URN
          Object.values(action.payload.treatments).forEach(tr => treatmentsState[tr.urn] = tr);

          return treatmentsState;
        }
      );
    }

    case getType(actions.updateCreateField): {
      return iassign(
        state,
        (s) => s.createState,
        s => {
          s[action.payload.field] = action.payload.value;

          return s;
        },
      );
    }

    case getType(actions.createInit): {
      return iassign(
        iassign(
          state,
          s => {
            s.isCreating = false;

            return s;
          }),

        s => s.createState,
        _c => {
          return CREATE_STATE;
        }
      );
    }

    case getType(actions.createAttempt): {
      return iassign(
        state,
        s => {
          s.isCreating = true;

          return s;
        }
      );
    }

    case getType(actions.createSuccess): {
      return iassign(
        state,
        s => {
          s.isCreating = false;

          return s;
        }
      );
    }

    case getType(actions.createFailure): {
      return iassign(
        state,
        s => {
          s.isCreating = false;

          return s;
        }
      );
    }

    case getType(actions.deleteSuccess): {
      return iassign(
        state,
        s => s.treatments,
        t => {
          // delete supplied URN from treatments
          delete t[action.payload];

          return t;
        }
      );
    }

    default: return state;
  }
}
