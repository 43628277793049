import * as React from "react";
import { Link } from "react-router-dom";
import { Badge } from "../atoms/customer-badge";
import { CustomerBadge } from "../../api/main/customer";

interface Props {
  customer: any;
}

export const CustomerPanel: React.SFC<Props> = ({ customer }) => (
  <div className="customer-panel">
    <div className="avatar">
      {(customer.firstName as string).substr(0, 1).toUpperCase()}
      {(customer.lastName as string).substr(0, 1).toUpperCase()}
    </div>

    <div className="details">
      <h2>
        <Link to={`/customers/${customer.urn}/general`}>
          {customer.firstName} {customer.lastName}
        </Link>{" "}
        {customer.badges.map((type: CustomerBadge) => <Badge type={type}/>)}

      </h2>
      <p>{customer.email}</p>
      <p>{customer.telephone}</p>
    </div>
  </div>
);
