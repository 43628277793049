import { createStandardAction } from "typesafe-actions";

import * as therapistApi from "../../api/main/therapist";

interface RequestSuccessPayload {
  therapists: therapistApi.ApiTherapist[];
}

interface ErrorPayload {
  error: string;
}

interface UpdateTherapistBusyKeyPayload {
  key: string;
}

interface DeleteImagePayload {
  urn: string;
  index: string;
}

interface UpdateImagePayload {
  urn: string;
  index: string;
  image: therapistApi.TherapistImageData;
}

interface UpdateImageCropPayload {
  urn: string;
  index: string;
  crop: therapistApi.CropDimensionSet;
  aspect: string;
}

interface UpdateImageAttemptPayload {
  index: string;
}

interface UpdateTherapistPostcode {
  therapistUrn: string;
  postcodeUrn: string;
  enabled: boolean;
}

export const therapistActions = {
  fetchTherapistsAttempt: createStandardAction("therapists/FETCH_THERAPISTS_ATTEMPT")<void>(),
  fetchTherapistsSuccess: createStandardAction("therapists/FETCH_THERAPISTS_SUCCESS")<RequestSuccessPayload>(),
  fetchTherapistsFail: createStandardAction("therapists/FETCH_THERAPISTS_ERROR")<ErrorPayload>(),
  updateTherapistFieldBusyToggle: createStandardAction("therapists/UPDATE_THERAPIST_FIELD_BUSY")<UpdateTherapistBusyKeyPayload>(),
  persistImageCropAttempt: createStandardAction("therapists/PERSIST_THERAPIST_IMAGE_CROP_ATTEMPT")<void>(),
  persistImageCropSuccess: createStandardAction("therapists/PERSIST_THERAPIST_IMAGE_CROP_SUCCESS")<void>(),
  persistImageCropFail: createStandardAction("therapists/PERSIST_THERAPIST_IMAGE_CROP_FAIL")<ErrorPayload>(),
  deleteImageAttempt: createStandardAction("therapists/DELETE_THERAPIST_IMAGE_ATTEMPT")<void>(),
  deleteImageSuccess: createStandardAction("therapists/DELETE_THERAPIST_IMAGE_SUCCESS")<void>(),
  deleteImageState: createStandardAction("therapists/DELETE_THERAPIST_IMAGE")<DeleteImagePayload>(),
  updateTherapistImageState: createStandardAction("therapists/UPDATE_THERAPIST_IMAGE")<UpdateImagePayload>(),
  updateImageCrop: createStandardAction("therapists/UPDATE_THERAPIST_IMAGE_CROP")<UpdateImageCropPayload>(),
  addTherapistImageAttempt: createStandardAction("therapists/ADD_THERAPIST_IMAGE_ATTEMPT")<UpdateImageAttemptPayload>(),
  updateTherapistPostcodes: createStandardAction("therapists/UPDATE_THERAPIST_POSTCODE")<UpdateTherapistPostcode>(),
  addReviewInit: createStandardAction("therapists/ADD_THERAPIST_REVIEW_INIT")<void>(),
  addReviewSuccess: createStandardAction("therapists/ADD_THERAPIST_REVIEW_SUCCESS")<void>(),
  addReviewFail: createStandardAction("therapists/ADD_THERAPIST_REVIEW_ERROR")<ErrorPayload>(),
};
