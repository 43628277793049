import { AxiosResponse } from "axios";

import { config } from "../../config";
import * as api from "./request";

export interface ApiIgnoredTherapist {
  urn: string;
  name: string;
}

const urlBase = `${config.adminPanelApiUrl}/ignored-therapist`;

export async function fetch(): Promise<ApiIgnoredTherapist[]> {

  const res = await api.get<{ therapists: ApiIgnoredTherapist[] }>(urlBase);

  return res.data.therapists;
}

export async function addTherapist(therapist: ApiIgnoredTherapist): Promise<ApiIgnoredTherapist> {

  const res: AxiosResponse = await api.put(urlBase, therapist);

  return res.data.therapist;
}

export async function removeTherapist(urn: string): Promise<void> {

  const url = `${urlBase}/${urn}`;

  await api.deletion(url);
}