import styled from "styled-components";

export const Well = styled.div`
  background-color: white;
  padding: 7px 10px;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: space-between;

  > * {
    :not(:first-child) {
      margin-top: 7px;
    }

    :not(:last-child) {
      margin-bottom: 7px;
    }
  }
`;

interface WellRowProps {
  justifyContent?: string;
}

export const WellRow = styled.div`
  display: flex;
  flex-direction: row;

  &&& {
    ${(props: WellRowProps) =>
      props.justifyContent
        ? `justify-content: ${props.justifyContent}`
        : undefined}
  }

  > * {
    :not(:first-child) {
      margin-left: 7px;
    }

    :not(:last-child) {
      margin-right: 7px;
    }
  }
`;
