import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { JobDeleteModal } from "../../components/sub/alerts/job-delete";
import { ApiJob } from "../../api/main/job";
import {
  fetchAllJobs,
  deleteJob,
  createJob,
  editJob,
} from "../../reducers/jobs/actions";
import { RootState } from "../../reducers/index";
import { config } from "../../config";
import styled from "styled-components";
import * as Modal from "react-modal";
import { Button } from "react-bootstrap";
import Switch from "react-bootstrap-switch";
import _ from "lodash";
import Table from "../../components/table";

const modalStyling: Modal.Styles = {
  content: {
    height: "fit-content",
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",
    border: "0px",
    backgroundColor: "#efefef",
  },
  overlay: {
    zIndex: 3,
  },
};

const PageContainer = styled.div`
  padding: 0 20px 20px 20px;
  float: left;
  width: 100%;
`;

const JobsContainer = styled.div`
  background-color: #ffffff;
  width: 100%;
  float: left;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
`;

const JobListing = styled.div`
  background-color: #ffffff;
  float: left;
  width: 100%;
`;

const Cell = styled.div`
  float: left;
`;

const Row = styled.div`
  padding: 10px;
  border-bottom: 1px solid #dedede;
  float: left;
  width: 100%;
  &:hover {
    background: #efefef;
  }
`;

const ModalContainer = styled.div`
  background-color: #efefef;
`;

const ModalHeading = styled.div`
  background-color: #396174;
  color: #ffffff;
  margin: 0px;
  padding: 10px;
`;

const ModalBody = styled.div`
  margin: 10px 0 0 0;
  padding: 0 20px 20px 20px;
`;

interface ReduxProps {
  jobs: ApiJob[];
  isLoading: boolean;
}

interface Column {
  title: string;
  key: string;
  width: string;
}

type Props = ReduxProps & { dispatch: Dispatch<{}> };
interface IState {
  isCreateModalVisible: boolean;
  isEditModalVisible: boolean;
  isDeleteModalVisible: boolean;
  title: string;
  description: string;
  salary: string;
  joblocation: string;
  email: string;
  isFeatured: string;
  id: string;
}

class Component extends React.Component<Props, IState> {
  state: IState;

  constructor(props: Props) {
    super(props);
    this.state = {
      isCreateModalVisible: false,
      isEditModalVisible: false,
      isDeleteModalVisible: false,
      title: "",
      description: "",
      salary: "",
      joblocation: "",
      email: config.defaultEmailAddress,
      isFeatured: "0",
      id: "",
    };
  }

  async componentDidMount() {
    await this.props.dispatch(fetchAllJobs());
  }

  toggleDeleteModal = () => {
    this.setState({ isDeleteModalVisible: !this.state.isDeleteModalVisible });
  };

  toggleCreateModal = (resetFields: boolean) => {
    if (resetFields) {
      this.setState({ title: "" });
      this.setState({ description: "" });
      this.setState({ salary: "" });
      this.setState({ joblocation: "" });
      this.setState({ email: config.defaultEmailAddress });
      this.setState({ isFeatured: "0" });
    }

    this.setState({ isCreateModalVisible: !this.state.isCreateModalVisible });
  };

  toggleDeleteModalAndSet = (id: string) => {
    this.setState({ id });
    this.toggleDeleteModal();
  };

  toggleEditModal = () => {
    this.setState({ isEditModalVisible: !this.state.isEditModalVisible });
  };

  toggleEditModalAndSet = (id: string) => {
    const job = this.props.jobs.find((j) => j.id == id);

    this.setState({ id: job.id });
    this.setState({ title: job.title });
    this.setState({ description: job.description });
    this.setState({ salary: job.salary });
    this.setState({ joblocation: job.joblocation });
    this.setState({ email: job.email });
    this.setState({ isFeatured: job.isFeatured });

    this.toggleEditModal();
  };

  handleInputChange(key: string, e: any) {
    let obj = {};
    obj[key] = e.target.value;
    this.setState(obj);
  }

  handleCheckboxChange = (): void => {
    this.setState({ isFeatured: this.state.isFeatured == "1" ? "0" : "1" });
  };

  submitDeleteJob = () => {
    this.props.dispatch(deleteJob(this.state.id));
    this.toggleDeleteModal();
  };

  mapDataset = (data: ApiJob[], columns: Column[]) => {
    return data.map((job) => {
      const obj = {};
      obj["_id"] = job.id;

      columns.map(({ key }) => {
        const value = job[key];

        switch (key) {
          case "id": {
            obj[key] = (
              <Cell key={key} style={{ width: "10%" }}>
                <a style={{ color: "black", textDecoration: "none" }}>
                  {job.id}
                </a>
              </Cell>
            );

            break;
          }

          case "title": {
            const component = (
              <Cell key={key} style={{ width: "50%" }}>
                <a
                  href={`${config.customerWebsiteUrl}jobs/${job.id}`}
                  style={{
                    textDecoration: "underline",
                    color: "#666666",
                  }}
                  target="_blank"
                >
                  {value}
                </a>
              </Cell>
            );

            obj[key] = component;
            break;
          }

          case "isFeatured": {
            const component = (
              <Cell key={key} style={{ width: "10%" }}>
                <a style={{ color: "black", textDecoration: "none" }}>
                  {"" + (Boolean(parseInt(value)) ? "Yes" : "No")}
                </a>
              </Cell>
            );

            obj[key] = component;
            break;
          }

          default: {
            const component = (
              <Cell key={key} style={{ width: "10%" }}>
                <a style={{ color: "black", textDecoration: "none" }}>
                  {"" + value}
                </a>
              </Cell>
            );

            obj[key] = component;
            break;
          }
        }
      });

      return obj;
    });
  };

  submitCreateJob = () => {
    const job = {
      id: Math.floor(Math.random() * Math.floor(10000)).toString(), // TODO remove this
      title: this.state.title,
      description: this.state.description,
      salary: this.state.salary,
      joblocation: this.state.joblocation,
      email: this.state.email,
      isFeatured: this.state.isFeatured,
    };
    this.props.dispatch(createJob(job));
    this.toggleCreateModal(true);
  };

  submitEditJob = () => {
    const job = {
      id: this.state.id,
      title: this.state.title,
      description: this.state.description,
      salary: this.state.salary,
      joblocation: this.state.joblocation,
      email: this.state.email,
      isFeatured: this.state.isFeatured,
    };

    this.props.dispatch(editJob(job));
    this.toggleEditModal();
  };

  renderJobs = () => {
    if (this.props.jobs.length == 0) {
      return (
        <JobListing>
          <Row>
            <Cell>No jobs to display</Cell>
          </Row>
        </JobListing>
      );
    }

    // Order jobs by title (ascending)
    const jobsOrdered = _.orderBy(
      this.props.jobs,
      (o) => {
        return new Number(o.title);
      },
      ["asc"],
    );

    return jobsOrdered.map((job, key) => (
      <JobListing key={key}>
        <Row>
          <Cell style={{ width: "5%" }}>{job.id}</Cell>
          <Cell style={{ width: "50%" }}>
            <a
              href={`${config.customerWebsiteUrl + "jobs/" + job.id}`}
              target="_blank"
              style={{ textDecoration: "underline", color: "#666666" }}
            >
              {job.title}
            </a>
          </Cell>
          <Cell style={{ width: "10%" }}>
            {job.isFeatured == "0" ? "No" : "Yes"}
          </Cell>
          <Cell style={{ width: "10%" }}>{job.joblocation}</Cell>
          <Cell style={{ width: "10%" }}>{job.email}</Cell>
          <Cell style={{ float: "right" }}>
            <a
              href="#"
              onClick={() => this.toggleEditModalAndSet(job.id)}
              style={{ textDecoration: "underline", color: "#666666" }}
            >
              Edit
            </a>
            &nbsp;|&nbsp;
            <a
              href="#"
              onClick={() => this.toggleDeleteModalAndSet(job.id)}
              style={{ textDecoration: "underline", color: "#666666" }}
            >
              Delete
            </a>
          </Cell>
        </Row>
      </JobListing>
    ));
  };

  renderTable = () => {
    const columns: Column[] = [
      { title: "#", key: "id", width: "10%" },
      { title: "Title", key: "title", width: "50%" },
      { title: "Is featured", key: "isFeatured", width: "10%" },
      { title: "Location", key: "joblocation", width: "10%" },
      { title: "Email", key: "email", width: "10%" },
    ];

    const rowActions = [
      {
        name: "Edit",
        onClick: (id: string) => this.toggleEditModalAndSet(id),
      },
      {
        name: "Delete",
        onClick: (id: string) => this.toggleDeleteModalAndSet(id),
      },
    ];

    const dataset = this.mapDataset(this.props.jobs, columns);

    return (
      <Table
        tableName={"Jobs"}
        createEntryAction={() => this.toggleCreateModal(true)}
        columns={columns}
        dataset={dataset}
        rowActions={rowActions}
        emptyMessage="No jobs to show"
        isLoading={this.props.isLoading}
      />
    );
  };

  render() {
    return (
      <div>
        <Modal isOpen={this.state.isCreateModalVisible} style={modalStyling}>
          <ModalContainer>
            <ModalHeading>Create new job</ModalHeading>

            <ModalBody>
              <form>
                <div className="main-job-details-row">
                  <div className="detail-job-heading">Job title</div>
                  <div className="detail-job-value">
                    <input
                      type="text"
                      value={this.state.title}
                      onChange={this.handleInputChange.bind(this, "title")}
                      autoFocus={true}
                    />
                  </div>
                </div>
                <div className="main-job-details-row">
                  <div className="detail-job-heading">Job description</div>
                  <div className="detail-job-value">
                    <textarea
                      value={this.state.description}
                      onChange={this.handleInputChange.bind(
                        this,
                        "description",
                      )}
                      rows={6}
                    />
                  </div>
                </div>
                <div className="main-job-details-row">
                  <div className="detail-job-heading">Job salary</div>
                  <div className="detail-job-value">
                    <input
                      type="text"
                      value={this.state.salary}
                      onChange={this.handleInputChange.bind(this, "salary")}
                    />
                  </div>
                </div>
                <div className="main-job-details-row">
                  <div className="job-title">
                    <div className="detail-job-heading">Job location</div>
                    <div className="detail-job-value">
                      <input
                        type="text"
                        value={this.state.joblocation}
                        onChange={this.handleInputChange.bind(
                          this,
                          "joblocation",
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="main-job-details-row">
                  <div className="detail-job-heading">Job email</div>
                  <div className="detail-job-value">
                    <input
                      type="text"
                      value={this.state.email}
                      onChange={this.handleInputChange.bind(this, "email")}
                    />
                  </div>
                </div>
                <div className="main-job-details-row">
                  <div className="detail-job-heading">Featured</div>
                  <div
                    className="detail-job-value"
                    style={{ textAlign: "left" }}
                  >
                    <Switch
                      value={this.state.isFeatured == "1"}
                      onChange={this.handleCheckboxChange}
                      bsSize="small"
                      onText="YES"
                      offText="NO"
                    />
                  </div>
                </div>

                <div className="main-job-details-row" style={{ float: "left" }}>
                  <Button
                    onClick={() => this.submitCreateJob()}
                    className="btn-create-job"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => this.toggleCreateModal(true)}
                    className="btn-cancel-job"
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </ModalBody>
          </ModalContainer>
        </Modal>

        <Modal isOpen={this.state.isEditModalVisible} style={modalStyling}>
          <ModalContainer>
            <ModalHeading>Edit exist job: {this.state.title}</ModalHeading>

            <ModalBody>
              <form>
                <div className="main-job-details-row">
                  <div className="detail-job-heading">Job title</div>
                  <div className="detail-job-value">
                    <input
                      type="text"
                      value={this.state.title}
                      onChange={this.handleInputChange.bind(this, "title")}
                      autoFocus={true}
                    />
                  </div>
                </div>
                <div className="main-job-details-row">
                  <div className="detail-job-heading">Job description</div>
                  <div className="detail-job-value">
                    <textarea
                      value={this.state.description}
                      onChange={this.handleInputChange.bind(
                        this,
                        "description",
                      )}
                      rows={6}
                    />
                  </div>
                </div>
                <div className="main-job-details-row">
                  <div className="detail-job-heading">Job salary</div>
                  <div className="detail-job-value">
                    <input
                      type="text"
                      value={this.state.salary}
                      onChange={this.handleInputChange.bind(this, "salary")}
                    />
                  </div>
                </div>
                <div className="main-job-details-row">
                  <div className="job-title">
                    <div className="detail-job-heading">Job location</div>
                    <div className="detail-job-value">
                      <input
                        type="text"
                        value={this.state.joblocation}
                        onChange={this.handleInputChange.bind(
                          this,
                          "joblocation",
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="main-job-details-row">
                  <div className="detail-job-heading">Job email</div>
                  <div className="detail-job-value">
                    <input
                      type="text"
                      value={this.state.email}
                      onChange={this.handleInputChange.bind(this, "email")}
                    />
                  </div>
                </div>
                <div className="main-job-details-row">
                  <div className="detail-job-heading">Featured</div>
                  <div
                    className="detail-job-value"
                    style={{ textAlign: "left" }}
                  >
                    <Switch
                      value={this.state.isFeatured == "1"}
                      onChange={this.handleCheckboxChange}
                      bsSize="small"
                      onText="YES"
                      offText="NO"
                    />
                  </div>
                </div>

                <div className="main-job-details-row" style={{ float: "left" }}>
                  <Button
                    onClick={() => this.submitEditJob()}
                    className="btn-create-job"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => this.toggleEditModal()}
                    className="btn-cancel-job"
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </ModalBody>
          </ModalContainer>
        </Modal>

        <JobDeleteModal
          isOpen={this.state.isDeleteModalVisible}
          title="Job deletion"
          message={"Are you sure you want to delete this job?"}
          closeModal={this.toggleDeleteModal}
          handleConfirm={this.submitDeleteJob}
        />

        <div>
          <div className="job-creation-row">
            <PageContainer>
              <JobsContainer>{this.renderTable()}</JobsContainer>
            </PageContainer>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): ReduxProps => {
  return {
    jobs: state.jobs.jobs,
    isLoading: state.jobs.isLoading,
  };
};

export const JobContainer = connect<ReduxProps, {}, {}>(mapStateToProps)(
  Component,
);
