import { AxiosResponse } from "axios";
import { config } from "../../config";
import * as api from "./request";

export interface ApiReview {
  urn: string;
  customerUrn: string;
  reviewer: string;
  salonUrn: string;
  rating: number;
  comment: string;
  bookingUrn: string;
  createdDate: string;
  custom_from: string;
}

export async function fetchReviews(therapistUrn: string): Promise<ApiReview[]> {
  const url = `${config.adminPanelApiUrl}/reviews/${therapistUrn}`;

  const response: AxiosResponse = await api.get(url);

  return response.data;
}

export async function deleteReview(bookingUrn: string): Promise<void> {
  const url = `${config.adminPanelApiUrl}/reviews/${bookingUrn}/`;

  await api.deletion(url);
}
