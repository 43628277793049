import * as React from "react";
import styled from "styled-components";
import Switch from "react-bootstrap-switch";

import { TreatmentPresenter, TreatmentRegion } from "../../../presenters/treatment";
import { EditableTitle } from "../../molecules/editable-title";
import { UpdateState } from "../../../reducers/treatments/reducer";
import { EditableTextArea } from "../../molecules/editable-text-area";
import { FieldDateTime } from "../../molecules/field-datetime";
import { EditableCategoryList } from "../../molecules/editable-category-list";
import { CategoryPresenter } from "../../../presenters/category";
import { TreatmentUpdateField } from "../../../api/main/treatment";
import { EditableNumber } from "../../molecules/editable-number";
import { EditableRegionalPrice } from "../../molecules/editable-regional-price";
import { ApiRegion } from "../../../api/main/region";
import { TreatmentFeeConfig } from "../../../api/main/configuration";

const HeaderContainer1 = styled.div`
    width: 100%; 
    float: left;
    background-color: #396174;
    padding: 10px;
    margin-top: 10px;
`;

const HeaderText1 = styled.div`
    color: #ffffff;
    float: left;
`;

const Row = styled.div`
    padding: 10px; 
    border-bottom: 1px solid #dedede;
    float: left; 
    width: 100%;
    &:hover {
      background: #efefef;
    }
`;
export const RegionListing = styled.div`
    background-color: #ffffff;
    float: left; 
    width: 100%;
`;

const Cell = styled.div`
    float: left;
    display: inline-table;
`;

const HeaderText2 = styled.div`
    float: left;
`;

const HeaderContainer2 = styled.div`
    background-color: #dedede;
    color: #666666; 
    float: left;
    padding: 10px;
    width: 100%;
`;

interface Props {
  isLoading: boolean;
  treatmentFeeConfig: TreatmentFeeConfig;
  treatment: TreatmentPresenter;
  categories: {
    [id: string]: CategoryPresenter;
  };
  categoriesList: CategoryPresenter[];
  updateState: UpdateState;
  regions: ApiRegion[];
  onStartEditing(field: string): void;
  onFinishEditing(field: string): void;
  onSubmit(field: string, value: string | boolean | number | string[], region?: string): void;
}

export const Treatment: React.StatelessComponent<Props> = props => {
  const treatment = props.treatment;

  if (typeof treatment === "undefined" || typeof props.updateState === "undefined") {
    return <div></div>;
  }

  const updateStateChecker = (key: string, isEditing: boolean) => {
    if (!props.updateState[key]) {
      return false;
    }

    return isEditing ? props.updateState[key].isEditing : props.updateState[key].isUpdating;
  }

  const regionalTreatmentPrices = props.regions.map(r => {
    let rTreatment: TreatmentRegion;

    if (typeof treatment.regions !== "undefined" && treatment.regions.find(t => t.name === r.name)) {
      rTreatment = treatment.regions.find(t => t.name === r.name);
    }
    else {
      rTreatment = {
        name: r.name,
        classic: 0,
        elite: 0,
        blackLabel: 0,
        hospitality: 0,
      };
    }

    const currency = rTreatment.name.toLowerCase() === "zurich" ? "CHF" : "GBP";

    return <div>
      <RegionListing key={r.name}>
        <Row>
          <Cell style={{ width: "25%", textTransform: "capitalize" }}>
            {rTreatment.name} {rTreatment.name.toLowerCase() === "london" ? "(Default)" : ""}
          </Cell>
          <Cell style={{ width: "15%" }}>
            <EditableRegionalPrice
              currency={currency}
              treatmentFeeConfig={props.treatmentFeeConfig}  
              label=""
              value={typeof rTreatment.classic !== "undefined" ? rTreatment.classic.toString() : "0"}
              isUpdating={updateStateChecker(`${TreatmentUpdateField.REGIONAL_CLASSIC_PRICE}_${r.name}`, false)}
              isEditing={updateStateChecker(`${TreatmentUpdateField.REGIONAL_CLASSIC_PRICE}_${r.name}`, true)}
              onStartEditing={() => props.onStartEditing(`${TreatmentUpdateField.REGIONAL_CLASSIC_PRICE}_${r.name}`)}
              onFinishEditing={() => props.onFinishEditing(`${TreatmentUpdateField.REGIONAL_CLASSIC_PRICE}_${r.name}`)}
              onSubmit={(value: number | string | boolean) => props.onSubmit(TreatmentUpdateField.REGIONAL_CLASSIC_PRICE, value, r.name)}
              emptyLabel="No price currently set" />
          </Cell>

          <Cell style={{ width: "15%" }}>
            <EditableRegionalPrice
              currency={currency}
              treatmentFeeConfig={props.treatmentFeeConfig}
              label=""
              value={typeof rTreatment.elite !== "undefined" ? rTreatment.elite.toString() : "0"}
              isUpdating={updateStateChecker(`${TreatmentUpdateField.REGIONAL_ELITE_PRICE}_${r.name}`, false)}
              isEditing={updateStateChecker(`${TreatmentUpdateField.REGIONAL_ELITE_PRICE}_${r.name}`, true)}
              onStartEditing={() => props.onStartEditing(`${TreatmentUpdateField.REGIONAL_ELITE_PRICE}_${r.name}`)}
              onFinishEditing={() => props.onFinishEditing(`${TreatmentUpdateField.REGIONAL_ELITE_PRICE}_${r.name}`)}
              onSubmit={(value: number | string | boolean) => props.onSubmit(TreatmentUpdateField.REGIONAL_ELITE_PRICE, value, r.name)}
              emptyLabel="No price currently set" />
          </Cell>

          <Cell style={{ width: "15%" }}>
            <EditableRegionalPrice
              currency={currency}
              treatmentFeeConfig={props.treatmentFeeConfig}  
              label=""
              value={typeof rTreatment.blackLabel !== "undefined" ? rTreatment.blackLabel.toString() : "0"}
              isUpdating={updateStateChecker(`${TreatmentUpdateField.REGIONAL_BLACK_LABEL_PRICE}_${r.name}`, false)}
              isEditing={updateStateChecker(`${TreatmentUpdateField.REGIONAL_BLACK_LABEL_PRICE}_${r.name}`, true)}
              onStartEditing={() => props.onStartEditing(`${TreatmentUpdateField.REGIONAL_BLACK_LABEL_PRICE}_${r.name}`)}
              onFinishEditing={() => props.onFinishEditing(`${TreatmentUpdateField.REGIONAL_BLACK_LABEL_PRICE}_${r.name}`)}
              onSubmit={(value: number | string | boolean) => props.onSubmit(TreatmentUpdateField.REGIONAL_BLACK_LABEL_PRICE, value, r.name)}
              emptyLabel="No price currently set" />
          </Cell>

          <Cell style={{ width: "15%" }}>
            <EditableRegionalPrice
              currency={currency}
              treatmentFeeConfig={props.treatmentFeeConfig}  
              label=""
              value={typeof rTreatment.hospitality !== "undefined" ? rTreatment.hospitality.toString() : "0"}
              isUpdating={updateStateChecker(`${TreatmentUpdateField.REGIONAL_HOSPITALITY_PRICE}_${r.name}`, false)}
              isEditing={updateStateChecker(`${TreatmentUpdateField.REGIONAL_HOSPITALITY_PRICE}_${r.name}`, true)}
              onStartEditing={() => props.onStartEditing(`${TreatmentUpdateField.REGIONAL_HOSPITALITY_PRICE}_${r.name}`)}
              onFinishEditing={() => props.onFinishEditing(`${TreatmentUpdateField.REGIONAL_HOSPITALITY_PRICE}_${r.name}`)}
              onSubmit={(value: number | string | boolean) => props.onSubmit(TreatmentUpdateField.REGIONAL_HOSPITALITY_PRICE, value, r.name)}
              emptyLabel="No price currently set" />
          </Cell>
        </Row>
      </RegionListing>
    </div>
  });

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <EditableTitle
            value={treatment.name}
            isUpdating={updateStateChecker(TreatmentUpdateField.NAME, false)}
            isEditing={updateStateChecker(TreatmentUpdateField.NAME, true)}
            onStartEditing={() => props.onStartEditing(TreatmentUpdateField.NAME)}
            onFinishEditing={() => props.onFinishEditing(TreatmentUpdateField.NAME)}
            onSubmit={(value: number | string | boolean) => props.onSubmit(TreatmentUpdateField.NAME, value)}
            emptyLabel="No name currently set"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <EditableTextArea
            label="Description"
            value={treatment.description}
            isUpdating={updateStateChecker(TreatmentUpdateField.DESCRIPTION, false)}
            isEditing={updateStateChecker(TreatmentUpdateField.DESCRIPTION, true)}
            onStartEditing={() => props.onStartEditing(TreatmentUpdateField.DESCRIPTION)}
            onFinishEditing={() => props.onFinishEditing(TreatmentUpdateField.DESCRIPTION)}
            onSubmit={(value: number | string | boolean) => props.onSubmit(TreatmentUpdateField.DESCRIPTION, value)}
            emptyLabel="No description currently set"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <EditableCategoryList
            label="Categories"
            values={treatment.categoryUrns}
            categories={props.categories}
            categoriesList={props.categoriesList}
            isUpdating={updateStateChecker(TreatmentUpdateField.CATEGORIES, false)}
            isEditing={updateStateChecker(TreatmentUpdateField.CATEGORIES, true)}
            onStartEditing={() => props.onStartEditing(TreatmentUpdateField.CATEGORIES)}
            onFinishEditing={() => props.onFinishEditing(TreatmentUpdateField.CATEGORIES)}
            onSubmit={(value: string[]) => props.onSubmit(TreatmentUpdateField.CATEGORIES, value)}
          />
        </div>
      </div>

      <HeaderContainer1>
        <HeaderText1>
          Prices by region ({props.regions.length})
        </HeaderText1>
      </HeaderContainer1>
      <HeaderContainer2>
        <HeaderText2 style={{ width: "25%" }}>
          Region
        </HeaderText2>
        <HeaderText2 style={{ width: "15%" }}>
          Classic
        </HeaderText2>
        <HeaderText2 style={{ width: "15%" }}>
          Elite
        </HeaderText2>
        <HeaderText2 style={{ width: "15%" }}>
          Celebrity
        </HeaderText2>
        <HeaderText2 style={{ width: "15%" }}>
          Hospitality
        </HeaderText2>        
      </HeaderContainer2>
      {regionalTreatmentPrices}

      <div style={{ clear: "both" }}></div>
      <div className="row">
        <div className="col-lg-6">
          <EditableNumber
            label="Duration"
            value={treatment.duration.toString()}
            isUpdating={updateStateChecker(TreatmentUpdateField.DURATION, false)}
            isEditing={updateStateChecker(TreatmentUpdateField.DURATION, true)}
            onStartEditing={() => props.onStartEditing(TreatmentUpdateField.DURATION)}
            onFinishEditing={() => props.onFinishEditing(TreatmentUpdateField.DURATION)}
            onSubmit={(value: number | string | boolean) => props.onSubmit(TreatmentUpdateField.DURATION, value)}
            emptyLabel="No duration currently set"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="detail-heading">
            Add-on Treatment
          </div>
          <div className="detail-value">
            <Switch value={treatment.isAddon}
              onChange={() => props.onSubmit(TreatmentUpdateField.IS_ADDON, !treatment.isAddon)}
              onText="Yes"
              offText="No"
              bsSize="small" 
            />            
          </div>       
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <FieldDateTime label="Time created" dateTime={treatment.timeCreated} />
        </div>

        <div className="col-lg-6">
          <FieldDateTime label="Time last updated" dateTime={treatment.timeUpdated} />
        </div>
      </div>
    </div>
  );
};
