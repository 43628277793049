import * as iassign from "immutable-assign";
import { ActionType, getType } from "typesafe-actions";

import {actions} from "./actions";

type OperatorAction = ActionType<typeof actions>;

export type OperatorState = {
  urn: string;
  id: string;
  permissions: any;
  isPartner: boolean;
  partner: any;
  summary: any;
  isLoggingIn: boolean;
  hasErroredLoggingIn: boolean;
  hasFetched: boolean;
};

const INITIAL_STATE: OperatorState = {
  urn: null,
  id: null,
  permissions: {},
  isPartner: false,
  partner: null,
  summary: null,
  isLoggingIn: false,
  hasErroredLoggingIn: false,
  hasFetched: false,
};

export function operatorReducer(state: OperatorState = INITIAL_STATE, action: OperatorAction): OperatorState {
  switch (action.type) {
    case getType(actions.fetchOperatorSuccess): {
      return iassign(
        state,
        s => {
          s.urn = action.payload.urn;
          s.id = action.payload.id;
          s.permissions = action.payload.permissions;
          s.isPartner = action.payload.isPartner;
          s.partner = action.payload.partner;
          s.summary = action.payload.summary;
          s.hasFetched = true;

          return s;
        }
      );
    }

    case getType(actions.loginAttempt): {
      return iassign(
        state,
        s => {
          s.isLoggingIn = true;
          s.hasErroredLoggingIn = false;

          return s;
        }
      );
    }

    case getType(actions.loginSuccess): {
      return iassign(
        state,
        s => {
          s.isLoggingIn = false;

          return s;
        }
      );
    }

    case getType(actions.loginFail): {
      return iassign(
        state,
        s => {
          s.isLoggingIn = false;
          s.hasErroredLoggingIn = true;

          return s;
        }
      );
    }

    default: return state;
  }
};
