import { ActionType, getType } from "typesafe-actions";
import { actions } from "../actions";
import { ApiPartner } from "../../../api/main/partners";
import * as iassign from "immutable-assign";

type PartnerAction = ActionType<typeof actions>;

export interface PartnerState {
  partners: ApiPartner[]
}

const INITIAL_STATE: PartnerState = {
  partners: []
};

export function partnersReducer(state: PartnerState = INITIAL_STATE, action: PartnerAction): PartnerState {
  switch (action.type) {
    case getType(actions.requestGetAllPartnersSuccess): {
      return iassign(
        state,
        s => {
          s.partners = action.payload.partners;
          return s;
        }
      );
    }
    case getType(actions.requestCreatePartnerInit): {
      return state;
    }
    case getType(actions.requestCreatePartnerSuccess): {
      return {
        ...state,
        partners: [...state.partners, action.payload]
      };
    }
    case getType(actions.requestEditPartnerSuccess): {
      const result = {
        ...state,
        partners: state.partners.map((partner) => {
          if (partner.id === action.payload.id) {
            action.payload.slug = partner.slug;
            return action.payload;
          }
          return partner;
        })
      };
      return result;
    }
    case getType(actions.requestDeletePartnerInit): {
      return state;
    }
    case getType(actions.requestDeletePartnerSuccess): {
      return iassign(
        state,
        s => {
          s.partners = state.partners.filter(x => x.id != action.payload.id);
          return s;
        }
      );
    }
    default: return state;
  }
}