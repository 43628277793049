import styled from "styled-components";

interface Props {
  background?: string;
  limitedWidth?: boolean;
  offsetPaddings?: boolean;
}

export const Container = styled.div`
  width: 100%;
  padding: 0;
  margin-right: auto;
  margin-left: auto;

  /**
   * Limited width for container regarding screen size
   */
  ${(props: Props) =>
    props.limitedWidth
      ? `
  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  `
      : undefined}

  /**
   * Addtitioanl background and offset properties for container
   */
  ${(props: Props) =>
    props.background ? `background: ${props.background}` : undefined};
  ${(props: Props) =>
    props.offsetPaddings
      ? `padding: 50px 5%;
        @media screen and (max-width: 992px) {
          padding: 25px 5%;
        }`
      : undefined};
`;
