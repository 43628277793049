import { createStandardAction } from "typesafe-actions";
import { ActionDispatch } from "../../";
import * as partnersApi from "../../../api/main/partners";
import { errorMessageFromApi } from "../../../utils/error-message";
import { addNotification } from "../../notifications/actions";
import { NOTIFICATION_TYPES } from "../../notifications/types";

interface RequestSuccessPayload {
  partners: partnersApi.ApiPartner[];
}

interface RequestDeletePartner {
  id: string;
}

export const actions = {
  requestGetAllPartnersInit: createStandardAction("partners/REQUEST_GET_ALL_Partners_INIT")<void>(),
  requestGetAllPartnersSuccess: createStandardAction("partners/REQUEST_GET_ALL_Partners_SUCCESS")<RequestSuccessPayload>(),
  requestCreatePartnerInit: createStandardAction("partners/REQUEST_CREATE_PARTNER_INT")<void>(),
  requestCreatePartnerSuccess: createStandardAction("partners/REQUEST_CREATE_PARTNER_SUCCESS")<partnersApi.ApiPartner>(),
  requestEditPartnerInit: createStandardAction("partners/REQUEST_EDIT_PARTNER_INT")<void>(),
  requestEditPartnerSuccess: createStandardAction("partners/REQUEST_EDIT_PARTNER_SUCCESS")<partnersApi.ApiPartner>(),
  requestDeletePartnerInit: createStandardAction("partners/REQUEST_DELETE_PARTNER_INT")<void>(),
  requestDeletePartnerSuccess: createStandardAction("partners/REQUEST_DELETE_PARTNER_SUCCESS")<RequestDeletePartner>(),
  requestGetPartnerInit: createStandardAction("partners/REQUEST_GET_Partner_INIT")<void>(),
  requestGetPartnerSuccess: createStandardAction("partners/REQUEST_GET_Partner_SUCCESS")<{ partner: partnersApi.ApiPartner }>(),
};

export function fetchAllPartners(): ActionDispatch {
  return async dispatch => {
    dispatch(actions.requestGetAllPartnersInit());
    const partners = await partnersApi.fetchAll();
    dispatch(actions.requestGetAllPartnersSuccess({ partners }));
  };
}

export function createPartner(partner: partnersApi.ApiPartner): ActionDispatch {
  return async dispatch => {
    dispatch(actions.requestCreatePartnerInit());

    try {
      const newPartner = await partnersApi.createPartner(partner);
      dispatch(actions.requestCreatePartnerSuccess(newPartner));
      dispatch(addNotification(NOTIFICATION_TYPES.success, "Created!", "Partner created"));
    } catch (error) {
      dispatch(addNotification(NOTIFICATION_TYPES.danger, error.message, errorMessageFromApi(error.response, "Failed to create partner")));
    }
  };
}

export function editPartner(partner: partnersApi.ApiPartner): ActionDispatch {
  return async dispatch => {
    dispatch(actions.requestEditPartnerInit());

    try {
      const editedPartner = await partnersApi.editPartner(partner.id, partner);
      dispatch(actions.requestEditPartnerSuccess(editedPartner));
      dispatch(addNotification(NOTIFICATION_TYPES.success, "Updated!", "Partner updated"));
    } catch (error) {
      dispatch(addNotification(NOTIFICATION_TYPES.danger, error.message, errorMessageFromApi(error.response, "Failed to update partner")));
    }
  };
}

export function setTherapists(partner: partnersApi.ApiPartnerTherapists): ActionDispatch {
  return async dispatch => {
    dispatch(actions.requestEditPartnerInit());

    try {
      const editedPartner = await partnersApi.setTherapists(partner);
      dispatch(actions.requestEditPartnerSuccess(editedPartner));
      dispatch(addNotification(NOTIFICATION_TYPES.success, "Updated!", "Therapists assigned to partner"));
    } catch (error) {
      dispatch(addNotification(NOTIFICATION_TYPES.danger, error.message, errorMessageFromApi(error.response, "Failed to set therapists")));
    }
  };
}
