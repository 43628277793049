import { config } from "../../config";
import * as api from "./request";

export interface ApiMarketingMessage {
  message: string;
  enabled: boolean;
}

/**
 * Fetch marketing message
 */
export async function fetchMarketingMessage(): Promise<ApiMarketingMessage> {
  const url = `${config.adminPanelApiUrl}/notifications/marketing-message`;
  const response = await api.get<ApiMarketingMessage>(url);

  return response.data;
}

/**
 * Update marketing message
 */
export async function updateMarketingMessage(message: ApiMarketingMessage): Promise<void> {
  const url = `${config.adminPanelApiUrl}/notifications/marketing-message`;
  await api.post<void>(url, message);
}
