import * as React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";
import Switch from "react-bootstrap-switch";
import { withRouter, RouteComponentProps } from "react-router-dom";

import AddressRows from "../components/customers/address-rows";
import { handleCustomerSubmit } from "../reducers/customers/actions";
import { RootState } from "../reducers";

interface ReduxProps {
  partnerId: string;
}

type OwnProps = RouteComponentProps<{}>;

type Props = ReduxProps & OwnProps & { dispatch: Dispatch<{}> };

interface CreateCustomerState {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  addresses?: any[];
  showForm?: boolean;
  isBlackLabel?: boolean;
  agreeMailListSignup?: boolean;
}

class Component extends React.Component<Props, CreateCustomerState> {
  constructor(props: any) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      addresses: [],
      showForm: false,
      isBlackLabel: false,
      agreeMailListSignup: true,
    };

    this.formValidated = this.formValidated.bind(this);
  }

  toggleFormVisibility() {
    this.setState({ showForm: !this.state.showForm });
  }

  handleInputChange(key: string, e: any) {
    let obj = {};
    obj[key] = e.target.value;
    this.setState(obj);
  }

  handleBlackLabel() {
    this.setState({ isBlackLabel: !this.state.isBlackLabel });
  }

  handleMailListSignup() {
    this.setState({ agreeMailListSignup: !this.state.agreeMailListSignup });
  }

  handleAddAddress(address: any) {
    const newAddresses = this.state.addresses.concat([address]);
    this.setState({
      addresses: newAddresses,
    });
  }

  handleDeleteAddress(index: number) {
    let newAddresses = this.state.addresses.slice(0);
    newAddresses.splice(index, 1);
    this.setState({
      addresses: newAddresses,
    });
  }

  handleCustomerSubmit = () => {
    const customerData = {
      partnerId: this.props.partnerId,
      isVip: false,
      ...this.state
    };

    this.props.dispatch(handleCustomerSubmit(customerData, this.props.history));
  }

  formValidated(): boolean {
    const { firstName, lastName, email } = this.state;

    let validated: boolean = true;

    if (
      firstName.length === 0 ||
      lastName.length === 0 ||
      email.length === 0
    )
      validated = false;

    return validated;
  }

  render() {
    const addresses = this.state.addresses;

    return (
      <div className="create-customer">
        <h1>Create Customer</h1>

        <div className="app-details-row container">
          <div className="row">
            <div className="main-details-row col-md-6">
              <div className="detail-heading">First Name</div>
              <input
                type="text"
                value={this.state.firstName}
                onChange={this.handleInputChange.bind(this, "firstName")}
              />
            </div>
            <div className="main-details-row col-md-6">
              <div className="detail-heading">Last Name</div>
              <input
                type="text"
                value={this.state.lastName}
                onChange={this.handleInputChange.bind(this, "lastName")}
              />
            </div>
          </div>
          <div className="row">
            <div className="main-details-row col-md-6">
              <div className="detail-heading">Email</div>
              <input
                type="text"
                value={this.state.email}
                onChange={this.handleInputChange.bind(this, "email")}
              />
            </div>
            <div className="main-details-row col-md-6">
              <div className="detail-heading">Phone</div>
              <input
                type="number"
                value={this.state.phone}
                onChange={this.handleInputChange.bind(this, "phone")}
              />
            </div>
          </div>
          <div className="row">           
            <div className="main-details-row col-md-6">
              <div className="detail-heading">Black Label</div>
              <div className="detail-value">
                <Switch
                  value={this.state.isBlackLabel}
                  onChange={() => this.handleBlackLabel()}
                  onText="Yes"
                  offText="No"
                  bsSize="small"
                />
              </div>
            </div>
            <div className="main-details-row col-md-6">
              <div className="detail-heading">Consents to Ruuby marketing</div>
              <div className="detail-value">
                <Switch
                  value={this.state.agreeMailListSignup}
                  onChange={() => this.handleMailListSignup()}
                  onText="Yes"
                  offText="No"
                  bsSize="small"
                />
              </div>
            </div>             
          </div>       
        </div>

        <div className="treatments-header">
          <h3>Addresses</h3>
          <button
            className="btn"
            disabled={addresses && addresses.length > 2 ? true : false}
            onClick={this.toggleFormVisibility.bind(this)}
          >
            <i className="fa fa-plus" /> New Address
          </button>
        </div>

        <Table>
          <col width="30%" />
          <col width="30%" />
          <col width="30%" />
          <col width="10%" />

          <thead>
            <tr>
              <th>Address 1</th>
              <th>Address 2</th>
              <th>Postcode</th>
              <th />
            </tr>
          </thead>

          <AddressRows
            addresses={this.state.addresses}
            toggleFormVisibility={this.toggleFormVisibility.bind(this)}
            showForm={this.state.showForm}
            handleAddAddress={this.handleAddAddress.bind(this)}
            handleDeleteAddress={this.handleDeleteAddress.bind(this)}
          />
        </Table>

        <button
          className="btn btn-submit"
          onClick={this.handleCustomerSubmit.bind(this)}
          disabled={!this.formValidated()}
        >
          Submit
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): ReduxProps => {
  let partnerId = "";

  if (state.operator.partner) {
    partnerId = state.operator.partner.id;
  }

  return {
    partnerId,
  }
};

export const CreateCustomerContainer = withRouter(connect<ReduxProps, {}, OwnProps>(mapStateToProps)(Component));
