import * as React from "react";
import { nameStamp, timeStamp } from "../../../utils";
import {ActivityLog} from "../../../api/main/audit";

interface Props {
  log: ActivityLog;
}

export const Log: React.SFC<Props> = ({log}) => (
  <li className="log" style={{marginBottom: "25px"}}>
    <p><span>{timeStamp(log.time)}</span> by {nameStamp(log.updatedByUrn)}</p>
    <pre>
      <code>
        {JSON.stringify(log.digest, null, 2)}
      </code>
    </pre>
  </li>
);
