import styled from "styled-components";

import { editButtonCss } from "../../atoms/editable-field-icon";
import { MixinEditableField } from "../mixin-editable-field";
import { TreatmentFeeConfig } from "../../../api/main/configuration";

export const getTreatmentFee = (
  treatmentFeeConfig: TreatmentFeeConfig,
  currency: keyof TreatmentFeeConfig,
  value?: number,
): number => {
  if (value === undefined || value === 0) {
    return 0;
  }

  const feeStructure = treatmentFeeConfig[currency];

  const matchedRange = feeStructure.find(
    ({ from, to }) => (from === undefined || value > from) && (to === undefined || value <= to),
  );

  if (!matchedRange) {
    throw new Error(
      `Received price is outside the treatment config ranges: ${JSON.stringify({
        currency,
        value,
        treatmentFeeConfig,
      })}`,
    );
  }

  return matchedRange.feeAmount;
};


const Paragraph = styled.p`
  font-family: "nunito_sansregular";
  color: #333333;
  font-size: 15px;

  ${editButtonCss}
`;

const CurrencyLabel = styled.span`
  margin-top: 8px;
  margin-right: 5px;
`;

const Container = styled.div`
  width: 100%;
  display: inline-flex;
`;

const Input = styled.input`
  font-family: "nunito_sansregular";
  color: #333333;
  padding: 1px 5px;
  width: 80px;
`;

const GrossPrice = styled(Paragraph)`
  font-size: 12px;
`;

type Props = React.ComponentProps<typeof MixinEditableField> & {
  currency: "GBP" | "CHF";
  treatmentFeeConfig: TreatmentFeeConfig;
};

export class EditableRegionalPrice extends MixinEditableField {
  props: Props;

  render(): JSX.Element {
    const { currency, treatmentFeeConfig, isEditing, value } = this.props;

    const symbol = currency === "GBP" ? "£" : "CHF ";

    if (isEditing) {
      return (
        <div>
          <Container>
            <CurrencyLabel>{symbol}</CurrencyLabel>
            <Input
              type="number"
              onChange={e => this.setState({ ...this.state, editingValue: (e.target as any).value })}
              value={this.state.editingValue}
            />
            {this.renderButtons()}
          </Container>
        </div>
      );
    }
    else {
      return (
        <div>
          <Paragraph>
            {symbol}{value}
            {this.renderEditIcon()}
          </Paragraph>
          <GrossPrice>
            ({symbol}{Number(value) + getTreatmentFee(treatmentFeeConfig, currency, Number(value))} with fees)
          </GrossPrice>
        </div>
      );
    }
  }
}
