import * as api from "./request";
import { config } from "../../config";

export interface Promotion {
  urn?: string;
  minimumSpend?: any;
  singleUse: boolean;
  firstTime: boolean;
  deduction: any;
  deductionType: "percentage" | "absolute";
  validUntil?: string;
  code: string;
  notes?: string;
  category?: string;
  validDate?: string;
}

export async function searchPromotions(query: string): Promise<Promotion[]> {
  const url = `${config.adminPanelApiUrl}/promotions?search=${query}`;

  const res = await api.get<Promotion[]>(url);

  return res.data;
}

export async function create(promotion: Promotion): Promise<void> {

  await api.put(`${config.adminPanelApiUrl}/promotions`, promotion);
}

export async function edit(promotion: Promotion): Promise<void> {

  await api.post(`${config.adminPanelApiUrl}/promotions`, promotion);
}

export async function deletePromotion(code: string): Promise<void> {
  const url = `${config.adminPanelApiUrl}/promotions/${code}/`;

  await api.deletion(url);
}
