import * as React from "react";
import * as Spinner from "react-spinkit";
import { BookingNote } from "./note";
import { Note } from "../../api/main/notes";
import { CreateNote } from "./note/create";

interface Props {
  onCreate(comment: string): void;
  onUpdate(urn: string, field: string, value: string | boolean): void;
  notes: Note[];
  isLoading: boolean;
}

export const BookingNotes: React.SFC<Props> = ({notes, isLoading, onCreate, onUpdate}) => {
  return (
    <div className="notes-container">
      <h1>Booking</h1>

      {notes.length === 0 && <p>There are no notes for this booking</p>}

      {isLoading && <Spinner name="pulse" fadeIn="none" />}

      {notes.length > 0 &&
        <div className="notes-wrapper">
          {notes.map((note, i) => <BookingNote key={i} note={note} onUpdate={onUpdate} />)}
        </div>
      }

      <CreateNote onCreate={onCreate} />
    </div>
  );
};
