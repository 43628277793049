import * as React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import * as Spinner from "react-spinkit";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { postcodesModel } from "../../model/postcodes";
import { RootState } from "../../reducers";
import { getAvailablePostcodes } from "../../reducers/postcodes/selectors";
import { PostcodesPresenter } from "../../presenters/postcodes/postcodes-presenter";
import { statusSelectors } from "../../reducers/api-status/selectors";
import * as therapistModel from "../../model/therapist";
import { getPostcodesForTherapist } from "../../reducers/therapist/selectors";
import { TherapistPostcodesPresenter } from "../../presenters/therapist/therapist-postcodes-presenter";
import { TherapistPostcodes } from "../../components/organisms/therapist-postcodes";
import { TherapistSubMenu } from "../../components/sub/sub-menu";
import { searchTherapist } from "../../reducers/bookings/actions";
import { inflateTherapist, TherapistPresenter } from "../../presenters/therapist";

interface ReduxProps {
  therapist: TherapistPresenter,
  therapistUrn: string;
  therapistPostcodesPending: boolean;
  availablePostcodes: PostcodesPresenter;
  therapistPostcodesPresenter: TherapistPostcodesPresenter;
  availablePostcodesArePending: boolean;
  availablePostcodesHaveLoaded: boolean;
  therapistIsPending: boolean;
  therapistHasLoaded: boolean;
  therapistIsStale: boolean;
}

type OwnProps = RouteComponentProps<{urn: string}>;
type Props = OwnProps & ReduxProps & {dispatch: Dispatch<{}>};

/**
 * A container component for the therapist postcode management.
 * Handles the loading state for the therapist and global list of postcodes, displaying a spinner if they are not ready
 *
 * Then passes the presenters down to the organism component for therapist postcodes
 */
class TherapistsPostcodesComponent extends React.Component<Props, {}> {
  componentDidMount() {
    if (!this.props.availablePostcodesHaveLoaded && !this.props.availablePostcodesArePending) {
      this.props.dispatch(postcodesModel.fetchAvailablePostcodeDistricts());
    }
    if (this.props.therapistIsStale || !this.props.therapistHasLoaded && !this.props.therapistIsPending) {
      this.props.dispatch(therapistModel.fetchTherapist(this.props.therapistUrn));
    }
  }

  handleClickBookings = (): void => {
    this.props.dispatch(searchTherapist(this.props.therapistUrn));
  }

  setSwitzerland = (value: boolean): void => {
    this.props.dispatch(therapistModel.setPostcodeForTherapist(this.props.therapistUrn, "urn:ruuby:district:CH-8", value));
  }

  render(): JSX.Element {
    const allLoaded = this.props.availablePostcodesHaveLoaded && this.props.therapistHasLoaded;
    const innerComponent = allLoaded
      ? <TherapistPostcodes
        key="therapist-postcodes"
        therapistName={this.props.therapist.name}
        therapistUrn={this.props.therapistUrn}
        therapistIsPending={this.props.therapistIsPending}
        therapistPostcodesPending={this.props.therapistPostcodesPending}
        availablePostcodes={this.props.availablePostcodes}
        therapistPostcodesPresenter={this.props.therapistPostcodesPresenter}
        setPostcodeForTherapist={(therapistUrn: string, postcodeUrn: string, enabled: boolean) => this.props.dispatch(therapistModel.setPostcodeForTherapist(therapistUrn, postcodeUrn, enabled))}
        setSwitzerland={this.setSwitzerland}
      />
      : <Spinner name="pulse" fadeIn="none" />;

    return (
      <div className="therapist-container">
        <div className="main-section">{innerComponent}</div>
        <div className="side-nav">
          <TherapistSubMenu urn={this.props.match.params.urn} active="postcodes" onClickBookings={this.handleClickBookings} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): ReduxProps => {
  const therapistUrn = ownProps.match.params.urn;
  const therapistRequestReference = therapistModel.fetchTherapistRef(therapistUrn);
  return {
    therapist: inflateTherapist(state.therapistsState.therapists[ownProps.match.params.urn]),
    therapistUrn,
    availablePostcodes: getAvailablePostcodes(state),
    therapistPostcodesPending: statusSelectors.getIsPending(state, therapistModel.setTherapistPostcodesRef(therapistUrn)),
    therapistPostcodesPresenter: getPostcodesForTherapist(state, {therapistUrn}),
    availablePostcodesHaveLoaded: statusSelectors.getHasLoaded(state, postcodesModel.fetchAvailablePostcodesRef),
    availablePostcodesArePending: statusSelectors.getIsPending(state, postcodesModel.fetchAvailablePostcodesRef),
    therapistIsPending: statusSelectors.getIsPending(state, therapistRequestReference),
    therapistHasLoaded: statusSelectors.getHasLoaded(state, therapistRequestReference),
    therapistIsStale: Date.now() - statusSelectors.getTimestamp(state, therapistRequestReference) > 100_0000,
  };
}

export const TherapistPostcodesContainer = withRouter(connect<ReduxProps, {}, OwnProps>(mapStateToProps)(TherapistsPostcodesComponent));