import * as React from "react";

interface Props {
  notes: string;
  setBookingNotes: (notes: string) => void;
}

export const BookingNotes: React.SFC<Props> = (props: Props) => (
  <div className="main-details-row">
    <div className="booking-notes">
      <div className="detail-heading">
        Add notes for Therapist and Client <small>(be careful what you write here!)</small>
      </div>
      <div className="detail-value">
        <div className="field-edit">
          <div className="input-box">
            <textarea rows={4} defaultValue={props.notes} onBlur={(evt: any) => props.setBookingNotes(evt.target.value)} className="form-control">
            </textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
);
