const RuubyColourPalette = {
  ruubyBlueDark: "#586b72",
  ruubyChat100: "#C3A18C",
  ruubyChat200: "#b68f77",
}

export const RuubyChatColourSet = {
  "--sendbird-light-primary-500": RuubyColourPalette.ruubyChat200,
  "--sendbird-light-primary-400": RuubyColourPalette.ruubyChat200,
  "--sendbird-light-primary-300": RuubyColourPalette.ruubyChat100,
  "--sendbird-light-primary-200": RuubyColourPalette.ruubyChat100,
  "--sendbird-light-primary-100": RuubyColourPalette.ruubyChat100,
  "--sendbird-message-input-border-active": RuubyColourPalette.ruubyBlueDark,
};
