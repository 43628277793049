import * as React from "react";

import { TreatmentPresenter } from "../../../presenters/treatment";
import { UpdateState } from "../../../reducers/treatments/reducer";
// import { EditablePrice } from "../../molecules/editable-price";
import { CategoryPresenter } from "../../../presenters/category";
// import { TreatmentUpdateField } from "../../../api/main/treatment";
// import { EditableNumber } from "../../molecules/editable-number";

interface Props {
  isLoading: boolean;
  treatment: TreatmentPresenter;
  categories: {
    [id: string]: CategoryPresenter;
  };
  categoriesList: CategoryPresenter[];
  updateState: UpdateState;
  onStartEditing(field: string): void;
  onFinishEditing(field: string): void;
  onSubmit(field: string, value: string | boolean | number | string[]): void;
}

export const PartnerTreatment: React.StatelessComponent<Props> = props => {
  const treatment = props.treatment;

  if (typeof treatment === "undefined" || typeof props.updateState === "undefined") {
    return <div></div>;
  }

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <h1>{treatment.name}</h1>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          {treatment.description}
        </div>
      </div>
    </div>
  );
};
