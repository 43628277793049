import { ApiTherapist, TherapistImageDataMap } from "../../api/main/therapist";
import { Fields } from "../../utils/class-types";

export enum TherapistTier {
  CLASSIC = "CLASSIC",
  ELITE = "ELITE",
  ON_DEMAND = "ON_DEMAND",
  BLACK_LABEL = "BLACK_LABEL",
  HOSPITALITY = "HOSPITALITY",
}

export enum TherapistRegion {
  LONDON = "London",
  MANCHESTER = "Manchester",
}


export class TherapistPresenter {
  urn: string;
  name: string;
  salonUrn: string;
  quote: string;
  products: string;
  description: string;
  email: string;
  telephone: string;
  treatmentUrns: string[];
  commission: number;
  isRecommended: boolean;
  isElite: boolean;
  images: TherapistImageDataMap;
  regions: TherapistRegion[];
  tier: TherapistTier;

  constructor(props: Fields<TherapistPresenter>) {
    Object.assign(this, props);
  }

  /**
   * @deprecated
   * Return tier for therapist
   */
  therapistTier() {
    return this.isElite ? TherapistTier.ELITE : TherapistTier.CLASSIC;
  }
}

export function inflateTherapist(object: ApiTherapist): TherapistPresenter {
  if (typeof object === "undefined") {
    return undefined;
  }

  let response = new TherapistPresenter({
    urn: object.urn,
    name: object.name,
    salonUrn: object.salonUrn,
    quote: object.quote,
    products: object.products,
    description: object.description,
    email: object.email,
    telephone: object.telephone,
    treatmentUrns: object.treatmentUrns,
    commission: object.commission,
    isRecommended: object.recommended,
    isElite: object.isElite,
    images: object.images,
    regions: object.regions,
    tier: object.tier,
  });

  if (typeof object.regions !== "undefined" && object.regions.length !== 0) {
    response.regions = object.regions;
  }

  return response;
}

