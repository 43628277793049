import * as React from "react";
import { Table } from "react-bootstrap";
import { sprintf } from "sprintf-js";

interface Props {
  tipAmount: number;
}

export const CustomerTip: React.SFC<Props> = (props) => {

  return (
    <div className="adjustments">
      <div className="adjustments-header">
        <h3>Customer Tip</h3>
      </div>

      <Table>
        <colgroup>
          <col width="20%" />
          <col width="20%" />
          <col width="20%" />
          <col width="40%" />
        </colgroup>

        <tbody>
          <tr>
            <td>Tip</td>
            <td>&nbsp;</td>
            <td>{sprintf("£%0.2f", props.tipAmount)}</td>
            <td></td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
