import { useCallback, useState } from "react";

import {
  ApiError,
  CustomerPaymentMethod,
  useCustomerPaymentMethodsQuery,
} from "../../../../api/types/graphql";

type UseCustomerPaymentMethods = (customerUrn: string) => {
  error: ApiError;
  isLoading: boolean;
  clearError(): void;
  stripeId: string,
  customerPaymentMethods: CustomerPaymentMethod[];
  refetch(): void;
};

export const useCustomerPaymentMethods: UseCustomerPaymentMethods = (customerUrn) => {
  const [error, setError] = useState<ApiError>();
  const [stripeId, setStripeId] = useState<string>("");
  const [customerPaymentMethods, setCustomerPaymentMethods] = useState<CustomerPaymentMethod[]>([]);

  const { refetch, loading } = useCustomerPaymentMethodsQuery({
    variables: {
      urn: customerUrn,
    },
    onCompleted: ({ customer }) => {
      switch (customer.__typename) {
        case "Customer":
          setError(undefined);
          setStripeId(customer.payment.stripeId);
          setCustomerPaymentMethods(customer.payment.paymentMethods);
          break;
        case "RuubyGraphError":
          setError(ApiError.GeneralError);
      }
    },
    onError: () => setError(ApiError.GeneralError),
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const reloadCustomerPaymentMethods = useCallback(async() => {
    setError(undefined);
    await refetch();
  }, []);

  return {
    error,
    isLoading: loading,
    clearError: () => setError(undefined),
    stripeId,
    customerPaymentMethods,
    refetch: reloadCustomerPaymentMethods,
  };
};
