import { createStandardAction } from "typesafe-actions";
import { ActionDispatch } from "../../";
import * as regionApi from "../../../api/main/region";

interface RequestSuccessPayload {
  regions: regionApi.ApiRegion[];
}

export const actions = {
  requestGetAllRegionsInit: createStandardAction("jobs/REQUEST_GET_ALL_REGIONS_INIT")<void>(),
  requestGetAllRegionsSuccess: createStandardAction("jobs/REQUEST_GET_ALL_REGIONS_SUCCESS")<RequestSuccessPayload>(),
  requestCreateRegionInit: createStandardAction("jobs/REQUEST_CREATE_REGION_INT")<void>(),
  requestCreateRegionSuccess: createStandardAction("jobs/REQUEST_CREATE_REGION_SUCCESS")<regionApi.ApiRegion>(),
  requestEditRegionInit: createStandardAction("jobs/REQUEST_EDIT_REGION_INT")<void>(),
  requestEditRegionSuccess: createStandardAction("jobs/REQUEST_EDIT_REGION_SUCCESS")<regionApi.ApiRegion>(),
};

export function fetchAllRegions(showActiveOnly = false): ActionDispatch {
  return async dispatch => {
    dispatch(actions.requestGetAllRegionsInit());
    const regions = await regionApi.fetchAll(showActiveOnly);
    dispatch(actions.requestGetAllRegionsSuccess({ regions }));
  };
}

export function createRegion(region: regionApi.ApiRegion): ActionDispatch {
  return async dispatch => {
    dispatch(actions.requestCreateRegionInit());
    const createdRegion = await regionApi.createRegion(region);
    dispatch(actions.requestCreateRegionSuccess(createdRegion));
  };
}

export function editRegion(id: string, region: regionApi.ApiRegion): ActionDispatch {
  return async dispatch => {
    dispatch(actions.requestEditRegionInit());
    const editedRegion = await regionApi.editRegion(id, region);
    dispatch(actions.requestEditRegionSuccess(editedRegion));

  };
}