import * as React from "react";
import { AlertContainer, Alert } from "react-bs-notifier";
import * as _ from "lodash";

interface NotificationsProps {
  notifications: any[];
  onDismissNotification: (id: number) => void;
}

export default class Notifications extends React.Component<NotificationsProps, {}> {
  constructor(props: NotificationsProps) {
    super(props);
    this.onAlertDismissed = this.onAlertDismissed.bind(this);
  }

  onAlertDismissed(notificationId: number) {
    this.props.onDismissNotification(notificationId);
  }

  render() {
    const alerts = _.map(this.props.notifications, (notification, key) => (
      <Alert
        key={key}
        type={notification.type}
        headline={notification.headline}
        timeout={5000}
        onDismiss={() => this.onAlertDismissed(key)}
      >
       {notification.message}
      </Alert>)
    );

    return (
      <div className="row">
        <div className="col-xs-6">
          <AlertContainer position="top-right">
            {alerts}
          </AlertContainer>
        </div>
      </div>
    );
  }
}
