import * as React from "react";
import * as Select from "react-select";
import { Dispatch } from "redux";

import EditField from "../../components/sub/edit-field";
import Switch from "react-bootstrap-switch";
import { ApiTherapist, TherapistUpdateField, TherapistUpdateValue } from "../../api/main/therapist";
import { config } from "../../config";
import { updateTherapistField } from "../../model/therapist";
import { fetchAll } from "../../api/main/region";
import { Label } from "react-bootstrap";

/*
import { fetchTherapistAvailabilitySlotsForDay } from "../../api/main/availability";
import { TherapistAvailabilityChart } from "./therapist-availability-chart";
*/

interface Props {
  therapist: ApiTherapist;
  dispatch: Dispatch<{}>;
}

interface Option {
  value: string;
  label: string;
}

interface State {
  editingName?: boolean;
  editingSlug?: boolean;
  editingXeroid?: boolean;
  editingPhone?: boolean;
  editingEmail?: boolean;
  editingQuote?: boolean;
  editingProducts?: boolean;
  editingDesc?: boolean;
  editingCommission?: boolean;
  editingInternalNotes?: boolean;
  regionOptions: Option[];
  selectedRegions: string[];
  availabilityChart: number[];
}

export class TherapistDetails extends React.Component<Props, State> {
  state: State = {
    editingName: false,
    editingSlug: false,
    editingXeroid: false,
    editingPhone: false,
    editingEmail: false,
    editingQuote: false,
    editingProducts: false,
    editingDesc: false,
    editingCommission: false,
    editingInternalNotes: false,
    regionOptions: [],
    selectedRegions: this.props.therapist.regions,
    availabilityChart: [],
  };

  async componentDidMount() {
    const regions = await fetchAll(true);

    this.setState({
      regionOptions: regions.map((region) => {
        return {
          value: region.name,
          label: region.name,
        }
      })
    });

    await this.getAvailabilityChartData();
  }

  handleCommissionUpdate = (key: TherapistUpdateField, value: TherapistUpdateValue): void => {
    this.props.dispatch(updateTherapistField(this.props.therapist.urn, key, (Number(value) / 100).toFixed(2)));
  }

  handleRecommendUpdate = (): void => {
    this.props.dispatch(updateTherapistField(this.props.therapist.urn, TherapistUpdateField.IS_RECOMMENDED, !this.props.therapist.recommended));
  }

  handleTierUpdate = (selectedOption: Option): void => {
    this.props.dispatch(updateTherapistField(this.props.therapist.urn, TherapistUpdateField.TIER, selectedOption.value));
  }

  toggleNotStandardTherapist = (): void => {
    this.props.dispatch(updateTherapistField(this.props.therapist.urn, TherapistUpdateField.IS_NOT_STANDARD, !this.props.therapist.isNotStandardTherapist));
  }

  toggleAutoSetAvailability = (): void => {
    this.props.dispatch(updateTherapistField(this.props.therapist.urn, TherapistUpdateField.SHOULD_AUTOMATICALLY_SET_AVAILABILITY, !this.props.therapist.isAutoSetAvailability));
  }

  toggleIsMobile = (): void => {
    this.props.dispatch(updateTherapistField(this.props.therapist.urn, TherapistUpdateField.IS_MOBILE, !this.props.therapist.isMobile));
  }

  toggleTherapistIsAcitve = (): void => {
    this.props.dispatch(updateTherapistField(this.props.therapist.urn, TherapistUpdateField.IS_ACTIVE, !this.props.therapist.isActive));
  }

  /**
   * Displays therapist field and allows field to be edited via a input field
   * displayed after fa-pencil is clicked
   * @param  key: string        What data field holds. Must correspond to database suffix after "salon_"
   * @param  stateField: string        Corresponding value in component state
   * @param  currentValue: string        Current value of field
   */
  handleInputFieldEdit = (key: TherapistUpdateField, stateField: string, currentValue: string): JSX.Element => {
    let state = {};
    const clickHandler = () => {
      state[`${stateField}`] = true;
      this.setState(state);
    };
    const editCancelHandler = () => {
      state[`${stateField}`] = false;
      this.setState(state);
    };
    const editSaveHandler = () => {
      const input = this.refs[stateField] as HTMLInputElement;
      this.props.dispatch(updateTherapistField(this.props.therapist.urn, key, input.value));
      editCancelHandler();
    };

    if (this.state[stateField]) {
      return (
        <div className="field-edit">
          <div className="input-box">
            <input type="text" ref={stateField} className="form-control" defaultValue={currentValue} />
            <button className="btn btn-submit" type="button" onClick={editSaveHandler}>
              <span className="fa fa-check text-success" aria-hidden="true" />
            </button>
            <button className="btn btn-cancel" type="submit" onClick={editCancelHandler}>
              <span className="fa fa-times" aria-hidden="true" />
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div onClick={clickHandler} className="field-edit">
          {currentValue}
          {key === TherapistUpdateField.NAME &&
            <Label
              className="therapist-is-active-label"
              style={this.props.therapist.isActive ? { backgroundColor: '#00AF6C' } : { backgroundColor: '#cc0000' }}>
              {(this.props.therapist.isActive ? "active" : "inactive")}
            </Label>
          }

          <span className="edit-icon edit-icon-hover fa fa-pencil"></span>
        </div>
      );
    }
  }

  handleTextareaFieldEdit = (key: TherapistUpdateField, stateField: string, currentValue: string): JSX.Element => {
    let state = {};
    const clickHandler = () => {
      state[`${stateField}`] = true;
      this.setState(state);
    };
    const editCancelHandler = () => {
      state[`${stateField}`] = false;
      this.setState(state);
    };
    const editSaveHandler = () => {
      const input = this.refs[stateField] as HTMLInputElement;
      this.props.dispatch(updateTherapistField(this.props.therapist.urn, key, input.value));
      editCancelHandler();
    };

    if (this.state[stateField]) {
      return (
        <div className="field-edit">
          <div className="input-box">
            <textarea defaultValue={currentValue} rows={4} ref={stateField} className="form-control">
            </textarea>
            <button className="btn btn-submit" type="button" onClick={editSaveHandler}>
              <span className="fa fa-check text-success" aria-hidden="true" />
            </button>
            <button className="btn btn-cancel" type="submit" onClick={editCancelHandler}>
              <span className="fa fa-times" aria-hidden="true" />
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="field-edit">
          {currentValue}
          <span onClick={clickHandler} className="edit-icon edit-icon-hover fa fa-pencil"></span>
        </div>
      );
    }
  }

  handleRegionsEdit = (selectedOptions: Option[]) => {
    const regions = selectedOptions.map((option: Option) => option.value);

    this.setState({
      selectedRegions: regions,
    });

    this.props.dispatch(updateTherapistField(this.props.therapist.urn, TherapistUpdateField.REGIONS, regions));
  }

  buildLinkToProfile = () => {
    const profilePath = this.props.therapist.isPartnerTherapist ? "partner" : "book";

    return <a href={`${config.customerWebsiteUrl}${profilePath}/${this.props.therapist.slug}`} target="_blank" style={{ color: "#337ab7", textDecoration: "underline" }}>Slug</a>;
  }

  getAvailabilityChartData = async () => {
    /*
    let chartData = [];
    for (let i = 0; i < 20; i++) {
      const slots = await fetchTherapistAvailabilitySlotsForDay(
        this.props.therapist.urn,
        moment().add("day", i).format("YYYY-MM-DD"));

      chartData.push(slots.length);
    }
    this.setState({ availabilityChart: [chartData] });
    */
  }

  render(): JSX.Element {
    const therapist = this.props.therapist;

    return (
      <div>
        <h1>
          {this.handleInputFieldEdit(TherapistUpdateField.NAME, "editingName", therapist.name)}
        </h1>

        <div className="app-details-row">
          <div className="main-details">
            {
              /*
                <TherapistAvailabilityChart data={this.state.availabilityChart} />
              */
            }

            <div style={{ clear: "both" }}></div>
            <div className="main-details-row">
              <div className="booking-date">
                <div className="detail-heading">
                  Rating score
                </div>
                <div className="detail-value">
                  {this.props.therapist.rating.toFixed(2)}
                </div>
              </div>
              <div className="booking-date">
                <div className="detail-heading">
                  Active
                </div>
                <div className="detail-value">
                  <Switch value={therapist.isActive} onChange={this.toggleTherapistIsAcitve} bsSize="small" onText="YES" offText="NO" />
                </div>
              </div>
            </div>
            <div className="main-details-row">
              <div className="booking-date">
                <div className="detail-heading">
                  Xero ID
                </div>
                <div className="detail-value">
                  {this.handleInputFieldEdit(TherapistUpdateField.XEROID, "editingXeroid", therapist.xeroId)}
                </div>
              </div>
              <div className="therapist-booking-lead-time">
                <div className="detail-heading">
                  Booking lead time
                </div>
                <div className="detail-value">
                  {this.handleInputFieldEdit(TherapistUpdateField.BOOKING_LEAD_TIME, "editingBookingLeadTime", therapist.bookingLeadTime)}
                </div>
              </div>
            </div>

            <div className="main-details-row">
              <div className="booking-date">
                <div className="detail-heading">
                  {this.buildLinkToProfile()}
                </div>
                <div className="detail-value">
                  {this.handleInputFieldEdit(TherapistUpdateField.SLUG, "editingSlug", therapist.slug)}
                </div>
              </div>
              <div className="booking-date">
                <div className="detail-heading">
                  Email
                </div>
                <div className="detail-value">
                  {this.handleInputFieldEdit(TherapistUpdateField.EMAIL, "editingEmail", therapist.email)}
                </div>
              </div>
            </div>

            <div className="main-details-row">
              <div className="therapist-commission">
                <div className="detail-heading">
                  Commission
                </div>
                <div className="detail-value">
                  <EditField dbKey={TherapistUpdateField.COMMISSION} type="percentage" value={Math.round(therapist.commission * 100)} saveHandler={this.handleCommissionUpdate} />
                </div>
              </div>
              <div className="booking-time">
                <div className="detail-heading">
                  Phone
                </div>
                <div className="detail-value">
                  {this.handleInputFieldEdit(TherapistUpdateField.TELEPHONE, "editingPhone", therapist.telephone)}
                </div>
              </div>
            </div>

            <div className="main-details-row">
              <div className="therapist-recommended">
                <div className="detail-heading">
                  On demand account
                </div>
                <div className="detail-value">
                  <Switch value={therapist.isNotStandardTherapist} onChange={this.toggleNotStandardTherapist} bsSize="small" onText="YES" offText="NO" />
                </div>
              </div>
              <div className="therapist-recommended">
                <div className="detail-heading">
                  Recommended
                </div>
                <div className="detail-value">
                  <Switch value={therapist.recommended} onChange={this.handleRecommendUpdate} bsSize="small" onText="YES" offText="NO" />
                </div>
              </div>
            </div>

            <div className="main-details-row">
              <div className="therapist-recommended">
                <div className="detail-heading">
                  Is Mobile
                </div>
                <div className="detail-value">
                  <Switch value={therapist.isMobile} onChange={this.toggleIsMobile} bsSize="small" onText="YES" offText="NO" />
                </div>
              </div>
              <div className="therapist-recommended">
                <div className="detail-heading">
                  Auto-set availability
                </div>
                <div className="detail-value">
                  <Switch value={therapist.isAutoSetAvailability} onChange={this.toggleAutoSetAvailability} bsSize="small" onText="YES" offText="NO" />
                </div>
              </div>
            </div>

            <div className="main-details-row">
              <div className="therapist-recommended">
                <div className="detail-heading">
                  Tier
                </div>
                <div className="detail-value">
                  <Select
                    value={therapist.tier}
                    options={config.tierOptions}
                    onChange={this.handleTierUpdate}
                    clearable={false}
                  />
                </div>
              </div>
            </div>

            <div className="main-details-row">
              <div className="therapist-recommended">
                <div className="detail-heading">
                  Region
                </div>
                <div className="detail-value">
                  <Select
                    value={this.state.selectedRegions}
                    options={this.state.regionOptions}
                    onChange={this.handleRegionsEdit}
                    multi
                  />
                </div>
              </div>
            </div>

            <div className="main-details-row">
              <div className="therapist-description">
                <div className="detail-heading">
                  About
                </div>
                <div className="detail-value">
                  {this.handleTextareaFieldEdit(TherapistUpdateField.DESCRIPTION, "editingDesc", therapist.description)}
                </div>
              </div>
            </div>

            <div className="main-details-row">
              <div className="therapist-description">
                <div className="detail-heading">
                  Quote
                </div>
                <div className="detail-value">
                  {this.handleInputFieldEdit(TherapistUpdateField.QUOTE, "editingQuote", therapist.quote)}
                </div>
              </div>
            </div>

            <div className="main-details-row">
              <div className="therapist-description">
                <div className="detail-heading">
                  Products
                </div>
                <div className="detail-value">
                  {this.handleInputFieldEdit(TherapistUpdateField.PRODUCTS, "editingProducts", therapist.products)}
                </div>
              </div>
            </div>

            <div className="main-details-row">
              <div className="therapist-description">
                <div className="detail-heading">
                  Internal Notes
                </div>
                <div className="detail-value">
                  {this.handleTextareaFieldEdit(TherapistUpdateField.INTERNAL_NOTES, "editingInternalNotes", therapist.internalNotes)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
