import * as React from "react";
import styled from "styled-components";

const Cell = styled.div`
    float: left; 
    width: 100%;
`;

interface Props {
  width: string;
  text: string;
}

export const DataTableCell: React.StatelessComponent<Props> = props => {

  return (
    <Cell style={{ textTransform: "capitalize", width: props.width }}>
      {props.text}
    </Cell>
  );
};
