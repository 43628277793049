import * as React from "react";
import * as cookie from "js-cookie";
import {
  Route,
  Redirect,
  Switch,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";
import { Dispatch, connect } from "react-redux";
import { hot } from "react-hot-loader";

import { HeaderContainer } from "./containers/header-container";
import NotificationsContainer from "./containers/notifications-container";
import { MarketingNotificationsContainer } from "./containers/marketing-notifications-container";
import { JobContainer } from "./containers/job-container";
import { PromotionContainer } from "./containers/promotion-container";
import { initiateApp } from "./reducers/operator/actions";
import { BookingsContainer } from "./containers/bookings-container";
import { BookingContainer } from "./containers/booking-container";
import { BookingActivityContainer } from "./containers/booking-activity-container";
import { BookingNotesContainer } from "./containers/booking-notes-container";
import CreateBookingContainer from "./containers/create-booking-container";
import TherapistsContainer from "./containers/therapists-container";
import { TherapistDetailsContainer } from "./containers/therapist-details-container";
import { TherapistImagesContainer } from "./containers/therapist-images-container";
import { TherapistTreatmentsContainer } from "./containers/therapist-treatments-container";
import { TherapistAvailabilityContainer } from "./containers/therapist-availability-container";
import { TherapistAvailabilityBulkContainer } from "./containers/therapist-availability-bulk-container";
import { TherapistChatContainer } from "./containers/therapist-chat-container";
import { CustomersContainer } from "./containers/customers-container";
import { CustomerContainer } from "./containers/customer-container";
import { CreateCustomerContainer } from "./containers/create-customer-container";
import { LoginContainer } from "./containers/login-container";
import { HomeContainer } from "./containers/home-container/index";
import { TreatmentsSearchContainer } from "./containers/treatments-search-container";
import { TreatmentContainer } from "./containers/treatment-container";
import { TreatmentCreateContainer } from "./containers/treatment-create-container";
import { RootState } from "./reducers";
import { TherapistPostcodesContainer } from "./containers/therapist-postcodes-container";
import { TherapistReviewsContainer } from "./containers/therapist-reviews-container";
import { TherapistTagsContainer } from "./containers/therapist-tags-container";
import { DashboardContainer } from "./containers/dashboard";
import { BookingStatementContainer } from "./containers/booking-statement-container";
import { FraudulentContainer } from "./containers/fraudulent-container";
import { ScriptContainer } from "./containers/script-container";
import { PartnerContainer } from "./containers/partner-container";
import { PartnerLoginContainer } from "./containers/partner-container/login-container";
import { RegionContainer } from "./containers/region-container";
import { IgnoredTherapistsContainer } from "./containers/ignored-therapists";

interface ReduxProps {
  isLoggedIn: boolean;
  permissions: any;
}

interface State {
  isLoaded: boolean;
}

type OwnProps = RouteComponentProps<{}>;

type Props = ReduxProps & OwnProps & { dispatch: Dispatch<{}> };

class Component extends React.Component<Props, State> {
  state: State = {
    isLoaded: false,
  };

  async componentDidMount() {
    await this.props.dispatch(initiateApp(this.props.history));
    this.setState({ isLoaded: true });

    if (this.props.location.pathname === "/") {
      this.props.history.push(this.props.isLoggedIn ? "/home" : "/login");
    }
  }

  componentDidCatch(err: Error, _info: object): void {
    console.log(`Caught here: ${err.message}`);
  }

  renderBaseRoutes() {
    return [
      <Route exact path="/dashboard" component={DashboardContainer} />,
      <Route exact path="/home" component={HomeContainer} />,
      <Route exact path="/login" component={LoginContainer} />,
      <Route exact path="/login/:partnerId" component={PartnerLoginContainer} />,
    ];
  }

  renderRootRoutes() {
    if (this.props.permissions.all) {
      return [
        <Route exact path="/platform" component={MarketingNotificationsContainer} />,
        <Route exact path="/jobs" component={JobContainer} />,
        <Route exact path="/promotions" component={PromotionContainer} />,
        <Route exact path="/partners" component={PartnerContainer} />,
        <Route exact path="/fraud" component={FraudulentContainer} />,
        <Route exact path="/script" component={ScriptContainer} />,
        <Route exact path="/regions" component={RegionContainer} />,
      ];
    }
  }

  renderWagesRoutes() {
    if (this.props.permissions.all) {
      return [
        <Route exact path="/ignore-therapists" component={IgnoredTherapistsContainer} />,
      ];
    }    
  }

  renderBookingsRoutes() {
    if (this.props.permissions.all || this.props.permissions.bookings) {
      return [
        <Route exact path="/bookings" component={BookingsContainer} />,
        <Route exact path="/bookings/create" component={CreateBookingContainer} />,
        <Route exact path="/bookings/:urn" component={BookingContainer} />,
        <Route exact path="/bookings/:urn/activity" component={BookingActivityContainer} />,
        <Route exact path="/bookings/:urn/notes" component={BookingNotesContainer} />,
        <Route exact path="/bookings/:urn/statements" component={BookingStatementContainer} />,
      ];
    }
  }

  renderTherapistsRoutes() {
    if (this.props.permissions.all || this.props.permissions.therapists) {
      return [
        <Route exact path="/therapists" component={TherapistsContainer} />,
        <Route exact path="/therapists/:urn" component={TherapistDetailsContainer} />,
        <Route exact path="/therapists/q:urn/details" component={TherapistDetailsContainer} />,
        <Route exact path="/therapists/:urn/images" component={TherapistImagesContainer} />,
        <Route exact path="/therapists/:urn/treatments" component={TherapistTreatmentsContainer} />,
        <Route exact path="/therapists/:urn/availability" component={TherapistAvailabilityContainer} />,
        <Route exact path="/therapists/:urn/availability-bulk" component={TherapistAvailabilityBulkContainer} />,
        <Route exact path="/therapists/:urn/postcodes" component={TherapistPostcodesContainer} />,
        <Route exact path="/therapists/:urn/chat" component={TherapistChatContainer} />,        
        <Route exact path="/therapists/:urn/reviews" component={TherapistReviewsContainer} />,
        <Route exact path="/therapists/:urn/tags" component={TherapistTagsContainer} />,
      ];
    }
  }

  renderTreatmentsRoutes() {
    if (this.props.permissions.all || this.props.permissions.treatments) {
      return [
        <Route exact path="/treatments" component={TreatmentsSearchContainer} />,
        <Route exact path="/treatments/create" component={TreatmentCreateContainer} />,
        <Route exact path="/treatments/:urn" component={TreatmentContainer} />,
      ];
    }
  }

  renderCustomersRoutes() {
    if (this.props.permissions.all || this.props.permissions.customers) {
      return [
        <Route exact path="/customers" component={CustomersContainer} />,
        <Route exact path="/customers/create" component={CreateCustomerContainer} />,
        <Route exact path="/customers/:urn/:page" component={CustomerContainer} />,
      ];
    }
  }

  render404Route() {
    const { pathname } = this.props.location;

    if (!/^\/login/.test(pathname) && (!cookie.get("token") || this.props.isLoggedIn)) {
      return (
        <Redirect to={`/login?path=${encodeURIComponent(pathname)}`} />
      );
    }
  }

  render(): JSX.Element {
    return (
      <>
        <HeaderContainer />
        <NotificationsContainer />
        <div className="container">
          <Switch>
            {this.renderBaseRoutes()}
            {this.renderRootRoutes()}
            {this.renderWagesRoutes()}
            {this.renderBookingsRoutes()}
            {this.renderCustomersRoutes()}
            {this.renderTherapistsRoutes()}
            {this.renderTreatmentsRoutes()}
            {this.render404Route()}
          </Switch>
        </div>
        <footer />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): ReduxProps => {
  return {
    isLoggedIn: state.operator.urn !== null,
    permissions: state.operator.permissions,
  };
};

export const App = hot(module)(
  withRouter(connect<ReduxProps, {}, OwnProps>(mapStateToProps)(Component)),
);
