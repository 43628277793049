import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { RootState } from "../../reducers";
import * as fraudulentAddressesActions from "../../reducers/fraudulent-addresses/actions";
import { ApiBooking, ApiAddress } from "../../api/main/booking";
import Table from "../../components/table";
import uuid from "node-uuid";
import { TableModal } from "../../components/sub";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import * as Modal from "react-modal";

const modalStyling: Modal.Styles = {
  content: {
    height: "fit-content",
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",
    border: "0px",
    backgroundColor: "#efefef",
  },
  overlay: {
    zIndex: 3,
  },
};

const ModalContainer = styled.div`
  background-color: #efefef;
`;

const ModalHeading = styled.div`
  background-color: #396174;
  color: #ffffff;
  margin: 0px;
  padding: 10px;
  font-weight: bold;
  text-transform: uppercase;
`;

const ModalBody = styled.div`
  margin: 10px 0 0 0;
  padding: 0 20px 0 20px;
`;

interface ReduxProps {
  booking: ApiBooking;
  fraudulentAddresses: ApiAddress[];
  isLoading: boolean;
}

interface IState {
  urn: string;
  address1: string;
  address2: string;
  postcode: string;
  isCreateModalVisible: boolean;
  isDeleteModalVisible: boolean;
}

type OwnProps = RouteComponentProps<{ urn: string }>;
type Props = ReduxProps & OwnProps & { dispatch: Dispatch<{}> };

interface DataTableDataItem {
  _id: string;
  name: string;
  value: string;
}

class Component extends React.Component<Props, IState> {
  state: IState;

  constructor(props: Props) {
    super(props);
    this.state = {
      urn: "",
      address1: "",
      address2: "",
      postcode: "",
      isCreateModalVisible: false,
      isDeleteModalVisible: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(fraudulentAddressesActions.fetchFraudulentAddresses());
  }

  toggleCreateModal = () => {
    this.setState({
      urn: "",
      address1: "",
      address2: "",
      postcode: "",
    });

    this.setState({ isCreateModalVisible: !this.state.isCreateModalVisible });
  };

  toggleDeleteModal = () => {
    this.setState({ isDeleteModalVisible: !this.state.isDeleteModalVisible });
  };

  toggleDeleteModalAndSet = (urn: string) => {
    this.setState({ urn });
    this.toggleDeleteModal();
  };

  handleInputChange(key: string, e: any) {
    let obj = {};
    obj[key] = e.target.value;
    this.setState(obj);
  }

  submitCreateBookingStatement = () => {
    const fraudulentAddress: ApiAddress = {
      urn: uuid.v4().toString(),
      address1: this.state.address1,
      address2: this.state.address2,
      postcode: this.state.postcode,
    };

    this.props.dispatch(
      fraudulentAddressesActions.createFraudulentAddress(fraudulentAddress),
    );
    this.toggleCreateModal();
  };

  submitDeleteAddress = () => {
    const address: ApiAddress = this.props.fraudulentAddresses.find(
      (b) => b.urn === this.state.urn,
    );

    this.props.dispatch(
      fraudulentAddressesActions.deleteBookingStatement(address.urn),
    );

    this.toggleDeleteModal();
  };

  render(): JSX.Element {
    const dataset: DataTableDataItem[] = this.props.fraudulentAddresses.map(
      (f) => {
        const item: DataTableDataItem = {
          _id: f.urn,
          name: `${f.address1}, ${f.address2}, ${f.postcode}`,
          value: "",
        };
        return item;
      },
    );

    const rowActions = [
      {
        name: "Delete",
        onClick: (id: string) => { this.toggleDeleteModalAndSet(id) },
      },
    ];

    return (
      <>
        <div className="booking-chat-container">
          <div className="main-section" style={{ width: "100%" }}>
            <Table
              tableName="Fraudulent addresses"
              createEntryAction={this.toggleCreateModal}
              columns={[{ title: "Address", key: "name", width: "90%" }]}
              rowActions={rowActions}
              dataset={dataset}
              emptyMessage="No addresses to show"
              isLoading={this.props.isLoading}
            />
          </div>
        </div>

        <Modal
          isOpen={this.state.isCreateModalVisible}
          style={modalStyling}
          ariaHideApp={false}
        >
          <ModalContainer>
            <ModalHeading>Create fraudulent address</ModalHeading>
            <ModalBody>
              <form>
                <div
                  className="main-job-details-row"
                  style={{
                    padding: "10px 15px 10px 15px",
                    textAlign: "left",
                    backgroundColor: "#E82127",
                    color: "#ffffff",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Warning: </span>
                  By adding this address into the fraudulent list any new
                  customer booking using this address will fail. You can delete
                  the address with immediate effect at any time.
                </div>
                <div className="main-job-details-row">
                  <div className="detail-job-heading">Address 1</div>
                  <div className="detail-job-value">
                    <input
                      style={{ textTransform: "capitalize" }}
                      type="text"
                      value={this.state.address1}
                      onChange={this.handleInputChange.bind(this, "address1")}
                      autoFocus={true}
                    />
                  </div>
                </div>
                <div className="main-job-details-row">
                  <div className="detail-job-heading">Address 2</div>
                  <div className="detail-job-value">
                    <input
                      style={{ textTransform: "capitalize" }}
                      type="text"
                      value={this.state.address2}
                      onChange={this.handleInputChange.bind(this, "address2")}
                    />
                  </div>
                </div>
                <div className="main-job-details-row">
                  <div className="detail-job-heading">Post code</div>
                  <div className="detail-job-value">
                    <input
                      style={{ textTransform: "capitalize" }}
                      type="text"
                      value={this.state.postcode}
                      onChange={this.handleInputChange.bind(this, "postcode")}
                    />
                  </div>
                </div>

                <div className="main-job-details-row" style={{ float: "left" }}>
                  <Button
                    onClick={() => this.submitCreateBookingStatement()}
                    className="btn-create-job"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => this.toggleCreateModal()}
                    className="btn-cancel-job"
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </ModalBody>
          </ModalContainer>
        </Modal>

        <TableModal
          isOpen={this.state.isDeleteModalVisible}
          title="Address deletion"
          message={"Are you sure you want to delete this address?"}
          confirmEvent={{
            event: this.submitDeleteAddress,
            text: "Yes",
          }}
          cancelEvent={{
            event: this.toggleDeleteModal,
            text: "No",
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: Props): ReduxProps => {
  return {
    booking: state.bookings.bookings[ownProps.match.params.urn],
    fraudulentAddresses: state.fraudulentAddresses.addresses,
    isLoading: state.fraudulentAddresses.isLoading,
  };
};

export const FraudulentContainer = withRouter(
  connect<ReduxProps, {}, OwnProps>(mapStateToProps)(Component),
);
