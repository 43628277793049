import * as React from "react";
import * as Spinner from "react-spinkit";
import styled from "styled-components";

const SpinnerLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface State {
  isShow: boolean;
}

export class SpinnerLoader extends React.Component<{}, State> {
  timer: any;

  state: State = {
    isShow: false,
  };

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({ isShow: true });
    }, 300);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    return this.state.isShow ? (
      <SpinnerLoaderWrapper>
        <Spinner name="pulse" fadeIn="none" />
      </SpinnerLoaderWrapper>
    ) : null;
  }
}
