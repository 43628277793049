import * as React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import * as Spinner from "react-spinkit";
import { withRouter, RouteComponentProps } from "react-router-dom";

import {TherapistSubMenu} from "../../components/sub/sub-menu";
import { TherapistImagesComponent } from "../../components/therapist/therapist-images-component";
import {RootState} from "../../reducers";
import { ApiTherapist } from "../../api/main/therapist";
import { fetchTherapist } from "../../model/therapist";
import { BusyState } from "../../reducers/therapist/reducer";
import { searchTherapist } from "../../reducers/bookings/actions";

interface ReduxProps {
  therapist: ApiTherapist;
  busy: BusyState;
  isBusy: boolean;
  isPartner: boolean;
}

type OwnProps = RouteComponentProps<{urn: string}>;
type Props = ReduxProps & OwnProps & {dispatch: Dispatch<{}>};

class Component extends React.Component<Props> {
  componentDidMount() {
    if (!this.props.therapist)
      this.props.dispatch(fetchTherapist(this.props.match.params.urn));
  }

  handleClickBookings = (): void => {
    this.props.dispatch(searchTherapist(this.props.therapist.urn));
  }

  render() {
    const {therapist} = this.props;

    return (
      <div className="therapist-container">
        {therapist &&
          <div className="main-section">
            <TherapistImagesComponent
              therapist={therapist}
              dispatch={this.props.dispatch}
              isBusy={this.props.isBusy}
              isPartner={this.props.isPartner}
              busy={this.props.busy} />
          </div>
        }
        {!therapist &&
          <div className="spinner-container main-section">
            <Spinner name="pulse" fadeIn="none" />
          </div>
        }

        <div className="side-nav">
          <TherapistSubMenu urn={this.props.match.params.urn} active="images" isPartner={this.props.isPartner} onClickBookings={this.handleClickBookings} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): ReduxProps => {
  const therapist = state.therapistsState.therapists[ownProps.match.params.urn];
  const isPartner = state.operator.urn && state.operator.urn.startsWith("urn:ruuby:partner:");

  return {
    therapist: (therapist) ? therapist : null,
    busy: state.therapistsState.busy,
    isBusy: state.therapistsState.isBusy,
    isPartner,
  };
};

export const TherapistImagesContainer = withRouter(connect<ReduxProps, {}, OwnProps>(mapStateToProps)(Component));
