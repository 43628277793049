import * as React from "react";
import { connect, } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { RootState } from "../../reducers";
import { loginPartner } from "../../reducers/operator/actions";
import { PartnerLogin } from "../../components/partner-login";

interface ReduxProps {
  hasErrored: boolean;
}

type OwnProps = RouteComponentProps<{ partnerId: string }> & { dispatch: Dispatch<{}> };
type Props = ReduxProps & OwnProps;

class Component extends React.Component<Props> {
  handleLogin = (partnerId: string, username: string, password: string) => {
    this.props.dispatch(loginPartner(this.props.history, partnerId, username, password));
  }

  render() {
    return (
      <div>
        <PartnerLogin
          onLogin={this.handleLogin}
          partnerId={this.props.match.params.partnerId}
          hasErrored={this.props.hasErrored}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): ReduxProps => {
  return {
    hasErrored: state.operator.hasErroredLoggingIn,
  };
};

export const PartnerLoginContainer = withRouter(connect<ReduxProps, {}, OwnProps>(mapStateToProps)(Component));
