import * as React from "react";
import { BookingsRow } from "./bookings-row";
import { Table } from "react-bootstrap";

interface BookingsTableProps {
  bookings: any[];
  showAll?: boolean;
  partner: any;
}

export default class BookingsTable extends React.Component<BookingsTableProps, {}> {
  render() {
    const bookingsRows = this.props.bookings.map(booking => <BookingsRow
      key={booking.urn}
      booking={booking}
      showAll={this.props.showAll}
      partner={this.props.partner} />);

    return (
      <Table className="app-table">
        {this.props.showAll && !this.props.partner ?
          <>
            <colgroup>
              <col width="12%" />
              <col width="12%" />
              <col width="10%" />
              <col width="15%" />
              <col width="20%" />
              <col width="5%" />
              <col width="6%" />
              <col width="5%" />
              <col width="3%" />
              <col width="12%" />
            </colgroup>
            <thead>
              <tr>
                <th>Time booked</th>
                <th>Appt. time</th>
                <th>Therapist</th>
                <th>Customer</th>
                <th>Treatments</th>
                <th>Price</th>
                <th>Ruuby commission</th>
                <th>Tier</th>
                <th>Postcode</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
          </>
          : ""}
        {!this.props.showAll && !this.props.partner ?
          <>
            <colgroup>
              <col width="20%" />
              <col width="20%" />
              <col width="10%" />
              <col width="20%" />
              <col width="10%" />
              <col width="20%" />
            </colgroup>
            <thead>
              <tr>
                <th>Time booked</th>
                <th>Appt. time</th>
                <th>Therapist</th>
                <th>Treatments</th>
                <th>Price</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
          </> : ""}
        {this.props.partner ?
          <>
            <colgroup>
              <col width="14%" />
              <col width="14%" />
              <col width="12%" />
              <col width="15%" />
              <col width="20%" />
              <col width="5%" />
              <col width="5%" />
              <col width="3%" />
              <col width="12%" />
            </colgroup>
            <thead>
              <tr>
                <th>Time booked</th>
                <th>Appt. time</th>
                <th>Therapist</th>
                <th>Customer</th>
                <th>Treatments</th>
                <th>Price</th>
                <th>Tier</th>
                <th>Postcode</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
          </> : ""}

        <tbody>
          {bookingsRows}
        </tbody>
      </Table>
    );
  };
}
