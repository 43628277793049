import * as Immutable from "immutable";
import * as types from "./types";
import { Action } from "../login/reducer";

const initialTransactionsState = Immutable.fromJS({
  error: null,
  isBusy: false,
  isOpen: false,
  token: null,
  isNewCardModalOpen: false
});

export default (state: Immutable.Map<any, any> = initialTransactionsState, action: Action) => {
  switch (action.type) {
    case types.TRANSACTION_ATTEMPT:
      return state.set("isBusy", true)
        .set("error", null);

    case types.OPEN_TRANSACTION_MODAL:
      return state.set("isOpen", true);

    case types.CLOSE_TRANSACTION_MODAL:
      return state.set("isBusy", false)
        .set("error", null)
        .set("isOpen", false);

    case types.OPEN_NEW_CARD_MODAL:
      return state.set("isNewCardModalOpen", true);

    case types.CLOSE_NEW_CARD_MODAL:
      return state.set("isBusy", false)
        .set("error", null)
        .set("isNewCardModalOpen", false);

    case types.TRANSACTION_SUCCESS:
      return state.set("isOpen", false)
        .set("isNewCardModalOpen", false)
        .set("isBusy", false)
        .set("error", null);

    case types.TRANSACTION_ERROR:
      return state.set("error", action.payload.error)
        .set("isBusy", false);

    case types.FETCH_BRAINTREE_TOKEN_SUCCESS:
      return state.set("token", action.payload.token);

    default:
      return state;
  }
};
