export type FETCH_ATTEMPT = "bookingNote.FETCH_BOOKING_NOTES";
export const FETCH_ATTEMPT: FETCH_ATTEMPT = "bookingNote.FETCH_BOOKING_NOTES";

export type FETCH_SUCCESS = "bookingNote.FETCH_BOOKING_NOTES_SUCCESS";
export const FETCH_SUCCESS: FETCH_SUCCESS = "bookingNote.FETCH_BOOKING_NOTES_SUCCESS";

export type FETCH_FAILURE = "bookingNote.FETCH_BOOKING_NOTES_FAIL";
export const FETCH_FAILURE: FETCH_FAILURE = "bookingNote.FETCH_BOOKING_NOTES_FAIL";

export type CLEAR = "bookingNote.CLEAR_NOTES";
export const CLEAR: CLEAR = "bookingNote.CLEAR_NOTES";

export type CREATE_SUCCESS = "bookingNote.CREATE_NOTE_SUCCESS";
export const CREATE_SUCCESS: CREATE_SUCCESS = "bookingNote.CREATE_NOTE_SUCCESS";

export type UPDATE = "bookingNote.UPDATE";
export const UPDATE: UPDATE = "bookingNote.UPDATE";
