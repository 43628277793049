export const reduce = Function.bind.call(Function.call, Array.prototype.reduce);
export const isEnumerable = Function.bind.call(Function.call, Object.prototype.propertyIsEnumerable);
export const concat = Function.bind.call(Function.call, Array.prototype.concat);
export const keys = Reflect.ownKeys;

if (!Object.values) {
  Object.values = function values(O: object) {
    return reduce(keys(O), (v: any, k: any) => concat(v, typeof k === "string" && isEnumerable(O, k) ? [O[k]] : []), []);
  };
}

if (!Object.entries) {
  Object.entries = function entries(O: object) {
    return reduce(keys(O), (e: any, k: any) => concat(e, typeof k === "string" && isEnumerable(O, k) ? [[k, O[k]]] : []), []);
  };
}
