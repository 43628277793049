import * as Immutable from "immutable";
import * as types from "./types";
import { Action } from "../../login/reducer";
import * as moment from "moment";

const initialBookingCreateState = Immutable.fromJS({
  isBusy: false,
  error: null,
  customer: null,
  timeStarts: moment(),
  timeEnds: moment(),
  therapist: null,
  therapistUrn: null,
  address: null,
  notes: null,
  status: null,
  tier: "CLASSIC",
  treatments: null,
  selectedTreatments: null,
  selectedTreatmentsPrice: Immutable.fromJS([]), // edited treatment price
  selectedTreatmentsQty: [],
  adjustments: [],
  promos: [],
  doNotSendEmail: false,
  isB2b: false,
  isProduction: false,
});

const createBookingState = (
  state: Immutable.Map<{}, {}> = initialBookingCreateState,
  action: Action,
) => {
  switch (action.type) {
    case types.SET_BOOKING_CUSTOMER:
      return state.set("customer", Immutable.fromJS(action.payload.customer));

    case types.SET_BOOKING_DATE:
      return state.set("timeStarts", action.payload.date);

    case types.SET_BOOKING_THERAPIST:
      return state.set("therapist", action.payload.therapist);

    case types.SET_BOOKING_ADDRESS:
      return state.set("address", action.payload.address);

    case types.SET_BOOKING_NOTES:
      return state.set("notes", action.payload.notes);

    case types.SET_BOOKING_STATUS:
      return state.set("status", action.payload.status);

    case types.SET_BOOKING_TIER:
      return state.set("tier", action.payload.tier);

    case types.FETCH_THERAPIST_TREATMENTS_SUCCESS:
      return state
        .set("treatments", action.payload.treatments)
        .set("therapistUrn", action.payload.therapistUrn);

    case types.SET_BOOKING_SELECTED_TREATMENTS:
      return state.set("selectedTreatments", action.payload.treatments);

    /**
     When updating selectedTreatmentsPrice state we first check if quantity already exists in the array, if so we get the index of its location in the array, then we splice the array replacing old quantity object with new updated object. If it doesn't already exist we push new object to array
     */
    case types.AMEND_BOOKING_SELECTED_TREATMENT_PRICE:
      let urn = action.payload.urn;
      let idx: number;
      const treatment = (state.get("selectedTreatmentsPrice") as any[]).find(
        (t: any, i: number) => {
          if (t.urn === urn) {
            idx = i;
            return true; // return boolean for .find function
          }
          return false;
        },
      );

      if (treatment) {
        return state.update(
          "selectedTreatmentsPrice",
          (treatments: Immutable.List<{}>) => {
            const t = treatments.toJS()[idx];
            t.price = action.payload.price;
            return treatments.splice(idx, 1, t);
          },
        );
      } else {
        return state.update(
          "selectedTreatmentsPrice",
          (treatments: Immutable.List<{}>) =>
            treatments.push({
              urn: action.payload.urn,
              price: action.payload.price,
            }),
        );
      }

    case types.ADD_ADJUSTMENT:
      return state.update("adjustments", (adjustments: any[]) =>
        adjustments.push({
          type: action.payload.type,
          amount: action.payload.amount,
        }),
      );

    case types.DELETE_ADJUSTMENT:
      return state.deleteIn(["adjustments", action.payload.index]);

    case types.AMEND_TREATMENT_QTY:
      urn = action.payload.urn;
      const quantity = (state.get("selectedTreatmentsQty") as any[]).find(
        (t: any, i: number) => {
          if (t.urn === urn) {
            idx = i;
            return true;
          }
          return false;
        },
      );

      if (quantity) {
        return state.update(
          "selectedTreatmentsQty",
          (quantities: Immutable.List<{}>) => {
            const q = quantities.toJS()[idx];
            q.qty = action.payload.qty;
            return quantities.splice(idx, 1, q);
          },
        );
      } else {
        return state.update("selectedTreatmentsQty", (quantities: any[]) =>
          quantities.push({
            urn,
            qty: action.payload.qty,
          }),
        );
      }

    case types.ADD_PROMO:
      return state.update("promos", (promos: any[]) =>
        promos.push(action.payload.promoTransaction),
      );

    case types.DELETE_PROMO:
      let promos: any = (state.get("promos") as any).filter(
        (p: any) => p.urn !== action.payload.urn,
      );
      return state.set("promos", promos);

    case types.SUBMIT_BOOKING_ATTEMPT:
      return state.set("isBusy", true);

    case types.SUBMIT_BOOKING_SUCCESS:
      return initialBookingCreateState;

    case types.SUBMIT_BOOKING_ERROR:
      return state.set("isBusy", false);

    case types.FETCH_BOOKING_CUSTOMER_ADDRESS_SUCCESS:
      return action.payload.bookingUrn
        ? state
        : state
            .setIn(["customer", "addresses"], action.payload.addresses)
            .set("address", null);

    case types.TOGGLE_DO_NOT_SEND_EMAIL_FLAG:
      return state.update("doNotSendEmail", (val) => !val);

    case types.TOGGLE_IS_B2B:
      const isB2b = state.get("isB2b");
      const newState = state.set("isB2b", !isB2b);

      if (isB2b) {
        return newState.set("isProduction", false);
      }

      return newState;

    case types.TOGGLE_IS_PRODUCTION:
      return state.update("isProduction", (val) => !val);

    default:
      return state;
  }
};

export default createBookingState;
