import * as React from "react";
import * as moment from "moment";
import { Table } from "react-bootstrap";
import { sprintf } from "sprintf-js";
import { Dispatch } from "react-redux";

import { config } from "../../../config";
import * as constants from "../../../constants/index";
import { Button } from "../../atoms/button";
import { CreateRefundModal } from "../create-refund-modal";
import { BookingUpdateValue, ApiBooking, ApiBookingTransaction } from "../../../api/main/booking";
import { RequestPaymentModal } from "../request-payment-modal";
import { CreateStripeTransaction } from "./create-stripe-transaction";

interface Props {
  transactions: any[];
  outstanding: number;
  cards: any[];
  booking: ApiBooking;
  transactionState: any;
  fetchCustomerCards: (urn: string) => void;
  openTransactionModal: () => void;
  closeTransactionModal: () => void;
  debitCard: (amount: number, card: any) => void;
  manualRefund: (value: BookingUpdateValue) => void;
  addTransaction: (type: string, amount: number) => void;
  dispatch?: Dispatch<{}>;
}

interface State {
  isNewTransactionModalOpen: boolean;
  isRefundModalOpen: boolean;
  isRequestPaymentModalOpen: boolean;
  cancelationValue: BookingUpdateValue;
}

export class Transactions extends React.Component<Props, State> {
  state: State = {
    isNewTransactionModalOpen: false,
    isRefundModalOpen: false,
    isRequestPaymentModalOpen: false,
    cancelationValue: this.props.booking.cancellationReason,
  };

  createBraintreeUrl = (transaction: ApiBookingTransaction): string => {
    return (config.braintree.environment === "sandbox") ?
      `https://sandbox.braintreegateway.com/merchants/${config.braintree.merchantId}/transactions/${transaction.transactionId}`
      : `https://www.braintreegateway.com/merchants/${config.braintree.merchantId}/transactions/${transaction.transactionId}`;
  }

  createStripeUrl = (transaction: ApiBookingTransaction): string => {
    return `${config.stripe.dashboard.url}/${config.environment === "prod" ? '' : 'test/'}payments/${transaction.transactionId}`;
  }

  getPaymentMethod = (transaction: ApiBookingTransaction): string => {
    if (transaction.method?.details) {
      const { method } = transaction;
      const { details } = method;
      switch (method.type) {
        case "apple_pay_card":
          return "Apple Pay";
        case "paypal_account":
          return "PayPal";
        default:
          return `${details.cardType} ${details.maskedNumber}`;
      }  
    } else {
      return transaction.type.replace('_TRANSACTION', '').replace('_',' ');
    }
  }

  newCancelationReason = (value: BookingUpdateValue) => {
    this.setState({
      cancelationValue: value,
    });
  }

  closeRefundModal = () => {
    this.setState({ isRefundModalOpen: false });
  }

  closeRequestPaymentModal = () => {
    this.setState({ isRequestPaymentModalOpen: false });
  }

  openTransactionModal = () => {
    if (config.braintree.isActive) {
      this.props.openTransactionModal();
    } else {
      this.setState({ isNewTransactionModalOpen: true });
    }
  }

  closeTransactionModal = () => {
    if (config.braintree.isActive) {
      this.props.closeTransactionModal();
    } else {
      this.setState({ isNewTransactionModalOpen: false });
    }
  }

  sendForRefund = () => {
    this.props.manualRefund(this.state.cancelationValue)
    this.closeRefundModal();
  }

  renderPaymentLink = (transaction: ApiBookingTransaction): JSX.Element => {
    const paymentUrl = this.createStripeUrl(transaction);

    return (
      <a href={paymentUrl} target="_blank" style={{ textTransform: "capitalize" }}>
        {this.getPaymentMethod(transaction).toLowerCase()}
      </a>
    );
  };

  renderRows = (): JSX.Element[] => {

    return this.props.transactions
      .map((transaction, index) => (
        <tr key={index} className="adjustments-row">
          <td>
            {moment(transaction.timeCreated).tz(config.timezone).format("DD/MM/YYYY HH:mm")}
          </td>
          {transaction.type === constants.TRANSACTION_CUSTOMER_CREDIT ? (
            <td>Customer Credit</td>
          ) : transaction.type === constants.TRANSACTION_INVOICE ? (
            <td>Invoice</td>
          ) : (
                <td>
                  {this.renderPaymentLink(transaction)}
                </td>
              )}
          <td>{sprintf("£%0.2f", transaction.amount)}</td>
          <td></td>
        </tr>
      ));
  }

  renderOutstandingRow = (): JSX.Element => {
    return (
      <tr>
        <td></td>
        <td className="total">Outstanding </td>
        <td>{sprintf("£%0.2f", this.props.outstanding)}</td>
        <td></td>
      </tr>
    );
  }

  render() {
    const noTransactions = (
      <div className="no-adjustments">
        No transactions for this booking
      </div>
    );

    return (
      <div className="adjustments">
        <div className="adjustments-header">
          <h3>Transactions</h3>

          {config.featureSwitch.showRequestPaymentButton && (
            <Button 
              disabled={this.props.outstanding === 0} 
              label="Request Payment" 
              icon="fa-hand-pointer-o"
              className="new-adjustment btn btn-primary" 
              onClick={() => this.setState({ isRequestPaymentModalOpen: true, })} 
            />
          )}

          {!this.props.booking.refunds && this.props.booking.status === "CANCELLED" &&
            <Button label="Manual refund" icon="fa-undo" className="new-adjustment btn btn-primary" onClick={() => this.setState({ isRefundModalOpen: true })} />
          }
          <Button label="New Transaction" icon="fa-plus" className="new-adjustment btn btn-primary" onClick={() => this.openTransactionModal()} />
        </div>

        <Table>
          <colgroup>
            <col width="40%" />
            <col width="40%" />
            <col width="15%" />
            <col width="5%" />
          </colgroup>

          <thead>
            <tr>
              <th>Payment Date/Time</th>
              <th>Payment Method</th>
              <th>Amount</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {this.renderRows()}
            {this.renderOutstandingRow()}
          </tbody>
        </Table>

        <CreateStripeTransaction
          booking={this.props.booking}
          isOpen={this.state.isNewTransactionModalOpen}
          closeModal={this.closeTransactionModal}
          addTransaction={this.props.addTransaction}
        />

        <CreateRefundModal
          cancelationValue={this.state.cancelationValue}
          isOpen={this.state.isRefundModalOpen}
          closeModal={this.closeRefundModal}
          onUpdateReason={this.newCancelationReason}
          handleSubmit={this.sendForRefund}
        />

        <RequestPaymentModal
          bookingUrn={this.props.booking.urn}
          isOpen={this.state.isRequestPaymentModalOpen && config.featureSwitch.showRequestPaymentButton}
          closeModal={this.closeRequestPaymentModal}
          dispatch={this.props.dispatch}
        />

        {this.props.transactions.length === 0 && noTransactions}
      </div>
    );
  }
}