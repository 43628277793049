import * as React from "react";
import * as Modal from "react-modal";

interface Props {
  isOpen: boolean;
  title?: string;
  message: string;
  closeModal: () => void;
  handleConfirm: () => void;
}

const modalStyling: Modal.Styles = {
  content: {
    height: "fit-content",
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",
  },
  overlay: {
    zIndex: 3
  }
};

export const ConfirmAlert: React.SFC<Props> = ({title, message, isOpen, handleConfirm, closeModal}) => (
  <Modal
  isOpen={isOpen}
  style={modalStyling}
  contentLabel={title}
  onRequestClose={closeModal}
  >
  <div className="alert-modal">
    <div className="row">
      <div className="col-md-12">
        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </div>
    </div>
    <div className="row alert-modal-info">
      <div className="col-md-12">
        {title && <h3>{title}</h3>}
        <p>{message}</p>
      </div>
    </div>
    <div className="row btn-row">
      <div className="col-md-6 confirm">
        <button type="button" onClick={() => handleConfirm()} className="btn btn-success">Confirm</button>
      </div>
      <div className="col-md-6 cancel">
        <button type="button" onClick={() => closeModal()} className="btn btn-danger">Cancel</button>
      </div>
    </div>
  </div>
</Modal>
);
