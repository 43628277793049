import { createStandardAction } from "typesafe-actions";
import { ActionDispatch } from "../../";
import * as promotionApi from "../../../api/main/promotions";
import { NOTIFICATION_TYPES } from "../../notifications/types";
import { addNotification } from "../../notifications/actions";

interface FetchAllSuccessPayload {
  promotions: promotionApi.Promotion[];
}

interface CreateSuccessPayload {
  promotion: promotionApi.Promotion;
}

interface EditSuccessPayload {
  promotion: promotionApi.Promotion;
}

interface RequestDeletePromotion {
  code: string;
}

export const actions = {
  requestGetAllPromotionsInit: createStandardAction(
    "promotions/REQUEST_GET_ALL_PROMOTIONS_INIT",
  )<void>(),
  requestGetAllPromotionsSuccess: createStandardAction(
    "promotions/REQUEST_GET_ALL_PROMOTIONS_SUCCESS",
  )<FetchAllSuccessPayload>(),
  requestCreatePromotionInit: createStandardAction(
    "promotions/REQUEST_CREATE_PROMOTION_INT",
  )<void>(),
  requestCreatePromotionSuccess: createStandardAction(
    "promotions/REQUEST_CREATE_PROMOTION_SUCCESS",
  )<CreateSuccessPayload>(),
  requestEditPromotionInit: createStandardAction(
    "promotions/REQUEST_EDIT_PROMOTION_INT",
  )<void>(),
  requestEditPromotionSuccess: createStandardAction(
    "promotions/REQUEST_EDIT_PROMOTION_SUCCESS",
  )<EditSuccessPayload>(),
  requestDeletePromotionInit: createStandardAction(
    "promotions/REQUEST_DELETE_PROMOTION_INT",
  )<void>(),
  requestDeletePromotionSuccess: createStandardAction(
    "jobs/REQUEST_DELETE_PROMOTION_SUCCESS",
  )<RequestDeletePromotion>(),
};

export function fetch(query: string): ActionDispatch {
  return async (dispatch) => {
    dispatch(actions.requestGetAllPromotionsInit());

    try {
      const promotions = await promotionApi.searchPromotions(query);
      dispatch(actions.requestGetAllPromotionsSuccess({ promotions }));
    } catch (error) {
      dispatch(
        addNotification(
          NOTIFICATION_TYPES.danger,
          "Error fetching promotions",
          error.message,
        ),
      );
    }
  };
}

export function create(promotion: promotionApi.Promotion): ActionDispatch {
  return async (dispatch) => {
    dispatch(actions.requestCreatePromotionInit());

    try {
      await promotionApi.create(promotion);
      dispatch(actions.requestCreatePromotionSuccess({ promotion }));
      dispatch(
        addNotification(
          NOTIFICATION_TYPES.success,
          "Created!",
          "Promotion has been created successfully",
        ),
      );
    } catch (error) {
      dispatch(
        addNotification(
          NOTIFICATION_TYPES.danger,
          "Error creating promotion",
          error.message,
        ),
      );
    }
  };
}

export function edit(promotion: promotionApi.Promotion): ActionDispatch {
  return async (dispatch) => {
    dispatch(actions.requestEditPromotionInit());

    try {
      await promotionApi.edit(promotion);
      dispatch(actions.requestEditPromotionSuccess({ promotion }));
      dispatch(
        addNotification(
          NOTIFICATION_TYPES.success,
          "Updated!",
          "Promotion has been changed successfully",
        ),
      );
    } catch (error) {
      dispatch(
        addNotification(
          NOTIFICATION_TYPES.danger,
          "Error updating promotion",
          error.message,
        ),
      );
    }
  };
}

export function deletePromotion(code: string): ActionDispatch {
  return async (dispatch) => {
    dispatch(actions.requestDeletePromotionInit());

    try {
      await promotionApi.deletePromotion(code);
      dispatch(actions.requestDeletePromotionSuccess({ code }));
      dispatch(
        addNotification(
          NOTIFICATION_TYPES.success,
          "Deleted!",
          "Promotion has been deleted successfully",
        ),
      );
    } catch (error) {
      dispatch(
        addNotification(
          NOTIFICATION_TYPES.danger,
          "Error deleting promotion",
          error.message,
        ),
      );
    }
  };
}
