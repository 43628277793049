import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {BookingSubMenu} from "../../components/sub/sub-menu";
import { withRouter, RouteComponentProps } from "react-router-dom";

import {RootState} from "../../reducers";
import {BookingActivity} from "../../components/booking-activity";
import {
  fetchActivityLogs,
  actionCreators
} from "../../reducers/booking-activity/actions";
import { ActivityLog } from "../../api/main/audit";
import { ApiBooking } from "../../api/main/booking";

interface ReduxProps {
  booking: ApiBooking;
  logs: ActivityLog[];
  isLoading: boolean;
}

type OwnProps = RouteComponentProps<{urn: string}>;
type Props = ReduxProps & OwnProps & {dispatch: Dispatch<{}>};

class Component extends React.Component<Props> {
  componentDidMount() {
    if (!this.props.logs.length)
      this.props.dispatch(fetchActivityLogs(this.props.match.params.urn));
  }

  componentWillUnmount() {
    this.props.dispatch(actionCreators.clearActivityLog());
  }

  render(): JSX.Element {
    const {logs, isLoading} = this.props;

    return (
      <div className="booking-chat-container">
        <div className="main-section">
          <BookingActivity logs={logs} isLoading={isLoading} />
        </div>

        <div className="side-nav">
          <BookingSubMenu urn={this.props.match.params.urn} active="audit" notesCount={this.props.booking.notesRequiringAction} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): ReduxProps => {
  return {
    booking: state.bookings.bookings[ownProps.match.params.urn],
    logs: state.activityLogState.logs,
    isLoading: state.activityLogState.isLoading
  };
};

export const BookingActivityContainer = withRouter(connect<ReduxProps, {}, OwnProps>(mapStateToProps)(Component));
