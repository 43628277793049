import * as React from "react";
import { PostcodeDistrict } from "../../../presenters/postcodes/postcodes-presenter";
import styled from "../../../../node_modules/styled-components";
import { SetPostcodeForTherapist } from ".";

interface Props {
  therapistUrn: string;
  district: PostcodeDistrict;
  enabled: boolean;
  setPostcodeForTherapist: SetPostcodeForTherapist;
}

export const mapLinkContent = "\u{1F4CC}"; // A push-pin
export const checkMark = "\u2714"; // A nice check mark

const DistrictTitle = styled.div`
font-size: 18px;
`;

interface DistrictWrapperProps {
  enabled: boolean;
}
const DistrictWrapper = styled.div`
display: flex;
justify-content: space-between;
background-color: ${(props: DistrictWrapperProps) => props.enabled ? "#fdeaed" : "white"};
margin: 1px;
padding: 1em;
cursor: pointer;
`;

const MapLink = styled.a`
  &:hover {
    text-decoration: none;
    border: 1px solid grey;
  }
`;

/**
 * Postcode districts don't cleanly map to nicely describable locations; we display them
 * slightly differently depending on how many neighbourhoods they contain.
 */
const makeDistrictTitle =
  (district: PostcodeDistrict) => district.localAreas.length <= 2
  ? `${district.outCode}: ${district.localAreas.join(" / ")}, ${district.administrativeArea}`
  : `${district.outCode}: ${district.administrativeArea}`;

/**
 * For districts with a large number of neighbourhoods inside, we display the full list in smaller text
 */
const makeDistrictDetails =
  (district: PostcodeDistrict) => district.localAreas.length <= 2
  ? ""
  : `${ district.localAreas.join(", ") }`;

export const flagIcon = {
  congestion: "\u{1F697}", // red car
  top: "\u2764\uFE0F", // red heart (the second character makes it display in "emoji style")
};

/**
 * A component that displays displays a postcode district, clickable to turn it on and off
 */
export const PostcodeDistrictComponent: React.StatelessComponent<Props> = (props: Props) => {
  const onClickHandler = () => props.setPostcodeForTherapist(props.therapistUrn, props.district.urn, !props.enabled);
  const title = makeDistrictTitle(props.district);
  const details = makeDistrictDetails(props.district);

  const flags = props.district.flags.map(f => {
    if (typeof flagIcon[f] !== "undefined") {
      return flagIcon[f];
    }
    else {
      return f;
    }
  }).join(" ");

  return (
    <DistrictWrapper enabled={props.enabled} onClick={onClickHandler}>
      <div>
        <DistrictTitle><MapLink target="_new" href={`https://www.doogal.co.uk/UKPostcodes.php?Search=${props.district.outCode}`}>{mapLinkContent}</MapLink> {title} {flags}</DistrictTitle>
        <div>{details}</div>
      </div>
      <div>{props.enabled ? checkMark : ""}</div>
    </DistrictWrapper>
  );
}
