import * as React from "react";

interface AddressRowsProps {
  handleDeleteAddress: (index: number) => void;
  handleAddAddress: (address: any) => void;
  toggleFormVisibility: () => void;
  showForm: boolean;
  addresses: any[];
}

interface AddressRowsState {
  address1?: string;
  address2?: string;
  postcode?: string;
}

const defaultAddressState = {
  address1: "",
  address2: "",
  postcode: ""
};

export default class AddressRows extends React.Component<AddressRowsProps, AddressRowsState> {
  constructor(props: AddressRowsProps) {
    super(props);
    this.state = defaultAddressState;

    this.newAddressRow = this.newAddressRow.bind(this);
  }

  handleNewAddressInputs(key: string, event: any) {
    let obj = {}; obj[key] = event.target.value;
    this.setState(obj);
  }

  toggleFormVisibility() {
    this.setState(defaultAddressState);
    this.props.toggleFormVisibility();
  }

  handleAddAddress() {
    this.props.handleAddAddress(this.state);
    this.toggleFormVisibility();
  }

  newAddressRow() {
    const isValidSubmit = this.state.address1.length === 0 || this.state.postcode.length === 0;

    return (
      <tr key={-1}>
        <td><input type="text" value={this.state.address1} onChange={this.handleNewAddressInputs.bind(this, "address1")} /></td>
        <td><input type="text" value={this.state.address2} onChange={this.handleNewAddressInputs.bind(this, "address2")} /></td>
        <td><input type="text" value={this.state.postcode} onChange={this.handleNewAddressInputs.bind(this, "postcode")} /></td>
        <td>
          <div className="field-edit">
            <div className="input-box">
              <button className="btn" disabled={isValidSubmit} type="button" onClick={this.handleAddAddress.bind(this)}><span className="fa fa-check text-success" aria-hidden="true" /></button>
              <button className="btn btn-cancel" type="button" onClick={this.toggleFormVisibility.bind(this)}><span className="fa fa-times" aria-hidden="true" /></button>
            </div>
          </div>
        </td>
      </tr>
    );
  }

  render() {
    const rows = this.props.addresses.map((addr: any, index: number) =>
      <tr key={index} className="address-row">
        <td>{addr.address1}</td>
        <td>{addr.address2}</td>
        <td>{addr.postcode}</td>
        <td><i className="fa fa-trash" aria-hidden="true" onClick={this.props.handleDeleteAddress.bind(null, index)}></i></td>
      </tr>
    );

    if (this.props.showForm)
      rows.unshift(this.newAddressRow());

    return (
      <tbody>
        {rows}
      </tbody>
    );
  }
}
