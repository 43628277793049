import * as React from "react";
import styled from "styled-components";
import { DataTableDataSubHeaderText } from "../../atoms/data-table-data-sub-header-text";

const HeaderContainer2 = styled.div`
    background-color: #dedede;
    color: #666666; 
    float: left;
    padding: 10px;
    width: 100%;
`;

interface Props {
  headings: { name: string; width: string }[];
}

export const DataTableSubHeading: React.StatelessComponent<Props> = ({
  headings,
}) => {

  return (
    <HeaderContainer2>
      {headings.map(h => {
        return <DataTableDataSubHeaderText text={h.name} width={h.width} />
      })}
    </HeaderContainer2>
  )
}
