import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { PromotionDeleteModal } from "../../components/sub/alerts/promotion-delete";
import { Promotion } from "../../api/main/promotions";
import { fetch, create, edit, deletePromotion } from "../../reducers/promotion-listing/actions";
import { RootState } from "../../reducers/index";
import styled from "styled-components";
import * as Modal from "react-modal";
import { Button } from "react-bootstrap";
import * as Select from "react-select";
import * as moment from "moment";
import {
    categoryArrayOptions,
    firstTimeArrayOptions,
    singleUseArrayOptions,
    variationTypeArrayOptions,
    SelectOption
} from "./static-data";

import Table from "../../components/table";

const modalStyling: Modal.Styles = {
    content: {
        height: "fit-content",
        width: "50%",
        top: "50%",
        left: "50%",
        transform: "translateY(-50%) translateX(-50%)",
        border: "0px",
        backgroundColor: "#efefef"
    },
    overlay: {
        zIndex: 3
    }
};

const PageContainer = styled.div`
    padding: 0 20px 20px 20px;
    float: left; 
    width: 100%; 
`;

const SearchContainer = styled.div`
    width: 100%; 
    float: left; 
`;

const PromotionListing = styled.div`
    background-color: #ffffff;
    float: left; 
    width: 100%;
`;

const Cell = styled.div`
    float: left; 
`;

const Row = styled.div`
    padding: 10px; 
    border-bottom: 1px solid #dedede;
    float: left; 
    width: 100%;
    &:hover {
      background: #efefef;
    }
`;

const ModalContainer = styled.div`
    background-color: #efefef;
`;

const ModalHeading = styled.div`
    background-color: #396174;
    color: #ffffff;
    margin: 0px;
    padding: 10px; 
    font-weight: bold;
    text-transform: uppercase;
`;

const ModalBody = styled.div`
    margin: 10px 0 0 0;
    padding: 0;
`;

const ResultsLimitedMessage = styled.div`
    padding: 10px; 
    float: left;
    color: #777777;
`;

interface ReduxProps {
    promotions: Promotion[];
    isBusy: boolean;
}

type Props = ReduxProps & { dispatch: Dispatch<{}> };
interface IState {
    isFormModalVisible: boolean;
    isEditModalVisible: boolean;
    isDeleteModalVisible: boolean;
    promotion: Promotion;

    search: string;
    singleUseSelect: SelectOption;
    variationTypeSelect: SelectOption;
    firstTimeSelect: SelectOption;
    categorySelect: SelectOption;

    searchIsEnabled: boolean;
    modalMode: "create" | "edit";
}

class Component extends React.Component<Props, IState> {
    state: IState;

    constructor(props: Props) {
        super(props);
        this.state = {
            isFormModalVisible: false,
            isEditModalVisible: false,
            isDeleteModalVisible: false,
            promotion: {
                urn: "",
                minimumSpend: 0,
                singleUse: false,
                firstTime: false,
                deduction: 0,
                deductionType: "percentage",
                validDate: null,
                code: "",
                notes: "",
                category: "",
            },
            search: "",
            singleUseSelect: null,
            variationTypeSelect: null,
            firstTimeSelect: null,
            categorySelect: null,
            modalMode: "create",
            searchIsEnabled: false,
        };
    }

    async componentDidMount() {
        await this.searchSubmit("");
    }

    // On change events
    variationTypeArrayOptionsHasChanged = (option: any): void => {
        if (option.length === 0) {
            return;
        }

        const selectedVariationType = variationTypeArrayOptions[option.value];

        this.setState(prevState => ({
            promotion: { ...prevState.promotion, deductionType: selectedVariationType.value === "0" ? "absolute" : "percentage" }
        }));
        this.setState({ variationTypeSelect: selectedVariationType });
    }
    firstTimeArrayOptionsHasChanged = (option: any): void => {
        if (option.length === 0) {
            return;
        }

        const selectedFirstTime = firstTimeArrayOptions[option.value];

        this.setState(prevState => ({
            promotion: { ...prevState.promotion, firstTime: selectedFirstTime.value === "1" }
        }));
        this.setState({ firstTimeSelect: selectedFirstTime });
    }
    categoryArrayOptionsHasChanged = (option: any): void => {
        if (option.length === 0) {
            return;
        }

        const selectedCategory = categoryArrayOptions[option.value];

        this.setState(prevState => ({
            promotion: { ...prevState.promotion, category: selectedCategory.value }
        }));
        this.setState({ categorySelect: selectedCategory });
    }
    singleUseArrayOptionsHasChanged = (option: any): void => {
        if (option.length === 0) {
            return;
        }

        const selectedSingleUse = singleUseArrayOptions[option.value];

        this.setState(prevState => ({
            promotion: { ...prevState.promotion, singleUse: selectedSingleUse.value === "1" }
        }));
        this.setState({ singleUseSelect: selectedSingleUse });
    }

    // Toggle modals
    toggleCreateModal = () => {
        this.setState({ modalMode: "create" });

        this.resetModal();
        this.setState({ isFormModalVisible: !this.state.isFormModalVisible });
    }
    toggleEditModal = () => {
        this.setState({ modalMode: "edit" });
        this.setState({ isFormModalVisible: !this.state.isFormModalVisible });
    }
    toggleEditModalAndSet = (promotion: Promotion) => {
        const mappedPromo = { ...promotion };
        mappedPromo.validUntil = mappedPromo.validUntil ?
            moment(mappedPromo.validUntil).format("YYYY-MM-DD").toString() :
            "";

        this.setState({ modalMode: "edit" });
        this.setState({ promotion: mappedPromo });

        // Set dropdowns
        this.setState({ variationTypeSelect: variationTypeArrayOptions[promotion.deductionType === "percentage" ? "1" : "0"] });
        this.setState({ singleUseSelect: singleUseArrayOptions[promotion.singleUse ? "1" : "0"] });
        this.setState({ firstTimeSelect: firstTimeArrayOptions[promotion.firstTime ? "1" : "0"] });
        this.setState({ categorySelect: categoryArrayOptions[promotion.category] });

        this.setState({ isFormModalVisible: !this.state.isFormModalVisible });
    }
    toggleDeleteModal = () => {
        this.setState({ isDeleteModalVisible: !this.state.isDeleteModalVisible });
    }
    toggleDeleteModalAndSet = (promotion: Promotion) => {
        this.setState({ promotion });
        this.toggleDeleteModal();
    }
    resetModal = () => {
        this.setState(prevState => ({
            promotion: {
                ...prevState.promotion,
                code: "",
                validUntil: null,
                deduction: null,
                deductionType: null,
                category: null,
                firstTime: null,
                singleUse: null,
                notes: "",
                minimumSpend: null
            },
        }));

        // Reset dropdowns
        this.setState({ variationTypeSelect: null });
        this.setState({ singleUseSelect: null });
        this.setState({ firstTimeSelect: null });
        this.setState({ categorySelect: null });
    }

    // Submit events
    searchSubmit = (search: string) => {
        this.props.dispatch(fetch(search));
    }
    submitPromotion = () => {

        this.setState({
            promotion: {
                ...this.state.promotion,
                validUntil: this.state.promotion.validUntil ? moment(this.state.promotion.validUntil).format() : null,
            }
        }, () => {
            if (this.state.modalMode === "create") {
                this.props.dispatch(create(this.state.promotion));
                this.toggleCreateModal();
            }
            else {
                this.props.dispatch(edit(this.state.promotion));
                this.toggleEditModal();
            }
        });
    }

    submitDeletePromotion = () => {
        this.props.dispatch(deletePromotion(this.state.promotion.code));
        this.toggleDeleteModal();

        if (this.state.isFormModalVisible) {
            this.toggleCreateModal();
        }
    }

    // Display promotions
    renderPromotions = () => {
        if (this.props.promotions.length == 0) {
            return <PromotionListing><Row><Cell>No promotions to display</Cell></Row></PromotionListing>
        }

        return this.props.promotions.map((promotion, key) => {

            // Display meaningful values for each promotion
            const validDate = promotion.validUntil ? moment(promotion.validUntil).format("YYYY-MM-DD") : "No date";
            const deductionType = promotion.deductionType == "absolute" ? `£${promotion.deduction}` : `${promotion.deduction}%`;
            const singleUse = singleUseArrayOptions[promotion.singleUse ? 1 : 0].label;
            const firstTime = firstTimeArrayOptions[promotion.firstTime ? 1 : 0].label;
            const category = promotion.category && promotion.category !== "0" ? categoryArrayOptions[promotion.category].label : "-";

            return (
                <PromotionListing key={key}>
                    <Row>
                        <Cell className="promotion-large-cell">
                            {promotion.code.toUpperCase()}
                        </Cell>
                        <Cell className="promotion-small-cell">
                            {validDate}
                        </Cell>
                        <Cell className="promotion-small-cell">
                            {promotion.deductionType}
                        </Cell>
                        <Cell className="promotion-small-cell">
                            {deductionType}
                        </Cell>
                        <Cell className="promotion-small-cell">
                            {singleUse}
                        </Cell>
                        <Cell className="promotion-small-cell">
                            {firstTime}
                        </Cell>
                        <Cell className="promotion-small-cell">
                            {promotion.minimumSpend ? `£${promotion.minimumSpend}` : "-"}
                        </Cell>
                        <Cell className="promotion-large-cell">
                            {category}
                        </Cell>
                        <Cell style={{ float: "right" }}>
                            <a href="#" onClick={() => this.toggleEditModalAndSet(promotion)} style={{ textDecoration: "underline", color: "#666666" }}>
                                Edit
                            </a>
                            &nbsp;|&nbsp;
                            <a href="#" onClick={() => this.toggleDeleteModalAndSet(promotion)} style={{ textDecoration: "underline", color: "#666666" }}>
                                Delete
                            </a>
                        </Cell>
                    </Row>
                </PromotionListing>
            )
        });
    }

    mapDataset(data: Promotion[]) {
        return data.map(entry => {
            const obj = {};
            obj["_id"] = entry.urn;
            Object.entries(entry).map(([key, value]) => {
                switch(key){
                    case "code": {
                        const component = (
                        <Cell key={key} style={{ width: "15%" }}>
                            <a style={{ color: "black", textDecoration: "none" }}>
                            {value} &nbsp;
                            </a>
                        </Cell>
                        );
                        obj[key] = component;
                        break;
                    }

                    case "validUntil": {
                        const component = (
                        <Cell key={key} style={{ width: "10%" }}>
                            <a style={{ color: "black", textDecoration: "none" }}>
                            {moment(value).format("YYYY-MM-DD")} &nbsp;
                            </a>
                        </Cell>
                        );
                        obj[key] = component;
                        break;
                    }

                    case "deduction": {
                        const component = (
                        <Cell key={key} style={{ width: "10%" }}>
                            <a style={{ color: "black", textDecoration: "none" }}>
                                {entry.deductionType == "absolute" && "£"}
                                {entry.deduction}
                                {entry.deductionType == "percentage" && "%"}
                            </a>
                        </Cell>
                        );
                        obj[key] = component;
                        break;
                    }

                    case "minimumSpend": {
                        const component = (
                        <Cell key={key} style={{ width: "10%" }}>
                            <a style={{ color: "black", textDecoration: "none" }}>
                                {value ? `£${value}` : "-"}
                            </a>
                        </Cell>
                        );
                        obj[key] = component;
                        break;
                    }

                    case "category": {
                        const component = (
                        <Cell key={key} style={{ width: "10%" }}>
                            <a style={{ color: "black", textDecoration: "none" }}>
                                {value ? categoryArrayOptions[value].label : "-"}
                            </a>
                        </Cell>
                        );
                        obj[key] = component;
                        break;
                    }

                    case "firstTime":
                    case "singleUse": {
                        const component = (
                        <Cell key={key} style={{ width: "10%" }}>
                            <a style={{ color: "black", textDecoration: "none" }}>
                                {value ? "Yes": "No"} &nbsp;
                            </a>
                        </Cell>
                        );
                        obj[key] = component;
                        break;
                    }

                    default: {
                        const component = (
                        <Cell key={key} style={{ width: "10%" }}>
                            <a style={{ color: "black", textDecoration: "none" }}>
                            {value} &nbsp;
                            </a>
                        </Cell>
                        );
                        obj[key] = component;
                        break;
                    }
                }
            });
            return obj;
        })
    }

    renderTable() {
        const dataset = this.mapDataset(this.props.promotions);

        const columns = [
            { title: "Code", key: "code", width: "15%" },
            { title: "Valid Until", key: "validUntil", width: "10%" },
            { title: "Type", key: "deductionType", width: "10%" },
            { title: "Variation", key: "deduction", width: "10%" },
            { title: "Single Use", key: "singleUse", width: "10%" },
            { title: "First time", key: "firstTime", width: "10%" },
            { title: "Minimum", key: "minimumSpend", width: "10%" },
            { title: "Category", key: "category", width: "10%" },
        ]

        const rowActions = [
            {
                name: "Edit",
                onClick: (id: string) => this.toggleEditModalAndSet(this.props.promotions.find(({urn}) => urn === id)),
            },
            {
                name: "Delete",
                onClick: (id: string) => this.toggleDeleteModalAndSet(this.props.promotions.find(({urn}) => urn === id)),
            },
        ]

        return (
            <Table
                tableName="Promotion"
                dataset={dataset}
                createEntryAction={() => this.toggleCreateModal()}
                columns={columns}
                rowActions={rowActions}
                emptyMessage={"No Promotions to show"}
                isLoading={this.props.isBusy}
            />
        );
    }

    handleKeyDown(e: any) {
        if (e.key == "Enter") {
            this.searchSubmit(this.state.search);
        }
    }

    showLimitMessage = () => {
        if (this.props.promotions.length == 100) {
            return <ResultsLimitedMessage>
                Limited to showing first 100 records
            </ResultsLimitedMessage>
        }
    }
    render() {

        return (
            <>
                <div className="job-creation-row">
                    <PageContainer>
                        <SearchContainer>
                            <div className="detail-job-heading">
                                Enter promo code to search
                            </div>
                            <div className="detail-job-value">

                                <input
                                    type="text"
                                    value={this.state.search}
                                    onChange={(e) => this.setState({ search: e.target.value })}
                                    onKeyPress={(e: any) => this.handleKeyDown(e)}
                                    style={{
                                        width: "200px",
                                        marginRight: "10px"
                                    }}
                                />
                                <Button onClick={() => this.searchSubmit(this.state.search)}
                                    className="btn btn-primary"
                                    style={{ backgroundColor: "#586b72" }}>Search</Button>
                            </div>
                        </SearchContainer>
                    </PageContainer>
                </div>

                <div className="job-creation-row">
                    <PageContainer>
                        {this.renderTable()}
                        {this.showLimitMessage()}
                    </PageContainer>
                </div>

                <Modal
                    isOpen={this.state.isFormModalVisible}
                    ariaHideApp={false}
                    style={modalStyling}>

                    <ModalContainer>

                        <ModalHeading>
                            Create &amp; edit promotion
                        </ModalHeading>

                        <ModalBody>
                            <form>
                                <div style={{ padding: "0 20px 0 20px" }}>
                                    <div className="main-promotion-details-row" style={{ margin: "0px" }}>
                                        <div className="detail-job-heading">
                                            Code
                                    </div>
                                        <div className="detail-job-value">
                                            <input
                                                type="text"
                                                disabled={this.state.modalMode === "edit"}
                                                value={this.state.promotion.code.toUpperCase() || ""}
                                                onChange={(e) => this.setState({ promotion: { ...this.state.promotion, code: e.target.value } })}
                                            />
                                        </div>
                                    </div>
                                    <div className="main-promotion-details-row float-right" style={{ margin: "0px" }}>
                                        <div className="detail-job-heading">
                                            Valid until
                                            <span style={{ paddingLeft: "5px" }}>
                                                <a href="#" onClick={() => this.setState({
                                                    promotion: {
                                                        ...this.state.promotion,
                                                        validUntil: moment().add(1, "year").format("YYYY-MM-DD").toString()
                                                    },
                                                })} style={{ color: "#000000", textDecoration: "underline" }}>Today plus 1 year</a>
                                            </span>
                                        </div>
                                        <div className="detail-job-value">
                                            <input
                                                type="text"
                                                placeholder={"YYYY-MM-DD"}
                                                value={
                                                    this.state.promotion.validUntil || ""
                                                }
                                                onChange={(e) => this.setState({
                                                    promotion: {
                                                        ...this.state.promotion,
                                                        validUntil: e.target.value,
                                                    },
                                                })
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="clear-both"></div>

                                    <div className="main-promotion-details-row">
                                        <div className="detail-job-heading">
                                            Variation type
                                            <span style={{ paddingLeft: "5px" }}>
                                                <a href="#" onClick={() => this.variationTypeArrayOptionsHasChanged({ value: "0", label: "absolute" })} style={{ color: "#000000", textDecoration: "underline" }}>absolute</a> | <a href="#" onClick={() => this.variationTypeArrayOptionsHasChanged({ value: "1", label: "percentage" })} style={{ color: "#000000", textDecoration: "underline" }}>Percentage</a>
                                            </span>
                                        </div>
                                        <div className="detail-job-value">
                                            <Select
                                                name={"variation-type"}
                                                value={this.state.variationTypeSelect}
                                                clearable={false}
                                                options={variationTypeArrayOptions}
                                                onChange={this.variationTypeArrayOptionsHasChanged}
                                                style={{ textAlign: "left" }}
                                            />
                                        </div>
                                    </div>

                                    <div className="main-promotion-details-row float-right">
                                        <div className="detail-job-heading">
                                            Variation
                                        </div>
                                        <div className="detail-job-value">
                                            <input
                                                type="text"
                                                value={this.state.promotion.deduction || ""}
                                                onChange={(e) => this.setState({
                                                    promotion: {
                                                        ...this.state.promotion, deduction: e.target.value === "" ? 0 : e.target.value
                                                    }
                                                })}
                                            />
                                        </div>
                                    </div>

                                    <div className="clear-both"></div>

                                    <div className="main-promotion-details-row">
                                        <div className="detail-job-heading">
                                            Single use
                                            <span style={{ paddingLeft: "5px" }}>
                                                <a href="#" onClick={() => this.singleUseArrayOptionsHasChanged({ value: "1", label: "Yes" })} style={{ color: "#000000", textDecoration: "underline" }}>Yes</a> | <a href="#" onClick={() => this.singleUseArrayOptionsHasChanged({ value: "0", label: "No" })} style={{ color: "#000000", textDecoration: "underline" }}>No</a>
                                            </span>
                                        </div>
                                        <div className="detail-job-value">
                                            <Select
                                                name={"single-use"}
                                                value={this.state.singleUseSelect}
                                                clearable={false}
                                                options={singleUseArrayOptions}
                                                onChange={this.singleUseArrayOptionsHasChanged}
                                                style={{ textAlign: "left" }}
                                            />
                                        </div>
                                    </div>

                                    <div className="main-promotion-details-row float-right">
                                        <div className="detail-job-heading">
                                            First time
                                            <span style={{ paddingLeft: "5px" }}>
                                                <a href="#" onClick={() => this.firstTimeArrayOptionsHasChanged({ value: "1", label: "Yes" })} style={{ color: "#000000", textDecoration: "underline" }}>Yes</a> | <a href="#" onClick={() => this.firstTimeArrayOptionsHasChanged({ value: "0", label: "No" })} style={{ color: "#000000", textDecoration: "underline" }}>No</a>
                                            </span>
                                        </div>
                                        <div className="detail-job-value">
                                            <Select
                                                name={"first-time"}
                                                value={this.state.firstTimeSelect}
                                                clearable={false}
                                                options={firstTimeArrayOptions}
                                                onChange={this.firstTimeArrayOptionsHasChanged}
                                                style={{ textAlign: "left" }}
                                            />
                                        </div>
                                    </div>

                                    <div className="clear-both"></div>

                                    <div className="main-promotion-details-row">
                                        <div className="detail-job-heading">
                                            Minimum spend
                                            <span style={{ paddingLeft: "5px" }}>
                                                <a href="#" onClick={() => this.setState({ promotion: { ...this.state.promotion, minimumSpend: 65.00 } })} style={{ color: "#000000", textDecoration: "underline" }}>£65</a>
                                                &nbsp;|&nbsp;<a href="#" onClick={() => this.setState({ promotion: { ...this.state.promotion, minimumSpend: 75.00 } })} style={{ color: "#000000", textDecoration: "underline" }}>£75</a>
                                                &nbsp;|&nbsp;<a href="#" onClick={() => this.setState({ promotion: { ...this.state.promotion, minimumSpend: 85.00 } })} style={{ color: "#000000", textDecoration: "underline" }}>£85</a>
                                            </span>
                                        </div>
                                        <div className="detail-job-value">
                                            <input
                                                type="text"
                                                value={this.state.promotion.minimumSpend || ""}
                                                onChange={(e) => this.setState({
                                                    promotion:
                                                    {
                                                        ...this.state.promotion,
                                                        minimumSpend: e.target.value === "" ? 0 : e.target.value
                                                    }
                                                })}
                                            />
                                        </div>
                                    </div>

                                    <div className="main-promotion-details-row float-right">
                                        <div className="detail-job-heading">
                                            Category
                                    </div>
                                        <div className="detail-job-value">
                                            <Select
                                                name={"category"}
                                                value={this.state.categorySelect}
                                                clearable={false}
                                                options={categoryArrayOptions}
                                                onChange={this.categoryArrayOptionsHasChanged}
                                                style={{ textAlign: "left" }}
                                            />
                                        </div>
                                    </div>

                                    <div className="clear-both"></div>

                                    <div className="main-promotion-details-row" style={{ width: "100%" }}>
                                        <div className="detail-job-heading">
                                            Notes
                                    </div>
                                        <div className="detail-job-value">
                                            <textarea
                                                rows={4}
                                                className="form-control"
                                                placeholder="Enter notes"
                                                onChange={(e) => this.setState({ promotion: { ...this.state.promotion, notes: e.target.value } })}
                                                value={this.state.promotion.notes || ""} />
                                        </div>
                                    </div>

                                    <div className="clear-both"></div>

                                </div>

                                <div className="main-promotion-details-row" style={{ textAlign: "left", backgroundColor: "#dedede", padding: "20px", width: "100%" }}>
                                    <Button onClick={() => this.submitPromotion()} className="btn-create-job" style={{ backgroundColor: "#5CB85B", border: "0px" }}>Save</Button>
                                    <Button onClick={() => this.toggleCreateModal()} className="btn-cancel-job" style={{ backgroundColor: "#337AB7", color: "#ffffff", border: "0px" }}>Cancel</Button>
                                    <Button disabled={this.state.modalMode === "create"} onClick={() => this.toggleDeleteModalAndSet(this.state.promotion)} className="btn-cancel-job" style={{ backgroundColor: "rgb(204, 0, 0)", color: "#ffffff", border: "0px" }}>Delete</Button>
                                </div>

                            </form>
                        </ModalBody>

                    </ModalContainer>

                </Modal>

                <PromotionDeleteModal
                    isOpen={this.state.isDeleteModalVisible}
                    title="Promotion deletion"
                    message={`Are you sure you want to delete ${this.state.promotion.code.toUpperCase()}?`}
                    closeModal={this.toggleDeleteModal}
                    handleConfirm={this.submitDeletePromotion} />
            </>
        );
    }
}

const mapStateToProps = (state: RootState): ReduxProps => {
    return {
        promotions: state.promotions.promotions,
        isBusy: state.promotions.isBusy,
    }
};

export const PromotionContainer = connect<ReduxProps, {}, {}>(mapStateToProps)(Component);