import * as React from "react";
import styled from "styled-components";

import { IconButton } from "../icon-button";

const Button = styled(IconButton)`
  background-color: #a10404;
`;

interface Props {
  onClick(): void;
  small?: boolean;
}

interface State {
  showConfirmation: boolean;
}

export class DeleteButton extends React.Component<Props, State> {
  state: State = {
    showConfirmation: false,
  };

  handleClick = () => {
    if (this.state.showConfirmation) {
      this.props.onClick();
      this.setState({showConfirmation: false});
    }
    else {
      this.setState({showConfirmation: true});
    }
  }

  render(): JSX.Element {
    return <Button
      icon="fa-trash"
      onClick={this.handleClick}
      small={this.props.small}
      label={this.state.showConfirmation ? "Are you sure?" : ""}
    />;
  }
}
