import { config } from "../../config";
import * as api from "./request";

interface Tag {
  tag: string;
}

/**
 * Get all tags or specific tag
 */
export async function getTag(tag?: string): Promise<Tag[]> {
  let url = `${config.adminPanelApiUrl}/tag`;

  if (tag) {
    url += `?tag=${tag}`;
  }

  const response = await api.get<Tag[]>(url);

  return response.data;
}

export async function addTag(tag: string): Promise<void> {
  let url = `${config.adminPanelApiUrl}/tag`;

  await api.post<Tag>(url, { tag });
}
