import * as React from "react";
import * as moment from "moment";
import DatePicker from "react-datepicker";

interface Props {
  timeStarts: moment.Moment;
  setBookingDate: (time: moment.Moment) => void;
}

export class BookingDate extends React.Component<Props, null> {
  editSaveHandler = (date: moment.Moment) => {
    this.props.setBookingDate(date.seconds(0).milliseconds(0));
  };

  renderTimes() {
    let times = [];
    let base = moment().startOf("day");
    const intervals = 15;
    const multiplier = 1440 / intervals;
    for (let i = 0; i < multiplier; i++) {
      times.push(base.clone().add(i * intervals, "minutes"));
    }

    return times.map((time, i) =>
      <option key={i} value={time.toString()}>
        {time.format("hh:mm A")}
      </option>
    );
  }

  handleDateChange = (date: moment.Moment) => {
    const newDate = this.props.timeStarts.clone().set({
      year: date.get("year"),
      month: date.get("month"),
      date: date.get("date")
    });

    this.editSaveHandler(newDate);
  }

  handleTimeChange(event: any) {
    const date = moment(event.target.value);
    const newDate = this.props.timeStarts.clone().set({
      hours: date.get("hours"),
      minutes: date.get("minutes"),
      seconds: 0,
    });

    this.editSaveHandler(newDate);
  }

  render() {
    return (
      <div className="booking-date">
        <div className="detail-heading">
          Select Start Date
        </div>
        <div className="detail-value">
          <div className="field-edit">
            <div className="input-box">
              <DatePicker
                dateFormat={"DD/MM/YYYY"}
                selected={this.props.timeStarts}
                onChange={this.handleDateChange} />
              <div className="react-datepicker__time-box">
                <select className="form-control" onChange={this.handleTimeChange.bind(this)}>
                  {this.renderTimes()}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
