import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as moment from "moment";
import * as Select from "react-select";
import styled from "styled-components";
import _ from "lodash";

import { RootState } from "../reducers";
import {
  fetchBookings,
  searchWithParameters,
  resetSearch,
  SearchParams,
} from "../reducers/bookings/actions";
import BookingsTable from "../components/bookings/bookings-table";
import { DateRangePicker } from "../components/bookings/date-range-picker";
import { SearchTypePicker } from "../components/bookings/search-field-picker";
import { ApiBooking } from "../api/main/booking";
import { RouteComponentProps, withRouter } from "react-router";
import { DebouncedSearchInput } from "../components/molecules/debounced-search-input";
import { Well, WellRow } from "../components/atoms/well";
import { StatusSelect } from "../components/molecules/status-select";
import { Button } from "react-bootstrap";
import { PlusButton } from "../components/atoms/plus-button";
import { PaymentStatusSelect } from "../components/molecules/payment-status-select";
import { PartnerFilterSelect } from "../components/molecules/partner-filter-select";
import { ApiPartner } from "../api/main/partners";
import { fetchAllPartners } from "../reducers/partners/actions";
import { SpinnerLoader } from "../components/molecules/spinner";
import { Operator } from "../presenters/operator";
import { TherapistTier } from "../presenters/therapist";

const Summary = styled.div`
  float: left;
  background-color: #ffffff;
  padding: 10px;
`;

const Strong = styled.span`
  font-weight: bold;
`;

interface ReduxProps {
  isBusy: boolean;
  searchParams: SearchParams;
  sortedBookings: any[];
  bookings: {
    [index: string]: ApiBooking;
  };
  isPartner: boolean;
  partners: ApiPartner[];
  operator: Operator;
}

type OwnProps = RouteComponentProps<{}>;

type Props = ReduxProps & OwnProps & { dispatch: Dispatch<{}> };

class BookingsContainerComponent extends React.Component<Props, {}> {
  searchBox: DebouncedSearchInput;

  componentDidMount() {
    // workaround to prevent call if comming from another page with query filter
    if (!this.props.searchParams.query) {
      this.props.dispatch(fetchBookings());
    }

    if (this.props.operator.permissions.all) {
      this.props.dispatch(fetchAllPartners());
    }
  }

  handleStartDateChange = (date: moment.Moment) => {
    this.props.dispatch(searchWithParameters({ start: date.valueOf() }));
  };

  handleEndDateChange = (date: moment.Moment) => {
    this.props.dispatch(searchWithParameters({ end: date.valueOf() }));
  };

  handleSearchFieldChange = (overlaps: string) => {
    this.props.dispatch(searchWithParameters({ dateType: overlaps }));
  };

  handleStatusChange = (status: string) => {
    this.props.dispatch(searchWithParameters({ status }));
  };

  handlePaymentStatusChange = (status: string) => {
    this.props.dispatch(searchWithParameters({ payment: status }));
  };

  handleQueryChange = (query: string) => {
    this.props.dispatch(searchWithParameters({ query }));
  };

  handleClickNewBooking = () => {
    this.props.history.push("/bookings/create");
  };

  handlePartnersSelect = (options: Select.Option[]) => {
    if (!options.length) {
      this.props.dispatch(searchWithParameters({ partners: null, query: "" }));

      return;
    }

    const originalSalonUrns = options.map((option: Select.Option) => {
      const urn = (option.value as string); // .replace("therapist", "salon");
      
      return urn.replace("therapist", "salon");
    });
    const query = `partner-salon-urns:${originalSalonUrns.join(",")}`;

    const therapistUrns = options.map((option: Select.Option) => String(option.value));

    this.props.dispatch(searchWithParameters({ partners: therapistUrns, query }));
  };

  /**
   * Handle action of resetting search
   */
  handleResetSearch = (): void => {
    this.searchBox.clear();
    this.props.dispatch(resetSearch());
  };

  render(): JSX.Element {
    const spinner = this.props.isBusy ? <SpinnerLoader /> : null;

    // Calculate booking summaries
    const hydratedBookings = this.props.sortedBookings.map((id: string) => {
      return this.props.bookings[id];
    });
    const classicBookings = hydratedBookings.filter(
      (x) => x.tier === TherapistTier.CLASSIC,
    );
    const eliteBookings = hydratedBookings.filter(
      (x) => x.tier === TherapistTier.ELITE,
    );
    const cancelledBookings = hydratedBookings.filter(
      (x) => x.status === "CANCELLED",
    );

    return (
      <>
        <Well>
          <WellRow>
            <SearchTypePicker
              onSearchTypeChange={this.handleSearchFieldChange}
              searchField={this.props.searchParams.dateType}
            />
            <DateRangePicker
              startDate={moment(this.props.searchParams.start)}
              endDate={moment(this.props.searchParams.end)}
              onStartDateChange={this.handleStartDateChange}
              onEndDateChange={this.handleEndDateChange}
            />

            <StatusSelect
              onChange={this.handleStatusChange}
              selected={this.props.searchParams.status}
            />

            <DebouncedSearchInput
              onChange={this.handleQueryChange}
              value={this.props.searchParams.query}
              ref={(ref) => (this.searchBox = ref)}
            />

            <PlusButton onClick={this.handleClickNewBooking} />
          </WellRow>
          <WellRow justifyContent="space-between">
            <Button
              bsStyle="primary"
              bsSize="xsmall"
              onClick={this.handleResetSearch}
            >
              Reset Search
            </Button>
            <WellRow>
              <PaymentStatusSelect
                onChange={this.handlePaymentStatusChange}
                selected={this.props.searchParams.payment}
              />
              {this.props.operator.permissions.all && (
                <PartnerFilterSelect
                  onChange={this.handlePartnersSelect}
                  selected={this.props.searchParams.partners}
                  partners={this.props.partners}
                />
              )}
            </WellRow>
          </WellRow>
        </Well>

        <Summary>
          Bookings shown: <Strong>{this.props.sortedBookings.length}</Strong>,
          Revenue:{" "}
          <Strong>
            {sprintf("£%0.2f", _.sumBy(hydratedBookings, "totalPrice"))}
          </Strong>
          {!this.props.isPartner ? (
            <>
              , classic:{" "}
              <Strong>
                {sprintf("£%0.2f", _.sumBy(classicBookings, "totalPrice"))}
              </Strong>{" "}
              ({classicBookings.length}), elite:{" "}
              <Strong>
                {sprintf("£%0.2f", _.sumBy(eliteBookings, "totalPrice"))}
              </Strong>{" "}
              ({eliteBookings.length}) cancelled:{" "}
              <Strong>
                {sprintf("£%0.2f", _.sumBy(cancelledBookings, "totalPrice"))}
              </Strong>{" "}
              ({cancelledBookings.length})
            </>
          ) : (
            ""
          )}
        </Summary>

        {spinner}

        <div style={{ clear: "both" }}></div>

        <BookingsTable
          bookings={this.props.sortedBookings.map(
            (id: string) => this.props.bookings[id],
          )}
          showAll
          partner={this.props.operator.partner}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): ReduxProps => {
  return {
    bookings: state.bookings.bookings,
    searchParams: state.bookings.searchParams,
    isBusy: state.bookings.isBusy,
    sortedBookings: state.bookings.sortedBookings,
    isPartner: state.operator.isPartner,
    partners: state.partners.partners,
    operator: state.operator,
  };
};

export const BookingsContainer = withRouter(
  connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
    BookingsContainerComponent,
  ),
);
