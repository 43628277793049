import { ActionType, getType } from "typesafe-actions";
import { actions } from "../actions";
import { ApiJob } from "../../../api/main/job";
import * as iassign from "immutable-assign";

type JobAction = ActionType<typeof actions>;

export interface JobState {
  jobs: ApiJob[],
  isLoading: boolean
}

const INITIAL_STATE: JobState = {
  jobs: [],
  isLoading: false
};

export function jobReducer(state: JobState = INITIAL_STATE, action: JobAction): JobState {
  switch (action.type) {
    case getType(actions.requestGetAllJobsInit): {
      return iassign(
        state,
        s => {
          s.isLoading = true;
          return s;
        }
      );
    }
    case getType(actions.requestGetAllJobsSuccess): {
      return iassign(
        state,
        s => {
          s.jobs = action.payload.jobs;
          s.isLoading = false;
          return s;
        }
      );
    }
    case getType(actions.requestCreateJobInit): {
      return state;
    }
    case getType(actions.requestCreateJobSuccess): {
      return {
        ...state,
        jobs: [...state.jobs, action.payload]
      };
    }
    case getType(actions.requestEditJobSuccess): {
      return state;
    }
    case getType(actions.requestDeleteJobInit): {
      return state;
    }
    case getType(actions.requestDeleteJobSuccess): {
      return iassign(
        state,
        s => {
          s.jobs = state.jobs.filter(x => x.id != action.payload.id);
          return s;
        }
      );
    }
    default: return state;
  }
}