import * as React from "react";
import styled from "styled-components";

import { IconButton } from "../icon-button";

const Button = styled(IconButton)`
  background-color: #cccccc;
`;

export const SpinnerButton: React.StatelessComponent<{}> = () => (
  <Button icon="fa-spinner fa-spin" />
);
