import * as React from "react";
import styled from "styled-components";

const Button = styled.button`
  border-radius: 5px;
  margin: 0 3px;
  color: white;
  text-align: center;
  vertical-align: middle;
  min-height: ${(props: {small: boolean}) => props.small ? "25px" : "35px" };
  min-width: ${(props: {small: boolean}) => props.small ? "25px" : "35px" };
  border: none;
  white-space: nowrap;
`;

interface Props {
  onClick?(): void;
  className?: string;
  icon: string;
  small?: boolean;
  label?: string;
}

export const IconButton: React.StatelessComponent<Props> = props => {
  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (typeof props.onClick !== "undefined") {
      props.onClick();
    }
  };

  const label = Boolean(props.label) ? ` ${props.label}` : undefined;

  return <Button className={props.className} onClick={onClick} small={props.small}><span className={`fa ${props.icon}`}></span>{label}</Button>;
};
