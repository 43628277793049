import * as React from "react";
import * as moment from "moment";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ApiCustomer } from "../../../api/main/customer";
import Table from "../../../components/table";

const Cell = styled.div`
  float: left;
`;

interface ReferralsProps {
    customer: ApiCustomer;
    getReferralBookings(): void;
}

export default class Referrals extends React.Component<ReferralsProps, {}> {
    componentWillMount() {
        this.props.getReferralBookings();
    }

    mapDataset = (data: ApiCustomer) => {
        const bookings = data.referralBookings;

        if (typeof bookings === "undefined") {
            return [];
        }

        return bookings.map((booking) => {
            const obj = {};
            Object.entries(booking).map(([key, value]) => {
                switch (key) {
                    case "urn": {
                        const component = (
                            <Cell key={key} style={{ width: "60%" }}>
                                <Link to={`/bookings/urn:ruuby:booking:${value}`} style={{ color: "rgb(102, 102, 102)", textDecoration: "underline" }}>
                                    {`urn:ruuby:booking:${value}`}
                                </Link>
                            </Cell>
                        );

                        obj[key] = component;
                        break;
                    }

                    case "customerUrn": {
                        const component = (
                            <Cell key={key} style={{ width: "20%" }}>
                                <Link
                                    to={`/customers/${value}/general`}
                                    onClick={() => window.location.href = `/customers/${value}/general`}
                                    style={{ color: "rgb(102, 102, 102)", textDecoration: "underline" }}
                                >
                                    {value}
                                </Link>
                            </Cell>
                        );

                        obj[key] = component;
                        break;
                    }

                    case "date": {
                        const component = (
                            <Cell key={key} style={{ width: "20%" }}>
                                {moment(value).format("DD/MM/YYYY HH:mm")}
                            </Cell>
                        );

                        obj[key] = component;
                        break;
                    }
                }
            });

            return obj;
        });
    };

    render(): JSX.Element {
        return (
            <div className="app-table main-details-lg">
                <div className="details-row">
                    <Table
                        tableName={"Referral Bookings"}
                        columns={[
                            { title: "Booking urn", key: "urn", width: "60%" },
                            { title: "Customer urn", key: "customerUrn", width: "20%" },
                            { title: "Date", key: "date", width: "20%" },
                        ]}
                        dataset={this.mapDataset(this.props.customer)}
                        emptyMessage="No referral bookings to show" rowActions={undefined} />
                </div>
            </div>
        );
    }
}