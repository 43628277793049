import { fetchPostcodesAvailableForBookings } from "../../api/main/postcodes";
import { ActionDispatch } from "../../reducers";
import { begin, success, failure } from "redux-api-status/actions";
import { actionCreators } from "../../reducers/postcodes/actions";

const fetchAvailablePostcodesRef = "POSTCODES/FETCH/AVAILABLE";

/**
 * Model for postcodes. Currently only supports fetching the list of available postcode districts
 * and tracking the status of the fetch using redux-api-status
 */
export const postcodesModel = {
  fetchAvailablePostcodesRef,
  fetchAvailablePostcodeDistricts: (): ActionDispatch => {
    return async dispatch => {
      dispatch(begin(fetchAvailablePostcodesRef));

      try {
        const response = await fetchPostcodesAvailableForBookings();
        dispatch(actionCreators.storeAvailablePostcodes({ available: response }));
        dispatch(success(fetchAvailablePostcodesRef, response));
      }
      catch (error) {
        dispatch(failure(fetchAvailablePostcodesRef, { error }));
      }
    };
  },
};
