import * as React from "react";
import { Label } from "react-bootstrap";
import * as moment from "moment";
import { sprintf } from "sprintf-js";
import { RouteComponentProps, withRouter } from "react-router";

import { ApiBooking, ApiBookingTreatment } from "../../api/main/booking";
import * as constants from "../../constants";
import * as utils from "../../utils";
import { Badge } from "../atoms/customer-badge";
import { TherapistTier } from '../../presenters/therapist';

interface BookingsRowProps {
  booking: ApiBooking;
  showAll?: boolean;
  partner: any;
}

class BookingsRowComponent extends React.Component<BookingsRowProps & RouteComponentProps<{}>> {
  getOutstanding(booking: ApiBooking): number {
    const transactionsValue = booking.transactions
      .filter(transaction => {
        return (transaction.type !== constants.TRANSACTION_ROD_ADJUSTMENT) &&
          (transaction.type !== constants.TRANSACTION_TYPE_ROD_DEDUCTION) &&
          (transaction.type !== constants.TRANSACTION_BANK_FEES) &&
          (transaction.type !== constants.TRANSACTION_RUUBY_TAKINGS) &&
          (transaction.type !== constants.TRANSACTION_THERAPIST_TAKINGS) &&
          (transaction.type !== constants.TRANSACTION_EXTERNAL_COMMISSION) &&
          (transaction.type !== constants.TRANSACTION_ADMIN_FEE);
      })
      .reduce((amount: number, t) => amount + t.amount, 0);

    const treatmentsTotal = booking.bookingTreatments
      .reduce((prev: number, t) => t.price ? t.price + prev : t.treatment.price + prev, 0);

    return treatmentsTotal - transactionsValue;
  }

  needsAddressing = (booking: ApiBooking): boolean => {
    const { status } = booking;
    const parity = moment.duration(moment(booking.timeStarts).diff(moment()));

    if ((parity.asMinutes() < 30 && parity.asMinutes() >= 0) && (status === "CONFIRMED" || status === "UNCONFIRMED"))
      return true;

    if (parity.asHours() < 2 && parity.asHours() >= 0 && (status === "UNCONFIRMED"))
      return true;

    if (parity.asMinutes() < -10 && (status === "CONFIRMED" || status === "UNCONFIRMED" || status === "THERAPIST_ON_WAY"))
      return true;
  }

  renderTreatments(bookingTreatments: ApiBookingTreatment[]): JSX.Element[] {
    return bookingTreatments
      .filter(bookingTreatment => bookingTreatment.treatment)
      .map(bookingTreatment => <div key={bookingTreatment.urn}>{bookingTreatment.treatment.name}</div>);
  }

  render() {
    const { booking } = this.props;
    const timeStarts = moment(booking.timeStarts);
    const timeCreated = moment(booking.timeCreated);
    const onClickHandler = (evt: React.MouseEvent<HTMLTableRowElement>, booking: ApiBooking) => {
      if (evt.altKey)
        window.open(`/bookings/${booking.urn}`);
      else
        this.props.history.push(`/bookings/${booking.urn}`);
    };

    const outstanding = this.getOutstanding(booking);
    const paid = outstanding <= 0;

    const needsAddressing = this.needsAddressing(booking);
    const unpaid = ((booking.status !== "CANCELLED") && !paid);

    const salonName = this.props.partner ? "TBC" : booking.salon.name;
    const tier = booking.tier === TherapistTier.BLACK_LABEL ? "CELEBRITY" : booking.tier;

    return (
      <tr onClick={evt => onClickHandler(evt as any, booking)} className={`table-row ${(unpaid || needsAddressing) ? "unpaid" : booking.status}`} style={unpaid ? { color: "red" } : {}}>
        <td>{timeCreated.format("DD MMM YY")} at {timeCreated.format("HH:mm")}</td>
        <td>{timeStarts.format("DD MMM YY")} at {timeStarts.format("HH:mm")}</td>
        <td>{salonName}</td>
        {this.props.showAll && <td>
          {booking.customer.firstName + " " + booking.customer.lastName}
          {booking.customer.badges.map((type) => <Badge small type={type}/>)}
        </td>}
        <td>{this.renderTreatments(booking.bookingTreatments)}</td>
        <td>{sprintf("£%0.2f", booking.totalPrice)}</td>
        {this.props.showAll && <td>{sprintf("£%0.2f", booking.net)}</td>}
        <td>{tier}</td>
        {this.props.showAll && <td>{(booking.salon.isMobile) ? booking.address.postcode : ""}</td>}
        <td className="labels">
          <Label className={`status-${booking.status.toLowerCase()}`}>{utils.mapStatus(booking.status)}</Label>
          {this.props.showAll && paid && <i className="fa fa-check"></i>}
          {this.props.showAll && (booking.notesRequiringAction > 0) && <i className="fa fa-flag"></i>}
        </td>
      </tr>
    );
  }
}

export const BookingsRow = withRouter(BookingsRowComponent);
