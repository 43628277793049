import * as React from "react";
import * as Select from "react-select";
import { SelectOption } from "../../components/booking/promotions";
import { ApiAddress, ApiBooking } from "../../api/main/booking";

interface Props {
  booking: ApiBooking;
  addresses: ApiAddress[];
  onUpdateAddress: Function;
}
interface State {
  editing?: boolean;
  address?: any; // object of value: 'address urn', label: 'address txt'
}

export class AddressEditField extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let option = {};
    const addresses = props.addresses;
    if (addresses && addresses.length > 0) {
      const activeAddress = addresses.find(a => a.urn === props.booking.address.urn);

      option = {
        label: `${activeAddress.address1}, ${activeAddress.address2}, ${activeAddress.postcode}`,
        value: activeAddress.urn
      };
    }

    this.state = {
      editing: false,
      address: option
    };
  }

  clickHandler = (): void => {
    this.setState({editing: true});
  }

  editCancelHandler = (): void => {
    this.setState({editing: false});
  }

  editSaveHandler = (): void => {
    this.props.onUpdateAddress(this.state.address.value);
    this.editCancelHandler();
  }

  addressChanged = (option: any): void => {
    this.setState({address: option});
  }

  render(): JSX.Element {
    const { booking, addresses } = this.props;

    let text;
    if (addresses) {
      const activeAddress = addresses.find((address: ApiAddress) => booking.address.urn === address.urn);
      if (activeAddress) {
        text = `${activeAddress.address1}, ${activeAddress.address2}, ${activeAddress.postcode}`;
      }
      else {
        text = booking.address.urn;
      }
    }

    let options: SelectOption[] = [];
    if (addresses) {
      options = addresses.reduce((opt: SelectOption[], address: ApiAddress) => {
        if (!address.isDeleted) {
          const txt = `${address.address1}, ${address.address2}, ${address.postcode}`;
          opt.push({
            value: address.urn,
            label: txt
          });
        }

        return opt;
      }, []);
    }

    return (this.state.editing) ?
      <div className="main-details-row">
        <div className="booking-address">
          <div className="detail-heading">
            Select Customer Address
          </div>
          <div className="field-edit">
            <div className="select-box">
              <Select
                value={this.state.address}
                clearable={false}
                options={options}
                onChange={this.addressChanged} />
            </div>
            <button className="btn btn-submit" type="button" onClick={this.editSaveHandler}>
            <span className="fa fa-check text-success" aria-hidden="true" />
            </button>
            <button className="btn btn-cancel" type="submit" onClick={this.editCancelHandler}>
              <span className="fa fa-times" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    : (
      <div className="main-details-row">
        <div className="booking-address">
          <div className="detail-heading">
            Customer Address
          </div>
          <div className="field-edit">
            {text + " "}
            {options && options.length > 0 && <span onClick={this.clickHandler} className="edit-icon edit-icon-hover fa fa-pencil"></span>}
          </div>
        </div>
      </div>
    );
  }
}
