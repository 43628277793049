import * as React  from "react";
import { SpinnerButton } from "../../atoms/spinner-button";
import { TickButton } from "../../atoms/tick-button";
import { CrossButton } from "../../atoms/cross-button";

interface Props {
  isUpdating: boolean;
  onSubmit(): void;
  onCancel(): void;
}

export const EditableFieldButtons: React.StatelessComponent<Props> = props => {
  if (props.isUpdating) {
    return <SpinnerButton />;
  }
  else {
    return (
      <>
        <TickButton onClick={props.onSubmit} />
        <CrossButton onClick={props.onCancel} />
      </>
    );
  }
};
