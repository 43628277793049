import * as React from "react";
import styled from "styled-components";

const Input = styled.input`
  width: 100%;
  height: 35px;
  border: 1px solid #cdcdcd;
  padding: 10px;
`;

interface Props {
  className?: string;
  value?: string;
  placeholder?: string;
  autoFocus?: boolean;
  onChange(value: string): void;
}

export const InputText: React.StatelessComponent<Props> = props => (
  <Input
    className={props.className}
    value={props.value}
    onChange={e => props.onChange(e.target.value)}
    placeholder={props.placeholder}
    autoFocus={props.autoFocus}
  />
);