import * as React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

import Customer from "../components/customers/customer";
import * as Spinner from "react-spinkit";
import {
  fetchCustomer,
  updateCustomer,
  fetchAddresses,
  updateCustomerAddress,
  deleteCustomerAddress,
  addCustomerAddress,
  deleteCustomerPaymentMethod,
  CustomerUpdateValue,
  CustomerUpdateField,
  getReferralBookings,
} from "../reducers/customers/actions";
import {
  fetchBraintreeToken,
  actions
} from "../reducers/transactions/actions";
import { ApiCustomer } from "../api/main/customer";
import { RootState } from "../reducers";
import { ApiBooking } from "../api/main/booking";
import { resetSearch, searchCustomer } from "../reducers/bookings/actions";
import * as notificationActions from "../reducers/notifications/actions";

interface ReduxProps {
  bookings: ApiBooking[];
  customer: ApiCustomer;
  isBusy: boolean;
  page: string;
  braintreeToken: string;
  transactionBusy: boolean;
  isModalOpen: boolean;
  transactionError: string;
  busy: {
    card: boolean;
  };
  isPartner: boolean;
}

type OwnProps = RouteComponentProps<{ urn: string, page: string }>;
type Props = ReduxProps & OwnProps & { dispatch: Dispatch<{}> };

class Component extends React.Component<Props, {}> {
  componentWillMount() {
    if (!this.props.customer || !this.props.customer.cards)
      this.props.dispatch(fetchCustomer(this.props.match.params.urn));
  }

  handleAddAddress = (address: any) => {
    this.props.dispatch(addCustomerAddress(this.props.customer.urn, address));
  }

  handleClickBookings = (): void => {
    this.props.dispatch(resetSearch());
    this.props.dispatch(searchCustomer(this.props.customer.urn));
  }

  render() {
    const customerUrn = this.props.match.params.urn;

    return this.props.customer ? (
      <Customer
        isPartner={this.props.isPartner}
        customer={this.props.customer}
        bookings={this.props.bookings}
        isBusy={this.props.isBusy}
        page={this.props.page}
        braintreeToken={this.props.braintreeToken}
        isModalOpen={this.props.isModalOpen}
        transactionBusy={this.props.transactionBusy}
        updateCustomer={(key: CustomerUpdateField, value: CustomerUpdateValue) => this.props.dispatch(updateCustomer(customerUrn, key, value))}
        fetchAddresses={() => this.props.dispatch(fetchAddresses(customerUrn))}
        updateCustomerAddress={(addressUrn: string, key: string, value: string) => this.props.dispatch(updateCustomerAddress(customerUrn, addressUrn, key, value))}
        deleteCustomerAddress={(addressUrn: string, index: number) => this.props.dispatch(deleteCustomerAddress(customerUrn, addressUrn, index))}
        addCustomerAddress={this.handleAddAddress}
        fetchBraintreeToken={() => this.props.dispatch(fetchBraintreeToken())}
        transactionAttempt={() => this.props.dispatch(actions.transactionAttempt())}
        setTransactionError={(error: string) => this.props.dispatch(actions.setTransactionError(error))}
        transactionError={this.props.transactionError}
        displayNotification={(type: string, headline: string, message: string) => this.props.dispatch(notificationActions.addNotification(type, headline, message))}
        deleteCustomerPaymentMethod={(token: string, index: number) => this.props.dispatch(deleteCustomerPaymentMethod(customerUrn, token, index))}
        busy={this.props.busy}
        onClickBookings={this.handleClickBookings}
        getReferralBookings={() => this.props.dispatch(getReferralBookings(customerUrn))}
      />
    ) : (
      <div className="spinner-container">
        <Spinner name="pulse" fadeIn="none" />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): ReduxProps => {
  const customer = state.customersState.getIn(["customers", ownProps.match.params.urn, "customer"])?.asMutable({ deep: true });
  const bookingUrns = state.customersState.getIn(["customers", ownProps.match.params.urn, "bookingUrns"])?.asMutable() || [];
  const bookings = bookingUrns ? bookingUrns.map(u => state.bookings.bookings[u]) : [];

  const page = ownProps.match.params.page || "general";

  return ({
    bookings,
    customer,
    isBusy: state.customersState.isBusy,
    transactionBusy: state.transactionsState.get("isBusy"),
    page,
    busy: state.customersState.busy,
    braintreeToken: state.transactionsState.get("token"),
    isModalOpen: state.transactionsState.get("isNewCardModalOpen"),
    transactionError: state.transactionsState.get("error"),
    isPartner: state.operator.isPartner,
  });
};

export const CustomerContainer = withRouter(connect<ReduxProps, {}, OwnProps>(mapStateToProps)(Component));
