import * as React from "react";
import styled from "styled-components";
import * as moment from "moment";

interface Props {
  timestamp: number;
  message: string;
}

const DashboardBlockItem = styled.div`
  flex: 1;
  margin-bottom: 20px;
`;

const DashboardBlockTime = styled.div`
  font-weight: bold;
  padding-bottom: 5px;
`;

const DashboardBlockMessage = styled.div`
  line-height: 2;
  padding-bottom: 5px;
  border-bottom: 1px solid #cdcdcd;
`;

export const DashboardBlock: React.StatelessComponent<Props> = (props) => {
  return (
    <DashboardBlockItem>
      <DashboardBlockTime>
        {moment(props.timestamp).format("DD/MM/YYYY HH:mm")}
      </DashboardBlockTime>
      <DashboardBlockMessage>{props.message}</DashboardBlockMessage>
    </DashboardBlockItem>
  );
};
