import * as React from "react";
import { connect, } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {RootState} from "../reducers";
import {login} from "../reducers/operator/actions";
import {Login} from "../components/login";

interface ReduxProps {
  hasErrored: boolean;
}

type OwnProps = RouteComponentProps<{}> & {dispatch: Dispatch<{}>};
type Props = ReduxProps & OwnProps;

class Component extends React.Component<Props> {
  handleLogin = (username: string, password: string) => {
    this.props.dispatch(login(this.props.history, username, password));
  }

  render() {
    return (
      <div>
        <Login
          onLogin={this.handleLogin}
          hasErrored={this.props.hasErrored}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): ReduxProps => {
  return {
    hasErrored: state.operator.hasErroredLoggingIn,
  };
};

export const LoginContainer = withRouter(connect<ReduxProps, {}, OwnProps>(mapStateToProps)(Component));
