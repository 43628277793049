import * as iassign from "immutable-assign";
import { ActionType, getType } from "typesafe-actions";

import { actionCreators } from "./actions";
import { ApiMarketingMessage } from "../../api/main/notifications";

type MarketingMessageAction = ActionType<typeof actionCreators>;

interface GetMarketingMessageState {
  marketingMessage: ApiMarketingMessage;
  marketingMessageLoading: boolean;
  marketingMessageError: Error | undefined;
}

interface UpdateMarketingMessageState {
  updateMarketingMessageLoading: boolean;
  updateMarketingMessageError: Error | undefined;
}

interface EditingMessageFieldState {
  isEditingMessage: boolean;
}

export type MarketingMessageState = GetMarketingMessageState & UpdateMarketingMessageState & EditingMessageFieldState;

const INITIAL_STATE: MarketingMessageState = {
  marketingMessage: undefined,
  marketingMessageLoading: false,
  marketingMessageError: undefined,
  updateMarketingMessageLoading: false,
  updateMarketingMessageError: undefined,
  isEditingMessage: false,
};

export function marketingMessageReducer(
  state: MarketingMessageState = INITIAL_STATE,
  action: MarketingMessageAction
): MarketingMessageState {
  switch (action.type) {
    case getType(actionCreators.fetchMarketingMessageInit): {
      return iassign(
        state,
        s => {
          s.marketingMessageLoading = true;
          s.marketingMessageError = undefined;

          return s;
        }
      );
    }
    case getType(actionCreators.fetchMarketingMessageSuccess): {
      return iassign(
        state,
        s => {
          s.marketingMessageLoading = false;
          s.marketingMessageError = undefined;
          s.marketingMessage = action.payload.message;

          return s;
        }
      );
    }
    case getType(actionCreators.fetchMarketingMessageError): {
      return iassign(
        state,
        s => {
          s.marketingMessageLoading = false;
          s.marketingMessageError = action.payload.error;

          return s;
        }
      );
    }
    case getType(actionCreators.updateMarketingMessageInit): {
      return iassign(
        state,
        s => {
          s.isEditingMessage = false;
          s.updateMarketingMessageLoading = true;
          s.updateMarketingMessageError = undefined;

          return s;
        }
      );
    }
    case getType(actionCreators.updateMarketingMessageSuccess): {
      return iassign(
        state,
        s => {
          s.updateMarketingMessageLoading = false;
          s.updateMarketingMessageError = undefined;

          return s;
        }
      );
    }
    case getType(actionCreators.updateMarketingMessageError): {
      return iassign(
        state,
        s => {
          s.updateMarketingMessageLoading = false;
          s.updateMarketingMessageError = action.payload.error;

          return s;
        }
      );
    }
    case getType(actionCreators.startEditingMessageField): {
      return iassign(
        state,
        s => {
          s.isEditingMessage = true;

          return s;
        }
      );
    }
    case getType(actionCreators.finishEditingMessageField): {
      return iassign(
        state,
        s => {
          s.isEditingMessage = false;

          return s;
        }
      );
    }
    default: {
      return state;
    }
  }
}
