import * as Immutable from "seamless-immutable";
import {actionCreators} from "./actions";
import * as types from "./types";
import { Note } from "../../api/main/notes";

type Action = ReturnType<typeof actionCreators[keyof typeof actionCreators]>;

export type State = {
  readonly isLoading: boolean;
  readonly notes: Note[];
  readonly error: string;
};

const INITIAL_STATE = Immutable.from<State>({
  isLoading: false,
  notes: [],
  error: null
});

export function reducer(state: Immutable.ImmutableObject<State> = INITIAL_STATE, action: Action): Immutable.ImmutableObject<State> {
  switch (action.type) {
    case types.FETCH_ATTEMPT:
      return state.set("isLoading", true);

    case types.FETCH_SUCCESS:
      return state.set("notes", action.payload.notes)
        .set("isLoading", false)
        .set("error", null);

    case types.FETCH_FAILURE:
      return state.set("error",  action.payload.error)
        .set("isLoading", false);

    case types.CREATE_SUCCESS: {
      const notes = state.asMutable({deep: true}).notes;
      notes.push(action.payload.note);
      return state.set("notes", notes);
    }

    case types.UPDATE: {
      const notes = state.asMutable({deep: true}).notes;
      const index = notes.findIndex(n => n.urn === action.payload.urn);
      notes[index][action.payload.field] = action.payload.value;

      return state.set("notes", notes);
    }

    case types.CLEAR:
      return INITIAL_STATE;

    default:
      return state;
  }
};
