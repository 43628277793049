import * as React from "react";
import * as Modal from "react-modal";
import * as Select from "react-select";

import { BookingUpdateValue } from "../../api/main/booking";
import { BOOKING_CANCELLATION_REASONS } from "./booking";

interface Props {
  cancelationValue: BookingUpdateValue;
  isOpen: boolean;
  closeModal: () => void;
  onUpdateReason: (value: BookingUpdateValue) => void;
  handleSubmit: () => void;
}

export class CreateRefundModal extends React.Component<Props, {}> {
  handleChange = (option: Select.Option) => {
    this.props.onUpdateReason(option.value.toString());
  }

  render() {
    const modalStyling: Modal.Styles = {
      content: {
        height: "fit-content",
        width: "50%",
        top: "50%",
        left: "50%",
        transform: "translateY(-50%) translateX(-50%)",
        overflow: "visible",
      },
      overlay: {
        zIndex: 3,
      },
    };

    return (
      <Modal
        isOpen={this.props.isOpen}
        style={modalStyling}
        contentLabel="Manual Refund"
        onRequestClose={this.props.closeModal}
        ariaHideApp={false}
      >
        <div className="new-transaction-modal">
          <div className="row new-transaction-title">
            <div
              className="fa fa-times"
              onClick={() => this.props.closeModal()}
            ></div>
            <div className="col-md-12">
              <h3>Refund to a Customer</h3>
            </div>
          </div>
          <div className="main-details-row">
            <div className="detail-value">
              <div className="field-edit">
                <div className="select-box">
                  <Select
                    value={this.props.cancelationValue}
                    clearable={false}
                    options={BOOKING_CANCELLATION_REASONS}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row new-transaction-row">
            <div className="col-md-12">
              <button
                className={`btn btn-submit btn-primary`}
                onClick={this.props.handleSubmit}
              >
                Confirm Refund
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
