import { ActionType, getType } from "typesafe-actions";
import { actions } from "./actions";
import * as iassign from "immutable-assign";
import { ApiAddress } from "../../api/main/booking";

type FraudulentAddressAction = ActionType<typeof actions>;

export interface FraudulentAddressState {
  addresses: ApiAddress[],
  isLoading: boolean
}

const INITIAL_STATE: FraudulentAddressState = {
  addresses: [],
  isLoading: false
};

export function fraudulentAddressReducer(state: FraudulentAddressState = INITIAL_STATE, action: FraudulentAddressAction): FraudulentAddressState {
  switch (action.type) {
    case getType(actions.requestGetAllFraudulentAddressesInit): {
      return iassign(
        state,
        s => {
          s.isLoading = true;
          return s;
        }
      );
    }
    case getType(actions.requestGetAllFraudulentAddressesSuccess): {
      return iassign(
        state,
        s => {
          s.addresses = action.payload;
          s.isLoading = false;
          return s;
        }
      );
    }
    case getType(actions.requestCreateFraudulentAddressInit): {
      return state;
    }
    case getType(actions.requestCreateFraudulentAddressSuccess): {
      return {
        ...state,
        addresses: [...state.addresses, action.payload]
      };
    }
    case getType(actions.requestDeleteFraudulentAddressInit): {
      return state;
    }
    case getType(actions.requestDeleteFraudulentAddressSuccess): {
      return iassign(
        state,
        s => {
          s.addresses = state.addresses.filter(x => x.urn != action.payload.urn);
          return s;
        }
      );
    }

    default: return state;
  }
}