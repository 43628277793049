import * as React from "react";
import { ApiBookingStatement } from "../../api/main/booking-statements";
import styled from "styled-components";
import { DataTable, DataTableTemplateEnum } from "../organisms/data-table";
import { DataTableDataItem } from "../molecules";
import _ from "lodash";

const PageContainer = styled.div`
    padding: 0 0 20px 0;
    float: left; 
    width: 100%; 
`;

const TableContainer = styled.div`
    background-color: #ffffff; 
    width: 100%; 
    float: left; 
    box-shadow: 1px 2px 4px rgba(0, 0, 0, .2); 
`;

interface Props {
  therapist: { name: string };
  bookingStatements: ApiBookingStatement[];
  toggleCreateModal(): void;
  toggleDeleteModal(urn: string): void;
  toggleEditModal(urn: string): void;
}

export const BookingStatements: React.SFC<Props> = ({
  therapist,
  bookingStatements,
  toggleCreateModal,
  toggleDeleteModal,
  toggleEditModal,
}) => {

  const data = _.map(bookingStatements, (item: DataTableDataItem) => {
    return {
      urn: item.urn,
      name: item.name,
      value: item.value,
    };
  });

  return (
    <>
      <h1>Booking</h1>
      <PageContainer>

        <TableContainer>

          <DataTable
            heading={{
              caption: `Therapist: ${therapist.name}`,
              event: {
                text: "Create booking statement",
                onClick: toggleCreateModal,
              }
            }}
            subHeading={[
              { name: "Name", width: "40%", },
              { name: "Value", width: "30%", },
              { name: "Actions", width: "30%" },
            ]}
            noData={{
              text: "No booking statements",
              event: {
                text: "Create booking statement",
                onClick: toggleCreateModal,
              }
            }}
            tableData={data}
            dataTableTemplate={DataTableTemplateEnum.THREE_COLUMNS}
            includeFooter={true}
            toggleCreate={toggleCreateModal}
            toggleEdit={toggleEditModal}
            toggleDelete={toggleDeleteModal} />

        </TableContainer>
      </PageContainer>
    </>
  );
};
