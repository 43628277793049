import * as React from "react";
import { Label } from "react-bootstrap";
import * as Select from "react-select";
import * as utils from "../../utils";
import { ConfirmAlert } from "../sub/alerts/confirm-alert";

interface Props {
  booking: any;
  onUpdateStatus: (status: string) => void;
}
interface State {
  editing: boolean;
  status: any;
  confirming: boolean;
}

export default class StatusEditField extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const {status} = props.booking;
    this.state = {
      editing: false,
      status: {
        label: status.toUpperCase(),
        value: status
      },
      confirming: false
    };
  }

  editHandler = (): void => {
    this.setState({...this.state, editing: true});
  }

  editCancelHandler = (): void => {
    this.setState({...this.state, editing: false});
  }

  editSaveHandler = (): void => {
    if (this.state.status.value === "CANCELLED")
      return this.setState({...this.state, confirming: true});

    this.props.onUpdateStatus(this.state.status.value);

    this.editCancelHandler();
  }

  handleStatusChange = (option: any): void => {
    this.setState({...this.state, status: option});
  }

  render() {
    const { booking, onUpdateStatus } = this.props;
    const options = ["COMPLETED", "UNCONFIRMED", "CONFIRMED", "CANCELLED", "IN_PROGRESS", "THERAPIST_ON_WAY"].map((status: string) => ({
      label: utils.mapStatus(status),
      value: status
    }));

    return (this.state.editing) ? (
      <Label className={`status-${booking.status.toLowerCase()} pull-right field-edit field-edit-status`}>
        <Select
          value={this.state.status}
          clearable={false}
          options={options}
          onChange={this.handleStatusChange}
          />
        <button className="btn btn-submit" type="button" onClick={this.editSaveHandler}>
        <span className="fa fa-check text-success" aria-hidden="true" />
        </button>
        <button className="btn btn-cancel" type="submit" onClick={this.editCancelHandler}>
          <span className="fa fa-times" aria-hidden="true" />
        </button>

        <ConfirmAlert
          isOpen={this.state.confirming}
          title="Are you sure you want to cancel?"
          message="Have you notified the client of any relevant charges for cancellation/reschedule?"
          closeModal={() => this.setState({...this.state, confirming: false})}
          handleConfirm={() => {
            this.setState({...this.state, confirming: false});
            onUpdateStatus(this.state.status.value);
            this.editCancelHandler();
          }} />
      </Label>
    ) : (
      <Label className={`status-${booking.status.toLowerCase()} pull-right field-edit`}>
        {utils.mapStatus(booking.status) + " "} 
        <span onClick={this.editHandler} className="edit-icon edit-icon-hover fa fa-pencil"></span>
      </Label>
    );
  }
}
