import { createStandardAction } from "typesafe-actions";
import { ActionDispatch } from "../../reducers";
import * as api from "../../api/main/ignored-therapists";

export const actions = {
  requestGetAllIgnoredTherapistsInit: createStandardAction("ignoredTherapist/REQUEST_GET_ALL_IGNORED_THERAPISTS_INIT")<void>(),
  requestGetAllIgnoredTherapistsSuccess: createStandardAction("ignoredTherapist/REQUEST_GET_ALL_IGNORED_THERAPISTS_SUCCESS")<api.ApiIgnoredTherapist[]>(),

  requestAddIgnoredTherapistInit: createStandardAction("ignoredTherapist/REQUEST_ADD_IGNORED_THERAPIST_INIT")<void>(),
  requestAddIgnoredTherapistSuccess: createStandardAction("ignoredTherapist/REQUEST_ADD_IGNORED_THERAPISTS_SUCCESS")<api.ApiIgnoredTherapist>(),

  requestRemoveIgnoredTherapistInit: createStandardAction("ignoredTherapist/REQUEST_REMOVE_IGNORED_THERAPISTS_INIT")<void>(),
  requestRemoveIgnoredTherapistSuccess: createStandardAction("ignoredTherapist/REQUEST_REMOVE_IGNORED_THERAPISTS_SUCCESS")<{ urn: string }>(),
};

export function fetchIgnoredTherapists(): ActionDispatch {

  return async dispatch => {
    dispatch(actions.requestGetAllIgnoredTherapistsInit());

    const ignoredTherapists = await api.fetch();

    dispatch(actions.requestGetAllIgnoredTherapistsSuccess(ignoredTherapists));
  };
}

export function addIgnoredTherapist(therapist: api.ApiIgnoredTherapist): ActionDispatch {
  return async dispatch => {
    dispatch(actions.requestAddIgnoredTherapistInit());

    await api.addTherapist(therapist);

    dispatch(actions.requestAddIgnoredTherapistSuccess(therapist));
  };
}

export function removeIgnoredTherapist(urn: string): ActionDispatch {
  return async dispatch => {
    dispatch(actions.requestRemoveIgnoredTherapistInit());
    
    await api.removeTherapist(urn);

    dispatch(actions.requestRemoveIgnoredTherapistSuccess({ urn }));
  };
}
