import { AxiosResponse } from "axios";

import { config } from "../../config";
import * as api from "./request";

interface CloudwatchGroup {
  filterPattern: string;
  logGroupName: string;
}

interface Log {
  eventId: number;
  ingestionTime: number;
  logStreamName: string;
  timestamp: number;
  message: string;
}

export interface EventLogs {
  [key: string]: Log[];
}

export async function getDashboardDetails(
  groups: CloudwatchGroup[],
): Promise<EventLogs> {
  const url = `${config.adminPanelApiUrl}/dashboard/cloudwatch`;
  const response: AxiosResponse = await api.post(url, { groups });

  return response.data;
}

export async function getAutoSetAvailabilityTherapistCount(): Promise<number> {
  const url = `${config.adminPanelApiUrl}/therapists/count/auto-availability`;
  const response: AxiosResponse = await api.get(url);

  return response.data;
}
