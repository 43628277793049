import axios from "axios";
import querystring from "querystring";
import { Dispatch } from "redux";

import * as api from "./request";
import {config} from "../../config";
import {mapToOperator} from "../../presenters/operator";

export interface ApiOperator {
  urn: string;
  permissions: {
    all?: boolean;
    bookings?: boolean;
    customers?: boolean;
    therapists?: boolean;
    treatments?: boolean;    
  };
  isPartner: boolean;
  partner?: any;
  summary?: any;
}

export async function getOperator(dispatch: Dispatch<{}>) {
  const url = `${config.adminPanelApiUrl}/operator`;

  const response = await api.get<ApiOperator>(url, dispatch);

  return mapToOperator(response.data);
}

export function login(username: string, password: string): Promise<any> {
  const apiConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  };
  const url = `${config.adminPanelApiUrl}/login`;
  const params = querystring.stringify({username, password});

  return axios.post(url, params, apiConfig);
}

export function loginPartner(partnerId: string, username: string, password: string): Promise<any> {
  const apiConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  };
  const url = `${config.adminPanelApiUrl}/login-partner`;
  const params = querystring.stringify({partnerId, username, password});

  return axios.post(url, params, apiConfig);
}