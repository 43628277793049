import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps, withRouter } from "react-router";

import { RootState } from "../../reducers";
import { inflateArrayFromMap } from "../../presenters/utils";
import {
  inflateTreatment,
  TreatmentPresenter,
} from "../../presenters/treatment";
import {
  initiateSearch,
  deleteTreatment,
  fetchAllTreatmentsForTherapist,
} from "../../reducers/treatments/actions";

import * as Modal from "react-modal";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { JobDeleteModal } from "../../components/sub/alerts/job-delete";

import Table, { ColumnNames } from "../../components/table";
import { Well, WellRow } from "../../components/atoms/well";
import { DebouncedSearchInput } from "../../components/molecules/debounced-search-input";
import { fetchTherapist } from "../../model/therapist";
import { AddonBadge } from "../../components/atoms/treatment-badge";

const modalStyling: Modal.Styles = {
  content: {
    height: "fit-content",
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",
    border: "0px",
    backgroundColor: "#efefef",
  },
  overlay: {
    zIndex: 3,
  },
};

const ModalContainer = styled.div`
  background-color: #efefef;
`;

const ModalHeading = styled.div`
  background-color: #396174;
  color: #ffffff;
  margin: 0px;
  padding: 10px;
  text-transform: capitalize;
`;
const ModalBody = styled.div`
  margin: 0;
  padding: 20px;
  min-height: 100px;
  text-align: left;
`;

const ModalFooter = styled.div`
  margin: 0;
  padding: 20px;
  float: left;
  background-color: rgb(222, 222, 222);
  width: 100%;
  text-align: left;
`;

const Cell = styled.div`
  float: left;
`;

interface ReduxProps {
  treatments: TreatmentPresenter[];
  isLoading: boolean;
  isPartner: boolean;
  therapist: any;
  therapistUrn: string;
}

interface State {
  activeTreatment: string;
  deleteModalVisible: boolean;
  modalVisible: boolean;
  selectedTreatment: TreatmentPresenter;
}

type OwnProps = RouteComponentProps<{}>;

type Props = ReduxProps & OwnProps & { dispatch: Dispatch<{}> };

class Container extends React.Component<Props, State> {
  handleFetchAll = (): void => {
    this.props.dispatch(
      fetchAllTreatmentsForTherapist(this.props.therapistUrn),
    );
  };

  handleSearch = (query: string): void => {
    this.props.dispatch(initiateSearch(query));
  };

  openModal = (urn: string): void => {
    const treatment = this.props.treatments.find((t) => t.urn === urn);
    this.setState({
      ...this.state,
      modalVisible: true,
      activeTreatment: urn,
      selectedTreatment: treatment,
    });
  };

  closeModal = (): void => {
    this.setState({
      ...this.state,
      modalVisible: false,
      selectedTreatment: null,
    });
  };

  handleSelectTreatment = (urn: string): void => {
    this.props.isPartner
      ? this.openModal(urn)
      : this.props.history.push(`/treatments/${urn}`);
  };

  handleDeleteTreatment = (): void => {
    const urn = this.state.activeTreatment;
    this.props.dispatch(deleteTreatment(urn));
    this.toggleDeleteModal();
  };

  componentDidMount(): void {
    if (!this.props.therapist) {
      this.props.dispatch(fetchTherapist(`${this.props.therapistUrn}-1`));
    }
    this.props.isPartner ? this.handleFetchAll() : null;
  }
  constructor(props: Props) {
    super(props);

    this.state = {
      activeTreatment: "",
      deleteModalVisible: false,
      modalVisible: false,
      selectedTreatment: null,
    };
  }

  getPrice(treatment: TreatmentPresenter, regionName: string, priceProp: string): string {
    const region = treatment.regions.find(r => r.name === regionName);

    if (typeof region !== "undefined") {
      return `£${region[priceProp]}`;
    }

    return `£0`;
  }

  mapDataset(treatments: TreatmentPresenter[], columns: ColumnNames[]) {
    const parsed = treatments.map((treatment) => {
      const obj: any = {};
      obj["_id"] = treatment.urn;

      columns.forEach(({ key }) => {
        switch (key) {
          case "urn": {
            const component = (
              <Cell key={key} style={{ width: "25%" }}>
                <a style={{ color: "black", textDecoration: "none" }}>
                  {treatment[key]}
                </a>
              </Cell>
            );

            obj[key] = component;
            break;
          }

          case "classic": {
            const component = (
              <Cell key={key} style={{ width: "13%" }}>
                <a style={{ color: "black", textDecoration: "none" }}>
                  {this.getPrice(treatment, "London", "classic")}
                </a>
              </Cell>
            );

            obj[key] = component;
            break;
          }

          case "elite": {
            const component = (
              <Cell key={key} style={{ width: "13%" }}>
                <a style={{ color: "black", textDecoration: "none" }}>
                  {this.getPrice(treatment, "London", "elite")}
                </a>
              </Cell>
            );
            
            obj[key] = component;
            break;
          }

          case "duration": {
            const component = (
              <Cell key={key} style={{ width: "10%" }}>
                <a style={{ color: "black", textDecoration: "none" }}>
                  {treatment[key]}
                </a>
              </Cell>
            );

            obj[key] = component;
            break;
          }

          case "name": {
            const component = (
              <Cell key={key} style={{ width: "20%" }}>
                <a
                  onClick={() => this.handleSelectTreatment(treatment.urn)}
                  style={{
                    textDecoration: "underline",
                    color: "#666666",
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                >
                  {treatment[key]}
                </a>
                {
                  treatment.isAddon 
                  ? (
                    <AddonBadge small>add-on</AddonBadge>
                  ) : null
                }
              </Cell>
            );

            obj[key] = component;
            break;
          }

          default: {
            const component = (
              <Cell key={key} style={{ width: "10%" }}>
                <a style={{ color: "black", textDecoration: "none" }}>
                  {treatment[key]}
                </a>
              </Cell>
            );

            obj[key] = component;
            break;
          }
        }
      });
      return obj;
    });

    return parsed;
  }

  renderTable() {
    const columns: ColumnNames[] = [
      { title: "Treatment URN", key: "urn", width: "25%" },
      { title: "Category", key: "topLevelCategory", width: "10%" },
      { title: "Name", key: "name", width: "20%" },
      { title: "Duration", key: "duration", width: "10%" },  
      { title: "London's Classic Price", key: "classic", width: "13%" },
      { title: "London's Elite Price", key: "elite", width: "13%" },
    ];

    if (this.props.isPartner) {
      columns.splice(0, 1);
      columns.splice(3, 3);
      const priceCol = { title: "Price", key: "price", width: "9%" };
      columns.push(priceCol);
    }

    const rowActions = [
      {
        name: "Delete",
        onClick: (urn: string) => this.toggleDeleteModalAndSet(urn),
      },
    ];

    const dataset = this.mapDataset(this.props.treatments, columns);

    return (
      <Table
        tableName="Treatments"
        createEntryAction={() => this.props.history.push(`/treatments/create`)}
        columns={columns}
        dataset={dataset}
        rowActions={rowActions}
        emptyMessage="No treatments to show"
        isLoading={this.props.isLoading}
      />
    );
  }

  toggleDeleteModal(): void {
    this.setState({
      deleteModalVisible: !this.state.deleteModalVisible,
    });
  }

  toggleDeleteModalAndSet(urn: string): void {
    this.setState({
      activeTreatment: urn,
      deleteModalVisible: !this.state.deleteModalVisible,
    });
  }

  render(): JSX.Element {
    return (
      <>
        <Modal
          isOpen={this.state.modalVisible}
          style={modalStyling}
          ariaHideApp={false}
        >
          <ModalContainer>
            <ModalHeading>
              {this.state.selectedTreatment
                ? this.state.selectedTreatment.name
                : null}
            </ModalHeading>
            <ModalBody>
              {this.state.selectedTreatment
                ? this.state.selectedTreatment.description
                : null}
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => this.closeModal()}>Close</Button>
            </ModalFooter>
          </ModalContainer>
        </Modal>
        {!this.props.isPartner ? (
          <Well>
            <WellRow>
              <DebouncedSearchInput onChange={this.handleSearch} />
            </WellRow>
          </Well>
        ) : null}
        {this.renderTable()}
        <JobDeleteModal
          isOpen={this.state.deleteModalVisible}
          title="Treatment deletion"
          message={"Are you sure you want to delete this treatment?"}
          closeModal={() => this.toggleDeleteModal()}
          handleConfirm={this.handleDeleteTreatment}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): ReduxProps => {
  return {
    treatments: inflateArrayFromMap(
      state.treatments.treatments,
      state.treatments.searchResultUrns,
      inflateTreatment,
    ),
    isLoading: state.treatments.isLoading,
    isPartner: state.operator.isPartner,
    therapist: state.operator.isPartner
      ? state.therapistsState.therapists[
      `${state.operator.partner.therapistUrn}-1`
      ]
      : null,
    therapistUrn: state.operator.isPartner
      ? state.operator.partner.therapistUrn
      : null,
  };
};

export const TreatmentsSearchContainer = withRouter(
  connect<ReduxProps, {}, OwnProps>(mapStateToProps)(Container),
);
