export interface SelectOption {
    value: string;
    label: string;
}

export const singleUseArrayOptions: SelectOption[] = [
    { "value": "0", "label": "No" },
    { "value": "1", "label": "Yes" }
];

export const variationTypeArrayOptions: SelectOption[] = [
    { "value": "0", "label": "absolute" },
    { "value": "1", "label": "percentage" }
];

export const firstTimeArrayOptions: SelectOption[] = [
    { "value": "0", "label": "No" },
    { "value": "1", "label": "Yes" }
];

export const categoryArrayOptions: SelectOption[] = [
    { "value": "0", "label": "No Category" },
    { "value": "1", "label": "Acquisition promo" },
    { "value": "15", "label": "Retention promo" },
    { "value": "2", "label": "B2B Commission" },
    { "value": "3", "label": "Concierge Incentive" },
    { "value": "4", "label": "Internal team use" },
    { "value": "5", "label": "Brand activation" },
    { "value": "6", "label": "Influencer" },
    { "value": "7", "label": "Credit" },
    { "value": "8", "label": "Existing customer promo" },
    { "value": "9", "label": "High value customer" },
    { "value": "10", "label": "Therapist affiliate code"},
    { "value": "11", "label": "Influencer affiliate code"},
    { "value": "12", "label": "Brand partner affiliate code"},
    { "value": "13", "label": "Content affiliate code"},
    { "value": "14", "label": "Other affiliate code"},
];
