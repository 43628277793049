import { ActionType, getType } from "typesafe-actions";
import * as iassign from "immutable-assign";

import {actions} from "../actions";
import { ApiCategory } from "../../../api/main/category";

type CategoryAction = ActionType<typeof actions>;

export interface CategoryState {
  categories: {
    [urn: string]: ApiCategory;
  };
  searchResultUrns: string[];
  isLoading: boolean;
}

const INITIAL_STATE: CategoryState = {
  categories: {},
  searchResultUrns: [],
  isLoading: false,
};

export function categoryReducer(state: CategoryState = INITIAL_STATE, action: CategoryAction): CategoryState {
  switch (action.type) {
    case getType(actions.requestInit): {
      return iassign(
        state,
        s => {
          s.isLoading = true;

          return s;
        }
      );
    }

    case getType(actions.requestSuccess): {
      return iassign(
        iassign(
          state,
          s => {
            s.isLoading = true;
            s.searchResultUrns = action.payload.categories.map(t => t.urn);

            return s;
          }
        ),

        s => s.categories,
        c => {
          // set each treatment in the map defined as 't'
          Object.values(action.payload.categories).forEach(cat => c[cat.urn] = cat);

          return c;
        }
      );
    }

    default: return state;
  }
}
