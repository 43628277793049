import * as React from "react";
import * as Select from "react-select";

import * as therapistApi from "../../api/main/therapist";

interface PartnerTherapistProps {
  therapistUrn: string;
  onChangePartnerTherapist: (therapist: any) => void;
}

interface State {
  therapist: {
    value: string;
    label: string;
  }
}

export default class PartnerTherapist extends React.Component<PartnerTherapistProps, State> {
  therapists: therapistApi.ApiTherapist[];

  state: State = {
    therapist: null,
  }

  async componentDidMount() {
    if (this.props.therapistUrn) {
      try {
        const therapist = await therapistApi.fetchTherapist(this.props.therapistUrn);

        this.setState({ therapist: { value: therapist.urn, label: therapist.name } });
      }
      catch (e) { }
    }
  }

  async salonChanged(option: any) {
    if (option) {
      const therapist = this.therapists.find((therapist: any) => therapist.urn === option.value);

      this.setState({ therapist: { value: therapist.urn, label: therapist.name } });

      this.props.onChangePartnerTherapist(therapist.urn);
    }
    else {
      this.setState({ therapist: null });
      this.props.onChangePartnerTherapist("");
    }
  }

  async searchTherapist(name: string) {
    const therapists = await therapistApi.search({ query: name, limit: 10 });
    this.therapists = therapists;

    const therapistsOptions = therapists.map((therapist: any) => {
      therapist.value = therapist.urn;
      therapist.label = therapist.name;

      return therapist;
    });

    return {
      options: therapistsOptions
    };
  }

  render() {
    return (
      <div className="main-partner-details-row">
        <div className="detail-partner-heading">
          Select profile
        </div>
        <div className="detail-partner-value">
          <div className="field-edit">
            <div className="select-box">
              <Select.Async
                cache={null}
                value={this.state.therapist}
                clearable={false}
                onChange={this.salonChanged.bind(this)}
                loadOptions={this.searchTherapist.bind(this)}
                placeholder="Search by therapist's name"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
