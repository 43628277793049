import * as React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import * as Spinner from "react-spinkit";
import { withRouter, RouteComponentProps } from "react-router-dom";

import {TherapistSubMenu} from "../../components/sub/sub-menu";
import { TherapistAvailability } from "../../components/therapist/therapist-availability";
import {RootState} from "../../reducers";
import { ApiTherapist } from "../../api/main/therapist";
import { fetchTherapist } from "../../model/therapist";
import { searchTherapist } from "../../reducers/bookings/actions";

interface ReduxProps {
  therapist: ApiTherapist;
}

type OwnProps = RouteComponentProps<{urn: string}>;
type Props = ReduxProps & OwnProps & {dispatch: Dispatch<{}>};

class Component extends React.Component<Props, {}> {
  componentDidMount() {
    if (!this.props.therapist) {
      this.props.dispatch(fetchTherapist(this.props.match.params.urn));
    }
  }

  handleClickBookings = (): void => {
    this.props.dispatch(searchTherapist(this.props.therapist.urn));
  }

  render() {
    const {therapist} = this.props;

    return (
      <div className="therapist-container">
        {therapist &&
          <div className="main-section">
            <TherapistAvailability
              urn={this.props.match.params.urn}
              therapist={therapist}
            />
          </div>
        }
        {!therapist &&
          <div className="spinner-container main-section">
            <Spinner name="pulse" fadeIn="none" />
          </div>
        }

        <div className="side-nav">
          <TherapistSubMenu urn={this.props.match.params.urn} active="availability" onClickBookings={this.handleClickBookings} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): ReduxProps => {
  const therapistUrn = ownProps.match.params.urn;
  const therapist = state.therapistsState.therapists[therapistUrn];

  return {
    therapist: (therapist) ? therapist : null,
  };
};

export const TherapistAvailabilityContainer = withRouter(connect<ReduxProps, {}, OwnProps>(mapStateToProps)(Component));
