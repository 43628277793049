import * as React from "react";

export const editButtonCss = `
  & > .edit-icon {
    display: none;
    cursor: pointer;
  }

  &:hover {
    & > .edit-icon {
      display: inline;
    }
  }
}
`;

interface Props {
  onClick(): void;
}

export const EditableFieldIcon: React.StatelessComponent<Props> = props => (
  <span onClick={props.onClick} className="edit-icon fa fa-pencil"></span>
);