export const SET_BOOKING_CUSTOMER = "SET BOOKING CUSTOMER";
export const SET_BOOKING_DATE = "SET BOOKING DATE";
export const SET_BOOKING_THERAPIST = "SET BOOKING THERAPIST";
export const SET_BOOKING_ADDRESS = "SET BOOKING ADDRESS";
export const SET_BOOKING_NOTES = "SET BOOKING NOTES";
export const SET_BOOKING_STATUS = "SET BOOKING STATUS";
export const SET_BOOKING_TIER = "SET BOOKING TIER";
export const SET_BOOKING_SELECTED_TREATMENTS = "SET BOOKING SELECTED TREATMENTS";
export const FETCH_THERAPIST_TREATMENTS_SUCCESS = "FETCH THERAPIST TREATMENTS SUCCESS";
export const SUBMIT_BOOKING_SUCCESS = "SUBMIT BOOKING SUCCESS";
export const SUBMIT_BOOKING_ATTEMPT = "SUBMIT_BOOKING_ATTEMPT";
export const SUBMIT_BOOKING_ERROR = "SUBMIT_BOOKING_ERROR";
export const AMEND_BOOKING_SELECTED_TREATMENT_PRICE = "AMEND BOOKING SELECTED TREATMENT PRICE";
export const ADD_ADJUSTMENT = "ADD_ADJUSTMENT";
export const DELETE_ADJUSTMENT = "DELETE_ADJUSTMENT";
export const AMEND_TREATMENT_QTY = "AMEND_TREATMENT_QTY";
export const TOGGLE_DO_NOT_SEND_EMAIL_FLAG = "TOGGLE_DO_NOT_SEND_EMAIL_FLAG";
export const TOGGLE_IS_B2B = "TOGGLE_IS_B2B";
export const TOGGLE_IS_PRODUCTION = "TOGGLE_IS_PRODUCTION";
export const FETCH_BOOKING_CUSTOMER_ADDRESS_SUCCESS = "FETCH_BOOKING_CUSTOMER_ADDRESS_SUCCESS";
export const ADD_PROMO = "ADD_PROMO";
export const DELETE_PROMO = "DELETE_PROMO";