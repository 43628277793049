import * as React from "react";
import styled from "styled-components";

import { IconButton } from "../icon-button";

const Button = styled(IconButton)`
  background-color: #3c763d;
`;

interface Props {
  onClick(): void;
}

export const TickButton: React.StatelessComponent<Props> = props => (
  <Button icon="fa-check" onClick={props.onClick} />
);
