import * as React from "react";
import { Table } from "react-bootstrap";
import { sprintf } from "sprintf-js";
import * as moment from "moment";
import { config } from "../../config";
import * as constants from "../../constants";
import { ApiRefund, ApiBookingTransaction } from "../../api/main/booking";
import { ConfirmAlert } from "../sub/alerts/confirm-alert";

interface Props {
  refunds: ApiRefund[];
  transactions: ApiBookingTransaction[];
  deleteRefund: (refund: ApiRefund) => void;
}
interface State {
  isOpen: boolean;
  message: string;
  refund: ApiRefund;
}

export class Refunds extends React.Component<Props, State> {
  state: State = {
    isOpen: false,
    message: "",
    refund: null,
  };

  deleteRefund = (refund: ApiRefund): void => {
    this.props.deleteRefund(refund);
    this.closeModal();
  };

  confirmDelete = (refund: ApiRefund): void => {
    this.setState({
      isOpen: true,
      message: `Are you sure you would like to delete refund of amount ${sprintf(
        "£%0.2f",
        refund.amount,
      )}?`,
      refund: refund,
    });
  };

  closeModal = (): void => {
    this.setState({
      isOpen: false,
      message: "",
      refund: null,
    });
  };

  createBraintreeUrl = (id: string): string => {
    return config.braintree.environment === "sandbox"
      ? `https://sandbox.braintreegateway.com/merchants/${config.braintree.merchantId}/transactions/${id}`
      : `https://www.braintreegateway.com/merchants/${config.braintree.merchantId}/transactions/${id}`;
  };

  createStripeUrl = (transactionUrn: string): string => {
    const transaction = this.props.transactions.find(
      (t) => t.urn === transactionUrn,
    );

    if (transaction) {
      return `${config.stripe.dashboard.url}/${
        config.environment === "production" ? "" : "test/"
      }payments/${transaction.transactionId}`;
    }

    return "#";
  };

  createLink = (refund: ApiRefund): JSX.Element => {
    const transaction = this.props.transactions.find(
      (t) => t.urn === refund.refundTransactionUrn,
    );

    const link =
      transaction.type === "STRIPE_REFUND"
        ? this.createStripeUrl(refund.transactionUrn)
        : this.createBraintreeUrl(transaction.transactionId);

    return (
      <a href={link} target="_blank">
        {transaction.transactionId}
      </a>
    );
  };

  renderRows = (): JSX.Element[] => {
    return this.props.refunds.map((refund, index) => (
      <tr key={index} className="adjustments-row">
        <td>
          {moment(refund.timeCreated)
            .tz(config.timezone)
            .format("DD/MM/YYYY HH:mm")}
        </td>
        <td>{refund.refundTransactionUrn && this.createLink(refund)}</td>
        <td>{sprintf("£%0.2f", refund.amount)}</td>
        <td>{refund.status}</td>
        <td>
          {refund.status === constants.REFUND_PENDING && (
            <button
              className="button-delete"
              type="button"
              onClick={() => this.confirmDelete(refund)}
            >
              <span className="fa fa-trash" aria-hidden="true" />
            </button>
          )}
        </td>
      </tr>
    ));
  };

  renderTotalRow = (): JSX.Element => {
    const total = this.props.refunds.reduce(
      (amount: number, transaction) => amount + transaction.amount,
      0,
    );

    return (
      <tr>
        <td></td>
        <td className="total">Refund total </td>
        <td>{sprintf("£%0.2f", total)}</td>
        <td></td>
        <td></td>
      </tr>
    );
  };

  render(): JSX.Element {
    return (
      <div className="adjustments">
        <div className="adjustments-header">
          <h3>Refunds</h3>
        </div>

        <Table>
          <colgroup>
            <col width="30%" />
            <col width="30%" />
            <col width="15%" />
            <col width="20%" />
            <col width="5%" />
          </colgroup>

          <thead>
            <tr>
              <th>Refund Date/Time</th>
              <th>Transaction ID</th>
              <th>Amount</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {this.renderRows()}
            {this.renderTotalRow()}
          </tbody>
        </Table>

        <ConfirmAlert
          isOpen={this.state.isOpen}
          title="Confirm Delete"
          message={this.state.message}
          closeModal={this.closeModal}
          handleConfirm={() => this.deleteRefund(this.state.refund)}
        />
      </div>
    );
  }
}
