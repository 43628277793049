import * as React from "react";
import * as Select from "react-select";
import { TreatmentPresenter } from "../../presenters/treatment";

interface Props {
  addNewTreatments: (urns: any[]) => void;
  treatments: TreatmentPresenter[];
}

interface State {
  editing?: boolean;
  treatments?: Select.Option[];
}

export default class NewTreatmentField extends React.Component<Props, State> {
  state: State = {
    editing: false,
    treatments: null,
  };

  clickHandler = () => {
    this.setState({editing: true});
  }

  editCancelHandler = () => {
    this.setState({editing: false});
  }

  editSaveHandler = () => {
    const urns = this.state.treatments.map((t: any) => t.value);
    this.props.addNewTreatments(urns);

    this.editCancelHandler();
    this.setState({treatments: null});
  }

  handleTreatmentSelect = (options: Select.Option[]) => {
    this.setState({treatments: options});
  }

  render() {
    const options: Select.Option[] = (this.props.treatments) ? this.props.treatments.map(treatment => ({
      value: treatment.urn,
      label: treatment.name
    })) : [{
      value: "TreatmentId",
      label: "Treatment"
    }];

    if (this.state.editing) {
      return (
        <div className="field-edit new-treatment-field">
          <div className="input-box">
            <Select
              options={options}
              multi
              onChange={this.handleTreatmentSelect}
              value={this.state.treatments} />
            <button className="btn btn-submit" type="button" onClick={this.editSaveHandler}>
              <span className="fa fa-check text-success" aria-hidden="true" />
            </button>
            <button className="btn btn-cancel" type="submit" onClick={this.editCancelHandler}>
              <span className="fa fa-times" aria-hidden="true" />
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <button className="new-treatment-btn btn btn-primary" onClick={this.clickHandler}><i className="fa fa-plus"></i> New Treatment</button>
      );
    }
  }
}
