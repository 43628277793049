import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type AddCreditResponse = Credit | RuubyGraphError;

export enum AdditionalPaymentTransactionType {
  AdditionalPayment = 'ADDITIONAL_PAYMENT',
  Tip = 'TIP'
}

export type Address = {
  __typename?: 'Address';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  postcode: Scalars['String']['output'];
  postcodeDistrict: PostcodeDistrict;
  urn: Scalars['String']['output'];
};

export type AdminFee = {
  __typename?: 'AdminFee';
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

/** Error codes that can be returned in responses */
export enum ApiError {
  /** Address it not found */
  AddressNotFound = 'ADDRESS_NOT_FOUND',
  /** The customer was not found in our system but the sign in attempt did not contain any personal details */
  AppleSignInMissingDetails = 'APPLE_SIGN_IN_MISSING_DETAILS',
  /** Booking address urn is required */
  BookingAddressRequired = 'BOOKING_ADDRESS_REQUIRED',
  /** Booking is already cancelled */
  BookingAlreadyCancelled = 'BOOKING_ALREADY_CANCELLED',
  /** Booking amount is less than the minimum booking fee */
  BookingAmountIsLessThanMinimum = 'BOOKING_AMOUNT_IS_LESS_THAN_MINIMUM',
  /** Booking cancellation failed */
  BookingCancellationFailed = 'BOOKING_CANCELLATION_FAILED',
  /** Booking country is not served by therapist */
  BookingCountryNotServed = 'BOOKING_COUNTRY_NOT_SERVED',
  /** Booking is out of sync */
  BookingDataIsOutdated = 'BOOKING_DATA_IS_OUTDATED',
  /** The status of booking is incompatible with the operation */
  BookingIncompatibleStatus = 'BOOKING_INCOMPATIBLE_STATUS',
  /** Booking is not found */
  BookingNotFound = 'BOOKING_NOT_FOUND',
  /** Booking does not have outstanding amount */
  BookingNoOutstandingAmount = 'BOOKING_NO_OUTSTANDING_AMOUNT',
  /** There is no payment request for a booking or it is expired */
  BookingPaymentRequestNotFound = 'BOOKING_PAYMENT_REQUEST_NOT_FOUND',
  /** Booking address is not served by therapist */
  BookingPostcodeNotServed = 'BOOKING_POSTCODE_NOT_SERVED',
  /** Therapist is not available at that time */
  BookingSlotTaken = 'BOOKING_SLOT_TAKEN',
  /** Booking transaction is not found */
  BookingTransactionNotFound = 'BOOKING_TRANSACTION_NOT_FOUND',
  /** Booking treatments are not supported by therapist */
  BookingTreatmentsNotSupported = 'BOOKING_TREATMENTS_NOT_SUPPORTED',
  /** Booking treatments are required */
  BookingTreatmentsRequired = 'BOOKING_TREATMENTS_REQUIRED',
  /** Booking update failed */
  BookingUpdateFailed = 'BOOKING_UPDATE_FAILED',
  /** Customer is not found */
  CustomerNotFound = 'CUSTOMER_NOT_FOUND',
  /** The customer was not found in our system but the sign in attempt did not contain any personal details */
  FacebookSignInMissingDetails = 'FACEBOOK_SIGN_IN_MISSING_DETAILS',
  /** A unspecific error occurred */
  GeneralError = 'GENERAL_ERROR',
  /** The customer was not found in our system but the sign in attempt did not contain any personal details */
  GoogleSignInMissingDetails = 'GOOGLE_SIGN_IN_MISSING_DETAILS',
  /** The provided start and end dates are either invalid or start is after end date */
  InvalidDates = 'INVALID_DATES',
  /** Invalid marketing region for a customer */
  InvalidMarketingRegion = 'INVALID_MARKETING_REGION',
  /** The provided phone number is invalid */
  InvalidPhoneNumber = 'INVALID_PHONE_NUMBER',
  /** The provided postcode is invalid */
  InvalidPostcode = 'INVALID_POSTCODE',
  /** The request the OTP code was supplied for had expired */
  OtpExpired = 'OTP_EXPIRED',
  /** The OTP code was incorrect */
  OtpInvalidCode = 'OTP_INVALID_CODE',
  /** The OTP method was invalid */
  OtpInvalidMethod = 'OTP_INVALID_METHOD',
  /** The user has reached the maximum number of OTP attempts */
  OtpMaxAttemptsReached = 'OTP_MAX_ATTEMPTS_REACHED',
  /** Payment gateway error */
  PaymentGatewayError = 'PAYMENT_GATEWAY_ERROR',
  /** Promotion is already used */
  PromotionAlreadyUsed = 'PROMOTION_ALREADY_USED',
  /** Cannot use own referral code */
  PromotionCannotUseOwnCode = 'PROMOTION_CANNOT_USE_OWN_CODE',
  /** Promotion has expired */
  PromotionExpired = 'PROMOTION_EXPIRED',
  /** Promotion should be used only with the first booking */
  PromotionFirstBookingOnly = 'PROMOTION_FIRST_BOOKING_ONLY',
  /** Promotion maximum spend error */
  PromotionMaximumSpend = 'PROMOTION_MAXIMUM_SPEND',
  /** Promotion minimum spend error */
  PromotionMinimumSpend = 'PROMOTION_MINIMUM_SPEND',
  /** Promotion is not found */
  PromotionNotFound = 'PROMOTION_NOT_FOUND',
  /** The email already exists in the database */
  SignupEmailAlreadyInUse = 'SIGNUP_EMAIL_ALREADY_IN_USE',
  /** The phone number already exists in the database */
  SignupPhoneAlreadyInUse = 'SIGNUP_PHONE_ALREADY_IN_USE',
  /** Therapist is not found */
  TherapistNotFound = 'THERAPIST_NOT_FOUND'
}

/** Permission roles */
export enum AuthRole {
  Admin = 'ADMIN',
  Customer = 'CUSTOMER',
  Therapist = 'THERAPIST'
}

/** Authorisation tokens */
export type AuthTokens = {
  __typename?: 'AuthTokens';
  /** An access token to use when making authenticated requests */
  accessToken: Scalars['String']['output'];
};

export type AvailabilitySlot = {
  __typename?: 'AvailabilitySlot';
  end: Scalars['DateTime']['output'];
  start: Scalars['DateTime']['output'];
};

export type BankFee = Financial & {
  __typename?: 'BankFee';
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export type Booking = {
  __typename?: 'Booking';
  address: Address;
  bookingTreatments: Array<BookingTreatment>;
  commission: Scalars['Float']['output'];
  customer: Customer;
  fees: Array<AdminFee>;
  financials: Array<Financial>;
  notes: Scalars['String']['output'];
  originalTherapist: Therapist;
  outstandingAmount: Scalars['Float']['output'];
  paymentTransactions: Array<PaymentTransaction>;
  percentageToTherapist: Scalars['Float']['output'];
  refunds: Array<Refund>;
  review?: Maybe<Review>;
  status: BookingStatus;
  therapist: Therapist;
  tier: Tier;
  timeCreated: Scalars['DateTime']['output'];
  timeEnds: Scalars['DateTime']['output'];
  timeStarts: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
  tips: Array<BookingTip>;
  urn: Scalars['String']['output'];
};

export type BookingAdminTreatmentParams = {
  price: Scalars['Float']['input'];
  urn: Scalars['String']['input'];
};

export type BookingCancelConfirmation = {
  __typename?: 'BookingCancelConfirmation';
  status: Scalars['Boolean']['output'];
};

export type BookingCancelConfirmationResponse = BookingCancelConfirmation | RuubyGraphError;

export enum BookingCancellationReason {
  Customer = 'CUSTOMER',
  Late = 'LATE',
  Quality = 'QUALITY',
  Ruuby = 'RUUBY',
  Therapist = 'THERAPIST'
}

export type BookingFee = {
  __typename?: 'BookingFee';
  amount: Scalars['Float']['output'];
};

export type BookingFeeResponse = BookingFee | RuubyGraphError;

export type BookingInvoiceCreationResponse = InvoiceTransaction | RuubyGraphError;

export type BookingPaymentConfirmation = {
  __typename?: 'BookingPaymentConfirmation';
  status: Scalars['Boolean']['output'];
};

export type BookingPaymentConfirmationResponse = BookingPaymentConfirmation | RuubyGraphError;

export type BookingPaymentIntent = {
  __typename?: 'BookingPaymentIntent';
  bookingUrn: Scalars['String']['output'];
  customerStripeId: Scalars['String']['output'];
  ephemeralKey: Scalars['String']['output'];
  paymentIntent: Scalars['String']['output'];
};

export type BookingPaymentIntentResponse = BookingPaymentIntent | RuubyGraphError;

export type BookingRequestPayment = {
  __typename?: 'BookingRequestPayment';
  paymentRequestToken: Scalars['String']['output'];
};

export type BookingRequestPaymentResponse = BookingRequestPayment | RuubyGraphError;

/** Booking response */
export type BookingResponse = Booking | RuubyGraphError;

export enum BookingStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Created = 'CREATED',
  InProgress = 'IN_PROGRESS',
  TherapistOnWay = 'THERAPIST_ON_WAY',
  Unconfirmed = 'UNCONFIRMED'
}

export type BookingTip = {
  __typename?: 'BookingTip';
  amount: Scalars['Float']['output'];
  brand?: Maybe<Scalars['String']['output']>;
  cardType?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Currency>;
  lastFour?: Maybe<Scalars['String']['output']>;
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export type BookingTreatment = {
  __typename?: 'BookingTreatment';
  price: Scalars['Float']['output'];
  treatment: Treatment;
  urn: Scalars['String']['output'];
};

export type BookingTreatmentParams = {
  quantity: Scalars['Int']['input'];
  urn: Scalars['String']['input'];
};

export type BookingsFilters = {
  appointmentTime?: InputMaybe<DateRange>;
  createdTime?: InputMaybe<DateRange>;
  isFullyPaid?: InputMaybe<Scalars['Boolean']['input']>;
  region?: InputMaybe<Array<InputMaybe<Region>>>;
  status?: InputMaybe<Array<InputMaybe<BookingStatus>>>;
  tier?: InputMaybe<Array<InputMaybe<Tier>>>;
};

/** Booking search response */
export type BookingsResponse = RuubyGraphError | SearchBookingsResultSet;

export type BookingsResultSet = {
  __typename?: 'BookingsResultSet';
  /** The last created date of bookings */
  lastTimeCreated?: Maybe<Scalars['DateTime']['output']>;
  /** Total number of bookings */
  totalCount: Scalars['Int']['output'];
};

export type BookingsSorting = {
  direction?: InputMaybe<Scalars['String']['input']>;
  field: Scalars['String']['input'];
};

export type BraintreeRefund = Refund & {
  __typename?: 'BraintreeRefund';
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export type BraintreeTransaction = PaymentTransaction & {
  __typename?: 'BraintreeTransaction';
  amount: Scalars['Float']['output'];
  braintreeTransactionId: Scalars['String']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export enum Channel {
  AdminPanel = 'ADMIN_PANEL',
  Android = 'ANDROID',
  Ios = 'IOS',
  Website = 'WEBSITE'
}

export type ComplimentaryTransaction = PaymentTransaction & {
  __typename?: 'ComplimentaryTransaction';
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

/** Contact methods supported for sign in */
export enum ContactMethod {
  Apple = 'APPLE',
  Email = 'EMAIL',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
  Phone = 'PHONE'
}

/** Response when a contact verification token is issued */
export type ContactVerificationToken = {
  __typename?: 'ContactVerificationToken';
  /** The token to represent the validated contact method when registering a user */
  verifiedContactToken?: Maybe<Scalars['String']['output']>;
};

export enum Country {
  Ch = 'CH',
  Gb = 'GB'
}

export type CountryDetails = {
  __typename?: 'CountryDetails';
  country: Country;
  currency: Currency;
  languageCode: LanguageCode;
};

export type Credit = {
  __typename?: 'Credit';
  customer: Customer;
  expires?: Maybe<Scalars['DateTime']['output']>;
  originalAmount: Scalars['Float']['output'];
  referralBookingUrn?: Maybe<Scalars['String']['output']>;
  remainingAmount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  type: CreditType;
  urn: Scalars['String']['output'];
  voucherUrn?: Maybe<Scalars['String']['output']>;
};

export type CreditRefund = Refund & {
  __typename?: 'CreditRefund';
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export type CreditSpend = {
  __typename?: 'CreditSpend';
  amountUsed: Scalars['Float']['output'];
  credit: Credit;
};

export type CreditTransaction = PaymentTransaction & {
  __typename?: 'CreditTransaction';
  amount: Scalars['Float']['output'];
  credit: Credit;
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export enum CreditType {
  Acquisition = 'ACQUISITION',
  Complaint = 'COMPLAINT',
  Influencer = 'INFLUENCER',
  Migrated = 'MIGRATED',
  Referral = 'REFERRAL',
  Retention = 'RETENTION',
  Voucher = 'VOUCHER'
}

export enum Currency {
  Chf = 'CHF',
  Gbp = 'GBP'
}

export type Cursors = {
  __typename?: 'Cursors';
  after?: Maybe<Scalars['String']['output']>;
  before?: Maybe<Scalars['String']['output']>;
};

export type Customer = {
  __typename?: 'Customer';
  addresses?: Maybe<Array<Address>>;
  credits: Array<CustomerCredits>;
  creditsRemaining: Scalars['Float']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  isBlocked: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  payment: CustomerPayment;
  phone?: Maybe<Scalars['String']['output']>;
  urn: Scalars['String']['output'];
};

export type CustomerCredits = {
  __typename?: 'CustomerCredits';
  balance: Scalars['Int']['output'];
  credits: Array<Credit>;
  currency: Currency;
};

export type CustomerPayment = {
  __typename?: 'CustomerPayment';
  paymentMethods: Array<CustomerPaymentMethod>;
  stripeId: Scalars['String']['output'];
};

export type CustomerPaymentMethod = {
  __typename?: 'CustomerPaymentMethod';
  brand: Scalars['String']['output'];
  expirationMonth: Scalars['Int']['output'];
  expirationYear: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  lastFour: Scalars['String']['output'];
};

export type CustomerResponse = Customer | RuubyGraphError;

/** Customer search response */
export type CustomersResponse = CustomersResultSet | RuubyGraphError;

export type CustomersResultSet = {
  __typename?: 'CustomersResultSet';
  cursors: Cursors;
  items: Array<Customer>;
};

export type CustomersSorting = {
  direction?: InputMaybe<Scalars['String']['input']>;
  field: Scalars['String']['input'];
};

export type DateRange = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Financial = {
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export type InvoiceTransaction = PaymentTransaction & {
  __typename?: 'InvoiceTransaction';
  amount: Scalars['Float']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export enum LanguageCode {
  De = 'DE',
  En = 'EN'
}

export type MerchandisingPrice = {
  __typename?: 'MerchandisingPrice';
  isFromPrice: Scalars['Boolean']['output'];
  price: Scalars['Float']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addCredit: AddCreditResponse;
  cancelBooking?: Maybe<BookingCancelConfirmationResponse>;
  chargeBookingAdditionalPayment?: Maybe<BookingPaymentConfirmationResponse>;
  completeBookingPaymentRequest: PaymentRequestCompleteResponse;
  confirmBookingAdditionalPayment?: Maybe<BookingPaymentConfirmationResponse>;
  confirmBookingPayment?: Maybe<BookingPaymentConfirmationResponse>;
  createBooking: BookingResponse;
  createBookingAdditionalPayment?: Maybe<BookingPaymentConfirmationResponse>;
  createBookingInvoice?: Maybe<BookingInvoiceCreationResponse>;
  /** Request an OTP code to be sent to verify the customer has access to the email address */
  otpEmailRequest: OtpRequestResponse;
  /** Request an OTP code to be sent to verify the customer has access to the contact method */
  otpPhoneRequest: OtpRequestResponse;
  /** Validate a sent OTP code */
  otpValidate: SignInResponse;
  /** Register a new customer that has already validated their email */
  registerCustomerWithValidatedEmail: RegisterCustomerResponse;
  /** Register a new customer that has already validated their phone number */
  registerCustomerWithValidatedPhone: RegisterCustomerResponse;
  /** Request booking payment */
  requestBookingPayment: BookingRequestPaymentResponse;
  reserveBooking?: Maybe<BookingPaymentIntentResponse>;
  signInWithApple: RegisterCustomerResponse;
  signInWithFacebook: SignInResponse;
  signInWithGoogle: SignInResponse;
  tipBooking?: Maybe<BookingPaymentIntentResponse>;
  updateBooking: BookingResponse;
  /** Only used for server to server integration and will be removed in the future */
  useCredit?: Maybe<UseCreditConfirmationResponse>;
};


export type MutationAddCreditArgs = {
  amount: Scalars['Float']['input'];
  currency: Currency;
  customerUrn: Scalars['String']['input'];
  expires?: InputMaybe<Scalars['DateTime']['input']>;
  referralBookingUrn?: InputMaybe<Scalars['String']['input']>;
  type: CreditType;
  voucherUrn?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCancelBookingArgs = {
  bookingUrn: Scalars['String']['input'];
  customerCancellationReason?: InputMaybe<Scalars['String']['input']>;
};


export type MutationChargeBookingAdditionalPaymentArgs = {
  amount: Scalars['Float']['input'];
  bookingUrn: Scalars['String']['input'];
  paymentMethodId: Scalars['String']['input'];
};


export type MutationCompleteBookingPaymentRequestArgs = {
  paymentRequestToken: Scalars['String']['input'];
};


export type MutationConfirmBookingAdditionalPaymentArgs = {
  additionalPaymentTransactionType: AdditionalPaymentTransactionType;
  bookingUrn: Scalars['String']['input'];
  creatorUrn: Scalars['String']['input'];
  paymentAmount: Scalars['Float']['input'];
  paymentIntentId: Scalars['String']['input'];
};


export type MutationConfirmBookingPaymentArgs = {
  bookingUrn: Scalars['String']['input'];
  creatorUrn: Scalars['String']['input'];
  creditsDeduction: Scalars['Float']['input'];
  paymentAmount: Scalars['Float']['input'];
  paymentIntentId: Scalars['String']['input'];
  promotionDeduction: Scalars['Float']['input'];
  promotionUrn?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateBookingArgs = {
  addressUrn?: InputMaybe<Scalars['String']['input']>;
  bookingTreatments: Array<BookingAdminTreatmentParams>;
  customerUrn: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
  sendConfirmationEmail: Scalars['Boolean']['input'];
  status: BookingStatus;
  therapistUrn: Scalars['String']['input'];
  tier: Tier;
  timeStarts: Scalars['DateTime']['input'];
};


export type MutationCreateBookingAdditionalPaymentArgs = {
  amount: Scalars['Float']['input'];
  bookingUrn: Scalars['String']['input'];
  paymentMethodId: Scalars['String']['input'];
  returnUrl: Scalars['String']['input'];
};


export type MutationCreateBookingInvoiceArgs = {
  amount: Scalars['Float']['input'];
  bookingUrn: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};


export type MutationOtpEmailRequestArgs = {
  deviceTime: Scalars['String']['input'];
  email: Scalars['String']['input'];
};


export type MutationOtpPhoneRequestArgs = {
  deviceTime: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};


export type MutationOtpValidateArgs = {
  code: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationRegisterCustomerWithValidatedEmailArgs = {
  agreesToEmailMarketing: Scalars['Boolean']['input'];
  emailValidationToken: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  preferredRegion?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRegisterCustomerWithValidatedPhoneArgs = {
  agreesToEmailMarketing: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneValidationToken: Scalars['String']['input'];
  preferredRegion?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRequestBookingPaymentArgs = {
  bookingUrn: Scalars['String']['input'];
};


export type MutationReserveBookingArgs = {
  addressUrn?: InputMaybe<Scalars['String']['input']>;
  bookingTreatments: Array<BookingTreatmentParams>;
  channel?: InputMaybe<Channel>;
  notes?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
  rakutenSiteId?: InputMaybe<Scalars['String']['input']>;
  rakutenTimeEntered?: InputMaybe<Scalars['DateTime']['input']>;
  saveCard?: InputMaybe<Scalars['Boolean']['input']>;
  therapistUrn: Scalars['String']['input'];
  timeStarts: Scalars['DateTime']['input'];
};


export type MutationSignInWithAppleArgs = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  identityToken: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSignInWithFacebookArgs = {
  identityToken: Scalars['String']['input'];
};


export type MutationSignInWithGoogleArgs = {
  identityToken: Scalars['String']['input'];
};


export type MutationTipBookingArgs = {
  bookingUrn: Scalars['String']['input'];
  tipAmount: Scalars['Float']['input'];
};


export type MutationUpdateBookingArgs = {
  addressUrn?: InputMaybe<Scalars['String']['input']>;
  bookingUrn: Scalars['String']['input'];
  commission?: InputMaybe<Scalars['Float']['input']>;
  createBookingTreatments?: InputMaybe<Array<BookingAdminTreatmentParams>>;
  deleteBookingTreatments?: InputMaybe<Array<Scalars['String']['input']>>;
  lastTimeUpdated: Scalars['DateTime']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  percentageToTherapist?: InputMaybe<Scalars['Float']['input']>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<BookingStatus>;
  therapistUrn?: InputMaybe<Scalars['String']['input']>;
  tier?: InputMaybe<Tier>;
  timeStarts?: InputMaybe<Scalars['DateTime']['input']>;
  updateBookingTreatments?: InputMaybe<Array<BookingAdminTreatmentParams>>;
};


export type MutationUseCreditArgs = {
  amount: Scalars['Float']['input'];
  currency: Currency;
  customerUrn: Scalars['String']['input'];
};

/** Response from the otpRequest mutation */
export type OtpRequestResponse = OtpRequestSuccess | RuubyGraphError;

/** An OTP code was successfully sent */
export type OtpRequestSuccess = {
  __typename?: 'OtpRequestSuccess';
  /** The token to use when validating the OTP code */
  token: Scalars['String']['output'];
};

export type PaymentRequestComplete = {
  __typename?: 'PaymentRequestComplete';
  isSuccess: Scalars['Boolean']['output'];
};

export type PaymentRequestCompleteResponse = PaymentRequestComplete | RuubyGraphError;

export type PaymentRequestedBooking = {
  __typename?: 'PaymentRequestedBooking';
  address: Address;
  bookingTreatments: Array<BookingTreatment>;
  outstandingAmount: Scalars['Float']['output'];
  paymentIntent: Scalars['String']['output'];
  therapist: Therapist;
  timeEnds: Scalars['DateTime']['output'];
  timeStarts: Scalars['DateTime']['output'];
  urn: Scalars['String']['output'];
};

export type PaymentRequestedBookingResponse = PaymentRequestedBooking | RuubyGraphError;

export type PaymentTransaction = {
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export type PostcodeDistrict = {
  __typename?: 'PostcodeDistrict';
  code: Scalars['String']['output'];
  region: RegionDetails;
  urn: Scalars['String']['output'];
};

/** Promo code validation response */
export type PromoCodeValidationResponse = Promotion | RuubyGraphError;

export type PromoTransaction = PaymentTransaction & {
  __typename?: 'PromoTransaction';
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export type Promotion = {
  __typename?: 'Promotion';
  code: Scalars['String']['output'];
  deduction: Scalars['Float']['output'];
  deductionType: PromotionDeductionType;
  urn: Scalars['String']['output'];
};

export enum PromotionDeductionType {
  Absolute = 'ABSOLUTE',
  Percentage = 'PERCENTAGE'
}

export enum PromotionType {
  Referral = 'REFERRAL',
  ReferralReward = 'REFERRAL_REWARD',
  Voucher = 'VOUCHER'
}

export type Query = {
  __typename?: 'Query';
  booking: BookingResponse;
  /** Get booking fee amount */
  bookingFee: BookingFeeResponse;
  /** Get booking fee description */
  bookingFeeDescription: Scalars['String']['output'];
  bookings: BookingsResponse;
  customer: CustomerResponse;
  customers: CustomersResponse;
  /** Get booking for which payment is requested */
  paymentRequestedBooking: PaymentRequestedBookingResponse;
  /** Get top level treatment categories */
  rootTreatmentCategories: Array<TreatmentCategory>;
  /** Current status of the API */
  status: Status;
  /** Get a therapist by URN or slug */
  therapist: TherapistResponse;
  /** Search therapists */
  therapists: TherapistsResponse;
  /** Validates a promoCode */
  validatePromoCode: PromoCodeValidationResponse;
};


export type QueryBookingArgs = {
  id: Scalars['String']['input'];
};


export type QueryBookingFeeArgs = {
  bookingTreatments: Array<InputMaybe<BookingTreatmentParams>>;
  therapistUrn: Scalars['String']['input'];
  timeStarts: Scalars['DateTime']['input'];
};


export type QueryBookingsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<BookingsFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<BookingsSorting>>;
};


export type QueryCustomerArgs = {
  urn?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCustomersArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Array<CustomersSorting>>;
};


export type QueryPaymentRequestedBookingArgs = {
  paymentRequestToken: Scalars['String']['input'];
};


export type QueryTherapistArgs = {
  id: Scalars['String']['input'];
};


export type QueryTherapistsArgs = {
  availabilityFromTime?: InputMaybe<Scalars['DateTime']['input']>;
  availabilityToTime?: InputMaybe<Scalars['DateTime']['input']>;
  categoryUrn?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TherapistsFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<TherapistsSorting>;
  subCategoryUrns?: InputMaybe<Array<Scalars['String']['input']>>;
  treatmentUrn?: InputMaybe<Scalars['String']['input']>;
};


export type QueryValidatePromoCodeArgs = {
  bookingTreatments: Array<BookingTreatmentParams>;
  customerUrn: Scalars['String']['input'];
  promoCode: Scalars['String']['input'];
  therapistUrn: Scalars['String']['input'];
};

export type Refund = {
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export enum Region {
  Brighton = 'BRIGHTON',
  Cotswolds = 'COTSWOLDS',
  GbOther = 'GB_OTHER',
  London = 'LONDON',
  Manchester = 'MANCHESTER',
  Surrey = 'SURREY',
  Zurich = 'ZURICH'
}

export type RegionDetails = {
  __typename?: 'RegionDetails';
  country: CountryDetails;
  region: Region;
  timezone: Scalars['String']['output'];
};

/** Response from the customer registration */
export type RegisterCustomerResponse = AuthTokens | RuubyGraphError;

/** The input data of the customer who has already validated via phone OTP */
export type RegisterCustomerWithEmailInput = {
  agreesToEmailMarketing: Scalars['Boolean']['input'];
  contactValidationToken: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type Review = {
  __typename?: 'Review';
  customer?: Maybe<Customer>;
  rating: Scalars['Int']['output'];
  text?: Maybe<Scalars['String']['output']>;
  urn: Scalars['String']['output'];
};

/** Response for errors */
export type RuubyGraphError = {
  __typename?: 'RuubyGraphError';
  /** The error code */
  error: ApiError;
};

export type RuubyTakings = Financial & {
  __typename?: 'RuubyTakings';
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export type SearchBookingsResultSet = {
  __typename?: 'SearchBookingsResultSet';
  cursors: Cursors;
  items: Array<Booking>;
};

/** Response from customer sign in */
export type SignInResponse = AuthTokens | ContactVerificationToken | RuubyGraphError;

/** Current status of the API */
export type Status = {
  __typename?: 'Status';
  status: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type StripeRefund = Refund & {
  __typename?: 'StripeRefund';
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export type StripeTransaction = PaymentTransaction & {
  __typename?: 'StripeTransaction';
  amount: Scalars['Float']['output'];
  brand: Scalars['String']['output'];
  cardType?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Currency>;
  lastFour: Scalars['String']['output'];
  paymentIntentId: Scalars['String']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

/** Ruuby therapist */
export type Therapist = {
  __typename?: 'Therapist';
  /** Therapist availability */
  availability: Array<AvailabilitySlot>;
  /** The therapist's avatar image URL */
  avatarImageUrl?: Maybe<Scalars['String']['output']>;
  /** The therapist's bio */
  bio: Scalars['String']['output'];
  /** Bookings for the therapist */
  bookings: BookingsResultSet;
  /** The customer facing name for the therapist */
  displayName: Scalars['String']['output'];
  /** Therapist's PostcodeDistricts */
  districts: Array<PostcodeDistrict>;
  /** Therapist email */
  email?: Maybe<Scalars['String']['output']>;
  /** An array of URLs of the therapist's gallery images */
  galleryImageUrls: Array<Scalars['String']['output']>;
  /** Represents if the therapist is mobile */
  isMobile: Scalars['Boolean']['output'];
  /** The therapist's last booking time */
  lastBookingTime: Scalars['String']['output'];
  /** The therapist full name */
  name: Scalars['String']['output'];
  /** Therapist phone */
  phone?: Maybe<Scalars['String']['output']>;
  /** Therapist's products */
  products?: Maybe<Scalars['String']['output']>;
  /** Reviews for the therapist */
  reviews: TherapistReviewsResultSet;
  /** The therapist's tier */
  tier: TierDetails;
  /** Therapist's treatments */
  treatments: Array<TherapistTreatment>;
  /** The therapist URN */
  urn: Scalars['String']['output'];
  /** The therapist first active workstation */
  workstation?: Maybe<Scalars['Int']['output']>;
};


/** Ruuby therapist */
export type TherapistAvailabilityArgs = {
  end: Scalars['DateTime']['input'];
  ignoreLastBookingTime?: InputMaybe<Scalars['Boolean']['input']>;
  start: Scalars['DateTime']['input'];
};


/** Ruuby therapist */
export type TherapistBookingsArgs = {
  status?: InputMaybe<Array<InputMaybe<BookingStatus>>>;
};


/** Ruuby therapist */
export type TherapistReviewsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
};


/** Ruuby therapist */
export type TherapistTreatmentsArgs = {
  postcode?: InputMaybe<Scalars['String']['input']>;
};

/** Ruuby therapist response */
export type TherapistResponse = RuubyGraphError | Therapist;

export type TherapistReviewsResultSet = {
  __typename?: 'TherapistReviewsResultSet';
  cursor?: Maybe<Scalars['String']['output']>;
  items: Array<Review>;
  totalAverageRating?: Maybe<Scalars['Float']['output']>;
  totalCount: Scalars['Int']['output'];
};

export type TherapistTakings = Financial & {
  __typename?: 'TherapistTakings';
  amount: Scalars['Float']['output'];
  timeCreated: Scalars['DateTime']['output'];
  timeUpdated: Scalars['DateTime']['output'];
};

export type TherapistTreatment = {
  __typename?: 'TherapistTreatment';
  merchandisingPrice: MerchandisingPrice;
  treatment: Treatment;
};

export type TherapistsFilters = {
  availability?: InputMaybe<DateRange>;
  /** Kept for backward compatibility */
  categoryUrn?: InputMaybe<Scalars['String']['input']>;
  categoryUrns?: InputMaybe<Array<Scalars['String']['input']>>;
  districtCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** DEPRECATED: Use filters.postcode instead */
  postcode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Array<InputMaybe<Region>>>;
  subCategoryUrns?: InputMaybe<Array<Scalars['String']['input']>>;
  tier?: InputMaybe<Array<InputMaybe<Tier>>>;
  treatmentUrn?: InputMaybe<Scalars['String']['input']>;
};

/** Therapist search response */
export type TherapistsResponse = RuubyGraphError | TherapistsResultSet;

export type TherapistsResultSet = {
  __typename?: 'TherapistsResultSet';
  cursors: Cursors;
  items: Array<Therapist>;
};

export type TherapistsSorting = {
  direction?: InputMaybe<Scalars['String']['input']>;
  field: Scalars['String']['input'];
};

export enum Tier {
  BlackLabel = 'BLACK_LABEL',
  Classic = 'CLASSIC',
  Elite = 'ELITE',
  Hospitality = 'HOSPITALITY',
  OnDemand = 'ON_DEMAND',
  Productions = 'PRODUCTIONS'
}

/** Therapist tier */
export type TierDetails = {
  __typename?: 'TierDetails';
  /** The tier description */
  description: Scalars['String']['output'];
  /** The tier name */
  name: Scalars['String']['output'];
  /** The tier type */
  tier: Tier;
};

export type Treatment = {
  __typename?: 'Treatment';
  categories: Array<TreatmentCategory>;
  description?: Maybe<Scalars['String']['output']>;
  duration: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  urn: Scalars['String']['output'];
};

export type TreatmentCategory = {
  __typename?: 'TreatmentCategory';
  name: Scalars['String']['output'];
  parent?: Maybe<TreatmentCategory>;
  urn: Scalars['String']['output'];
};

export type UseCreditConfirmation = {
  __typename?: 'UseCreditConfirmation';
  creditUsed: Array<CreditSpend>;
};

export type UseCreditConfirmationResponse = RuubyGraphError | UseCreditConfirmation;

export type RequestBookingPaymentMutationVariables = Exact<{
  bookingUrn: Scalars['String']['input'];
}>;


export type RequestBookingPaymentMutation = { __typename?: 'Mutation', requestBookingPayment: { __typename: 'BookingRequestPayment', paymentRequestToken: string } | { __typename: 'RuubyGraphError', error: ApiError } };

export type ChargeBookingAdditionalPaymentMutationVariables = Exact<{
  bookingUrn: Scalars['String']['input'];
  amount: Scalars['Float']['input'];
  paymentMethodId: Scalars['String']['input'];
}>;


export type ChargeBookingAdditionalPaymentMutation = { __typename?: 'Mutation', chargeBookingAdditionalPayment?: { __typename: 'BookingPaymentConfirmation', status: boolean } | { __typename?: 'RuubyGraphError' } | null };

export type CustomerPaymentMethodsQueryVariables = Exact<{
  urn?: InputMaybe<Scalars['String']['input']>;
}>;


export type CustomerPaymentMethodsQuery = { __typename?: 'Query', customer: { __typename?: 'Customer', urn: string, payment: { __typename?: 'CustomerPayment', stripeId: string, paymentMethods: Array<{ __typename?: 'CustomerPaymentMethod', brand: string, expirationMonth: number, expirationYear: number, id: string, lastFour: string }> } } | { __typename?: 'RuubyGraphError', error: ApiError } };

export type CustomerCreditsQueryVariables = Exact<{
  urn?: InputMaybe<Scalars['String']['input']>;
}>;


export type CustomerCreditsQuery = { __typename?: 'Query', customer: { __typename?: 'Customer', creditsRemaining: number, credits: Array<{ __typename?: 'CustomerCredits', currency: Currency, balance: number, credits: Array<{ __typename?: 'Credit', urn: string, type: CreditType, remainingAmount: number, originalAmount: number, expires?: any | null, voucherUrn?: string | null, timeCreated: any }> }> } | { __typename?: 'RuubyGraphError' } };

export type AddCustomerCreditMutationVariables = Exact<{
  customerUrn: Scalars['String']['input'];
  currency: Currency;
  amount: Scalars['Float']['input'];
  type: CreditType;
  expires?: InputMaybe<Scalars['DateTime']['input']>;
  voucherUrn?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddCustomerCreditMutation = { __typename?: 'Mutation', addCredit: { __typename?: 'Credit', remainingAmount: number } | { __typename?: 'RuubyGraphError' } };


export const RequestBookingPaymentDocument = gql`
    mutation RequestBookingPayment($bookingUrn: String!) {
  requestBookingPayment(bookingUrn: $bookingUrn) {
    __typename
    ... on BookingRequestPayment {
      paymentRequestToken
    }
    ... on RuubyGraphError {
      error
    }
  }
}
    `;
export type RequestBookingPaymentMutationFn = Apollo.MutationFunction<RequestBookingPaymentMutation, RequestBookingPaymentMutationVariables>;

/**
 * __useRequestBookingPaymentMutation__
 *
 * To run a mutation, you first call `useRequestBookingPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestBookingPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestBookingPaymentMutation, { data, loading, error }] = useRequestBookingPaymentMutation({
 *   variables: {
 *      bookingUrn: // value for 'bookingUrn'
 *   },
 * });
 */
export function useRequestBookingPaymentMutation(baseOptions?: Apollo.MutationHookOptions<RequestBookingPaymentMutation, RequestBookingPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestBookingPaymentMutation, RequestBookingPaymentMutationVariables>(RequestBookingPaymentDocument, options);
      }
export type RequestBookingPaymentMutationHookResult = ReturnType<typeof useRequestBookingPaymentMutation>;
export type RequestBookingPaymentMutationResult = Apollo.MutationResult<RequestBookingPaymentMutation>;
export type RequestBookingPaymentMutationOptions = Apollo.BaseMutationOptions<RequestBookingPaymentMutation, RequestBookingPaymentMutationVariables>;
export const ChargeBookingAdditionalPaymentDocument = gql`
    mutation ChargeBookingAdditionalPayment($bookingUrn: String!, $amount: Float!, $paymentMethodId: String!) {
  chargeBookingAdditionalPayment(
    bookingUrn: $bookingUrn
    amount: $amount
    paymentMethodId: $paymentMethodId
  ) {
    ... on BookingPaymentConfirmation {
      __typename
      status
    }
  }
}
    `;
export type ChargeBookingAdditionalPaymentMutationFn = Apollo.MutationFunction<ChargeBookingAdditionalPaymentMutation, ChargeBookingAdditionalPaymentMutationVariables>;

/**
 * __useChargeBookingAdditionalPaymentMutation__
 *
 * To run a mutation, you first call `useChargeBookingAdditionalPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChargeBookingAdditionalPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chargeBookingAdditionalPaymentMutation, { data, loading, error }] = useChargeBookingAdditionalPaymentMutation({
 *   variables: {
 *      bookingUrn: // value for 'bookingUrn'
 *      amount: // value for 'amount'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useChargeBookingAdditionalPaymentMutation(baseOptions?: Apollo.MutationHookOptions<ChargeBookingAdditionalPaymentMutation, ChargeBookingAdditionalPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChargeBookingAdditionalPaymentMutation, ChargeBookingAdditionalPaymentMutationVariables>(ChargeBookingAdditionalPaymentDocument, options);
      }
export type ChargeBookingAdditionalPaymentMutationHookResult = ReturnType<typeof useChargeBookingAdditionalPaymentMutation>;
export type ChargeBookingAdditionalPaymentMutationResult = Apollo.MutationResult<ChargeBookingAdditionalPaymentMutation>;
export type ChargeBookingAdditionalPaymentMutationOptions = Apollo.BaseMutationOptions<ChargeBookingAdditionalPaymentMutation, ChargeBookingAdditionalPaymentMutationVariables>;
export const CustomerPaymentMethodsDocument = gql`
    query CustomerPaymentMethods($urn: String) {
  customer(urn: $urn) {
    ... on Customer {
      urn
      payment {
        stripeId
        paymentMethods {
          brand
          expirationMonth
          expirationYear
          id
          lastFour
        }
      }
    }
    ... on RuubyGraphError {
      error
    }
  }
}
    `;

/**
 * __useCustomerPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useCustomerPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerPaymentMethodsQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useCustomerPaymentMethodsQuery(baseOptions?: Apollo.QueryHookOptions<CustomerPaymentMethodsQuery, CustomerPaymentMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerPaymentMethodsQuery, CustomerPaymentMethodsQueryVariables>(CustomerPaymentMethodsDocument, options);
      }
export function useCustomerPaymentMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerPaymentMethodsQuery, CustomerPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerPaymentMethodsQuery, CustomerPaymentMethodsQueryVariables>(CustomerPaymentMethodsDocument, options);
        }
export function useCustomerPaymentMethodsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CustomerPaymentMethodsQuery, CustomerPaymentMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustomerPaymentMethodsQuery, CustomerPaymentMethodsQueryVariables>(CustomerPaymentMethodsDocument, options);
        }
export type CustomerPaymentMethodsQueryHookResult = ReturnType<typeof useCustomerPaymentMethodsQuery>;
export type CustomerPaymentMethodsLazyQueryHookResult = ReturnType<typeof useCustomerPaymentMethodsLazyQuery>;
export type CustomerPaymentMethodsSuspenseQueryHookResult = ReturnType<typeof useCustomerPaymentMethodsSuspenseQuery>;
export type CustomerPaymentMethodsQueryResult = Apollo.QueryResult<CustomerPaymentMethodsQuery, CustomerPaymentMethodsQueryVariables>;
export const CustomerCreditsDocument = gql`
    query customerCredits($urn: String) {
  customer(urn: $urn) {
    ... on Customer {
      creditsRemaining
      credits {
        credits {
          urn
          type
          remainingAmount
          originalAmount
          expires
          voucherUrn
          timeCreated
        }
        currency
        balance
      }
    }
  }
}
    `;

/**
 * __useCustomerCreditsQuery__
 *
 * To run a query within a React component, call `useCustomerCreditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerCreditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerCreditsQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useCustomerCreditsQuery(baseOptions?: Apollo.QueryHookOptions<CustomerCreditsQuery, CustomerCreditsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerCreditsQuery, CustomerCreditsQueryVariables>(CustomerCreditsDocument, options);
      }
export function useCustomerCreditsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerCreditsQuery, CustomerCreditsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerCreditsQuery, CustomerCreditsQueryVariables>(CustomerCreditsDocument, options);
        }
export function useCustomerCreditsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CustomerCreditsQuery, CustomerCreditsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustomerCreditsQuery, CustomerCreditsQueryVariables>(CustomerCreditsDocument, options);
        }
export type CustomerCreditsQueryHookResult = ReturnType<typeof useCustomerCreditsQuery>;
export type CustomerCreditsLazyQueryHookResult = ReturnType<typeof useCustomerCreditsLazyQuery>;
export type CustomerCreditsSuspenseQueryHookResult = ReturnType<typeof useCustomerCreditsSuspenseQuery>;
export type CustomerCreditsQueryResult = Apollo.QueryResult<CustomerCreditsQuery, CustomerCreditsQueryVariables>;
export const AddCustomerCreditDocument = gql`
    mutation addCustomerCredit($customerUrn: String!, $currency: Currency!, $amount: Float!, $type: CreditType!, $expires: DateTime, $voucherUrn: String) {
  addCredit(
    customerUrn: $customerUrn
    currency: $currency
    amount: $amount
    type: $type
    expires: $expires
    voucherUrn: $voucherUrn
  ) {
    ... on Credit {
      remainingAmount
    }
  }
}
    `;
export type AddCustomerCreditMutationFn = Apollo.MutationFunction<AddCustomerCreditMutation, AddCustomerCreditMutationVariables>;

/**
 * __useAddCustomerCreditMutation__
 *
 * To run a mutation, you first call `useAddCustomerCreditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomerCreditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomerCreditMutation, { data, loading, error }] = useAddCustomerCreditMutation({
 *   variables: {
 *      customerUrn: // value for 'customerUrn'
 *      currency: // value for 'currency'
 *      amount: // value for 'amount'
 *      type: // value for 'type'
 *      expires: // value for 'expires'
 *      voucherUrn: // value for 'voucherUrn'
 *   },
 * });
 */
export function useAddCustomerCreditMutation(baseOptions?: Apollo.MutationHookOptions<AddCustomerCreditMutation, AddCustomerCreditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCustomerCreditMutation, AddCustomerCreditMutationVariables>(AddCustomerCreditDocument, options);
      }
export type AddCustomerCreditMutationHookResult = ReturnType<typeof useAddCustomerCreditMutation>;
export type AddCustomerCreditMutationResult = Apollo.MutationResult<AddCustomerCreditMutation>;
export type AddCustomerCreditMutationOptions = Apollo.BaseMutationOptions<AddCustomerCreditMutation, AddCustomerCreditMutationVariables>;