import * as React from "react";
import Switch from "react-bootstrap-switch";

import { CreateState } from "../../../reducers/treatments/reducer";
import { CategoryPresenter } from "../../../presenters/category";
import { TreatmentUpdateField, TreatmentUpdateValue } from "../../../api/main/treatment";
import { InputTitle } from "../../atoms/input-title";
import { Button } from "../../atoms/button";
import { LabelledInputTextArea } from "../../molecules/labelled-input-text-area";
import { LabelledInputText } from "../../molecules/labelled-input-text";
import { Row, Container, Column } from "../../grid";
import { SpinnerButton } from "../../atoms/spinner-button";

const FormRow = Row.extend`
  margin: 5px 0;
`;

interface Props {
  categories: {
    [id: string]: CategoryPresenter;
  };
  categoriesList: CategoryPresenter[];
  createState: CreateState;
  isCreating: boolean;
  onUpdateField(field: TreatmentUpdateField, value: TreatmentUpdateValue): void;
  onSubmit(): void;
}

export const TreatmentCreate: React.StatelessComponent<Props> = props => {
  return (
    <Container>
      <FormRow>
        <Column lg="12">
          <InputTitle
            value={props.createState[TreatmentUpdateField.NAME as string]}
            placeholder="Treatment name"
            onChange={(value: string) => props.onUpdateField(TreatmentUpdateField.NAME, value)}
          />
        </Column>
      </FormRow>

      <FormRow>
        <Column lg="12">
          <LabelledInputTextArea
            label="Description"
            value={props.createState[TreatmentUpdateField.DESCRIPTION as string]}
            onChange={(value: string) => props.onUpdateField(TreatmentUpdateField.DESCRIPTION, value)}
          />
        </Column>
      </FormRow>
      <FormRow>
        <Column lg="12">
          <div className="detail-heading">
            Add-on Treatment
          </div>
          <div className="detail-value">
            <Switch value={Boolean(props.createState[TreatmentUpdateField.IS_ADDON])}
              onChange={() => props.onUpdateField(TreatmentUpdateField.IS_ADDON, !props.createState[TreatmentUpdateField.IS_ADDON])}
              onText="Yes"
              offText="No"
              bsSize="small" 
            />            
          </div>    
        </Column>
      </FormRow>
      <FormRow>
        <Column lg="3">
          <LabelledInputText
            label="London Price Classic"
            value={props.createState[TreatmentUpdateField.LONDON_PRICE_CLASSIC].toString()}
            onChange={(value: string) => props.onUpdateField(TreatmentUpdateField.LONDON_PRICE_CLASSIC, value)}
          />
        </Column>
        <Column lg="3">
          <LabelledInputText
            label="London Price Elite"
            value={props.createState[TreatmentUpdateField.LONDON_PRICE_ELITE].toString()}
            onChange={(value: string) => props.onUpdateField(TreatmentUpdateField.LONDON_PRICE_ELITE, value)}
          />
        </Column>
        <Column lg="3">
          <LabelledInputText
            label="London Price Celebrity"
            value={props.createState[TreatmentUpdateField.LONDON_PRICE_BLACK_LABEL].toString()}
            onChange={(value: string) => props.onUpdateField(TreatmentUpdateField.LONDON_PRICE_BLACK_LABEL, value)}
          />
        </Column>
        <Column lg="3">
          <LabelledInputText
            label="London Price Hospitality"
            value={props.createState[TreatmentUpdateField.LONDON_PRICE_HOSPITALITY].toString()}
            onChange={(value: string) => props.onUpdateField(TreatmentUpdateField.LONDON_PRICE_HOSPITALITY, value)}
          />
        </Column>               
      </FormRow>
      <FormRow>
        <Column lg="3">
          <LabelledInputText
            label="Duration"
            value={props.createState[TreatmentUpdateField.DURATION].toString()}
            onChange={(value: string) => props.onUpdateField(TreatmentUpdateField.DURATION, value)}
          />
        </Column>
      </FormRow>
      <FormRow>
        <Column lg="12">
          {
            props.isCreating ?
            <SpinnerButton />
            :
            <Button
              label="Submit"
              onClick={props.onSubmit}
            />
          }
        </Column>
      </FormRow>
    </Container>
  );
};
