import * as React from "react";
import { Link } from "react-router-dom";
import { config } from "../../../config";

interface BookingSubMenuProps {
  urn: string;
  active: "details" | "chat" | "audit" | "notes" | "statements";
  notesCount: number;
  chatUrl?: string;
}

export const BookingSubMenu: React.SFC<BookingSubMenuProps> = ({ urn, active, notesCount, chatUrl }) => (
  <div className="list-group">
    <Link to={`/bookings/${urn}`} className={active === "details" ? "list-group-item active" : "list-group-item"}>Details</Link>
    <Link to={`/bookings/${urn}/activity`} className={active === "audit" ? "list-group-item active" : "list-group-item"}>Audit</Link>
    <Link to={`/bookings/${urn}/notes`} className={active === "notes" ? "list-group-item active" : "list-group-item"}>
      {(notesCount > 0) && <span className="badge">{notesCount}</span>}
      Notes
    </Link>
    <Link to={`/bookings/${urn}/statements`} className={active === "statements" ? "list-group-item active" : "list-group-item"}>Statements</Link>
    {
      chatUrl 
      ?
      <a 
        href={`https://dashboard.sendbird.com/${config.sendbird.appId}/group-channels/${chatUrl}`}
        target="_blank"
        className="list-group-item"
      >
        Chat
      </a>
      :
      <a
        className="list-group-item disabled"
      >
        Chat
      </a>      
    }
  </div>
);

interface CustomerSubmenuProps {
  urn: string;
  active: "general" | "addresses" | "cards" | "credit" | "referrals"; // | "clients";
  onClickBookings(): void;
}

export const CustomerSubmenu: React.SFC<CustomerSubmenuProps> = ({ urn, active, onClickBookings }) => (
  <div className="list-group">
    <Link to={`/customers/${urn}/general`} className={active === "general" ? "list-group-item active" : "list-group-item"}>General</Link>
    <Link to={`/customers/${urn}/addresses`} className={active === "addresses" ? "list-group-item active" : "list-group-item"}>Addresses</Link>
    <Link to={`/customers/${urn}/credit`} className={active === "credit" ? "list-group-item active" : "list-group-item"}>Credit</Link>
    <Link to="/bookings" onClick={onClickBookings} className="list-group-item">Bookings</Link>
    <Link to={`/customers/${urn}/referrals`} className={active === "referrals" ? "list-group-item active" : "list-group-item"}>Referrals</Link>
    {/* <Link to={`/customers/${urn}/clients`} className={active === "clients" ? "list-group-item active" : "list-group-item"}>Clients</Link> */}
  </div>
);

export const CustomerPartnerSubmenu: React.SFC<CustomerSubmenuProps> = ({ urn, active, onClickBookings }) => (
  <div className="list-group">
    <Link to={`/customers/${urn}/general`} className={active === "general" ? "list-group-item active" : "list-group-item"}>General</Link>
    <Link to="/bookings" onClick={onClickBookings} className="list-group-item">Bookings</Link>
    <Link to={`/customers/${urn}/referrals`} className={active === "referrals" ? "list-group-item active" : "list-group-item"}>Referrals</Link>
    {/* <Link to={`/customers/${urn}/clients`} className={active === "clients" ? "list-group-item active" : "list-group-item"}>Clients</Link> */}
  </div>
);

interface TherapistSubmenuProps {
  urn: string;
  active: "details" | "images" | "treatments" | "availability" | "postcodes" | "chat" | "reviews" | "tags" ;
  isPartner?: boolean;
  isTherapistActive?: boolean;
  onClickBookings(): void;
}

interface NavigationTab {
  label: string;
  url: string;
  isEnabled: boolean;
  onClick?(): void;
};

export const TherapistSubMenu: React.SFC<TherapistSubmenuProps> = ({ urn, active, isPartner, onClickBookings }) => {

  const elements: NavigationTab[] = [
    { label: "Details", url: "", isEnabled: true, },
    { label: "Images", url: "images", isEnabled: true, },
    { label: "Treatments", url: "treatments", isEnabled: !isPartner, },
    { label: "Availability", url: "availability", isEnabled: !isPartner, },
    { label: "Postcodes", url: "postcodes", isEnabled: !isPartner, },
    { label: "Chat", url: "chat", isEnabled: !isPartner, },
    { label: "Bookings", url: "/bookings", isEnabled: !isPartner, onClick: onClickBookings },
    { label: "Reviews", url: "reviews", isEnabled: !isPartner, },
    { label: "Tags", url: "tags", isEnabled: !isPartner, },
  ];

  const tabs = elements.map(item => {
    if (!item.isEnabled) return;

    return <Link
      to={item.onClick ? item.url : `/therapists/${urn}/${item.url}`}
      onClick={onClickBookings}
      className={active === `${item.label.toLowerCase()}` ? "list-group-item active" : "list-group-item"}>{item.label}</Link>
  });

  return (
    <div className="list-group">
      {tabs}
    </div>
  )
}
