import * as types from "./types";
import {ActionDispatch} from "../";

 const actionCreators = {
  editInProgress: () => ({
    type: types.EDIT_IN_PROGRESS,
    payload: { isEditing: true }
  }),
  editComplete: () => ({
    type: types.EDIT_COMPLETE,
    payload: { isEditing: false }
  }),
};

export function setEditInProgress(): ActionDispatch {
  return dispatch => {
    dispatch(actionCreators.editInProgress);
  };
}

export function setEditComplete(): ActionDispatch {
  return dispatch => {
    dispatch(actionCreators.editComplete);
  };
}
