import iassign from "immutable-assign";
import * as moment from "moment";

import {apiActivityActionCreators} from "./actions";
import * as types from "./types";

type Action = ReturnType<typeof apiActivityActionCreators[keyof typeof apiActivityActionCreators]>;

interface ApiActivityDetail {
  hasLoaded: boolean;
  isLoading: boolean;
  error: string;
  lastFetched: string;
}

// tslint:disable-next-line:interface-over-type-literal
export type ApiActivityState = {
  [resource: number]: ApiActivityDetail;
};

const emptyActivity: ApiActivityDetail = {
  hasLoaded: false,
  isLoading: false,
  error: undefined,
  lastFetched: undefined,
};

export enum ApiActivity {
  CHAT_FETCH,
}

const INITIAL_STATE: ApiActivityState = {};
INITIAL_STATE[ApiActivity.CHAT_FETCH] = emptyActivity;

export function apiActivityReducer(state: ApiActivityState = INITIAL_STATE, action: Action): ApiActivityState {
  switch (action.type) {
    case types.FETCH_ATTEMPT: {
      return iassign(
        setActivityInState(state, action.payload.resource),
        (s, ctx) => s[ctx.payload.resource],
        r => {
          r.hasLoaded = false;
          r.isLoading = true;
          r.error = undefined;

          return r;
        },
        {payload: action.payload},
      );
    }

    case types.FETCH_SUCCESS: {
      return iassign(
        setActivityInState(state, action.payload.resource),
        (s, ctx) => s[ctx.payload.resource],
        r => {
          r.isLoading = false;
          r.hasLoaded = true;
          r.lastFetched = moment().toISOString();

          return r;
        },
        {payload: action.payload},
      );
    }

    case types.FETCH_FAILURE: {
      return iassign(
        setActivityInState(state, action.payload.resource),
        (s, ctx) => s[ctx.payload.resource],
        r => {
          r.isLoading = false;
          r.error = action.payload.error.message;

          return r;
        },
        {payload: action.payload},
      );
    }

    default: {
      return state;
    }
  }
}

function setActivityInState(state: ApiActivityState, id: ApiActivity) {
  return iassign(
    state,
    s => {
      if (typeof s[id] === "undefined") {
        s[id] = emptyActivity;
      }

      return s;
    },
  );
}
