import styled from "styled-components";

import { MixinEditableField } from "../mixin-editable-field";
import { InputTitle } from "../../atoms/input-title";
import { Title } from "../../atoms/title";

const Container = styled.div`
  margin: 20px 0 10px 0;
  width: 100%;
  display: inline-flex;
  flex-wrap: nowrap;
`;

export class EditableTitle extends MixinEditableField {
  render(): JSX.Element {
    if (this.props.isEditing) {
      return (
        <Container>
          <InputTitle
            value={this.state.editingValue}
            onChange={(editingValue: string) => this.setState({...this.state, editingValue})}
          />
          {this.renderButtons()}
        </Container>
      );
    }
    else {
      return <Title>{this.props.value} {this.renderEditIcon()}</Title>;
    }
  }
}
