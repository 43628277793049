import * as React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import * as Spinner from "react-spinkit";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { TherapistSubMenu } from "../../components/sub/sub-menu";
import { TherapistDetails } from "../../components/therapist/therapist-details";
import { RootState } from "../../reducers";
import { ApiTherapist } from "../../api/main/therapist";
import { fetchTherapist } from "../../model/therapist";
import { resetSearch, searchTherapist } from "../../reducers/bookings/actions";
import { PartnerTherapistDetails } from "../../components/therapist/partner-therapist-details";

interface ReduxProps {
  therapist: ApiTherapist;
  isPartner: boolean;
}

type OwnProps = RouteComponentProps<{ urn: string }>;
type Props = ReduxProps & OwnProps & { dispatch: Dispatch<{}> };

class Component extends React.Component<Props> {
  componentDidMount() {
    if (!this.props.therapist) {
      this.props.dispatch(fetchTherapist(`${this.props.match.params.urn}-1`));
    }
  }

  handleClickBookings = (): void => {
    this.props.dispatch(resetSearch());
    this.props.dispatch(searchTherapist(this.props.therapist.urn));
  };

  render() {
    const { therapist } = this.props;

    return (
      <div className="therapist-container">
        {therapist && this.props.isPartner ? (
          <div className="main-section">
            <PartnerTherapistDetails
              therapist={therapist}
              dispatch={this.props.dispatch}
            />
          </div>
        ) : (
          <div></div>
        )}

        {therapist && !this.props.isPartner && (
          <div className="main-section">
            <TherapistDetails
              therapist={therapist}
              dispatch={this.props.dispatch}
            />
          </div>
        )}
        {!therapist && (
          <div className="spinner-container main-section">
            <Spinner name="pulse" fadeIn="none" />
          </div>
        )}

        <div className="side-nav">
          <TherapistSubMenu
            urn={this.props.match.params.urn}
            active="details"
            isPartner={this.props.isPartner}
            onClickBookings={this.handleClickBookings}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const therapist = state.therapistsState.therapists[ownProps.match.params.urn];
  const isPartner =
    state.operator.urn && state.operator.urn.startsWith("urn:ruuby:partner:");

  return {
    therapist: therapist ? therapist : null,
    isPartner,
  };
};

export const TherapistDetailsContainer = withRouter(
  connect<ReduxProps, {}, OwnProps>(mapStateToProps)(Component),
);
