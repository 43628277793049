import * as React from "react";
import * as sprintfJs from "sprintf-js";
import * as Select from "react-select";

const {sprintf} = sprintfJs;

type EditFieldBase = {
  dbKey: string;
  readOnly?: boolean;
  saveHandler: (key: string, value: any) => void;
}

type EditFieldProps =
  | (EditFieldBase & {
      type: "select";
      value: Select.ReactSelectProps['value'];
      options: Select.Option[];
    })
  | (EditFieldBase & {
      type: "price";
      value: number;
    })
  | (EditFieldBase & {
      type: "percentage";
      value: number;
      maxValue?: number;
    })
  | (EditFieldBase & {
      type: "textarea";
      value: string;
    })
  | (EditFieldBase & {
      type: "text"
      value: string;
    });

interface EditFieldState {
  visible?: boolean;
  value?: any;
}

export default class EditField extends React.Component<EditFieldProps, EditFieldState> {
  constructor(props: EditFieldProps) {
    super(props);
    this.state = {
      visible: false,
      value: props.value
    };
  }

  componentDidUpdate(_prevProps: EditFieldProps, prevState: EditFieldState) {
    if (this.state.visible !== prevState.visible &&
      this.state.value !== this.props.value) {
        this.setState({value: this.props.value});
    }
  }

  handleInputChange = (e: any): void => {
    this.setState({value: e.target.value});
  }
  
  handlePercentageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const percentage = Math.max(Number(e.target.value), 0);
    const value = ("maxValue" in this.props && typeof this.props.maxValue !== "undefined") 
      ? Math.min(percentage, this.props.maxValue) 
      : percentage;
    
    this.setState({ value });
  }

  editHandler = (): void => {
    this.setState({visible: true});
  }

  editCancelHandler = (): void => {
    this.setState({
      visible: false,
      value: this.props.value
    });
  }

  saveHandler = (): void => {
    this.props.saveHandler(this.props.dbKey, this.state.value);
    this.editCancelHandler();
  }

  renderValue = (): string => {
    const {type} = this.props;
    const value = (this.state.value !== this.props.value) ? this.props.value : this.state.value;

    switch (type) {
      case "price":
        return sprintf("£%0.2f", value);
      case "percentage":
        return `${value}%`;
      case "select":
        const option = this.props.options.find(o => o.value === value);
        if (typeof option !== "undefined") {
          return option.label;
        }

        return undefined ;
      case "text":
      case "textarea":
        return value;
    }
  }

  renderInput = (): JSX.Element => {
    const {type} = this.props;
    const value = this.state.value;

    switch (type) {
      case "price":
        return (
          <input readOnly={this.props.readOnly} type="number" min="0" className="form-control" defaultValue={value} onChange={this.handleInputChange} />
        );

      case "select":
        return (
          <Select
            value={value}
            clearable={false}
            options={this.props.options}
            onChange={(option: Select.Option) => this.setState({value: option.value})}
          />
        );

      case "percentage":
        return (
          <input 
            readOnly={this.props.readOnly} 
            type="number" 
            min="0" 
            className="form-control" 
            defaultValue={value} 
            onChange={this.handlePercentageInputChange} 
            {...(this.props.maxValue && {max: this.props.maxValue})}
          />
        );

      case "textarea":
        return (
          <textarea readOnly={this.props.readOnly} className="form-control" onChange={this.handleInputChange}>{value}</textarea>
        );

      case "text":
        return (
          <input readOnly={this.props.readOnly} type="text" className="form-control" defaultValue={value} onChange={this.handleInputChange} />
        );
    }
  }

  render() {
    return (this.state.visible) ? (
      <div className="field-edit">
        <div className="input-box">
          {this.renderInput()}
          {!this.props.readOnly ?
            <>
              <button className="btn btn-submit" type="button" onClick={this.saveHandler}>
                <span className="fa fa-check text-success" aria-hidden="true" />
              </button>
              <button className="btn btn-cancel" type="submit" onClick={this.editCancelHandler}>
                <span className="fa fa-times" aria-hidden="true" />
              </button>
            </>
            : null
          }
        </div>
      </div>
    ) : (
      <div className="field-edit">
        <p className="value">
          {this.renderValue()}
          {!this.props.readOnly ?
            <span 
              onClick={this.editHandler} 
              className="edit-icon edit-icon-hover fa fa-pencil">
            </span>
            : null
          }
        </p>
      </div>
    );
  }
}
