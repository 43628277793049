import * as React from "react";
import { Table } from "react-bootstrap";
import EditField from "../../../components/sub/edit-field";
import { ApiAddress } from "../../../api/main/booking";
import { ApiCustomer } from "../../../api/main/customer";
import { NOTIFICATION_TYPES } from "../../../reducers/notifications/types";

interface AddressesProps {
  customer: ApiCustomer;
  fetchAddresses: (urn: string) => void;
  updateCustomerAddress: (addressUrn: string, key: string, value: string) => void;
  deleteCustomerAddress: (addressUrn: string, index: number) => void;
  addCustomerAddress: (form: any) => void;
  displayNotification: (type: string, headline: string, message: string) => void;
}

// NOTE: Copy of @ruuby/common/src/utils/region/index.ts:Country.
// Keep up to date until switch to new admin-panel.
export enum Country {
  CH = "CH",
  GB = "GB",
}

const CountryDisplay: Record<Country, string> = {
  [Country.CH]: "Switzerland",
  [Country.GB]: "United Kingdom",
} as const;


interface State {
  urn?: string;
  editingAddress1?: boolean;
  editingAddress2?: boolean;
  editingPostcode?: boolean;
  newForm: {
    address1?: string;
    address2?: string;
    postcode?: string;
    country?: string;
  };
  showForm?: boolean;
}

const defaultAddressState = {
  address1: "",
  address2: "",
  postcode: "",
  country: Country.GB
};

export default class Addresses extends React.Component<AddressesProps, State> {
  state: State = {
    urn: "",
    editingAddress1: false,
    editingAddress2: false,
    editingPostcode: false,
    newForm: {
      ...defaultAddressState
    },
    showForm: false
  };

  componentWillMount() {
    this.props.fetchAddresses(this.props.customer.urn);
  }

  toggleFormVisibility = (): void => {
    this.setState({
      newForm: {...defaultAddressState},
      showForm: !this.state.showForm
    });
  }

  handleNewAddressInputs = (key: string, event: any): void => {
    let obj = Object.assign({}, this.state.newForm);
    obj[key] = event.target.value;

    this.setState({newForm: obj});
  }

  handleAddAddress = (): void => {
    const { address1, postcode } = this.state.newForm;

    if (!address1 || !postcode) {
      this.props.displayNotification(
        NOTIFICATION_TYPES.danger,
        "Error creating address",
        "Address1 and Postcode are required."
      );
      return;
    }

    this.props.addCustomerAddress(this.state.newForm);
    this.toggleFormVisibility();
  }

  newAddressRow = (): JSX.Element => {
    return (
      <tr key={-1}>
        <td><input type="text" value={this.state.newForm.address1} onChange={this.handleNewAddressInputs.bind(this, "address1")} /></td>
        <td><input type="text" value={this.state.newForm.address2} onChange={this.handleNewAddressInputs.bind(this, "address2")} /></td>
        <td><input type="text" value={this.state.newForm.postcode} onChange={this.handleNewAddressInputs.bind(this, "postcode")} /></td>
        <td>
          <select
            value={this.state.newForm.country}
            onChange={this.handleNewAddressInputs.bind(this, "country")}
          >
             {Object.entries(CountryDisplay).map(([code, country]) => (
              <option key={country} value={code}>
                {country}
              </option>
            ))}
          </select>
        </td>
        <td>
          <div className="field-edit">
            <div className="input-box">
              <button className="btn" type="button" onClick={this.handleAddAddress}><span className="fa fa-check text-success" aria-hidden="true" /></button>
              <button className="btn btn-cancel" type="button" onClick={this.toggleFormVisibility}><span className="fa fa-times" aria-hidden="true" /></button>
            </div>
          </div>
        </td>
      </tr>
    );
  }

  saveAddressHandler = (addressUrn: string, key: string, value: string): void => {
    this.props.updateCustomerAddress(addressUrn, key, value);
  }

  renderAddresses(): JSX.Element[] {
    let addresses = this.props.customer.addresses;

    addresses = (addresses) ?
      addresses.reduce((addrsArray: ApiAddress[], address: ApiAddress) => {
        if (!address.isDeleted)
          addrsArray.push(address);

        return addrsArray;
      }, []) : [];

    const addrs: JSX.Element[] = (addresses as any).map((address: ApiAddress, i: number) =>
      <tr key={i}>
        <td>
          <EditField dbKey="address1" type="text" value={address.address1} saveHandler={(key, value) => this.saveAddressHandler(address.urn, key, value)} />
        </td>
        <td>
          <EditField dbKey="address2" type="text" value={address.address2} saveHandler={(key, value) => this.saveAddressHandler(address.urn, key, value)} />
        </td>
        <td>
          <EditField dbKey="postcode" type="text" value={address.postcode} saveHandler={(key, value) => this.saveAddressHandler(address.urn, key, value)} />
        </td>
        <td>
          <span>{CountryDisplay[address.country]}</span>
        </td>
        <td>
          <button className="btn-delete" aria-hidden="true" onClick={() => this.props.deleteCustomerAddress(address.urn, i)}><i className="fa fa-trash"></i></button>
        </td>
      </tr>
    );

    if (this.state.showForm) addrs.unshift(this.newAddressRow());

    return addrs;
  }

  render(): JSX.Element {
    const addresses = this.props.customer.addresses;
    const addrCount = addresses ? addresses.filter(a => !a.isDeleted).length : 100;

    return (
      <div className="app-table main-details-lg addresses">
        <div className="details-row">
          <button className="btn new-booking-btn btn-primary" disabled={(addrCount > 2) ? true : false} onClick={this.toggleFormVisibility}><i className="fa fa-plus"></i> New Address</button>
          <Table>
            <colgroup>
              <col width="22.5%" />
              <col width="22.5%" />
              <col width="22.5%" />
              <col width="22.5%" />
              <col width="10%" />
            </colgroup>

            <thead>
              <tr>
                <th>Address 1</th>
                <th>Address 2</th>
                <th>Postal Code</th>
                <th>Country</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {this.props.customer.addresses && this.renderAddresses()}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}
