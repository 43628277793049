import * as React from "react";
import EditField from "../../sub/edit-field";
import { CustomerUpdateField, CustomerUpdateValue } from "../../../reducers/customers/actions";
import { ApiCustomer } from "../../../api/main/customer";

interface GeneralProps {
  customer: ApiCustomer;
  updateCustomer: (key: CustomerUpdateField, value: CustomerUpdateValue) => void;
}

export default class GeneralPartner extends React.Component<GeneralProps, null> {
  constructor(props: GeneralProps) {
    super(props);

    this.saveHandler = this.saveHandler.bind(this);
  }

  saveHandler(key: CustomerUpdateField, value: CustomerUpdateValue) {
    this.props.updateCustomer(key, value);
  }

  render() {
    return (
      <div className="main-details-lg">
        <div className="details-row">
          <div className="detail-col">
            <div className="detail-heading">
              Email
            </div>
            <div className="detail-value">
              <EditField readOnly={true} dbKey={CustomerUpdateField.EMAIL} type="text" value={this.props.customer.email} saveHandler={this.saveHandler} />
            </div>
          </div>
          <div className="detail-col">
            <div className="detail-heading">
              Phone
            </div>
            <div className="detail-value">
              <EditField readOnly={true} dbKey={CustomerUpdateField.PHONE} type="text" value={this.props.customer.phone} saveHandler={this.saveHandler} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
