import * as React from "react";

interface B2BTabContentProps {
  inputNumberChange: (key: string, e: any) => void;
  b2bCommission: number;
}

export default class B2BTabContent extends React.Component<B2BTabContentProps, {}> {
  render() {
    return (
      <div className="main-region-details-cell">
        <div className="main-region-details-row">
          <div className="detail-region-heading">
            B2B Commission (%)
          </div>
          <div className="detail-region-value">
            <input
              type="number"
              value={this.props.b2bCommission}
              onChange={(event) => this.props.inputNumberChange('b2bCommission', event)}
            />
          </div>
        </div>
      </div>
    );
  }
}