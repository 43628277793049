import * as React from "react";
import * as moment from "moment-timezone";
import * as Spinner from "react-spinkit";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { History } from "history";

import BookingCustomer from "./components/booking-customer";
import { BookingDate } from "./components/booking-date";
import BookingTherapist from "./components/booking-therapist";
import BookingAddress from "./components/booking-address";
import { BookingNotes } from "./components/booking-notes";
import BookingStatus from "./components/booking-status";
import BookingTreatments from "./components/booking-treatments";
import { Adjustments } from "../adjustments";
import { ApiTherapist } from "../../../api/main/therapist";
import { ApiCustomer } from "../../../api/main/customer";
import { ApiAddress } from "../../../api/main/booking";
import Switch from "react-bootstrap-switch";
import BookingTier from "./components/booking-tier";
import { TherapistTier } from "../../../presenters/therapist";
import { Promotions } from "../promotions";
import { Promotion } from "../../../api/main/promotions";

interface Props {
  isPartner: boolean;
  customer: ApiCustomer;
  addresses: ApiAddress[];
  timeStarts: moment.Moment;
  therapist: ApiTherapist;
  address: any;
  notes: string;
  status: string;
  tier: TherapistTier;
  selectedTreatments: any[];
  treatments: any[];
  setBookingCustomer: (customer: ApiCustomer) => void;
  setBookingDate: (date: moment.Moment) => void;
  setTier(tier: string): void;
  setBookingTherapist: (therapist: ApiTherapist) => void;
  setBookingAddress: (address: any) => void;
  setBookingNotes: (notes: string) => void;
  setBookingStatus: (status: string) => void;
  setBookingSelectedTreatments: (treatments: any[]) => void;
  fetchTherapistTreatments: (urn: string) => void;
  submitBooking: (history: History) => void;
  fetchAddresses: (customerUrn: string) => void;
  amendBookingTreatmentPrice: (urn: string, price: number, index: number) => void;
  selectedTreatmentsPrice: any[];
  adjustments: any[];
  addAdjustment: (type: string, amount: number) => void;
  deleteAdjustment: (index: number) => void;
  amendTreatmentQty: (urn: string, qty: number) => void;
  selectedTreatmentsQty: any[];
  doNotSendEmail: boolean;
  toggleDoNotSendFlag: () => void;
  isB2b: boolean;
  toggleIsB2b: () => void;
  isProduction: boolean;
  toggleIsProduction: () => void;
  submitting: boolean;
  // Promotion block
  promos: Promotion[];
  promotions: any[];
  submittingPromos: boolean;
  isPromoBusy: boolean;
  deleting: {
    [name: string]: boolean;
  };
  fetchPromos: (search: string) => void;
  submitPromo: (urn: string) => void;
  removePromo: (promoUrn: string) => void;
}

class CreateBookingComponent extends React.Component<Props & RouteComponentProps<{}>> {
  handleBookingSubmit = () => {
    if (this.checkFields()) {
      if (this.props.tier === TherapistTier.HOSPITALITY) {
        const message = `"B2B PRICING INCORRECT, CHECK BEFORE PRESSING SUBMIT\n
TREATMENTS <= £75, DEDUCT £1 FROM TREATMENT PRICE\n
TREATMENTS > £75, DEDUCT £2 FROM TREATMENT PRICE\n
E.G - BLOWDRY (LONG HAIR), HOSPITALITY TIER CHANGE FROM £77 TO £75`;

        if (!window.confirm(message)) {
          return;
        }
      }

      this.props.submitBooking(this.props.history);
    }
  }

  checkFields = (): Array<string> => {
    const { customer, therapist, address, status, selectedTreatments } = this.props;
    let errors = [];

    if (!customer)
      errors.push("Customer needed");
    if (!therapist)
      errors.push("Therapist needed");
    if (therapist && !address)
      errors.push("Customer address needed");
    if (!status)
      errors.push("Status field required");
    if ((selectedTreatments instanceof Array && selectedTreatments.length === 0) || selectedTreatments === null)
      errors.push("Treatments required");

    return errors;
  }

  /**
   * Change therapist
   * @param salon Therapist to change to
   */
  handleChangeBookingTherapist = (therapist: ApiTherapist): void => {
    if(therapist.isPartnerTherapist) {
      if(!this.props.isB2b) this.props.toggleIsB2b();
    } else {
      if(this.props.isB2b) this.props.toggleIsB2b();
    }
    this.props.setBookingTherapist(therapist);
    this.props.setTier(therapist.tier);
  }

  /**
   *
   * @param customer
   */
  handleChangeCustomer = (customer: ApiCustomer): void => {
    this.props.setBookingCustomer(customer);

    // A new address will have to be selected
    this.props.setBookingAddress(undefined);

    if (customer.isBlackLabel) {
      this.props.setTier(TherapistTier.BLACK_LABEL);
    }

    // if the customer B2B flag is different to the current booking B2B
    // flag, then toggle the booking B2B flag to reflect the customer B2B flag
    if (customer.isB2b !== this.props.isB2b) {
      this.props.toggleIsB2b();
    }
  }

  render() {
    const ready = this.checkFields().length === 0;

    return (
      <div className="create-booking">
        <h1>Create Booking</h1>

        <div className="app-details-row booking-details-row">
          <div className="main-details">
            <div className="main-details-row">
              <BookingDate
                timeStarts={this.props.timeStarts}
                setBookingDate={this.props.setBookingDate} />
              <BookingStatus
                status={this.props.isPartner ? "unconfirmed" : this.props.status}
                setBookingStatus={this.props.setBookingStatus}
              />
            </div>

            <BookingNotes
              notes={this.props.notes}
              setBookingNotes={this.props.setBookingNotes} />

            {(!this.props.isPartner) ?
              <BookingTherapist
                onChangeBookingTherapist={this.handleChangeBookingTherapist}
              />
              : null}

            {(this.props.therapist) ?
              <BookingTreatments
                therapist={this.props.therapist}
                tier={this.props.tier}
                selectedTreatments={this.props.selectedTreatments}
                amendBookingTreatmentPrice={this.props.amendBookingTreatmentPrice}
                selectedTreatmentsPrice={this.props.selectedTreatmentsPrice}
                treatments={this.props.treatments}
                fetchTherapistTreatments={this.props.fetchTherapistTreatments}
                setBookingSelectedTreatments={this.props.setBookingSelectedTreatments}
                amendTreatmentQty={this.props.amendTreatmentQty}
                selectedTreatmentsQty={this.props.selectedTreatmentsQty}
                timeStarts={this.props.timeStarts}
              />
              : null}

            {(!this.props.isPartner) ?
              <Adjustments
                adjustments={this.props.adjustments}
                addAdjustment={this.props.addAdjustment}
                deleteAdjustmentByIndex={this.props.deleteAdjustment}
                deleteAdjustmentByUrn={(_urn: string) => { throw new Error("deleteAdjustmentByUrn called when booking does not exist, so deleteBookingByIndex should be used") }}
                bookingUrn={""} />
              : null}

            <Promotions
              promotions={this.props.promotions}
              treatments={this.props.treatments}
              treatmentTotal={0}
              discountTotal={0}
              clientToPay={0}
              submitFree={() => { return }}
              promos={this.props.promos}
              isBusy={this.props.isPromoBusy}
              fetchPromos={this.props.fetchPromos}
              submitPromo={this.props.submitPromo}
              transactionState={null}
              submittingPromos={this.props.submittingPromos}
              removePromo={this.props.removePromo}
              deleting={this.props.deleting}
              displayNotification={() => { return }}
              isCreateMode />
          </div>

          <div className="customer">
            <BookingCustomer
              customer={this.props.customer}
              onChangeCustomer={this.handleChangeCustomer}
              fetchAddresses={this.props.fetchAddresses} />

            {(this.props.customer && this.props.therapist) ?
              <BookingAddress
                customer={this.props.customer}
                address={this.props.address}
                addresses={this.props.addresses.filter(a => !a.isDeleted)}
                setBookingAddress={this.props.setBookingAddress} />
              : null}

            <div className="row booking-b2b">
              <div className="col-md-6">
                <div className="detail-heading">
                  B2B
                </div>
                <div className="detail-value">
                  <Switch value={this.props.isB2b}
                    onChange={this.props.toggleIsB2b}
                    onText="Yes"
                    offText="No"
                    bsSize="small"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <BookingTier
                  isPartner={this.props.isPartner}
                  tier={this.props.tier}
                  setTier={this.props.setTier}
                />
              </div>
            </div>
            <div className="row">
              {this.props.isB2b && (
                <div className="col-md-6">
                  <div className="detail-heading">
                    Production
                  </div>
                  <div className="detail-value">
                    <Switch value={this.props.isProduction}
                      onChange={this.props.toggleIsProduction}
                      onText="Yes"
                      offText="No"
                      bsSize="small"
                    />
                  </div>
                </div>
              )}
            </div>            
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-check">
              <label className="form-check-label">
                <input type="checkbox" className="form-check-input" checked={this.props.doNotSendEmail} onChange={this.props.toggleDoNotSendFlag} />
                <p>Do Not Send Email</p>
              </label>
            </div>
          </div>
        </div>

        {!this.props.submitting ?
          <button className="btn btn-submit btn-primary" onClick={this.handleBookingSubmit} disabled={(ready) ? false : true}>Submit</button>
          : <Spinner name="pulse" fadeIn="none" />
        }
      </div>
    );
  }
}

export const CreateBooking = withRouter(CreateBookingComponent);
