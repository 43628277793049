import * as cookie from "js-cookie";
import {History} from "history";
import { createStandardAction } from "typesafe-actions";

import * as operatorApi from "../../api/main/operator";
import {Operator} from "../../presenters/operator";
import {ActionDispatch} from "../";
import { config } from "../../config";

export const actions = {
  fetchOperatorSuccess: createStandardAction("treatments/FETCH_OPERATOR_SUCCESS")<Operator>(),
  loginAttempt: createStandardAction("treatments/LOGIN_ATTEMPT")<void>(),
  loginSuccess: createStandardAction("treatments/LOGIN_SUCCESS")<void>(),
  loginFail: createStandardAction("treatments/LOGIN_FAIL")<Error>(),
  logOut: createStandardAction("treatments/LOG_OUT")<void>(),
};

/**
 * Initiate the app by fetching operator
 */
export function initiateApp(history: History): ActionDispatch {
  return async dispatch => {
    try {
      if (!cookie.get("token")) {
        throw new Error();
      }

      const operator = await operatorApi.getOperator(dispatch);
      dispatch(actions.fetchOperatorSuccess(operator));
    }
    catch (error) {
      dispatch(logOut(history));
    }
  };
}


export function login (
  history: History, 
  username: string, 
  password: string
): ActionDispatch {
  return async dispatch  => {
    dispatch(actions.loginAttempt());

    try {
      const res = await operatorApi.login(username, password);
      const path = /^\?path=([^&]+)&?/.exec(window.location.search);

      cookie.set("token", res.headers.token, {
        domain: config.cookieDomain,
      });
      
      if (path && path[1]) {
        history.push(decodeURIComponent(path[1]));
      }
      else {
        history.push("/home");
      }

      dispatch(actions.loginSuccess());
      dispatch(initiateApp(history));
    }
    catch (err) {
      dispatch(actions.loginFail(err.message));
    }
  };
}

export function loginPartner (history: History, partnerId: string, username: string, password: string): ActionDispatch {
  return async dispatch  => {
    dispatch(actions.loginAttempt());

    try {
      const res = await operatorApi.loginPartner(partnerId, username, password);

      cookie.set("token", res.headers.token);
      history.push("/home");

      dispatch(actions.loginSuccess());
      dispatch(initiateApp(history));
    }
    catch (err) {
      dispatch(actions.loginFail(err.message));
    }
  };
}

export function logOut(history: History) {
  cookie.remove("token");
  
  // Redirect to login only if pathname is not regular login or partners login
  if (!/^\/login(?:\/[^\/]+)?\/?$/.test(history.location.pathname)) {
    history.push("/login");
  }

  return actions.logOut();
}
