import * as React from "react";
import {
  Alert,
  Button,
  FormControl,
  FormGroup,
  ControlLabel,
  Col,
} from "react-bootstrap";

interface Props {
  hasErrored: boolean;
  partnerId: string;
  onLogin(partnerId: string, username: string, password: string): void;
}

interface State {
  partnerId?: string;
  username?: string;
  password?: string;
}

export class PartnerLogin extends React.Component<Props, State> {
  state: State = {
    partnerId: this.props.partnerId,
    username: "",
    password: ""
  };

  onFormSubmit = (event: any): void => {
    event.preventDefault();
    this.props.onLogin(this.state.partnerId, this.state.username, this.state.password);
  }

  renderError = (): JSX.Element => {
    if (this.props.hasErrored) {
      return (
        <Alert bsStyle="danger">
          The username or password was not valid
        </Alert>
      );
    }
    return null;
  }

  render() {
    return (
      <Col xs={12} sm={6}>
        {this.renderError()}

        <form onSubmit={this.onFormSubmit}>
          <FormGroup controlId="partnerId">
            <ControlLabel>Partner Id</ControlLabel>
            <FormControl
              type="text"
              placeholder="40c882e6-611e-4c7f-b5c8-a6628b509e8a"
              value={this.state.partnerId}
              onChange={event => this.setState({ partnerId: (event.target as HTMLInputElement).value })}
            />
          </FormGroup>
          <FormGroup controlId="username">
            <ControlLabel>Username</ControlLabel>
            <FormControl
              type="text"
              value={this.state.username}
              onChange={event => this.setState({ username: (event.target as HTMLInputElement).value })}
            />
          </FormGroup>

          <FormGroup controlId="password">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              type="password"
              value={this.state.password}
              onChange={event => this.setState({ password: (event.target as HTMLInputElement).value })}
            />
          </FormGroup>

          <Button type="submit">Login</Button>
        </form>

      </Col>
    );
  }
}
