import * as Immutable from "seamless-immutable";
import {actions} from "./actions";
import * as types from "./types";
import { Promotion } from "../../api/main/promotions";

type Action = ReturnType<typeof actions[keyof typeof actions]>;

interface State {
  isBusy: boolean;
  promos: Promotion[];
}

const initialState = Immutable.from<State>({
  isBusy: false,
  promos: null,
});

export function reducer(state: Immutable.ImmutableObject<State> = initialState, action: Action): Immutable.ImmutableObject<State> {
  switch (action.type) {
    case types.FETCH_SEARCH_PROMO_ATTEMPT:
      return state.set("isBusy", true);

    case types.FETCH_SEARCH_PROMO_SUCCESS:
      return state.set("promos", action.payload.promos)
        .set("isBusy", false);

    default:
      return state;
  }
}
