import * as React from "react";
import { PostcodeArea, PostcodeDistrictGroup } from "../../../presenters/postcodes/postcodes-presenter";
import styled from "../../../../node_modules/styled-components";
import { PostcodeDistrictComponent, checkMark } from "./postcode-district";
import { TherapistPostcodesPresenter } from "../../../presenters/therapist/therapist-postcodes-presenter";
import { Button } from "../../atoms/button";
import { SetPostcodeForTherapist } from ".";

interface Props {
  therapistUrn: string;
  area: PostcodeArea;
  setPostcodeForTherapist: SetPostcodeForTherapist;
  therapistPostcodesPresenter: TherapistPostcodesPresenter;
}

const Area = styled.div`
  position: relative;
  padding: 1em;
  margin: 1em;
`;

const SingleDistrictGroup = styled.div``;

const MultiDistrictGroup = styled.div`
margin-bottom: 2em;
`;

const MultiDistrictGroupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
`;

const MultiDistrictGroupTitle = styled.span`
  font-size: 22px;
`;

const DistrictGroupControls = styled.div`
  align-self: flex-end;
`;

/**
 * A handler function that turns on all districts in a group. It just does a lot of individual calls, which we hope will be ok
 */
const turnAllDistrictsInGroupOn = async (group: PostcodeDistrictGroup, therapistUrn: string, setPostcodeForTherapist:  SetPostcodeForTherapist): Promise<void> => {
  for (const district of group.children) {
    // await each one because there's a race condition if we fire them all async
    await setPostcodeForTherapist(therapistUrn, district.urn, true);
  }
};

/**
 * A handler function that turns off all districts in a group. It just does a lot of individual calls, which we hope will be ok
 */
const turnAllDistrictsInGroupOff = async (group: PostcodeDistrictGroup, therapistUrn: string, setPostcodeForTherapist:  SetPostcodeForTherapist): Promise<void> => {
  for (const district of group.children) {
    // await each one because there's a race condition if we fire them all async
    await setPostcodeForTherapist(therapistUrn, district.urn, false);
  }
};

/**
 * Convenience function to map a district-group to a component.
 * If the group contains more than one district, we list them out with controls to enable/disable them all
 * For district groups that are just a wrapper around a single district we just minimally wrap it to keep the format consistent
 */
const mapDistrictGroup = (therapistUrn: string, group: PostcodeDistrictGroup, setPostcodeForTherapist: SetPostcodeForTherapist, therapistPostcodePresenter: TherapistPostcodesPresenter) => {
  if (group.children.length > 1) {
    // this group contains multiple districts

    return (
      <MultiDistrictGroup key={group.districtGroupCode}>
        <MultiDistrictGroupHeader>
        <MultiDistrictGroupTitle>{group.districtGroupCode}</MultiDistrictGroupTitle>
        <DistrictGroupControls>
          <div>
            <Button className="btn" label={`${checkMark} turn all ${group.districtGroupCode} on`} onClick={() => turnAllDistrictsInGroupOn(group, therapistUrn, setPostcodeForTherapist)} />
            <Button className="btn btn-danger" label={`turn all ${group.districtGroupCode} off`} onClick={() => turnAllDistrictsInGroupOff(group, therapistUrn, setPostcodeForTherapist)} />
          </div>
        </DistrictGroupControls>
        </MultiDistrictGroupHeader>
        <div>
          {
            group.children.map(
              district => <PostcodeDistrictComponent
                key={district.outCode}
                therapistUrn={therapistUrn}
                district={district}
                setPostcodeForTherapist={setPostcodeForTherapist}
                enabled={therapistPostcodePresenter.isPostcodeEnabled(district.urn)}
              />
            )
          }
        </div>
      </MultiDistrictGroup>
    );
  }
  else {
    // only one district nothing fancy needed for the group

    return (
      <SingleDistrictGroup key={group.districtGroupCode}>{
        group.children.map(
          district =>
          <PostcodeDistrictComponent
            key={district.outCode}
            therapistUrn={therapistUrn}
            district={district}
            setPostcodeForTherapist={setPostcodeForTherapist}
            enabled={therapistPostcodePresenter.isPostcodeEnabled(district.urn)}
          />
        )
      }</SingleDistrictGroup>
    );
  }
};

const AreaName = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

// The anchor that we'll link to in the sticky nav. Has a negative position
// so that the sticky nav doesn't hide it
const AreaAnchor = styled.a`
  position: absolute;
  left: 0px;
  top: -100px;
`;

/**
 * A component that display a postcode area. Includes the area name and an anchor to scroll to
 */
export const PostcodeAreaComponent: React.StatelessComponent<Props> = (props: Props) => {
  const districtGroupComponents = props.area.children.map(
    districtGroup => mapDistrictGroup(
      props.therapistUrn,
      districtGroup,
      props.setPostcodeForTherapist,
      props.therapistPostcodesPresenter
    )
  );
  return (
    <Area key={props.area.areaCode}>
      <AreaAnchor id={props.area.areaCode}></AreaAnchor>
      <AreaName>{props.area.areaCode} - {props.area.areaName}</AreaName>
      <div>
      {districtGroupComponents}
      </div>
    </Area>
  );
}