import { config } from "../../../config";
import * as api from "../request";

export interface ApiCategory {
  urn: string;
  name: string;
  parentUrn: string;
}

/**
 * Fetch all categories
 */
export async function fetchAll(): Promise<ApiCategory[]> {
  const url = `${config.adminPanelApiUrl}/categories`;
  const response = await api.get<{categories: ApiCategory[]}>(url);

  return response.data.categories;
}
