import * as React from "react";

import { EditableFieldButtons } from "../editable-field-buttons";
import { EditableFieldIcon } from "../../atoms/editable-field-icon";

interface Props {
  label?: string;
  value: string;
  isEditing: boolean;
  isUpdating: boolean;
  emptyLabel: string;
  onStartEditing(): void;
  onFinishEditing(): void;
  onSubmit(value: string): void;
}

interface State {
  editingValue: string;
}

export class MixinEditableField extends React.Component<Props, State> {
  state: State = {
    editingValue: "",
  };

  handleStartEditing = (): void => {
    this.props.onStartEditing();

    this.setState({
      ...this.state,
      editingValue: this.props.value,
    });
  }

  handleFinishEditing = (): void => {
    this.props.onFinishEditing();

    this.setState({
      ...this.state,
      editingValue: "",
    });
  }

  renderButtons = (): JSX.Element => {
    return <EditableFieldButtons
      isUpdating={this.props.isUpdating}
      onSubmit={() => this.props.onSubmit(this.state.editingValue)}
      onCancel={this.handleFinishEditing}
    />;
  }

  renderEditIcon = (): JSX.Element => {
    return <EditableFieldIcon onClick={this.handleStartEditing} />;
  }
}
