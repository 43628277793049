import * as moment from "moment-timezone";
import {Dispatch} from "redux";
import { createStandardAction } from "typesafe-actions";
import uuid from "node-uuid";

import * as api from "../../api/main/booking";
import {ActionDispatch} from "../";
import { NOTIFICATION_TYPES } from "../notifications/types";
import { setEditInProgress, setEditComplete } from "../edit/actions";
import { addNotification } from "../notifications/actions";
import * as customerActions from "../customers/actions";
import { calculateSurchargeForStartTime } from "../../model/treatment";
import { inflateArrayFromMap } from "../../presenters/utils";
import { inflateTreatment } from "../../presenters/treatment";
import { ApiTherapist } from "../../api/main/therapist";

export interface SearchParams {
  start?: number;
  end?: number;
  dateType?: string;
  query?: string;
  status?: string;
  payment?: string;
  partners?: string[];
}

interface UpdateBookingPrice {
  bookingUrn: string;
  index: number;
  value: number;
}

interface CreateTransactionSuccess {
  bookingUrn: string;
  type: string;
  amount: number;
  transactionUrn: string;
}

interface DeleteTransactionSuccess {
  bookingUrn: string;
  transactionIndex: number;
}

interface DeleteAdjustmentSuccess {
  bookingUrn: string;
  adjustmentUrn: string;
}

interface FetchTransactionsSuccess {
  bookingUrn: string;
  transactions: any;
}

interface TherapistBookingsForDayPayload {
  therapistUrn: string;
  day: string;
  bookings: api.ApiBooking[];
}

export const actionCreators = {
  updateSearchParameters: createStandardAction("bookings/UPDATE_SEARCH_PARAMETERS")<SearchParams>(),
  updateBookingsMap: createStandardAction("bookings/UPDATE_BOOKINGS_MAP")<api.ApiBooking[]>(),
  setBookingsList: createStandardAction("bookings/SET_BOOKINGS_LIST")<string[]>(),
  setTherapistBookingsForDay: createStandardAction("bookings/SET_THERAPIST_BOOKINGS_FOR_DAY")<TherapistBookingsForDayPayload>(),
  fetchBookingsFail: createStandardAction("bookings/FETCH_BOOKINGS_FAIL")<string>(),
  fetchBookingAttempt: createStandardAction("bookings/FETCH_BOOKING_ATTEMPT")<void>(),
  fetchBookingFail: createStandardAction("bookings/FETCH_BOOKING_FAIL")<string>(),
  updateBookingAttempt: createStandardAction("bookings/UPDATE_BOOKING_ATTEMPT")<void>(),
  updateBookingSuccess: createStandardAction("bookings/UPDATE_BOOKING_SUCCESS")<void>(),
  updateBookingFail: createStandardAction("bookings/UPDATE_BOOKING_FAIL")<string>(),
  updateBookingTreatmentPriceSuccess: createStandardAction("bookings/UPDATE_BOOKING_TREATMENT_PRICE_SUCCESS")<UpdateBookingPrice>(),
  createTransactionAttempt: createStandardAction("bookings/CREATE_BOOKING_TRANSACTION_ATTEMPT")<void>(),
  createTransactionSuccess: createStandardAction("bookings/CREATE_BOOKING_TRANSACTION_SUCCESS")<CreateTransactionSuccess>(),
  createTransactionFail: createStandardAction("bookings/CREATE_BOOKING_TRANSACTION_FAIL")<void>(),
  deleteTransactionSuccess: createStandardAction("bookings/DELETE_BOOKING_TRANSACTION_SUCCESS")<DeleteTransactionSuccess>(),
  deleteAdjustmentSuccess: createStandardAction("bookings/DELETE_BOOKING_ADJUSTMENT_SUCCESS")<DeleteAdjustmentSuccess>(),
  submitPromosAttempt: createStandardAction("bookings/SUBMIT_PROMOS_ATTEMPT")<void>(),
  submitPromosSuccess: createStandardAction("bookings/SUBMIT_PROMOS_SUCCESS")<void>(),
  fetchTransactionsSuccess: createStandardAction("bookings/FETCH_TRANSACTIONS_SUCCESS")<FetchTransactionsSuccess>(),
  removePromoAttempt: createStandardAction("bookings/REMOVE_PROMO_ATTEMPT")<void>(),
  removeRefundAttempt: createStandardAction("bookings/REMOVE_REFUND_ATTEMPT")<void>(),
  deleteBookingTreatment: createStandardAction("bookings/DELETE_BOOKING_TREATMENT")<{bookingUrn: string, bookingTreatmentUrn: string}>(),
  resetSearch: createStandardAction("bookings/RESET_SEARCH")<void>(),
};

export function initiateBookingScreen(bookingUrn: string): ActionDispatch {
  return async (dispatch, getState) => {
    await dispatch(fetchBooking(bookingUrn));

    const booking = getState().bookings.bookings[bookingUrn];
    await dispatch(customerActions.fetchCustomer(booking.customer.urn));
    await dispatch(customerActions.fetchAddresses(booking.customer.urn));
  };
}

/**
 * Return large date range for search
 */
 function setLargeDateRnge() {
  return { start: moment().subtract(3, "months").valueOf(), end: moment().add(1, "years").valueOf() };
}

export function searchWithParameters(param: SearchParams): ActionDispatch {
  return async dispatch => {
    dispatch(actionCreators.updateSearchParameters(param));
    await dispatch(fetchBookings());
  };
}

/**
 * Search for a given therapist
 * @param therapistUrn
 */
export function searchTherapist(therapistUrn: string): ActionDispatch {
  return async dispatch => {
    dispatch(
      actionCreators.updateSearchParameters({
        query: `therapist-urn:"${therapistUrn}"`,
        status: "ALL",
        ...setLargeDateRnge(),
      }),
    );
    await dispatch(fetchBookings());
  };
}

/**
 * Search for a given customer
 * @param customerUrn
 */
export function searchCustomer(customerUrn: string): ActionDispatch {
  return async dispatch => {
    dispatch(
      actionCreators.updateSearchParameters({
        query: `customer-urn:"${customerUrn}"`,
        status: "ALL",
        ...setLargeDateRnge(),
      }),
    );
    await dispatch(fetchBookings());
  };
}

/**
 * Reset the search parameters
 */
export function resetSearch(): ActionDispatch {
  return async dispatch => {
    dispatch(actionCreators.resetSearch());
    await dispatch(fetchBookings());
  }
}

let activeSearchRequestId: string;

export function fetchBookings(): ActionDispatch {
  return async (dispatch, getState) => {
    const thisRequestId = uuid.v4();

    try {
      activeSearchRequestId = thisRequestId;
      dispatch(actionCreators.fetchBookingAttempt());

      const search = getState().bookings.searchParams;

      let query = `date:${search.start}-${search.end} date-type:'${search.dateType}'`;

      if (search.status !== "ALL") {
        query += ` status:'${search.status}'`
      }

      if (search.payment !== "ALL") {
        query += ` payment:'${search.payment}'`
      }

      query += ` ${search.query}`;

      const bookings = await api.fetchBookings(query);

      if (thisRequestId === activeSearchRequestId) {
        dispatch(actionCreators.updateBookingsMap(bookings));
        dispatch(actionCreators.setBookingsList(bookings.map(b => b.urn)));
      }
    } catch (err) {
      if (thisRequestId === activeSearchRequestId) {
        dispatch(actionCreators.fetchBookingsFail(err));
      }
    }
  };
}

export function fetchTherapistBookingsForDay(therapistUrn: string, day: string): ActionDispatch {
  return async (dispatch) => {
    try {
      const bookings = await api.fetchTherapistBookingsForDay(therapistUrn, day);
      dispatch(actionCreators.updateBookingsMap(bookings));
      dispatch(actionCreators.setBookingsList(bookings.map(b => b.urn)));
      dispatch(actionCreators.setTherapistBookingsForDay({therapistUrn, day, bookings}));
    }
    catch (err) {
      console.error(`ERROR`, err);
      // We add this notification because this is used on the availability page, which doesn't display bookings errors
      dispatch(addNotification(NOTIFICATION_TYPES.danger, err.message, "Error fetching therapist bookings. Try refreshing the page"));
      dispatch(actionCreators.fetchBookingsFail(err));
    }

  };
};

export function fetchBooking(urn: string): ActionDispatch {
  return async dispatch => {
    try {
      dispatch(actionCreators.fetchBookingAttempt());
      const booking = await api.fetchBooking(urn);
      dispatch(actionCreators.updateBookingsMap([booking]));
    }
    catch (err) {
      console.error("ERROR FETCHING BOOKING", err);
      dispatch(actionCreators.fetchBookingFail(err));
    }
  };
}

export function updateBooking(bookingUrn: string, key: api.BookingUpdateField, value: api.BookingUpdateValue): ActionDispatch {
  return async dispatch => {
    dispatch(actionCreators.updateBookingAttempt());
    dispatch(setEditInProgress());

    try {
      await api.updateBooking(bookingUrn, key, value);
      dispatch(setEditComplete());
      dispatch(fetchBooking(bookingUrn));
      dispatch(addNotification(NOTIFICATION_TYPES.success, "Updated!", "The booking has been changed successfully"));
    } catch (err) {
      dispatch(actionCreators.updateBookingFail(err));
      dispatch(addNotification(NOTIFICATION_TYPES.danger, "Error Updating Booking", "The booking could not be updated. Refresh the booking data and try again."));
    }
  };
}

export function updateBookingTreatment(bookingUrn: string, treatmentUrn: string, index: number, key: string, value: number): ActionDispatch {
  return async dispatch => {
    try {
      await api.updateBookingTreatment(treatmentUrn, key, value);
      dispatch(actionCreators.updateBookingTreatmentPriceSuccess({bookingUrn, index, value}));
      dispatch(fetchBooking(bookingUrn));
      dispatch(addNotification(NOTIFICATION_TYPES.success, "Updated!", `The ${key} for the booking treatment has been changed successfully`));
    } catch (err) {
      console.error("FETCH TREATMENTS ERROR", err);
      dispatch(addNotification(NOTIFICATION_TYPES.danger, "Error updating booking treatment", err.message));
    }
  };
}

export function addNewBookingTreatment(booking: api.ApiBooking, treatmentUrns: string[], therapist: ApiTherapist): ActionDispatch {
  return async (dispatch, getState) => {
    try {
      const timeStarts = moment(booking.timeStarts);
      const timeEnds = moment(booking.timeEnds);

      const newTreatments = inflateArrayFromMap(
        getState().treatments.treatments,
        treatmentUrns,
        inflateTreatment,
      );

      const therapistRegions = therapist.regions;

      const addTreatments = newTreatments
        .map<api.AddBookingTreatment>(t => {
          const surcharge = calculateSurchargeForStartTime(
            booking.tier,
            timeStarts,
            timeEnds.clone().add(t.duration, "minutes"),
          );
          const price = t.getPrice(booking.tier, therapistRegions);

          return {
            treatmentUrn: t.urn,
            price: price + (price * surcharge)
          };
        });

      await api.addNewBookingTreatment(booking, addTreatments);

      await dispatch(fetchBooking(booking.urn));
    }
    catch (err) {
      dispatch(addNotification(NOTIFICATION_TYPES.danger, err.message, "Error in adding treatment. Try refreshing the browser if problem persists"));
    }
  };
}

export function deleteBookingTreatment(booking: api.ApiBooking, bookingTreatmentUrn: string): ActionDispatch {
  return async dispatch => {
    try {
      await api.deleteBookingTreatment(booking, bookingTreatmentUrn);
      dispatch(actionCreators.deleteBookingTreatment({
        bookingUrn: booking.urn,
        bookingTreatmentUrn,
      }));
    }
    catch (err) {
      console.error("DELETE ERROR", err);
      dispatch(addNotification(NOTIFICATION_TYPES.danger, err.message, "Error in deleting treatment. Try refreshing the browser if problem persists"));
    }
  };
}

export function createTransaction(bookingUrn: string, type: string, amount: number): ActionDispatch {
  return async dispatch => {
    dispatch(actionCreators.createTransactionAttempt());

    try {
      const transactionUrn = await api.createTransaction(bookingUrn, type, amount);
      dispatch(actionCreators.createTransactionSuccess({bookingUrn, type, amount, transactionUrn}));
      dispatch(addNotification(NOTIFICATION_TYPES.success, "Updated!", `Transaction added`));
    }
    catch (err) {
      console.error("ERROR FETCH CREATING TRANSACTION", err);
      dispatch(addNotification(NOTIFICATION_TYPES.danger, err.message, "Could not create the transaction."));
    }
  };
}

export function fetchTransactions(bookingUrn: string) {
  return async (dispatch: Dispatch<any>) => {
    try {
      const transactions = await api.fetchTransactions(bookingUrn);
      dispatch(actionCreators.fetchTransactionsSuccess({bookingUrn, transactions}));
    } catch (err) {
      console.error("FETCH TRANSACTION RESPONSE", err);
      dispatch(addNotification(NOTIFICATION_TYPES.danger, err.message, "Could not fetch transactions."));
    }
  };
}

export function deleteAdjustment(bookingUrn: string, transactionIndex: number): ActionDispatch {
  return async (dispatch, getState) => {
    const bookingTransactionUrn = getState().bookings.bookings[bookingUrn].transactions[transactionIndex].urn;

    try {
      await api.deleteAdjustment(bookingTransactionUrn);
      dispatch(actionCreators.deleteTransactionSuccess({bookingUrn, transactionIndex}));
      dispatch(addNotification(NOTIFICATION_TYPES.success, "Updated!", `Transaction deleted`));
    } catch (err) {
      console.error("ERROR FETCH DELETING TRANSACTION", err);
      dispatch(addNotification(NOTIFICATION_TYPES.danger, err.message, "Could not delete the transaction."));
    }
  };
}

export function deleteAdjustmentByUrn(bookingUrn: string, adjustmentUrn: string): ActionDispatch {
  return async (dispatch: Dispatch<{}>) => {
    try {
      await api.deleteAdjustment(adjustmentUrn);
      dispatch(actionCreators.deleteAdjustmentSuccess({bookingUrn, adjustmentUrn}));
    } catch (err) {
      console.error("ERROR FETCH DELETING ADJUSTMENT", err);
      dispatch(addNotification(NOTIFICATION_TYPES.danger, err.message, "Could not delete the transaction."));
    }
  };
}

export function submitFree(bookingUrn: string, amount: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actionCreators.submitPromosAttempt());
    try {
      await api.submitFree(bookingUrn, amount);
      dispatch(actionCreators.submitPromosSuccess());
      dispatch(fetchTransactionMethods(bookingUrn));
      dispatch(addNotification(NOTIFICATION_TYPES.success, "Updated!", `Complimentary added`));
    } catch (err) {
      console.error("ERROR CREATING FREE PROMO", err);
      dispatch(addNotification(NOTIFICATION_TYPES.danger, err.message, "Could not create complimentary promo. Refresh and try again. If problem persists consult admin team"));
    }
  };
}

export function submitPromo(bookingUrn: string, promoUrn: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actionCreators.submitPromosAttempt());

    try {
      await api.submitPromo(bookingUrn, promoUrn);
      dispatch(actionCreators.submitPromosSuccess());
      dispatch(fetchTransactionMethods(bookingUrn));
      dispatch(addNotification(NOTIFICATION_TYPES.success, "Updated!", `Promo added`));
    } catch (err) {
      console.error("ERROR CREATING FREE PROMO", err);
      dispatch(addNotification(NOTIFICATION_TYPES.danger, err.message, "Could not create complimentary promo. Refresh and try again. If problem persists consult admin team"));
    }
  };
}

export function removePromo(bookingUrn: string, promoUrn: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actionCreators.removePromoAttempt());

    try {
      await api.removePromo(promoUrn);
      dispatch(fetchBooking(bookingUrn));

      dispatch(addNotification(NOTIFICATION_TYPES.success, "Updated!", `Promo successfully deleted`));
    } catch (err) {
      console.error("ERROR DELETING PROMO", err);
      dispatch(addNotification(NOTIFICATION_TYPES.danger, err.message, "Could not delete the promo. Refresh the page and try again"));
    }
  };
}

export function fetchTransactionMethods(bookingUrn: string) {
  return async (dispatch: Dispatch<any>) => {
    try {
      const transactions = await api.fetchTransactionMethods(bookingUrn);
      dispatch(actionCreators.fetchTransactionsSuccess({bookingUrn, transactions}));
    } catch (error) {
      console.error("ERROR FETCHING TRANSACTION METHODS", error);
    }
  };
}

export function removeRefund(bookingUrn: string, refund: api.ApiRefund) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actionCreators.removeRefundAttempt());

    try {
      await api.removeRefund(refund.urn);
      dispatch(fetchBooking(bookingUrn));

      dispatch(addNotification(NOTIFICATION_TYPES.success, "Deleted!", `Refund of amount ${sprintf("£%0.2f", refund.amount)} successfully deleted`));
    } catch (err) {
      console.error("ERROR DELETING REFUND", err);
      dispatch(addNotification(NOTIFICATION_TYPES.danger, err.message, "Could not delete the refund. Refresh the page and try again"));
    }
  };
}
