import * as React from "react";
import * as Modal from "react-modal";

interface Props {
    isOpen: boolean;
    title?: string;
    message: string;
    closeModal: () => void;
    handleConfirm: () => void;
}

const modalStyling: Modal.Styles = {
    content: {
        height: "fit-content",
        width: "50%",
        top: "50%",
        left: "50%",
        transform: "translateY(-50%) translateX(-50%)",
    },
    overlay: {
        zIndex: 3
    }
};

export const PromotionDeleteModal: React.SFC<Props> = ({ title, message, isOpen, handleConfirm, closeModal }) => (
    <Modal
        isOpen={isOpen}
        style={modalStyling}
        contentLabel={title}
        onRequestClose={closeModal}
    >
        <div className="alert-modal" style={{ padding: "0px" }}>
            <div className="row alert-modal-info" style={{ margin: "0px" }}>
                <div className="col-md-12" style={{ backgroundColor: "#396174", color: "#ffffff", padding: "0px", margin: "0px" }}>
                    <h3 style={{ margin: "0px", padding: "10px" }}>{title}</h3>
                </div>
            </div>
            <div className="row alert-modal-info" style={{ margin: "10px 0 0 0" }}>

                <div className="col-md-12">
                    <p>{message}</p>
                </div>
            </div>

            <div className="row btn-row" style={{ padding: "0px", margin: "0 0 20px 0" }}>
                <div className="col-md-6 confirm">
                    <button type="button" style={{ backgroundColor: "#00AF6C", borderRadius: "0px", borderColor: "#4CAF50", color: "#ffffff", width: "100px" }} onClick={() => handleConfirm()} className="btn btn-success">Yes</button>
                </div>
                <div className="col-md-6 cancel">
                    <button type="button" style={{ backgroundColor: "#cc0000", borderRadius: "0px", borderColor: "#cc0000", color: "#ffffff", width: "100px" }} onClick={() => closeModal()} className="btn btn-danger">No</button>
                </div>
            </div>
        </div>
    </Modal>
);
