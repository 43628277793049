import styled from "styled-components";

interface Props {
  justifyContent?:
    | "center"
    | "flex-end"
    | "flex-start"
    | "space-around"
    | "space-between"
    | "space-evenly";
  alignItems?: "center" | "flex-end" | "flex-start";
}

export const Row = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  /**
   * Addtitioanl justify and alight properties for row
   */
  ${(props: Props) =>
    props.justifyContent
      ? `justify-content: ${props.justifyContent}`
      : undefined};
  ${(props: Props) =>
    props.alignItems ? `align-items: ${props.alignItems}` : undefined};
`;
