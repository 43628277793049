import { config } from "../../../config";
import * as api from "../request";

export interface ApiJob {
  id: string;
  title: string;
  description: string;
  salary: string;
  joblocation: string;
  email: string;
  isFeatured: string;
}

const baseUrl = `${config.adminPanelApiUrl}/jobs/`;

export async function fetchAll(): Promise<ApiJob[]> {
  const response = await api.get<{ jobs: ApiJob[] }>(baseUrl);
  return response.data.jobs;
}

export async function deleteJob(id: string): Promise<void> {
  await api.deletion(`${baseUrl}${id}`);
}

export async function createJob(job: ApiJob): Promise<void> {
  await api.post(baseUrl, job);
}

export async function editJob(job: ApiJob): Promise<void> {
  const url = `${baseUrl}edit`;
  await api.post(url, job);
}