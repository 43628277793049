import {Dispatch} from "redux";
import * as types from "./types";
import {fetchBooking} from "../bookings/actions";
import * as api from "../../api/main/transactions";
import {fetchCustomer} from "../customers/actions";

export const actions = {
  transactionAttempt: () => ({
    type: types.TRANSACTION_ATTEMPT
  }),
  transactionError: (error: any) => ({
    type: types.TRANSACTION_ERROR,
    payload: { error }
  }),
  transactionSuccess: () => ({
    type: types.TRANSACTION_SUCCESS,
  }),
  openModal: () => ({
    type: types.OPEN_TRANSACTION_MODAL
  }),
  closeModal: () => ({
    type: types.CLOSE_TRANSACTION_MODAL
  }),
  fetchBraintreeTokenSuccess: (token: string) => ({
    type: types.FETCH_BRAINTREE_TOKEN_SUCCESS,
    payload: { token }
  }),
  openNewCardModal: () => ({
    type: types.OPEN_NEW_CARD_MODAL
  }),
  closeNewCardModal: () => ({
    type: types.CLOSE_NEW_CARD_MODAL
  }),
  setTransactionError: (error: string) => ({
    type: types.TRANSACTION_ERROR,
    payload: { error }
  }),
  submitNewCardSuccess: () => ({
    type: types.SUBMIT_NEW_CARD_SUCCESS
  })
};

export function fetchBraintreeToken() {
  return async (dispatch: Dispatch<any>) => {
    try {
      const token = await api.fetchBraintreeToken();
      dispatch(actions.fetchBraintreeTokenSuccess(token));
    } catch (err) {
      console.error("ERROR FETCHING BRAINTREE TOKEN", err);
    }
  };
}

export function debitCard(customerUrn: string, amount: number, card: any, bookingUrn: string) {
  return async function(dispatch: Dispatch<any>) {
    dispatch(actions.transactionAttempt());

    try {
      await api.debitCard(customerUrn, amount, card.token, bookingUrn);

      dispatch(fetchBooking(bookingUrn));
      dispatch(actions.transactionSuccess());
    } catch (error) {
      console.error("DEBIT CARD ERROR", error);
      dispatch(actions.transactionError(error.response.data.error));
    }
  };
}

export function submitNewCard(customerUrn: string, nonce: string) {
  return async (dispatch: Dispatch<any>) => {
    try {
      await api.submitNewCard(customerUrn, nonce);

      dispatch(actions.transactionSuccess());
      dispatch(fetchCustomer(customerUrn));
    } catch (error) {
      console.error("ERROR SUBMITTING NEW CARD:", error);
      dispatch(actions.setTransactionError(error.response.data.message || error.message));
    }
  };
}
