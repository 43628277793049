import {AxiosResponse} from "axios";
import {config} from "../../config";
import * as api from "./request";

export async function fetchBraintreeToken(): Promise<string>  {
  const url = `${config.adminPanelApiUrl}/payment/token`;

  const response: AxiosResponse =  await api.get(url);

  return response.data;
}

export async function debitCard(customerUrn: string, amount: number, token: string, bookingUrn: string) {
  const url = `${config.adminPanelApiUrl}/payment`;

  const res: AxiosResponse = await api.post(url, { customerUrn, amount, token, bookingUrn });
  return res.data;
}

export async function submitNewCard(urn: string, nonce: string): Promise<boolean> {
  const url = `${config.adminPanelApiUrl}/customers/${urn}/card`;
  await api.post(url, { nonce });

  return true;
}
