import styled from "styled-components";

import { editButtonCss } from "../../atoms/editable-field-icon";
import { FieldLabel } from "../../atoms/field-label";
import { MixinEditableField } from "../mixin-editable-field";

const Paragraph = styled.p`
  font-family: "nunito_sansregular";
  color: #333333;
  font-size: 15px;

  ${editButtonCss}

  em {
    color: #999999;
  }
`;

const Container = styled.div`
  width: 100%;
  display: inline-flex;
`;

const TextArea = styled.textarea`
  font-family: "nunito_sansregular";
  color: #333333;
  flex-grow: 1;
  height: 100px;
  padding: 5px;
  font-size: 15px;
`;

export class EditableTextArea extends MixinEditableField {
  render(): JSX.Element {
    const fieldLabel = <FieldLabel>{this.props.label}</FieldLabel>;

    if (this.props.isEditing) {
      return (
        <div>
          {fieldLabel}
          <Container>
            <TextArea onChange={e => this.setState({...this.state, editingValue: e.target.value})}>
              {this.state.editingValue}
            </TextArea>
            {this.renderButtons()}
          </Container>
        </div>
      );
    }
    else {
      const label = ((typeof this.props.value !== "undefined") && (this.props.value !== ""))
        ? <span>{this.props.value}</span>
        : <em>{this.props.emptyLabel}</em>;

      return (
        <div>
          {fieldLabel}
          <Paragraph>
            {label} {this.renderEditIcon()}
          </Paragraph>
        </div>
      );
    }
  }
}
