import * as React from "react";
import { connect, Dispatch } from "react-redux";
import * as moment from "moment";
import * as constants from "../constants";
import { fetchAddresses } from "../reducers/customers/actions";
import * as promotionActions from "../reducers/promotions/actions";
import * as bookingsCreateActions from "../reducers/bookings/create/actions";
import { CreateBooking } from "../components/booking/create/create-booking";
import { ApiTherapist } from "../api/main/therapist";
import { ApiAddress, ApiTreatment, ApiBookingTransaction } from "../api/main/booking";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ApiCustomer } from "../api/main/customer";
import { RootState } from "../reducers";
import { Promotion } from "../api/main/promotions";
import { TherapistTier } from "../presenters/therapist";

interface ReduxProps {
  isPartner: boolean;
  customer: ApiCustomer;
  addresses: ApiAddress[];
  timeStarts: moment.Moment;
  timeEnds: moment.Moment;
  therapist: ApiTherapist;
  address: any;
  notes: string;
  status: string;
  tier: TherapistTier;
  treatments: any[];
  selectedTreatments: any[];
  selectedTreatmentsPrice: any[];
  adjustments: any[];
  selectedTreatmentsQty: any[];
  doNotSendEmail: boolean;
  isB2b: boolean;
  isProduction: boolean;
  submitting: boolean;
  deleting: {
    [name: string]: boolean;
  };
  submittingPromos: boolean;
  promosSearch: Promotion[];
  promosAdded: ApiBookingTransaction[];
  isPromoBusy: boolean;
}

type OwnProps = RouteComponentProps<{}>;
type Props = ReduxProps & OwnProps & { dispatch: Dispatch<{}> };

class CreateBookingContainer extends React.Component<Props, {}> {

  setPromoForStore = (urn: string) => {
    const promoToAdd = this.props.promosSearch.find(promo => promo.urn === urn);
    const promoTransaction = {
      urn,
      bookingUrn: "",
      transactionId: "",
      type: constants.TRANSACTION_PROMO,
      deductionType: promoToAdd.deductionType,
      amount: promoToAdd.deduction,
      method: promoToAdd,
    }

    this.props.dispatch(bookingsCreateActions.actions.addPromo(promoTransaction));
  }

  render() {

    return (
      <CreateBooking
        isPartner={this.props.isPartner}
        customer={this.props.customer}
        addresses={this.props.addresses}
        setBookingCustomer={(customer: ApiCustomer) => this.props.dispatch(bookingsCreateActions.actions.setBookingCustomer(customer))}
        timeStarts={this.props.timeStarts.seconds(0).milliseconds(0)}
        setBookingDate={(date: moment.Moment) => this.props.dispatch(bookingsCreateActions.actions.setBookingDate(date))}
        therapist={this.props.therapist}
        setBookingTherapist={(therapist: ApiTherapist) => this.props.dispatch(bookingsCreateActions.actions.setBookingTherapist(therapist))}
        address={this.props.address}
        setBookingAddress={(address: ApiAddress) => this.props.dispatch(bookingsCreateActions.actions.setBookingAddress(address))}
        notes={this.props.notes}
        setBookingNotes={(notes: string) => this.props.dispatch(bookingsCreateActions.actions.setBookingNotes(notes))}
        status={this.props.status}
        setBookingStatus={(status: string) => this.props.dispatch(bookingsCreateActions.actions.setBookingStatus(status))}
        tier={this.props.tier}
        setTier={(tier: string) => this.props.dispatch(bookingsCreateActions.actions.setBookingTier(tier))}
        fetchTherapistTreatments={(salonUrn: string) => this.props.dispatch(bookingsCreateActions.fetchTherapistTreatments(salonUrn))}
        treatments={this.props.treatments}
        selectedTreatments={this.props.selectedTreatments}
        setBookingSelectedTreatments={(treatments: ApiTreatment[]) => this.props.dispatch(bookingsCreateActions.actions.setBookingSelectedTreatments(treatments))}
        submitBooking={() => this.props.dispatch(bookingsCreateActions.submitBooking(this.props.history))}
        fetchAddresses={(customerUrn: string) => this.props.dispatch(fetchAddresses(customerUrn))}
        amendBookingTreatmentPrice={(urn: string, price: number, index: number) => this.props.dispatch(bookingsCreateActions.actions.amendBookingTreatmentPrice(urn, price, index))}
        selectedTreatmentsPrice={this.props.selectedTreatmentsPrice}
        adjustments={this.props.adjustments}
        addAdjustment={(type: string, amount: number) => this.props.dispatch(bookingsCreateActions.actions.addAdjustment(type, amount))}
        deleteAdjustment={(index: number) => this.props.dispatch(bookingsCreateActions.actions.deleteAdjustment(index))}
        amendTreatmentQty={(urn: string, quantity: number) => this.props.dispatch(bookingsCreateActions.actions.amendTreatmentQty(urn, quantity))}
        selectedTreatmentsQty={this.props.selectedTreatmentsQty}
        doNotSendEmail={this.props.doNotSendEmail}
        toggleDoNotSendFlag={() => this.props.dispatch(bookingsCreateActions.actions.toggleDoNotSendFlag())}
        isB2b={this.props.isB2b}
        toggleIsB2b={() => this.props.dispatch(bookingsCreateActions.actions.toggleIsB2b())}
        isProduction={this.props.isProduction}
        toggleIsProduction={() => this.props.dispatch(bookingsCreateActions.actions.toggleIsProduction())}        
        submitting={this.props.submitting}
        // PROMOCODE block
        deleting={this.props.deleting}
        submittingPromos={this.props.submittingPromos}
        promos={this.props.promosSearch}
        promotions={this.props.promosAdded}
        isPromoBusy={this.props.isPromoBusy}
        fetchPromos={(search: string) => this.props.dispatch(promotionActions.fetch(search))}
        submitPromo={(urn: string) => this.setPromoForStore(urn)}
        removePromo={(urn: string) => this.props.dispatch(bookingsCreateActions.actions.deletePromo(urn))}
      />
    );
  }
}

const mapStateToProps = (state: RootState): ReduxProps => {
  const isPartner = state.operator.isPartner;
  const customer = state.createBookingState.get("customer");
  const addresses = (customer && state.customersState.customers[customer.toJS().urn]) ? state.customersState.customers[customer.toJS().urn].customer.addresses.asMutable() : [];

  return {
    isPartner,
    customer: (customer) ? customer.toJS() : null,
    addresses,
    timeStarts: state.createBookingState.get("timeStarts"),
    timeEnds: state.createBookingState.get("timeEnds"),
    tier: isPartner ? state.operator.partner.tier : state.createBookingState.get("tier"),
    therapist: isPartner ? {urn: state.operator.partner.therapistUrn} : state.createBookingState.get("therapist"),
    status: state.createBookingState.get("status"),
    address: state.createBookingState.get("address"),
    notes: state.createBookingState.get("notes"),
    treatments: state.createBookingState.get("treatments"),
    selectedTreatments: state.createBookingState.get("selectedTreatments"),
    selectedTreatmentsPrice: state.createBookingState.get("selectedTreatmentsPrice").toJS(),
    adjustments: state.createBookingState.get("adjustments").toJS(),
    selectedTreatmentsQty: state.createBookingState.get("selectedTreatmentsQty").toJS(),
    doNotSendEmail: state.createBookingState.get("doNotSendEmail"),
    isB2b: isPartner ? true : state.createBookingState.get("isB2b"),
    isProduction: state.createBookingState.get("isProduction"),
    submitting: state.createBookingState.get("isBusy"),
    promosSearch: state.promotionsState.promos,
    promosAdded: state.createBookingState.get("promos").toArray(),
    isPromoBusy: state.promotionsState.isBusy,
    submittingPromos: state.bookings.submittingPromos,
    deleting: state.bookings.deleting,
  };
};

export default withRouter(connect<ReduxProps, {}, OwnProps>(mapStateToProps)(CreateBookingContainer));
