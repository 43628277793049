import {AxiosResponse} from "axios";
import {config} from "../../config";
import * as api from "./request";

/**
 * The payload for a postcode district coming from the API
 */
export interface ApiPostcodeDistrict {
  urn: string;
  outCode: string;
  administrativeArea: string;
  localAreas: string[];
  areaCode: string;
  areaName: string;
  flags?: string[];
}

/**
 * Fetch the postcodes that are available for assigning to therapists / bookings
 */
export async function fetchPostcodesAvailableForBookings(): Promise<ApiPostcodeDistrict[]> {
  const url = `${config.adminPanelApiUrl}/postcodes/available`;

  const response: AxiosResponse = await api.get(url);

  return response.data;
}

/**
 * Update a therapist to enable/disable coverage of a postcode district
 * @param therapistUrn: the therapist URN
 * @param postcodeUrn: the postcode URN
 * @param enabled: whether to enable (true) or disable (false) the postcode for the therapist
 */
export async function updateTherapistPostcode(therapistUrn: string, postcodeUrn: string, enabled: boolean): Promise<void> {
  const urn = `${config.adminPanelApiUrl}/therapists/${therapistUrn}/postcode-coverage/${postcodeUrn}`;

  await api.post(urn, { enabled });
}