import { routerReducer as routing } from "react-router-redux";
import { combineReducers, Dispatch } from "redux";
import * as Immutable from "seamless-immutable";

import { apiActivityReducer, ApiActivityState } from "./api-activity/reducer";
import { actions as operatorActions } from "./operator/actions";
import session from "./login/reducer";
import { BookingsState, bookingsReducer } from "./bookings/reducer";
import { State as ActivityLogState, reducer as activityLogReducer } from "./booking-activity/reducer";
import { State as NotesState, reducer as notesReducer } from "./booking-notes/reducer";
import { OperatorState, operatorReducer } from "./operator/reducer";
import { TreatmentState, treatmentReducer } from "./treatments/reducer";
import { TrelloState, trelloReducer } from "./trello/reducer";
import { TherapistState, therapistReducer } from "./therapist/reducer";
import salonsState from "./salons/reducer";
import editState from "./edit/reducer";
import notifications from "./notifications/reducer";
import { CategoryState, categoryReducer } from "./categories/reducer";
import { State as CustomerState, customersState } from "./customers/reducer";
import createBookingState from "./bookings/create/reducer";
import transactionsState from "./transactions/reducer";
import { reducer as promotionsState } from "./promotions/reducer";
import { ActionType, getType } from "typesafe-actions";
import { PostcodesState, postcodesReducer } from "./postcodes/reducer";
import { apiStatusReducer } from "./api-status/reducer";
import { marketingMessageReducer, MarketingMessageState } from "./marketing-message/reducer";
import { JobState, jobReducer } from "./jobs/reducer";
import { PromotionState, promotionReducer } from "./promotion-listing/reducer";
import { PartnerState, partnersReducer } from "./partners/reducer";
import { BookingStatementState, bookingStatementReducer } from "./booking-statements/reducer";
import { FraudulentAddressState, fraudulentAddressReducer } from "./fraudulent-addresses/reducer";
import { ReviewState, reviewReducer } from "./reviews/reducer";
import { RegionState, regionReducer } from "./regions/reducer";
import { IgnoredTherapistState, ignoredTherapistReducer } from "./ignored-therapists/reducer";

export type ActionDispatch<T = void> = (dispatch: Dispatch<any>, getState?: () => RootState) => Promise<T> | T;

export type RootState = {
  session: any;
  routing: any;
  operator: OperatorState;
  bookings: BookingsState;
  trello: TrelloState;
  therapistsState: TherapistState;
  salonsState: any;
  editState: any;
  notifications: any;
  categories: CategoryState;
  customersState: Immutable.ImmutableObject<CustomerState>;
  createBookingState: any;
  transactionsState: any;
  promotionsState: any;
  postcodesState: PostcodesState;
  activityLogState: ActivityLogState;
  notesState: NotesState;
  treatments: TreatmentState;
  apiActivity: ApiActivityState;
  marketingMessage: MarketingMessageState;
  jobs: JobState;
  promotions: PromotionState;
  bookingStatements: BookingStatementState;
  fraudulentAddresses: FraudulentAddressState;
  reviews: ReviewState;
  partners: PartnerState;
  regions: RegionState;
  ignoredTherapists: IgnoredTherapistState;
};

const reducer = combineReducers<RootState>({
  operator: operatorReducer,
  bookings: bookingsReducer,
  trello: trelloReducer,
  treatments: treatmentReducer,
  categories: categoryReducer,
  activityLogState: activityLogReducer,
  notesState: notesReducer,
  session,
  therapistsState: therapistReducer,
  routing,
  salonsState,
  editState,
  notifications,
  customersState,
  createBookingState,
  transactionsState,
  promotionsState,
  postcodesState: postcodesReducer,
  apiActivity: apiActivityReducer,
  status: apiStatusReducer, // replaces apiActivityReducer
  marketingMessage: marketingMessageReducer,
  jobs: jobReducer,
  promotions: promotionReducer,
  bookingStatements: bookingStatementReducer,
  fraudulentAddresses: fraudulentAddressReducer,
  reviews: reviewReducer,
  partners: partnersReducer,
  regions: regionReducer,
  ignoredTherapists: ignoredTherapistReducer,
});

type RootAction = ActionType<typeof operatorActions>;

export const rootReducer = (state: RootState, action: RootAction) => {
  if (action.type === getType(operatorActions.logOut)) {
    state = undefined;
  }

  return reducer(state, action);
};
