import * as React from "react";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";

import {
  ApiCalendarSlotInterval, 
  ApiCalendarSlotType,
  addTherapistAvailability,
  fetchTherapistAvailabilitySlotsForDay,
  removeTherapistAvailability,
  fetchTherapistCalendar
} from "../../api/main/availability";
import { ApiTherapist } from "../../api/main/therapist";
import { AvailabilityList } from "./availability-list";
import { Button } from "../atoms/button";
import { ApiBooking } from "../../api/main/booking";

const PickerContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 20;

  i.fa {
    font-size: 24px;
  }
`;

interface OwnProps {
  urn: string;
  therapist: ApiTherapist;
}

interface State {
  day: moment.Moment;
  highlightDates: moment.Moment[];
  slots: ApiCalendarSlotInterval[];
  bookings: ApiBooking[];
  isLoading: boolean;
  updatingSlot: ApiCalendarSlotInterval;
}

type Props = OwnProps & RouteComponentProps<{}>;

const DATE_FORMAT: string = "YYYY-MM-DD";

class TherapistAvailabilityComponent extends React.Component<Props, State> {
  state: State = {
    day: moment(),
    highlightDates: [],
    slots: [],
    bookings: [],
    updatingSlot: null,
    isLoading: false
  }

  async componentDidMount(): Promise<void> {
    const day = this.state.day.format(DATE_FORMAT);
    
    await this.fetchCalendar();
    await this.fetchDetailsForDay(day);
  }

  async fetchCalendar(): Promise<void> {
    const {urn} = this.props;

    try {
      const calendar = await fetchTherapistCalendar(urn);
      const highlightSet: Set<moment.Moment> = new Set();

      for (const month of calendar) {
        for (const week of month.weeks) {
          for (const day of week) {
            if (day.hasBookings || day.isAvailable) {
              highlightSet.add(moment(day.date));
            }
          }
        }
      }

      this.setState({
        highlightDates: [...highlightSet]
      });
    } 
    catch (_err) {}
  }

  async fetchDetailsForDay(day: string): Promise<void> {
    const {urn} = this.props;

    this.setState({
      isLoading: true
    });

    try {
      const calendarDay = await fetchTherapistAvailabilitySlotsForDay(urn, day);

      this.setState({
        slots: calendarDay.slots,
        bookings: calendarDay.bookings
      });
    } finally {
      this.setState({
        isLoading: false
      });
    }
    
  }

  async componentWillUpdate(_nextProps: Props, nextState: State): Promise<void> {
    const incomingDay = nextState.day.format(DATE_FORMAT);

    if (this.state.day.format(DATE_FORMAT) !== incomingDay) {
      await this.fetchDetailsForDay(incomingDay);
    }
  }

  updateSlotType(slot: ApiCalendarSlotInterval, type: ApiCalendarSlotType): void {
    this.setState({
      slots: this.state.slots.map(
        (currSlot) => currSlot === slot ? {...slot, type} : currSlot
      )
    });
  }

  handleDateChange = (day: moment.Moment): void => {
    this.setState({
      ...this.state,
      day
    });
  }

  handleClickSetBulk = (): void => {
    this.props.history.push(`/therapists/${this.props.therapist.urn}/availability-bulk`);
  }

  handleSlotClick = async (slot: ApiCalendarSlotInterval): Promise<void> => {
    const { type, start } = slot;
    const {urn} = this.props;
    
    this.setState({
      updatingSlot: slot
    });

    try {
      switch (type) {
        case ApiCalendarSlotType.EMPTY:
          await addTherapistAvailability(urn, start, ApiCalendarSlotType.AVAILABLE);
          this.updateSlotType(slot, ApiCalendarSlotType.AVAILABLE);
        break;

        case ApiCalendarSlotType.SCHEDULED:
          await addTherapistAvailability(urn, start, ApiCalendarSlotType.UNAVAILABLE);
          this.updateSlotType(slot, ApiCalendarSlotType.UNAVAILABLE);
        break;

        case ApiCalendarSlotType.AVAILABLE:
          await removeTherapistAvailability(urn, start);
          this.updateSlotType(slot, ApiCalendarSlotType.EMPTY);
        break;

        case ApiCalendarSlotType.UNAVAILABLE:
          await removeTherapistAvailability(urn, start);
          this.updateSlotType(slot, ApiCalendarSlotType.SCHEDULED);  
        break;
      }
    } finally {
      this.setState({
        updatingSlot: null
      });      
    }
  }

  render(): JSX.Element {
    const {therapist, urn} = this.props;
    const day = this.state.day.format(DATE_FORMAT);
    const availabilitySlots = this.state.slots;
    const bookingSlots  = this.state.bookings;

    return (
      <div className="therapist-availability">
        <h1>{therapist.name}</h1>

        <div className="app-details-row">
          <div className="main-details">
            <div className="main-details-row">
              <div className="therapist-header">
                <Button label="Bulk set availability" onClick={this.handleClickSetBulk} />

                <PickerContainer>
                  <i className="fa fa-calendar"></i>
                  <DatePicker
                    selected={this.state.day}
                    onChange={this.handleDateChange}
                    highlightDates={this.state.highlightDates}
                    minDate={moment()}
                    dateFormat={"ddd, D MMM 'YY"}
                    onFocus={e => e.target.blur()}
                  />
                </PickerContainer>
              </div>

              <AvailabilityList
                therapistUrn={urn}
                bookingSlots={bookingSlots}
                day={day}
                availabilitySlots={availabilitySlots}
                isLoading={this.state.isLoading}
                onClick={this.handleSlotClick}
                updatingSlot={this.state.updatingSlot}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const TherapistAvailability = withRouter(TherapistAvailabilityComponent);
