import { useState } from "react";

import {
  ApiError,
  useRequestBookingPaymentMutation,
} from "../../../../api/types/graphql";

type UseRequestPayment = (params: { onSuccess(): void }) => {
  error: ApiError;
  isLoading: boolean;
  clearError(): void;
  requestPayment(bookingUrn: string): void;
};

export const useRequestPayment: UseRequestPayment = ({ onSuccess }) => {
  const [error, setError] = useState<ApiError>();

  const [requestBookingPayment, { loading }] = useRequestBookingPaymentMutation(
    {
      onCompleted: ({ requestBookingPayment: data }) => {
        switch (data.__typename) {
          case "BookingRequestPayment":
            setError(undefined);
            onSuccess();
            break;
          case "RuubyGraphError":
            setError(data.error);
        }
      },
      onError: () => setError(ApiError.GeneralError),
      errorPolicy: "all",
    },
  );

  const requestPayment = (bookingUrn: string): void => {
    requestBookingPayment({
      variables: {
        bookingUrn,
      },
    });
  };

  return {
    error,
    isLoading: loading,
    clearError: () => setError(undefined),
    requestPayment,
  };
};
