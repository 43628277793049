import * as React from "react";
import * as Spinner from "react-spinkit";

import { TreatmentPresenter } from "../../../presenters/treatment";
import { TherapistPresenter } from "../../../presenters/therapist";
import { Title } from "../../atoms/title";
import { TherapistTreatmentsTable } from "../therapist-treatments-table";
import { Well } from "../../atoms/well";
import { SearchableTreatmentSelect } from "../../molecules/searchable-treatment-select";

interface Props {
  therapist: TherapistPresenter;
  therapistTreatments: TreatmentPresenter[];
  searchResults: TreatmentPresenter[];
  onDelete(urn: string): void;
  onAdd(urns: string[]): Promise<void>;
  onSearch(query: string): void;
  isSearching: boolean;
}

export const TherapistTreatments: React.StatelessComponent<Props> = props => {
  if (typeof props.therapist === "undefined") {
    return <Spinner name="pulse" fadeIn="none" />;
  }

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Title>{props.therapist.name}</Title>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <Well>
            <SearchableTreatmentSelect
              treatments={props.searchResults}
              onSearch={props.onSearch}
              isBusy={props.isSearching}
              onAdd={props.onAdd}
            />
          </Well>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <TherapistTreatmentsTable
            treatments={props.therapistTreatments}
            therapistTier={props.therapist.tier}
            therapistRegions={props.therapist.regions}
            onDelete={props.onDelete}
          />
        </div>
      </div>
    </div>
  );
}
