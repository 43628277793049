import { useState, useEffect, useCallback } from "react";
import "@sendbird/uikit-react/dist/index.css";
import "./chat.scss";
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import Channel from "@sendbird/uikit-react/Channel";

import { config } from "../../config";
import { getAdminCredentials, getOrCreateChannel } from "../../api/chat";
import { RuubyChatColourSet } from "../../theme/ruuby-chat-colour-set";

interface Props {
  urn: string;
}

export const Chat = ({ urn }: Props) => {
  const [userId, setUserId] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [channelUrl, setChannelUrl] = useState("");

  const getChannelData = useCallback(async (): Promise<void> => {
    const { userId, accessToken } = await getAdminCredentials();
    const { chatUrl } = await getOrCreateChannel(urn);

    setUserId(userId);
    setAccessToken(accessToken);
    setChannelUrl(chatUrl);
  }, [urn]);

  useEffect(() => {
    getChannelData();
  }, [urn]);

  if (!accessToken) {
    return null;
  }

  return (
    <SendbirdProvider
      appId={config.sendbird.appId}
      userId={userId}
      accessToken={accessToken}
      colorSet={RuubyChatColourSet}
    >
      <Channel channelUrl={channelUrl} />
    </SendbirdProvider>
  );
};
