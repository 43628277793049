import * as Cookie from "js-cookie";
import axios from "axios";
import * as moment from "moment";
import { Dispatch } from "redux";
import { History } from "history";

import * as types from "./types";
import { config as configuration } from "../../../config";
import { NOTIFICATION_TYPES } from "../../notifications/types";
import { addNotification } from "../../notifications/actions";
import { ApiCustomer } from "../../../api/main/customer";
import { ApiTreatment, ApiAddress, ApiBookingTransaction } from "../../../api/main/booking";
import { ApiTherapist } from "../../../api/main/therapist";
import { TherapistTier } from "../../../presenters/therapist";

export const actions = {
  setBookingCustomer: (customer: ApiCustomer) => ({
    type: types.SET_BOOKING_CUSTOMER,
    payload: { customer }
  }),
  setBookingDate: (date: moment.Moment) => ({
    type: types.SET_BOOKING_DATE,
    payload: { date }
  }),
  setBookingTherapist: (therapist: ApiTherapist) => ({
    type: types.SET_BOOKING_THERAPIST,
    payload: { therapist }
  }),
  setBookingAddress: (address: ApiAddress) => ({
    type: types.SET_BOOKING_ADDRESS,
    payload: { address }
  }),
  setBookingNotes: (notes: string) => ({
    type: types.SET_BOOKING_NOTES,
    payload: { notes }
  }),
  setBookingStatus: (status: string) => ({
    type: types.SET_BOOKING_STATUS,
    payload: { status }
  }),
  setBookingTier: (tier: string) => ({
    type: types.SET_BOOKING_TIER,
    payload: { tier }
  }),
  toggleDoNotSendFlag: () => ({
    type: types.TOGGLE_DO_NOT_SEND_EMAIL_FLAG
  }),
  toggleIsB2b: () => ({
    type: types.TOGGLE_IS_B2B
  }),
  toggleIsProduction: () => ({
    type: types.TOGGLE_IS_PRODUCTION
  }),
  setBookingSelectedTreatments: (treatments: ApiTreatment[]) => ({
    type: types.SET_BOOKING_SELECTED_TREATMENTS,
    payload: { treatments }
  }),
  amendBookingTreatmentPrice: (urn: string, price: number, i: number) => ({
    type: types.AMEND_BOOKING_SELECTED_TREATMENT_PRICE,
    payload: { urn, price, i }
  }),
  fetchTherapistTreatmentsSuccess: (therapistUrn: string, treatments: ApiTreatment[]) => ({
    type: types.FETCH_THERAPIST_TREATMENTS_SUCCESS,
    payload: { treatments, therapistUrn }
  }),
  submitBookingSuccess: () => ({
    type: types.SUBMIT_BOOKING_SUCCESS
  }),
  addAdjustment: (type: string, amount: number) => ({
    type: types.ADD_ADJUSTMENT,
    payload: { type, amount }
  }),
  deleteAdjustment: (index: number) => ({
    type: types.DELETE_ADJUSTMENT,
    payload: { index }
  }),
  amendTreatmentQty: (urn: string, qty: number) => ({
    type: types.AMEND_TREATMENT_QTY,
    payload: { urn, qty }
  }),
  submitBookingAttempt: () => ({
    type: types.SUBMIT_BOOKING_ATTEMPT
  }),
  addPromo: (promoTransaction: ApiBookingTransaction) => ({
    type: types.ADD_PROMO,
    payload: { promoTransaction }
  }),
  deletePromo: (urn: string) => ({
    type: types.DELETE_PROMO,
    payload: { urn }
  }),
};

export function fetchCustomersAsync(keyword: string) {
  const url = `${configuration.adminPanelApiUrl}/customers?q=${keyword}`;
  const token = Cookie.get("token");
  const config = {
    headers: { "authorization": token }
  };

  return axios.get(url, config)
    .then((response: any) => {
      // add react-select values
      const customers = response.data.map((customer: any) => {
        customer.value = customer.urn;
        customer.label = `${customer.isB2b ? '[B2B]' : ''} ${customer.isBlackLabel ? '[BL]' : ''} ${customer.firstName
          } ${customer.lastName} (${customer.email})`;
        return customer;
      });

      return {
        options: customers
      };
    });
}

export function fetchTherapistTreatments(urn: string) {
  return (dispatch: Dispatch<any>) => {
    const url = `${configuration.adminPanelApiUrl}/therapists/${urn}/treatments`;
    const token = Cookie.get("token");
    const config = {
      headers: { "authorization": token }
    };

    axios.get(url, config)
      .then((response: { data: { treatments: ApiTreatment[] } }) => {
        dispatch(actions.fetchTherapistTreatmentsSuccess(urn, response.data.treatments));
      })
      .catch((err: any) => {
        console.error("ERROR FETCHING TREATMENTS", err);
        dispatch(addNotification(NOTIFICATION_TYPES.danger, err.message, "Error fetching salon treatments. Refresh the page to try fix"));
      });
  };
}

export function submitBooking(history: History) {
  return (dispatch: Dispatch<any>, getState: any) => {
    dispatch(actions.submitBookingAttempt());

    const url = `${configuration.adminPanelApiUrl}/bookings`;
    const token = Cookie.get("token");
    const config = {
      headers: { "authorization": token }
    };
    const state = getState().createBookingState.toJS();

    let commission = state.therapist.commission;

    if (state.tier === TherapistTier.BLACK_LABEL) {
      commission = 0.35;
    }


    // Handle commission based on tier
    if (state.isB2b) {
      if (state.tier === TherapistTier.ELITE) {
        commission = 0.40;
      }
      else if (state.tier === TherapistTier.BLACK_LABEL) {
        commission = 0.50;
      }
      else if (state.tier === TherapistTier.CLASSIC) {
        commission = 0.25;
      }
    }

    const promos = state.promos.reduce((acc: any, value: ApiBookingTransaction) => {
      acc.push({ urn: value.urn });

      return acc;
    }, []);

    const bookingData: any = {
      timeStarts: state.timeStarts.toISOString(),
      customerUrn: state.customer.urn,
      notes: state.notes,
      status: state.status,
      commission,
      addressUrn: state.address.urn,
      treatmentData: state.selectedTreatments,
      treatmentPriceData: state.selectedTreatmentsPrice,
      treatmentQtyData: state.selectedTreatmentsQty,
      therapistUrn: state.therapistUrn,
      adjustments: state.adjustments,
      promos,
      doNotSendEmail: state.doNotSendEmail,
      isB2b: state.isB2b,
      isProduction: state.isProduction,
      tier: state.tier,
    };

    axios.post(url, { bookingData }, config)
      .then((response: any) => {
        const { urn } = response.data;
        history.push(`/bookings/${urn}`);

        dispatch(actions.submitBookingSuccess());
      })
      .catch((err: any) => {
        console.error("ERROR FETCHING TREATMENTS", err);
        dispatch(addNotification(NOTIFICATION_TYPES.danger, err.message, "Error creating booking. Refresh the page and try again"));
      });
  };
}
