import * as React from "react";
import * as Select from "react-select";
import { Table } from "react-bootstrap";
import * as moment from "moment-timezone";

import EditField from "../../../sub/edit-field";
import { inflateTreatment } from "../../../../presenters/treatment";
import { TherapistTier } from "../../../../presenters/therapist";
import { calculateSurchargeForStartTime } from "../../../../model/treatment";
import { ApiTherapist } from "../../../../api/main/therapist";
import { ApiTreatment } from "../../../../api/main/treatment";

interface BookingTreatmentsProps {
  therapist: ApiTherapist;
  tier: TherapistTier;
  selectedTreatments: ApiTreatment[];
  treatments: any[];
  fetchTherapistTreatments: (urn: string) => void;
  setBookingSelectedTreatments: (treatments: any[]) => void;
  amendBookingTreatmentPrice: (urn: string, price: number, i: number) => void;
  selectedTreatmentsPrice: any[];
  amendTreatmentQty: (urn: string, qty: number) => void;
  selectedTreatmentsQty: any[];
  timeStarts: moment.Moment;
}

export default class BookingTreatments extends React.Component<BookingTreatmentsProps, {}> {

  handleTreatmentSelect = (options: any) => {
    const treatments = options.map((option: any) => this.props.treatments.find((t: any) => t.urn === option.value));
    this.props.setBookingSelectedTreatments(treatments);
  }

  onQtyChangeHandler = (urn: string, option: any) => {
    this.props.amendTreatmentQty(urn, option.value);
  }

  savePriceHandler = (urn: string, value: string, i: number) => {
    this.props.amendBookingTreatmentPrice(urn, Number.parseInt(value), i);
  }

  componentWillReceiveProps(nextProps: BookingTreatmentsProps) {
    if (this.props.therapist.urn !== nextProps.therapist.urn) {
      const urn: string = nextProps.therapist.urn;
      this.props.fetchTherapistTreatments(urn);
    }
  }

  renderBookingTreatmentsRows = (): JSX.Element[] => {
    if (typeof this.props.timeStarts === "undefined") {
      return [];
    }

    const bookingTreatments = this.props.selectedTreatments;
    const startTimeIndex = this.props.timeStarts.clone();

    let treatmentPresenters = bookingTreatments
      .map(inflateTreatment)
      // sort most expensive last
      .sort((a, b) => {
        const priceA = a.getPrice(TherapistTier.CLASSIC, this.props.therapist.regions,);
        const priceB = b.getPrice(TherapistTier.CLASSIC, this.props.therapist.regions,);

        if (priceA > priceB) {
          return -1;
        }
        else if (priceB > priceA) {
          return 1;
        }
        else {
          return 0;
        }
      });

    // if in afternoon, sort most expensive last
    if (this.props.timeStarts.hour() < 12) {
      treatmentPresenters = treatmentPresenters.reverse();
    }

    return treatmentPresenters.map((treatment, i: number) => {
      const amended = this.props.selectedTreatmentsPrice.find(t => t.urn === treatment.urn);
      let options = [];
      for (let j = 1; j < 10; j++)
        options.push({
          value: j,
          label: JSON.stringify(j),
        });

      const amendedQty = this.props.selectedTreatmentsQty.find((t: any) => t.urn === treatment.urn);
      const value = amendedQty ? amendedQty.qty : 1;

      const start = startTimeIndex.clone();
      startTimeIndex.add(treatment.duration * value, "minutes");

      const surcharge = calculateSurchargeForStartTime(
        this.props.tier,
        start,
        startTimeIndex.clone(),
      );

      const price = treatment.getPrice(
        this.props.tier,
        this.props.therapist.regions,
        surcharge,
      );

      // // add new price to amended prices
      // this.props.amendBookingTreatmentPrice(treatment.urn, price, 1);

      // horrible hack to set price in object
      (this.props.selectedTreatments.find(t => t.urn === treatment.urn) as any).price = price;

      // TODO: When edited for first time after editing the price still reflects old price in input. Doesn't take value from amended
      return (
        <tr key={i + treatment.urn} className="create-booking-treatment-row">
          <td>{treatment.name}</td>
          <td>
            <EditField dbKey="price" value={(amended) ? amended.price : price} type="price" saveHandler={(_key: string, value: string) => this.savePriceHandler(treatment.urn, value, i)} />
          </td>
          <td>
            <Select
              value={value}
              options={options}
              onChange={option => this.onQtyChangeHandler(treatment.urn, option)}
              clearable={false} />
          </td>
        </tr>
      );
    });
  }

  renderLabel(treatment: any): string {
    return treatment.isBlackLabel
      ? `CELEBRITY - ${treatment.name}`
      : treatment.isCorporate
        ? `CORPORATE - ${treatment.name}`
        : `${treatment.topLevelCategory} - ${treatment.name}`;
  }

  render() {
    const { therapist, treatments, selectedTreatments } = this.props;
    if (!treatments) {
      const urn: string = therapist.urn;
      this.props.fetchTherapistTreatments(urn);
    }

    const options = (treatments) ? treatments.map(t => ({
      value: t.urn,
      label: this.renderLabel(t),
    })) : [];

    const values = (selectedTreatments) ? selectedTreatments.map((treatment: any) => ({
      value: treatment.urn,
      label: this.renderLabel(treatment),
    })) : [];

    return (
      <div className="main-details-row">
        <div className="booking-notes">
          <div className="detail-heading">
            Add {therapist.name} treatments to Booking
          </div>
          <div className="detail-value">
            <div className="field-edit">
              <div className="input-box">
                <Select
                  options={options}
                  multi
                  onChange={this.handleTreatmentSelect}
                  value={values} />
              </div>
            </div>
          </div>
          <div className="detail-value">
            <Table>
              <colgroup>
                <col width="70%" />
                <col width="20%" />
                <col width="10%" />
              </colgroup>

              <thead>
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Qty</th>
                </tr>
              </thead>

              <tbody>
                {this.props.selectedTreatments && this.renderBookingTreatmentsRows()}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}
