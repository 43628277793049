import * as React from "react";
import styled from "styled-components";
import * as moment from "moment";

import { FieldLabel } from "../../atoms/field-label";

const Container = styled.div`
  width: 100%;
  display: inline-flex;
`;

interface Props {
  label: string;
  dateTime: moment.Moment;
}

export const FieldDateTime: React.StatelessComponent<Props> = props => (
  <div>
    <FieldLabel>{props.label}</FieldLabel>

    <Container>
      {props.dateTime.format("D MMM YYYY - HH:mm:ss")}
    </Container>
  </div>
);
