import { createStandardAction } from "typesafe-actions";
import { ActionDispatch } from "../";
import * as reviewApi from "../../api/main/reviews";
import { addNotification } from "../notifications/actions";
import { NOTIFICATION_TYPES } from "../notifications/types";

interface RequestSuccessPayload {
    reviews: reviewApi.ApiReview[];
}

export interface Review {
    review: string;
    rating: number;
    customer: string;
}

export const actions = {
    requestGetAllReviewsInit: createStandardAction("reviews/REQUEST_GET_ALL_REVIEWS_INIT")<void>(),
    requestGetAllReviewsSuccess: createStandardAction("reviews/REQUEST_GET_ALL_REVIEWS_SUCCESS")<RequestSuccessPayload>(),

    requestDeleteReviewInit: createStandardAction("reviews/REQUEST_DELETE_REVIEW_INT")<void>(),
    requestDeleteReviewSuccess: createStandardAction("reviews/REQUEST_DELETE_REVIEW_SUCCESS")<{ bookingUrn: string }>(),
};

export function fetchAllReviews(therapistUrn: string): ActionDispatch {
    return async dispatch => {
        dispatch(actions.requestGetAllReviewsInit());

        const reviews = await reviewApi.fetchReviews(therapistUrn);

        dispatch(actions.requestGetAllReviewsSuccess({ reviews }));
    };
}

export function deleteReview(bookingUrn: string): ActionDispatch {
    return async (dispatch) => {
        dispatch(actions.requestDeleteReviewInit());

        try {
            await reviewApi.deleteReview(bookingUrn);
            dispatch(actions.requestDeleteReviewSuccess({ bookingUrn }));
            dispatch(
                addNotification(
                    NOTIFICATION_TYPES.success,
                    "Deleted!",
                    "Review has been deleted successfully",
                ),
            );
        } catch (error) {
            dispatch(
                addNotification(
                    NOTIFICATION_TYPES.danger,
                    "Error deleting review",
                    error.message,
                ),
            );
        }
    };
}
