import * as React from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import * as Select from "react-select";
import * as Modal from "react-modal";
import styled from "styled-components";

import { ApiSearchQuery, ApiTherapist } from "../api/main/therapist";
import { ApiTreatment } from "../api/main/treatment";
import { ApiCategory } from "../api/main/category";
import { fetchTherapists, fetchPartnerTherapists } from "../model/therapist";
import { fetchAllCategories } from "../reducers/categories/actions";
import { fetchAllTreatments } from "../reducers/treatments/actions";
import { fetchAllRegions } from "../reducers/regions/actions";
import { CategoryPresenter, inflateCategory } from "../presenters/category";
import { inflateArrayFromMap } from "../presenters/utils";
import TherapistsTree from "../components/therapists/therapists-tree";
import TherapistsGallery from "../components/therapists/therapists-gallery";
import { Well, WellRow } from "../components/atoms/well";
import { DebouncedSearchInput } from "../components/molecules/debounced-search-input";
import { TherapistsBulkChatModal } from "../components/molecules/therapists-bulk-chat-modal";
import { InputText } from "../components/atoms/input-text";
import { Button } from "../components/atoms/button";
import { RootState } from "../reducers";
import { Operator } from "../presenters/operator";
import Table from "../components/table";
import { ColumnNames } from "../components/table";
import { TherapistTier } from '../presenters/therapist';

import { config } from "../config";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { blastMessage } from "../api/chat";
import { addNotification } from "../reducers/notifications/actions";
import { NOTIFICATION_TYPES } from "../reducers/notifications/types";

const therapistModalStyling: Modal.Styles = {
  content: {
    height: "fit-content",
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",
    border: "0px",
    backgroundColor: "#efefef",
  },
  overlay: {
    zIndex: 3,
  },
};

const TherapistModalContainer = styled.div`
  background-color: #efefef;
`;

const TherapistModalHeading = styled.div`
  background-color: #396174;
  color: #ffffff;
  margin: 0px;
  padding: 10px;
  text-transform: capitalize;
`;

const TherapistModalBody = styled.div`
  margin: 0;
  padding: 20px;
  min-height: 100px;
  text-align: left;
`;

const TherapistModalImageContainer = styled.div`
  margin: 0;
  padding: 20px;
  float: left;
  background-color: rgb(222, 222, 222);
  text-align: left;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
`;

const TherapistModalImage = styled.img`
  width: 200px;
  height: 200px;
  margin-left: 10px;
  margin-right: 10px;
  object-fit: cover;
`;

const TherapistModalFooter = styled.div`
  margin: 0;
  padding: 20px;
  float: left;
  background-color: rgb(222, 222, 222);
  width: 100%;
  text-align: left;
`;

const TherapistNoThumbnail = styled.div`
  width: 200px;
  height: 200px;
  font-size: 160px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Cell = styled.div`
  float: left;
`;

interface Option {
  value: string;
  label: string;
}

interface State {
  isShowChatModal: boolean;
  isShowAdvancedSearch: boolean;
  isChatModalConfirmLoading: boolean;
  isShowTherapistModal: boolean;
  query: string;
  postcodes: string;
  categories: string;
  message: string;
  selectedRegion: string;
  selectedTherapists: string[];
  clickedTherapist: ApiTherapist;
  allTherapistChecked: boolean;
  selectedShiftIndex: number;
  isTreeViewEnabled: boolean;
}

interface Props {
  fetchAllCategories: () => void;
  fetchAllTreatments: () => void;
  fetchAllRegions: (showOnlyActive?: boolean) => void;
  fetchTherapists: (query?: ApiSearchQuery) => void;
  fetchPartnerTherapists(id: string): void;
  addNotification(type: string, headline: string, message?: string): void;
  categories: CategoryPresenter[];
  treatments: {
    [urn: string]: ApiTreatment;
  };
  therapists: {
    [urn: string]: ApiTherapist;
  };
  sortedTherapists: string[];
  isBusy: boolean;
  operator: Operator;
  regions: Option[];
  history: any;
}

class TherapistsContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isShowChatModal: false,
      isShowAdvancedSearch: false,
      isChatModalConfirmLoading: false,
      isShowTherapistModal: false,
      query: undefined,
      postcodes: undefined,
      categories: undefined,
      message: undefined,
      selectedRegion: undefined,
      selectedTherapists: [],
      clickedTherapist: undefined,
      allTherapistChecked: false,
      selectedShiftIndex: undefined,
      isTreeViewEnabled: false,
    };
  }

  componentDidMount() {
    if (this.props.sortedTherapists.length === 0) {
      if (!this.props.operator.permissions.all) {
        this.props.fetchPartnerTherapists(this.props.operator.id);
      } else {
        this.props.fetchTherapists();
      }
    }
    this.props.fetchAllCategories();
    this.props.fetchAllTreatments();
    this.props.fetchAllRegions(true);
  }

  queryTherapists = (query: string): void => {
    const searchQuery = query.length ? query : undefined;

    this.setState({ query: searchQuery });
    this.props.fetchTherapists({ query: searchQuery, limit: 50 });
  };

  toggleAdvanceSearch = (): void => {
    this.state.isShowAdvancedSearch
      ? this.setState({ isShowAdvancedSearch: false })
      : this.setState({ isShowAdvancedSearch: true });

    if (this.state.isShowAdvancedSearch) {
      this.setState(
        {
          query: undefined,
          postcodes: undefined,
          categories: undefined,
          selectedRegion: undefined,
        },
        () => {
          this.props.fetchTherapists();
        },
      );
    }
  };

  toggleView = (): void => {
    this.setState({
      isTreeViewEnabled: !this.state.isTreeViewEnabled,
    });
  };

  searchAdvanced = (): void => {
    const searchQuery = {
      postcodes: this.state.postcodes && this.state.postcodes.toUpperCase(),
      categories: this.state.categories,
      region: this.state.selectedRegion,
    };

    this.props.fetchTherapists(searchQuery);
  };

  sendMessages = async (): Promise<void> => {
    if (!this.state.message) {
      return;
    }
    
    try {
      this.setState({ isChatModalConfirmLoading: true });

      await blastMessage(
        this.state.selectedTherapists,
        this.state.message,
      );
  
      this.setState({ selectedTherapists: [], allTherapistChecked: false });
      this.closeChatModal();
    } catch (err) {
      this.props.addNotification(
        NOTIFICATION_TYPES.danger, 
        "Error sending a message to selected therapists",
      );
    } finally {
      this.setState({ isChatModalConfirmLoading: false });
    }
  };

  closeChatModal = (): void => {
    this.setState({
      isShowChatModal: false,
      isChatModalConfirmLoading: false,
      message: undefined,
    });
  };

  toggleSelectTherapist = (therapist: string, index?: number): void => {
    if (!index && this.state.selectedTherapists.indexOf(therapist) > -1) {
      const removedTherapist = this.state.selectedTherapists.filter(
        (id) => id !== therapist,
      );
      this.setState({
        selectedTherapists: removedTherapist,
        allTherapistChecked: false,
        selectedShiftIndex: undefined,
      });
    } else {
      const addedTherapist = [...this.state.selectedTherapists, therapist];
      this.setState({ selectedTherapists: addedTherapist }, () => {
        if (typeof index !== "undefined") {
          this.therapistShiftSelect(index);
        }
      });
    }
  };

  therapistShiftSelect = (index: number): void => {
    const newSelectedShiftIndex = index;
    if (
      newSelectedShiftIndex > this.state.selectedShiftIndex ||
      newSelectedShiftIndex < this.state.selectedShiftIndex
    ) {
      const selectedRangeIds = this.props.sortedTherapists.filter(
        (_, index) =>
          (index > this.state.selectedShiftIndex &&
            index < newSelectedShiftIndex) ||
          (index < this.state.selectedShiftIndex &&
            index > newSelectedShiftIndex),
      );

      this.setState({
        selectedTherapists: [
          ...this.state.selectedTherapists,
          ...selectedRangeIds,
        ],
      });
    }

    this.setState({ selectedShiftIndex: newSelectedShiftIndex });
  };

  toggleSelectAllTherapist = (): void => {
    const checkbox = this.state.allTherapistChecked ? false : true;
    this.setState({ allTherapistChecked: checkbox }, () => {
      if (checkbox) {
        const selectedTherapists = this.props.sortedTherapists.map((id) => id);

        this.setState({ selectedTherapists });
      } else {
        this.setState({
          selectedTherapists: [],
          selectedShiftIndex: undefined,
        });
      }
    });
  };

  toggleMultipleTherapists = (
    therapists: Set<ApiTherapist>,
    state: boolean,
  ) => {
    const selectedTherapists = new Set([...this.state.selectedTherapists]);
    const setMethod = state ? "add" : "delete";

    therapists.forEach((therapist) => {
      selectedTherapists[setMethod](therapist.urn);
    });

    this.setState({
      selectedTherapists: [...selectedTherapists],
    });
  };

  openTherapistModal = (therapist: ApiTherapist): void => {
    this.setState({
      ...this.state,
      isShowTherapistModal: true,
      clickedTherapist: therapist,
    });
  };

  closeTherapistModal = (): void => {
    this.setState({
      ...this.state,
      isShowTherapistModal: false,
      clickedTherapist: undefined,
    });
  };

  // Select a therapist when logged in as a partner.
  selectTherapistAsPartner = (e: Event, therapist: ApiTherapist): void => {
    this.openTherapistModal(therapist);

    e.preventDefault();
  };

  // TODO: workaround to filter own therapist profile from the list
  // Logic is used for getting treatments for the therapist in bookings details
  // leave it as is for now, big effort to rewrite and avoid regression
  getPartnerTherapists = (): ApiTherapist[] => {
    return this.props.sortedTherapists
      .filter((t) => t !== `${this.props.operator.partner.therapistUrn}-1`)
      .map((id) => this.props.therapists[id]);
  };

  renderViews() {
    if (this.state.isTreeViewEnabled) {
      return (
        <TherapistsTree
          categories={this.props.categories}
          treatments={this.props.treatments}
          therapists={this.props.sortedTherapists.map(
            (id) => this.props.therapists[id],
          )}
          onMultipleTherapistsToggle={this.toggleMultipleTherapists}
          onTherapistSelect={this.toggleSelectTherapist}
          onTherapistShiftSelect={this.toggleSelectTherapist}
          onAllSelect={this.toggleSelectAllTherapist}
          selectedTherapists={this.state.selectedTherapists}
          allTherapistChecked={this.state.allTherapistChecked}
        />
      );
    } else {
      return this.renderTable();
    }
  }

  mapDataset(data: ApiTherapist[]) {
    return data.map((therapist) => {
      const obj = {};
      obj["_id"] = therapist.urn;
      Object.entries(therapist).map(([key, value]) => {
        switch (key) {
          case "urn": {
            const checked =
              this.state.selectedTherapists.indexOf(therapist.urn) > -1;

            const component = (
              <Cell key={key} style={{ width: "10%" }}>
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={() => this.toggleSelectTherapist(therapist.urn)}
                  onClick={() => this.toggleSelectTherapist(therapist.urn)}
                  style={{
                    height: "20px",
                    width: "20px",
                    float: "left",
                    padding: 0,
                    margin: 0,
                  }}
                />
                <a
                  style={{
                    color: "black",
                    textDecoration: "none",
                    float: "left",
                    marginLeft: "5px",
                  }}
                >
                  {value.slice(value.lastIndexOf(":") + 1, value.length)}{" "}
                </a>
              </Cell>
            );
            obj[key] = component;
            break;
          }
          case "email": {
            const component = (
              <Cell key={key} style={{ width: "20%" }}>
                <a style={{ color: "black", textDecoration: "none" }}>
                  {value}{" "}
                </a>
              </Cell>
            );
            obj[key] = component;
            break;
          }
          case "telephone": {
            const component = (
              <Cell key={key} style={{ width: "10%" }}>
                <a style={{ color: "black", textDecoration: "none" }}>
                  {value}{" "}
                </a>
              </Cell>
            );
            obj[key] = component;
            break;
          }

          case "recommended": {
            const component = (
              <Cell key={key} style={{ width: "10%" }}>
                <a style={{ color: "black", textDecoration: "none" }}>
                  {value ? "\u2605" : ""} &nbsp;
                </a>
              </Cell>
            );
            obj[key] = component;
            break;
          }

          case "name": {
            const component = (
              <Cell key={key} style={{ width: "20%" }}>
                <a
                  onClick={() => this.props.history.push(`/therapists/${therapist.urn}`)}
                  style={{
                    textDecoration: "underline",
                    color: "#666666",
                    cursor: "pointer"
                  }}
                >
                  {value}
                </a>
              </Cell>
            );
            obj[key] = component;
            break;
          }

          case "regions": {
            const component = (
              <Cell key={key} style={{ width: "8%" }}>
                {value.length === 0 ? <>&nbsp;</> : value}
              </Cell>
            );
            obj[key] = component;
            break;
          }

          case "isAutoSetAvailability": {
            const component = (
              <Cell key={key} style={{ width: "10%", textAlign: "center" }}>
                {value === true ? "ON" : "OFF"}
              </Cell>
            );
            obj[key] = component;
            break;
          }

          case "tier": {
            const tierValue = value === TherapistTier.BLACK_LABEL ? 'CELEBRITY' : value;
            const component = (
              <Cell key={key} style={{ width: "10%" }}>
                {tierValue}
              </Cell>
            );
            obj[key] = component;
            break;
          }

          default: {
            const component = (
              <Cell key={key} style={{ width: "10%" }}>
                <a style={{ color: "black", textDecoration: "none" }}>
                  {value}{" "}
                </a>
              </Cell>
            );
            obj[key] = component;
            break;
          }
        }
      });
      return obj;
    });
  }

  renderTable() {
    const columns: ColumnNames[] = [
      { title: "ID", key: "urn", width: "10%" },
      { title: "Name", key: "name", width: "20%" },
      { title: "Tier", key: "tier", width: "10%" },
      { title: "Rec", key: "recommended", width: "10%" },
      { title: "Email", key: "email", width: "20%" },
      { title: "Phone", key: "telephone", width: "10%" },
      { title: "Region", key: "regions", width: "8%" },
      { title: "Auto set availability", key: "isAutoSetAvailability", width: "10%" },

    ];

    const therapists = this.props.sortedTherapists.map(
      (id) => this.props.therapists[id],
    );
    const dataset: any = this.mapDataset(therapists);

    // therapists={this.props.sortedTherapists.map(
    //   (id) => this.props.therapists[id],
    // )}
    // onTherapistSelect={this.toggleSelectTherapist}
    // onTherapistShiftSelect={this.toggleSelectTherapist}
    // onAllSelect={this.toggleSelectAllTherapist}
    // selectedTherapists={this.state.selectedTherapists}
    // allTherapistChecked={this.state.allTherapistChecked}

    return (
      <React.Fragment>
        <div style={{ padding: "10px", height: "50px" }}>
          <input
            type="checkbox"
            onChange={() => this.toggleSelectAllTherapist()}
            onClick={() => this.toggleSelectAllTherapist()}
            checked={this.state.allTherapistChecked}
            style={{
              height: "20px",
              width: "20px",
              float: "left",
              padding: 0,
              margin: 0,
            }}
          />
          <a
            onClick={() => { this.toggleSelectAllTherapist() }}
            style={{
              textDecoration: "underline",
              color: "#666666",
              float: "left",
              fontSize: "16px",
              padding: 0,
              marginLeft: "10px",
              cursor: "pointer",
            }}
          >
            Select all therapists
          </a>
        </div>
        <Table
          tableName={"Therapists"}
          createEntryAction={null}
          columns={columns}
          dataset={dataset}
          rowActions={null}
          emptyMessage={"No Therapists to show."}
          isLoading={this.props.isBusy}
        />
      </React.Fragment>
    );
  }

  render() {
    return (
      <>
        {!this.props.operator.permissions.all ? (
          <>
            <Modal
              isOpen={this.state.isShowTherapistModal}
              style={therapistModalStyling}
              ariaHideApp={false}
            >
              <TherapistModalContainer>
                <TherapistModalHeading>
                  {this.state.clickedTherapist &&
                    this.state.clickedTherapist.name}
                </TherapistModalHeading>
                <TherapistModalBody>
                  {this.state.clickedTherapist &&
                    this.state.clickedTherapist.description}
                </TherapistModalBody>
                <TherapistModalImageContainer>
                  {this.state.clickedTherapist &&
                    this.state.clickedTherapist.images ? (
                    Object.keys(this.state.clickedTherapist.images).map(
                      (key) => {
                        const image = this.state.clickedTherapist.images[key];
                        return (
                          <TherapistModalImage
                            src={`${config.s3.bucket}${image.src}`}
                          />
                        );
                      },
                    )
                  ) : (
                    <TherapistNoThumbnail
                      className="fa fa-user-circle-o"
                      aria-hidden="true"
                    ></TherapistNoThumbnail>
                  )}
                </TherapistModalImageContainer>
                <TherapistModalFooter>
                  <Button
                    label="Close"
                    onClick={() => this.closeTherapistModal()}
                  />
                </TherapistModalFooter>
              </TherapistModalContainer>
            </Modal>
            <TherapistsGallery
              onSelect={this.selectTherapistAsPartner}
              therapists={this.getPartnerTherapists()}
            />
          </>
        ) : (
          <div>
            <Well>
              <WellRow>
                {this.state.selectedTherapists.length > 0 && (
                  <Button
                    onClick={() => this.setState({ isShowChatModal: true })}
                    label="Send message to selected therapists"
                    icon="fa-comments-o"
                    className="btn-success push-right-5"
                  ></Button>
                )}
                {!this.state.isShowAdvancedSearch && (
                  <DebouncedSearchInput onChange={this.queryTherapists} />
                )}
                {this.state.isShowAdvancedSearch && (
                  <React.Fragment>
                    <div className="input-group" style={{ flexGrow: 1 }}>
                      <InputText
                        onChange={(postcodes: string) =>
                          this.setState({ postcodes })
                        }
                        placeholder="Postcode"
                        className="form-control"
                        autoFocus={true}
                      ></InputText>
                    </div>
                    <div className="input-group" style={{ flexGrow: 1 }}>
                      <InputText
                        onChange={(categories: string) =>
                          this.setState({ categories })
                        }
                        placeholder="Category"
                        className="form-control"
                      ></InputText>
                    </div>
                    <div className="input-group" style={{ flexGrow: 1 }}>
                      <Select
                        placeholder="Region"
                        value={this.state.selectedRegion}
                        options={this.props.regions}
                        onChange={(selectedOption: Option) => {
                          this.setState({
                            selectedRegion:
                              (selectedOption && selectedOption.value) ||
                              undefined,
                          });
                        }}
                      />
                    </div>
                    <Button
                      onClick={this.searchAdvanced}
                      label="Search"
                    ></Button>
                  </React.Fragment>
                )}
                <Button
                  onClick={this.toggleAdvanceSearch}
                  label={
                    this.state.isShowAdvancedSearch
                      ? "close"
                      : "Advanced Search"
                  }
                  icon={
                    this.state.isShowAdvancedSearch ? "fa-close" : undefined
                  }
                ></Button>
                <Button
                  onClick={this.toggleView}
                  label={
                    this.state.isTreeViewEnabled ? "Table View" : "Tree View"
                  }
                ></Button>
              </WellRow>
            </Well>

            {this.renderViews()}

            <TherapistsBulkChatModal
              closeModal={this.closeChatModal}
              handleConfirm={this.sendMessages}
              confirmLoading={this.state.isChatModalConfirmLoading}
              handleMessage={(message: string) => this.setState({ message })}
              isOpen={this.state.isShowChatModal}
              message={this.state.message}
              therapistsCount={this.state.selectedTherapists.length}
              therapists={this.state.selectedTherapists.map(
                (id) => this.props.therapists[id],
              )}
            />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  categories: inflateArrayFromMap<ApiCategory, CategoryPresenter>(
    state.categories.categories,
    state.categories.searchResultUrns,
    inflateCategory,
  ),
  treatments: state.treatments.treatments,
  therapists: state.therapistsState.therapists,
  sortedTherapists: state.therapistsState.sortedTherapists,
  isBusy: state.therapistsState.isBusy,
  operator: state.operator,
  regions: state.regions.regions.map((region) => {
    return {
      label: region.name,
      value: region.name,
    };
  }),
});
const mapDispatchToProps = (dispatch: Dispatch<any>) =>
  bindActionCreators(
    {
      fetchAllCategories,
      fetchAllTreatments,
      fetchAllRegions,
      fetchTherapists,
      fetchPartnerTherapists,
      addNotification,
    },
    dispatch,
  ) as any;

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(TherapistsContainer);
