import * as React from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";

import { removeNotification } from "../reducers/notifications/actions";
import Notifications from "../components/notifications/notifications";

interface NotificationsContainerProps {
  notifications: any[];
  removeNotification: () => void;
}

class NotificationsContainer extends React.Component<NotificationsContainerProps, {}> {
  render() {
    return (<Notifications notifications={this.props.notifications} onDismissNotification={this.props.removeNotification} />);
  }
}

const mapStateToProps = (state: any) => {
  return {
    notifications: state.notifications.toJS()
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return bindActionCreators({ removeNotification }, dispatch) as any;
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsContainer);
