import { useCallback, useMemo, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import ReactModal from "react-modal";
import Spinner from "react-spinkit";
import styled from "styled-components";

import { ApiBooking } from "../../../../api/main/booking";
import { useCustomerPaymentMethods } from "../use-customer-payment-methods";
import { TRANSACTION_INVOICE } from "../../../../constants";
import { config } from "../../../../config";
import { useCreateTransaction } from "../use-create-transaction";
import { Button } from "../../../atoms/button";
import { getRequestPaymentError } from "../../request-payment-modal";
import { StripeCard } from "./components/stripe-card";

const Actions = styled.div`
  display: flex;
  gap: 10px;
  width: 100px;
  float: right;
`;

const ActionsLabel = styled.h5`
  line-height: 30px;
  padding-left: 100px;
`;

type CreateStripeTransactionProps = {
  isOpen: boolean;
  booking: ApiBooking;
  closeModal: () => void;
  addTransaction: (type: string, amount: number) => void;
};

enum PaymentType {
  CARD = "card",
  INVOICE = "invoice",
};

export const CreateStripeTransaction = ({ isOpen, booking, addTransaction, closeModal } : CreateStripeTransactionProps) : JSX.Element => {
  const [type, setType] = useState(PaymentType.CARD);
  const [amount, setAmount] = useState(0);
  const [activePaymentMethodId, setActivePaymentMethodId] = useState("");
  const [isSubmiting] = useState(false);
  const [error] = useState("");
  const { stripeId, customerPaymentMethods, isLoading: isLoadingPaymentInfo, refetch: reloadCustomerPaymentMethods } = useCustomerPaymentMethods(booking.customer.urn);

  const canSubmit = useMemo(() => amount !== 0 && (type === PaymentType.INVOICE || activePaymentMethodId !== ''), [amount, type, activePaymentMethodId]);

  const close = useCallback(() => {
    setAmount(0);
    setActivePaymentMethodId("");
    setType(PaymentType.CARD);
    closeModal();
  }, [closeModal]);

  const onSuccess = useCallback(() => {
    close();
    window.location.reload();
  }, []);

  const { createTransaction, error: createTransactionError, isLoading: isLoadingTransaction } = useCreateTransaction({ onSuccess });

  const openStripeDashboard = useCallback(() => {
    const isProduction = config.environment === "production";
    const dashboardUrl = `${config.stripe.dashboard.url}/${isProduction ? '' : 'test/'}customers/${stripeId}`;
    window.open(dashboardUrl, "_blank");
  }, [config, stripeId]);

  const handleSubmit = useCallback(() => {
    if (type === PaymentType.CARD) {
      createTransaction({
        amount,
        paymentMethodId: activePaymentMethodId,
        bookingUrn: booking.urn,
      });
    } else if (type === PaymentType.INVOICE) {
      addTransaction(TRANSACTION_INVOICE, Number(amount));
      close();
    }
  }, [type, amount, activePaymentMethodId, booking, createTransaction, addTransaction, close]);

  const modalStyling: ReactModal.Styles = {
    content: {
      height: "fit-content",
      width: "50%",
      top: "50%",
      left: "50%",
      transform: "translateY(-50%) translateX(-50%)",
      overflowX: "hidden"
    },
    overlay: {
      zIndex: 3
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={modalStyling}
      contentLabel="Create New Transaction"
      onRequestClose={close}
      appElement={document.getElementById('root') || undefined}
    >
      <div className="new-transaction-modal">
        <div className="row new-transaction-title">
          <div className="fa fa-times" onClick={close}></div>
          <div className="col-md-12">
            <h3>Create Transaction</h3>
          </div>
        </div>
        <div className="row new-transaction-row">
          <div className="col-md-12">
            <h5>Amount</h5>
            <div className="input-group">
              <span className="input-group-addon" id="pound">£</span>
              <input type="number" className="form-control" onChange={e => setAmount(e.target.valueAsNumber)} value={amount} min="0" step="0.01" />
            </div>
          </div>
        </div>
        <Tabs id="type-selector" defaultActiveKey={PaymentType.CARD} onSelect={(key: any) => setType(key)}>
          <Tab eventKey={PaymentType.CARD} title="Card">
            <div className="row new-transaction-row">
              <div className="col-md-12">
                <Actions>
                  <Button style="link" className="button-sm" icon="fa-refresh" disabled={isLoadingPaymentInfo} onClick={reloadCustomerPaymentMethods} label=""/>
                  <Button style="link" className="button-sm" icon="fa-plus" disabled={isLoadingPaymentInfo} onClick={openStripeDashboard} label=""/>
                </Actions>
                <ActionsLabel>
                  Select Card
                </ActionsLabel>
                {!isLoadingPaymentInfo ?
                  <ul className="list-group">
                    {customerPaymentMethods.map(paymentMethod => (
                      <li key={`payment-method-${paymentMethod.id}`}
                        className={`list-group-item ${(activePaymentMethodId === paymentMethod.id) ? "active" : ""}`}
                        onClick={() => setActivePaymentMethodId(paymentMethod.id)}
                      >
                        <StripeCard paymentMethod={paymentMethod} />
                      </li>)
                    )}
                  </ul>
                : <Spinner name="pulse" fadeIn="none" />}
              </div>
            </div>
          </Tab>
          <Tab eventKey={PaymentType.INVOICE} title="Invoice">
          <div className="row new-transaction-row">
            <div className="col-md-12">
              <h5>Paid by Invoice</h5>
            </div>
          </div>
          </Tab>
        </Tabs>
        {createTransactionError && (
          <div className="row new-transaction-row justify-content-md-center">
            <div className="col-md-12 col-error">
              <p>{`${getRequestPaymentError(createTransactionError)}`}</p>
            </div>
          </div>
        )}
        <div className="row new-transaction-row">
          <div className="col-md-12">
            {!isSubmiting ?
              <Button 
                className={`btn btn-submit btn-primary ${!canSubmit ? "disabled" : ""}`} 
                disabled={!canSubmit} 
                onClick={handleSubmit} 
                isBusy={isLoadingTransaction} 
                label="Confirm" 
              />
            : <Spinner name="pulse" fadeIn="none" />}
          </div>
          </div>
          <div className="row new-transaction-row justify-content-md-center">
            <div className="col-md-12">
              {error && <p>{`Error: ${error}`}</p>}
            </div>
          </div>
      </div>
    </ReactModal>
  );
};