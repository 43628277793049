import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as Spinner from "react-spinkit";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {BookingSubMenu} from "../../components/sub/sub-menu";
import {RootState} from "../../reducers";
import * as notificationActions from "../../reducers/notifications/actions";
import * as bookingsActions from "../../reducers/bookings/actions";
import * as customerActions from "../../reducers/customers/actions";
import * as paymentActions from "../../reducers/transactions/actions";
import {Booking} from "../../components/booking/booking";
import {BookingPartner} from "../../components/booking/booking-partner";
import * as promotionActions from "../../reducers/promotions/actions";
import { Promotion } from "../../api/main/promotions";
import {ApiBooking, ApiRefund, BookingUpdateField, BookingUpdateValue, ApiAddress, manualRefundBooking} from "../../api/main/booking";
import { initiateViewTherapistTreatments } from "../../model/therapist";
import { inflateArrayFromMap } from "../../presenters/utils";
import { inflateTreatment, TreatmentPresenter } from "../../presenters/treatment";
import { ApiTherapist } from "../../api/main/therapist";

interface ReduxProps {
  deleting: {
    [name: string]: boolean;
  };
  treatments: TreatmentPresenter[];
  booking: ApiBooking;
  addresses: ApiAddress[];
  isEditing: boolean;
  cards: any[];
  transactionState: any;
  submittingPromos: boolean;
  promos: Promotion[];
  isPromoBusy: boolean;
  isPartner: boolean;
  therapist: ApiTherapist;
}

type OwnProps = RouteComponentProps<{urn: string}>;

type Props = ReduxProps & OwnProps & {dispatch: Dispatch<{}>};

class Component extends React.Component<Props> {
  componentDidMount() {
    this.props.dispatch(bookingsActions.initiateBookingScreen(this.props.match.params.urn));
  }

  handleUpdateBooking = (key: BookingUpdateField, value: BookingUpdateValue) => {
    this.props.dispatch(bookingsActions.updateBooking(this.props.booking.urn, key, value));
  }

  handleManualRefund = async (value: BookingUpdateValue) => {
    await manualRefundBooking(this.props.booking.urn);
    this.handleUpdateBooking(BookingUpdateField.CANCELLATION_REASON, value);
  }

  handleAddTreatments = (treatmentUrns: string[]): void => {
    this.props.dispatch(bookingsActions.addNewBookingTreatment(this.props.booking, treatmentUrns, this.props.therapist));
  }

  render() {
    const {booking} = this.props;

    if (!booking || !booking.urn) {
      return (
        <div className="spinner-container">
          <Spinner name="pulse" fadeIn="none" />
        </div>
      );
    }

    return (
      <div className="booking-container">
        <div className="main-section">
          {this.props.isPartner ?
            <BookingPartner
              booking={booking}
              cards={this.props.cards}
              addresses={this.props.addresses}
              onUpdateBooking={this.handleUpdateBooking}
              transactionState={this.props.transactionState}
              promos={this.props.promos}
              isPromoBusy={this.props.isPromoBusy}
              submittingPromos={this.props.submittingPromos}
              deleting={this.props.deleting}
              treatments={this.props.treatments}
              fetchTherapistTreatments={(therapistUrn: string) => this.props.dispatch(initiateViewTherapistTreatments(therapistUrn))}
              addNewBookingTreatment={this.handleAddTreatments}
              deleteBookingTreatment={(urn: string) => this.props.dispatch(bookingsActions.deleteBookingTreatment(booking, urn))}
              updateBookingTreatment={(treatmentUrn: string, index: number, key: string, value: number) => this.props.dispatch(bookingsActions.updateBookingTreatment(booking.urn, treatmentUrn, index, key, value))}
              createTransaction={(type: string, amount: number) => this.props.dispatch(bookingsActions.createTransaction(booking.urn, type, amount))}
              deleteAdjustment={(index: number) => this.props.dispatch(bookingsActions.deleteAdjustment(booking.urn, index))}
              deleteAdjustmentByUrn={(urn: string) => this.props.dispatch(bookingsActions.deleteAdjustmentByUrn(booking.urn, urn))}
              fetchCustomerCards={(urn: string) => this.props.dispatch(customerActions.fetchCustomerCards(urn))}
              debitCard={(amount: number, card: any) => this.props.dispatch(paymentActions.debitCard(booking.customer.urn, amount, card, booking.urn))}
              openTransactionModal={() => this.props.dispatch(paymentActions.actions.openModal())}
              closeTransactionModal={() => this.props.dispatch(paymentActions.actions.closeModal())}
              submitFree={(amount: number) => this.props.dispatch(bookingsActions.submitFree(booking.urn, amount))}
              fetchPromos={(search: string) => this.props.dispatch(promotionActions.fetch(search))}
              submitPromo={(urn: string) => this.props.dispatch(bookingsActions.submitPromo(booking.urn, urn))}
              fetchTransactionMethods={() => this.props.dispatch(bookingsActions.fetchTransactionMethods(booking.urn))}
              removePromo={(urn: string) => this.props.dispatch(bookingsActions.removePromo(booking.urn, urn))}
              removeRefund={(refund: ApiRefund) => this.props.dispatch(bookingsActions.removeRefund(booking.urn, refund))}
              manualRefund={this.handleManualRefund}
              displayNotification={(type: string, headline: string, message: string) => this.props.dispatch(notificationActions.addNotification(type, headline, message))}
              isEditing={this.props.isEditing}
              />
              :
            <Booking
              booking={booking}
              cards={this.props.cards}
              addresses={this.props.addresses}
              onUpdateBooking={this.handleUpdateBooking}
              transactionState={this.props.transactionState}
              promos={this.props.promos}
              isPromoBusy={this.props.isPromoBusy}
              submittingPromos={this.props.submittingPromos}
              deleting={this.props.deleting}
              treatments={this.props.treatments}
              fetchTherapistTreatments={(therapistUrn: string) => this.props.dispatch(initiateViewTherapistTreatments(therapistUrn))}
              addNewBookingTreatment={this.handleAddTreatments}
              deleteBookingTreatment={(urn: string) => this.props.dispatch(bookingsActions.deleteBookingTreatment(booking, urn))}
              updateBookingTreatment={(treatmentUrn: string, index: number, key: string, value: number) => this.props.dispatch(bookingsActions.updateBookingTreatment(booking.urn, treatmentUrn, index, key, value))}
              createTransaction={(type: string, amount: number) => this.props.dispatch(bookingsActions.createTransaction(booking.urn, type, amount))}
              deleteAdjustment={(index: number) => this.props.dispatch(bookingsActions.deleteAdjustment(booking.urn, index))}
              deleteAdjustmentByUrn={(urn: string) => this.props.dispatch(bookingsActions.deleteAdjustmentByUrn(booking.urn, urn))}
              fetchCustomerCards={(urn: string) => this.props.dispatch(customerActions.fetchCustomerCards(urn))}
              debitCard={(amount: number, card: any) => this.props.dispatch(paymentActions.debitCard(booking.customer.urn, amount, card, booking.urn))}
              openTransactionModal={() => this.props.dispatch(paymentActions.actions.openModal())}
              closeTransactionModal={() => this.props.dispatch(paymentActions.actions.closeModal())}
              submitFree={(amount: number) => this.props.dispatch(bookingsActions.submitFree(booking.urn, amount))}
              fetchPromos={(search: string) => this.props.dispatch(promotionActions.fetch(search))}
              submitPromo={(urn: string) => this.props.dispatch(bookingsActions.submitPromo(booking.urn, urn))}
              fetchTransactionMethods={() => this.props.dispatch(bookingsActions.fetchTransactionMethods(booking.urn))}
              removePromo={(urn: string) => this.props.dispatch(bookingsActions.removePromo(booking.urn, urn))}
              removeRefund={(refund: ApiRefund) => this.props.dispatch(bookingsActions.removeRefund(booking.urn, refund))}
              manualRefund={this.handleManualRefund}
              displayNotification={(type: string, headline: string, message: string) => this.props.dispatch(notificationActions.addNotification(type, headline, message))}
              isEditing={this.props.isEditing}
              dispatch={this.props.dispatch}
            />
          }              
        </div>

        <div className="side-nav">
          <BookingSubMenu 
            urn={booking.urn} 
            active="details" 
            notesCount={booking.notesRequiringAction}
            chatUrl={booking.chatUrl}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: Props): ReduxProps => {
  const booking = state.bookings.bookings[ownProps.match.params.urn];
  const customers = state.customersState.customers.asMutable({ deep: true });
  const customer = Object.keys(customers).length && booking ? customers[booking.customer.urn] : null;
  const cards = (booking && customer) ? customer.customer.cards : [];
  const addresses = (booking && customer) ? customer.customer.addresses : [];
  let treatmentUrns = (booking && state.therapistsState.therapists[booking.therapistUrn]) ? state.therapistsState.therapists[booking.therapistUrn].treatmentUrns : [];
  let therapist;

  if (booking && booking.therapistUrn && (treatmentUrns.length > 0)) {
    therapist = state.therapistsState.therapists[booking.therapistUrn];

    if (therapist && therapist.isElite) {
      treatmentUrns = treatmentUrns.concat(
        Object.values(state.treatments.treatments)
          .filter(t => t.isBlackLabel)
          .map(t => t.urn)
      );
    }

    treatmentUrns = treatmentUrns.concat(
      Object.values(state.treatments.treatments)
        .filter(t => t.isCorporate)
        .map(t => t.urn)
    );
  }

  return {
    therapist,
    booking,
    treatments: inflateArrayFromMap(state.treatments.treatments, treatmentUrns, inflateTreatment),
    isEditing: state.editState.get("isEditing"),
    cards,
    addresses,
    transactionState: state.transactionsState.toJS(),
    promos: state.promotionsState.promos,
    isPromoBusy: state.promotionsState.isBusy,
    submittingPromos: state.bookings.submittingPromos,
    deleting: state.bookings.deleting,
    isPartner: state.operator.isPartner,
  };
};

export const BookingContainer = withRouter(connect<ReduxProps, {}, OwnProps>(mapStateToProps)(Component));
