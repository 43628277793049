import * as React from "react";
import { Table } from "react-bootstrap";

import NewTreatmentField from "./new-treatment-field";
import EditField from "../sub/edit-field";
import { ApiBooking } from "../../api/main/booking";
import { TreatmentPresenter } from "../../presenters/treatment";

interface Props {
  booking: ApiBooking;
  treatments: TreatmentPresenter[];
  treatmentTotal: number;
  addNewTreatmentHandler: (treatments: any) => void;
  deleteTreatmentHandler: (id: string) => void;
  savePriceHandler: (treatmentUrn: string, index: number, key: string, value: number) => void;
}

export const BookingTreatments: React.SFC<Props> = ({ booking, treatments, addNewTreatmentHandler, deleteTreatmentHandler, savePriceHandler, treatmentTotal }) => {
  const bookingTreatments = booking.bookingTreatments;

  const bookingsRow = bookingTreatments.map((bookingTreatment, i: number) => {
    const treatment = bookingTreatment.treatment;

    return (
      <tr key={i + treatment.urn}>
        <td>{treatment.name}</td>
        <td></td>
        <td><EditField dbKey="price" value={bookingTreatment.price} type="price" saveHandler={(key: string, value: string) => savePriceHandler(bookingTreatment.urn, i, key, Number.parseFloat(value))} /></td>
        <td>
          <button className="button-delete" type="button" onClick={() => deleteTreatmentHandler(bookingTreatment.urn)}>
            <span className="fa fa-trash" aria-hidden="true" />
          </button>
        </td>
      </tr>
    );
  });

  return (
    <div>
      <div className="treatments-header">
        <h3>Treatments</h3>
        <NewTreatmentField addNewTreatments={addNewTreatmentHandler} treatments={treatments} />
      </div>

      <Table>
        <colgroup>
          <col width="40%" />
          <col width="40%" />
          <col width="15%" />
          <col width="5%" />
        </colgroup>

        <thead>
          <tr>
            <th>Name</th>
            <th></th>
            <th>Price</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {bookingsRow}
          <tr>
            <td></td>
            <td className="total">Total </td>
            <td>{sprintf("£%0.2f", treatmentTotal)}</td>
            <td></td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
