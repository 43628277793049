import { ApiPostcodeDistrict } from "../../api/main/postcodes";
/*
A postcode district is granularity of our therapist availability.

A district is identified by an "outward code".

Most districts code look like W12, however there are some cases
where a previous district has been subdivided, e.g. W1 is no longer
a district, but W1A, W1B etc. are. In this case, we consider those
districts to be in a district group, W1 - but this is not part of
the postcode definition.
*/
export interface PostcodeDistrict {
  urn: string;
  outCode: string;
  administrativeArea: string;
  localAreas: string[];
  flags: string[];
}
/*
"DistrictGroups" aren't a real thing, but are here to support a
convenience feature in this UI.

Most district codes (out codes) look like W3. However there are
some with a letter suffix, e.g. W1A.

We allow districts with a letter suffix to be set or unset
in one click. We call all of these districts under W1 a district
group.

The previous system treated W1 as a district, when it's actually
not a thing (hence I had to make up the name DistrictGroup)
*/
export interface PostcodeDistrictGroup {
  districtGroupCode: string;
  children: PostcodeDistrict[];
}
/*
An area is the broadest part of a postcode. It is the first letter
or two letters of the postcode. The letters identify a town or city (apart
from the london compass point postcodes), though the area normally covers
nearby towns too.

Example: W - West London or TW - Twickenham (a town west of London).
*/
export interface PostcodeArea {
  areaCode: string;
  areaName: string;
  children: PostcodeDistrictGroup[];
}

export class PostcodesPresenter {
  postcodes: PostcodeArea[];

  constructor(availablePostcodes: ApiPostcodeDistrict[]) {
    const decoratedPostcodes = availablePostcodes.map(createDecoratedDistrict);
    decoratedPostcodes.sort(comparison);
    this.postcodes = nestPostcodes(decoratedPostcodes);
  };

  getAreas() {
    return this.postcodes;
  }
}

/**
 * Comparison for decorated postcode districts. The "decoration" contains the outcode broken into three
 * parts: Area, "district-group" and District. (district-group is not really a thing and is often the same
 * as the district). We then sort by those, so that an area, it's district groups and districts are together
 * in order, e.g. E1, E1W, E2, ... E10, E11
 */
const comparison = (d1: DistrictDecoratedForStructuring, d2: DistrictDecoratedForStructuring): number => {
  return (
    ( d1.areaString === d2.areaString )
    ? (
        (d1.districtNumber === d2.districtNumber)
        ? (
            (d1.subDistrictString === d2.subDistrictString)
            ? 0 // they are equal - shouldn't happen...
            : (d1.subDistrictString > d2.subDistrictString ? 1 : -1)
        )
        : (d1.districtNumber > d2.districtNumber ? 1 : -1)
    )
    : (d1.areaString > d2.areaString ? 1 : -1)
  );
};

/**
 * An internal structure used for sorting and creating a nested
 * structure of the postcode districts
 *
 * Exported only for testing
 */
interface DistrictDecoratedForStructuring {
  areaString: string;
  districtNumber: number;
  subDistrictString: string;
  district: ApiPostcodeDistrict;
  districtGroupCode: string;
}
/**
 * Create a decorated district object that we can use for the
 * sorting and nesting operations
 *
 * Exported only for testing
 */
export const createDecoratedDistrict = (district: ApiPostcodeDistrict): DistrictDecoratedForStructuring => {
  // Find the area (the letters at the start).
  const matchArea = district.outCode.match(/^([A-Z]+)(.*)$/);
  if (matchArea.length !== 3) {
    // the match array is expected to have 3 elements if we matched
    throw new Error(`Malformed postcode area "${district.outCode}"`);
  }
  const areaString = matchArea[1];
  const rest = matchArea[2];

  // match the rest of the string (i.e. without the area code) to find a postcode district and district group
  // * most districts are defined by the digits after the area code
  // * however some have digits followed by letters - in this case, we consider the digits to define a district group and the letters districts
  const matchDistrict = rest.match(/^([0-9]+)(.*)$/);
  if (matchDistrict.length !== 3) {
    throw new Error(`Malformed postcode district: ${district.outCode}`);
  }
  const districtNumber = parseInt(matchDistrict[1], 10);
  const subDistrictString = matchDistrict[2];
  return {
    district,
    areaString,
    districtNumber,
    subDistrictString,
    districtGroupCode: `${areaString}${districtNumber}`,
  }
}

//
const mapApiDistrictToPresenter = (district: ApiPostcodeDistrict): PostcodeDistrict => {
  return {
    urn: district.urn,
    outCode: district.outCode,
    administrativeArea: district.administrativeArea,
    localAreas: district.localAreas,
    flags: typeof district.flags === "undefined" ? [] : district.flags,
  };
};

/**
 * Structure postcode information into a nested structure for the UI:
 *
 * Area
 *  DistrictGroup
 *    District
 * ... etc
 *
 * We do this by looking at the codes, which have to be pre-processed
 * into a convenient format (DistrictDecoratedForStructuring) and sorted
 * in advance of calling this.
 *
 * Exported for testing only
 */
export const nestPostcodes = (districts: DistrictDecoratedForStructuring[]): PostcodeArea[] => {
  const result: PostcodeArea[] = [];
  for (const district of districts) {
    const lastArea = result[result.length - 1];
    if ( typeof lastArea !== "undefined" && lastArea.areaCode === district.district.areaCode ) {
      // We are looking at the last area we looked at
      // - add the current district to the end of the area's children
      const lastDistrictGroup = lastArea.children[lastArea.children.length - 1];
      if (
        typeof lastDistrictGroup !== "undefined"
        && lastDistrictGroup.districtGroupCode === district.districtGroupCode
      ) {
        // we are looking at the same district group we just looked at
        // - add the district to its children
        lastDistrictGroup.children.push(
          mapApiDistrictToPresenter(district.district),
        );
      }
      else {
        // new district group in the same area
        // - add the district group with this one as its child
        lastArea.children.push({
          districtGroupCode: district.districtGroupCode,
          children: [
            mapApiDistrictToPresenter(district.district),
          ],
        });
      }
    }
    else {
      // either this is the first district, or a new area we've not seen
      // before; either way add a new area with this as the only district
      result.push({
        areaCode: district.district.areaCode,
        areaName: district.district.areaName,
        children: [
          {
            districtGroupCode: district.districtGroupCode,
            children: [
              mapApiDistrictToPresenter(district.district),
            ],
          }
        ],
      });
    }
  }

  return result;
}

/**
 * Take postcode information from the API and return
 * nested structure for building the UI
 */
export function inflatePostcodes(postcodes: ApiPostcodeDistrict[]) {
  return new PostcodesPresenter(postcodes);
}