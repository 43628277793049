import "es6-shim";
import "whatwg-fetch";
import "./polyfills/object-values";

import { createStore, applyMiddleware } from "redux";
import { render } from "react-dom";
import ReduxThunk from "redux-thunk";
import Favicon from "react-favicon";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

import { App } from "./routes";
import { rootReducer } from "./reducers";
import { apolloClient } from "./utils/apollo-client";

import "react-select/dist/react-select.css";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css";
import "react-datepicker/dist/react-datepicker.css";

import "./styles/main.scss";

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

render(
  <>
    <Favicon url={require("./assets/favicon.png")} />
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  </>,
  document.getElementById("root"),
);
