import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { RootState } from "../../reducers";
import {
  inflateTreatment,
  TreatmentPresenter,
} from "../../presenters/treatment";
import {
  actions,
  initiateViewTreatment,
} from "../../reducers/treatments/actions";
import { Treatment } from "../../components/organisms/treatment/";
import { PartnerTreatment } from "../../components/organisms/treatment/partner-index";
import { CategoryPresenter, inflateCategory } from "../../presenters/category";
import { inflateMapFromMap, inflateArrayFromMap } from "../../presenters/utils";
import { ApiCategory } from "../../api/main/category";
import {
  TreatmentUpdateField,
  TreatmentUpdateValue,
  update,
  fetch,
} from "../../api/main/treatment";
import { fetchAllRegions } from "../../reducers/regions/actions";
import { ApiRegion } from "../../api/main/region";
import { addNotification } from "../../reducers/notifications/actions";
import { NOTIFICATION_TYPES } from "../../reducers/notifications/types";
import { UpdateState } from "../../reducers/treatments/reducer";
import {
  getConfiguration,
  TreatmentFeeConfig,
} from "../../api/main/configuration";

interface State {
  updateState: UpdateState;
  treatmentFeeConfig?: TreatmentFeeConfig;
}

interface ReduxProps {
  treatment: TreatmentPresenter;
  categoriesList: CategoryPresenter[];
  categories: {
    [id: string]: CategoryPresenter;
  };
  isLoading: boolean;
  isPartner: boolean;
  regions: ApiRegion[];
}

type OwnProps = RouteComponentProps<{ urn: string }>;
type Props = ReduxProps & OwnProps & { dispatch: Dispatch<{}> };

class Component extends React.Component<Props, State> {
  state: State = {
    updateState: {},
    treatmentFeeConfig: undefined,
  };

  componentDidMount = async (): Promise<void> => {
    this.props.dispatch(initiateViewTreatment(this.props.match.params.urn));
    this.props.dispatch(fetchAllRegions(false));
    const config = await getConfiguration();

    this.setState({
      treatmentFeeConfig: config.TREATMENT_FEE,
    });
  };

  handleStartEditing = (field: string): void => {
    this.setState({
      updateState: {
        [field]: {
          isEditing: true,
          isUpdating: false,
        },
      },
    });
  };

  handleFinishEditing = (field: string): void => {
    this.setState({
      updateState: {
        [field]: {
          isEditing: false,
          isUpdating: false,
        },
      },
    });
  };

  /**
   * Update a field in the treatment
   * @param urn URN of treatment to update
   * @param field Field to update
   * @param value value to update to
   */
  handleSubmit = async (
    field: TreatmentUpdateField,
    value: TreatmentUpdateValue,
    region?: string,
  ): Promise<void> => {
    try {
      this.setState({
        updateState: {
          [field]: {
            isEditing: false,
            isUpdating: true,
          },
        },
      });
      await update(this.props.treatment.urn, field, value, region);

      // fetch treatment to update in state
      const treatment = await fetch(this.props.treatment.urn);
      this.props.dispatch(actions.requestSuccess({ treatments: [treatment] }));

      this.props.dispatch(
        addNotification(NOTIFICATION_TYPES.success, "Treatment updated!"),
      );
    } catch (err) {
      this.props.dispatch(
        addNotification(
          NOTIFICATION_TYPES.danger,
          "Failed to update treatment. Try reloading the page.",
        ),
      );
    } finally {
      this.setState({
        updateState: {
          [field]: {
            isEditing: false,
            isUpdating: false,
          },
        },
      });
    }
  };

  render() {
    return this.props.isPartner ? (
      <PartnerTreatment
        treatment={this.props.treatment}
        categories={this.props.categories}
        categoriesList={this.props.categoriesList}
        updateState={this.state.updateState}
        isLoading={this.props.isLoading}
        onStartEditing={this.handleStartEditing}
        onFinishEditing={this.handleFinishEditing}
        onSubmit={this.handleSubmit}
      />
    ) : this.state.treatmentFeeConfig ? (
      <Treatment
        treatment={this.props.treatment}
        treatmentFeeConfig={this.state.treatmentFeeConfig}
        categories={this.props.categories}
        categoriesList={this.props.categoriesList}
        updateState={this.state.updateState}
        isLoading={this.props.isLoading}
        onStartEditing={this.handleStartEditing}
        onFinishEditing={this.handleFinishEditing}
        onSubmit={this.handleSubmit}
        regions={this.props.regions}
      />
    ) : null;
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): ReduxProps => {
  return {
    treatment: inflateTreatment(
      state.treatments.treatments[ownProps.match.params.urn],
    ),
    isLoading: state.treatments.isLoading,
    categories: inflateMapFromMap<ApiCategory, CategoryPresenter>(
      state.categories.categories,
      inflateCategory,
    ),
    categoriesList: inflateArrayFromMap<ApiCategory, CategoryPresenter>(
      state.categories.categories,
      state.categories.searchResultUrns,
      inflateCategory,
    ),
    isPartner: state.operator.isPartner,
    regions: state.regions.regions,
  };
};

export const TreatmentContainer = withRouter(
  connect<ReduxProps, {}, OwnProps>(mapStateToProps)(Component),
);
