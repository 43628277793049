import { createStandardAction } from "typesafe-actions";
import { ActionDispatch } from "../../";
import * as jobApi from "../../../api/main/job";

interface RequestSuccessPayload {
  jobs: jobApi.ApiJob[];
}

interface RequestDeleteJob {
  id: string;
}

export const actions = {
  requestGetAllJobsInit: createStandardAction("jobs/REQUEST_GET_ALL_JOBS_INIT")<void>(),
  requestGetAllJobsSuccess: createStandardAction("jobs/REQUEST_GET_ALL_JOBS_SUCCESS")<RequestSuccessPayload>(),
  requestCreateJobInit: createStandardAction("jobs/REQUEST_CREATE_JOB_INT")<void>(),
  requestCreateJobSuccess: createStandardAction("jobs/REQUEST_CREATE_JOB_SUCCESS")<jobApi.ApiJob>(),
  requestEditJobInit: createStandardAction("jobs/REQUEST_EDIT_JOB_INT")<void>(),
  requestEditJobSuccess: createStandardAction("jobs/REQUEST_EDIT_JOB_SUCCESS")<jobApi.ApiJob>(),
  requestDeleteJobInit: createStandardAction("jobs/REQUEST_DELETE_JOB_INT")<void>(),
  requestDeleteJobSuccess: createStandardAction("jobs/REQUEST_DELETE_JOB_SUCCESS")<RequestDeleteJob>(),
};

export function fetchAllJobs(): ActionDispatch {
  return async dispatch => {
    dispatch(actions.requestGetAllJobsInit());
    const jobs = await jobApi.fetchAll();
    dispatch(actions.requestGetAllJobsSuccess({ jobs }));
  };
}

export function createJob(job: jobApi.ApiJob): ActionDispatch {
  return async dispatch => {
    dispatch(actions.requestCreateJobInit());
    dispatch(actions.requestCreateJobSuccess(job));
    await jobApi.createJob(job);
  };
}

export function editJob(job: any): ActionDispatch {
  return async dispatch => {
    dispatch(actions.requestEditJobInit());
    dispatch(actions.requestEditJobSuccess(job));
    await jobApi.editJob(job);
  };
}

export function deleteJob(id: string): ActionDispatch {
  return async dispatch => {
    dispatch(actions.requestDeleteJobInit());
    dispatch(actions.requestDeleteJobSuccess({ id }));
    await jobApi.deleteJob(id);
  };
}