import * as Immutable from "immutable";
import * as types from "./types";
import { Action } from "../login/reducer";

const initialNotificationsState = Immutable.Map({});

const notifications = (state = initialNotificationsState, action: Action) => {
  switch (action.type) {
    case types.ADD_NOTIFICATION:
      return state.set(action.payload.id, action.payload.body);
    case types.REMOVE_NOTIFICATION:
      return state.delete(action.payload);
    default:
      return state;
  }
};

export default notifications;
