import * as Immutable from "immutable";
import * as types from "./types";
import { Action } from "../login/reducer";

const initialSalonsState = Immutable.Map({
  error: null,
  isBusy: false,
  salons: Immutable.List.of()
});

const salonsState = (state = initialSalonsState, action: Action) => {
  switch (action.type) {
    case types.FETCH_SALONS_ATTEMPT:
      return state.merge({ isBusy: true });
    case types.FETCH_SALONS_FAIL:
      return state.merge({
        isBusy: false,
        error: action.payload
      });
    case types.FETCH_SALONS_SUCCESS:
      return state.merge({
        isBusy: false,
        salons: action.payload,
        error: null
      });
    default:
      return state;
  }
};

export default salonsState;
