import * as api from "./request";
import { config } from "../../config";
import { ApiAddress } from "./booking";
import { ApiReferralBooking } from "../../reducers/customers/actions";

export enum CustomerBadge {
  NEW = "NEW",
  HIGH_VALUE = "HIGH_VALUE",
  VIP = "VIP",
  B2B = "B2B",
  BLACK_LABEL = "BLACK_LABEL"
}
export interface ApiCustomer {
  urn: string;
  email: string;
  firstName: string;
  lastName: string;
  city: string;
  phone: string;
  cards?: any[];
  addresses?: ApiAddress[];
  credit?: number;
  referralBookings?: ApiReferralBooking[];
  notesRuuby: string;
  notesTherapist: string;
  isFromPerfect10: boolean;
  isBlackLabel: boolean;
  isB2b: boolean;
  isVip: boolean;
  isBlocked: boolean;
  isTrustedCustomer: boolean;
  referralCode: string;
  badges: CustomerBadge[];
}

export async function updateCustomerAddress(customerUrn: string, address: ApiAddress): Promise<void> {
  const url = `${config.adminPanelApiUrl}/customers/${customerUrn}/addresses/${address.urn}`;

  await api.patch(url, {
    address: {
      address1: address.address1,
      address2: address.address2,
      postcode: address.postcode
    }
  });
}

export async function deletePaymentMethod(urn: string, token: string): Promise<void> {
  const url = `${config.adminPanelApiUrl}/customers/${urn}/card/${token}`;

  await api.deletion(url);
}
