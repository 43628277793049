import { createStandardAction } from "typesafe-actions";
import * as api from "../../api/main/booking-statements";
import { ActionDispatch } from "../../reducers";

export const actions = {
  requestGetAllBookingStatementsInit: createStandardAction("bookingStatements/REQUEST_GET_ALL_BOOKING_STATEMENTS_INIT")<void>(),
  requestGetAllBookingStatementsSuccess: createStandardAction("bookingStatements/REQUEST_GET_ALL_BOOKING_STATEMENTS_SUCCESS")<api.ApiBookingStatement[]>(),
  requestEditBookingStatementInit: createStandardAction("bookingStatements/REQUEST_DELETE_BOOKING_STATEMENT_INIT")<void>(),
  requestEditBookingStatementSuccess: createStandardAction("bookingStatements/REQUEST_EDIT_BOOKING_STATEMENT_SUCCESS")<api.ApiBookingStatement>(),

  requestCreateBookingStatementInit: createStandardAction("bookingStatements/REQUEST_CREATE_BOOKING_STATEMENT_INIT")<void>(),
  requestCreateBookingStatementSuccess: createStandardAction("bookingStatements/REQUEST_CREATE_BOOKING_STATEMENT_SUCCESS")<api.ApiBookingStatement>(),

  requestDeleteBookingStatementInit: createStandardAction("bookingStatements/REQUEST_DELETE_BOOKING_STATEMENT_INIT")<void>(),
  requestDeleteBookingStatementSuccess: createStandardAction("bookingStatements/REQUEST_DELETE_BOOKING_STATEMENT_SUCCESS")<{ urn: string }>(),
};

export function fetchBookingStatements(bookingUrn: string): ActionDispatch {

  return async dispatch => {
    dispatch(actions.requestGetAllBookingStatementsInit());
    const bookingStatements = await api.fetch(bookingUrn);
    dispatch(actions.requestGetAllBookingStatementsSuccess(bookingStatements));
  };
}

export function createBookingStatement(bookingStatement: api.ApiBookingStatement): ActionDispatch {
  return async dispatch => {
    dispatch(actions.requestCreateBookingStatementInit());
    dispatch(actions.requestCreateBookingStatementSuccess(bookingStatement));
    await api.createBookingStatement(bookingStatement);
  };
}

export function editBookingStatement(bookingStatement: api.ApiBookingStatement): ActionDispatch {
  return async dispatch => {
    dispatch(actions.requestEditBookingStatementInit());
    dispatch(actions.requestEditBookingStatementSuccess(bookingStatement));
    await api.editBookingStatement(bookingStatement);
  };
}

export function deleteBookingStatement(urn: string): ActionDispatch {
  return async dispatch => {
    dispatch(actions.requestDeleteBookingStatementInit());
    dispatch(actions.requestDeleteBookingStatementSuccess({ urn }));
    await api.deleteBookingStatement(urn);
  };
}