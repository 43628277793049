import { Fields } from "../../utils/class-types";
import { ApiCategory } from "../../api/main/category";

export class CategoryPresenter {
  urn: string;
  name: string;
  parentUrn?: string;

  constructor(props: Fields<CategoryPresenter>) {
    Object.assign(this, props);
  }

  get isTopLevel(): boolean {
    return typeof this.parentUrn === "undefined";
  }
}

export function inflateCategory(object: ApiCategory): CategoryPresenter {
  if (typeof object === "undefined") {
    return undefined;
  }

  return new CategoryPresenter({
    ...object,
  });
}
