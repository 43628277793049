import * as React from "react";
import styled from "styled-components";

const TextArea = styled.textarea`
  font-family: 'nunito_sansregular';
  color: #333333;
  flex-grow: 1;
  height: auto;
  width: 100%;
  padding: 5px;
  font-size: 15px;
  border: 1px solid #cdcdcd;
`;

interface Props {
  value: string;
  placeholder?: string;
  rows?: number;
  autoFocus?: boolean;
  innerRef?(instance: HTMLAnchorElement): void;
  onChange(value: string): void;
  onKeyDown?(e: React.KeyboardEvent<HTMLTextAreaElement>): void;
}

export const InputTextArea: React.StatelessComponent<Props> = props => (
  <TextArea
    onKeyDown={props.onKeyDown ? (e: any) => props.onKeyDown(e) : undefined}
    onChange={e => props.onChange(e.target.value)}
    value={props.value}
    placeholder={props.placeholder}
    rows={props.rows}
    innerRef={props.innerRef}
    autoFocus={props.autoFocus}
  />
);
