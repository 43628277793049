import * as React from "react";
import styled from "styled-components";

import { editButtonCss, EditableFieldIcon } from "../../atoms/editable-field-icon";
import { FieldLabel } from "../../atoms/field-label";
import { CategoryPresenter } from "../../../presenters/category";
import { EditableFieldButtons } from "../editable-field-buttons";
import { Column, Row, Container } from "../../grid";

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const CategoryList = styled.ul`
  list-style: none;
  flex-grow: 1;
`;

const CategoryOption = styled.li`
  ${editButtonCss}
  ${({indent}: {indent: boolean}) => indent ? `margin-left: 20px;` : undefined}
`;

const Buttons = styled.div`
  align-self: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  display: inline-flex;
`;

interface Props {
  label?: string;
  categories: {
    [id: string]: CategoryPresenter;
  };
  categoriesList: CategoryPresenter[];
  values: string[];
  isEditing: boolean;
  isUpdating: boolean;
  onStartEditing(): void;
  onFinishEditing(): void;
  onSubmit(value: string[]): void;
}

interface State {
  selectedCheckboxes: string[];
}

export class EditableCategoryList extends React.Component<Props, State> {
  state: State = {
    selectedCheckboxes: [],
  };

  handleStartEditing = (): void => {
    this.props.onStartEditing();

    this.setState({
      ...this.state,
      selectedCheckboxes: this.props.values,
    });
  }

  handleFinishEditing = (): void => {
    this.props.onFinishEditing();

    this.setState({
      ...this.state,
      selectedCheckboxes: [],
    });
  }

  handleSelectOption = (urn: string, toAdd: boolean): void => {
    const selectedCheckboxes = [...this.state.selectedCheckboxes];

    // either append the urn or find the urn from the existing state and delete it
    if (toAdd) {
      selectedCheckboxes.push(urn);
    }
    else {
      selectedCheckboxes.splice(selectedCheckboxes.findIndex(u => u === urn), 1);
    }

    this.setState({...this.state, selectedCheckboxes});
  }

  handleSubmit = () => this.props.onSubmit(this.state.selectedCheckboxes);

  renderCheckbox = (category: CategoryPresenter, index: number, indent: boolean = false): JSX.Element => {
    const checked = typeof this.state.selectedCheckboxes.find(v => v === category.urn) !== "undefined";

    return (
      <CategoryOption indent={indent} key={index}>
        <input
          type="checkbox"
          value={category.urn}
          onChange={() => this.handleSelectOption(category.urn, !checked)}
          checked={checked}
        /> {category.name}
      </CategoryOption>
    );
  }

  render(): JSX.Element {
    const fieldLabel = <FieldLabel>{this.props.label}</FieldLabel>;

    if (this.props.isEditing) {
      const categoryLists = this.props.categoriesList
        .filter(c => c.isTopLevel)
        .map((c, i) => {
          const children = this.props.categoriesList
            .filter(child => child.parentUrn === c.urn)
            .map((c, i) => this.renderCheckbox(c, i, true));

          return (
            <Column md="3">
              <CategoryList>
                {this.renderCheckbox(c, i)}
                {children}
              </CategoryList>
            </Column>
          );
        });

      return (
        <>
          {fieldLabel}
          <OuterContainer>
            <Container>
              <Row>
                {categoryLists}
              </Row>
            </Container>

            <Buttons>
              <EditableFieldButtons
                isUpdating={this.props.isUpdating}
                onSubmit={this.handleSubmit}
                onCancel={this.handleFinishEditing}
              />
            </Buttons>
          </OuterContainer>
        </>
      );
    }
    else {
      const editButton = <EditableFieldIcon onClick={this.handleStartEditing} />;

      const categories: JSX.Element[] = this.props.values.map(urn => {
        const name = typeof this.props.categories[urn] !== "undefined"
          ? this.props.categories[urn].name
          : urn;

        return (
          <CategoryOption indent={false}>
            {name} {editButton}
          </CategoryOption>
        );
      });

      if (categories.length === 0) {
        categories.push(
          <CategoryOption indent={false}>
            No categories set {editButton}
          </CategoryOption>
        );
      }

      return (
        <div>
          {fieldLabel}
          <ul>
            {categories}
          </ul>
        </div>
      );
    }
  }
}
