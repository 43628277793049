import * as React from "react";

interface Props {
  onChange(query: string): void;
  query: string;
}

export class SearchInput extends React.Component<Props, {}> {
  input: HTMLInputElement;

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.props.onChange((e.target as any).value);
  }

  focus = () => {
    this.input.focus();
  }

  render(): JSX.Element {
    return (
      <div className="input-group" style={{flexGrow: 1}}>
        <div className="input-group-addon"><i className="fa fa-search"></i></div>
        <input
          ref={ref => this.input = ref}
          type="text"
          placeholder="Search"
          className="form-control"
          onChange={this.onChange}
          value={this.props.query}
        />
      </div>
    );
  }
}
