import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { RootState } from "../../reducers";
import { ApiIgnoredTherapist } from "../../api/main/ignored-therapists";
import {
  fetchIgnoredTherapists,
  addIgnoredTherapist,
  removeIgnoredTherapist,
} from "../../reducers/ignored-therapists/actions";
import IgnoredTherapist from "../../components/ignored-therapists/ignored-therapist";
import Table from "../../components/table";
import { TableModal } from "../../components/sub";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import * as Modal from "react-modal";

const modalStyling: Modal.Styles = {
  content: {
    height: "fit-content",
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",
    border: "0px",
    backgroundColor: "#efefef",
  },
  overlay: {
    zIndex: 3,
  },
};

const ModalContainer = styled.div`
  background-color: #efefef;
`;

const ModalHeading = styled.div`
  background-color: #396174;
  color: #ffffff;
  margin: 0px;
  padding: 10px;
  font-weight: bold;
  text-transform: uppercase;
`;

const ModalBody = styled.div`
  margin: 10px 0 0 0;
  padding: 0 20px 0 20px;
`;

const Cell = styled.div`
  float: left;
`;

interface ReduxProps {
  ignoredTherapists: ApiIgnoredTherapist[];
  isLoading: boolean;
}

interface IState {
  urn: string;
  name: string;
  isCreateModalVisible: boolean;
  isDeleteModalVisible: boolean;
}

type OwnProps = RouteComponentProps<{ urn: string }>;
type Props = ReduxProps & OwnProps & { dispatch: Dispatch<{}> };

interface DataTableDataItem {
  _id: string;
  urn: any;
  name: any;
  value: string;
}

class Component extends React.Component<Props, IState> {
  state: IState;

  constructor(props: Props) {
    super(props);
    this.state = {
      urn: "",
      name: "",
      isCreateModalVisible: false,
      isDeleteModalVisible: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchIgnoredTherapists());
  }

  toggleCreateModal = () => {
    this.setState({
      urn: "",
      name: "",
    });

    this.setState({ isCreateModalVisible: !this.state.isCreateModalVisible });
  };

  toggleDeleteModal = () => {
    this.setState({ isDeleteModalVisible: !this.state.isDeleteModalVisible });
  };

  toggleDeleteModalAndSet = (urn: string) => {
    this.setState({ urn });
    this.toggleDeleteModal();
  };

  onChangeTherapist = (therapist: ApiIgnoredTherapist) => {
    if (therapist) {
      this.setState({
        urn: therapist.urn,
        name: therapist.name,
      });
    }
    else {
      this.setState({
        urn: "",
        name: "",
      });
    }
  }

  submitAddTherapist = () => {
    const therapist: ApiIgnoredTherapist = {
      urn: this.state.urn,
      name: this.state.name,
    };

    this.props.dispatch(
      addIgnoredTherapist(therapist)
    );
    this.toggleCreateModal();
  };

  submitRemoveTherapist = () => {
    this.props.dispatch(
      removeIgnoredTherapist(this.state.urn)
    );

    this.toggleDeleteModal();
  };

  render(): JSX.Element {
    const dataset: DataTableDataItem[] = this.props.ignoredTherapists.map(
      (f, idx) => {
        const item: DataTableDataItem = {
          _id: f.urn,
          urn: (
            <Cell key={`urn-${idx}-${f.urn}`} style={{ width: "20%" }}>
              <a
                href={`/therapists/${f.urn}`}
                style={{
                  textDecoration: "underline",
                  color: "#666666",
                }}
              >
                {f.urn}
              </a>
            </Cell>
          ),
          name: (
            <Cell key={`name-${idx}-${f.name}`} style={{ width: "60%" }}>
              {f.name || "N/A"}
            </Cell>
          ),
          value: "",
        };
        return item;
      },
    );

    const rowActions = [
      {
        name: "Remove",
        onClick: (id: string) => { this.toggleDeleteModalAndSet(id) },
      },
    ];

    return (
      <>
        <div className="booking-chat-container">
          <div className="main-section" style={{ width: "100%" }}>
            <Table
              tableName="Ignored Therapists"
              createEntryAction={this.toggleCreateModal}
              columns={[
                { title: "URN", key: "urn", width: "20%" },
                { title: "Name", key: "name", width: "60%" }
              ]}
              rowActions={rowActions}
              dataset={dataset}
              emptyMessage="No therapists to show"
              isLoading={this.props.isLoading}
            />
          </div>
        </div>

        <Modal
          isOpen={this.state.isCreateModalVisible}
          style={modalStyling}
          ariaHideApp={false}
        >
          <ModalContainer>
            <ModalHeading>Add therapist to the ignored list</ModalHeading>
            <ModalBody>
              <form>

                <div className="main-job-details-row" style={{ height: "300px" }}>
                  <div className="detail-job-value">
                    <IgnoredTherapist
                      onChangeTherapist={this.onChangeTherapist}
                      therapistUrn={this.state.urn}
                      ignoredTherapists={new Set(this.props.ignoredTherapists.map((t) => t.urn))}
                    />
                  </div>
                </div>

                <div className="main-job-details-row" style={{ float: "left" }}>
                  <Button
                    onClick={() => this.submitAddTherapist()}
                    className="btn-create-job"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => this.toggleCreateModal()}
                    className="btn-cancel-job"
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </ModalBody>
          </ModalContainer>
        </Modal>

        <TableModal
          isOpen={this.state.isDeleteModalVisible}
          title="Remove therapist"
          message={"Are you sure you want to remove this therapist from the ignored list?"}
          confirmEvent={{
            event: this.submitRemoveTherapist,
            text: "Yes",
          }}
          cancelEvent={{
            event: this.toggleDeleteModal,
            text: "No",
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): ReduxProps => {
  return {
    ignoredTherapists: state.ignoredTherapists.ignoredTherapists,
    isLoading: state.ignoredTherapists.isLoading
  };
};

export const IgnoredTherapistsContainer = withRouter(
  connect<ReduxProps, {}, OwnProps>(mapStateToProps)(Component),
);
