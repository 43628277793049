/**
 * A simple class that holds the postcodes a therapist covers and allows lookups to see if they are enabled or not
 */
export class TherapistPostcodesPresenter {
  therapistPostcodes: { [urn: string]: boolean } = {};

  constructor(therapistPostcodes: string[]) {
    if (typeof therapistPostcodes !== "undefined") {
      therapistPostcodes.forEach(urn => {
        this.therapistPostcodes[urn] = true;
      });
    }
  };
  /**
   * Is the given postcode enabled for the therapist?
   */
  isPostcodeEnabled = (postcodeUrn: string): boolean => typeof this.therapistPostcodes[postcodeUrn] !== "undefined" && this.therapistPostcodes[postcodeUrn];
}

/**
 * given a list of therapist postcode urns, return a TherapistPostcodePresenter object for them
 */
export const inflateTherapistPostcodes = (therapistPostcodeUrns: string[]): TherapistPostcodesPresenter => {
  return new TherapistPostcodesPresenter(therapistPostcodeUrns);
}
