import * as React from "react";
import { FieldLabel } from "../../atoms/field-label";
import { InputTextArea } from "../../atoms/input-text-area";

interface Props {
  label: string;
  value: string;
  onChange(value: string): void;
}

export const LabelledInputTextArea: React.StatelessComponent<Props> = props => (
  <>
    <FieldLabel>{props.label}</FieldLabel>
    <InputTextArea value={props.value} onChange={props.onChange} />
  </>
);
