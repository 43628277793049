import { AxiosResponse } from "axios";

import * as api from "./request";
import { config } from "../../config";
import { TherapistTier } from "../../presenters/therapist";
import { CustomerBadge } from "./customer";
import { Country } from "../../components/customers/sub/addresses";

export interface ApiBookingTransaction {
  urn: string;
  bookingUrn: string;
  transactionId: string;
  type: string;
  amount: number;
  method?: {
    details?: {
      cardType: string;
      maskedNumber: string;
    },
    type?: string,
    code: string,
  },
  timeCreated?: Date;
}

export interface ApiTreatment {
  urn: string;
  name: string;
  price: number;
  duration: number;
  description: string;
}

export interface ApiAddress {
  urn?: string;
  address1: string;
  address2: string;
  postcode: string;
  isDeleted?: boolean;
  country?: Country;
}

export interface ApiBookingTreatment {
  urn: string;
  treatmentUrn: string;
  therapistUrn: string;
  therapist?: {
    urn: string;
    name: string;
  };
  treatment?: ApiTreatment;
  price?: number;
  timeCreated: string;
  timeUpdated: string;
}

export interface ApiRefund {
  urn: string;
  resourceUrn: string;
  amount: number;
  status: string;
  transactionUrn: string;
  refundTransactionUrn: string;
  timeCreated: string;
  timeUpdated: string;
}

export interface RefundInfo {
  clientAmount: number;
  therapistAmount: number;
  ruubyAmount: number;
}

export interface ApiBooking {
  urn: string;
  timeStarts: string;
  timeEnds: string;
  customer?: {
    urn: string;
    email: string;
    firstName: string;
    lastName: string;
    telephone: string;
    isBlackLabel: boolean;
    isB2b: boolean;
    badges: CustomerBadge[];
  };
  originalSalonUrn: string;
  therapistUrn: string;
  salon?: {
    urn: string;
    name: string;
    description: string;
    isMobile: boolean;
    isRuubyOnDemand: boolean;
  };
  address?: ApiAddress;
  transactions?: ApiBookingTransaction[];
  refunds: ApiRefund[];
  refundInfo: RefundInfo;
  bookingTreatments?: ApiBookingTreatment[];
  totalPrice?: number;
  totalEarnings?: number;
  status: string;
  tier: TherapistTier;
  notes: string;
  isB2b: boolean;
  isProduction: boolean;
  isRuubyOnDemand: boolean;
  net?: number;
  commission: number;
  timeCreated: string;
  timeUpdated: string;
  percentageToTherapist: number;
  cancellationReason: string;
  notesRequiringAction: number;
  customerCancellationReason: string;
  chatUrl?: string;
}

export interface MappedApiBookings {
  [urn: string]: ApiBooking;
}

export enum BookingUpdateField {
  SALON_URN = "SALON_URN",
  CUSTOMER_URN = "CUSTOMER_URN",
  ADDRESS_URN = "ADDRESS_URN",
  NOTES = "NOTES",
  PERCENTAGE_TO_THERAPIST = "PERCENTAGE_TO_THERAPIST",
  COMMISSION = "COMMISSION",
  CANCELLATION_REASON = "CANCELLATION_REASON",
  TIME_STARTS = "TIME_STARTS",
  TIME_ENDS = "TIME_ENDS",
  STATUS = "STATUS",
  IS_B2B = "IS_B2B",
  IS_PRODUCTION = "IS_PRODUCTION",
  TIER = "TIER",
}

export type BookingUpdateValue = string | number | boolean;

export async function fetchBookings(query: string): Promise<ApiBooking[]> {
  const url = `${config.adminPanelApiUrl}/bookings?q=${encodeURIComponent(query)}`;

  const response = await api.get<ApiBooking[]>(url);

  return response.data;
}

export async function fetchBooking(urn: string): Promise<ApiBooking> {
  const url = `${config.adminPanelApiUrl}/bookings/${urn}`;

  const response: AxiosResponse = await api.get(url);

  return response.data;
}

export async function updateBooking(bookingUrn: string, key: BookingUpdateField, value: BookingUpdateValue): Promise<ApiBooking> {
  const url = `${config.adminPanelApiUrl}/bookings/${bookingUrn}`;
  const body = { key, value };

  await api.patch(url, body);
  const newBooking = await fetchBooking(bookingUrn);

  return newBooking;
}

export async function manualRefundBooking(bookingUrn: string): Promise<void> {
  const url = `${config.adminPanelApiUrl}/bookings/${bookingUrn}/refund`;

  await api.post(url, {});
}

export async function updateBookingTreatment(treatmentUrn: string, key: string, value: number): Promise<void> {
  const url = `${config.adminPanelApiUrl}/booking-treatments/${treatmentUrn}`;
  const body = { key, value };

  await api.patch(url, body);
}

export async function fetchTherapistTreatments(therapistUrn: string): Promise<{ treatments: ApiTreatment[], therapistUrn: string }> {
  const url = `${config.adminPanelApiUrl}/therapists/${therapistUrn}/treatments/`;

  const response: AxiosResponse = await api.get(url);
  return response.data;
}

export interface AddBookingTreatment {
  treatmentUrn: string;
  price: number;
}

/**
 * Add booking treatments to booking
 * @param booking Booking
 * @param treatments Treatments to add
 */
export async function addNewBookingTreatment(booking: ApiBooking, treatments: AddBookingTreatment[]): Promise<void> {
  const therapistUrn = booking.therapistUrn;
  const body = { therapistUrn, treatments };

  const url = `${config.adminPanelApiUrl}/bookings/${booking.urn}/treatments/`;

  await api.post(url, body);
}

export async function deleteBookingTreatment(booking: any, bookingTreatmentUrn: string): Promise<void> {
  const url = `${config.adminPanelApiUrl}/bookings/${booking.urn}/treatments/${bookingTreatmentUrn}`;

  await api.deletion(url);
}

export async function fetchBookingCustomerAddresses(customerUrn: string): Promise<ApiAddress[]> {
  const url = `${config.adminPanelApiUrl}/customers/${customerUrn}/addresses`;

  const res: AxiosResponse = await api.get(url);
  return res.data;
}

export async function createTransaction(bookingUrn: string, type: string, amount: number): Promise<string> {
  const url = `${config.adminPanelApiUrl}/bookings/${bookingUrn}/transactions`;
  const body = { type, amount };

  const res: AxiosResponse = await api.post(url, body);
  return res.data.urn;
}

export async function fetchTransactions(bookingUrn: string): Promise<ApiBookingTransaction[]> {
  const url = `${config.adminPanelApiUrl}/bookings/${bookingUrn}/transactions`;

  const res: AxiosResponse = await api.get(url);
  return res.data;
}

export async function deleteAdjustment(bookingTransactionUrn: string): Promise<void> {
  const url = `${config.adminPanelApiUrl}/booking-transactions/${bookingTransactionUrn}`;

  await api.deletion(url);
}

export async function submitFree(bookingUrn: string, amount: number): Promise<ApiBooking> {
  const url = `${config.adminPanelApiUrl}/bookings/${bookingUrn}/promo/free`;
  const body = { bookingUrn, amount };

  await api.post(url, body);
  return await fetchBooking(bookingUrn);
}

export async function submitPromo(bookingUrn: string, promoUrn: string): Promise<ApiBooking> {
  const url = `${config.adminPanelApiUrl}/bookings/${bookingUrn}/promo`;
  const body = { bookingUrn, promoUrn };

  const res: AxiosResponse = await api.post(url, body);
  return res.data;
}

export async function removePromo(promoUrn: string): Promise<void> {
  const url = `${config.adminPanelApiUrl}/booking-transactions/${promoUrn}`;

  await api.deletion(url);
}

export async function fetchTransactionMethods(bookingUrn: string): Promise<ApiBookingTransaction[]> {
  const url = `${config.adminPanelApiUrl}/bookings/${bookingUrn}/transactions/method`;

  const res: AxiosResponse = await api.get(url);
  return res.data;
}

export async function removeRefund(refundUrn: string): Promise<void> {
  const url = `${config.adminPanelApiUrl}/booking-refunds/${refundUrn}`;

  await api.deletion(url);
}

export async function fetchTherapistBookingsForDay(therapistUrn: string, date: string): Promise<ApiBooking[]> {
  const url = `${config.adminPanelApiUrl}/therapists/${therapistUrn}/bookings/day/${date}`;

  const res: AxiosResponse = await api.get(url);

  return res.data;
}
