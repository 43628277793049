import {createSelector, Selector} from "reselect";

import { RootState } from "../index";
import { ApiPostcodeDistrict } from "../../api/main/postcodes";
import { inflatePostcodes } from "../../presenters/postcodes/postcodes-presenter";

/**
 * Get the list of available postcodes. This should change rarely.
 */
const availablePostcodesSelector: Selector<RootState, ApiPostcodeDistrict[]> = (rootState: RootState) => {
  if (typeof rootState.postcodesState === "undefined" || typeof rootState.postcodesState.available === "undefined") {
    return [];
  }
  return rootState.postcodesState.available;
}

/**
 * Return the information about available postcodes
 */
export const getAvailablePostcodes = createSelector(
  [ availablePostcodesSelector ],
  inflatePostcodes,
);
