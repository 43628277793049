import * as React from "react";
import { Table } from "react-bootstrap";
import { sprintf } from "sprintf-js";

interface Props {
  treatmentTotal: number;
  commission: number;
  isB2B: boolean;
}

export const Commission: React.SFC<Props> = (props) => {
  const { treatmentTotal, commission, isB2B } = props;

  return (
    <div className="adjustments">
      <div className="adjustments-header">
        <h3>Ruuby Commission</h3>
      </div>

      <Table>
        <colgroup>
          <col width="20%" />
          <col width="20%" />
          <col width="20%" />
          <col width="40%" />
        </colgroup>

        <tbody>
          <tr>
            <td>Treatment Total</td>
            <td>&nbsp;</td>
            <td>{sprintf("£%0.2f", treatmentTotal)}</td>
            <td></td>
          </tr>
          {!isB2B &&
            <tr>
              <td>Commission</td>
              <td>{`${commission * 100}%`}</td>
              <td></td>
            </tr>
          }
        </tbody>
      </Table>
    </div>
  );
};
