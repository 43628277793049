import { AxiosResponse } from "axios";
import * as moment from "moment";
import { config } from "../../config";
import * as api from "./request";
import { ApiBooking } from "./booking";

export enum ApiCalendarSlotType {
  AVAILABLE = "AVAILABLE",
  UNAVAILABLE = "UNAVAILABLE",
  SCHEDULED = "SCHEDULED",
  BOOKING = "BOOKING",
  HOLIDAY = "HOLIDAY",
  EMPTY = "EMPTY",
  DISABLED = "DISABLED",
}

export interface ApiCalendarSlotInterval {
  start: string,
  end: string,
  type: ApiCalendarSlotType;
}

export interface ApiDayCalendar {
  slots: ApiCalendarSlotInterval[];
  bookings: ApiBooking[];
}

export interface CalendarWeek {
  day: number;
  date: string;
  notInMonth: boolean;
  isDisabled: boolean;
  isAvailable?: boolean;
  hasBookings?: boolean;
  isHoliday?: boolean;
}

export interface TherapistCalendar {
  name: string;
  weeks: CalendarWeek[][];
}

export interface ApiTimeRanges {
  [weekday: string]: Array<ApiTimeRange>;
}

export interface ApiTimeRange {
  fromTime: string;
  toTime: string;
}


export async function fetchTherapistCalendar(
  urn: string,
): Promise<TherapistCalendar[]> {
  const url = `${config.adminPanelApiUrl}/therapists/${urn}/calendar`;

  const response: AxiosResponse = await api.get(url);

  return response.data;
}

export async function fetchTherapistDayAvailability(
  urn: string,
  day: string,
): Promise<ApiDayCalendar> {
  const url = `${config.adminPanelApiUrl}/therapists/${urn}/availability/${day}`;

  const response: AxiosResponse = await api.get(url);

  return response.data;
}

export async function addTherapistAvailability(
  urn: string,
  timeStarts: string,
  type: ApiCalendarSlotType
): Promise<void> {
  const url = `${config.adminPanelApiUrl}/therapists/${urn}/availability/slot/`;
  const time = moment(timeStarts).toISOString();

  await api.post(url, { timeStarts: time, type });

  return;
}

export async function removeTherapistAvailability(
  therapistUrn: string,
  timeStarts: string,
): Promise<void> {
  const time = moment(timeStarts); // XXX timezone!!!!!

  const url = `${config.adminPanelApiUrl
    }/therapists/${therapistUrn}/availability/slot/${time.toISOString()}`;

  await api.deletion(url);

  return;
}

export async function fetchTherapistAvailabilitySlotsForDay(
  therapistUrn: string,
  date: string,
): Promise<ApiDayCalendar> {
  const url = `${config.adminPanelApiUrl}/therapists/${therapistUrn}/availability/day/${date}`;

  const res: AxiosResponse = await api.get(url);

  return res.data;
}

/**
 * Bulk set availability for given therapist
 * @param therapistUrn Therapist to add availability for
 * @param timeRange Array of duration strings of slots to add
 */
export const bulkSetAvailability = async (
  therapistUrn: string,
  timeRange: ApiTimeRanges
): Promise<void> => {
  const url = `${config.adminPanelApiUrl}/therapists/${therapistUrn}/availability/batch-set`;

  await api.post(url, timeRange);
};

export const getTherapistAvailabilitySchedule = async (
  therapistUrn: string,
): Promise<ApiTimeRanges> => {
  const url = `${config.adminPanelApiUrl}/therapists/${therapistUrn}/availability/batch-set`;

  const res: AxiosResponse = await api.get(url);

  return res.data;
};

export const getTherapistsWithNoAutoAvailabilitySet = async (): Promise<string[]> => {
  const url = `${config.adminPanelApiUrl}/script/noavailabilityset`;

  const response = await api.get<{ urns: string[] }>(url);

  return response.data.urns;
};
