import * as React from "react";

interface CommissionTabContentProps {
  inputNumberChange: (key: string, e: any) => void;
  classicCommission: number;
  eliteCommission: number;
  rodSurcharge: number;
}

export default class CommissionTabContent extends React.Component<CommissionTabContentProps, {}> {
  render() {
    return (
      <div className="main-region-details-cell">
        <div className="main-region-details-row">
          <div className="detail-region-heading">
            Classic commission (%)
          </div>
          <div className="detail-region-value">
            <input
              type="number"
              value={this.props.classicCommission}
              onChange={(event) => this.props.inputNumberChange('classicCommission', event)}
            />
          </div>        
        </div>
        <div className="main-region-details-row">
          <div className="detail-region-heading">
            Elite commission (%)
          </div>
          <div className="detail-region-value">
            <input
              type="number"
              value={this.props.eliteCommission}
              onChange={(event) => this.props.inputNumberChange('eliteCommission', event)}
            />
          </div>
        </div>
        <div className="main-region-details-row">
          <div className="detail-region-heading">
            ROD surcharge (£)
          </div>
          <div className="detail-region-value">
            <input
              type="number"
              value={this.props.rodSurcharge}
              onChange={(event) => this.props.inputNumberChange('rodSurcharge', event)}
            />
          </div>
        </div>           
      </div>
    );
  }
}