import * as moment from "moment";

import { TherapistRegion, TherapistTier } from "../therapist";
import { ApiTreatment } from "../../api/main/treatment";
import { Fields } from "../../utils/class-types";

const ROD_SURCHRGE = 0;
const MANCHESTER_SURCHARGE = 7;

export interface TreatmentRegion {
  name: string;
  classic: number;
  elite: number;
  blackLabel: number;
  hospitality: number;
}

interface PriceRegion {
  therapistTier: TherapistTier;
  therapistRegions: TherapistRegion[];
  treatmentsRegions: {
    [key: string]: TreatmentRegion
  };
  surcharge?: number;
}

interface PriceTierRegion {
  therapistTier: TherapistTier;
  therapistRegions: TherapistRegion[];
  treatmentsRegions?: TreatmentRegion[];
  surcharge?: number;
}

export class TreatmentPresenter {
  urn: string;
  name: string;
  description: string;
  duration: number;
  isDeleted: boolean;
  isBlackLabel: boolean;
  isCorporate: boolean;
  isAddon: boolean;
  categoryUrns: string[];
  topLevelCategory: string;
  regions?: TreatmentRegion[];
  timeCreated: moment.Moment;
  timeUpdated: moment.Moment;

  constructor(props: Fields<TreatmentPresenter>) {
    Object.assign(this, props);
  }

  /**
   * Return price for specified therapist tier
   * @param type Therapist tier to get price for
   */
  getPrice(therapistTier: TherapistTier, therapistRegions: TherapistRegion[], surcharge = 0): number {
    const details = {
      therapistTier,
      therapistRegions,
      treatmentsRegions: this.regions,
      surcharge: 0, // set all surcharges to 0 on service side and add it separately from FE logic calculateSurchargeForStartTime()
    };

    let price = getPriceTierRegion(details);
    price += surcharge * price; // add surcharge based on calculateSurchargeForStartTime()

    return price;
  }
}

/**
 * Util function to get treatment price based on the Therapist Region and Tier
 *
 * @export
 * @param {PriceRegion} details
 * @returns {number}
 */
 function getPriceTierRegion(details: PriceTierRegion): number {
  // normalize regions from list to map
  const treatmentsRegions = details.treatmentsRegions
    ? details.treatmentsRegions.reduce<{[key: string]: TreatmentRegion}>((acc, v) => {
        acc[v.name] = {
          name: v.name,
          classic: v.classic,
          blackLabel: v.blackLabel,
          elite: v.elite,
          hospitality: v.hospitality,
        };

        return acc;
      }, {})
    : undefined;

  const data: PriceRegion = {
    ...details,
    treatmentsRegions,
  };

  return getPriceRegion(data);
}

function getPriceRegion(details: PriceRegion): number {
  const therapistRegion = details.therapistRegions[0];
  const region = details.treatmentsRegions[therapistRegion];
  const regionName = therapistRegion;

  if (!therapistRegion || !region || !regionName) return -1;

  if (details.therapistTier === TherapistTier.CLASSIC) {
    if (region && region.classic !== 0) {
      return region.classic;
    }

    return 0;
  }

  if (details.therapistTier === TherapistTier.ELITE) {
    if (region && region.elite !== 0) {
      return region.elite;
    }

    return 0;
  }

  if (details.therapistTier === TherapistTier.HOSPITALITY) {
    if (region && region.hospitality !== 0) {
      return region.hospitality;
    }

    return 0;
  }

  if (details.therapistTier === TherapistTier.ON_DEMAND) {
    return (
      region.classic +
      (regionName === TherapistRegion.MANCHESTER
        ? getSurchargeFor(MANCHESTER_SURCHARGE, details.surcharge)
        : getSurchargeFor(ROD_SURCHRGE, details.surcharge))
    );
  }

  if (details.therapistTier === TherapistTier.BLACK_LABEL) {
    return region.blackLabel;
  }

  throw new Error("Price could not be calculated");
}

function getSurchargeFor(type: number, surcharge: number | undefined): number {
  return typeof surcharge !== "undefined" ? surcharge : type;
}

export function inflateTreatment(object: ApiTreatment): TreatmentPresenter {
  if (typeof object === "undefined") {
    return undefined;
  }

  return new TreatmentPresenter({
    ...object,
    timeCreated: moment(object.timeCreated),
    timeUpdated: moment(object.timeUpdated),
  });
}
