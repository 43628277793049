import { ActionType, getType } from "typesafe-actions";
import { actions } from "../actions";
import { Promotion } from "../../../api/main/promotions";
import * as iassign from "immutable-assign";

type PromotionAction = ActionType<typeof actions>;

export interface PromotionState {
  promotions: Promotion[];
  isBusy: boolean;
}

const INITIAL_STATE: PromotionState = {
  promotions: [],
  isBusy: false
};

export function promotionReducer(
  state: PromotionState = INITIAL_STATE,
  action: PromotionAction,
): PromotionState {
  switch (action.type) {
    case getType(actions.requestGetAllPromotionsInit): {
      return iassign(state, (s) => {
        s.isBusy = true;
        return s;
      });
    }
    case getType(actions.requestGetAllPromotionsSuccess): {
      return iassign(state, (s) => {
        s.promotions = action.payload.promotions;
        s.isBusy = false;
        return s;
      });
    }
    case getType(actions.requestDeletePromotionSuccess): {
      return iassign(state, (s) => {
        s.promotions = state.promotions.filter(
          (x) => x.code != action.payload.code,
        );
        return s;
      });
    }
    case getType(actions.requestCreatePromotionSuccess): {
      return {
        ...state,
        promotions: [...state.promotions, action.payload.promotion],
      };
    }
    case getType(actions.requestEditPromotionSuccess): {
      return {
        ...state,
        promotions: state.promotions.map((promotion) =>
          promotion.code === action.payload.promotion.code
            ? { ...promotion, ...action.payload.promotion }
            : promotion,
        ),
      };
    }
    default:
      return state;
  }
}
