import * as React from "react";
import { EditableTextArea } from "../../components/molecules/editable-text-area";
import { Title } from "../../components/atoms/title";
import Switch from "react-bootstrap-switch";
import { connect } from "react-redux";
import { RootState } from "../../reducers";
import { ApiMarketingMessage } from "../../api/main/notifications";
import { Dispatch } from "redux";
import { fetchMarketingMessage, updateMarketingMessage, actionCreators as marketingMessageActions } from "../../reducers/marketing-message/actions";
import * as Spinner from "react-spinkit";

interface ReduxProps {
  marketingMessage: ApiMarketingMessage;
  loading: boolean;
  error: Error | undefined;
  isEditingMessage: boolean;
}

type Props = ReduxProps & { dispatch: Dispatch<{}> };

interface State {
  marketingMessage: ApiMarketingMessage;
}

class MarketingNotificationsContainerComponent extends React.Component<Props, State> {

  state: State = {
    marketingMessage: {
      enabled: false,
      message: ""
    },
  };

  componentWillReceiveProps(nextProps: Readonly<Props>): void {
    const { marketingMessage } = nextProps;
    if (this.props.marketingMessage !== nextProps.marketingMessage) {
      this.setState({ marketingMessage: { ...marketingMessage } });
    }
  }

  async componentDidMount() {
    await this.props.dispatch(fetchMarketingMessage());
  }

  async onMarketingMessageEnableChange(_component: any, _enabled: boolean) {
    const { marketingMessage } = this.state;
    marketingMessage.enabled = !marketingMessage.enabled;
    await this.props.dispatch(updateMarketingMessage(marketingMessage));
    this.setState({ marketingMessage });
  }

  onStartEditingMessage = async (): Promise<void> => {
    await this.props.dispatch(marketingMessageActions.startEditingMessageField());
  }

  onFinishEditingMessage = async (): Promise<void> => {
    await this.props.dispatch(marketingMessageActions.finishEditingMessageField());
  }

  onSubmitMessage = async (message: string): Promise<void> => {
    const { marketingMessage } = this.state;
    marketingMessage.message = message;
    await this.props.dispatch(updateMarketingMessage(marketingMessage));
    this.setState({ marketingMessage });
  }

  render(): JSX.Element {
    const { marketingMessage } = this.state;
    const { error, loading, isEditingMessage } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-lg-12">
            <Title>Platform message</Title>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <EditableTextArea
              label="Message"
              value={marketingMessage.message}
              isUpdating={loading}
              isEditing={isEditingMessage}
              onStartEditing={this.onStartEditingMessage}
              onFinishEditing={this.onFinishEditingMessage}
              onSubmit={this.onSubmitMessage}
              emptyLabel="No message currently set"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="detail-heading">
              ENABLE
            </div>
            <div className="detail-value">
              <Switch
                value={marketingMessage.enabled}
                onChange={this.onMarketingMessageEnableChange.bind(this)}
                bsSize="small"
                onText="YES"
                offText="NO"
                disabled={marketingMessage == null}
                wrapperClass="onboardedSwitch"
              />
            </div>
          </div>
        </div>

        {!marketingMessage && !error &&
          <div className="spinner-container main-section">
            <Spinner name="pulse" fadeIn="none" />
          </div>
        }

      </div>
    );
  }
}

const mapStateToProps = (state: RootState): ReduxProps => {
  const {
    marketingMessageLoading,
    marketingMessageError,
    marketingMessage,
    updateMarketingMessageLoading,
    updateMarketingMessageError,
    isEditingMessage
  } = state.marketingMessage;
  return {
    marketingMessage,
    loading: marketingMessageLoading || updateMarketingMessageLoading,
    error: updateMarketingMessageError || marketingMessageError,
    isEditingMessage,
  };
};

export const MarketingNotificationsContainer = connect<ReduxProps, {}, {}>(mapStateToProps)(MarketingNotificationsContainerComponent);
