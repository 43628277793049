import * as React from "react";
import styled from "styled-components";

import { IconButton } from "../icon-button";

const Button = styled(IconButton)`
  background-color: #a10404;
`;

interface Props {
  onClick(): void;
}

export const CrossButton: React.StatelessComponent<Props> = props => (
  <Button icon="fa-times" onClick={props.onClick} />
);
