import * as Immutable from "seamless-immutable";
import { actionCreators } from "./actions";
import * as types from "./types";
import { ApiCustomer } from "../../api/main/customer";
import { ApiAddress } from "../../api/main/booking";

type Action = ReturnType<typeof actionCreators[keyof typeof actionCreators]>;

export type MappedCustomers = {
  [urn: string]: {
    customer: ApiCustomer;
    bookingUrns: string[];
  };
};

export interface State {
  customers: MappedCustomers;
  sortedCustomers: string[];
  isBusy: boolean;
  busy: {
    card: boolean;
  };
}

const initialCustomersState = Immutable.from<State>({
  customers: {},
  sortedCustomers: [],
  isBusy: false,
  busy: {
    card: false
  }
});

export const customersState = (state: Immutable.ImmutableObject<State> = initialCustomersState, action: Action): Immutable.ImmutableObject<State> => {
  switch (action.type) {
    case types.FETCH_CUSTOMERS_ATTEMPT:
      return state.set("isBusy", true);

    case types.FETCH_CUSTOMERS_SUCCESS:
      return state.set("customers", Immutable(action.payload.customers))
        .set("sortedCustomers", Immutable.from(action.payload.sortedCustomers))
        .set("isBusy", false);

    case types.UPDATE_CUSTOMER_SUCCESS:
      const customer = (state.customers[action.payload.urn] as any).asMutable();
      customer[action.payload.key] = action.payload.value;
      return state.setIn(
        ["customers", action.payload.urn],
        customer
      );

    case types.FETCH_ADDRESSES_SUCCESS:
      return state.setIn(
        ["customers", action.payload.urn, "customer", "addresses"],
        action.payload.addresses
      );

    case types.DELETE_CUSTOMER_ADDRESS_SUCCESS:
      let addresses: ApiAddress[] = (state.customers[action.payload.customerUrn].customer.addresses as any).asMutable();
      return state.setIn(["customers", action.payload.customerUrn, "customer", "addresses"], addresses.filter(a => a.urn !== action.payload.addressUrn));

    case types.ADD_CUSTOMER_ADDRESS_SUCCESS: {
      const addresses = Immutable.asMutable(
        state.customers[action.payload.customerUrn]?.customer?.addresses || [],
      );

      return state.setIn(["customers", action.payload.customerUrn, "customer", "addresses"], addresses.concat([action.payload.address]));
    }

    case types.UPDATE_CUSTOMER_ADDRESS_SUCCESS:
      addresses = (state.customers[action.payload.customerUrn].customer.addresses as any).asMutable();
      const idx = addresses.findIndex(a => a.urn === action.payload.address.urn);
      addresses.splice(idx, 1, action.payload.address);
      return state.setIn(["customers", action.payload.customerUrn, "customer", "addresses"], addresses);

    case types.DELETE_CUSTOMER_PAYMENT_METHOD_ATTEMPT:
      return state.setIn(["busy", "card"], true);

    case types.DELETE_CUSTOMER_PAYMENT_METHOD_SUCCESS:
      let cards: any[] = (state.customers[action.payload.urn].customer.cards as any).asMutable();
      cards.splice(action.payload.index, 1);
      return state.setIn(["customers", action.payload.urn, "customer", "cards"], cards)
        .setIn(["busy", "card"], false);

    case types.SET_CUSTOMER_BOOKINGS: {
      return state
        .setIn(["customers", action.payload.customerUrn, "bookingUrns"], action.payload.bookingUrns);
    }

    case types.SET_CUSTOMER_CARDS: {
      return state
        .setIn(["customers", action.payload.customerUrn, "cards"], action.payload.cards);
    }

    case types.FETCH_REFERRAL_BOOKINGS_SUCCESS:
      return state.setIn(
        ["customers", action.payload.customerUrn, "customer", "referralBookings"],
        action.payload.bookings
      );

    default:
      return state;
  };
};
