import * as React from "react";
import * as Select from "react-select";
import { config } from "../../../../config";

interface BookingStatusProps {
  isPartner: boolean;
  tier: string;
  setTier(tier: string): void;
}

export default class BookingTier extends React.Component<BookingStatusProps  , {}> {
  handleStatusChange = (option: Select.Option) => {
    this.props.setTier(option.value.toString());
  }

  render() {
    const { tier } = this.props;

    return (
      <div className="booking-time">
        <div className="detail-heading">
          Tier
        </div>
        <div className="detail-value">
          <div className="field-edit">
            <div className="select-box">
              <Select
                disabled={this.props.isPartner}
                name={"tier"}
                value={tier}
                clearable={false}
                options={config.tierOptions}
                onChange={this.handleStatusChange}
                />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
