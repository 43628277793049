import * as React from "react";

import { TreatmentPresenter } from "../../../presenters/treatment";
import { TherapistRegion, TherapistTier } from "../../../presenters/therapist";
import { DeleteButton } from "../../atoms/delete-button";
import { Link } from "react-router-dom";
import { AddonBadge } from "../../atoms/treatment-badge";

interface Props {
  treatment: TreatmentPresenter;
  therapistTier: TherapistTier;
  therapistRegions: TherapistRegion[];
  onDelete(): void;
}

export const TherapistTreatmentsTableRow: React.StatelessComponent<Props> = ({
  treatment,
  onDelete,
  therapistTier,
  therapistRegions,
}) => {
  const price = treatment.getPrice(therapistTier, therapistRegions);

  return (
    <tr
      className="table-row"
      style={{ backgroundColor: price === 0 ? "#ffe6e6" : "#ffffff" }}
    >
      <td>
        {treatment.topLevelCategory}
      </td>
      <td>
        <Link
          to={`/treatments/${treatment.urn}`}
          style={{
            textDecoration: "underline",
            color: "rgb(102, 102, 102)",
            marginRight: "5px",
          }}
        >
          {treatment.name}
        </Link>
        {
          treatment.isAddon 
          ? (
            <AddonBadge small>add-on</AddonBadge>
          ) : null
        }        
      </td>
      <td>{treatment.duration} min</td>
      <td>£{price}</td>
      <td>
        <DeleteButton onClick={onDelete} small />
      </td>
    </tr>
  );
};
