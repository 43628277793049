/**
 * Takes a list of IDs and a map if ID to object, and returns an inflated array of objects
 * @param map The ID to object map
 * @param list The list of IDs
 * @param inflateFunction The function to run to inflate the source objects
 */
export function inflateArrayFromMap<T, V>(map: {[id: string]: T}, list: string[], inflateFunction: (item: T) => V): V[] {
  return list.reduce<V[]>((acc, id) => {
    if (typeof map[id] !== "undefined") {
      acc.push(inflateFunction(map[id]));
    }

    return acc;
  }, []);
}

/**
 * Tahes a map of objects and returns a map of inflated objects
 * @param map The ID to object map
 * @param inflateFunction The function to run to inflate the source objects
 */
export function inflateMapFromMap<T, V>(map: {[id: string]: T}, inflateFunction: (item: T) => V): {[id: string]: V} {
  return Object.keys(map).reduce<{[id: string]: V}>((acc, id) => {
    acc[id] = inflateFunction(map[id]);

    return acc;
  }, {});
}
