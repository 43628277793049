import * as React from "react";
import styled from "styled-components";
import { DataTableHeaderText } from "../../atoms/data-table-data-header-text";

const HeaderContainer1 = styled.div`
    width: 100%; 
    float: left;
    background-color: #396174;
    padding: 10px;
`;

const HeaderText1 = styled.div`
    color: #ffffff;
    float: left;
`;

interface Props {
  caption: string;
  event?: { onClick(): void, text: string };
}

export const DataTableHeading: React.StatelessComponent<Props> = ({
  caption,
  event,
}) => {
  return (
    <HeaderContainer1>
      <DataTableHeaderText text={caption} />

      {event &&
        <HeaderText1 style={{ float: "right" }}>
          <a href="#" style={{
            textDecoration: "underline",
            color: "#ffffff",
            cursor: "pointer"
          }} onClick={() => event.onClick()}>{event.text}</a>
        </HeaderText1>
      }
    </HeaderContainer1>
  )
}