import {AxiosResponse} from "axios";

import {config} from "../../config";
import * as api from "./request";

export interface Note {
  urn: string;
  resourceUrn: string;
  creatorUrn: string;
  comment: string;
  timeCreated: string;
  requiresAction: boolean;
}

function mapRowToNote(note: any): Note {
  return {
    urn: note.urn,
    resourceUrn: note.resourceUrn,
    creatorUrn: note.creatorUrn,
    comment: note.comment,
    timeCreated: note.timeCreated,
    requiresAction: note.requiresAction,
  };
}

export async function fetchNotes(bookingUrn: string): Promise<Note[]> {
  const url = `${config.adminPanelApiUrl}/bookings/${bookingUrn}/notes`;
  const response: AxiosResponse = await api.get(url);

  return response.data.notes.map((note: any) => mapRowToNote(note));
}

/**
 * Creates a new note
 * @param bookingUrn - URN of booking to create note for
 * @param comment - Note content
 */
export async function createNote(bookingUrn: string, comment: string): Promise<Note> {
  const url = `${config.adminPanelApiUrl}/bookings/${bookingUrn}/notes`;
  const res: AxiosResponse = await api.post(url, { comment });

  return mapRowToNote(res.data.note);
}

/**
 * Update a field on the note
 * @param noteUrn - URN of note to update
 * @param key - field to update
 * @param value - value to set field to
 */
export async function updateNote(noteUrn: string, key: string, value: string | boolean): Promise<void> {
  const url = `${config.adminPanelApiUrl}/notes/${noteUrn}`;
  await api.put(url, { key, value });
}
