import * as React from "react";
import { NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

interface Props {
  onLogOut(): void;
  isLoggedIn: boolean;
}

export const SessionLink: React.StatelessComponent<Props> = ({isLoggedIn, onLogOut}) => {
  if (isLoggedIn) {
    return (
      <NavItem eventKey={100} href="#" onClick={onLogOut}>Logout</NavItem>
    );
  }

  return (
    <LinkContainer to="/login">
      <NavItem eventKey={101}>Login</NavItem>
    </LinkContainer>
  );
}
