import * as React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {TherapistSubMenu} from "../../components/sub/sub-menu";
import {RootState} from "../../reducers";
import { ApiTherapist } from "../../api/main/therapist";
import { fetchTherapist } from "../../model/therapist";
import { searchTherapist } from "../../reducers/bookings/actions";
import { Chat } from "../../components/chat/chat";

interface ReduxProps {
  therapist: ApiTherapist;
}

type OwnProps = RouteComponentProps<{urn: string}>;
type Props = ReduxProps & OwnProps & {dispatch: Dispatch<{}>};

class Component extends React.Component<Props> {
  async componentDidMount() {
    if (!this.props.therapist) {
      await this.props.dispatch(fetchTherapist(this.props.match.params.urn));
    }
  }

  handleClickBookings = (): void => {
    this.props.dispatch(searchTherapist(this.props.therapist.urn));
  }

  render() {
    return (
      <div className="therapist-container">
        <div className="main-section">
          <Chat urn={this.props.match.params.urn} />
        </div>

        <div className="side-nav">
          <TherapistSubMenu urn={this.props.match.params.urn} active="chat" onClickBookings={this.handleClickBookings} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const therapist = state.therapistsState.therapists[ownProps.match.params.urn];

  return {
    therapist: (therapist) ? therapist : null,
  };
};

export const TherapistChatContainer = withRouter(connect<ReduxProps, {}, OwnProps>(mapStateToProps)(Component));
