import * as types from "./types";
import * as _ from "lodash";

export function addNotification(type: string, headline: string, message: string = "") {
  return {
    type: types.ADD_NOTIFICATION,
    payload: { id: _.uniqueId("notification_"), body: { type, headline, message } }
  };
}

export function removeNotification(notificationId: string) {
  return {
    type: types.REMOVE_NOTIFICATION,
    payload: notificationId
  };
}
