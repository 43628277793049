/**
 * Get message text from API response
 * @param errorResponse - error response object
 * @param defaultMessage - default string message
 */
export function errorMessageFromApi(
  errorResponse: any,
  defaultMessage: string,
) {
  if (
    errorResponse.hasOwnProperty("data") &&
    errorResponse.data.hasOwnProperty("message")
  ) {
    return errorResponse.data.message.hasOwnProperty("detail")
      ? errorResponse.data.message.detail
      : errorResponse.data.message;
  } else {
    return defaultMessage;
  }
}
