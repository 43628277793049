import * as React from "react";
import styled from "styled-components";

const Cell = styled.div`
    float: left; 
    width: 100%;
`;

const RowNoHover = styled.div`
    padding: 10px; 
    border-bottom: 1px solid #dedede;
    background-color: #ffffff;
    float: left; 
    width: 100%;
`;

interface Props {
  text: string;
  event: { text: string, onClick(): void };
}

export const DataTableNoData: React.StatelessComponent<Props> = ({
  text,
  event,
}) => {
  return (
    <>
      <RowNoHover>
        <Cell>
          {text} - <a href="#" style={{
            textDecoration: "underline",
            color: "#396174",
            cursor: "pointer"
          }}
            onClick={() => event.onClick()}>{event.text}</a>
        </Cell>
      </RowNoHover>
    </>
  )
}
