import * as React from "react";
import { Link, NavLink } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";

import { SessionLink } from "./header/session-link";
import { AdminLogo } from "../components/sub/admin-logo";

interface Props {
  isLoggedIn: boolean;
  onLogOut(): void;
  navigationTabs: {
    label: string;
    url: string;
  }[];
}

export class Header extends React.Component<Props> {
  render(): JSX.Element {
    return (
      <Navbar className="admin-nav">
        <Navbar.Header>
          <Navbar.Brand className="header-brand">
            <Link className="logo-wrapper" to="/home">
              <AdminLogo />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          {this.props.isLoggedIn && (
            <Nav>
              {this.props.navigationTabs.map((m) => (
                <li key={m.label}>
                  <NavLink to={m.url} activeClassName="header-link-active">{m.label}</NavLink>
                </li>
              ))}
            </Nav>
          )}
          <Nav pullRight>
            <SessionLink
              onLogOut={this.props.onLogOut}
              isLoggedIn={this.props.isLoggedIn}
            />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
