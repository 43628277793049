import * as React from "react";
import { EditImageModal } from "./edit-image-modal";
import { Image } from "react-bootstrap";
import { config } from "../../config";
import * as Spinner from "react-spinkit";
import { ApiTherapist } from "../../api/main/therapist";
import { Dispatch } from "redux";

import { deleteImage } from "../../model/therapist";

interface Props {
  isBusy: boolean;
  isPartner: boolean;
  index: string;
  therapist: ApiTherapist;
  dispatch: Dispatch<{}>;
}

interface State {
  modalIsOpen: boolean;
}

export class TherapistImage extends React.Component<Props, State> {
  state: State = {
    modalIsOpen: false,
  };

  handleOpenModal = () => {
    this.setState({ modalIsOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ modalIsOpen: false });
  };

  handleDeleteImage = () => {
    this.props.dispatch(
      deleteImage(
        this.props.therapist.urn,
        this.props.therapist.images[this.props.index].src,
        this.props.index,
      ),
    );
  };

  render() {
    const { index, therapist, isBusy, isPartner } = this.props;

    return (
      <div
        key={this.props.index}
        className={
          this.props.isBusy
            ? "therapist-img attempting_delete"
            : "therapist-img"
        }
      >
        <div className="image_overlay">
          <Spinner name="pulse" fadeIn="none" />
          <div className="overlay_bg"></div>
        </div>
        {!isPartner && (
          <div className="button-wrapper">
            <button
              className={`btn btn-submit edit ${isBusy ? "disabled" : ""}`}
              onClick={this.handleOpenModal.bind(this)}
              type="button"
            >
              <span className="fa fa-pencil-square-o" aria-hidden="true" />
            </button>
            <button
              className={`btn btn-cancel delete ${isBusy ? "disabled" : ""}`}
              onClick={this.handleDeleteImage.bind(this)}
              type="submit"
            >
              <span className="fa fa-times" aria-hidden="true" />
            </button>
          </div>
        )}
        <Image
          src={`${config.s3.bucket}${
            therapist.images[index].src
          }?${Math.random()}`}
          width="100%"
          height="auto"
        />

        <EditImageModal
          index={index}
          isBusy={this.props.isBusy}
          therapist={this.props.therapist}
          modalIsOpen={this.state.modalIsOpen}
          closeModal={this.handleCloseModal}
          dispatch={this.props.dispatch}
        />
      </div>
    );
  }
}
