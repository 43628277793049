import * as React from "react";
import * as Modal from "react-modal";
import { Button } from "../../../components/atoms/button";
import { InputTextArea } from "../../atoms/input-text-area";

interface Props {
  isOpen: boolean;
  message: string;
  therapistsCount: number;
  confirmLoading: boolean;
  handleMessage(message: string): void;
  closeModal(): void;
  handleConfirm(): void;
  therapists: any;
}

const modalStyling: Modal.Styles = {
  content: {
    height: "fit-content",
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",
  },
  overlay: {
    zIndex: 3
  }
};

export const TherapistsBulkChatModal: React.SFC<Props> = props => {
  const mapTherapistsNames = () => {
    const therapistsLine: any[] = [];

    for (let i = 0; i < props.therapists.length; i++ ) {
      if (i < 12) {
        therapistsLine.push(<span key={i} className="badge push-around-2">{props.therapists[i].name}</span>);
      }

      if (i === 12) {
        therapistsLine.push(<span key={i} className="badge push-around-2" title="Maximum 12 names are shown">...</span>);
        break;
      }
    }

    return therapistsLine;
  };

  return (
    <Modal
    isOpen={props.isOpen}
    style={modalStyling}
    onRequestClose={props.closeModal}
    ariaHideApp={false}
    >
    <div className="alert-modal">
      <div className="row">
        <div className="col-md-12">
          <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
        </div>
      </div>
      <div className="row alert-modal-info">
        <div className="col-md-12">
          <h3>Send message to {props.therapistsCount} therapists</h3>
          <h6>
            <div className="ruuby-grey-mid push-around-2">{props.therapistsCount > 12 && "Maximum 12 names are shown"}</div>
            {mapTherapistsNames()}
            <br/><br/>
          </h6>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <InputTextArea
            onChange={props.handleMessage}
            value={props.message}
            placeholder="Message..."
            rows={3}
            autoFocus={true}
            ></InputTextArea>
        </div>
      </div>
      <div className="row btn-row">
        <div className="col-md-6 confirm">
          <Button onClick={props.handleConfirm}
            label={props.confirmLoading ? "Sending Messages..." : "Send Message"}
            className="btn-success" disabled={props.confirmLoading}></Button>
        </div>
        <div className="col-md-6 cancel">
          <Button onClick={props.closeModal} label="Cancel"
            className="btn-danger" disabled={props.confirmLoading}></Button>
        </div>
      </div>
    </div>
  </Modal>
  );
};
