import * as React from "react";
import styled from "styled-components";
import { ApiTherapist } from "../../api/main/therapist";
import { config } from "../../config";

const TilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const A = styled.a`
  cursor: pointer;
`

const Panel = styled.div`
  flex-direction: column;
  width: 200px;
  height: 240px;
  background-color: #ffffff;
  padding: 0;
  margin: 30px 30px 30px 0;
  box-shadow: 1px 2px 4px rgb(0 0 0 / 20%);
  transition: all 300ms;

  a {
    font-family: "nunito_sansbold";
    font-size: 1.1em !important;
    color: #777;
    text-decoration: none;
  }

  :hover {
    box-shadow: none;

    a {
      color: #555;
    }
  }
`;

const Thumbnail = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
`;

const NoThumbnail = styled.div`
  width: 200px;
  height: 200px;
  font-size: 160px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const TherapistName = styled.div`
  text-align: center;
  padding: 10px;
`;

interface Props {
  onSelect: Function;
  therapists: ApiTherapist[];
}

export default class TherapistsGallery extends React.Component<Props> {

  render() {
    const therapistRows = this.props.therapists.map((therapist, i) => {
      const image = therapist.images["index"] || therapist.images["image"];

      return (
        <Panel key={i}>
          <A key={therapist.urn} onClick={(event) => this.props.onSelect(event, therapist)}>
            {image ? (
              <Thumbnail
                src={`${config.s3.bucket}${image.src}`}
              />
            ) : (
              <NoThumbnail
                className="fa fa-user-circle-o"
                aria-hidden="true"
              ></NoThumbnail>
            )}
            <TherapistName>{therapist.name.split(" ")[0]}</TherapistName>
          </A>
        </Panel>
      );
    });

    return <TilesContainer>{therapistRows}</TilesContainer>;
  }
}
