import * as React from "react";
import DatePicker from "react-datepicker";
import * as moment from "moment";

interface Props {
  booking: any;
  updateStartDate: (date: moment.Moment) => void;
}

interface State {
  editing?: boolean;
  time?: moment.Moment;
}

export class DateEditField extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editing: false,
      time: moment(this.props.booking.timeStarts)
    };
  }

  handleChange = (date: moment.Moment): void => {
    this.setState({ time: date });
  }

  clickHandler = (): void => {
    this.setState({ editing: true });
  }

  editCancelHandler = (): void => {
    this.setState({ editing: false });
  }

  editSaveHandler = (): void => {
    this.props.updateStartDate(this.state.time);

    this.editCancelHandler();
  }

  handleTimeChange = (event: any): void => {
    const date = moment(event.target.value);
    const newTime = this.state.time.clone().hours(date.get("hours")).minutes(date.get("minutes"));

    this.setState({time: newTime});
  }

  renderTimes = (): JSX.Element[] => {
    let times = [];
    let base = moment().startOf("day");
    // TODO: factor this out as it is also in src/components/booking/create/components/booking-date.tsx
    const intervals = 15;
    const multiplier = 1440 / intervals;
    for (let i = 0; i < multiplier; i++) {
      times.push(base.clone().add(i * intervals, "minutes"));
    }
    const start = moment(this.props.booking.timeStarts);

    return times.map((time, i) => {
      const defaultVal = start.get("hours") === time.get("hours") && start.get("minutes") === time.get("minutes");

      return <option key={i} value={time.toString()} selected={(defaultVal) ? true : false}>
        {time.format("hh:mm A")}
      </option>;
    });
  }

  render() {
    const timeStarts = moment(this.props.booking.timeStarts);

    if (this.state.editing) {
      return (
        <div className="field-edit">
          <div className="input-box">
            <DatePicker
              selected={this.state.time}
              onChange={this.handleChange} />
            <div className="react-datepicker__time-box">
              <select className="form-control" onChange={this.handleTimeChange}>
                {this.renderTimes()}
              </select>
            </div>
            <button className="btn btn-submit" type="button" onClick={this.editSaveHandler}>
              <span className="fa fa-check text-success" aria-hidden="true" />
            </button>
            <button className="btn btn-cancel" type="submit" onClick={this.editCancelHandler}>
              <span className="fa fa-times" aria-hidden="true" />
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="field-edit">
          {timeStarts.format("DD MMM YYYY")}
          <span onClick={this.clickHandler} className="edit-icon edit-icon-hover fa fa-pencil"></span>
        </div>
      );
    }
  }
}
