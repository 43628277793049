import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {BookingSubMenu} from "../../components/sub/sub-menu";
import { Note } from "../../api/main/notes";
import {RootState} from "../../reducers";
import { BookingNotes } from "../../components/booking-notes";
import {
  fetchBookingNotes,
  createNote,
  updateNote
} from "../../reducers/booking-notes/actions";
import { ApiBooking } from "../../api/main/booking";

interface ReduxProps {
  booking: ApiBooking;
  notes: Note[];
  isLoading: boolean;
}

type OwnProps = RouteComponentProps<{urn: string}>;
type Props = ReduxProps & OwnProps & {dispatch: Dispatch<{}>};

class Component extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.dispatch(fetchBookingNotes(this.props.match.params.urn));
  }

  createNote = (comment: string): void => {
    this.props.dispatch(createNote(this.props.match.params.urn, comment));
  }

  updateNote = (noteUrn: string, field: string, value: string | boolean): void => {
    this.props.dispatch(updateNote(noteUrn, field, value));
  }

  render(): JSX.Element {
    const {notes, isLoading} = this.props;

    return (
      <div className="booking-chat-container">
        <div className="main-section">
          <BookingNotes
            notes={notes}
            isLoading={isLoading}
            onCreate={this.createNote}
            onUpdate={this.updateNote}
          />
        </div>

        <div className="side-nav">
          <BookingSubMenu urn={this.props.match.params.urn} active="notes" notesCount={this.props.booking.notesRequiringAction} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): ReduxProps => ({
  booking: state.bookings.bookings[ownProps.match.params.urn],
  isLoading: state.bookings.isBusy,
  notes: state.notesState.notes,
});

export const BookingNotesContainer = withRouter(connect<ReduxProps, {}, OwnProps>(mapStateToProps)(Component));
