import * as React from "react";

import { DashboardComponent } from "../../components/atoms/dashboard-component";
import { EventLogs, getDashboardDetails, getAutoSetAvailabilityTherapistCount } from "../../api/main/dashboard";
import { DashboardBlock } from "../../components/molecules/dashboard-block";
import { SpinnerLoader } from "../../components/molecules/spinner";
import { config } from "../../config";

interface State {
  data: EventLogs;
  bulkAvailabilityCount: number;
}

const ConfigArray = config.cloudWatchDashboard.logGroupNames.map(name => {
  return {
    filterPattern: config.cloudWatchDashboard.filterPattern,
    logGroupName: name
  }
});

class DashboardContainer extends React.Component<{}, State> {
  state: State = {
    data: undefined,
    bulkAvailabilityCount: 0,
  };

  async componentDidMount() {

    const data = await getDashboardDetails(ConfigArray);
    const bulkAvailabilityCount = await getAutoSetAvailabilityTherapistCount();

    this.setState({ data, bulkAvailabilityCount });
  }

  mapLogs = (groupName: string) => {
    if (!this.state.data[groupName].length) {
      return <div>No logs for last date</div>
    }

    return this.state.data[groupName].map((log) => {
      return (
        <DashboardBlock
          key={log.eventId}
          message={log.message}
          timestamp={log.timestamp}
        />
      );
    });
  };

  render() {
    if (!this.state.data) {
      return <SpinnerLoader />;
    } else {

      const logPanels = ConfigArray.map(c => {
        return <div className="col-md-3">
          <DashboardComponent title={c.logGroupName}>
            {this.mapLogs(c.logGroupName)}
          </DashboardComponent>
        </div>
      });

      const bulkAvailabilityPanel = <div className="col-md-3">
        <DashboardComponent title="Bulk availability">
          {this.state.bulkAvailabilityCount} therapists
        </DashboardComponent>
      </div >;

      return (
        <>
          <div className="row">
            {bulkAvailabilityPanel}
            {logPanels}
          </div>
        </>
      );
    }
  }
}

export { DashboardContainer };
