import styled from "styled-components";

export const FieldLabel = styled.h3`
  margin: 10px 0 2px 0;
  font-family: "nunito_sansregular";
  color: #666666;
  font-size: 12px;
  text-transform: uppercase;
`;

export const NewFieldLabel = styled(FieldLabel)`
  margin: 0;
`;
