import * as api from "../main/request";
import { config } from "../../config";


interface AdminUserCredentials {
  userId: string;
  accessToken: string;
}

/**
 * Retrieves admin user credentials for Sendbird SDK
 */
export const getAdminCredentials = async (): Promise<AdminUserCredentials> => {
  const url = `${config.adminPanelApiUrl}/chat/credentials/`;

  const response = await api.get<AdminUserCredentials>(url);

  return response.data;
};

interface ChatChannel {
  chatUrl: string;
}

/**
 * Creates or retrieves a chat channel using the provided URN.
 */
export const getOrCreateChannel = async (urn: string): Promise<ChatChannel> => {
  const url = `${config.adminPanelApiUrl}/chat/channel/create/`;

  const response = await api.post<ChatChannel>(url, { urn });

  return response.data;
};

/**
 * Sends a message to multiple therapists.
 * @param {string[]} therapistUrns - An array of therapist URNs
 * @param {string} message - The `message` parameter is a string that represents the content of the
 * blast message that you want to send to the therapists.
 */
export const blastMessage = async (
  therapistUrns: string[],
  message: string,
): Promise<void> => {
  const url = `${config.adminPanelApiUrl}/chat/blast-message/`;

  await api.post(url, {
    therapistUrns,
    message,
  });
};