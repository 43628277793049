import axios from "axios";
import * as Cookie from "js-cookie";
import uuid from "node-uuid";

import * as therapistApi from "../../api/main/therapist";
import { config } from "../../config";
import { NOTIFICATION_TYPES } from "../../reducers/notifications/types";
import { addNotification } from "../../reducers/notifications/actions";
import { ActionDispatch } from "../../reducers";
import { fetchTreatmentsForTherapist } from "../../api/main/treatment";
import { actions as treatmentActions } from "../../reducers/treatments/actions";
import { errorMessageFromApi } from "../../utils/error-message";
import { begin, success, failure } from "redux-api-status/actions";
import { therapistActions } from "../../reducers/therapist/actions";
import * as therapistPostcodesApi from "../../api/main/postcodes";
import * as partnerApi from "../../api/main/partners";

// created by moving thunk functions out of therapist actions

let activeSearchRequestId: string;

export function fetchTherapists(query?: therapistApi.ApiSearchQuery): ActionDispatch {
  return async dispatch => {
    const thisRequestId = uuid.v4();

    // set this request id in the 'singleton' so we know what was the last request
    activeSearchRequestId = thisRequestId;

    dispatch(therapistActions.fetchTherapistsAttempt());

    const therapists = await therapistApi.search(query);

    // only set results if this request is the most recent one made
    if (thisRequestId === activeSearchRequestId) {
      dispatch(therapistActions.fetchTherapistsSuccess({ therapists }));
    }
  };
}

export function fetchPartnerTherapists(id: string): ActionDispatch {
  return async dispatch => {
    const thisRequestId = uuid.v4();

    // set this request id in the 'singleton' so we know what was the last request
    activeSearchRequestId = thisRequestId;

    dispatch(therapistActions.fetchTherapistsAttempt());

    const partner = await partnerApi.fetch(id);
    const therapists = await therapistApi.fetchPartnerTherapists(partner.therapists.map(t => t.urn));

    // only set results if this request is the most recent one made
    if (thisRequestId === activeSearchRequestId) {
      dispatch(therapistActions.fetchTherapistsSuccess({ therapists }));
    }
  };
}

/**
 * Create a reference for redux-api-status tracking of a fetch therapist request
 */
export const fetchTherapistRef = (therapistUrn: string) => `THERAPISTS/FETCH/${therapistUrn}`;

/**
 * Fetch a single therapist by urn
 */
export function fetchTherapist(urn: string): ActionDispatch {
  // A mixture of begin/success/failure from redux-api-status
  // and the hand-rolled isBusy tracking!
  return async (dispatch, getState) => {
    const ref = fetchTherapistRef(urn);
    dispatch(therapistActions.fetchTherapistsAttempt());
    dispatch(begin(ref));
    try {
      const therapists = Object.values(getState().therapistsState.therapists).map(t => t);
      const therapist = await therapistApi.fetchTherapist(urn);

      dispatch(therapistActions.fetchTherapistsSuccess({ therapists: [...therapists, therapist] }));
      dispatch(success(ref, therapist));
    }
    catch (error) {
      dispatch(failure(ref, error));
    }
  };
}

/**
 * Update a therapist field
 * @param  urn Therapist urn
 * @param  key Field being edited (name/email/telephone/description/quote/products)
 * @param  value Update value
 */
export function updateTherapistField(urn: string, key: therapistApi.TherapistUpdateField, value: therapistApi.TherapistUpdateValue): ActionDispatch {
  return async dispatch => {
    if (key === therapistApi.TherapistUpdateField.IS_RECOMMENDED) {
      dispatch(therapistActions.updateTherapistFieldBusyToggle({ key: "recommended" }));
    }

    try {
      await therapistApi.updateTherapist(urn, key, value);
      dispatch(addNotification(NOTIFICATION_TYPES.success, "Updated!", `"${key.toLowerCase()}" has been changed successfully`));
    } catch (error) {
      dispatch(addNotification(NOTIFICATION_TYPES.danger, error.message, errorMessageFromApi(error.response, "Error updating therapist.")));
    }

    dispatch(fetchTherapist(urn));
  };
}

/**
 * Process to upload to AWS S3 bucket. Firstly retrieves signed url, uses it to
 * PUT into S3 bucket then updates postgres database and redux state
 * @param  urn:  string        Therapist urn
 * @param  index:  string        index/insight/review/product
 * @param  file: File
 * @return {Function}       redux-thunk
 */
export function getPutImageUrl(urn: string, index: string, file: any): ActionDispatch {
  return (dispatch, getState) => {
    dispatch(therapistActions.addTherapistImageAttempt({ index }));

    const token = Cookie.get("token");
    const name = index;
    const url = `${config.adminPanelApiUrl}/therapists/${urn}/image?file-name=${name}&content-type=${file.type}`;

    axios.get(url, {
      headers: { "authorization": token }
    }).then((response: any) => {
      axios.put(response.data.signedUrl, file, {
        headers: { "Content-Type": file.type }
      }).then(() => {
        dispatch(therapistActions.updateTherapistImageState({ urn, index, image: response.data.image }));
        dispatch(persistImageCrop(getState().therapistsState.therapists[urn]));
      });
    });
  };
}

export function persistImageCrop(therapist: any): ActionDispatch {
  return dispatch => {
    dispatch(therapistActions.persistImageCropAttempt());

    const token = Cookie.get("token");
    const url = `${config.adminPanelApiUrl}/therapists/${therapist.urn}/image`;
    const body = therapist.images;

    axios.post(url, body, {
      headers: { "authorization": token }
    })
      .then(() => {
        dispatch(therapistActions.persistImageCropSuccess());
      })
      .catch((err: any) => {
        dispatch(therapistActions.persistImageCropFail(err));
      });
  };
}

/**
 * Deletes image from AWS S3 bucket
 * TODO: Create browser cache buster; if image is deleted and another is
 * immediately downloaded, browser will still display old image from cache
 * @param  urn:   string        Therapist urn
 * @param  src:   string        "salon_id/index"
 * @param  index: string        index/insight/review/product
 * @return {Function}        redux-thunk
 */
export function deleteImage(urn: string, src: string, index: string): ActionDispatch {
  return dispatch => {
    dispatch(therapistActions.deleteImageAttempt());

    const token = Cookie.get("token");
    const url = `${config.adminPanelApiUrl}/therapists/${urn}/image?src=${src}&index=${index}`;

    axios.delete(url, {
      headers: { "authorization": token }
    }).then(() => {
      dispatch(therapistActions.deleteImageState({ urn, index }));
      dispatch(therapistActions.deleteImageSuccess());
      dispatch(addNotification(NOTIFICATION_TYPES.success, "Success", "Image deleted!"));
    }).catch((err: any) => {
      console.error(err);
      dispatch(addNotification(NOTIFICATION_TYPES.danger, "Error in deleting image", err.message));
    });
  };
}

/**
 * Initiate viewing the therapist treatments screen
 * @param therapistUrn URN of therapist
 */
export function initiateViewTherapistTreatments(therapistUrn: string): ActionDispatch {
  return async (dispatch, getState) => {
    if (typeof getState().therapistsState.therapists[therapistUrn] === "undefined") {
      await dispatch(fetchTherapist(therapistUrn));
    }

    const treatments = await fetchTreatmentsForTherapist(therapistUrn);

    dispatch(treatmentActions.requestSuccess({ treatments }));
  };
}

/**
 * get a reference for tracking setPostcodeForTherapist requests in redux-api-status
 * we don't track the individual requests, hence it's called setTherapistPostcodesRef
 */
export const setTherapistPostcodesRef = (therapistUrn: string) => `THERAPIST/${therapistUrn}/POSTCODES`;
/**
 * set a postcode district as enabled or disabled for a therapist
 * @param therapistUrn the therapist urn
 * @param postcodeUrn the postcode urn
 * @param enabled boolean indicating whether to enable or disable the given postcode for the therapist
 */
export const setPostcodeForTherapist = (therapistUrn: string, postcodeUrn: string, enabled: boolean): ActionDispatch => {
  const ref = setTherapistPostcodesRef(therapistUrn);
  return async dispatch => {
    dispatch(begin(ref));
    try {
      await therapistPostcodesApi.updateTherapistPostcode(therapistUrn, postcodeUrn, enabled);
      dispatch(therapistActions.updateTherapistPostcodes({ therapistUrn, postcodeUrn, enabled }));
      dispatch(success(ref));
    }
    catch (error) {
      dispatch(failure(ref, { error }));
    }
  };
};

export function addReview(therapistUrn: string, review: string, rating: number, customerSelection: string): ActionDispatch {
  return async dispatch => {
    dispatch(therapistActions.addReviewInit());
    try {
      await therapistApi.addReview(therapistUrn, review, rating, customerSelection);

      dispatch(therapistActions.addReviewSuccess());
    } catch (error) {
      dispatch(therapistActions.addReviewFail({ error }));
    }
  };
}

/**
 * Deactivate a therapist
 * @param  urn Therapist urn
 */
export function deactivateTherapist(urn: string): ActionDispatch {
  return async dispatch => {
    try {
      await therapistApi.deactivateTherapist(urn);
      dispatch(addNotification(NOTIFICATION_TYPES.success, "Therapist has been deactivated successfully."));
    } catch (error) {
      dispatch(addNotification(NOTIFICATION_TYPES.danger, "Error deactivating a therapist."));
    }

    dispatch(fetchTherapist(urn));
  };
}

/**
 * Activate a therapist
 * @param  urn Therapist urn
 */
export function activateTherapist(urn: string): ActionDispatch {
  return async dispatch => {
    try {
      await therapistApi.activateTherapist(urn);
      dispatch(addNotification(NOTIFICATION_TYPES.success, "Therapist has been activated successfully."));
    } catch (error) {
      dispatch(addNotification(NOTIFICATION_TYPES.danger, "Error activating a therapist."));
    }

    dispatch(fetchTherapist(urn));
  };
}