import moment from "moment-timezone";

export type TierOptionsLabel = "Classic" | "Elite" | "Celebrity" | "Hospitality";
export type TierOptionsValue = "CLASSIC" | "ELITE" | "BLACK_LABEL" | "HOSPITALITY";

interface Config {
  environment: string;
  adminPanelApiUrl: string;
  deviceId: string;
  version: string;
  s3: {
    bucket: string;
  };
  braintree: {
    merchantId: string;
    environment: string;
    isActive: boolean;
  };
  stripe: {
    dashboard: {
      url: string;
    }
  }
  timezone: string;
  trello: {
    board: string;
    lists: {
      toDo: string;
      inProgress: string;
    }
  };
  surchargeRules: {
    dates: {
      [date: string]: {
        surcharge: number;
      }
    };
    times: {
      start: string;
      end: string;
      surcharge: number;
    }[];
  };
  customers: {
    value: string;
    label: string;
  }[];
  customerWebsiteUrl: string;
  defaultEmailAddress: string;
  cloudWatchDashboard: {
    filterPattern: string;
    logGroupNames: string[];
  };
  tierOptions: {
    label: TierOptionsLabel;
    value: TierOptionsValue;
  }[];
  sendbird: {
    appId: string;
  };
  newAdminPanelUrl: string;
  graphql: {
    url: string;
  };
  featureSwitch: {
    showRequestPaymentButton: boolean;
  };
  cookieDomain: string;
}

declare global {
  interface Window {
    RUUBY_ADMIN_CONFIG: Config;
  }
}

moment.tz.setDefault(window.RUUBY_ADMIN_CONFIG.timezone);

export const config = {
  ...window.RUUBY_ADMIN_CONFIG,
  version: require('../package.json').version,
};
