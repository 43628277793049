import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import uuid from "node-uuid";

import { BookingSubMenu } from "../../components/sub/sub-menu";
import { RootState } from "../../reducers";
import * as bookingStatementActions from "../../reducers/booking-statements/actions";
import { ApiBooking } from "../../api/main/booking";
import { ApiActivity } from "../../reducers/api-activity/reducer";
import { BookingStatements } from "../../components/booking-statements";
import styled from "styled-components";

import * as Modal from "react-modal";
import { Button } from "react-bootstrap";
import { TableModal } from "../../components/sub/";
import { ApiBookingStatement } from "../../api/main/booking-statements";

const modalStyling: Modal.Styles = {
    content: {
        height: "fit-content",
        width: "50%",
        top: "50%",
        left: "50%",
        transform: "translateY(-50%) translateX(-50%)",
        border: "0px",
        backgroundColor: "#efefef"
    },
    overlay: {
        zIndex: 3
    }
};

const ModalContainer = styled.div`
    background-color: #efefef;
`;

const ModalHeading = styled.div`
    background-color: #396174;
    color: #ffffff;
    margin: 0px;
    padding: 10px; 
    font-weight: bold;
    text-transform: uppercase;
`;

const ModalBody = styled.div`
    margin: 10px 0 0 0;
    padding: 0 20px 0 20px;
`;

interface ReduxProps {
    booking: ApiBooking;
    isLoading: boolean;
    bookingStatements: ApiBookingStatement[];
}

interface IState {
    isCreateModalVisible: boolean;
    isDeleteModalVisible: boolean;
    isEditModalVisible: boolean;
    urn: string,
    name: string;
    value: string;
    therapistUrn: string;
    bookingUrn: string;
}

type OwnProps = RouteComponentProps<{ urn: string }>;
type Props = ReduxProps & OwnProps & { dispatch: Dispatch<{}> };

class Component extends React.Component<Props, IState> {

    state: IState;

    constructor(props: Props) {
        super(props);
        this.state = {
            isCreateModalVisible: false,
            isDeleteModalVisible: false,
            isEditModalVisible: false,
            urn: "",
            name: "",
            value: "",
            therapistUrn: "",
            bookingUrn: "",
        }
    };

    componentDidMount() {
        this.props.dispatch(bookingStatementActions.fetchBookingStatements(this.props.match.params.urn));
    }

    toggleCreateModal = () => {
        this.setState({
            urn: "",
            bookingUrn: "",
            therapistUrn: "",
            name: "",
            value: "",
        });

        this.setState({ isCreateModalVisible: !this.state.isCreateModalVisible });
    }

    toggleDeleteModal = () => {
        this.setState({ isDeleteModalVisible: !this.state.isDeleteModalVisible });
    }

    toggleDeleteModalAndSet = (urn: string) => {
        this.setState({ urn });
        this.toggleDeleteModal();
    }

    toggleEditModal = () => {
        this.setState({ isEditModalVisible: !this.state.isEditModalVisible });
    }

    toggleEditModalAndSet = (urn: string) => {

        const item = this.props.bookingStatements.find(b => b.urn == urn);

        this.setState({
            urn: item.urn,
            name: item.name,
            value: item.value,
            therapistUrn: item.therapistUrn,
            bookingUrn: item.bookingUrn,
        });

        this.toggleEditModal();
    }

    handleInputChange(key: string, e: any) {
        let obj = {};
        obj[key] = e.target.value;
        this.setState(obj);
    }

    submitCreateBookingStatement = () => {
        const bookingStatement: ApiBookingStatement = {
            urn: uuid.v4().toString(),
            therapistUrn: this.props.booking.therapistUrn,
            bookingUrn: this.props.match.params.urn,
            name: this.state.name,
            value: (Number(this.state.value)).toFixed(2),
        };

        this.props.dispatch(bookingStatementActions.createBookingStatement(bookingStatement));
        this.toggleCreateModal();
    }

    submitEditBookingStatement = () => {
        const bookingStatement: ApiBookingStatement = {
            urn: this.state.urn,
            therapistUrn: this.props.booking.therapistUrn,
            bookingUrn: this.props.match.params.urn,
            name: this.state.name,
            value: (Number(this.state.value)).toFixed(2),
        };

        this.props.dispatch(bookingStatementActions.editBookingStatement(bookingStatement));
        this.toggleEditModal();
    }

    submitDeleteBookingStatement = () => {
        const statement: ApiBookingStatement = this.props.bookingStatements.find(b => b.urn === this.state.urn);

        this.props.dispatch(bookingStatementActions.deleteBookingStatement(statement.urn));
        this.toggleDeleteModal();
    }

    render(): JSX.Element {
        return (
            <>
                <div className="booking-chat-container">
                    <div className="main-section">
                        <BookingStatements
                            therapist={{ name: this.props.booking.salon.name }}
                            bookingStatements={this.props.bookingStatements}
                            toggleCreateModal={() => this.toggleCreateModal()}
                            toggleDeleteModal={this.toggleDeleteModalAndSet}
                            toggleEditModal={this.toggleEditModalAndSet} />
                    </div>
                    <div className="side-nav">
                        <BookingSubMenu
                            urn={this.props.match.params.urn}
                            active="statements"
                            notesCount={this.props.booking.notesRequiringAction}
                        />
                    </div>
                </div>

                <Modal
                    isOpen={this.state.isCreateModalVisible}
                    style={modalStyling}
                    ariaHideApp={false}>
                    <ModalContainer>
                        <ModalHeading>
                            Create booking statement
                        </ModalHeading>
                        <ModalBody>
                            <form>
                                <div className="main-job-details-row">
                                    <div className="detail-job-heading">
                                        Name
                                    </div>
                                    <div className="detail-job-value">
                                        <input
                                            style={{ textTransform: "capitalize" }}
                                            type="text"
                                            value={this.state.name}
                                            onChange={this.handleInputChange.bind(this, "name")}
                                            autoFocus={true}
                                        />
                                    </div>
                                </div>
                                <div className="main-job-details-row">
                                    <div className="detail-job-heading">
                                        Value
                                    </div>
                                    <div className="detail-job-value">
                                        <input
                                            style={{ textTransform: "capitalize" }}
                                            type="text"
                                            value={this.state.value}
                                            onChange={this.handleInputChange.bind(this, "value")}
                                        />
                                    </div>
                                </div>
                                <div className="main-job-details-row" style={{ float: "left" }}>
                                    <Button onClick={() => this.submitCreateBookingStatement()} className="btn-create-job">Save</Button>
                                    <Button onClick={() => this.toggleCreateModal()} className="btn-cancel-job">Cancel</Button>
                                </div>
                            </form>
                        </ModalBody>
                    </ModalContainer>
                </Modal>

                <Modal
                    isOpen={this.state.isEditModalVisible}
                    style={modalStyling}
                    ariaHideApp={false}>
                    <ModalContainer>
                        <ModalHeading>
                            Edit booking statement
                        </ModalHeading>
                        <ModalBody>
                            <form>
                                <div className="main-job-details-row">
                                    <div className="detail-job-heading">
                                        Name
                                    </div>
                                    <div className="detail-job-value">
                                        <input
                                            style={{ textTransform: "capitalize" }}
                                            type="text"
                                            value={this.state.name}
                                            onChange={this.handleInputChange.bind(this, "name")}
                                            autoFocus={true}
                                        />
                                    </div>
                                </div>
                                <div className="main-job-details-row">
                                    <div className="detail-job-heading">
                                        Value
                                    </div>
                                    <div className="detail-job-value">
                                        <input
                                            style={{ textTransform: "capitalize" }}
                                            type="text"
                                            value={this.state.value}
                                            onChange={this.handleInputChange.bind(this, "value")}
                                        />
                                    </div>
                                </div>
                                <div className="main-job-details-row" style={{ float: "left" }}>
                                    <Button onClick={() => this.submitEditBookingStatement()} className="btn-create-job">Save</Button>
                                    <Button onClick={() => this.toggleEditModal()} className="btn-cancel-job">Cancel</Button>
                                </div>
                            </form>
                        </ModalBody>
                    </ModalContainer>
                </Modal>

                <TableModal
                    isOpen={this.state.isDeleteModalVisible}
                    title="Booking statement deletion"
                    message={"Are you sure you want to delete this booking statement?"}
                    confirmEvent={{
                        event: this.submitDeleteBookingStatement,
                        text: "Yes"
                    }}
                    cancelEvent={{
                        event: this.toggleDeleteModal,
                        text: "No"
                    }}
                />
            </>);
    }
}

const mapStateToProps = (state: RootState, ownProps: Props): ReduxProps => {
    return {
        booking: state.bookings.bookings[ownProps.match.params.urn],
        isLoading: state.bookings.isBusy || state.apiActivity[ApiActivity.CHAT_FETCH].isLoading,
        bookingStatements: state.bookingStatements.bookingStatements,
    };
};

export const BookingStatementContainer = withRouter(
    connect<ReduxProps, {}, OwnProps>(mapStateToProps)(Component),
);
