import { ActionType, getType } from "typesafe-actions";
import { actions } from "./actions";
import * as iassign from "immutable-assign";
import { ApiIgnoredTherapist } from "../../api/main/ignored-therapists";

type IgnoredTherapistAction = ActionType<typeof actions>;

export interface IgnoredTherapistState {
  ignoredTherapists: ApiIgnoredTherapist[],
  isLoading: boolean
}

const INITIAL_STATE: IgnoredTherapistState = {
  ignoredTherapists: [],
  isLoading: false
};

export function ignoredTherapistReducer(state: IgnoredTherapistState = INITIAL_STATE, action: IgnoredTherapistAction): IgnoredTherapistState {
  switch (action.type) {
    case getType(actions.requestGetAllIgnoredTherapistsInit): {
      return iassign(
        state,
        s => {
          s.isLoading = true;
          return s;
        }
      );
    }
    case getType(actions.requestGetAllIgnoredTherapistsSuccess): {
      return iassign(
        state,
        s => {
          s.ignoredTherapists = action.payload;
          s.isLoading = false;
          return s;
        }
      );
    }
    case getType(actions.requestAddIgnoredTherapistInit): {
      return state;
    }
    case getType(actions.requestAddIgnoredTherapistSuccess): {
      return {
        ...state,
        ignoredTherapists: [...state.ignoredTherapists, action.payload]
      };
    }
    case getType(actions.requestRemoveIgnoredTherapistInit): {
      return state;
    }
    case getType(actions.requestRemoveIgnoredTherapistSuccess): {
      return iassign(
        state,
        s => {
          s.ignoredTherapists = state.ignoredTherapists.filter(x => x.urn != action.payload.urn);
          return s;
        }
      );
    }

    default: return state;
  }
}