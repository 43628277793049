import { config } from "../../config";
import * as api from "./request";

export interface TreatmentFeeConfig {
  GBP: {
    from?: number;
    to?: number;
    feeAmount: number;
  }[];
  CHF: {
    from?: number;
    to?: number;
    feeAmount: number;
  }[];
}

interface Configuration {
  TREATMENT_FEE: TreatmentFeeConfig;
}

/**
 * Get all tags or specific tag
 */
export async function getConfiguration(): Promise<Configuration> {
  let url = `${config.adminPanelApiUrl}/configuration`;

  const response = await api.get<Configuration>(url);

  return response.data;
}
