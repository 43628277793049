import * as React from "react";
import {Table} from "react-bootstrap";
import {sprintf} from "sprintf-js";
import {ApiBookingTransaction} from "../../api/main/booking";
import * as Select from "react-select";
import * as constants from "../../constants";
import * as Spinner from "react-spinkit";
import { Promotion } from "../../api/main/promotions";
import * as _ from "lodash";
import { NOTIFICATION_TYPES } from "../../reducers/notifications/types";
import moment from "moment";

export interface SelectOption {
  value: string;
  label: string;
}

interface Props {
  promotions: ApiBookingTransaction[];
  treatments: any[];
  treatmentTotal: number;
  discountTotal: number;
  clientToPay: number;
  promos: Promotion[];
  isBusy: boolean;
  transactionState: any;
  submittingPromos: boolean;
  deleting: {
    [name: string]: boolean;
  };
  isCreateMode?: boolean;

  fetchPromos: (query: string) => void;
  submitPromo: (bookingUrn: string) => void;
  submitFree: (amount: number) => void;
  removePromo: (promoUrn: string) => void;
  displayNotification: (type: string, headline: string, message: string) => any;
}
interface State {
  free: number;
  promo: {
    value: string;
    label: string;
  };
  isPromoOpen: boolean;
  isFreeOpen: boolean;
}

export class Promotions extends React.Component<Props, State> {
  state: State = {
    free: 0,
    promo: null,
    isPromoOpen: false,
    isFreeOpen: false,
  };

  openPromo = () => {
    this.setState({
      isPromoOpen: true,
      isFreeOpen: false,
    });
  }

  openFree = () => {
    this.setState({
      isPromoOpen: false,
      isFreeOpen: true,
    });
  }

  close = () => {
    this.setState({
      isPromoOpen: false,
      isFreeOpen: false,
    });
  }

  getDescription(promo: any): string {
    if (!promo)
      return "";

    if (promo.deductionType === "percentage")
      return `${promo.deduction}% OFF`;
    else
      return `£${promo.deduction} OFF`;
  }

  renderRows = (): JSX.Element[] => {
    return this.props.promotions.map((promo, index) => (
      <tr key={index} className="adjustments-row">
        <td>
          {promo.method ? promo.method.code : promo.type.replace("_", " ")}
        </td>
        <td>
          {this.getDescription(promo.method)}
        </td>
        <td>{sprintf("-£%0.2f", promo.amount)}</td>
        <td>
          <button className="button-delete" disabled={this.props.deleting.promo} type="button" onClick={() => this.props.removePromo(promo.urn)}>
            <span className="fa fa-trash" aria-hidden="true" />
          </button>
        </td>
      </tr>
    ));
  }

  renderTotalDiscount = (): JSX.Element => {
    return (
      <tr>
        <td></td>
        <td className="total">Total Discount </td>
        <td>{sprintf("-£%0.2f", this.props.discountTotal)}</td>
        <td></td>
      </tr>
    );
  }

  renderClientToPay = (): JSX.Element => {
    return (
      <tr>
        <td></td>
        <td className="total">Client to pay </td>
        <td>{sprintf("£%0.2f", this.props.clientToPay)}</td>
        <td></td>
      </tr>
    );
  }

  submitFree = (): void => {
    if (this.state.free <= this.props.treatmentTotal) {
      this.props.submitFree(this.state.free);
      this.setState({...this.state, free: 0, isFreeOpen: false});
    }
  }

  handleFreeChange = (event: any) => {
    const amount = Number((event.target as HTMLInputElement).value);

    if (amount > this.props.treatmentTotal) {
      this.props.displayNotification(NOTIFICATION_TYPES.danger, "Error creating complimentary", "You cannot add a complimentary higher than the treatment total");
    } else {
      this.setState({...this.state, free: amount});
    }
  }

  renderAddingFreeRow = (): JSX.Element => {
    return (
      <tr className="adjustments-row">
        <td>
          <div className="form-group">
            <p>RUUBY COMPLIMENTARY</p>
          </div>
        </td>
        <td></td>
        <td className="amount">
          <div className="input-group">
            <span className="input-group-addon">£</span>
            <input type="number" className="adjustment-amount form-control" value={this.state.free} min="0" max={this.props.treatmentTotal} onChange={event => this.handleFreeChange(event) } />
          </div>
        </td>
        <td className="controls promo-controls">
          <button className="button-submit" disabled={this.props.submittingPromos} type="button" onClick={this.submitFree}>
            <span className="fa fa-check" aria-hidden="true" />
          </button>
          <button className="button-cancel" disabled={this.props.submittingPromos} type="button" onClick={this.close}>
            <span className="fa fa-times" aria-hidden="true" />
          </button>
        </td>
      </tr>
    );
  }

  mapPromotionsToOptions = (): SelectOption[] => {
    const {promos} = this.props;

    if (!promos) {
      return [];
    }

    return promos.map(promo => {
      const validUntil = moment(promo.validUntil);
      const expired = moment().isAfter(validUntil);

      return {
        label: expired ? `${promo.code} - EXPIRED ${validUntil.format("YYYY-MM-DD")}` : promo.code,
        value: promo.urn,
      };
    });
  }

  submitPromo = (): void => {
    this.props.submitPromo(this.state.promo.value);
    this.setState({...this.state, promo: null, isPromoOpen: false});
  }

  renderAddingPromoRow = (): JSX.Element => {
    const {promotions} = this.props;
    const codes = promotions
      .filter(p => typeof p.method !== "undefined")
      .map(p => p.method.code);

    return (
      <tr className="adjustments-row">
        <td>
          <div className="form-group">
            <Select
              name="promoSelect"
              options={this.mapPromotionsToOptions()}
              placeholder="Select promo code"
              value={this.state.promo}
              onChange={(val: any) => this.setState({...this.state, promo: {
                value: val.value,
                label: val.label
              }})}
              onInputChange={query => query.length !== 0 ? this.props.fetchPromos(query) : null}
              filterOption={(option, _filter) => _.includes(codes, option.label) ? null : option}
              isLoading={this.props.isBusy}
              clearable={false} />
          </div>
        </td>
        <td></td>
        <td className="amount"></td>
        <td className="controls promo-controls">
          <button className="button-submit" disabled={this.props.submittingPromos} type="button" onClick={this.submitPromo}>
            <span className="fa fa-check" aria-hidden="true" />
          </button>
          <button className="button-cancel" disabled={this.props.submittingPromos} type="button" onClick={this.close}>
            <span className="fa fa-times" aria-hidden="true" />
          </button>
        </td>
      </tr>
    );
  }

  render() {
    const noPromos = (
      <div className="no-adjustments">
        No promotions for this booking
      </div>
    );

    const isPromoAdded = this.props.promotions.some(
      transaction => transaction.type === constants.TRANSACTION_PROMO
      );
    const isComplimentaryAdded = this.props.promotions.some(
      transaction => transaction.type === constants.TRANSACTION_COMPLIMENTARY
      );

    return (
      <div className="adjustments">
        <div className="adjustments-header">
          <h3>Promo Code</h3>

          <button className="new-adjustment btn btn-primary"
            onClick={this.openPromo} disabled={isPromoAdded || this.props.submittingPromos}>
              <i className="fa fa-plus"></i> Add Promo</button>
          {!this.props.isCreateMode && <button className="new-adjustment btn btn-primary" 
            onClick={this.openFree} disabled={isComplimentaryAdded || this.props.submittingPromos}>
              <i className="fa fa-plus"></i> Add Free</button>
          }
        </div>

        <Table>
          <colgroup>
            <col width="40%" />
            <col width="40%" />
            <col width="15%" />
            <col width="5%" />
          </colgroup>

          <thead>
            <tr>
              <th>Code</th>
              <th>Description</th>
              <th>Amount</th>
              <th></th>
            </tr>
          </thead>

            
              <tbody>
                {this.state.isPromoOpen && this.renderAddingPromoRow()}
                {this.state.isFreeOpen && this.renderAddingFreeRow()}
                {this.props.promotions.length > 0 &&
                  this.renderRows()
                }
                {!this.props.isCreateMode && this.renderTotalDiscount()}
                {!this.props.isCreateMode && this.renderClientToPay()}
              </tbody>
            {this.props.isBusy || this.props.submittingPromos && 
              <tbody>
                <tr>
                  <td></td>
                  <td><Spinner name="pulse" fadeIn="none" /></td>
                  <td></td>
                </tr>
              </tbody>
            }
        </Table>

        {this.props.promotions.length === 0 && noPromos}
      </div>
    );
  }
}
