import * as React from "react";
import Salon from "./salon";
import { Label } from "react-bootstrap";
import * as moment from "moment";
import * as _ from "lodash";
import "moment-timezone";
import { Dispatch } from "react-redux";
import Switch from "react-bootstrap-switch";

import { DateEditField } from "./date-edit-field";
import { AddressEditField } from "./address-edit-field";
import StatusEditField from "./status-edit-field";
import NotesEditField from "./notes-edit-field";
import { BookingTreatments as Treatments } from "./treatments";
import { Adjustments } from "./adjustments";
import { Transactions } from "./transactions-modal";
import { Refunds } from "./refunds";
import { Promotions } from "./promotions";
import { Commission } from "./commission";
import { CustomerTip } from "./customer-tip";
import { Totals } from "./totals";
import { CustomerPanel } from "../customer-panel";
import * as constants from "../../constants";
import * as utils from "../../utils";
import { ApiBooking, ApiRefund, BookingUpdateField, BookingUpdateValue, ApiAddress } from "../../api/main/booking";
import { Promotion } from "../../api/main/promotions";
import EditField from "../sub/edit-field";
import { TreatmentPresenter } from "../../presenters/treatment";
import { ExternalCommissions } from "./external-commissions";
import { getB2BTotals } from "../../utils/index";
import { config, TierOptionsLabel, TierOptionsValue } from '../../config';

interface Props {
  onUpdateBooking: (key: BookingUpdateField, value: BookingUpdateValue) => void;
  fetchTherapistTreatments: (therapistUrn: string) => void;
  addNewBookingTreatment: (treatmentUrns: any[]) => void;
  deleteBookingTreatment: (bookingTreatmentUrn: string) => void;
  updateBookingTreatment: (treatmentUrn: string, index: number, key: string, value: number) => void;
  createTransaction: (type: string, amount: number) => void;
  deleteAdjustment: (transactionIndex: number) => void;
  fetchCustomerCards: (urn: string) => void;
  debitCard: (amount: number, card: any) => void;
  closeTransactionModal: () => void;
  openTransactionModal: () => void;
  treatments: TreatmentPresenter[];
  addresses: ApiAddress[];
  transactionState: any;
  booking: ApiBooking;
  cards: any[];
  submitFree: (amount: number) => void;
  fetchPromos: (search: string) => void;
  promos: Promotion[];
  isPromoBusy: boolean;
  submitPromo: (urn: string) => void;
  fetchTransactionMethods: () => void;
  submittingPromos: boolean;
  removePromo: (promoUrn: string) => void;
  removeRefund: (refund: ApiRefund) => void;
  displayNotification: (type: string, headline: string, message: string) => void;
  deleting: {
    [name: string]: boolean;
  };
  deleteAdjustmentByUrn: (adjustmentUrn: string) => void;
  manualRefund: (value: BookingUpdateValue) => void;
  isEditing: boolean;
  dispatch: Dispatch<{}>;
}

export const BOOKING_CANCELLATION_REASONS = [
  { label: "Ruuby canceled", value: "RUUBY" },
  { label: "Therapist canceled", value: "THERAPIST" },
  { label: "Customer canceled", value: "CUSTOMER" },
  { label: "Therapist was late", value: "LATE" },
  { label: "Poor quality ", value: "QUALITY" },
];

export class Booking extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.fetchTherapistTreatments(this.props.booking.therapistUrn);

    if (!utils.hasMethods(this.props.booking.transactions))
      this.props.fetchTransactionMethods();
  }

  updateSalonHandler = (salonUrn: string): void => {
    this.props.onUpdateBooking(BookingUpdateField.SALON_URN, salonUrn);
  }

  updateStatusHandler = (status: string): void => {
    this.props.onUpdateBooking(BookingUpdateField.STATUS, status);
  }

  updateNotesHandler = (notes: string): void => {
    this.props.onUpdateBooking(BookingUpdateField.NOTES, notes);
  }

  updateBookingStartDateHandler = (date: moment.Moment): void => {
    const duration = this.props.booking.bookingTreatments.reduce((total: number, t: any) => total + t.treatment.duration, 0);

    const timeStarts = date.toISOString();
    const timeEnds = date.add(duration, "minutes").toISOString();
    this.props.onUpdateBooking(BookingUpdateField.TIME_STARTS, timeStarts);
    this.props.onUpdateBooking(BookingUpdateField.TIME_ENDS, timeEnds);
  }

  updateCustomerAddressHandler = (addressUrn: string): void => {
    this.props.onUpdateBooking(BookingUpdateField.ADDRESS_URN, addressUrn);
  }

  updateB2b = (isB2b: boolean): void => {
    if (!isB2b) {
      this.props.onUpdateBooking(BookingUpdateField.IS_PRODUCTION, false);
    }
    this.props.onUpdateBooking(BookingUpdateField.IS_B2B, isB2b);
  }

  getOptionByValue = (value: TierOptionsValue): { label: TierOptionsLabel; value: TierOptionsValue; } => (
    config.tierOptions.find(option => option.value === value)
  )

  renderTags = () => {
    const tags: JSX.Element[] = [];

    if (this.props.booking.isRuubyOnDemand) {
      tags.push(<Label key="ruuby-on-demand" className="tag-ruuby-on-demand"><i className="fa fa-magic" /> Ruuby on Demand</Label>);
    }

    if (tags) return <div className="tags">{tags}</div>;

    return null;
  }

  renderCommissionPercentages = () => {
    const booking = this.props.booking;

    if (!booking.isB2b) {
      return (
        <div className="main-details-row">
          <div className="main-details-section">
            <div className="detail-heading">
              Commission
            </div>
            <div className="detail-value">
              <EditField
                dbKey="commission"
                type="percentage"
                value={Math.round(booking.commission * 100)}
                maxValue={100}
                saveHandler={(_key, value) => this.props.onUpdateBooking(BookingUpdateField.COMMISSION, (value / 100).toFixed(2))}
              />
            </div>
          </div>

          <div className="main-details-section">
            <div className="detail-heading">
              Percentage of fee for therapist
            </div>
            <div className="detail-value">
              <EditField
                dbKey="percentageToTherapist"
                type="select"
                options={[
                  { label: "100%", value: 1 },
                  { label: "50%", value: 0.5 },
                  { label: "0%", value: 0 },
                ]}
                value={booking.percentageToTherapist}
                saveHandler={(_key, value) => this.props.onUpdateBooking(BookingUpdateField.PERCENTAGE_TO_THERAPIST, value)}
              />
            </div>
          </div>
        </div>
      );
    }
  }

  render() {

    const { booking } = this.props;
    const timeStarts = moment(booking.timeStarts).tz(moment.tz.guess());
    const timeEnds = moment(booking.timeEnds).tz(moment.tz.guess());

    const adminFee = booking.transactions.find(t => t.type === constants.TRANSACTION_ADMIN_FEE);
    const adminFeeAmount = typeof adminFee !== "undefined" ? adminFee.amount : 0;
    const filteredTransactions = booking.transactions.filter(
      t => t.type === constants.TRANSACTION_BRAINTREE ||
        t.type === constants.TRANSACTION_STRIPE ||
        t.type === constants.TRANSACTION_CUSTOMER_CREDIT ||
        t.type === constants.TRANSACTION_INVOICE
    );

    const treatmentTotal = booking.bookingTreatments.reduce<number>((acc, t) => acc + t.price, 0);
    const discountTotal = booking.transactions.filter(
      t => t.type === constants.TRANSACTION_PROMO ||
        t.type === constants.TRANSACTION_COMPLIMENTARY
    ).reduce((prev: number, promo) => prev + promo.amount, 0);
    const externalCommissionsTotal = booking.transactions.filter(
      t => t.type === constants.TRANSACTION_EXTERNAL_COMMISSION
    ).reduce((amount: number, t) => amount + t.amount, 0);

    const clientToPayIncludingCredits = booking.refundInfo ? booking.refundInfo.clientAmount - discountTotal : 0;
    const clientToPay = treatmentTotal + adminFeeAmount - discountTotal + externalCommissionsTotal;
    const outstanding = clientToPay - filteredTransactions.reduce((prev: number, promo) => {
      if (promo.type !== constants.TRANSACTION_ADMIN_FEE) {
        prev += promo.amount
      }

      return prev;
    }, 0);

    const bookingTotals = getB2BTotals(booking);

    // Check if the booking includes a customer tip
    const bookingTip = booking.transactions.find(t => t.type === constants.TRANSACTION_BOOKING_TIP);
    const bookingTipAmount = typeof bookingTip !== "undefined" ? bookingTip.amount : 0;

    return (
      <div>
        <h1>
          Booking 
          { booking.isProduction && (
            <Label className="badge badge-production">
              Production
            </Label>)
          }
          <StatusEditField booking={booking} onUpdateStatus={this.updateStatusHandler} />
        </h1>

        {this.renderTags()}

        <div className="app-details-row booking-details-row">
          <div className="main-details">
            <div className="main-details-row">
              <div className="booking-date">
                <div className="detail-heading">
                  Date
                </div>
                <div className="detail-value">
                  <DateEditField
                    booking={booking}
                    updateStartDate={this.updateBookingStartDateHandler} />
                </div>
              </div>
              <div className="booking-time">
                <div className="detail-heading">
                  Time
                </div>
                <div className="detail-value">
                  {timeStarts.format("HH:mm")} - {timeEnds.format("HH:mm")}
                </div>
              </div>
            </div>

            <div className="main-details-row">
              <div className="booking-salon">
                <div className="detail-heading">
                  {booking.salon.isMobile ? "Therapist" : "Salon"}
                </div>
                <div className="detail-value">
                  <Salon
                    salon={booking.salon}
                    onUpdateSalon={this.updateSalonHandler}
                    therapistUrn={booking.therapistUrn}
                  />
                </div>
              </div>
            </div>

            {booking.salon.isMobile && <AddressEditField
              booking={booking}
              onUpdateAddress={this.updateCustomerAddressHandler}
              addresses={this.props.addresses}
            />}

            <div className="main-details-row">
              <div className="booking-notes">
                <div className="detail-heading">
                  Notes for Therapist and Client <small>(be careful what you write here!)</small>
                </div>
                <div className="detail-value">
                  <NotesEditField resource={booking} onUpdateNotes={this.updateNotesHandler} />
                </div>
              </div>
            </div>
              
            {this.renderCommissionPercentages()}
            
            <div className="main-details-row">
              <div className="main-details-section">
                <div className="detail-heading">
                  B2B
                </div>
                <div className="detail-value">
                  <Switch value={booking.isB2b}
                    onChange={() => this.updateB2b(!booking.isB2b)}
                    onText="Yes"
                    offText="No"
                    bsSize="small" />
                </div>
              </div>

              <div className="main-details-section">
                <div className="detail-heading">
                  Tier
                </div>
                <div className="detail-value">
                  <EditField
                    dbKey="tier"
                    type="select"
                    options={[
                      this.getOptionByValue("CLASSIC"),
                      this.getOptionByValue("ELITE"),
                      this.getOptionByValue("BLACK_LABEL"),
                    ]}
                    value={booking.tier}
                    saveHandler={(_key, value) => this.props.onUpdateBooking(BookingUpdateField.TIER, value)}
                  />
                </div>
              </div>
            </div>
            {
              booking.isB2b && (
                <div className="main-details-row">
                <div className="main-details-section">
                  <div className="detail-heading">
                    Production
                  </div>
                  <div className="detail-value">
                    <Switch value={booking.isProduction}
                      onChange={() => this.props.onUpdateBooking(BookingUpdateField.IS_PRODUCTION, !booking.isProduction)}
                      onText="Yes"
                      offText="No"
                      bsSize="small" />
                  </div>
                </div>
              </div>)
            }
            {
              booking.status === "CANCELLED" && (
              <div className="main-details-row">
                <div className="booking-notes">
                  <div className="detail-heading">
                    Reason for cancellation
                  </div>
                  <div className="detail-value">
                    <EditField
                      dbKey="cancellationReason"
                      type="select"
                      options={BOOKING_CANCELLATION_REASONS}
                      value={booking.cancellationReason}
                      saveHandler={(_key, value) => this.props.onUpdateBooking(BookingUpdateField.CANCELLATION_REASON, value)}
                    />
                  </div>
                </div>
              </div>)
            }

            {
              booking.status === "CANCELLED" && booking.customerCancellationReason !== "" && (
              <div className="main-details-row">
                <div className="booking-notes">
                  <div className="detail-heading">
                    Customer cancellation reason
                  </div>
                  <div className="detail-value">
                    {booking.customerCancellationReason}
                  </div>
                </div>
              </div>)
            }

          </div>

          <div className="customer">
            <CustomerPanel customer={booking.customer} />
          </div>
        </div>

        <Treatments
          booking={booking}
          treatments={this.props.treatments}
          treatmentTotal={treatmentTotal}
          addNewTreatmentHandler={this.props.addNewBookingTreatment}
          deleteTreatmentHandler={this.props.deleteBookingTreatment}
          savePriceHandler={this.props.updateBookingTreatment} />

        <Promotions
          promotions={booking.transactions.filter(t => t.type === constants.TRANSACTION_PROMO || t.type === constants.TRANSACTION_COMPLIMENTARY)}
          treatments={booking.bookingTreatments}
          treatmentTotal={treatmentTotal}
          discountTotal={discountTotal}
          clientToPay={clientToPayIncludingCredits}
          submitFree={this.props.submitFree}
          promos={this.props.promos}
          isBusy={this.props.isPromoBusy}
          fetchPromos={this.props.fetchPromos}
          submitPromo={this.props.submitPromo}
          transactionState={this.props.transactionState}
          submittingPromos={this.props.submittingPromos}
          removePromo={this.props.removePromo}
          deleting={this.props.deleting}
          displayNotification={this.props.displayNotification} />

        <Transactions
          transactions={filteredTransactions}
          outstanding={outstanding}
          cards={this.props.cards}
          booking={booking}
          fetchCustomerCards={this.props.fetchCustomerCards}
          debitCard={this.props.debitCard}
          transactionState={this.props.transactionState}
          addTransaction={this.props.createTransaction}
          openTransactionModal={this.props.openTransactionModal}
          closeTransactionModal={this.props.closeTransactionModal}
          manualRefund={this.props.manualRefund}
          dispatch={this.props.dispatch}/>

        {booking.refunds && <Refunds
          refunds={booking.refunds}
          transactions={booking.transactions}
          deleteRefund={(refund) => this.props.removeRefund(refund)}
        />}
        
        <Adjustments
          adjustments={booking.transactions.filter(transaction => _.includes([constants.TRANSACTION_TYPE_ROD_DEDUCTION], transaction.type))}
          addAdjustment={this.props.createTransaction}
          deleteAdjustmentByUrn={this.props.deleteAdjustmentByUrn}
          deleteAdjustmentByIndex={(_index: number) => { throw new Error("deleteAdjustmentByIndex called when booking exists, so deleteBookingByUrn should be used") }}
          bookingUrn={booking.urn}
        />

        <ExternalCommissions
          commissions={booking.transactions.filter(transaction => _.includes([constants.TRANSACTION_EXTERNAL_COMMISSION], transaction.type))}
          add={this.props.createTransaction}
          delete={this.props.deleteAdjustmentByUrn}
          bookingUrn={booking.urn}
          grossValue={booking.totalPrice}
        />
        
        <Commission
          treatmentTotal={booking.totalPrice - adminFeeAmount}
          commission={booking.commission}
          isB2B={booking.isB2b}
        />

        {bookingTipAmount !== 0 &&
          <CustomerTip tipAmount={bookingTipAmount} />
        }

        <Totals
          bookingUrn={booking.urn}
          totalToPartner={bookingTotals.totalToPartner}
          refundInfo={booking.refundInfo}
          total={booking.totalPrice}
          adminFeeAmount={adminFeeAmount}
          isB2B={booking.isB2b}
        />
      </div>
    );
  }
}
