import { createStandardAction } from "typesafe-actions";
import { History } from "history";

import { ActionDispatch } from "../../";
import * as treatmentApi from "../../../api/main/treatment";
import { addNotification } from "../../notifications/actions";
import { NOTIFICATION_TYPES } from "../../notifications/types";
import { fetchAllCategories } from "../../categories/actions";

interface RequestSuccessPayload {
  treatments: treatmentApi.ApiTreatment[];
}

interface UpdateCreateFieldPayload {
  field: treatmentApi.TreatmentUpdateField;
  value: treatmentApi.TreatmentUpdateValue;
}

export const actions = {
  requestInit: createStandardAction("treatments/REQUEST_INIT")<void>(),
  requestSuccess: createStandardAction("treatments/REQUEST_SUCCESS")<RequestSuccessPayload>(),
  updateSearchResults: createStandardAction("treatments/UPDATE_SEARCH_RESULTS")<RequestSuccessPayload>(),
  updateCreateField: createStandardAction("treatments/UPDATE_CREATE_FIELD")<UpdateCreateFieldPayload>(),
  createInit: createStandardAction("treatments/CREATE_INIT")<void>(),
  createAttempt: createStandardAction("treatments/CREATE_ATTEMPT")<void>(),
  createSuccess: createStandardAction("treatments/CREATE_SUCCESS")<void>(),
  createFailure: createStandardAction("treatments/CREATE_FAILURE")<void>(),
  deleteSuccess: createStandardAction("treatments/DELETE_SUCCESS")<string>(),
};

/**
 * Fetch all treatments
 */
export function fetchAllTreatments(): ActionDispatch {
  return async (dispatch) => {
    dispatch(actions.requestInit());

    const treatments = await treatmentApi.fetchAll();

    dispatch(actions.requestSuccess({ treatments }));
  };
}

/**
 * Fetch all treatments for a therapist
 * @param therapistUrn Therapist's urn to fetch all treatments for
 */
export function fetchAllTreatmentsForTherapist(therapistUrn: string): ActionDispatch {
  return async (dispatch) => {
    dispatch(actions.requestInit());

    const treatments = await treatmentApi.fetchTreatmentsForTherapist(therapistUrn);

    dispatch(actions.requestSuccess({ treatments }));
    dispatch(actions.updateSearchResults({ treatments }));
  };
}

/**
 * Initiate a search for treatments
 * @param query Query string to search
 */
export function initiateSearch(query: string): ActionDispatch {
  return async (dispatch) => {
    dispatch(actions.requestInit());

    const treatments = await treatmentApi.search(query);

    dispatch(actions.requestSuccess({ treatments }));
    dispatch(actions.updateSearchResults({ treatments }));
  };
}

/**
 * Load the data needed to display the view treatment screen
 * @param treatmentUrn URN of treatment to load the data for
 */
export function initiateViewTreatment(treatmentUrn: string): ActionDispatch {
  return async (dispatch, getState) => {
    // check if we already have the treatment loaded
    if (typeof getState().treatments.treatments[treatmentUrn] === "undefined") {
      dispatch(actions.requestInit());

      // fetch treatment
      const treatment = await treatmentApi.fetch(treatmentUrn);

      dispatch(actions.requestSuccess({ treatments: [treatment] }));
    }

    // check if category data is in place
    if (Object.keys(getState().categories.categories).length === 0) {
      dispatch(fetchAllCategories());
    }
  };
}

/**
 * Initiate state for creating treatments
 */
export function initiateCreateTreatment(): ActionDispatch {
  return async (dispatch, getState) => {
    dispatch(actions.createInit());

    // check if category data is in place
    if (Object.keys(getState().categories.categories).length === 0) {
      dispatch(fetchAllCategories());
    }
  };
}

/**
 * Create a treatment
 */
export function createTreatment(history: History): ActionDispatch {
  return async (dispatch, getState) => {
    const params = getState().treatments.createState;

    dispatch(actions.createAttempt());

    try {
      const treatmentUrn = await treatmentApi.create({
        name: params[treatmentApi.TreatmentUpdateField.NAME] as string,
        description: params[treatmentApi.TreatmentUpdateField.DESCRIPTION] as string,
        londonPriceElite: params[treatmentApi.TreatmentUpdateField.LONDON_PRICE_ELITE] as number,
        londonPriceClassic: params[treatmentApi.TreatmentUpdateField.LONDON_PRICE_CLASSIC] as number,
        londonPriceBlackLabel: params[treatmentApi.TreatmentUpdateField.LONDON_PRICE_BLACK_LABEL] as number,
        londonPriceHospitality: params[treatmentApi.TreatmentUpdateField.LONDON_PRICE_HOSPITALITY] as number,
        duration: params[treatmentApi.TreatmentUpdateField.DURATION] as number,
        categoryUrns: params[treatmentApi.TreatmentUpdateField.CATEGORIES] as string[],
        isAddon: params[treatmentApi.TreatmentUpdateField.IS_ADDON] as boolean,
      });

      dispatch(actions.createSuccess());
      history.replace(`/treatments/${treatmentUrn}`);
    } catch (err) {
      dispatch(addNotification(NOTIFICATION_TYPES.danger, "Failed to create new treatment", err.response.data.error));
      dispatch(actions.createFailure());
    }
  };
}

/**
 * Delete treatment
 * @param treatmentUrn URN of treatment to delete
 */
export function deleteTreatment(treatmentUrn: string): ActionDispatch {
  return async (dispatch) => {
    try {
      await treatmentApi.deleteTreatment(treatmentUrn);
      dispatch(addNotification(NOTIFICATION_TYPES.success, "Updated!", "Treatment deleted"));
      dispatch(actions.deleteSuccess(treatmentUrn));
    } catch (err) {
      dispatch(addNotification(NOTIFICATION_TYPES.danger, `Failed to delete treatment`));
    }
  };
}

