import * as React from "react";
import styled from "styled-components";

const HeaderText1 = styled.div`
    color: #ffffff;
    float: left;
`;

interface Props {
  text: string;
}

export const DataTableHeaderText: React.StatelessComponent<Props> = props => {

  return (
    <HeaderText1>
      {props.text}
    </HeaderText1>
  );
};
