import * as React from "react";
import { Dispatch } from "redux";

import { ApiTherapist } from "../../api/main/therapist";
import { config } from "../../config";

interface Props {
  therapist: ApiTherapist;
  dispatch: Dispatch<{}>;
}

export class PartnerTherapistDetails extends React.Component<Props> {
  /**
   * Displays therapist field and allows field to be edited via a input field
   * displayed after fa-pencil is clicked
   * @param  key: string        What data field holds. Must correspond to database suffix after "salon_"
   * @param  stateField: string        Corresponding value in component state
   * @param  currentValue: string        Current value of field
   */
  handleInputField = (currentValue: string): JSX.Element => {
    return (
      <div className="field-edit">
        {currentValue}
      </div>
    );
  }

  handleTextareaField = (currentValue: string): JSX.Element => {
    return (
      <div className="field-edit">
        {currentValue}
      </div>
    );
  }

  buildLinkToProfile = () => {
    return <a href={`${config.customerWebsiteUrl}book/${this.props.therapist.slug}`} target="_blank" style={{ color: "#337ab7", textDecoration: "underline" }}>Slug</a>;
  }

  render(): JSX.Element {
    const therapist = this.props.therapist;

    return (
      <div>
        <h1>
          {this.handleInputField(therapist.name.split(' ')[0])}
        </h1>

        <div className="app-details-row">
          <div className="main-details">
            {/* <div className="main-details-row">
              <div className="booking-date">
                <div className="detail-heading">
                  Rating score
                </div>
                <div className="detail-value">
                  {this.props.therapist.rating.toFixed(2)}
                </div>
              </div>
            </div> */}
            {/* <div className="main-details-row">
              <div className="booking-date">
                <div className="detail-heading">
                  Xero ID
                </div>
                <div className="detail-value">
                  {this.handleInputFieldEdit(TherapistUpdateField.XEROID, "editingXeroid", therapist.xeroId)}
                </div>
              </div>
              <div className="therapist-booking-lead-time">
                <div className="detail-heading">
                  Booking lead time
                </div>
                <div className="detail-value">
                  {this.handleInputFieldEdit(TherapistUpdateField.BOOKING_LEAD_TIME, "editingBookingLeadTime", therapist.bookingLeadTime)}
                </div>
              </div>
            </div> */}

            {/* <div className="main-details-row">
              <div className="booking-date">
                <div className="detail-heading">
                  {this.buildLinkToProfile()}
                </div>
                <div className="detail-value">
                  {this.handleInputFieldEdit(TherapistUpdateField.SLUG, "editingSlug", therapist.slug)}
                </div>
              </div>
              <div className="booking-date">
                <div className="detail-heading">
                  Email
                </div>
                <div className="detail-value">
                  {this.handleInputFieldEdit(TherapistUpdateField.EMAIL, "editingEmail", therapist.email)}
                </div>
              </div>
            </div> */}

            <div className="main-details-row">
              {/* <div className="therapist-commission">
                <div className="detail-heading">
                  Commission
                </div>
                <div className="detail-value">
                  <EditField dbKey={TherapistUpdateField.COMMISSION} type="percentage" value={therapist.commission} saveHandler={this.handleCommissionUpdate} />
                </div>
              </div> */}
              {/* <div className="booking-time">
                <div className="detail-heading">
                  Phone
                </div>
                <div className="detail-value">
                  {this.handleInputFieldEdit(TherapistUpdateField.TELEPHONE, "editingPhone", therapist.telephone)}
                </div>
              </div> */}
            </div>

            {/* <div className="main-details-row">
              <div className="therapist-recommended">
                <div className="detail-heading">
                  Elite
                </div>
                <div className="detail-value">
                  <Switch value={therapist.isElite} onChange={this.handleEliteUpdate} bsSize="small" onText="YES" offText="NO" />
                </div>
              </div>
              <div className="therapist-recommended">
                <div className="detail-heading">
                  Recommended
                </div>
                <div className="detail-value">
                  <Switch value={therapist.recommended} onChange={this.handleRecommendUpdate} bsSize="small" onText="YES" offText="NO" />
                </div>
              </div>
            </div> */}

            {/* <div className="main-details-row">
              <div className="therapist-recommended">
                <div className="detail-heading">
                  On demand account
                </div>
                <div className="detail-value">
                  <Switch value={therapist.isNotStandardTherapist} onChange={this.toggleNotStandardTherapist} bsSize="small" onText="YES" offText="NO" />
                </div>
              </div>
              <div className="therapist-recommended">
                <div className="detail-heading">
                  Auto-set availability
                </div>
                <div className="detail-value">
                  <Switch value={therapist.isAutoSetAvailability} onChange={this.toggleAutoSetAvailability} bsSize="small" onText="YES" offText="NO" />
                </div>
              </div>
            </div> */}

            {/* <div className="main-details-row">
              <div className="therapist-recommended">
                <div className="detail-heading">
                  Onboarded
                </div>
                {therapist.timeOnboarded ?
                  (<div className="field-edit">
                    {moment(therapist.timeOnboarded).format("DD MMM YYYY")}
                  </div>)
                  : (
                    <div className="detail-value">
                      <Switch value={therapist.isOnboarded} onChange={this.toggleOnboardUpdate} bsSize="small"
                        onText="YES" offText="NO" disabled={therapist.isOnboarded} wrapperClass="onboardedSwitch" />
                    </div>
                  )
                }
              </div>
            </div> */}

            <div className="main-details-row">
              <div className="therapist-description">
                <div className="detail-heading">
                  Description
                </div>
                <div className="detail-value">
                  {this.handleTextareaField(therapist.description)}
                </div>
              </div>
            </div>

            <div className="main-details-row">
              <div className="therapist-description">
                <div className="detail-heading">
                  Quote
                </div>
                <div className="detail-value">
                  {this.handleInputField(therapist.quote)}
                </div>
              </div>
            </div>
            {/*
            <div className="main-details-row">
              <div className="therapist-description">
                <div className="detail-heading">
                  Products
                </div>
                <div className="detail-value">
                  {this.handleInputFieldEdit(TherapistUpdateField.PRODUCTS, "editingProducts", therapist.products)}
                </div>
              </div>
            </div> */}

            <div className="main-details-row">
              <div className="therapist-description">
                <div className="detail-heading">
                  Internal Notes
                </div>
                <div className="detail-value">
                  {this.handleTextareaField(therapist.internalNotes)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
