import { Nav, NavItem } from "react-bootstrap";
import "react-image-crop/dist/ReactCrop.css";
import * as React from "react";
import Modal from "react-modal";

import {config} from "../../config";
import * as Spinner from "react-spinkit";
import { ApiTherapist } from "../../api/main/therapist";
import { Dispatch } from "redux";

import { persistImageCrop } from "../../model/therapist";
import { therapistActions } from "../../reducers/therapist/actions";

const ReactCrop = require("react-image-crop");
interface Props {
  isBusy: boolean;
  index: string;
  therapist: ApiTherapist;
  modalIsOpen: boolean;
  closeModal: () => void;
  dispatch: Dispatch<{}>;
}

interface State {
  crop: string;
}

export class EditImageModal extends React.Component<Props, State> {
  state: State = {
    crop: "1x1"
  };

  handleSelect = (eventKey: any) => {
    event.preventDefault();
    this.setState({crop: eventKey});
  }

  persistImageCrop = () => {
    this.props.dispatch(persistImageCrop(this.props.therapist) as any);
  }

  onComplete = (percentage: any, pixels: any) => {
    this.props.dispatch(therapistActions.updateImageCrop({
      urn: this.props.therapist.urn,
      index: this.props.index,
      crop: { percentage, pixels },
      aspect: this.state.crop
    }));
  }

  render() {
    const styles = {
      content : {
        top: "10%",
        left: "10%",
        right: "10%",
        bottom: "10%"
      }
    };
    const { therapist } = this.props;
    const imgs = therapist.images[this.props.index];
    const crop = imgs.crop[this.state.crop].percentage;

    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        contentLabel={`Edit ${this.props.index}`}
        style={styles}
      >
        {(this.props.isBusy) ?
          <div className="spinner-container">
            <Spinner name="three-bounce" fadeIn="none" />
          </div> : ""}
        <div className={(this.props.isBusy) ? "modal_wrapper persisting_image" : "modal_wrapper"}>
          <div className="topNav">
            <div className="icons">
              <div className="icons_overlay"></div>
              <div className="fa fa-floppy-o" aria-hidden="true" onClick={this.persistImageCrop}></div>
              <div className="fa fa-times" aria-hidden="true" onClick={this.props.closeModal}></div>
            </div>
          </div>
          <Nav bsStyle="tabs" activeKey={this.state.crop} onSelect={this.handleSelect}>
            <NavItem eventKey="1x1">1x1</NavItem>
            <NavItem eventKey="1.8x1">1.8x1</NavItem>
          </Nav>
          <ReactCrop src={config.s3.bucket + imgs.src} crop={crop} keepSelection={true} onComplete={this.onComplete} />
        </div>
      </Modal>
    );
  }
}
