import * as React from "react";
import * as Spinner from "react-spinkit";
import Switch from "react-bootstrap-switch";

import { PostcodeArea, PostcodesPresenter } from "../../../presenters/postcodes/postcodes-presenter";
import { PostcodeAreaComponent } from "./postcode-area";
import { TherapistPostcodesPresenter } from "../../../presenters/therapist/therapist-postcodes-presenter";
import styled from "../../../../node_modules/styled-components";
import { mapLinkContent, flagIcon } from "./postcode-district";

interface Props {
  therapistName: string;
  therapistUrn: string;
  therapistIsPending: boolean; // therapist data is loading
  therapistPostcodesPending: boolean; // therapist postcode update pending
  availablePostcodes: PostcodesPresenter;
  therapistPostcodesPresenter: TherapistPostcodesPresenter;
  setPostcodeForTherapist: SetPostcodeForTherapist;
  setSwitzerland: (value: boolean) => void;
}

export type SetPostcodeForTherapist = (therapistUrn: string, postcodeUrn: string, enabled: boolean) => void;

/**
 * convenience function for mapping a list of area presenters to components
 */
const mapAreaToComponent = (
  therapistUrn: string,
  area: PostcodeArea,
  therapistPostcodesPresenter: TherapistPostcodesPresenter,
  setPostcodeForTherapist: SetPostcodeForTherapist
) => {
  return (
    <PostcodeAreaComponent
      therapistUrn={therapistUrn}
      area={area}
      setPostcodeForTherapist={setPostcodeForTherapist}
      therapistPostcodesPresenter={therapistPostcodesPresenter}
      key={area.areaCode}
    />
  );
};

const AreaLink = styled.a`
padding: 0.5em;
float: left;
margin-right: 10px;
margin-bottom: 10px;
width: 36px;
text-align: center;
color: #000000;
background-color: #ffffff;
padding: 8px;

&:hover {
  text-decoration: none;
  color: #000000;
}
`;

const EnabledAreaLink = styled.a`
padding: 0.5em;
float: left;
margin-right: 10px;
margin-bottom: 10px;
width: 36px;
text-align: center;
color: #333333;
background-color: #fdeaed;
padding: 8px;

&:hover {
  text-decoration: none;
  color: #333333;
}
`;

const StickyNav = styled.div`
position: sticky;
top: 0;
z-index: 100;
background-color: #f4f4f4;
opacity: 1;
`;

const PageTitle = styled.h1`
display: flex;
justify-content: space-between;
font-family: "nunito_sansbold";
color: #565656;
`;

/**
 * Component for displaying and updating therapists postcode list
 */
export const TherapistPostcodes: React.StatelessComponent<Props> = (props: Props) => {
  const hasEnabledOutcode = (area: PostcodeArea) => {
    for (const districtGroup of area.children) {
      for (const district of districtGroup.children) {
        if (props.therapistPostcodesPresenter.isPostcodeEnabled(district.urn)) {
          return true;
        }
      }
    }
    return false;
  };

  const areaLinks = props.availablePostcodes.getAreas().map((area) => {
    if (hasEnabledOutcode(area)) {
      return (
        <EnabledAreaLink key={area.areaCode} href={`#${area.areaCode}`}>
          {area.areaCode}
        </EnabledAreaLink>
      );
    }
    else {
      return (
        <AreaLink key={area.areaCode} href={`#${area.areaCode}`}>
          {area.areaCode}
        </AreaLink>
      );
    }
  });

  const areaComponents = props.availablePostcodes.getAreas().map(
    (area) => mapAreaToComponent(
      props.therapistUrn,
      area,
      props.therapistPostcodesPresenter,
      props.setPostcodeForTherapist
    )
  );

  const hasSwitzerland = React.useMemo(() => Boolean(props.therapistPostcodesPresenter.therapistPostcodes["urn:ruuby:district:CH-8"]), [props.therapistPostcodesPresenter.therapistPostcodes]);

  const toggleSwitzerland = React.useCallback((_, value) => props.setSwitzerland(value), [props.setSwitzerland]);

  return (
    <div>
      <StickyNav>

        <div className="row">
          <div className="col-lg-12">
            <PageTitle>{props.therapistName} {props.therapistPostcodesPending ? <Spinner name="three-bounce" fadeIn="none" /> : null}</PageTitle>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            {areaLinks}
            <a href="#notes">notes</a>
          </div>
        </div>
      </StickyNav>
      <div className="row">
        <div className="col-lg-12">
          {areaComponents}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <a id="notes"></a>
          <ul>
            <li>Place names are approximate. Check the linked maps for details.</li>
            <li>Non-geographic postcodes are used for some institutions, e.g. BBC.</li>
            <li>{flagIcon.top} = top most popular postcodes; recommended that therapists support these if they can</li>
            <li>{flagIcon.congestion} = congestion charging applies (not necessarily to the whole of the postcode district)</li>
            <li>To view maps on doogal.co.uk: use the {mapLinkContent} icon, "Click to view map" then use "show/hide postcodes" to see the outline.</li>
            <li>See also <a target="_new" href="https://www.doogal.co.uk/london_postcodes.php">London Postcodes Map</a>.</li>
            <li>The postcode data came from <a target="_new" href="https://www.doogal.co.uk/UKPostcodes.php">doogal.co.uk</a>.</li>
            <li>Contains OS data © Crown copyright and database rights 2019.</li>
            <li>Contains Royal Mail data © Royal Mail copyright and database rights 2019.</li>
            <li>Contains National Statistics data © Crown copyright and database rights 2019.</li>
          </ul>
        </div>

        <p>
          Switzerland:
          <Switch value={hasSwitzerland} onChange={toggleSwitzerland} onText="YES" offText="NO" />
        </p>
      </div>
    </div>
  );
}
