import * as React from "react";
import { Table } from "react-bootstrap";
import { sprintf } from "sprintf-js";
import { RefundInfo } from "../../api/main/booking";
import { config } from "../../config";

interface Props {
  bookingUrn: string;
  totalToPartner: number;
  refundInfo: RefundInfo;
  total: number;
  adminFeeAmount: number;
  isB2B: boolean;
}

export const Totals: React.SFC<Props> = ({ bookingUrn, totalToPartner, refundInfo, total, adminFeeAmount, isB2B }) => {
  const urn = bookingUrn.substring(bookingUrn.lastIndexOf(":") + 1);
  const newAdminPanelURL = `${config.newAdminPanelUrl}/bookings/${urn}`

  return (
    <div className="adjustments booking-totals">
      <br />
      <div className="alert alert-info" role="alert">
        Note: Ruuby and therapist takings are now only available in the new <a className="alert-link" href={newAdminPanelURL} style={{ textDecoration: "underline" }}>admin panel</a>.
      </div>

      <Table>
        <colgroup>
          <col width="20%" />
          <col width="20%" />
          <col width="20%" />
          <col width="40%" />
        </colgroup>

        {refundInfo ?
          <tbody>
            <tr className="refund">
              <td className="total">Total to Client</td>
              <td></td>
              <td>{sprintf("£%0.2f", refundInfo.clientAmount)}</td>
              <td></td>
            </tr>
          </tbody>
          :
          <tbody>
            {isB2B && 
              <tr>
                <td className="total">Total to Partner</td>
                <td></td>
                <td>{sprintf("£%0.2f", totalToPartner)}</td>
                <td></td>
              </tr>
            }          
            <tr>
              <td className="total">Booking fee</td>
              <td></td>
              <td>{sprintf("£%0.2f", adminFeeAmount)}</td>
              <td></td>
            </tr>
            <tr>
              <td className="total">Booking total</td>
              <td></td>
              <td style={{ fontWeight: "bold" }}>{sprintf("£%0.2f", total)}</td>
              <td></td>
            </tr>
          </tbody>
        }
      </Table>
    </div>
  );
};
