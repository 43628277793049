import * as types from "./types";

import {ActionDispatch} from "../";
import {fetchLogs, ActivityLog} from "../../api/main/audit";

export const actionCreators = {
  fetchLogsAttempt: () => ({
    type: types.FETCH_LOGS_ATTEMPT,
  }),
  fetchLogsSuccess: (logs: ActivityLog[]) => ({
    type: types.FETCH_LOGS_SUCCESS,
    payload: {logs}
  }),
  fetchLogsFail: () => ({
    type: types.FETCH_LOGS_FAIL,
  }),
  clearActivityLog: () => ({
    type: types.CLEAR_LOGS
  })
};

export function fetchActivityLogs(bookingUrn: string): ActionDispatch {
  return async dispatch => {
    dispatch(actionCreators.fetchLogsAttempt());

    try {
      const logs = await fetchLogs(bookingUrn);
      dispatch(actionCreators.fetchLogsSuccess(logs));
    } catch (error) {
      console.error("Error fetching logs", error);
    }
  };
}
