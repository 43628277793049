import styled from "styled-components";

import { editButtonCss } from "../../atoms/editable-field-icon";
import { FieldLabel } from "../../atoms/field-label";
import { MixinEditableField } from "../mixin-editable-field";

const Paragraph = styled.p`
  font-family: "nunito_sansregular";
  color: #333333;
  font-size: 15px;

  ${editButtonCss}
`;

const Container = styled.div`
  width: 100%;
  display: inline-flex;
`;

const Input = styled.input`
  font-family: "nunito_sansregular";
  color: #333333;
  padding: 1px 5px;
`;

export class EditableNumber extends MixinEditableField {
  render(): JSX.Element {
    const fieldLabel = <FieldLabel>{this.props.label}</FieldLabel>;

    if (this.props.isEditing) {
      return (
        <div>
          {fieldLabel}
          <Container>
            <Input
              type="number"
              onChange={e => this.setState({...this.state, editingValue: (e.target as any).value})}
              value={this.state.editingValue}
            />
            {this.renderButtons()}
          </Container>
        </div>
      );
    }
    else {
      return (
        <div>
          {fieldLabel}
          <Paragraph>
            {this.props.value} {this.renderEditIcon()}
          </Paragraph>
        </div>
      );
    }
  }
}
