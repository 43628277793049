import { createStandardAction } from "typesafe-actions";
import { ActionDispatch } from "../../reducers";
import * as api from "../../api/main/booking";
import * as apiFraudulent from "../../api/main/fraudulent-addresss";

export const actions = {
  requestGetAllFraudulentAddressesInit: createStandardAction("fraudulentAddress/REQUEST_GET_ALL_FRAUDULENT_ADDRESSES_INIT")<void>(),
  requestGetAllFraudulentAddressesSuccess: createStandardAction("fraudulentAddress/REQUEST_GET_ALL_FRAUDULENT_ADDRESSES_SUCCESS")<api.ApiAddress[]>(),

  requestCreateFraudulentAddressInit: createStandardAction("fraudulentAddress/REQUEST_CREATE_FRAUDULENT_ADDRESSES_INIT")<void>(),
  requestCreateFraudulentAddressSuccess: createStandardAction("fraudulentAddress/REQUEST_CREATE_FRAUDULENT_ADDRESSES_SUCCESS")<api.ApiAddress>(),

  requestDeleteFraudulentAddressInit: createStandardAction("fraudulentAddress/REQUEST_DELETE_FRAUDULENT_ADDRESS_INIT")<void>(),
  requestDeleteFraudulentAddressSuccess: createStandardAction("fraudulentAddress/REQUEST_DELETE_FRAUDULENT_ADDRESS_SUCCESS")<{ urn: string }>(),
};

export function fetchFraudulentAddresses(): ActionDispatch {

  return async dispatch => {
    dispatch(actions.requestGetAllFraudulentAddressesInit());
    const fraudulentAddress = await apiFraudulent.fetch();
    dispatch(actions.requestGetAllFraudulentAddressesSuccess(fraudulentAddress));
  };
}

export function createFraudulentAddress(address: api.ApiAddress): ActionDispatch {
  return async dispatch => {
    dispatch(actions.requestCreateFraudulentAddressInit());
    dispatch(actions.requestCreateFraudulentAddressSuccess(address));
    await apiFraudulent.createFraudulentAddress(address);
  };
}

export function deleteBookingStatement(urn: string): ActionDispatch {
  return async dispatch => {
    dispatch(actions.requestDeleteFraudulentAddressInit());
    dispatch(actions.requestDeleteFraudulentAddressSuccess({ urn }));
    await apiFraudulent.deleteFraudulentAddress(urn);
  };
}
