import {createSelector, ParametricSelector} from "reselect";

import { RootState } from "../index";
import { inflateTherapistPostcodes } from "../../presenters/therapist/therapist-postcodes-presenter";

interface TherapistUrnSelectorProps {
  therapistUrn: string;
}

/**
 * Get the list of postcodes for a therapist
 */
const therapistPostcodesSelector: ParametricSelector<RootState, TherapistUrnSelectorProps, string[]> = (rootState: RootState, props: TherapistUrnSelectorProps) => {
  if (typeof rootState.therapistsState.therapists[props.therapistUrn] === "undefined") {
    return undefined;
  }
  return rootState.therapistsState.therapists[props.therapistUrn].postcodeUrns;
}

/**
 * Return the information about available postcodes
 */
export const getPostcodesForTherapist = createSelector(
  [ therapistPostcodesSelector ],
  inflateTherapistPostcodes,
);
