import * as React from "react";
import * as Dropzone from "react-dropzone";
import * as Spinner from "react-spinkit";
import { TherapistImage } from "./therapist-image";
import { BusyState } from "../../reducers/therapist/reducer";
import { Dispatch } from "redux";

import { getPutImageUrl } from "../../model/therapist";
import { ApiTherapist } from "../../api/main/therapist";

interface Props {
  isBusy: boolean;
  isPartner: boolean;
  therapist: ApiTherapist;
  busy: BusyState;
  dispatch: Dispatch<{}>;
}

export const TherapistImages: React.SFC<Props> = ({
  isBusy,
  isPartner,
  busy,
  therapist,
  dispatch,
}: Props) => {
  const imgsArray = Object.keys(therapist.images);
  const onImagesDrop = (
    acceptedFiles: any,
    rejectedFiles: any,
    isIndex = false,
  ) => {
    if (rejectedFiles.length > 0) {
      console.error("rejected", rejectedFiles);
    }

    if (isIndex === true) {
      dispatch(getPutImageUrl(therapist.urn, "index", acceptedFiles[0]));

      return;
    }

    const currentLength = !therapist.images["index"]
      ? imgsArray.length
      : imgsArray.length - 1;
    let newIndex: string;
    let count = 0;
    acceptedFiles.forEach((file: any) => {
      count++;
      const i = currentLength + count;
      newIndex = `image${i.toString().padStart(2, "0")}`;

      dispatch(getPutImageUrl(therapist.urn, newIndex, file));
    });
  };

  let images = imgsArray.map((key) => {
    return busy.image[key] ? (
      <div key={key} className="img-dropzone">
        <Spinner name="pulse" fadeIn="none" />
      </div>
    ) : (
      <TherapistImage
        key={key}
        index={key}
        isBusy={isBusy}
        therapist={therapist}
        dispatch={dispatch}
        isPartner={isPartner}
      />
    );
  });

  return (
    <div>
      {!therapist.images["index"] && !isPartner && (
        <div className="img-dropzone">
          <Dropzone
            onDrop={(acceptedFiles: any, rejectedFiles: any) =>
              onImagesDrop(acceptedFiles, rejectedFiles, true)
            }
            multiple={false}
          >
            <i className="fa fa-upload"></i>
            <p>Avatar (index)</p>
          </Dropzone>
        </div>
      )}
      {images}
      {isBusy && <Spinner name="pulse" fadeIn="none" />}
      {!isPartner && (
        <div className="img-dropzone">
          <Dropzone onDrop={onImagesDrop} multiple>
            <i className="fa fa-upload"></i>
            <p>Add multiple images</p>
          </Dropzone>
        </div>
      )}
    </div>
  );
};
