import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "../../reducers/index";
import { PartnerHome } from "./partner-home";

interface ReduxProps {
  isPartner: boolean;
}

class Component extends React.Component<ReduxProps, {}> {

  render(): JSX.Element {
    if (this.props.isPartner) {
      return <PartnerHome />
    }

    return (
      <div className="home-container">
        <h1>Ruuby Admin Panel</h1>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): ReduxProps => ({
  isPartner: state.operator.isPartner
});

export const HomeContainer = connect<ReduxProps, {}, {}>(mapStateToProps)(Component);
