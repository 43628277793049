import * as React from "react";
import { Tab, Tabs } from "react-bootstrap";

import GeneralTabContent from "./GeneralTab";
import CommissionTabContent from "./CommissionTab";
import B2BTabContent from "./B2BTab";

interface RegionTabsProps {
  inputTextChange: (key: string, e: any) => void;
  inputNumberChange: (key: string, e: any) => void;
  checkboxChange: () => void;
  tabsId: string;
  name: string;
  classicCommission: number;
  eliteCommission: number;
  rodSurcharge: number;
  b2bCommission: number;
  isActive: boolean;
}

export default class RegionTabs extends React.Component<RegionTabsProps, {}> {
  render() {
    return (
      <Tabs mountOnEnter={true} defaultActiveKey="general" id={this.props.tabsId} className="mb-3" style={{ margin: "20px" }}>
        <Tab eventKey="general" title="General" style={{ height: "240px" }}>
          <GeneralTabContent
            name={this.props.name}
            isActive={this.props.isActive}
            inputTextChange={this.props.inputTextChange}
            checkboxChange={this.props.checkboxChange}
          />
        </Tab>
        <Tab eventKey="commission" title="Commission" style={{ height: "240px" }}>
          <CommissionTabContent
            classicCommission={this.props.classicCommission}
            eliteCommission={this.props.eliteCommission}
            rodSurcharge={this.props.rodSurcharge}
            inputNumberChange={this.props.inputNumberChange}
          />
        </Tab>
        <Tab eventKey="b2b" title="B2B" style={{ height: "240px" }}>
          <B2BTabContent
            b2bCommission={this.props.b2bCommission}
            inputNumberChange={this.props.inputNumberChange}
          />
        </Tab>
      </Tabs>
    )
  }
}