export type FETCH_PROMOS_SUCCESS = "promotion.FETCH_PROMOS_SUCCESS";
export const FETCH_PROMOS_SUCCESS: FETCH_PROMOS_SUCCESS = "promotion.FETCH_PROMOS_SUCCESS";
export type FETCH_PROMOS_ATTEMPT = "promotion.FETCH_PROMOS_ATTEMPT";
export const FETCH_PROMOS_ATTEMPT: FETCH_PROMOS_ATTEMPT = "promotion.FETCH_PROMOS_ATTEMPT";

export type FETCH_SEARCH_PROMO_SUCCESS = "promotion.FETCH_SEARCH_PROMO_SUCCESS";
export const FETCH_SEARCH_PROMO_SUCCESS: FETCH_SEARCH_PROMO_SUCCESS = "promotion.FETCH_SEARCH_PROMO_SUCCESS";
export type FETCH_SEARCH_PROMO_ATTEMPT = "promotion.FETCH_SEARCH_PROMO_ATTEMPT";
export const FETCH_SEARCH_PROMO_ATTEMPT: FETCH_SEARCH_PROMO_ATTEMPT = "promotion.FETCH_SEARCH_PROMO_ATTEMPT";
export type FETCH_SEARCH_PROMO_FAIL = "promotion.FETCH_SEARCH_PROMO_FAIL";
export const FETCH_SEARCH_PROMO_FAIL: FETCH_SEARCH_PROMO_FAIL = "promotion.FETCH_SEARCH_PROMO_FAIL";
