import * as React from "react";
import * as Select from "react-select";

import * as therapistApi from "../../../../api/main/therapist";

interface BookingTherapistProps {
  onChangeBookingTherapist: (therapist: therapistApi.ApiTherapist) => void;
}

interface State {
  therapist: {
    value: string;
    label: string;
  }
  therapistRegion: string;
}

export default class BookingTherapist extends React.Component<BookingTherapistProps, State> {
  therapists: therapistApi.ApiTherapist[];

  state: State = {
    therapist: undefined,
    therapistRegion: "",
  }

  async therapistChanged(option: any) {
    const therapist = this.therapists.find((therapist) => therapist.urn === option.value);

    let regionLabel;
    if (typeof therapist.regions !== "undefined" && therapist.regions.length !== 0) {
      regionLabel = therapist.regions[0];
    }
    else {
      regionLabel = "London";
    }

    this.setState({
      therapist: {
        value: therapist.urn,
        label: therapist.name
      },
      therapistRegion: regionLabel,
    });

    const therapistDetails = await therapistApi.fetchTherapist(option.value);
    this.props.onChangeBookingTherapist(therapistDetails);
  }

  async searchTherapist(name: string) {
    const therapists = await therapistApi.search({ query: name, limit: 50 });
    this.therapists = therapists;

    const therapistsOptions = therapists.map((therapist) => {
      const option = {
        value: "",
        label: "",
      };

      option.value = therapist.urn;
      option.label = therapist.name;
      
      return option;
    });

    return {
      options: therapistsOptions
    };
  }

  render() {

    return (
      <div className="main-details-row">
        <div className="booking-salon">
          <div className="detail-heading">
            Select Therapist
          </div>
          <div className="detail-value">
            <div className="field-edit">
              <div className="select-box">
                <Select.Async
                  cache={null}
                  value={this.state.therapist}
                  clearable={false}
                  onChange={this.therapistChanged.bind(this)}
                  loadOptions={this.searchTherapist.bind(this)}
                  placeholder="Search by therapist's name"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
