import { config } from "../../config";
import * as queryString from "query-string";
import * as api from "./request";
import { TherapistRegion, TherapistTier } from "../../presenters/therapist";

export interface ApiSalon {
  urn: string;
  name: string;
  commission: string; // change to number at refactor
  isMobile: boolean;
  isElite: boolean;
}

export interface CropDimensions {
  x: number;
  y: number;
  width: number;
  height: number;
  aspect: number;
}

export interface CropDimensionSet {
  percentage: CropDimensions;
  pixels: CropDimensions;
}

export interface TherapistImageData {
  src: string;
  crop: {
    "1x1": CropDimensionSet;
    "1.8x1": CropDimensionSet;
  };
}

export interface TherapistImageDataMap {
  [key: string]: TherapistImageData;
}

export interface ApiTherapist {
  urn: string;
  name?: string;
  slug: string;
  xeroId: string;
  salonUrn: string;
  quote?: string;
  products?: string;
  description?: string;
  email?: string;
  telephone?: string;
  treatmentUrns: string[];
  commission?: number;
  recommended?: boolean;
  isElite: boolean;
  isNotStandardTherapist: boolean;
  images: TherapistImageDataMap;
  ruubyChatUrn: string;
  postcodeUrns: string[];
  internalNotes: string;
  bookingLeadTime: string;
  isAutoSetAvailability: boolean;
  tags: string[];
  rating: number;
  isPartnerTherapist: boolean;
  regions?: TherapistRegion[];
  tier: TherapistTier;
  isActive: boolean;
  isMobile: boolean;
}

export interface ApiSearchQuery {
  query?: string;
  postcodes?: string;
  categories?: string;
  limit?: number;
}

/**
 * Search for therapists
 */
export async function search(query?: ApiSearchQuery): Promise<ApiTherapist[]> {
  let url = `${config.adminPanelApiUrl}/therapists`;

  if (query) {
    url += `?${queryString.stringify(query)}`;
  }

  const response = await api.get<ApiTherapist[]>(url);

  return response.data;
}

/**
 * Fetch single therapist
 * @param therapistUrn URN of therapist to fetch
 */
export async function fetchTherapist(therapistUrn: string): Promise<ApiTherapist> {
  const url = `${config.adminPanelApiUrl}/therapists/${therapistUrn}`;

  const response = await api.get<ApiTherapist>(url);

  return response.data;
}
export async function fetchPartnerTherapists(urns: string[]): Promise<ApiTherapist[]> {
  const url = `${config.adminPanelApiUrl}/therapists/partner`;
  const response = await api.post<ApiTherapist[]>(url, urns);

  return response.data;
}

export enum TherapistUpdateField {
  HAS_SET_AVAILABILITY_REMINDER = "HAS_SET_AVAILABILITY_REMINDER",
  DESCRIPTION = "DESCRIPTION",
  TELEPHONE = "TELEPHONE",
  EMAIL = "EMAIL",
  TREATMENTS = "TREATMENTS",
  IMAGES = "IMAGES",
  IS_RECOMMENDED = "IS_RECOMMENDED",
  NAME = "NAME",
  QUOTE = "QUOTE",
  PRODUCTS = "PRODUCTS",
  COMMISSION = "COMMISSION",
  IS_ELITE = "IS_ELITE",
  IS_NOT_STANDARD = "IS_NOT_STANDARD",
  SLUG = "SLUG",
  XEROID = "XEROID",
  INTERNAL_NOTES = "INTERNAL_NOTES",
  BOOKING_LEAD_TIME = "BOOKING_LEAD_TIME",
  SHOULD_AUTOMATICALLY_SET_AVAILABILITY = "SHOULD_AUTOMATICALLY_SET_AVAILABILITY",
  TAGS = "TAGS",
  IS_RUUBY_PA_ENABLED = "IS_RUUBY_PA_ENABLED",
  MEMBER_ACTIVE = "MEMBER_ACTIVE",
  REGIONS = "REGIONS",
  TIER = "TIER",
  IS_ACTIVE = "IS_ACTIVE",
  IS_MOBILE = "IS_MOBILE",
}

export type TherapistUpdateValue = string | number | boolean | string[];

export async function updateTherapist(urn: string, key: TherapistUpdateField, value: TherapistUpdateValue): Promise<void> {
  const url = `${config.adminPanelApiUrl}/therapists/${urn}`;
  const body = { key, value };

  await api.put(url, body);
}

interface ShortSalonResponse {
  urn: string;
  name: string;
  isMobile: boolean;
}

export async function fetchSalonByTherapist(salonUrn: string): Promise<ShortSalonResponse> {
  const url = `${config.adminPanelApiUrl}/therapists/${salonUrn}/salon`;

  const response = await api.get<ShortSalonResponse>(url);

  return response.data;
}

/**
 * Add a review for therapist
 */
export async function addReview(therapistUrn: string, review: string, rating: number, customer: string): Promise<ApiTherapist> {
  const url = `${config.adminPanelApiUrl}/therapists/${therapistUrn}/reviews`;

  const response = await api.post<ApiTherapist>(url, {
    review, rating, customer
  });

  return response.data;
}

/**
 * Deactivate therapist
 * @param urn - Therapist urn
 */
export async function deactivateTherapist(urn: string): Promise<void> {
  const url = `${config.adminPanelApiUrl}/therapists/${urn}/deactivate`;

  await api.put(url, {});
}

/**
 * Activate therapist
 * @param urn - Therapist urn
 */
export async function activateTherapist(urn: string): Promise<void> {
  const url = `${config.adminPanelApiUrl}/therapists/${urn}/activate`;

  await api.put(url, {});
}