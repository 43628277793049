import * as React from "react";
import * as Select from "react-select";
import styled from "styled-components";
import { ApiPartner } from "../../../api/main/partners";

import { NewFieldLabel } from "../../atoms/field-label";

const SelectStyled = styled(Select)`
  flex-grow: 1;
  text-transform: capitalize;
`;

const PaymentStatusSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

const PaymentStatusSelectWrapper = styled.div`
  width: 200px;
  margin-right: 45px;
`;

interface Props {
  selected: string[];
  partners: ApiPartner[];
  onChange(options: Select.Option[]): void;
}

export const PartnerFilterSelect: React.StatelessComponent<Props> = (props) => {
  const options = props.partners.map((partner) => {
    return {
      label: partner.name,
      value: partner.therapistUrn,
    };
  });

  return (
    <PaymentStatusSelectContainer>
      <PaymentStatusSelectWrapper>
        <NewFieldLabel>Partner</NewFieldLabel>
        <SelectStyled
          value={props.selected}
          clearable={true}
          options={options}
          onChange={(options: Select.Option[]) =>
            props.onChange(options)
          }
          multi={true}
        />
      </PaymentStatusSelectWrapper>
    </PaymentStatusSelectContainer>
  );
};
