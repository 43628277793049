import * as Modal from "react-modal";
import Spinner from "react-spinkit";

import { Dispatch } from "react-redux";
import { useRequestPayment } from "./use-request-payment";
import { NOTIFICATION_TYPES } from "../../../reducers/notifications/types";
import { addNotification } from "../../../reducers/notifications/actions";
import { ApiError } from "../../../api/types/graphql";
import { useEffect } from "react";

export const getRequestPaymentError = (error: ApiError) => {
  switch (error) {
    case ApiError.BookingNoOutstandingAmount:
      return "Booking does not have outstanding amount.";
    case ApiError.BookingIncompatibleStatus:
      return "You cannnot request payment for cancelled bookings.";
    case ApiError.BookingNotFound:
      return "Booking is not found.";
    default:
      return "Something went wrong, please try again.";
  }
};

const modalStyling: Modal.Styles = {
  content: {
    height: "fit-content",
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",
    overflowX: "hidden",
  },
  overlay: {
    zIndex: 3,
  },
};

interface RequestPaymentModalProps {
  bookingUrn: string;
  isOpen: boolean;
  dispatch: Dispatch<{}>;
  closeModal: () => void;
}

export const RequestPaymentModal = ({
  bookingUrn,
  isOpen,
  dispatch,
  closeModal,
}: RequestPaymentModalProps) => {
  const onSuccess = (): void => {
    closeModal();
    dispatch(
      addNotification(
        NOTIFICATION_TYPES.success,
        "Success",
        "Payment requested!",
      ),
    );
  };

  const { error, isLoading, clearError, requestPayment } = useRequestPayment({
    onSuccess,
  });

  useEffect(() => {
    if (!isOpen) {
      clearError();
    }
  }, [isOpen]);

  const handleSubmit = async (): Promise<void> => {
    clearError();

    requestPayment(bookingUrn);
  };

  return (
    <Modal
      isOpen={isOpen}
      style={modalStyling}
      contentLabel="Request Payment"
      onRequestClose={closeModal}
    >
      <div className="new-transaction-modal">
        <div className="row new-transaction-title">
          <div className="fa fa-times" onClick={() => closeModal()}></div>
          <div className="col-md-12">
            <h3>Request Payment</h3>
          </div>
        </div>
        <div className="row new-transaction-row">
          <div className="col-md-12">
            <h5>Send an email to the customer to request payment?</h5>
          </div>
        </div>
        <div className="row new-transaction-row">
          <div className="col-md-12">
            {!isLoading ? (
              <button
                className={`btn btn-submit btn-primary`}
                onClick={handleSubmit}
              >
                Confirm
              </button>
            ) : (
              <Spinner name="pulse" fadeIn="none" />
            )}
          </div>
        </div>
        {error && (
          <div className="row new-transaction-row justify-content-md-center">
            <div className="col-md-12 col-error">
              <p>{`${getRequestPaymentError(error)}`}</p>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
