import * as React from "react";
import styled from "styled-components";
import { CustomerBadge } from "../../../api/main/customer";
import { BadgeWrapper, BadgeProps } from "../badge";

interface Props extends BadgeProps {
    type: CustomerBadge;
}

const NewBadgeWrapper = styled(BadgeWrapper)`
    color: #fff;
    background: #28a745;
`;

const NewBadge: React.StatelessComponent<Props> = props => {
    return <NewBadgeWrapper {...props}>NEW</NewBadgeWrapper>;
};

const HighValueBadgeWrapper = styled(BadgeWrapper)`
    color: #212529;
    background-color: #ffc107;
`

const HighValueBadge: React.StatelessComponent<Props> = props => {
    return <HighValueBadgeWrapper {...props}>TOP £</HighValueBadgeWrapper>;
};

const VipBadgeWrapper = styled(BadgeWrapper)`
    color: #fff;
    background-color: #dc3545;

`
const VipBadge: React.StatelessComponent<Props> = props => {
    return <VipBadgeWrapper {...props}>VIP</VipBadgeWrapper>;
};

const B2bBadgeWrapper = styled(BadgeWrapper)`
    color: white;
    background: #008800;

`
const B2bBadge: React.StatelessComponent<Props> = props => {
    return <B2bBadgeWrapper {...props}>B2B</B2bBadgeWrapper>;
};

const BlackLabelBadgeWrapper = styled(BadgeWrapper)`
    color: white;
    background: #151614;
`
const BlackLabelBadge: React.StatelessComponent<Props> = props => {
    return <BlackLabelBadgeWrapper {...props}>BL</BlackLabelBadgeWrapper>;
};

export const Badge: React.StatelessComponent<Props> = props => {

    switch (props.type) {
        case CustomerBadge.NEW:
            return <NewBadge {...props} />;
        case CustomerBadge.HIGH_VALUE:
            return <HighValueBadge {...props} />;
        case CustomerBadge.VIP:
            return <VipBadge {...props} />;
        case CustomerBadge.B2B:
            return <B2bBadge {...props} />;
        case CustomerBadge.BLACK_LABEL:
            return <BlackLabelBadge {...props} />;

        default:
            throw new Error("Incorrect badge type");
    }

}