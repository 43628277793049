import { ActionType, getType } from "typesafe-actions";
import { actions } from "./actions";
import { ApiReview } from "../../api/main/reviews";
import * as iassign from "immutable-assign";

type ReviewAction = ActionType<typeof actions>;

export interface ReviewState {
  reviews: ApiReview[],
}

const INITIAL_STATE: ReviewState = {
  reviews: []
};

export function reviewReducer(state: ReviewState = INITIAL_STATE, action: ReviewAction): ReviewState {

  switch (action.type) {
    case getType(actions.requestGetAllReviewsSuccess): {
      return iassign(
        state,
        s => {
          s.reviews = action.payload.reviews;
          return s;
        }
      );
    }
    case getType(actions.requestDeleteReviewSuccess): {
      return iassign(state, (s) => {
        s.reviews = state.reviews.filter(
          (r) => r.bookingUrn != action.payload.bookingUrn,
        );
        return s;
      });
    }
    default: return state;
  }
}
