import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { ApiPartner, ApiPartnerSummary } from "../../api/main/partners";
import { RootState } from "../../reducers/index";

var BarChart = require("react-chartjs").Bar;

const PageContainer = styled.div`
    padding: 0 20px 20px 20px;
    float: left; 
    width: 100%; 
`;

const HeaderText2 = styled.div`
    float: left;
`;

const HeaderContainer2 = styled.div`
    background-color: #dedede;
    color: #666666; 
    float: left;
    padding: 10px;
    width: 100%;
`;

const JobsContainer = styled.div`
    background-color: #ffffff; 
    width: 400px; 
    float: left; 
    box-shadow: 1px 2px 4px rgba(0, 0, 0, .2); 
`;

const HeaderContainer1 = styled.div`
    width: 100%; 
    float: left;
    background-color: #396174;
    padding: 10px;
`;

const HeaderText1 = styled.div`
    color: #ffffff;
    float: left;
`;

const PartnerListing = styled.div`
    background-color: #ffffff;
    float: left; 
    width: 100%;
`;

const LabelColumn = styled.div`
    float: left;
    width: 50%;
    text-transform: capitalize;
`;

const ValueColumn = styled.div`
    width: 50%;
    float: left;
`;

const Row = styled.div`
    padding: 10px; 
    border-bottom: 1px solid #dedede;
    float: left; 
    width: 100%;
`;

const AvailabilityChart = styled.div`
  box-shadow: 1px 2px 4px rgb(0 0 0 / 20%); 
  border: 0px solid red;
  float: left;
  width: 100%;
  height: 180px;
  background-color: #ffffff;
  padding: 10px 20px 10px 20px;
`;

interface ReduxProps {
    partner: ApiPartner;
    summary: ApiPartnerSummary;
}

class Component extends React.Component<ReduxProps, {}> {

    render(): JSX.Element {

        const labels = _.range(1, this.props.summary.monthBookingsPerDay.length);

        const datasets = [
            {
                label: 'Availability',
                yAxisID: 'A',
                data: this.props.summary.monthBookingsPerDay.map(x => x.bookings),
            }
        ];

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    id: 'A',
                    type: 'linear',
                    position: 'left',
                }]
            },
        };

        return (
            <div className="home-container">

                <PageContainer>

                    <JobsContainer style={{ width: "60%" }}>
                        <HeaderContainer1>
                            <HeaderText1>
                                Ruuby Admin Panel
                            </HeaderText1>
                        </HeaderContainer1>
                        <HeaderContainer2>
                            <HeaderText2>
                                <span style={{ fontWeight: "bold" }}>Welcome back</span>
                                <span style={{ textTransform: "capitalize" }}> {this.props.partner.name}</span>
                            </HeaderText2>
                        </HeaderContainer2>
                        <PartnerListing>
                            <Row>
                                {this.props.partner.welcomeText}
                            </Row>
                        </PartnerListing>
                    </JobsContainer>

                    <JobsContainer style={{ width: "30%", marginLeft: "20px" }}>
                        <HeaderContainer1>
                            <HeaderText1>
                                Useful information
                            </HeaderText1>
                        </HeaderContainer1>
                        <HeaderContainer2>
                            <HeaderText2>
                                Contact details
                            </HeaderText2>
                        </HeaderContainer2>
                        <PartnerListing>
                            <Row>
                                <LabelColumn>
                                    Contact number
                                </LabelColumn>
                                <ValueColumn>
                                    {this.props.partner.contactNumber}
                                </ValueColumn>
                            </Row>
                            <Row>
                                <LabelColumn>
                                    Contact email
                                </LabelColumn>
                                <ValueColumn>
                                    <a href={`mailto:${this.props.partner.contactEmail}`}>{this.props.partner.contactEmail}</a>
                                </ValueColumn>
                            </Row>
                        </PartnerListing>
                    </JobsContainer>

                    <JobsContainer style={{ width: "60%", marginTop: "20px" }}>
                        <HeaderContainer1>
                            <HeaderText1>
                                Bookings - {moment().format("MMMM YYYY")}
                            </HeaderText1>
                        </HeaderContainer1>

                        <AvailabilityChart>
                            <BarChart data={{ labels, datasets }} options={options} />
                        </AvailabilityChart>
                    </JobsContainer>

                    <JobsContainer style={{ width: "30%", margin: "20px 0 0 20px" }}>
                        <HeaderContainer1>
                            <HeaderText1>
                                Summary
                            </HeaderText1>
                        </HeaderContainer1>
                        <PartnerListing>
                            <Row>
                                <LabelColumn>
                                    This month commission
                                </LabelColumn>
                                <ValueColumn>
                                    &pound;{this.props.summary.monthCommission ? `${this.props.summary.monthCommission.toFixed(2)}` : 0}
                                </ValueColumn>
                            </Row>
                            <Row>
                                <LabelColumn>
                                    This year commission
                                </LabelColumn>
                                <ValueColumn>
                                    &pound;{this.props.summary.yearCommission ? `${this.props.summary.yearCommission.toFixed(2)}` : 0}
                                </ValueColumn>
                            </Row>
                            <Row>
                                <LabelColumn>
                                    Total commission
                                </LabelColumn>
                                <ValueColumn>
                                    &pound;{this.props.summary.totalCommission ? `${this.props.summary.totalCommission.toFixed(2)}` : 0}
                                </ValueColumn>
                            </Row>
                            <Row>
                                <LabelColumn>
                                    Today's bookings
                                </LabelColumn>
                                <ValueColumn>
                                    {this.props.summary.todayBookings}
                                </ValueColumn>
                            </Row>
                            <Row>
                                <LabelColumn>
                                    This month bookings
                                </LabelColumn>
                                <ValueColumn>
                                    {this.props.summary.monthBookings}
                                </ValueColumn>
                            </Row>
                            <Row>
                                <LabelColumn>
                                    Cancelled bookings
                                </LabelColumn>
                                <ValueColumn>
                                    {this.props.summary.cancelledBookings}
                                </ValueColumn>
                            </Row>
                            <Row>
                                <LabelColumn>
                                    Number of customers
                                </LabelColumn>
                                <ValueColumn>
                                    {this.props.summary.customersCount}
                                </ValueColumn>
                            </Row>
                        </PartnerListing> 
                    </JobsContainer>
                </PageContainer>

            </div>
        );
    }
}

const mapStateToProps = (state: RootState): ReduxProps => ({
    partner: state.operator.partner,
    summary: state.operator.summary,
});

export const PartnerHome = connect<ReduxProps, {}, {}>(mapStateToProps)(Component);
