import { useCallback, useState } from "react";
import * as Select from "react-select";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import { Table } from "react-bootstrap";

import { ApiCustomer } from "../../../../api/main/customer";
import {
  CreditType,
  Currency,
  CustomerCreditsQuery,
  useAddCustomerCreditMutation,
  useCustomerCreditsQuery,
} from "../../../../api/types/graphql";

const creditOptions = [
  {
    label: "Referral",
    value: CreditType.Referral,
  },
  {
    label: "Influencer",
    value: CreditType.Influencer,
  },
  {
    label: "Voucher",
    value: CreditType.Voucher,
  },
  {
    label: "Retention",
    value: CreditType.Retention,
  },
  {
    label: "Complaint",
    value: CreditType.Complaint,
  },
  {
    label: "Acquisition",
    value: CreditType.Acquisition,
  },
];

const currencyOptions = [
  {
    label: "GBP",
    value: Currency.Gbp,
  },
  {
    label: "CHF",
    value: Currency.Chf,
  },
];

const creditTypeMap = new Map<CreditType, string>(
  creditOptions.map((item) => [item.value, item.label]),
);

const getCreditTypeDescription = (creditType: CreditType): string => {
  if (creditTypeMap.has(creditType)) {
    return creditTypeMap.get(creditType);
  }

  return creditType ?? "";
};

type Credit = Extract<
  Required<CustomerCreditsQuery["customer"]>,
  { __typename: "Customer" }
>["credits"][0];

type CreditItems = Extract<
  Required<CustomerCreditsQuery["customer"]>,
  { __typename: "Customer" }
>["credits"][0]["credits"][number];

type CreditItemProps = {
  item: CreditItems;
  currency: Currency;
};

const CreditItem: React.FC<CreditItemProps> = ({ item, currency }) => (
  <>
    <tr key={item.urn}>
      <td>{`${currency} ${item.originalAmount}`}</td>
      <td>{`${currency} ${item.remainingAmount}`}</td>
      <td>
        {item.expires ? moment(item.expires).format("DD/MM/YYYY") : "Unlimited"}
      </td>
      <td>{item.voucherUrn ? "From Voucher" : "From Ruuby"}</td>
      <td>{getCreditTypeDescription(item.type)}</td>
      <td>{moment(item.timeCreated).format("DD/MM/YYYY HH:mm")}</td>
    </tr>
  </>
);

interface Props {
  customer: ApiCustomer;
}

export const Credit = ({ customer }: Props) => {
  const [adjustment, setAdjustment] = useState<string>("");
  const [creditType, setCreditType] = useState<string>("");
  const [currency, setCurrency] = useState<Currency>();
  const [expiryDate, setExpiryDate] = useState<moment.Moment | undefined>(
    undefined,
  );

  const [customerCredits, setCustomerCredits] = useState<Credit[]>([]);
  const [error, setError] = useState<undefined | string>(undefined);

  const { refetch, loading } = useCustomerCreditsQuery({
    variables: {
      urn: customer.urn,
    },
    onCompleted: ({ customer: data }) => {
      switch (data.__typename) {
        case "Customer":
          setError(undefined);
          setCustomerCredits(data.credits);
          break;
        case "RuubyGraphError":
          setError("Something went wrong, please try again.");
      }
    },
    onError: () => setError("Something went wrong, please try again."),
    errorPolicy: "all",
    defaultOptions: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    },
  });

  const [addCredit, { loading: isLoading }] = useAddCustomerCreditMutation({
    onCompleted: async ({ addCredit: data }) => {
      switch (data.__typename) {
        case "Credit":
          setError(undefined);
          setAdjustment("");
          setCreditType("");
          setExpiryDate(undefined);
          setCurrency(undefined);
          await refetch();
          break;
        case "RuubyGraphError":
          setError("Something went wrong, please try again.");
      }
    },
    onError: () => setError("Something went wrong, please try again."),
    errorPolicy: "all",
  });

  const handleSetAdjustment = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAdjustment(event.target.value);
    },
    [setAdjustment],
  );

  const handleSetCreditType = useCallback(
    (option: Select.Option) => {
      setCreditType(option.value as string);
    },
    [setAdjustment],
  );

  const handleSetCurrency = useCallback(
    (option: Select.Option) => {
      setCurrency(option.value as Currency);
    },
    [setCurrency],
  );

  const handleSetExpiryDate = useCallback(
    (date: moment.Moment) => {
      setExpiryDate(date);
    },
    [setExpiryDate],
  );

  const handleSubmit = useCallback(async () => {
    if (adjustment && creditType) {
      await addCredit({
        variables: {
          customerUrn: customer.urn,
          amount: Number(adjustment),
          type: creditType as CreditType,
          currency: currency,
          ...(expiryDate && { expires: expiryDate.toISOString() }),
        },
      });
    }
  }, [
    addCredit,
    setAdjustment,
    setCreditType,
    adjustment,
    creditType,
    expiryDate,
    currency,
  ]);

  return (
    <div className="app-table main-details-lg">
      {error && <div className="details-row">{error}</div>}
      <div className="details-row">
        <div className="detail-col">
          <div className="detail-heading">Current Credit Balance</div>
          <div className="detail-value">
            {loading
              ? "Loading..."
              : customerCredits
                  .map((c) => `${c.currency} ${c.balance}`)
                  .join(", ")}
          </div>
        </div>
        <div className="detail-col">
          <div className="detail-heading">Add customer credits</div>
          <div className="detail-value">
            <div className="field-edit">
              <div className="input-box">
                <input
                  type="number"
                  value={adjustment}
                  onChange={handleSetAdjustment}
                  className="form-control input-number-width-50"
                />
                &nbsp;
                <Select
                  value={creditType}
                  options={creditOptions}
                  onChange={handleSetCreditType}
                  clearable={false}
                  placeholder="Reason"
                />
                &nbsp;
                <Select
                  value={currency}
                  options={currencyOptions}
                  onChange={handleSetCurrency}
                  clearable={false}
                  placeholder="Currency"
                />
                <button
                  className="btn btn-submit push-right-5"
                  type="button"
                  onClick={handleSubmit}
                  disabled={
                    !Boolean(adjustment) || !Boolean(creditType) || isLoading
                  }
                >
                  <span
                    className="fa fa-plus text-success"
                    aria-hidden="true"
                  />
                </button>
                <DatePicker
                  selected={expiryDate}
                  placeholderText="Exp. date (optional)"
                  onChange={handleSetExpiryDate}
                  minDate={moment()}
                  dateFormat={"D MMMM YYYY"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {customerCredits.length > 0 && (
        <div className="details-row">
          <Table>
            <colgroup>
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
            </colgroup>

            <thead>
              <tr>
                <th>Original amount</th>
                <th>Remaining amount</th>
                <th>Expiration Date</th>
                <th>Voucher</th>
                <th>Reason</th>
                <th>Created</th>
              </tr>
            </thead>

            <tbody>
              {customerCredits.map((creditArray) =>
                creditArray.credits.map((credit) => (
                  <CreditItem
                    item={credit}
                    currency={creditArray.currency}
                    key={credit.urn}
                  />
                )),
              )}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};
