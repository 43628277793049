export const TRANSACTION_TYPE_ROD_DEDUCTION = "ROD_DEDUCTION";
export const TRANSACTION_ROD_ADJUSTMENT = "ROD_ADJUSTMENT";
export const TRANSACTION_BRAINTREE = "BRAINTREE_TRANSACTION";
export const TRANSACTION_STRIPE = "STRIPE_TRANSACTION";
export const TRANSACTION_REFUND = "BRAINTREE_REFUND";
export const TRANSACTION_PROMO = "PROMO_CODE";
export const TRANSACTION_COMPLIMENTARY = "RUUBY_COMPLIMENTARY";
export const TRANSACTION_BANK_FEES = "BANK_FEES";
export const TRANSACTION_PARTNER_TAKINGS = "PARTNER_TAKINGS";
export const TRANSACTION_THERAPIST_TAKINGS = "THERAPIST_TAKINGS";
export const TRANSACTION_RUUBY_TAKINGS = "RUUBY_TAKINGS";
export const TRANSACTION_EXTERNAL_COMMISSION = "EXTERNAL_COMMISSION";
export const TRANSACTION_CUSTOMER_CREDIT = "CUSTOMER_CREDIT";
export const TRANSACTION_INVOICE = "INVOICE";
export const TRANSACTION_ADMIN_FEE = "ADMIN_FEE";
export const TRANSACTION_BOOKING_TIP = "BOOKING_TIP";

export const REFUND_PENDING = "PENDING";
export const REFUND_COMPLETE = "COMPLETE";
