import * as React from "react";
import * as Select from "react-select";
import {debounce} from "lodash";
import styled from "styled-components";

import { TreatmentPresenter } from "../../../presenters/treatment";
import { TickButton } from "../../atoms/tick-button";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

interface Props {
  treatments: TreatmentPresenter[];
  onSearch(query: string): void;
  onAdd(urns: string[]): Promise<void>;
  isBusy: boolean;
}

interface State {
  selected: Select.Option[];
}

export class SearchableTreatmentSelect extends React.Component<Props, State> {
  state: State = {
   selected: [],
  };

  mapTreatments = (): Select.Option[] => {
    return this.props.treatments.map(t => ({
      label : `${t.name}`,
      value: t.urn,
    }));
  }

  handleOnSearch = debounce((query: string) => this.props.onSearch(query), 1000);

  handleAdd = async () => {
    await this.props.onAdd(this.state.selected.map(o => o.value.toString()));
    this.setState({selected: []});
  }

  render(): JSX.Element {
    return (
      <Container>
        <Select
          options={this.mapTreatments()}
          placeholder="Search for treatments"
          onChange={(selected: Select.Option[]) => this.setState({selected})}
          onInputChange={this.handleOnSearch}
          isLoading={this.props.isBusy}
          value={this.state.selected}
          multi
        />
        {(this.state.selected.length > 0) && <TickButton onClick={this.handleAdd} />}
      </Container>
    );
  }
}