import * as React from "react";
import EditField from "../../sub/edit-field";
import { CustomerUpdateField, CustomerUpdateValue } from "../../../reducers/customers/actions";
import Switch from "react-bootstrap-switch";
import { ApiCustomer } from "../../../api/main/customer";

interface GeneralProps {
  customer: ApiCustomer;
  updateCustomer: (key: CustomerUpdateField, value: CustomerUpdateValue) => void;
}

export default class General extends React.Component<GeneralProps, null> {
  constructor(props: GeneralProps) {
    super(props);

    this.saveHandler = this.saveHandler.bind(this);
  }

  saveHandler(key: CustomerUpdateField, value: CustomerUpdateValue) {
    this.props.updateCustomer(key, value);
  }

  render() {
    return (
      <div className="main-details-lg">
        <div className="details-row">
          <div className="detail-col">
            <div className="detail-heading">
              Email
            </div>
            <div className="detail-value">
              <EditField dbKey={CustomerUpdateField.EMAIL} type="text" value={this.props.customer.email} saveHandler={this.saveHandler} />
            </div>
          </div>
          <div className="detail-col">
            <div className="detail-heading">
              Phone
            </div>
            <div className="detail-value">
              <EditField dbKey={CustomerUpdateField.PHONE} type="text" value={this.props.customer.phone} saveHandler={this.saveHandler} />
            </div>
          </div>
        </div>

        <div className="details-row">
          <div className="detail-col">
            <div className="detail-heading">
              City
            </div>
            <div className="detail-value">
              {this.props.customer.city}
            </div>
          </div>

          <div className="detail-col">
            <div className="detail-heading">
              Personal referral code
            </div>
            <div className="detail-value">
              {this.props.customer.referralCode}
            </div>
          </div>
        </div>

        <div className="details-row">
          <div className="detail-col">
            <div className="booking-notes">
              <div className="detail-heading">
                Notes for therapist
              </div>
              <div className="detail-value">
                <EditField dbKey={CustomerUpdateField.NOTES_THERAPIST} value={this.props.customer.notesTherapist} saveHandler={this.saveHandler} type="textarea" />
              </div>
            </div>
          </div>
        </div>
        <div className="details-row">
          <div className="detail-col">
            <div className="booking-notes">
              <div className="detail-heading">
                Notes for internal use
              </div>
              <div className="detail-value">
                <EditField dbKey={CustomerUpdateField.NOTES_RUUBY} value={this.props.customer.notesRuuby} saveHandler={this.saveHandler} type="textarea" />
              </div>
            </div>
          </div>
        </div>
        <div className="details-row">
          <div className="detail-col">
            <div className="booking-notes">
              <div className="detail-heading">
                Black Label
              </div>
              <div className="detail-value">
                <Switch value={this.props.customer.isBlackLabel}
                  onChange={() => this.saveHandler(CustomerUpdateField.BLACK_LABEL, !this.props.customer.isBlackLabel)}
                  onText="YES"
                  offText="NO"
                  bsSize="small" />
              </div>
            </div>
          </div>
          <div className="detail-col">
            <div className="booking-notes">
              <div className="detail-heading">
                VIP
              </div>
              <div className="detail-value">
                <Switch value={this.props.customer.isVip}
                  onChange={() => this.saveHandler(CustomerUpdateField.VIP, !this.props.customer.isVip)}
                  onText="YES"
                  offText="NO"
                  bsSize="small" />
              </div>
            </div>
          </div>
          <div className="detail-col">
            <div className="booking-notes">
              <div className="detail-heading">
                B2B
              </div>
              <div className="detail-value">
                <Switch value={this.props.customer.isB2b}
                  onChange={() => this.saveHandler(CustomerUpdateField.B2B, !this.props.customer.isB2b)}
                  onText="YES"
                  offText="NO"
                  bsSize="small" />
              </div>
            </div>
          </div>
        </div>
        <div className="details-row">
          <div className="detail-col">
            <div className="booking-notes">
              <div className="detail-heading">
                Blocked
              </div>
              <div className="detail-value">
                <Switch value={this.props.customer.isBlocked}
                  onChange={() => this.saveHandler(CustomerUpdateField.IS_BLOCKED, !this.props.customer.isBlocked)}
                  onText="YES"
                  offText="NO"
                  bsSize="small" />
              </div>
            </div>
          </div>
          <div className="detail-col">
            <div className="booking-notes">
              <div className="detail-heading">
                Is Trusted?  (Skip AVS &amp; CVV)
              </div>
              <div className="detail-value">
                <Switch value={this.props.customer.isTrustedCustomer}
                  onChange={() => this.saveHandler(CustomerUpdateField.IS_TRUSTED_CUSTOMER, !this.props.customer.isTrustedCustomer)}
                  onText="YES"
                  offText="NO"
                  bsSize="small" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
