import * as moment from "moment";
import * as constants from "../constants";

import { ApiBooking } from "../api/main/booking";

export const hasMethods = (transactions: any[]): boolean => {
  return transactions.reduce((bool: boolean, transaction) => bool && (transaction.method || transaction.type === constants.TRANSACTION_COMPLIMENTARY), true);
};

export function mapStatus(status: string): string {
  switch (status) {
    case "THERAPIST_ON_WAY":
      return "ON WAY TO CLIENT";
    case "IN_PROGRESS":
      return "CHECKED IN";
    default:
      return status;
  }
}

export function nameStamp(urn: string): string {
  const tokens = urn.split(":");

  switch (tokens[2]) {
    case "customer":
      return "Customer";

    case "therapist":
      return "Therapist";

    case "operator":
      return tokens[3];

    default:
      return urn;
  }
}

export function timeStamp(time: string): string {
  return `${moment(time).format("DD MMM YYYY")} @ ${moment(time).format("HH:mm")}`;
}

interface BookingTotals {
  totalToPartner?: number;
}

export function getB2BTotals(booking: ApiBooking): BookingTotals {
  return {
    totalToPartner: booking.transactions.filter(
      t => t.type === constants.TRANSACTION_PARTNER_TAKINGS
    ).reduce((amount: number, t) => amount + t.amount, 0),
  }
}