import * as React from "react";
import {debounce} from "lodash";

import { SearchInput } from "../search-input";

interface Props {
  onChange(query: string): void;
  value?: string;
}

interface State {
  value: string;
}

export class DebouncedSearchInput extends React.Component<Props, State> {
  input: SearchInput;
  state: State = {
    value: "",
  };

  componentDidMount(): void {
    if (typeof this.props.value !== "undefined") {
      this.setState({...this.state, value: this.props.value});
    }

    this.input.focus();
  }

  clear = (): void => {
    this.setState({...this.state, value: ""});
  }

  handleOnSearch = debounce(() => this.props.onChange(this.state.value), 600);

  handleChange = (query: string): void => {
    // set new value in state, and when done call onSearch method
    this.setState({...this.state, value: query}, () => {
      this.handleOnSearch();
    });
  }

  render(): JSX.Element {
    return (
      <SearchInput query={this.state.value} onChange={this.handleChange} ref={ref => this.input = ref} />
    );
  }
}
