import * as api from "../../api/main/availability";
import { NOTIFICATION_TYPES } from "../../reducers/notifications/types";
import { addNotification } from "../../reducers/notifications/actions";
import { ActionDispatch } from "../../reducers";
import { begin, failure, success } from "redux-api-status/actions";
import { ApiTimeRanges } from "../../api/main/availability";


export const setBulkAvailabilityRef = `THERAPISTS/SET_BULK_AVAILABILITY`;

/**
 * Bulk set availability for given therapist
 * @param therapistUrn Therapist to add availability for
 * @param timeRange Array of booleans matching times in config
 */
export const setBulkAvailability = (
  therapistUrn: string,
  timeRange: ApiTimeRanges,
): ActionDispatch<boolean> => {
  return async dispatch => {
    try {

      dispatch(begin(setBulkAvailabilityRef));

      // set availability
      await api.bulkSetAvailability(therapistUrn, timeRange);

      dispatch(success(setBulkAvailabilityRef));

      // show notification
      dispatch(addNotification(NOTIFICATION_TYPES.success, "Updated!", "Availability updated"));

      return true;
    } catch (error) {
      dispatch(failure(setBulkAvailabilityRef, error));

      // show error notification
      dispatch(addNotification(NOTIFICATION_TYPES.danger, "Error Updating Availability", "An error occurred. Please try again."));

      return false;
    }
  };
};