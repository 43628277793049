import * as React from "react";

interface NotesEditFieldProps {
  resource: any;
  onUpdateNotes: (notes: string) => void;
}
interface NotesEditFieldStatus {
  editing?: boolean;
  notes?: string;
}

export default class NotesEditField extends React.Component<NotesEditFieldProps, NotesEditFieldStatus> {
  constructor(props: NotesEditFieldProps) {
    super(props);
    this.state = {
      editing: false,
      notes: props.resource.notes || props.resource.comment
    };
  }

  editCancelHandler() {
    this.setState({editing: false});
  }

  onEditStartHandler() {
    this.setState({editing: true});
  }

  editSaveHandler() {
    this.props.onUpdateNotes(this.state.notes);
    this.editCancelHandler();
  }

  handleNotesChange(event: any) {
    this.setState({notes: event.target.value});
  }

  render() {
    return (this.state.editing) ? (
      <div className="field-edit">
        <div className="input-box">
          <textarea rows={4} onBlur={this.handleNotesChange.bind(this)} className="form-control">
            {this.state.notes}
          </textarea>
          <button className="btn btn-submit" type="button" onClick={this.editSaveHandler.bind(this)}>
            <span className="fa fa-check text-success" aria-hidden="true" />
          </button>
          <button className="btn btn-cancel" type="submit" onClick={this.editCancelHandler.bind(this)}>
            <span className="fa fa-times" aria-hidden="true" />
          </button>
        </div>
      </div>
    ) : (
      <div className="field-edit">
        {this.state.notes}
        <span onClick={this.onEditStartHandler.bind(this)} className="edit-icon edit-icon-hover fa fa-pencil"></span>
      </div>
    );
  }
}
