import * as React from "react";
import DatePicker from "react-datepicker";
import * as moment from "moment";
import styled from "styled-components";

require("react-datepicker/dist/react-datepicker.css");

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  input.date {
    border: 0;
    width: 100px;
    color: #337ab7;
    text-align: center;
    margin: 0 5px;
  }

  input.date:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

interface Props {
  startDate: moment.Moment;
  endDate: moment.Moment;
  onStartDateChange: (date: moment.Moment) => any;
  onEndDateChange: (date: moment.Moment) => any;
}

export const DateRangePicker: React.SFC<Props> = (props: Props) => (
  <Container>
    <span className="fa fa-calendar"></span>
    <DatePicker
      dateFormat={"DD/MM/YYYY"}
      selected={props.startDate}
      selectsStart
      startDate={props.startDate}
      endDate={props.endDate}
      onChange={ (date: moment.Moment) => props.onStartDateChange(date.startOf("day")) }
      className="date"
    />
    <span className="form-label">-</span>
    <DatePicker
      dateFormat={"DD/MM/YYYY"}
      selected={props.endDate}
      selectsEnd
      startDate={props.startDate}
      endDate={props.endDate}
      onChange={ (date: moment.Moment) => props.onEndDateChange(date.endOf("day")) }
      className="date"
    />
  </Container>
);
