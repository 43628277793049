import * as Immutable from "immutable";
import * as types from "./types";
import { Action } from "../login/reducer";

const initialEditState = Immutable.Map({
  isEditing: false
});

const editState = (state = initialEditState, action: Action) => {
  switch (action.type) {
    case types.EDIT_IN_PROGRESS:
      return state.merge({ isEditing: true });
    case types.EDIT_COMPLETE:
      return state.merge({ isEditing: false });
    default:
      return state;
  }
};

export default editState;
