import * as React from "react";
import * as Modal from "react-modal";
import styled from "styled-components";

const modalStyling: Modal.Styles = {
    content: {
        height: "fit-content",
        width: "50%",
        top: "50%",
        left: "50%",
        transform: "translateY(-50%) translateX(-50%)",
    },
    overlay: {
        zIndex: 3
    }
};

const Heading = styled.div`
    background-color: #396174;
    color: #ffffff;
    padding: 0px;
    margin: 0px;
`;

const ConfirmButton = styled.button`
    background-color: #00AF6C;
    border-radius: 0px; 
    padding: 10px;
    color: #ffffff;
    width: 100px; 
    border: 0px;
`;

const CancelButton = styled.button`
    background-color: #cc0000;
    border-radius: 0px; 
    padding: 10px;
    color: #ffffff;
    width: 100px; 
    border: 0px;
`;

interface Props {
    isOpen: boolean;
    title: string;
    message: string;
    confirmEvent: {
        event(): void,
        text: string
    };
    cancelEvent: {
        event(): void,
        text: string
    };
}

export const TableModal: React.SFC<Props> = ({
    title,
    message,
    isOpen,
    confirmEvent,
    cancelEvent,
}) => (
        <Modal
            isOpen={isOpen}
            style={modalStyling}
            contentLabel={title}
            onRequestClose={cancelEvent.event}
            ariaHideApp={false}>

            <div className="alert-modal" style={{ padding: "0px" }}>

                <div className="row alert-modal-info" style={{ margin: "0px" }}>
                    <Heading className="col-md-12">
                        <h3 style={{ margin: "0px", padding: "10px" }}>
                            {title}
                        </h3>
                    </Heading>
                </div>

                <div className="row alert-modal-info" style={{ margin: "10px 0 0 0" }}>

                    <div className="col-md-12">
                        <p>{message}</p>
                    </div>
                </div>

                <div className="row btn-row" style={{ padding: "0px", margin: "0 0 20px 0" }}>
                    <div className="col-md-6 confirm">
                        <ConfirmButton
                            onClick={() => confirmEvent.event()}>{confirmEvent.text}
                        </ConfirmButton>
                    </div>
                    <div className="col-md-6 cancel">
                        <CancelButton
                            onClick={() => cancelEvent.event()}>{cancelEvent.text}
                        </CancelButton>
                    </div>
                </div>

            </div>
        </Modal>
    );
