import * as React from "react";
import * as Select from "react-select";
import styled from "styled-components";

import * as utils from "../../../utils";

const SelectStyled = styled(Select)`
  flex-grow: 1;
`;

interface Props {
  selected: string;
  onChange(option: string): void;
}

const OPTIONS = ["ALL", "COMPLETED", "UNCONFIRMED", "CONFIRMED", "CANCELLED", "IN_PROGRESS", "THERAPIST_ON_WAY"]
  .map(s => ({
    label: utils.mapStatus(s),
    value: s
  }));;

export const  StatusSelect: React.StatelessComponent<Props> = props => {
  return (
    <SelectStyled
      value={props.selected}
      clearable={false}
      options={OPTIONS}
      onChange={(option: Select.Option) => props.onChange(option.value as string)}
    />
  );
};
