import { config } from "../../config";
import * as api from "./request";

export interface MonthBookingsPerDay {
  date: string;
  bookings: number;
}

export interface PartnerTherapist {
  urn: string;
  hideFromPlatform: boolean;
};

export interface ApiPartner {
  id?: string;
  name: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  postcode: string;
  slug?: string;
  tier: string;
  dateCreated?: string;
  therapists?: PartnerTherapist[];
  loginEnabled: boolean;
  username: string;
  password?: string;
  therapistUrn?: string;
  bookingsEnabled: boolean;
  therapistsEnabled: boolean;
  treatmentsEnabled: boolean;
  customersEnabled: boolean;
  contactName: string;
  telephone: string;
  contactNumber: string;
  contactEmail: string;
  welcomeText: string;
  logoURL: string;
  emails: {
    partner: {
      address: string,
      enabled: boolean
    },
    customer: {
      address: string,
      enabled: boolean
    },
    ruuby: {
      address: string,
      enabled: boolean
    },
  },
  isActive: boolean;
  commissions: {
    partner: number;
    therapist: number;
    ruuby: number;
  };  
}

export interface ApiPartnerSummary {
  todayBookings: number;
  monthBookings: number;
  yearBookings: number;
  cancelledBookings: number;
  customersCount: number;
  monthCommission: number;
  yearCommission: number;
  totalCommission: number;
  monthBookingsPerDay: MonthBookingsPerDay[];
}

export interface ApiPartnerTherapists {
  id: string;
  therapists?: PartnerTherapist[];
}

const baseUrl = `${config.adminPanelApiUrl}/partners/`;

export async function fetchAll(): Promise<ApiPartner[]> {
  const response = await api.get<{ partners: ApiPartner[] }>(baseUrl);
  return response.data.partners;
}

export async function createPartner(partner: ApiPartner): Promise<ApiPartner> {
  const response = await api.post<{ partner: ApiPartner }>(baseUrl, partner);

  return response.data.partner;
}

export async function editPartner(id: string, partner: ApiPartner): Promise<ApiPartner> {
  const response = await api.put(`${baseUrl}${id}`, partner);

  return response.data.partner;
}

export async function setTherapists(partner: ApiPartnerTherapists): Promise<ApiPartner> {
  const url = `${baseUrl}set-therapists`;
  const response = await api.post<{ partner: ApiPartner }>(url, partner);

  return response.data.partner;
}

export async function fetch(id: string): Promise<ApiPartner> {
  const response = await api.get<{ partner: ApiPartner }>(`${baseUrl}${id}`);
  return response.data.partner;
}
