import { createStandardAction } from "typesafe-actions";

import { ActionDispatch } from "../../";
import * as categoryApi from "../../../api/main/category";

interface RequestSuccessPayload {
  categories: categoryApi.ApiCategory[];
}

export const actions = {
  requestInit: createStandardAction("categories/REQUEST_INIT")<void>(),
  requestSuccess: createStandardAction("categories/REQUEST_SUCCESS")<RequestSuccessPayload>(),
};

/**
 * Fetch all categories
 */
export function fetchAllCategories(): ActionDispatch {
  return async dispatch => {
    dispatch(actions.requestInit());

    const categories = await categoryApi.fetchAll();

    dispatch(actions.requestSuccess({ categories }));
  };
}
