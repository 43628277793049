import * as React from "react";

interface Props {
  onCreate(comment: string): void;
}
interface State {
  draft: string;
  creating: boolean;
}

export class CreateNote extends React.Component<Props, State> {
  state = {
    creating: false,
    draft: ""
  };

  handleDraftUpdate = (evt: any): void => {
    this.setState({...this.state, draft: evt.target.value});
  }

  handleDraftSubmit = (): void => {
    this.props.onCreate(this.state.draft);
    this.cancelCreating();
  }

  cancelCreating = (): void => {
    this.setState({draft: "", creating: false});
  }

  startCreating = (): void => {
    this.setState({draft: "", creating: true});
  }

  render(): JSX.Element {
    return (
      <div className="note-create">
        {this.state.creating &&
          <div className="note-textarea">
            <textarea name="draft" onChange={this.handleDraftUpdate} cols={30} rows={5} value={this.state.draft}></textarea>

            <button className="btn btn-success" disabled={this.state.draft.length === 0} onClick={this.handleDraftSubmit}>Add</button>
            <button className="btn btn-danger" onClick={this.cancelCreating}>Cancel</button>
          </div>
        }
        {!this.state.creating &&
          <button className="btn btn-outline-secondary btn-primary" onClick={this.startCreating}><i className="fa fa-comment-o" aria-hidden="true"></i> Comment</button>
        }
      </div>
    );
  }
}
