import * as Immutable from "seamless-immutable";
import {actionCreators} from "./actions";
import * as types from "./types";
import { ActivityLog } from "../../api/main/audit";

type Action = ReturnType<typeof actionCreators[keyof typeof actionCreators]>;

export type State = {
  readonly isLoading: boolean;
  readonly logs: ActivityLog[];
};

const INITIAL_STATE = Immutable.from<State>({
  isLoading: false,
  logs: []
});

export function reducer(state: Immutable.ImmutableObject<State> = INITIAL_STATE, action: Action): Immutable.ImmutableObject<State> {
  switch (action.type) {
    case types.FETCH_LOGS_ATTEMPT:
      return state.set("isLoading", true);

    case types.FETCH_LOGS_SUCCESS:
      return state.set("logs", action.payload.logs)
        .set("isLoading", false);

    case types.CLEAR_LOGS:
      return INITIAL_STATE;

    default:
      return state;
  }
};
