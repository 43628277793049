import iassign from "immutable-assign";

import {actionCreators, TrelloCard} from "./actions";
import * as types from "./types";

type Action = ReturnType<typeof actionCreators[keyof typeof actionCreators]>;

export type TrelloState = {
  hasErroredLoading: boolean;
  isLoading: boolean;
  isAuthorised: boolean;
  cards?: TrelloCard[];
};

const INITIAL_STATE: TrelloState = {
  hasErroredLoading: false,
  isLoading: false,
  isAuthorised: false,
  cards: undefined,
};

export function trelloReducer(state: TrelloState = INITIAL_STATE, action: Action): TrelloState {
  switch (action.type) {
    case types.SET_AUTHORISED: {
      return iassign(
        state,
        s => {
          s.isAuthorised = action.payload.isAuthorised;

          return s;
        }
      );
    }

    case types.FETCH_ATTEMPT: {
      return iassign(
        state,
        s => {
          s.isLoading = true;

          return s;
        }
      );
    }

    case types.FETCH_SUCCESS: {
      return iassign(
        state,
        s => {
          s.isLoading = false;
          s.hasErroredLoading = false;
          s.cards = action.payload.cards;

          return s;
        }
      );
    }

    case types.FETCH_FAILED: {
      return iassign(
        state,
        s => {
          s.isLoading = false;
          s.hasErroredLoading = true;

          return s;
        }
      );
    }

    default: return state;
  }
}
