import {AxiosResponse} from "axios";
import {config} from "../../config";
import * as api from "./request";

interface BookingDigest {
  timeStarts?: string;
  timeEnds?: string;
  customerName?: string;
  salonName?: string;
  address?: string;
  status?: string;
  notes?: string;
  bookingTreatments?: string[];
  changedBookingTreatments?: string[];
  addedTreatments?: string | string[];
  removedTreatments?: string | string[];
  updatedTreatments?: string | string[];
  timeCreated?: string;
  origin?: string;
}
export interface ActivityLog {
  urn: string;
  updatedByUrn: string;
  digest: BookingDigest;
  time: string;
}


export async function fetchLogs(bookingUrn: string): Promise<ActivityLog[]> {
  const url = `${config.adminPanelApiUrl}/bookings/${bookingUrn}/audit`;
  const response: AxiosResponse = await api.get(url);

  return response.data;
}
