import * as React from "react";
import * as Select from "react-select";
import { Link } from "react-router-dom";

import * as therapistApi from "../../api/main/therapist";

interface SalonProps {
  onUpdateSalon: (urn: string) => void;
  salon: any;
  therapistUrn: string;
  readOnly?: boolean;
}

interface SalonState {
  selectedSalon?: {
    value: string;
    label: string;
  };
  isIconShowing?: boolean;
  isEditing?: boolean;
}

export default class Salon extends React.Component<SalonProps, SalonState> {
  constructor(props: SalonProps) {
    super(props);

    this.state = {
      selectedSalon: {
        value: this.props.salon.urn,
        label: this.props.salon.name,
      },
      isIconShowing: false,
      isEditing: false
    };

    this.salonChanged = this.salonChanged.bind(this);
    this.onEditCancelHandler = this.onEditCancelHandler.bind(this);
    this.onEditSaveHandler = this.onEditSaveHandler.bind(this);
    this.isOpen = this.isOpen.bind(this);
    this.onEditStartHandler = this.onEditStartHandler.bind(this);
    this.onMouseEnterField = this.onMouseEnterField.bind(this);
    this.onMouseLeaveField = this.onMouseLeaveField.bind(this);
  }

  async searchTherapist(name: string) {
    const therapists = await therapistApi.search({query: name, limit: 50});

    const therapistsOptions = therapists.map((therapist: any) => {
      therapist.value = therapist.salonUrn;
      therapist.label = therapist.name;
      
      return therapist;
    });

    return {
      options: therapistsOptions
    };
  }

  onEditSaveHandler() {
    this.props.onUpdateSalon(this.state.selectedSalon.value);
    this.onEditCancelHandler();
  }

  onEditCancelHandler() {
    this.setState({
      selectedSalon: {
        value: this.props.salon.urn,
        label: this.props.salon.name,
      },
      isEditing: false
    });
  }

  onEditStartHandler() {
    this.setState({
      isEditing: true
    });
  }

  isOpen() {
    return this.state.isEditing;
  }

  salonChanged(option: any) {
    this.setState({selectedSalon: option});
  }

  onMouseEnterField() {
    this.setState({isIconShowing: true});
  }

  onMouseLeaveField() {
    this.setState({isIconShowing: false});
  }

  render() {
    const {therapistUrn, salon} = this.props;

    if (this.isOpen()) {
      return (
        <div className="field-edit">
          <div className="select-box">
              <Select.Async
                name={"salon-name"}
                cache={null}
                value={this.state.selectedSalon}
                clearable={false}
                onChange={this.salonChanged}
                loadOptions={this.searchTherapist.bind(this)}
                placeholder="Search by therapist's name"
              />
          </div>
          <button className="btn btn-submit" type="button" onClick={this.onEditSaveHandler}>
            <span className="fa fa-check text-success" aria-hidden="true" />
          </button>
          <button className="btn btn-cancel" type="submit" onClick={this.onEditCancelHandler}>
            <span className="fa fa-times" aria-hidden="true" />
          </button>
        </div>
      );
    }

    const editIcon = this.state.isIconShowing ?
      (
        <span onClick={this.onEditStartHandler} className="edit-icon fa fa-pencil"></span>
      ) : null;

    return (
      <div onMouseEnter={this.onMouseEnterField} onMouseLeave={this.onMouseLeaveField} className="field-edit">
        <Link to={`/therapists/${therapistUrn}`}>{salon.name}</Link>
        {!this.props.readOnly ? editIcon : null}
      </div>
    );
  }
}
