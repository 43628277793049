import _  from "lodash";
import { config } from "../../../config";
import * as api from "../request";

export interface ApiRegion {
  id?: string;
  name: string;
  classicCommission: number;
  eliteCommission: number;
  rodSurcharge: number;
  b2bCommission: number;
  isActive: boolean;
  dateCreated?: string;
}

const baseUrl = `${config.adminPanelApiUrl}/regions/`;

export async function fetchAll(showActiveOnly: boolean): Promise<ApiRegion[]> {
  const response = await api.get<{ regions: ApiRegion[] }>(`${baseUrl}?showActiveOnly=${showActiveOnly}`);
  return _.orderBy(response.data.regions, (o) => { return o.name; }, ["asc"]);
}


export async function createRegion(region: ApiRegion): Promise<ApiRegion> {
  const response = await api.post<{ region: ApiRegion }>(baseUrl, region);

  return response.data.region;
}

export async function editRegion(id: string, region: ApiRegion): Promise<ApiRegion> {
  const url = `${baseUrl}${id}`;
  const response = await api.put(url, region);

  return response.data.region;
}