import { useState } from "react";

import {
  ApiError,
  ChargeBookingAdditionalPaymentMutationVariables,
  useChargeBookingAdditionalPaymentMutation,
} from "../../../../api/types/graphql";

type UseCreateTransaction = (params: { onSuccess(): void }) => {
  error: ApiError;
  isLoading: boolean;
  createTransaction(options: ChargeBookingAdditionalPaymentMutationVariables): void;
};

export const useCreateTransaction: UseCreateTransaction = ({ onSuccess }) => {
  const [error, setError] = useState<ApiError>();

  const [createTransaction, { loading }] = useChargeBookingAdditionalPaymentMutation({
    onCompleted: ({ chargeBookingAdditionalPayment: data }) => {
      switch (data.__typename) {
        case "BookingPaymentConfirmation":
          setError(undefined);
          onSuccess();
          break;
        case "RuubyGraphError":
          setError(ApiError.GeneralError);
      }
    },
    onError: () => setError(ApiError.GeneralError),
    errorPolicy: "all",
  });

  const submitTransaction = async (options: ChargeBookingAdditionalPaymentMutationVariables) => {
    setError(undefined);
    await createTransaction({ variables: options });
  };

  return {
    error,
    isLoading: loading,
    createTransaction: submitTransaction,
  };
};
