import * as React from "react";
import styled from "styled-components";
import { DataTableCell } from "../../atoms/data-table-cell";
import { DataTableDataItem } from "..";

const RowNoHover = styled.div`
    background-color: #ffffff;
    padding: 10px; 
    border-bottom: 1px solid #dedede;
    float: left; 
    width: 100%;
`;

const Row = styled(RowNoHover)`
    &:hover {
      background: #efefef;
    }
`;

const Cell = styled.div`
    float: left; 
    width: 100%;
`;

interface Props {
  data: DataTableDataItem[];
  editHandler(urn: string): void;
  deleteHandler(urn: string): void;
}

export const DataTableThreeColumnData: React.StatelessComponent<Props> = props => {

  const tableData = props.data.map((item, key) => {
    const value = Number(item.value) < 0 ? `(£${item.value.replace("-", "")})` : `£${item.value}`;

    return (
      <>
        <Row key={key}>
          <DataTableCell width="40%" text={item.name} />

          <DataTableCell width="30%" text={value} />

          <Cell style={{ float: "right", width: "30%" }}>
            {/* <a href="#"
              onClick={() => props.editHandler(item.urn)}
              style={{
                textDecoration: "underline",
                color: "#396174"
              }}>
              Edit
            </a>
            &nbsp;|&nbsp; */}
            <a href="#"
              onClick={() => props.deleteHandler(item.urn)}
              style={{
                textDecoration: "underline",
                color: "#396174"
              }}>
              Delete
            </a>
          </Cell>
        </Row>
      </>
    );
  });
  return (
    <>
      {tableData}
    </>
  )
}
