import { createStandardAction } from "typesafe-actions";

import { ActionDispatch } from "../index";
import * as notificationsApi from "../../api/main/notifications";
import { ApiMarketingMessage } from "../../api/main/notifications";

interface FetchMarketingMessageSuccess {
  message: ApiMarketingMessage;
}

interface MarketingMessageApiError {
  error: Error;
}

export const actionCreators = {
  fetchMarketingMessageInit: createStandardAction("notifications/FETCH_MARKETING_MESSAGE_INIT")<void>(),
  fetchMarketingMessageSuccess: createStandardAction("notifications/FETCH_MARKETING_MESSAGE_SUCCESS")<FetchMarketingMessageSuccess>(),
  fetchMarketingMessageError: createStandardAction("notifications/FETCH_MARKETING_MESSAGE_ERROR")<MarketingMessageApiError>(),
  updateMarketingMessageInit: createStandardAction("notifications/UPDATE_MARKETING_MESSAGE_INIT")<void>(),
  updateMarketingMessageSuccess: createStandardAction("notifications/UPDATE_MARKETING_MESSAGE_SUCCESS")<void>(),
  updateMarketingMessageError: createStandardAction("notifications/UPDATE_MARKETING_MESSAGE_ERROR")<MarketingMessageApiError>(),
  startEditingMessageField: createStandardAction("notifications/START_EDITING_MESSAGE_FIELD")<void>(),
  finishEditingMessageField: createStandardAction("notifications/FINISH_EDITING_MESSAGE_FIELD")<void>(),
};

/**
 * Fetch marketing message
 */
export function fetchMarketingMessage(): ActionDispatch {
  return async dispatch => {
    dispatch(actionCreators.fetchMarketingMessageInit());

    try {
      const message = await notificationsApi.fetchMarketingMessage();

      dispatch(actionCreators.fetchMarketingMessageSuccess({message}));
    } catch (error) {
      dispatch(actionCreators.fetchMarketingMessageError({error}));
    }
  };
}

/**
 * Update marketing message
 * @param message The marketing message payload
 */
export function updateMarketingMessage(message: ApiMarketingMessage): ActionDispatch {
  return async dispatch => {
    dispatch(actionCreators.updateMarketingMessageInit());

    try {
      await notificationsApi.updateMarketingMessage(message);

      dispatch(actionCreators.updateMarketingMessageSuccess());
    } catch (error) {
      dispatch(actionCreators.updateMarketingMessageError({error}));
    }
  };
}
