import * as React from "react";
import { ApiTherapist } from "../../api/main/therapist";
import { Dispatch } from "redux";
import { BusyState } from "../../reducers/therapist/reducer";
import { TherapistImages } from "./therapist-images";

interface Props {
  therapist: ApiTherapist;
  dispatch: Dispatch<{}>;
  busy: BusyState;
  isBusy: boolean;
  isPartner: boolean;
}

export class TherapistImagesComponent extends React.Component<Props, null> {
  render(): JSX.Element {
    const { therapist, dispatch, isBusy, isPartner, busy } = this.props;

    const therapistName = isPartner ? therapist.name.split(' ')[0] : therapist.name;

    return (
      <div>
        <h1>{therapistName}</h1>

        <div className="app-details-row">
          <div className="main-details">
            <div className="main-details-row">
              <div className="therapist-imgs">
                <div className="customer-details-row">
                  <div className="detail-heading pull-left">
                    Images
                  </div>
                </div>
                <div className="customer-details-row">
                  <TherapistImages
                    therapist={therapist}
                    isBusy={isBusy}
                    isPartner={isPartner}
                    busy={busy}
                    dispatch={dispatch} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
