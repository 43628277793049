import { AxiosResponse } from "axios";

import { config } from "../../config";
import * as api from "./request";
import { ApiAddress } from "./booking";

const urlBase = `${config.adminPanelApiUrl}/fraudulent-address`;

export async function fetch(): Promise<ApiAddress[]> {

  const res = await api.get<{ addresses: ApiAddress[] }>(urlBase);
  return res.data.addresses;
}

export async function createFraudulentAddress(address: ApiAddress): Promise<ApiAddress> {

  const res: AxiosResponse = await api.put(urlBase, address);
  return res.data.bookingStatement;
}

export async function deleteFraudulentAddress(urn: string): Promise<void> {

  const url = `${urlBase}/${urn}`;
  await api.deletion(url);
}
