import * as React from "react";
import {Table} from "react-bootstrap";
import {sprintf} from "sprintf-js";

import {TRANSACTION_TYPE_ROD_DEDUCTION} from "../../../constants";

interface Props {
  addAdjustment: (type: string, amount: number) => void;
  deleteAdjustmentByUrn: (adjustmentUrn: string) => void;
  deleteAdjustmentByIndex: (index: number) => void;
  adjustments: any[];
  bookingUrn: string;
}

interface State {
  isAddingOpen?: boolean;
  addingAmount?: string;
}

export class Adjustments extends React.Component<Props, State> {
  state: State = {
    isAddingOpen: false,
    addingAmount: "",
  };

  handleAddAdjustment = (): void => {
    this.props.addAdjustment(TRANSACTION_TYPE_ROD_DEDUCTION, +this.state.addingAmount);
    this.clearAdding();
  }

  /*
   * We need this because this component is called in two places, one where the adjustment doesn't
   * yet exist and one after it's been created. We need to do different things to delete an adjustment
   * depending on whether it exists (has a URN/is in the DB) or not (is just in the client store)
   */
  handleDeleteAdjustment = (adjustmentUrn: string, index: number): void => {
    if (typeof adjustmentUrn !== 'undefined') {
      this.props.deleteAdjustmentByUrn(adjustmentUrn);
    }
    else {
      this.props.deleteAdjustmentByIndex(index);
    }
  }

  openAdding = (): void => {
    this.setState({
      isAddingOpen: true,
      addingAmount: "-10.00",
    });
  }

  clearAdding = (): void => {
    this.setState({
      isAddingOpen: false,
      addingAmount: "",
    });
  }

  renderAddingRow = (): JSX.Element => {
    return (
      <tr className="adjustments-row">
        <td>
          {getDisplayType(TRANSACTION_TYPE_ROD_DEDUCTION)}
        </td>
        <td></td>
        <td className="amount">
          <div className="input-group">
            <span className="input-group-addon">£</span>
            <input type="number" className="adjustment-amount" value={this.state.addingAmount} onChange={event => this.setState({addingAmount: (event.target as HTMLInputElement).value}) } />
          </div>
        </td>
        <td className="controls promo-controls">
          <button className="button-submit" type="button" onClick={this.handleAddAdjustment}>
            <span className="fa fa-check" aria-hidden="true" />
          </button>
          <button className="button-cancel" type="button" onClick={this.clearAdding}>
            <span className="fa fa-times" aria-hidden="true" />
          </button>
        </td>
      </tr>
    );
  }

  renderRows = () => {
    const {adjustments} = this.props;

    return adjustments.map((adjustment, index) => (
      <tr key={index} className="adjustments-row">
        <td>
          {getDisplayType(adjustment.type)}
        </td>
        <td></td>
        <td>
          {sprintf("£%0.2f", adjustment.amount)}
        </td>
        <td className="controls">
          <button onClick={() => this.handleDeleteAdjustment(adjustment.urn, index)} className="button-delete" type="button" >
            <span className="fa fa-trash" aria-hidden="true" />
          </button>
        </td>
      </tr>
    ));
  }

  render() {
    const noAdjustments = (
      <div className="no-adjustments">
        No adjustments for this booking
      </div>
    );

    const isAdjustmentAdded = this.props.adjustments.length > 0;

    return (
      <div className="adjustments">
        <div className="adjustments-header">
          <h3>Adjustments</h3>

          {!this.state.isAddingOpen && <button className="new-adjustment btn btn-primary"
            onClick={this.openAdding} disabled={isAdjustmentAdded}><i className="fa fa-plus"></i> Add adjustment</button>}
        </div>

        <Table>
          <colgroup>
            <col width="40%" />
            <col width="40%" />
            <col width="15%" />
            <col width="5%" />
          </colgroup>

          <thead>
            <tr>
              <th>Type</th>
              <th></th>
              <th>Amount</th>
              <th>&nbsp;</th>
            </tr>
          </thead>

          <tbody>
            {this.state.isAddingOpen && this.renderAddingRow()}
            {this.renderRows()}
          </tbody>
        </Table>

        {this.props.adjustments.length === 0 && !this.state.isAddingOpen && noAdjustments}
      </div>
    );
  }
}

function getDisplayType (type: string): string {
  switch (type) {
    case TRANSACTION_TYPE_ROD_DEDUCTION:
      return "Ruuby on Demand fee";
  }
}
