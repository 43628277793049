import * as React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import * as Spinner from "react-spinkit";
import { withRouter, RouteComponentProps } from "react-router-dom";
import * as Select from "react-select";
import { SelectOption } from "../../components/booking/promotions";

import { TherapistSubMenu } from "../../components/sub/sub-menu";
import { RootState } from "../../reducers";
import { ApiTherapist } from "../../api/main/therapist";
import { addReview, fetchTherapist } from "../../model/therapist";
import { searchTherapist } from "../../reducers/bookings/actions";

import { DropdownButton, MenuItem } from "react-bootstrap";
import { InputTextArea } from "../../components/atoms/input-text-area";
import { Button } from "../../components/atoms/button";
import { config } from "../../config";
import styled from "styled-components";
import { DataTableHeading, DataTableNoData } from "../../components/molecules";
import * as reviewsActions from "../../reducers/reviews/actions";
import { ApiReview } from "../../api/main/reviews";
import moment from "moment-timezone";
import { DataTableCell } from "../../components/atoms/data-table-cell";
import { ReviewDeleteModal } from "../../components/sub/alerts/review-delete";

const HeaderContainer2 = styled.div`
    background-color: #dedede;
    color: #666666; 
    float: left;
    padding: 10px;
    width: 100%;
`;

const HeaderText2 = styled.div`
    float: left;
`;

const RowNoHover = styled.div`
    background-color: #ffffff;
    padding: 10px; 
    border-bottom: 1px solid #dedede;
    float: left; 
    width: 100%;
`;

const Row = styled(RowNoHover)`
    &:hover {
      background: #efefef;
    }
`;

const Cell = styled.div`
    float: left; 
    width: 100%;
`;

interface ReduxProps {
    therapist: ApiTherapist;
    isBusy: boolean;
    error: string;
    reviews: ApiReview[];
}

interface State {
    review: string;
    rating: number;
    customer: SelectOption;
    reviewsHasLoaded: boolean;
    toggleCreateModal: boolean;
    toggleDeleteModal: boolean;
    customerId: string;
    dateCreated: string;
    isDeleteModalVisible: boolean;
    bookingUrn: string;
}

type OwnProps = RouteComponentProps<{ urn: string }>;
type Props = ReduxProps & OwnProps & { dispatch: Dispatch<{}> };

const DEFAULT_STATE: State = {
    review: "",
    rating: 5,
    customer: null,
    reviewsHasLoaded: false,
    toggleCreateModal: false,
    toggleDeleteModal: false,
    customerId: "",
    dateCreated: "",
    isDeleteModalVisible: false,
    bookingUrn: "",
};

class Component extends React.Component<Props, State> {

    state: State = DEFAULT_STATE;

    componentDidMount() {
        if (!this.props.therapist) {
            this.props.dispatch(fetchTherapist(this.props.match.params.urn));
        }

        this.props.dispatch(reviewsActions.fetchAllReviews(this.props.match.params.urn));

        this.setState({
            reviewsHasLoaded: true,
        });
    }

    handleClickBookings = (): void => {
        this.props.dispatch(searchTherapist(this.props.therapist.urn));
    }

    onReviewTextChange = (review: string): void => {
        this.setState({ review });
    }

    onRatingChange = (rating: number): void => {
        this.setState({ rating });
    }

    onReviewEnterKeyDown = (_e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
        // e.preventDefault();
        // this.handleSubmitReview();
    }

    handleSubmitReview = async (): Promise<void> => {
        const { therapist } = this.props;
        const { review, rating } = this.state;

        let customer: SelectOption = this.state.customer;
        await this.props.dispatch(addReview(therapist.urn, review, rating, customer.value));
        this.setState({ ...DEFAULT_STATE });
    }

    customerArrayOptionsChanged = (option: any): void => {
        this.setState({ customer: option });
    }

    bookingLink = (review: ApiReview) => {
        if (review.bookingUrn === "") {
            return "-";
        }

        return <a href={`/bookings/${review.bookingUrn}`}
            style={{ color: "#337ab7", textDecoration: "underline" }}>Booking</a>
    }

    toggleDeleteModal = () => {
        this.setState({ isDeleteModalVisible: !this.state.isDeleteModalVisible });
    }

    toggleDeleteModalAndSet = (bookingUrn: string) => {
        if (bookingUrn === "") return;

        this.setState({
            bookingUrn,
            isDeleteModalVisible: !this.state.isDeleteModalVisible,
        });
    }

    submitDelete = () => {
        this.props.dispatch(reviewsActions.deleteReview(this.state.bookingUrn));
        this.toggleDeleteModal();
    }

    render() {
        const { therapist } = this.props;
        const customerArrayOptions: SelectOption[] = config.customers;

        let caption = "Reviews";
        if (this.props.reviews.length > 0) {
            caption += ` (${this.props.reviews.length})`;
        }

        return (
            <div className="therapist-container">
                {therapist &&
                    <div className="main-section">
                        <h1>{therapist.name}</h1>

                        <div className="app-details-row">
                            <div className="main-details">

                                <div className="detail-heading">
                                    Select Customer
                                </div>
                                <div className="field-edit">
                                    <div className="select-box">
                                        <Select
                                            value={this.state.customer}
                                            clearable={false}
                                            options={customerArrayOptions}
                                            onChange={this.customerArrayOptionsChanged} />
                                    </div>
                                </div>

                                <br />

                                <div className="main-details-row">
                                    <div className="therapist-description">
                                        <div className="detail-heading">
                                            Review
                                        </div>
                                        <InputTextArea
                                            onChange={this.onReviewTextChange}
                                            onKeyDown={this.onReviewEnterKeyDown}
                                            value={this.state.review}
                                            placeholder="Enter therapist review"
                                            rows={3}
                                        />
                                    </div>
                                </div>

                                <div className="main-details-row">
                                    <div className="therapist-description">
                                        <div className="detail-heading">
                                            Rating
                                        </div>

                                        <DropdownButton
                                            title={`${this.state.rating} star`}
                                            key={1}
                                            id={"therapist-rating-dropdown"}
                                        >
                                            {[1, 2, 3, 4, 5].map((rating) => <MenuItem
                                                onClick={this.onRatingChange.bind(this, rating)} eventKey={rating}>{rating}</MenuItem>)}
                                        </DropdownButton>
                                    </div>
                                </div>

                                <div className="main-details-row">
                                    <div className="therapist-description"
                                        style={{ display: "flex", alignContent: "flex-end", justifyContent: "flex-end" }}>
                                        <Button onClick={this.handleSubmitReview} label="Submit review" />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <DataTableHeading
                            caption={caption}
                            event={{
                                onClick: null,
                                text: "",
                            }} />

                        <HeaderContainer2>
                            <HeaderText2 style={{ width: "10%" }}>Date created</HeaderText2>
                            <HeaderText2 style={{ width: "40%" }}>Comment</HeaderText2>
                            <HeaderText2 style={{ width: "20%", textAlign: "center" }}>Rating</HeaderText2>
                            <HeaderText2 style={{ width: "10%" }}>Booking</HeaderText2>
                            <HeaderText2 style={{ width: "10%" }}>Customer</HeaderText2>
                            <HeaderText2 style={{ width: "10%" }}>Actions</HeaderText2>
                        </HeaderContainer2>

                        {!this.state.reviewsHasLoaded && (
                            <Row>
                                <Cell style={{ width: "100%" }}>
                                    Loading
                                </Cell>
                            </Row>
                        )}

                        {this.props.reviews.length === 0 && this.state.reviewsHasLoaded &&
                            <DataTableNoData
                                text={"No reviews to display"}
                                event={{
                                    text: "",
                                    onClick: null,
                                }} />
                        }

                        {this.props.reviews.map((review, key) => {
                            return <Row key={key}>

                                <Cell style={{ width: "10%", textAlign: "center" }}>
                                    {moment(review.createdDate).format("hh:mm")}
                                    <br />
                                    {moment(review.createdDate).format("YYYY-MM-DD")}
                                </Cell>

                                <DataTableCell width="40%" text={review.comment} />

                                <Cell style={{ width: "20%", textAlign: "center" }}>
                                    {review.rating}
                                </Cell>

                                <Cell style={{ width: "10%", textAlign: "left" }}>
                                    {this.bookingLink(review)}
                                </Cell>

                                <Cell style={{ width: "10%" }}>
                                    <a href={`/customers/${review.customerUrn}/general`}
                                        style={{
                                            color: "#337ab7",
                                            textDecoration: "underline",
                                        }}>{review.customerUrn.replace("urn:ruuby:customer:", "")}</a>
                                </Cell>

                                <Cell style={{ width: "10%", textAlign: "left" }}>
                                    <a onClick={() => this.toggleDeleteModalAndSet(review.bookingUrn)}
                                        style={{
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                            color: "#337ab7"
                                        }}>
                                        Delete
                                    </a>
                                </Cell>

                            </Row>
                        })}
                    </div>
                }

                <div className="side-nav">
                    <TherapistSubMenu urn={this.props.match.params.urn} active="reviews"
                        onClickBookings={this.handleClickBookings} />
                </div>

                {
                    !therapist || this.props.isBusy &&
                    <div className="spinner-container main-section">
                        <Spinner name="pulse" fadeIn="none" />
                    </div>
                }

                <ReviewDeleteModal
                    isOpen={this.state.isDeleteModalVisible}
                    title="Review deletion"
                    message={`Are you sure you want to delete this review?`}
                    closeModal={this.toggleDeleteModal}
                    handleConfirm={this.submitDelete} />
            </div>
        );
    }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): ReduxProps => {
    const therapistUrn = ownProps.match.params.urn;
    const therapist = state.therapistsState.therapists[therapistUrn];

    return {
        therapist: (therapist) ? therapist : null,
        isBusy: state.therapistsState.isBusy,
        error: state.therapistsState.error,
        reviews: state.reviews.reviews,
    };
};

export const TherapistReviewsContainer = withRouter(connect<ReduxProps, {}, OwnProps>(mapStateToProps)(Component));
