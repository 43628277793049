import styled from "styled-components";
import { Option } from "react-select";
import moment from "moment-timezone";
import * as Select from "react-select";
import * as React from "react";

const TimeRangeGroup = styled.div`
  display: flex;
  row-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const TimeRangeToText = styled.text`
  margin: 5px;
`;

const TimeRangeTextDisabled = styled.text`
  color: #ababab;
`;

export interface TimeRange {
  fromTime: Option;
  toTime: Option;
}

interface IDateRangeSelectProps {
  fromTime: Option;
  toTime: Option;
  isInvalid: boolean;
  onTimeRangeChange: (range: TimeRange) => void;
}

interface IDateRangeSelectState {
  fromTime?: Option;
  toTime?: Option;
}

export default class TherapistTimeRangeSelect extends React.Component<
  IDateRangeSelectProps,
  IDateRangeSelectState
> {
  options: Array<Option> = [];
  state: IDateRangeSelectState = {};

  constructor(props: IDateRangeSelectProps) {
    super(props);
    const startTime = moment.duration("PT0H");
    const endTime = moment.duration("PT24H");
    while (startTime.asMilliseconds() <= endTime.asMilliseconds()) {
      this.options.push({
        label: durationToTimeLabel(startTime),
        value: startTime.toISOString(),
      });
      startTime.add(30, "m");
    }
  }

  componentDidUpdate(
    prevProps: IDateRangeSelectProps,
    _prevState: IDateRangeSelectState,
  ) {
    if (this.props.fromTime !== prevProps.fromTime) {
      this.setState({
        fromTime: this.props.fromTime,
      });
    }

    if (this.props.toTime !== prevProps.toTime) {
      this.setState({
        toTime: this.props.toTime,
      });
    }
  }

  isSameOrGreater = (option: Option, option2: Option) => {
    if (!option || !option2) {
      return;
    }

    const duration = moment.duration(option.value).asMilliseconds();
    const duration2 = moment.duration(option2.value).asMilliseconds();
    return duration >= duration2;
  };

  handleOnFromTimeChange = (fromTime: Option) => {
    let toTime = this.state.toTime;
    if (toTime && this.isSameOrGreater(fromTime, toTime)) {
      toTime = undefined;
    }
    const timeRange = { toTime, fromTime };
    this.setState({ toTime, fromTime });
    this.props.onTimeRangeChange(timeRange);
  };

  handleOnToTimeChange = (toTime: Option) => {
    let fromTime = this.state.fromTime;
    if (fromTime && this.isSameOrGreater(fromTime, toTime)) {
      fromTime = undefined;
    }
    const timeRange = { toTime, fromTime };
    this.setState({ toTime, fromTime });
    this.props.onTimeRangeChange(timeRange);
  };

  renderToTimeOption = (toTime: Option) => {
    const fromTime = this.state.fromTime;
    if (fromTime && this.isSameOrGreater(fromTime, toTime)) {
      return <TimeRangeTextDisabled>{toTime.label}</TimeRangeTextDisabled>;
    }

    return <div>{toTime.label}</div>;
  };

  render() {
    return (
      <TimeRangeGroup>
        <Select
          style={{
            borderColor:
              this.props.isInvalid &&
              (!this.state.fromTime || !this.state.fromTime.value)
                ? "red"
                : "",
          }}
          value={this.state.fromTime}
          clearable={false}
          options={this.options}
          onChange={this.handleOnFromTimeChange}
        />
        <TimeRangeToText>to</TimeRangeToText>
        <Select
          style={{
            borderColor:
              this.props.isInvalid &&
              (!this.state.toTime || !this.state.toTime.value)
                ? "red"
                : "",
          }}
          value={this.state.toTime}
          clearable={false}
          options={this.options}
          optionRenderer={this.renderToTimeOption}
          onChange={this.handleOnToTimeChange}
        />
      </TimeRangeGroup>
    );
  }
}

export function durationToTimeLabel(time: moment.Duration) {
  const hours = time.hours();
  const minutes = time.minutes();
  const hoursLabel = `${hours < 10 ? `0` : ``}${hours}`;
  const minutesLabel = `${minutes < 10 ? `0` : ``}${minutes}`;

  return `${hoursLabel}:${minutesLabel}`;
}
