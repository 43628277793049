import * as React from "react";
import * as Spinner from "react-spinkit";
import styled from "styled-components";
import { RouteComponentProps, withRouter } from "react-router";
import { ApiCalendarSlotInterval } from "../../api/main/availability";
import { ApiBooking } from "../../api/main/booking";
import moment from "moment-timezone";


const ONE_SLOT_HEIGHT = 40;
const SLOTS_PER_HOUR = 2;

interface Props {
  therapistUrn: string;
  availabilitySlots: ApiCalendarSlotInterval[];
  bookingSlots: ApiBooking[];
  updatingSlot: ApiCalendarSlotInterval;
  onClick: (slot: ApiCalendarSlotInterval) => void;
  day: string;
  isLoading: boolean;
}

const BookingsList = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
`;
const Booking = styled.p`
  width: 100%;
  position: absolute;
  background-color: rgb(188, 199, 211);
  border-left: 5px solid lightslategray;
  padding: 5px;
  top: 0;
  height: ${ONE_SLOT_HEIGHT}
`;

class AvailabilityListComponent extends React.Component<Props & RouteComponentProps<{}>> {
  renderBooking = (index: number, booking: ApiBooking): JSX.Element => {
    const day = moment(this.props.day);
    const timeStarts = moment(booking.timeStarts);
    const timeEnds = moment(booking.timeEnds);
    const hoursSinceMidnight = timeStarts.diff(timeStarts.clone().hours(0).minutes(0), "hours", true);
    const duration = timeEnds.diff(timeStarts < day ? day : timeStarts, "hours", true);
    const height = SLOTS_PER_HOUR * ONE_SLOT_HEIGHT * duration;
    const top = SLOTS_PER_HOUR * ONE_SLOT_HEIGHT * (timeStarts < day ? 0 : hoursSinceMidnight);
    // Specific position and height to overlay this booking on the time schedule
    const ThisBooking = styled(Booking)`
      top: ${top};
      height: ${height};
    `;
    const url = `/bookings/${booking.urn}`;
    return (
      <ThisBooking key={index}>
        <a href={url}>
          {booking.customer.firstName} {booking.customer.lastName}: {timeStarts.format("HH:mm")} - {timeEnds.format("HH:mm")}
        </a>
      </ThisBooking>
    );
  }

  renderTimeSlot = (index: number, slot: ApiCalendarSlotInterval): JSX.Element => {
    const {start, type} = slot;
    const updatingClass = slot === this.props.updatingSlot ? 'updating' : '';

    return (
      <div 
        key={index} 
        className={`availability-row ${type.toLocaleLowerCase()} ${updatingClass}`}
        onClick={() => this.props.onClick(slot)}
      >
        <span className="time">{moment(start).format("HH:mm")}</span>
      </div>
    );
  }

  render() {
    if (this.props.isLoading) {

      return (
        <div className="spinner-container">
          <Spinner name="pulse" fadeIn="none" />
        </div>
      );
    }

    return (
        <div className="availability-list">
          <BookingsList>
            {
              this.props.bookingSlots.map(
                (booking, index) => this.renderBooking(index, booking)
              )
            }
          </BookingsList>
          {
            this.props.availabilitySlots.map(
              (slot, index) => this.renderTimeSlot(index, slot)
            )
          }
        </div>
    );
  }
}

export const AvailabilityList = withRouter(AvailabilityListComponent);
