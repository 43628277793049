import styled from "styled-components";

export interface BadgeProps {
  small?: boolean;
}

export const BadgeWrapper = styled.div`
  display: inline-block;
  height: auto;
  font-family: "nunito_sansregular";
  border-radius: 5px;
  margin-left: 2px;
  margin-right: 2px;

  ${(props: BadgeProps) =>
        props.small
            ? `padding: 3px 5px; font-size: 10px;`
            : `padding: 5px 10px; font-size: 14px;`}
`;