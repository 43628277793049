import * as types from "./types";
import * as api from "../../api/main/notes";
import { ActionDispatch } from "../../reducers";
import { Note } from "../../api/main/notes";
import { addNotification } from "../notifications/actions";
import { NOTIFICATION_TYPES } from "../notifications/types";

export const actionCreators = {
  fetchAttempt: () => ({
    type: types.FETCH_ATTEMPT
  }),
  fetchSuccess: (notes: Note[]) => ({
    type: types.FETCH_SUCCESS,
    payload: { notes }
  }),
  fetchFailure: (error: string) => ({
    type: types.FETCH_FAILURE,
    payload: { error }
  }),
  clear: () => ({
    type: types.CLEAR
  }),
  createSuccess: (note: Note) => ({
    type: types.CREATE_SUCCESS,
    payload: { note }
  }),
  update: (urn: string, field: string, value: string | boolean) => ({
    type: types.UPDATE,
    payload: {urn, field, value},
  }),
};

export function fetchBookingNotes(urn: string): ActionDispatch {
  return async dispatch => {
    dispatch(actionCreators.fetchAttempt());

    try {
      const notes = await api.fetchNotes(urn);
      dispatch(actionCreators.fetchSuccess(notes));
    } catch (error) {
      dispatch(actionCreators.fetchFailure(error.message));
    }
  };
}

export function createNote(bookingUrn: string, comment: string): ActionDispatch {
  return async dispatch => {
    try {
      const note = await api.createNote(bookingUrn, comment);
      dispatch(actionCreators.createSuccess(note));
    } catch (error) {
      console.error("FAILING MAKING NOTE", error);
      dispatch(actionCreators.fetchFailure(error.message));
    }
  };
}

/**
 * Update a note
 * @param noteUrn - URN of note to update
 * @param field - Field of note to update
 * @param value - value to set
 */
export function updateNote(noteUrn: string, field: string, value: string| boolean): ActionDispatch {
  return async dispatch => {
    try {
      await api.updateNote(noteUrn, field, value);
      dispatch(actionCreators.update(noteUrn, field, value));
      dispatch(addNotification(NOTIFICATION_TYPES.success, "Note updated"));
    } catch (error) {
      dispatch(addNotification(NOTIFICATION_TYPES.danger, "Note could not be updated"));
      dispatch(actionCreators.fetchFailure(error.message));
    }
  };
}
