import { config } from "../../../config";
import * as api from "../request";

export interface ApiTreatment {
  name: string;
  description: string;
  duration: number;
  isDeleted: boolean;
  isBlackLabel: boolean;
  isCorporate: boolean;
  isAddon: boolean;
  categoryUrns: string[];
  urn: string;
  topLevelCategory: string;
  timeCreated: string;
  timeUpdated: string;

  regions?: {
      name: string;
      classic: number;
      elite: number;
      blackLabel: number;
      hospitality: number;
  }[];
}

/**
 * Search for treatments
 * @param query String to search
 */
export async function search(query: string): Promise<ApiTreatment[]> {
  const url = `${config.adminPanelApiUrl}/treatments?q=${query}`;
  const response = await api.get<{ treatments: ApiTreatment[] }>(url);

  return response.data.treatments;
}

/**
 * Fetch all treatments
 */
export async function fetchAll(): Promise<ApiTreatment[]> {
  const url = `${config.adminPanelApiUrl}/all-treatments`;
  const response = await api.get<{ treatments: ApiTreatment[] }>(url);

  return response.data.treatments;
}

/**
 * Fetch treatments for a therapist
 * @param therapistUrn URN of therapist to fetch treatments for
 */
export async function fetchTreatmentsForTherapist(therapistUrn: string): Promise<ApiTreatment[]> {
  const url = `${config.adminPanelApiUrl}/therapists/${therapistUrn}/treatments`;
  const response = await api.get<{ treatments: ApiTreatment[] }>(url);

  return response.data.treatments;
}

/**
 * Fetch a single treatment
 * @param treatmentUrn URN of treatment to fetch
 */
export async function fetch(treatmentUrn: string): Promise<ApiTreatment> {
  const url = `${config.adminPanelApiUrl}/treatments/${treatmentUrn}`;
  const response = await api.get<ApiTreatment>(url);

  return response.data;
}

export enum TreatmentUpdateField {
  NAME = "NAME",
  DESCRIPTION = "DESCRIPTION",
  LONDON_PRICE_CLASSIC = "LONDON_PRICE_CLASSIC",
  LONDON_PRICE_ELITE = "LONDON_PRICE_ELITE",
  LONDON_PRICE_BLACK_LABEL = "LONDON_PRICE_BLACK_LABEL",
  LONDON_PRICE_HOSPITALITY = "LONDON_PRICE_HOSPITALITY",
  PRICE_MANCHESTER = "PRICE_MANCHESTER",
  CATEGORIES = "CATEGORIES",
  DURATION = "DURATION",
  REGIONAL_CLASSIC_PRICE = "REGIONAL_CLASSIC_PRICE",
  REGIONAL_ELITE_PRICE = "REGIONAL_ELITE_PRICE",
  REGIONAL_BLACK_LABEL_PRICE = "REGIONAL_BLACK_LABEL_PRICE",
  REGIONAL_HOSPITALITY_PRICE = "REGIONAL_HOSPITALITY_PRICE",
  IS_ADDON = "IS_ADDON",
}

export type TreatmentUpdateValue = string | number | boolean | string[];

/**
 * Update a treatment
 * @param treatmentUrn URN of treatment to update
 * @param key Field to update
 * @param value Value to update to
 */
export async function update(treatmentUrn: string, key: TreatmentUpdateField, value: TreatmentUpdateValue, region: string): Promise<void> {
  const url = `${config.adminPanelApiUrl}/treatments/${treatmentUrn}`;
  await api.patch(url, { key, value, region });
}

interface CreateParams {
  name: string;
  description: string;
  categoryUrns: string[];
  londonPriceClassic: number;
  londonPriceElite: number;
  londonPriceBlackLabel: number;
  londonPriceHospitality: number;
  duration: number;
  isAddon: boolean;
}

/**
 * Create a new treatment, returning new URN
 * @param params Paramaters of new treatment
 */
export async function create(params: CreateParams): Promise<string> {
  const url = `${config.adminPanelApiUrl}/treatments`;
  const response = await api.post<{ urn: string }>(url, params);

  return response.data.urn;
}

/**
 * Delete treatment
 * @param treatmentUrn URN of treamtent to delete
 */
export async function deleteTreatment(treatmentUrn: string): Promise<void> {
  const url = `${config.adminPanelApiUrl}/treatments/${treatmentUrn}`;
  await api.deletion(url);
}
