import * as React from "react";
import {Table} from "react-bootstrap";
import {sprintf} from "sprintf-js";
import * as Select from "react-select";

import {TRANSACTION_EXTERNAL_COMMISSION} from "../../../constants";

const commissionTypes = [
  {
    name: "Quintessentially",
    value: 0.1,
  },
  {
    name: "Manhattan Loft Gardens",
    value: 0.1,
  },
  {
    name: "Devonshire Hotel & Club",
    value: 0.15,
  },
  {
    name: "Luxury Retreats (Airbnb)",
    value: 0.25,
  },
];

interface Props {
  add: (type: string, amount: number) => void;
  delete: (transactionUrn: string) => void;
  commissions: any[];
  bookingUrn: string;
  grossValue: number;
}

interface State {
  isAddingOpen: boolean;
  selectedType: number;
}

export class ExternalCommissions extends React.Component<Props, State> {
  state: State = {
    isAddingOpen: false,
    selectedType: undefined,
  };

  handleSubmit = (): void => {
    this.props.add(TRANSACTION_EXTERNAL_COMMISSION, this.getCalculatedCommission());
    this.handleClose();
  }

  handleOpen = (): void => {
    this.setState({
      isAddingOpen: true,
    });
  }

  handleClose = (): void => {
    this.setState({
      isAddingOpen: false,
      selectedType: undefined,
    });
  }

  handleSelectType = (selectedType: number) => {
    this.setState({...this.state, selectedType});
  }

  getCalculatedCommission = (): number => {
    if (typeof this.state.selectedType !== "undefined") {
      return commissionTypes[this.state.selectedType].value * this.props.grossValue;
    }
    else {
      return 0;
    }
  }

  renderAddingRow = (): JSX.Element => {
    const options = commissionTypes.map((promo, value) => ({
      label: promo.name,
      value,
    }));

    const value = this.getCalculatedCommission();

    return (
      <tr className="adjustments-row">
        <td>
          <Select
            name="promoSelect"
            options={options}
            value={this.state.selectedType}
            onChange={(v: Select.Option) => this.handleSelectType(Number.parseInt(v.value.toString()))}
            clearable={false}
          />
        </td>
        <td></td>
        <td className="amount">
          {sprintf("£%0.2f", value)}
        </td>
        <td className="controls promo-controls">
          {
            value > 0 &&
            <button className="button-submit" type="button" onClick={this.handleSubmit}>
              <span className="fa fa-check" aria-hidden="true" />
            </button>
          }
          <button className="button-cancel" type="button" onClick={this.handleClose}>
            <span className="fa fa-times" aria-hidden="true" />
          </button>
        </td>
      </tr>
    );
  }

  renderRows = () => {
    const {commissions} = this.props;

    return commissions.map((commission, index) => (
      <tr key={index} className="adjustments-row">
        <td>
          Commission
        </td>
        <td></td>
        <td>
          {sprintf("£%0.2f", commission.amount)}
        </td>
        <td className="controls">
          <button onClick={() => this.props.delete(commission.urn)} className="button-delete" type="button" >
            <span className="fa fa-trash" aria-hidden="true" />
          </button>
        </td>
      </tr>
    ));
  }

  render() {
    const noCommissions = (
      <div className="no-adjustments">
        No commissions for this booking
      </div>
    );

    return (
      <div className="adjustments">
        <div className="adjustments-header">
          <h3>External Commissions</h3>

          {!this.state.isAddingOpen && <button className="new-adjustment btn btn-primary" onClick={this.handleOpen}><i className="fa fa-plus"></i> Add commission</button>}
        </div>

        <Table>
          <colgroup>
            <col width="40%" />
            <col width="40%" />
            <col width="15%" />
            <col width="5%" />
          </colgroup>

          <thead>
            <tr>
              <th>Type</th>
              <th></th>
              <th>Amount</th>
              <th>&nbsp;</th>
            </tr>
          </thead>

          <tbody>
            {this.state.isAddingOpen && this.renderAddingRow()}
            {this.renderRows()}
          </tbody>
        </Table>

        {this.props.commissions.length === 0 && !this.state.isAddingOpen && noCommissions}
      </div>
    );
  }
}
