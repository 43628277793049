import * as React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import * as Spinner from "react-spinkit";
import { withRouter, RouteComponentProps } from "react-router-dom";
import * as Select from "react-select";

import { TherapistSubMenu } from "../../components/sub/sub-menu";
import { RootState } from "../../reducers";
import { ApiTherapist, TherapistUpdateField } from "../../api/main/therapist";
import { fetchTherapist, updateTherapistField } from "../../model/therapist";
import { searchTherapist } from "../../reducers/bookings/actions";
import { addTag, getTag } from "../../api/main/tag";

interface ReduxProps {
  therapist: ApiTherapist;
  isBusy: boolean;
  error: string;
}

interface State {
  tags: Select.Option[];
  options: Select.Option[];
}

type OwnProps = RouteComponentProps<{ urn: string }>;
type Props = ReduxProps & OwnProps & { dispatch: Dispatch<{}> };

const DEFAULT_STATE: State = {
  tags: undefined,
  options: undefined,
};

class Component extends React.Component<Props, State> {
  selectRef: any;
  state: State = DEFAULT_STATE;

  async componentDidMount() {
    if (!this.props.therapist) {
      await this.props.dispatch(fetchTherapist(this.props.match.params.urn));
    }

    const tagsData = await getTag();
    const options = tagsData.map(t => {
      return {
        value: t.tag,
        label: t.tag,
      };
    })

    this.setState({ options });

    const tags = this.props.therapist.tags
      ? this.props.therapist.tags.map((tag) => {
          return {
            value: tag,
            label: tag,
          };
        })
      : [];

    this.setState({ tags });
  }

  handleClickBookings = (): void => {
    this.props.dispatch(searchTherapist(this.props.therapist.urn));
  };

  handleTagsChange = (options: Select.Option[]) => {
    this.setState({ tags: options }, this.saveTags);
  };

  saveTags = () => {
    const therapistTags = this.state.tags.map(v => v.label);

    this.props.dispatch(updateTherapistField(this.props.therapist.urn, TherapistUpdateField.TAGS, therapistTags));
  }

  // add new tag to a dictionary in DynamoDB and mimic default logic for select
  addNewTag = async (option: Select.Option) => {
    this.selectRef.props.options.unshift(option);
    this.selectRef.select.selectValue(option);
    
    await addTag(option.label);
  }

  render() {
    const { therapist } = this.props;

    return (
      <div className="therapist-container">
        {therapist && (
          <div className="main-section">
            <h1>{therapist.name}</h1>

            <div className="app-details-row">
              <div className="main-details">
                <Select.Creatable
                  multi
                  value={this.state.tags}
                  onChange={this.handleTagsChange}
                  onNewOptionClick={this.addNewTag}
                  options={this.state.options}
                  placeholder="Type new tag or select existing one..."
                  ref={(select: any) => this.selectRef = select}
                />
              </div>
            </div>
          </div>
        )}

        <div className="side-nav">
          <TherapistSubMenu
            urn={this.props.match.params.urn}
            active="tags"
            onClickBookings={this.handleClickBookings}
          />
        </div>

        {!therapist ||
          (this.props.isBusy && (
            <div className="spinner-container main-section">
              <Spinner name="pulse" fadeIn="none" />
            </div>
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): ReduxProps => {
  const therapistUrn = ownProps.match.params.urn;
  const therapist = state.therapistsState.therapists[therapistUrn];

  return {
    therapist: therapist ? therapist : null,
    isBusy: state.therapistsState.isBusy,
    error: state.therapistsState.error,
  };
};

export const TherapistTagsContainer = withRouter(
  connect<ReduxProps, {}, OwnProps>(mapStateToProps)(Component),
);
