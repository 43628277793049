import * as React from "react";
import styled from "styled-components";
import moment from "moment";
import loadingWomenIcon from "../../assets/loaders/loading_women.gif";
import loadingDefaultIcon from "../../assets/loaders/loading.svg";

const HeaderContainer1 = styled.div`
  width: 100%;
  float: left;
  background-color: #396174;
  padding: 10px;
`;

const HeaderText1 = styled.div`
  color: #ffffff;
  float: left;
`;

const HeaderText2 = styled.div`
  float: left;
`;

const HeaderContainer2 = styled.div`
  background-color: #dedede;
  color: #666666;
  float: left;
  padding: 10px;
  width: 100%;
`;

const Cell = styled.div`
  float: left;
`;

const Row = styled.div`
  padding: 10px;
  border-bottom: 1px solid #dedede;
  float: left;
  width: 100%;
  &:hover {
    background: #efefef;
  }
`;

const Listing = styled.div`
  background-color: #ffffff;
  float: left;
  width: 100%;
`;

interface Action {
  name: string;
  onClick: (id: string) => void;
}

export interface ColumnNames {
  title: string;
  key: string;
  width: string;
}

interface DataEntry {
  [key: string]: any;
}

interface Props {
  tableName: string;
  createEntryAction?: () => void;
  columns: ColumnNames[];
  dataset: DataEntry[];
  rowActions: Action[] | null;
  emptyMessage: string;
  isLoading?: boolean;
}

interface State {
  dataset: DataEntry[];
}

export default class Table extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      dataset: this.mapEntries(this.props.dataset),
    };
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.dataset !== this.props.dataset) {
      this.setState({
        ...this.state,
        dataset: this.mapEntries(this.props.dataset),
      });
    }
  }

  mapEntry(entry: DataEntry) {
    const obj = {};
    obj["_id"] = { data: entry["_id"], hidden: true };
    Object.values(this.props.columns).forEach(({ key }) => {
      obj[key] = { data: entry[key], hidden: false };
    });

    return obj;
  }

  mapEntries(entries: DataEntry[]) {
    return entries.map((entry) => this.mapEntry(entry));
  }

  renderHeaders() {
    return (
      <React.Fragment>
        <HeaderContainer1>
          <HeaderText1>
            {this.props.tableName} ({this.state.dataset.length})
          </HeaderText1>
          {this.props.createEntryAction && (
            <HeaderText1 style={{ float: "right" }}>
              <a
                href="#"
                style={{
                  textDecoration: "underline",
                  color: "#ffffff",
                  cursor: "pointer",
                }}
                onClick={() => this.props.createEntryAction()}
              >
                Create new {this.props.tableName.toLowerCase()}
              </a>
            </HeaderText1>
          )}
        </HeaderContainer1>
        <HeaderContainer2>
          {this.props.columns.map(({ title, width }) => {
            return <HeaderText2 style={{ width: width }}>{title}</HeaderText2>;
          })}
          {this.props.rowActions && (
            <HeaderText2 style={{ float: "right" }}> Actions </HeaderText2>
          )}
        </HeaderContainer2>
      </React.Fragment>
    );
  }

  renderEntry(entry: DataEntry) {
    if (entry === null) {
      return (
        <Row>
          <Cell>{this.props.emptyMessage}</Cell>
        </Row>
      );
    }

    if (this.props.isLoading) {
      return;
    }

    return (
      <Row>
        {Object.keys(entry).map((key) => {
          if (entry[key].hidden) return;

          if (!entry[key].data) {
            return <Cell style={{ width: "10%" }}>&nbsp;</Cell>;
          }

          return <>{entry[key].data}</>;
        })}

        {this.props.rowActions && (
          <Cell style={{ float: "right" }}>
            {this.props.rowActions.map((action, i) => {
              return (
                <React.Fragment>
                  <a
                    href="#"
                    onClick={() => action.onClick(entry["_id"].data)}
                    style={{ textDecoration: "underline", color: "#666666" }}
                  >
                    {action.name}
                  </a>
                  {i !== this.props.rowActions.length - 1 ? " | " : ""}
                </React.Fragment>
              );
            })}
          </Cell>
        )}
      </Row>
    );
  }

  renderEntries() {
    if (!this.state.dataset.length) {
      return this.props.isLoading ? null : (
        <Listing>{this.renderEntry(null)}</Listing>
      );
    }

    return (
      <Listing>
        {this.state.dataset.map((entry) => {
          return this.renderEntry(entry);
        })}
      </Listing>
    );
  }

  checkDatesBetween(date: moment.Moment, dateStart: moment.Moment, dateEnd: moment.Moment) {
    if (date.isBetween(dateStart, dateEnd) || date.isSame(dateStart)) {
      return true;
    }

    return false;
  }

  renderLoader() {
    const currDate = moment();
    const events = [
      {
        loader: loadingWomenIcon,
        start: moment("8 Mar").year(currDate.year()),
        end: moment("9 Mar").year(currDate.year()),
      },
    ];

    const event = events.find((e) => {
      if (this.checkDatesBetween(currDate, e.start, e.end)) {
        return true;
      }
      return false;
    });

    const loader = event ? event.loader : loadingDefaultIcon;

    return (
      <div style={{ textAlign: "center", backgroundColor: "#ffffff" }}>
        <img height="50" src={loader} alt="loading..." />
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderHeaders()}
        {this.props.isLoading && this.renderLoader()}
        {this.renderEntries()}
      </React.Fragment>
    );
  }
}
