import * as React from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import styled from "styled-components";

const ButtonContainer = styled(ButtonGroup)`
  display: inline;
  margin-right: 10px;
`;

interface Props {
  onSearchTypeChange: (type: string) => void;
  searchField: string;
}

export class SearchTypePicker extends React.Component<Props, {}> {
  renderOption = (label: string, value: string) => {
    return (
      <Button bsSize="small" active={this.props.searchField === value} onClick={() => this.props.onSearchTypeChange(value)} >
        { label }
      </Button>
    );
  }

  render() {
    return (
      <ButtonContainer>
        { this.renderOption("Booked", "CREATED") }
        { this.renderOption("Appt.", "BOOKING") }
      </ButtonContainer>
    );
  }
}