import * as React from "react";
import { Button } from "react-bootstrap";
import { Dispatch } from "redux";
import * as api from "../../api/main/availability";
import { ApiTimeRange, getTherapistsWithNoAutoAvailabilitySet } from "../../api/main/availability";
import { TimeRange } from "../../components/therapist/therapist-time-range-select";

interface IState {
    textbox: string;
    timeRange: { [weekday: string]: { [row: number]: TimeRange } };
    urns: string[];
}

interface ReduxProps {
    textbox: string;
}

type Props = ReduxProps & { dispatch: Dispatch<{}> };

class ScriptContainer extends React.Component<Props, IState> {
    state: IState;

    constructor(props: Props) {
        super(props);
        this.state = {
            textbox: "",
            timeRange: {},
            urns: [],
        }
    }

    async componentDidMount() {
        const urns = await getTherapistsWithNoAutoAvailabilitySet();
        this.setState({ urns });
    }

    handleInputChange(key: string, e: any) {
        let obj = {};
        obj[key] = e.target.value;
        this.setState(obj);
    }

    submit = () => {

        let timeRanges: {
            [weekday: string]: ApiTimeRange[],
        } = {};

        // Week days
        timeRanges["Monday"] = [{ fromTime: "PT9H", toTime: "PT21H" }];
        timeRanges["Tuesday"] = [{ fromTime: "PT9H", toTime: "PT21H" }];
        timeRanges["Wednesday"] = [{ fromTime: "PT9H", toTime: "PT21H" }];
        timeRanges["Thursday"] = [{ fromTime: "PT9H", toTime: "PT21H" }];
        timeRanges["Friday"] = [{ fromTime: "PT9H", toTime: "PT21H" }];

        // Weekend
        timeRanges["Saturday"] = [{ fromTime: "PT10H", toTime: "PT19H" }];
        timeRanges["Sunday"] = [{ fromTime: "PT10H", toTime: "PT19H" }];

        this.state.textbox.split(',').forEach((urn: string) => {
            try {
                this.setAvailability(urn.trim(), timeRanges);
            }
            catch (err) {
                console.log(err);
            }
        });
    }

    setAvailability = async (therapistUrn: string, timeRanges: api.ApiTimeRanges) => {
        await api.bulkSetAvailability(therapistUrn, timeRanges);
    }

    render() {

        return (
            <>
                <div className="booking-chat-container">

                    <div className="main-section" style={{ width: "100%" }}>

                        <div className="detail-job-heading">
                            Therapists with no auto availability set
                        </div>
                        <div className="detail-job-value">
                            <textarea
                                style={{ padding: "10px" }}
                                value={this.state.urns}
                                rows={4}
                            />
                        </div>

                        <br />

                        <div className="detail-job-heading">
                            Therapist urn's
                        </div>
                        <div className="detail-job-value">
                            <textarea
                                style={{ padding: "10px" }}
                                value={this.state.textbox}
                                onChange={this.handleInputChange.bind(this, "textbox")}
                                cols={70}
                                rows={10}
                            />
                        </div>

                        <br />

                        <Button onClick={() => this.submit()} className="btn-create-job">Run</Button>

                    </div>

                </div>
            </>
        );
    }
}

export { ScriptContainer };
