import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import moment from "moment";

import { ApiPartner } from "../../api/main/partners";
import { fetchTherapists } from "../../model/therapist";
import {
  fetchAllPartners,
  createPartner,
  editPartner,
  setTherapists,
} from "../../reducers/partners/actions";
import PartnerTherapist from "../../components/partners/partner-therapist";
import { RootState } from "../../reducers/index";
import styled from "styled-components";
import * as Modal from "react-modal";
import { Button, Tab, Tabs } from "react-bootstrap";
import Switch from "react-bootstrap-switch";
import Table from "../../components/table";
import { config } from "../../config";

const loadingIcon = require("../../assets/loaders/loading.svg");

const modalStyling: Modal.Styles = {
  content: {
    height: "fit-content",
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",
    border: "0px",
    backgroundColor: "#efefef",
  },
  overlay: {
    zIndex: 3,
  },
};

const PageContainer = styled.div`
  padding: 0 20px 20px 20px;
  float: left;
  width: 100%;
`;

const JobsContainer = styled.div`
  background-color: #ffffff;
  width: 100%;
  float: left;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
`;

const HeaderText2 = styled.div`
  float: left;
`;

const HeaderContainer2 = styled.div`
  background-color: #dedede;
  color: #666666;
  float: left;
  padding: 10px;
  width: 100%;
`;

const TherapistListingBody = styled.div`
  background-color: #ffffff;
  float: left;
  width: 100%;
  max-height: 500px;
  overflow: auto;
`;

const TherapistListing = styled.div`
  background-color: #ffffff;
  float: left;
  width: 100%;
`;

const Cell = styled.div`
  float: left;
`;

const Row = styled.div`
  padding: 10px;
  border-bottom: 1px solid #dedede;
  float: left;
  width: 100%;
  &:hover {
    background: #efefef;
  }
`;

const ModalContainer = styled.div`
  background-color: #efefef;
`;

const ModalHeading = styled.div`
  background-color: #396174;
  color: #ffffff;
  margin: 0px;
  padding: 10px;
  text-transform: capitalize;
`;

const ModalBody = styled.div`
  margin: 0;
  padding: 0;
`;

const TherapistModalBody = styled.div`
  margin: 0;
  padding: 0;
  textAlign: left;
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  width: 20px;
  height: 20px;
  margin: 0 !important;
  vertical-align: middle;
`;

interface ReduxProps {
  partners: ApiPartner[];
  therapists: any;
  isLoading: boolean;
  sortedTherapists: any[];
}

type Props = ReduxProps & {
  dispatch: Dispatch<{}>;
};

enum ModalType {
  EDIT = "edit",
  CREATE = "create",
  THERAPISTS = "therapists",
  NONE = "",
}

interface PartnerFields {
  addressLine1: string;
  addressLine2: string;
  bookingsEnabled: boolean;
  contactName: string;
  customersEnabled: boolean;
  email: string;
  id: string;
  loginEnabled: boolean;
  name: string;
  password: string;
  postcode: string;
  contactNumber: string;
  contactEmail: string;
  welcomeText: string;
  logoURL: string;
  ruubyEmail: boolean;
  customerEmail: boolean;
  partnerEmail: boolean;
  ruubyEmailAddress: string;
  customerEmailAddress: string;
  partnerEmailAddress: string;
  telephone: string;
  therapistsEnabled: boolean;
  therapistUrn: string;
  tier: string;
  treatmentsEnabled: boolean;
  username: string;
  isActive: boolean;
  partnerCommission: number;
  therapistCommission: number;
  ruubyCommission: number;
}

interface IState extends PartnerFields {
  modalTypeVisible: ModalType;
  selectedTherapists: any;
  isAllTherapistsSelected: boolean;
  fieldsEdited: {
    [key: string]: boolean;
  };
  therapistItems: any[];
}

const INITIAL_STATE = {
  addressLine1: "",
  addressLine2: "",
  bookingsEnabled: true,
  contactName: "",
  customerEmail: false,
  customersEnabled: true,
  email: "",
  id: "",
  isAllTherapistsSelected: false,
  loginEnabled: false,
  modalTypeVisible: ModalType.NONE,
  name: "",
  partnerEmail: false,
  password: "",
  postcode: "",
  ruubyEmail: false,
  selectedTherapists: Object.create(null),
  telephone: "",
  therapistsEnabled: true,
  therapistUrn: "",
  tier: "CLASSIC",
  treatmentsEnabled: true,
  username: "",
  fieldsEdited: Object.create(null),
  contactNumber: "",
  contactEmail: "",
  customerEmailAddress: "",
  ruubyEmailAddress: "",
  partnerEmailAddress: "",
  welcomeText: "",
  logoURL: "",
  therapistItems: [] as any,
  isActive: false,
  partnerCommission: 40,
  therapistCommission: 40,
  ruubyCommission: 20,
};

class Component extends React.Component<Props, IState> {
  state = { ...INITIAL_STATE };

  async componentDidMount() {
    await this.props.dispatch(fetchAllPartners());

    if (
      this.props.sortedTherapists.length <= 1 ||
      !this.props.sortedTherapists
    ) {
      await this.props.dispatch(fetchTherapists());
    }
  }

  showCreateModal = () => {
    this.setState({
      modalTypeVisible: ModalType.CREATE,
    });
  };

  showEditModal = (id: string) => {
    const partner: ApiPartner = this.props.partners.find((p) => p.id == id);

    this.setState({
      id: partner.id,
      name: partner.name,
      addressLine1: partner.addressLine1,
      addressLine2: partner.addressLine2,
      postcode: partner.postcode,
      tier: partner.tier,
      email: partner.email,
      loginEnabled: partner.loginEnabled,
      username: partner.username,
      password: "*******",
      bookingsEnabled: partner.bookingsEnabled,
      therapistsEnabled: partner.therapistsEnabled,
      treatmentsEnabled: partner.treatmentsEnabled,
      customersEnabled: partner.customersEnabled,
      therapistUrn: partner.therapistUrn,
      contactName: partner.contactName,
      telephone: partner.telephone,
      partnerEmail: partner.emails.partner.enabled,
      customerEmail: partner.emails.customer.enabled,
      ruubyEmail: partner.emails.ruuby.enabled,
      customerEmailAddress: partner.emails.customer.address,
      ruubyEmailAddress: partner.emails.ruuby.address,
      partnerEmailAddress: partner.emails.partner.address,
      modalTypeVisible: ModalType.EDIT,
      contactNumber: partner.contactNumber,
      contactEmail: partner.contactEmail,
      welcomeText: partner.welcomeText,
      logoURL: partner.logoURL,
      isActive: partner.isActive,
      partnerCommission: partner.commissions.partner,
      therapistCommission: partner.commissions.therapist,
      ruubyCommission: partner.commissions.ruuby,
    });
  };

  showTherapistsModal = (id: string) => {
    const partner = this.props.partners.find((p) => p.id == id);
    const selectedTherapists = Object.create(null);

    for (const therapist of partner.therapists) {
      selectedTherapists[therapist.urn] = therapist.hideFromPlatform;
    }

    this.setState({
      id: partner.id,
      selectedTherapists: selectedTherapists,
      isAllTherapistsSelected:
        Object.keys(selectedTherapists).length ===
        this.props.sortedTherapists.length,
      modalTypeVisible: ModalType.THERAPISTS,
      therapistItems: this.getPartnerTherapistItems(selectedTherapists),
    });
  };

  getPartnerTherapistItems(selectedTherapistMap: object) {
    const therapists = [];
    const checkedTherapists = [];

    for (const i of this.props.sortedTherapists) {
      if (i in selectedTherapistMap) {
        checkedTherapists.push(i);
      } else {
        therapists.push(i);
      }
    }

    return checkedTherapists.concat(therapists);
  }

  toggleTherapist(therapistId: string, e: any) {
    const selectedTherapists = Object.assign({}, this.state.selectedTherapists);
    const isChecked = e.target.checked;

    if (isChecked) {
      selectedTherapists[therapistId] = false;
    } else {
      delete selectedTherapists[therapistId];
    }

    this.setState({ selectedTherapists: selectedTherapists });
  }

  toggleHideFromPlatform(therapistId: string, e: any) {
    const selectedTherapists = Object.assign({}, this.state.selectedTherapists);

    selectedTherapists[therapistId] = e.target.checked;

    this.setState({ selectedTherapists: selectedTherapists });
  }

  toggleSelectAllTherapists(e: any) {
    const isChecked = e.target.checked;

    if (isChecked) {
      const selectedTherapists = Object.assign(
        {},
        this.state.selectedTherapists,
      );

      for (const therapist of this.props.sortedTherapists) {
        if (!(therapist in selectedTherapists)) {
          selectedTherapists[therapist] = false;
        }
      }

      this.setState({ selectedTherapists: selectedTherapists });
    } else {
      this.setState({ selectedTherapists: {} });
    }

    this.setState({ isAllTherapistsSelected: isChecked });
  }

  // remove suffix
  onChangePartnerTherapist = (urn: string) => {
    const therapistUrn = urn.slice(0, -2);
    this.setState({ therapistUrn });
  };

  handleInputChange(key: string, e: any) {
    let obj = {};
    obj[key] = e.target.value;
    obj["fieldsEdited"] = { ...this.state.fieldsEdited, [key]: true };
    this.setState(obj);
  }

  handleCheckboxChange(key: string) {
    let obj = {};
    obj[key] = !this.state[key];
    obj["fieldsEdited"] = { ...this.state.fieldsEdited, [key]: true };
    this.setState(obj);
  }

  submitCreatePartner = () => {
    const partner: ApiPartner = {
      name: this.state.name,
      addressLine1: this.state.addressLine1,
      addressLine2: this.state.addressLine2,
      postcode: this.state.postcode.toUpperCase(),
      tier: this.state.tier,
      email: this.state.email,
      loginEnabled: this.state.loginEnabled,
      username: this.state.username,
      password: this.state.password,
      therapistUrn: this.state.therapistUrn,
      bookingsEnabled: this.state.bookingsEnabled,
      therapistsEnabled: this.state.therapistsEnabled,
      treatmentsEnabled: this.state.treatmentsEnabled,
      customersEnabled: this.state.customersEnabled,
      contactName: this.state.contactName,
      telephone: this.state.telephone,
      emails: {
        partner: {
          enabled: this.state.partnerEmail,
          address: this.state.partnerEmailAddress,
        },
        customer: {
          enabled: this.state.customerEmail,
          address: this.state.customerEmailAddress,
        },
        ruuby: {
          enabled: this.state.partnerEmail,
          address: this.state.ruubyEmailAddress,
        },
      },
      contactNumber: this.state.contactNumber,
      contactEmail: this.state.contactEmail,
      welcomeText: this.state.welcomeText,
      logoURL: this.state.logoURL,
      isActive: this.state.isActive,
      commissions: {
        partner: this.state.partnerCommission,
        therapist: this.state.therapistCommission,
        ruuby: this.state.ruubyCommission,
      }
    };

    this.props.dispatch(createPartner(partner));
    this.closeModal();
  };

  submitEditPartner = () => {
    const partner = Object.keys(this.state.fieldsEdited).reduce(
      (acc, key) => {
        switch (key) {
          case "postcode":
            acc[key] = this.state.postcode.toUpperCase();
            break;

          case "partnerEmail":
          case "partnerEmailAddress":
            acc["emails"] = {
              ...acc["emails"],
              partner: {
                enabled: this.state.partnerEmail,
                address: this.state.partnerEmailAddress,
              },
            };
            break;

          case "customerEmail":
          case "customerEmailAddress":
            acc["emails"] = {
              ...acc["emails"],
              customer: {
                enabled: this.state.customerEmail,
                address: this.state.customerEmailAddress,
              },
            };

            break;

          case "ruubyEmail":
          case "ruubyEmailAddress":
            acc["emails"] = {
              ...acc["emails"],
              ruuby: {
                enabled: this.state.ruubyEmail,
                address: this.state.ruubyEmailAddress,
              },
            };
            break;

          default:
            acc[key] = this.state[key];
            break;
        }

        return acc;
      },
      { id: this.state.id, name: this.state.name } as ApiPartner,
    );

    partner.commissions = {
      partner: this.state.partnerCommission,
      therapist: this.state.therapistCommission,
      ruuby: this.state.ruubyCommission,
    };

    this.props.dispatch(editPartner(partner));
    this.closeModal();
  };

  submitSetTherapists = () => {
    const partner = {
      id: this.state.id,
      therapists: Object.keys(this.state.selectedTherapists).map(
        (therapistUrn) => ({
          urn: therapistUrn,
          hideFromPlatform: this.state.selectedTherapists[therapistUrn],
        }),
      ),
    };

    this.props.dispatch(setTherapists(partner));
    this.closeModal();
  };

  closeModal = () => {
    this.setState({ ...INITIAL_STATE, modalTypeVisible: ModalType.NONE });
  };

  mapDataset(data: ApiPartner[]) {
    return data.map((partner: ApiPartner) => {
      const obj = {};
      obj['_id'] = partner.id;
      Object.entries(partner).map(([key, value]) => {
        switch (key) {
          case "name": {
            const component = (
              <Cell key={key} style={{ width: "20%", textTransform: "capitalize" }}>
                <a
                  href={`${config.customerWebsiteUrl}partner/${partner.slug}`}
                  style={{
                    textDecoration: "underline",
                    color: "#666666",
                  }}
                  target="_blank"
                >
                  {value}
                </a>
              </Cell>
            );
            obj[key] = component;
            break;
          }

          case "dateCreated": {
            const component = (
              <Cell key={key} style={{ width: "10%" }}>
                <a style={{ color: "black", textDecoration: "none" }}>
                  {moment(value).format("DD-MM-YYYY")}
                </a>
              </Cell>
            );
            obj[key] = component;
            break;
          }

          case "loginEnabled":
          case "isActive": {
            const component = (
              <Cell key={key} style={{ width: "10%" }}>
                <a style={{ color: "black", textDecoration: "none" }}>
                  {value ? "Yes" : "No"}
                </a>
              </Cell>
            );
            obj[key] = component;
            break;
          }

          default: {
            const component = (
              <Cell key={key} style={{ width: "10%", textTransform: "capitalize" }}>
                <a style={{ color: "black", textDecoration: "none" }}>
                  {value}
                </a>
              </Cell>
            );
            obj[key] = component;
            break;
          }
        }
      });
      return obj;
    });
  }

  renderTherapists = () => {
    if (this.props.isLoading) {
      return (
        <TherapistListing>
          <Row>
            <Cell style={{ width: "100%", textAlign: "center" }}>
              <img height="50" src={loadingIcon} alt="loading..." />           
            </Cell>
          </Row>
        </TherapistListing>
      );
    }

    if (this.props.sortedTherapists.length == 0) {
      return (
        <TherapistListing>
          <Row>
            <Cell>No therapists to display</Cell>
          </Row>
        </TherapistListing>
      );
    }

    return this.state.therapistItems.map((therapistId: string, key: number) => (
      <TherapistListing key={key}>
        <Row>
          <Cell style={{ width: "5%" }}>
            <Checkbox
              id={`partner-therapist-${key}`}
              type="checkbox"
              checked={therapistId in this.state.selectedTherapists}
              onChange={this.toggleTherapist.bind(this, therapistId)}
            />
          </Cell>
          <Cell style={{ width: "35%" }}>
            <label
              htmlFor={`partner-therapist-${key}`}
              style={{ fontWeight: "normal" }}
            >
              {this.props.therapists[therapistId].name}
            </label>
          </Cell>
          <Cell style={{ width: "40%" }}>
            {this.props.therapists[therapistId].email}
          </Cell>
          <Cell style={{ width: "20%", textAlign: "center" }}>
            <Checkbox
              type="checkbox"
              checked={!!this.state.selectedTherapists[therapistId]}
              disabled={!(therapistId in this.state.selectedTherapists)}
              onChange={this.toggleHideFromPlatform.bind(this, therapistId)}
            />
          </Cell>
        </Row>
      </TherapistListing>
    ));
  };

  renderTabs(tabsId: string) {
    return (
      <Tabs
        mountOnEnter={true}
        defaultActiveKey="general"
        id={tabsId}
        className="mb-3"
        style={{ margin: "20px" }}
      >
        <Tab eventKey="general" title="General" style={{ height: "265px" }}>
          <div className="main-partner-details-cell">
            <div className="main-partner-details-row">
              <div className="detail-partner-heading">Name of partner</div>
              <div className="detail-partner-value">
                <input
                  type="text"
                  value={this.state.name}
                  onChange={this.handleInputChange.bind(this, "name")}
                  autoFocus={true}
                />
              </div>
            </div>
            <div className="main-partner-details-row">
              <div className="detail-partner-heading">Contact name</div>
              <div className="detail-partner-value">
                <input
                  type="text"
                  value={this.state.contactName}
                  onChange={this.handleInputChange.bind(this, "contactName")}
                />
              </div>
            </div>
            <div className="main-partner-details-row clear-both">
              <div className="detail-partner-heading">Contact email</div>
              <div className="detail-partner-value">
                <input
                  type="text"
                  value={this.state.email}
                  onChange={this.handleInputChange.bind(this, "email")}
                />
              </div>
            </div>
          </div>
          <div className="main-partner-details-cell-right">
            <div className="main-partner-details-row clear-both">
              <div className="detail-partner-heading">Contact telephone</div>
              <div className="detail-partner-value">
                <input
                  type="text"
                  value={this.state.telephone}
                  onChange={this.handleInputChange.bind(this, "telephone")}
                />
              </div>
            </div>
            <div className="main-partner-details-row clear-both">
              <div className="detail-partner-heading">Ruuby email</div>
              <div className="detail-partner-value">
                <input
                  type="text"
                  value={this.state.contactEmail}
                  onChange={this.handleInputChange.bind(this, "contactEmail")}
                />
              </div>
            </div>
            <div className="main-partner-details-row clear-both">
              <div className="detail-partner-heading">Ruuby telephone</div>
              <div className="detail-partner-value">
                <input
                  type="text"
                  value={this.state.contactNumber}
                  onChange={this.handleInputChange.bind(this, "contactNumber")}
                />
              </div>
            </div>
          </div>
        </Tab>
        <Tab eventKey="address" title="Address" style={{ height: "265px" }}>
          <div className="main-partner-details-cell">
            <div className="main-partner-details-row">
              <div className="detail-partner-heading">Address line 1</div>
              <div className="detail-partner-value">
                <input
                  type="text"
                  value={this.state.addressLine1}
                  onChange={this.handleInputChange.bind(this, "addressLine1")}
                />
              </div>
            </div>
            <div className="main-partner-details-row">
              <div className="detail-partner-heading">Address line 2</div>
              <div className="detail-partner-value">
                <input
                  type="text"
                  value={this.state.addressLine2}
                  onChange={this.handleInputChange.bind(this, "addressLine2")}
                />
              </div>
            </div>
            <div className="main-partner-details-row">
              <div className="detail-partner-heading">Outcode</div>
              <div className="detail-partner-value">
                <input
                  type="text"
                  value={this.state.postcode.toUpperCase()}
                  onChange={this.handleInputChange.bind(this, "postcode")}
                  maxLength={4}
                />
                <p
                  style={{
                    textAlign: "left",
                    color: "#ababab",
                    fontSize: "0.8em",
                    marginTop: "6px",
                    fontStyle: "italic",
                  }}
                >
                  Note: This outcode must be enabled on the therapist profile
                </p>
              </div>
            </div>
          </div>
          <div className="main-partner-details-cell-right">
            <div className="main-partner-details-row">
              <div className="detail-partner-heading">Welcome text</div>
              <div
                className="detail-partner-value"
                style={{ textAlign: "left" }}
              >
                <textarea
                  rows={9}
                  value={this.state.welcomeText}
                  onChange={this.handleInputChange.bind(this, "welcomeText")}
                ></textarea>
              </div>
            </div>
          </div>
        </Tab>
        <Tab eventKey="setup" title="Setup" style={{ height: "265px" }}>
          <div className="main-partner-details-cell">
            <div className="main-partner-details-row">
              <div className="detail-partner-heading">Partner tier</div>
              <div className="detail-partner-value">
                <select
                  value={this.state.tier}
                  onChange={this.handleInputChange.bind(this, "tier")}
                >
                  {config.tierOptions.map(item => {
                    return (
                      <option key={item.value} value={item.value}>{item.label}</option>
                    );
                  })}
                </select>
              </div>
            </div>
            <PartnerTherapist
              // temporaly add sufix for representation
              therapistUrn={`${this.state.therapistUrn}-1`}
              onChangePartnerTherapist={this.onChangePartnerTherapist}
            />
            <div className="main-partner-details-row">
              <div className="detail-partner-heading">Logo URL</div>
              <div className="detail-partner-value">
                <input
                  type="text"
                  value={this.state.logoURL}
                  onChange={this.handleInputChange.bind(this, "logoURL")}
                />
              </div>
            </div>
          </div>
          <div className="main-partner-details-cell-right">
            <div className="main-partner-details-row">
              <div className="detail-partner-heading">Partner Commission %</div>
              <div className="detail-partner-value">
                <input
                  type="number"
                  value={this.state.partnerCommission}
                  onChange={this.handleInputChange.bind(this, "partnerCommission")}
                />
              </div>
            </div>
            <div className="main-partner-details-row">
              <div className="detail-partner-heading">Therapist Commission %</div>
              <div className="detail-partner-value">
                <input
                  type="number"
                  value={this.state.therapistCommission}
                  onChange={this.handleInputChange.bind(this, "therapistCommission")}
                />
              </div>
            </div>
            <div className="main-partner-details-row">
              <div className="detail-partner-heading">Ruuby Commission %</div>
              <div className="detail-partner-value">
                <input
                  type="number"
                  value={this.state.ruubyCommission}
                  onChange={this.handleInputChange.bind(this, "ruubyCommission")}
                />
              </div>
            </div>
          </div>
        </Tab>
        <Tab
          eventKey="permissions"
          title="Permissions"
          style={{ height: "265px" }}
        >
          <div className="main-partner-details-cell">
            <div className="main-partner-details-row-sm">
              <div className="detail-partner-heading">Bookings page</div>
              <div
                className="detail-partner-value"
                style={{ textAlign: "left" }}
              >
                <Switch
                  value={this.state.bookingsEnabled}
                  onChange={() =>
                    this.handleCheckboxChange.bind(this, "bookingsEnabled")
                  }
                  bsSize="small"
                  onText="YES"
                  offText="NO"
                />
              </div>
            </div>
            <div className="main-partner-details-row-sm">
              <div className="detail-partner-heading">Therapists page</div>
              <div
                className="detail-partner-value"
                style={{ textAlign: "left" }}
              >
                <Switch
                  value={this.state.therapistsEnabled}
                  onChange={this.handleCheckboxChange.bind(
                    this,
                    "therapistsEnabled",
                  )}
                  bsSize="small"
                  onText="YES"
                  offText="NO"
                />
              </div>
            </div>
            <div className="main-partner-details-row-sm">
              <div className="detail-partner-heading">Treatments page</div>
              <div
                className="detail-partner-value"
                style={{ textAlign: "left" }}
              >
                <Switch
                  value={this.state.treatmentsEnabled}
                  onChange={this.handleCheckboxChange.bind(
                    this,
                    "treatmentsEnabled",
                  )}
                  bsSize="small"
                  onText="YES"
                  offText="NO"
                />
              </div>
            </div>

            <div className="main-partner-details-row-sm">
              <div className="detail-partner-heading">Customers page</div>
              <div
                className="detail-partner-value"
                style={{ textAlign: "left" }}
              >
                <Switch
                  value={this.state.customersEnabled}
                  onChange={this.handleCheckboxChange.bind(
                    this,
                    "customersEnabled",
                  )}
                  bsSize="small"
                  onText="YES"
                  offText="NO"
                />
              </div>
            </div>
          </div>
          <div className="main-partner-details-cell-right"></div>
        </Tab>
        <Tab eventKey="login" title="Credentials" style={{ height: "265px" }}>
          <div className="main-partner-details-cell">
            <div className="main-partner-details-row">
              <div className="detail-partner-heading">Account active</div>
              <div
                className="detail-partner-value"
                style={{ textAlign: "left" }}
              >
                <Switch
                  value={this.state.isActive}
                  onChange={this.handleCheckboxChange.bind(this, "isActive")}
                  bsSize="small"
                  onText="YES"
                  offText="NO"
                />
              </div>
            </div>

            <div className="main-partner-details-row">
              <div className="detail-partner-heading">Login</div>
              <div
                className="detail-partner-value"
                style={{ textAlign: "left" }}
              >
                <Switch
                  value={this.state.loginEnabled}
                  onChange={this.handleCheckboxChange.bind(
                    this,
                    "loginEnabled",
                  )}
                  bsSize="small"
                  onText="YES"
                  offText="NO"
                />
              </div>
            </div>
          </div>
          <div className="main-partner-details-cell-right">
            <div className="main-partner-details-row clear-both">
              <div className="detail-partner-heading">Username</div>
              <div className="detail-partner-value">
                <input
                  type="text"
                  disabled={!this.state.loginEnabled}
                  value={this.state.username}
                  onChange={this.handleInputChange.bind(this, "username")}
                />
              </div>
            </div>
            <div className="main-partner-details-row">
              <div className="detail-partner-heading">Password</div>
              <div className="detail-partner-value">
                <input
                  type="password"
                  disabled={!this.state.loginEnabled}
                  value={this.state.password}
                  onChange={this.handleInputChange.bind(this, "password")}
                />
              </div>
            </div>
          </div>
        </Tab>
        <Tab
          eventKey="emails"
          title="Confirmation emails"
          style={{ height: "265px" }}
        >
          <div className="main-partner-details-row">
            <div className="detail-partner-heading">
              Customer booking confirmation email
            </div>
            <div
              className="main-partner-details-row"
              style={{ height: "40px" }}
            >
              <div
                className="detail-partner-value"
                style={{ textAlign: "left", float: "left" }}
              >
                <Switch
                  value={this.state.customerEmail}
                  onChange={this.handleCheckboxChange.bind(
                    this,
                    "customerEmail",
                  )}
                  bsSize="small"
                  onText="YES"
                  offText="NO"
                />
              </div>
              <div
                className="detail-partner-value"
                style={{ float: "left", marginLeft: "10px" }}
              >
                <input
                  style={{ height: "30px" }}
                  type="text"
                  value={this.state.customerEmailAddress}
                  placeholder="Enter email"
                  onChange={this.handleInputChange.bind(
                    this,
                    "customerEmailAddress",
                  )}
                />
              </div>
            </div>
          </div>
          <div className="main-partner-details-row">
            <div className="detail-partner-heading">
              Partner booking confirmation email
            </div>
            <div
              className="main-partner-details-row"
              style={{ height: "40px" }}
            >
              <div
                className="detail-partner-value"
                style={{ textAlign: "left", float: "left" }}
              >
                <Switch
                  value={this.state.partnerEmail}
                  onChange={this.handleCheckboxChange.bind(
                    this,
                    "partnerEmail",
                  )}
                  bsSize="small"
                  onText="YES"
                  offText="NO"
                />
              </div>
              <div
                className="detail-partner-value"
                style={{ float: "left", marginLeft: "10px" }}
              >
                <input
                  style={{ height: "30px" }}
                  type="text"
                  placeholder="Enter email"
                  value={this.state.partnerEmailAddress}
                  onChange={this.handleInputChange.bind(
                    this,
                    "partnerEmailAddress",
                  )}
                />
              </div>
            </div>
          </div>
          <div className="main-partner-details-row">
            <div className="detail-partner-heading">
              Ruuby booking confirmation email
            </div>
            <div
              className="main-partner-details-row"
              style={{ height: "40px" }}
            >
              <div
                className="detail-partner-value"
                style={{ textAlign: "left", float: "left" }}
              >
                <Switch
                  value={this.state.ruubyEmail}
                  onChange={this.handleCheckboxChange.bind(this, "ruubyEmail")}
                  bsSize="small"
                  onText="YES"
                  offText="NO"
                />
              </div>
              <div
                className="detail-partner-value"
                style={{ float: "left", marginLeft: "10px" }}
              >
                <input
                  style={{ height: "30px" }}
                  type="text"
                  placeholder="Enter email"
                  value={this.state.ruubyEmailAddress}
                  onChange={this.handleInputChange.bind(
                    this,
                    "ruubyEmailAddress",
                  )}
                />
              </div>
            </div>
          </div>
        </Tab>
      </Tabs>
    );
  }

  renderTable() {
    const columns = [
      { title: "Name of partner", key: "name", width: "20%" },
      { title: "Contact name", key: "contactName", width: "10%" },
      { title: "Tier", key: "tier", width: "10%" },
      { title: "Login enabled", key: "loginEnabled", width: "10%" },
      { title: "Account active", key: "isActive", width: "10%" },
      { title: "Date created", key: "dateCreated", width: "10%" },
    ];

    const rowActions = [
      {
        name: "Partner login",
        onClick: (id: string) =>
        (window.location.href = `/login/${this.props.partners.find((p) => p.id == id).id
          }`),
      },
      {
        name: "Assign Therapist",
        onClick: (id: string) => this.showTherapistsModal(id),
      },
      {
        name: "Edit",
        onClick: (id: string) => this.showEditModal(id),
      },
    ];

    const dataset = this.mapDataset(this.props.partners);

    return (
      <Table
        tableName={"Partners"}
        createEntryAction={() => this.showCreateModal()}
        columns={columns}
        dataset={dataset}
        rowActions={rowActions}
        emptyMessage="No partners to show"
      />
    );
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modalTypeVisible !== ModalType.NONE}
          style={modalStyling}
          ariaHideApp={false}
        >
          <ModalContainer>
            {this.state.modalTypeVisible === ModalType.THERAPISTS ? (
              <>
                <ModalHeading>
                  {Object.keys(this.state.selectedTherapists).length} therapists
                  selected
                </ModalHeading>
                <TherapistModalBody>
                  <HeaderContainer2>
                    <HeaderText2 style={{ width: "5%" }}>
                      <Checkbox
                        type="checkbox"
                        checked={this.state.isAllTherapistsSelected}
                        onChange={this.toggleSelectAllTherapists.bind(this)}
                      />
                    </HeaderText2>
                    <HeaderText2 style={{ width: "34%" }}>Name</HeaderText2>
                    <HeaderText2 style={{ width: "40%" }}>Email</HeaderText2>
                    {/* <HeaderText2 style={{ width: "17%" }}>
                                    Phone
                                </HeaderText2> */}
                    <HeaderText2 style={{ width: "20%", textAlign: "center" }}>
                      Hide from platform
                    </HeaderText2>
                  </HeaderContainer2>
                  <TherapistListingBody>
                    {this.renderTherapists()}
                  </TherapistListingBody>
                  <div
                    className="main-partner-details-row"
                    style={{
                      float: "left",
                      marginTop: "20px",
                      marginLeft: "20px",
                    }}
                  >
                    <Button
                      onClick={() => this.submitSetTherapists()}
                      className="btn-create-job"
                    >
                      Save
                    </Button>
                    <Button
                      onClick={() => this.closeModal()}
                      className="btn-cancel-job"
                    >
                      Cancel
                    </Button>
                  </div>
                </TherapistModalBody>
              </>
            ) : (
              <>
                <ModalHeading>
                  {this.state.modalTypeVisible === ModalType.CREATE
                    ? "Create new partner"
                    : `Edit existing partner: ${this.state.name}`}
                </ModalHeading>
                <ModalBody>
                  <form style={{ margin: "0px" }}>
                    {this.renderTabs(
                      this.state.modalTypeVisible === ModalType.CREATE
                        ? "create-partner-tabs"
                        : "edit-partner-tabs",
                    )}
                    <div
                      className="main-partner-details-row clear-both"
                      style={{
                        textAlign: "left",
                        clear: "both",
                        margin: "0px",
                        padding: "20px",
                        backgroundColor: "rgb(222, 222, 222)",
                      }}
                    >
                      <Button
                        onClick={() =>
                          this.state.modalTypeVisible === ModalType.CREATE
                            ? this.submitCreatePartner()
                            : this.submitEditPartner()
                        }
                        className="btn-create-job"
                      >
                        {this.state.modalTypeVisible === ModalType.CREATE
                          ? "Create"
                          : "Save"}
                      </Button>
                      <Button
                        onClick={() => this.closeModal()}
                        className="btn-cancel-job"
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </ModalBody>
              </>
            )}
          </ModalContainer>
        </Modal>

        <div>
          <div className="job-creation-row">
            <PageContainer>
              <JobsContainer>{this.renderTable()}</JobsContainer>
            </PageContainer>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): ReduxProps => {
  return {
    partners: state.partners.partners,
    therapists: state.therapistsState.therapists,
    isLoading: state.therapistsState.isBusy,
    sortedTherapists: state.therapistsState.sortedTherapists
      .slice()
      .sort((therapistId1: string, therapistId2: string) => {
        const therapist1 = state.therapistsState.therapists[therapistId1];
        const therapist2 = state.therapistsState.therapists[therapistId2];

        if (therapist1.name > therapist2.name) {
          return 1;
        } else if (therapist2.name > therapist1.name) {
          return -1;
        }
        return 0;
      }),
  }
};

export const PartnerContainer = connect<ReduxProps, {}, {}>(mapStateToProps)(
  Component,
);