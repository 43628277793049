import { ActionType, getType } from "typesafe-actions";
import * as iassign from "immutable-assign";

import {therapistActions} from "./actions";
import { ApiTherapist } from "../../api/main/therapist";

type TherapistAction = ActionType<typeof therapistActions>;

export interface BusyState {
  recommended: boolean;
  image: {
    [index: string]: boolean;
  };
}

export type TherapistState = {
  error: string;
  isBusy: boolean;
  busy: BusyState;
  showTreatmentForm: boolean;
  therapists: {
    [urn: string]: ApiTherapist;
  };
  sortedTherapists: string[];
};

const INITIAL_STATE: TherapistState = {
  error: null,
  isBusy: false,
  busy: {
    recommended: false,
    image: {},
  },
  showTreatmentForm: false,
  therapists: {},
  sortedTherapists: [],
};

export function therapistReducer(state: TherapistState = INITIAL_STATE, action: TherapistAction): TherapistState {
  switch (action.type) {
    case getType(therapistActions.fetchTherapistsAttempt): {
      return iassign(
        state,
        s => {
          s.isBusy = true;

          return s;
        }
      );
    }

    case getType(therapistActions.fetchTherapistsSuccess): {
      return iassign(
        iassign(
          state,
          s => {
            s.isBusy = false;
            s.error = undefined;
            s.sortedTherapists = action.payload.therapists.map(t => t.urn);

            return s;
          }
        ),

        s => s.therapists,
        t => {
          // set each treatment in the map defined as 't'
          Object.values(action.payload.therapists).forEach(th => t[th.urn] = th);

          return t;
        }
      );
    }

    case getType(therapistActions.fetchTherapistsFail): {
      return iassign(
        state,
        s => {
          s.isBusy = false;
          s.error = action.payload.error;

          return s;
        }
      );
    }

    case getType(therapistActions.updateImageCrop): {

      return iassign(
        state,
        (s, ctx) => s.therapists[ctx.payload.urn].images[ctx.payload.index].crop,
        c => {
          c[action.payload.aspect] = action.payload.crop;

          return c;
        },
        {payload: action.payload},
      );
    }

    case getType(therapistActions.persistImageCropAttempt): {
      return iassign(
        state,
        s => {
          s.isBusy = true;

          return s;
        }
      );
    }

    case getType(therapistActions.persistImageCropSuccess): {
      return iassign(
        state,
        s => {
          s.isBusy = false;

          return s;
        }
      );
    }

    case getType(therapistActions.persistImageCropFail): {
      return iassign(
        state,
        s => {
          s.isBusy = false;
          s.error = action.payload.error;

          return s;
        },
      );
    }

    case getType(therapistActions.deleteImageState): {
      return iassign(
        state,
        (s, ctx) => s.therapists[ctx.payload.urn].images,
        i => {
          delete i[action.payload.index];

          return i;
        },
        {payload: action.payload},
      );
    }

    case getType(therapistActions.deleteImageAttempt): {
      return iassign(
        state,
        s => {
          s.isBusy = true;

          return s;
        }
      );
    }

    case getType(therapistActions.deleteImageSuccess): {
      return iassign(
        state,
        s => {
          s.isBusy = false;

          return s;
        }
      );
    }

    case getType(therapistActions.addTherapistImageAttempt): {
      return iassign(
        state,
        s => s.busy.image,
        i => {
          i[action.payload.index] = true;

          return i;
        },
      );
    }

    case getType(therapistActions.updateTherapistImageState): {
      return iassign(
        iassign(
          state,
          s => s.busy.image,
          i => {
            i[action.payload.index] = false;

            return i;
          },
        ),

        (s, ctx)  => s.therapists[ctx.payload.urn].images,
        i => {
          i[action.payload.index] = action.payload.image;

          return i;
        },
        {payload: action.payload},
      );
    }

    case getType(therapistActions.updateTherapistPostcodes): {
      return iassign(
        state,
        (s) => s.therapists[action.payload.therapistUrn],
        t => {
          const newPostcodeUrns = t.postcodeUrns.filter(p => p !== action.payload.postcodeUrn);
          if ( action.payload.enabled ) {
            newPostcodeUrns.push(action.payload.postcodeUrn);
          }
          t.postcodeUrns = newPostcodeUrns;

          return t;
        }
      )
    }

    case getType(therapistActions.addReviewInit): {
      return iassign(
        state,
        s => {
          s.isBusy = true;
          s.error = undefined;

          return s;
        }
      );
    }

    case getType(therapistActions.addReviewSuccess): {
      return iassign(
        state,
        s => {
          s.isBusy = false;

          return s;
        }
      );
    }

    case getType(therapistActions.addReviewFail): {
      return iassign(
        state,
        s => {
          s.isBusy = false;
          s.error = action.payload.error;

          return s;
        }
      );
    }

    default: return state;
  }
};
