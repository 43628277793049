import * as React from "react";
import Switch from "react-bootstrap-switch";

interface GeneralTabContentProps {
  inputTextChange: (key: string, e: any) => void;
  checkboxChange: () => void;
  name: string;
  isActive: boolean;
}

export default class GeneralTabContent extends React.Component<GeneralTabContentProps, {}> {
  render() {
    return (
      <div className="main-region-details-cell">
        <div className="main-region-details-row">
          <div className="detail-region-heading">
            Region name
          </div>
          <div className="detail-region-value">
            <input
              type="text"
              value={this.props.name}
              onChange={(event) => {
                this.props.inputTextChange('name', event);
              }}
              autoFocus={true}
            />
          </div>
        </div>
        <div className="main-region-details-row">
          <div className="detail-region-heading">
            Active
          </div>
          <div className="detail-region-value" style={{ textAlign: "left" }}>
            <Switch 
              value={this.props.isActive} 
              onChange={this.props.checkboxChange}
              bsSize="small" 
              onText="YES" 
              offText="NO" 
            />
          </div>
        </div>             
      </div>
    );
  }
}