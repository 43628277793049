import * as React from "react";
import * as Select from "react-select";

interface BookingStatusProps {
  status: string;
  setBookingStatus: (status: string) => void;
}

export default class BookingStatus extends React.Component<BookingStatusProps, {}> {

  handleStatusChange(option: any) {
    this.props.setBookingStatus(option.value);
  }

  render() {

    const options = ["Completed", "Unconfirmed", "Confirmed", "Cancelled"].map((status: string) => ({
      label: status.toUpperCase(),
      value: status
    }));

    const { status } = this.props;
    const value = (status) ? {
      label: status.toUpperCase(),
      value: status
    } : {};

    return (
      <div className="booking-time">
        <div className="detail-heading">
          Set booking status
        </div>
        <div className="detail-value">
          <div className="field-edit">
            <div className="select-box">
              <Select
                name={"salon-name"}
                value={value}
                clearable={false}
                options={options}
                onChange={this.handleStatusChange.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
