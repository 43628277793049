import { AxiosResponse } from "axios";

import { config } from "../../config";
import * as api from "./request";

export interface ApiBookingStatement {
  urn: string;
  therapistUrn: string;
  bookingUrn: string;
  name: string;
  value: string;
}

export async function fetch(bookingUrn: string): Promise<ApiBookingStatement[]> {
  const url = `${config.adminPanelApiUrl}/statements/${bookingUrn}`;

  const res = await api.get<{ bookingStatements: ApiBookingStatement[] }>(url);

  return res.data.bookingStatements;
}

export async function createBookingStatement(bookingStatement: ApiBookingStatement): Promise<ApiBookingStatement> {

  const url = `${config.adminPanelApiUrl}/statements/${bookingStatement.bookingUrn}`;

  const res: AxiosResponse = await api.put(url, bookingStatement);

  return mapRowToBookingStatement(res.data.bookingStatement);
}

export async function editBookingStatement(bookingStatement: ApiBookingStatement): Promise<ApiBookingStatement> {

  const url = `${config.adminPanelApiUrl}/statements/${bookingStatement.urn}`;

  const res: AxiosResponse = await api.post(url, bookingStatement);

  return mapRowToBookingStatement(res.data.bookingStatement);
}

export async function deleteBookingStatement(urn: string): Promise<void> {
  const url = `${config.adminPanelApiUrl}/statements/${urn}`;

  await api.deletion(url);
}

function mapRowToBookingStatement(bookingStatement: any): ApiBookingStatement {
  const statement: ApiBookingStatement = {
    urn: bookingStatement.urn,
    therapistUrn: bookingStatement.therapistUrn,
    bookingUrn: bookingStatement.bookingUrn,
    name: bookingStatement.name,
    value: bookingStatement.value,
  };

  return statement;
}