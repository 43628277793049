import * as React from "react";
import styled from "styled-components";
import moment from "moment-timezone";
import { Option } from "react-select";

import { Form, FormGroup, Col } from "react-bootstrap";
import { Button } from "../atoms/button";
import {
  ApiTimeRanges,
  getTherapistAvailabilitySchedule,
} from "../../api/main/availability";
import TherapistTimeRangeSelect, {
  TimeRange,
  durationToTimeLabel,
} from "./therapist-time-range-select";
import { ConfirmAlert } from "../sub/alerts/confirm-alert";

const FormContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px 15px;

  h2 {
    margin: 0 0 30px 0;
    text-transform: uppercase;
    font-size: 20px;
    color: #565656;
  }
`;

const Group = styled(FormGroup)`
  > * {
    margin-bottom: 10px;
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 20px;
  width: 100%;
  font-size: 18px;
`;

interface Props {
  therapistUrn: string;
  isBusy: boolean;
  onSubmit(
    timeRange: ApiTimeRanges
  ): Promise<boolean>;
}

interface State {
  timeRange: { [weekday: string]: { [row: number]: TimeRange } };
  invalidTimeRanges: { [weekday: string]: { [row: number]: boolean } };
  isModalOpen: boolean;
}

const INITIAL_STATE: State = {
  timeRange: {},
  invalidTimeRanges: {},
  isModalOpen: false,
};

const DAYS_OF_WEEK = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];

export class TherapistAvailabilityBulkForm extends React.Component<
  Props,
  State
> {
  state = INITIAL_STATE;

  async getAvailabilitySchedule() {
    const schedule = await getTherapistAvailabilitySchedule(
      this.props.therapistUrn,
    );
    const timeRange = {};

    for (const key in schedule) {
      const range = schedule[key].reduce((acc, val, index) => {
        const fromTime = {
          label: durationToTimeLabel(moment.duration(val["fromTime"])),
          value: val["fromTime"],
        };
        const toTime = {
          label: durationToTimeLabel(moment.duration(val["toTime"])),
          value: val["toTime"],
        };

        acc[index] = { fromTime, toTime };

        return acc;
      }, {});

      timeRange[key] = range;
    }

    this.setState({
      timeRange,
      invalidTimeRanges: {},
    });
  }

  async componentDidMount() {
    this.getAvailabilitySchedule();
  }

  handleClickSubmit = async (): Promise<void> => {
    if (this.isFormValid()) {
      await this.onSubmitForm();
    }
  };

  isFormValid = () => {
    const validTimeOption = (option: Option) => option && option.value;
    const validTimeRange = (range: TimeRange) =>
      range &&
      (!validTimeOption(range.fromTime) || !validTimeOption(range.toTime));

    const invalidTimeRanges: {
      [weekday: string]: { [row: number]: boolean };
    } = {};

    const hasInvalidTimeRanges =
      Object.keys(this.state.timeRange).filter((weekday: string) => {
        const ranges = this.state.timeRange[weekday];

        if (!invalidTimeRanges[weekday]) {
          invalidTimeRanges[weekday] = {};
        }

        invalidTimeRanges[weekday][0] = validTimeRange(ranges[0]);
        invalidTimeRanges[weekday][1] = validTimeRange(ranges[1]);

        return invalidTimeRanges[weekday][0] || invalidTimeRanges[weekday][1];
      }).length > 0;

    this.setState({ invalidTimeRanges });
    return !hasInvalidTimeRanges;
  };

  onSubmitForm = async () => {
    const timeRange = Object.keys(this.state.timeRange).reduce(
      (acc, weekday: string) => {
        const ranges = this.state.timeRange[weekday];
        acc[weekday] = Object.values(ranges).map((range) => {
          return { fromTime: range.fromTime.value, toTime: range.toTime.value };
        });
        return acc;
      },
      {},
    );

    await this.props.onSubmit(timeRange);
  };

  handleOnTimeRangeChange = (
    weekday: string,
    row: number,
    range: TimeRange,
  ) => {
    const timeRange = this.state.timeRange;

    if (!timeRange[weekday]) {
      timeRange[weekday] = {};
    }

    timeRange[weekday][row] = range;

    this.setState({ timeRange });
  };

  clearDay = (weekday: string) => {
    const timeRange = this.state.timeRange;
    delete timeRange[weekday];

    this.setState({ timeRange });
  };

  openDeleteModal = () => {
    this.setState({isModalOpen: true});
  };

  closeDeleteModal = () => {
    this.setState({isModalOpen: false});
  };

  clearAllDays = () => {
    this.props.onSubmit({});

    this.setState({
      timeRange: {},
      invalidTimeRanges: {},
      isModalOpen: false,
    });
  };

  renderWeekdayAvailability = (weekday: string) => {
    const invalidTimeRanges = this.state.invalidTimeRanges[weekday];
    const timeRangeDay = this.state.timeRange[weekday];

    return (
      <Col md={3} key={weekday}>
        <div>{weekday}</div>
        <Button
          label="Clear time"
          disabled={!timeRangeDay || !Object.keys(timeRangeDay).length}
          onClick={() => this.clearDay(weekday)}
        />
        <TherapistTimeRangeSelect
          isInvalid={Boolean(invalidTimeRanges && invalidTimeRanges[0])}
          onTimeRangeChange={(range) =>
            this.handleOnTimeRangeChange(weekday, 0, range)
          }
          fromTime={timeRangeDay && timeRangeDay[0] && timeRangeDay[0].fromTime}
          toTime={timeRangeDay && timeRangeDay[0] && timeRangeDay[0].toTime}
        />
        <TherapistTimeRangeSelect
          isInvalid={Boolean(invalidTimeRanges && invalidTimeRanges[1])}
          onTimeRangeChange={(range) =>
            this.handleOnTimeRangeChange(weekday, 1, range)
          }
          fromTime={timeRangeDay && timeRangeDay[1] && timeRangeDay[1].fromTime}
          toTime={timeRangeDay && timeRangeDay[1] && timeRangeDay[1].toTime}
        />
      </Col>
    );
  };

  render(): JSX.Element {
    return (
      <FormContainer>
        <Form>
          <div
            className="main-details-row"
            style={{ display: "inline-block", width: "100%", marginBottom: 10 }}
          >
            <div className="col-md-6">
              <div className="detail-heading">
                Clear all therapist's availability
              </div>
              <div className="detail-value" style={{ textAlign: "left" }}>
                <Button label="Clear all time" onClick={this.openDeleteModal} />
              </div>
            </div>
          </div>
          <Group>
            {DAYS_OF_WEEK.map((weekday: string) =>
              this.renderWeekdayAvailability(weekday),
            )}
          </Group>
        </Form>

        <div style={{ clear: "both" }} />

        <SubmitButton
          label="Submit"
          onClick={this.handleClickSubmit}
          isBusy={this.props.isBusy}
          disabled={this.props.isBusy}
        />

        <ConfirmAlert
          title="Clear all slots?"
          message="Please confirm deletion of all availability slots"
          closeModal={this.closeDeleteModal}
          handleConfirm={this.clearAllDays}
          isOpen={this.state.isModalOpen}
        />
      </FormContainer>
    );
  }
}
