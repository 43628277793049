import * as React from "react";
import * as Select from "react-select";
import { fetchCustomersAsync } from "../../../../reducers/bookings/create/actions";
import { ApiCustomer } from "../../../../api/main/customer";

interface BookingCustomerProps {
  customer: ApiCustomer;
  onChangeCustomer: (customer: any) => void;
  fetchAddresses: (customerUrn: string, bookingUrn?: string) => void;
}
interface BookingCustomerState {
  customer?: any;
}

export default class BookingCustomer extends React.Component<BookingCustomerProps, BookingCustomerState> {
  constructor(props: BookingCustomerProps) {
    super(props);
    this.state = {
      customer: {}
    };
  }

  handleCustomerSelect = (option: any): void => {
    /* TODO: Why?
     * Commented this lines, so options are not deleted on the selection
     */
    // delete option.value;
    // delete option.label;

    if (Array.isArray(option)) return;

    this.props.onChangeCustomer(option);
    this.props.fetchAddresses(option.urn);
  }

  render() {
    const { customer } = this.props;

    const value = (customer) ? {
      label: `${customer.isB2b ? '[B2B]' : ''} ${
        customer.isBlackLabel ? '[BL]' : ''} ${
        customer.firstName
      } ${customer.lastName} (${customer.email})`,
      value: customer.urn
    } : {};

    return (
      <div className="customer-edit">
        <div className="customer-details">
          <div className="detail-heading">
            Select a customer
          </div>
        </div>
        <div className="field-edit">
          <div className="input-box">
            <Select.Async
              value={value}
              clearable={false}
              onChange={this.handleCustomerSelect}
              loadOptions={fetchCustomersAsync} />
          </div>
        </div>
      </div>
    );
  }
}
