import { ActionType, getType } from "typesafe-actions";
import { actions } from "./actions";
import { ApiBookingStatement } from "../../api/main/booking-statements";
import * as iassign from "immutable-assign";

type BookingStatementAction = ActionType<typeof actions>;

export interface BookingStatementState {
  bookingStatements: ApiBookingStatement[],
}

const INITIAL_STATE: BookingStatementState = {
  bookingStatements: [],
};

export function bookingStatementReducer(state: BookingStatementState = INITIAL_STATE, action: BookingStatementAction): BookingStatementState {
  switch (action.type) {
    case getType(actions.requestGetAllBookingStatementsSuccess): {
      const bla = iassign(
        state,
        s => {
          s.bookingStatements = action.payload;
          return s;
        }
      );
      return bla;
    }
    case getType(actions.requestCreateBookingStatementInit): {
      return state;
    }
    case getType(actions.requestCreateBookingStatementSuccess): {
      return {
        ...state,
        bookingStatements: [...state.bookingStatements, action.payload]
      };
    }
    case getType(actions.requestEditBookingStatementInit): {
      return state;
    }
    case getType(actions.requestEditBookingStatementSuccess): {
      return {
        ...state,
        bookingStatements: state.bookingStatements.map((bookingStatement) =>
          bookingStatement.urn === action.payload.urn
            ? { ...bookingStatement, ...action.payload }
            : bookingStatement,
        ),
      };
    }
    case getType(actions.requestDeleteBookingStatementInit): {
      return state;
    }
    case getType(actions.requestDeleteBookingStatementInit): {
      return state;
    }
    case getType(actions.requestDeleteBookingStatementSuccess): {
      return iassign(
        state,
        s => {
          s.bookingStatements = state.bookingStatements.filter(x => x.urn != action.payload.urn);
          return s;
        }
      );
    }

    default: return state;
  }
}