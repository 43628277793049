import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps, withRouter } from "react-router";

import { fetchCustomers, searchCustomers } from "../reducers/customers/actions";
import { RootState } from "../reducers";
import { Well, WellRow } from "../components/atoms/well";
import { DebouncedSearchInput } from "../components/molecules/debounced-search-input";

import styled from "styled-components";
import Table from "../components/table";
import { ColumnNames } from "../components/table";
import { Badge } from "../components/atoms/customer-badge";
import { CustomerBadge } from "../api/main/customer";

interface ReduxProps {
  customers: any;
  sortedCustomers: string[];
  isBusy: boolean;
  isPartner: boolean;
}

type OwnProps = RouteComponentProps<{}>;

type Props = ReduxProps & OwnProps & { dispatch: Dispatch<{}> };

const Cell = styled.div`
  float: left;
`;

class CustomersContainerComponent extends React.Component<Props, {}> {
  componentWillMount(): void {
    if (!this.props.sortedCustomers || (this.props.sortedCustomers && this.props.sortedCustomers.length <= 1))
      this.props.dispatch(fetchCustomers());
  }

  queryCustomers = (query: string) => {
    this.props.dispatch(searchCustomers(query));
  }

  handleClickNewCustomer = (): void => {
    this.props.history.push("/customers/create");
  }

  mapDataset(data: any[], columns: any[]) {
    return data.map(({ customer }) => {
      const obj = {};
      obj["_id"] = customer.urn;
      columns.map(({ key }) => {
        const value = customer[key];

        switch (key) {
          case "name": {
            const component = (
              <Cell key={key} style={{ width: "30%" }}>
                <a
                  onClick={() => this.props.history.push(`/customers/${customer.urn}/general`)}
                  style={{
                    textDecoration: "underline",
                    color: "#666666",
                    cursor: "pointer"
                  }}
                >
                  {`${customer.firstName} ${customer.lastName}`}
                </a>
                &nbsp;
                {customer.badges.map((type: CustomerBadge) => <Badge small type={type}/>)}
              </Cell>
            );
            obj[key] = component;
            break;
          }

          case 'city': {
            const component = (
              <Cell key={key} style={{ width: "30%" }}>
                <a style={{ color: "black", textDecoration: "none" }}>
                  {customer.city} &nbsp;
                </a>
              </Cell>
            )
            obj[key] = component;
            break;
          }

          default: {
            const component = (
              <Cell key={key} style={{ width: "20%" }}>
                <a style={{ color: "black", textDecoration: "none" }}>
                  {value}
                </a>&nbsp;
              </Cell>
            );
            obj[key] = component;
            break;
          }
        }
      });
      return obj;
    });
  }
  renderTable() {
    const columns: ColumnNames[] = [
      { title: "Name", key: "name", width: "30%" },
      { title: "Email", key: "email", width: "20%" },
      { title: "Phone", key: "phone", width: "20%" },
      { title: "City", key: "city", width: "20%" },
    ];

    const customers = this.props.sortedCustomers.map(
      (id) => this.props.customers[id],
    );
    const dataset: any = this.mapDataset(customers, columns);

    return (
      <React.Fragment>
        <Table
          tableName={"Customer"}
          createEntryAction={() => this.handleClickNewCustomer()}
          columns={columns}
          dataset={dataset}
          rowActions={null}
          emptyMessage={"No customers to show."}
          isLoading={this.props.isBusy}
        />
      </React.Fragment>
    );
  }

  render() {
    return (
      <>
        <Well>
          <WellRow>
            <DebouncedSearchInput
              onChange={this.queryCustomers}
            />
          </WellRow>
        </Well>
        {this.renderTable()}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): ReduxProps => ({
  customers: state.customersState.customers,
  sortedCustomers: state.customersState.sortedCustomers.asMutable(),
  isBusy: state.customersState.isBusy,
  isPartner: state.operator.isPartner,
});

export const CustomersContainer = withRouter(connect<ReduxProps, {}, OwnProps>(mapStateToProps)(CustomersContainerComponent));
