import * as iassign from "immutable-assign";
import { ActionType, getType } from "typesafe-actions";

import {actionCreators} from "./actions";
import { ApiPostcodeDistrict } from "../../api/main/postcodes";

type postcodesAction = ActionType<typeof actionCreators>;

export type PostcodesState = {
  available: ApiPostcodeDistrict[]
};

const INITIAL_STATE: PostcodesState = {
  available: [],
};

export function postcodesReducer(state: PostcodesState = INITIAL_STATE, action: postcodesAction): PostcodesState {
  switch (action.type) {
    case getType(actionCreators.storeAvailablePostcodes): {
      return iassign(
        state,
        s => {
          s.available = action.payload.available;

          return s;
        }
      );
    }
    default: {
      return state;
    }
  }
}