import {ApiOperator} from "../api/main/operator";

export interface Operator {
  id: string;
  urn: string;
  permissions: {
    all?: boolean;
    bookings?: boolean;
    customers?: boolean;
    therapists?: boolean;
    treatments?: boolean;    
  };
  isPartner: boolean;
  partner?: any;
  summary?: any;
}

export function mapToOperator(operator: ApiOperator): Operator {
  return {
    urn: operator.urn,
    id: operator.urn.replace(/([a-z]+:){3}/, ""),
    permissions: operator.permissions,
    isPartner: operator.isPartner,
    partner: operator.partner,
    summary: operator.summary,
  };
}
