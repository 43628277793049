import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import * as Cookie from "js-cookie";

import { config } from "../config";

const DeviceInfo = config.deviceId;

const httpLink = createHttpLink({
  uri: config.graphql.url,
});

const authLink = setContext((_, { headers }) => {
  const token = Cookie.get("token");

  return {
    headers: {
      ...headers,
      "Device-Id": DeviceInfo,
      ...(token && {
        authorization: `Bearer ${token}`,
      }),
    },
  };
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
